import type { TPairIds } from "../../../constants/pairsConstants.ts";
import type {
  TFeeConfigs,
  TGroupConfigs,
} from "../../../constants/liveConstants.ts";
import type {
  TLexGroupConfigsLensStruct,
  TLexPairConfigsLensStruct,
  TLexPairStateLensStruct,
} from "../../../services/contractsIntegration/LexLensService/ILexLensService.ts";

import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import { CryptoWalletConnectionStore } from "../../CryptoWalletConnectionStore.ts";
import { ILeverageDimensionTradePairParameters } from "../../../services/leverageDimensionService/ILeverageDiomensionsService.ts";
import { TClosedPositionGist } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { TCompletePositionDataFromLens } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import { unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { precisionBnToFloat } from "../../../utils/lynxScalesUtils.ts";

import { scaledLeverageToUnits } from "../../../utils/leverageCalculationsUtils.ts";
import { scaledFractionToUnits } from "../../../utils/reductionCalculationUtils.ts";
import { SingleTradePairUserStore } from "./SingleTradePairUserStore.ts";
import { SingleLexStore } from "../LexStore/SingleLexStore.ts";
import { makePersistable } from "mobx-persist-store";
import { persistenceUtils } from "../../../utils/persistenceUtils.ts";

// note : used for display
export type TTradeActionType = "long" | "short";

// Represents all info and positions of a Pair-Asset
export class SingleTradePairStore {
  // meta
  @observable pairName: string;

  @observable pairId: TPairIds;

  // Matching Fee&Group
  @observable public feeId: number;
  @observable public groupId: number;

  // Limits
  @observable minLeverage: number; // leverage scale
  @observable maxLeverage: number; // leverage scale
  @observable maxBorrowF: number; // fraction scale

  @observable maxOpenInterestInUnits: bigint; // underlying scale
  @observable maxSkew: bigint; // underlying scale
  @observable maxPositionSize: bigint; // underlying scale
  @observable maxGain: bigint; // underlying scale

  // TODO : Turn to computables
  @observable liquidationFee: bigint;

  // Borrow asset constants
  @observable assetAId: string;
  @observable assetBId: string;

  // Pair State
  @observable openInterestShort: bigint;
  @observable openInterestLong: bigint;
  @observable fundingRate: bigint;

  // Accounts Stores
  @observable accountsMap: ObservableMap<string, SingleTradePairUserStore> =
    new ObservableMap<string, SingleTradePairUserStore>();

  constructor(
    private cryptoWalletConnectionStore: CryptoWalletConnectionStore,
    public ownLexStore: SingleLexStore,
    private tradPairParameters: ILeverageDimensionTradePairParameters,
  ) {
    makeObservable(this);

    void makePersistable(this, {
      name: `SingleLexStore_${ownLexStore.id}_${tradPairParameters.pairId}_lt`,
      properties: [
        // Numbers
        "feeId",
        "groupId",
        "minLeverage",
        "maxLeverage",
        "maxBorrowF",

        // Big Ints
        {
          key: "maxOpenInterestInUnits",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "maxSkew",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "maxPositionSize",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "maxGain",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 24 * 365 * 1000, // 1 year
      removeOnExpiration: true,
    });

    void makePersistable(this, {
      name: `SingleLexStore_${ownLexStore.id}_${tradPairParameters.pairId}_st`,
      properties: [
        // Big Ints
        {
          key: "openInterestShort",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "openInterestLong",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "fundingRate",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 2 * 1000, // 2 hours
      removeOnExpiration: true,
    });

    const { pairId, pairAssetBName, pairAssetAName } = tradPairParameters;

    this.pairId = pairId;
    this.pairName = `${pairAssetAName}-${pairAssetBName}`;
    this.assetAId = pairAssetAName;
    this.assetBId = pairAssetBName;

    this.groupId = 0;
    this.feeId = 0;

    this.minLeverage = 0;
    this.maxLeverage = 0;
    this.maxBorrowF = 0;

    this.maxOpenInterestInUnits = 0n;
    this.maxSkew = 0n;
    this.maxPositionSize = 0n;
    this.maxGain = 0n;
    this.liquidationFee = 0n;

    this.openInterestShort = 0n;
    this.openInterestLong = 0n;
    this.fundingRate = 0n;
  }

  // **** Account Stores ****

  @computed public get activeUserStore(): SingleTradePairUserStore {
    const activeAccountAddress = this.cryptoWalletConnectionStore.mainAddress;
    return this.getUserStore(activeAccountAddress);
  }

  public getUserStore(accountAddress: string): SingleTradePairUserStore {
    const accountStore = this.accountsMap.get(accountAddress);

    if (accountStore) {
      return accountStore;
    } else {
      return this.createAndStoreNewAccountStore(accountAddress);
    }
  }

  // **** State refresh from outside ****

  public updateWithPairConfigs(pairConfigs: TLexPairConfigsLensStruct) {
    // TODO : Add all configs to store
    this.setGroupId(pairConfigs.groupId);
    this.setFeeId(pairConfigs.feeId);

    this.setParamsFromPairConfigs(pairConfigs);
  }

  public updateWithPairState(pairState: TLexPairStateLensStruct) {
    this.setOpenInterestShort(pairState.openInterestShort);
    this.setOpenInterestLong(pairState.openInterestLong);
    this.setFundingRate(pairState.fundingRate);
  }

  public updateWithFreshTraderSpecificState(
    traderAddress: string,
    openTrades: TCompletePositionDataFromLens[],
    closedPositions: TClosedPositionGist[],
    cancelledPositions: TClosedPositionGist[],
  ) {
    const userStore = this.getUserStore(traderAddress);
    userStore.updateWithFreshTraderSpecificState(
      openTrades,
      closedPositions,
      cancelledPositions,
    );
  }

  // **** Common types extraction ****

  // **** Store state ****
  /**
   * We assume that if no bpToken address has been set, it means that the 'batch update' has not finished.
   */
  @computed
  public get hasBeenInitialized(): boolean {
    return this.pairName !== "";
  }

  // **** Pair ID ****
  @computed public get isCatMode(): boolean {
    return this.pairId > 10_000;
  }

  @computed public get isForex(): boolean {
    return this.pairId > 8_000 && this.pairId < 10_000;
  }

  // **** Pair state ****

  @computed
  public get openInterestShortInUnits(): number {
    return unitsBnToFloat(
      this.openInterestShort,
      this.ownLexStore.chipAssetParams.decimals,
    );
  }

  @computed
  public get openInterestLongInUnits(): number {
    return unitsBnToFloat(
      this.openInterestLong,
      this.ownLexStore.chipAssetParams.decimals,
    );
  }

  /**
   * Note : return value is the "funding paid by heavier side" in units per OI (heavier side) per second
   *        e.g : 0.01 = Paying (heavier) side (as a whole) pays 1% of funding per second for each OI unit
   */
  @computed
  public get fundingRateInUnitsPerSecond(): number {
    return precisionBnToFloat(this.fundingRate);
  }

  /**
   * Returns the amount in units paid each seconds by both sides.
   * Note : In case of 0 open interest in one side, or completely equal sides, no one pays.
   */
  @computed public get fundingAmountsPaidByBothSidesPerSecondInUnits(): {
    shortsPayPerSecondInUnits: number;
    longsPayPerSecondInUnits: number;
  } {
    const openInterestInUnitsForShort = this.openInterestShortInUnits;
    const openInterestInUnitsForLong = this.openInterestLongInUnits;

    const fundingRateInUnitsPerSecond = this.fundingRateInUnitsPerSecond;

    let shortsPayPerSecondInUnits = 0;
    let longsPayPerSecondInUnits = 0;

    if (openInterestInUnitsForShort > 0 && openInterestInUnitsForLong > 0) {
      // Longs pay
      if (openInterestInUnitsForLong > openInterestInUnitsForShort) {
        longsPayPerSecondInUnits =
          fundingRateInUnitsPerSecond * openInterestInUnitsForLong;
        shortsPayPerSecondInUnits = -longsPayPerSecondInUnits;
      }
      // Shorts pays
      else if (openInterestInUnitsForShort > openInterestInUnitsForLong) {
        shortsPayPerSecondInUnits =
          fundingRateInUnitsPerSecond * openInterestInUnitsForShort;
        longsPayPerSecondInUnits = -shortsPayPerSecondInUnits;
      }
    }

    return {
      shortsPayPerSecondInUnits,
      longsPayPerSecondInUnits,
    };
  }

  /**
   * Positive means that the shorts pay, negative means that the shorts receive
   */
  @computed
  public get fundingAprForShortPerSecondInUnits(): number {
    const { shortsPayPerSecondInUnits } =
      this.fundingAmountsPaidByBothSidesPerSecondInUnits;

    const unitsSpr =
      this.openInterestShortInUnits == 0
        ? 0
        : shortsPayPerSecondInUnits / this.openInterestShortInUnits;
    return unitsSpr;
  }

  @computed
  public get fundingAprForShortPerSecondInPercentage(): number {
    return this.fundingAprForShortPerSecondInUnits * 100;
  }

  @computed
  public get fundingAprForLongPerSecondInUnits(): number {
    const { longsPayPerSecondInUnits } =
      this.fundingAmountsPaidByBothSidesPerSecondInUnits;

    const unitsSpr =
      this.openInterestLongInUnits == 0
        ? 0
        : longsPayPerSecondInUnits / this.openInterestLongInUnits;
    return unitsSpr;
  }

  @computed
  public get fundingAprForLongPerSecondInPercentage(): number {
    return this.fundingAprForLongPerSecondInUnits * 100;
  }

  // **** Limits ****

  @computed public get groupObjectForPair(): TGroupConfigs {
    const groupsConfigurations = this.ownLexStore.groupsConfigurations;

    const matchingGroupConfig = groupsConfigurations.find(
      (group) => group.groupId == this.groupId,
    );

    if (matchingGroupConfig) {
      return {
        minLeverageInUnits: scaledLeverageToUnits(
          Number(matchingGroupConfig.minLeverage),
        ),
        maxLeverageInUnits: scaledLeverageToUnits(
          Number(matchingGroupConfig.maxLeverage),
        ),
        maxBorrowFInUnits: scaledFractionToUnits(
          matchingGroupConfig.maxBorrowF,
        ),
        maxPositionSizeInUnits: unitsBnToFloat(
          matchingGroupConfig.maxPositionSize as bigint,
          this.ownLexStore.chipAssetParams.decimals,
        ),
      };
    } else {
      console.error(`No group config found for ${this.pairName}`);
      return {
        maxBorrowFInUnits: 0,
        maxLeverageInUnits: 0,
        maxPositionSizeInUnits: 0,
        minLeverageInUnits: 0,
      };
    }

    // const definedConfigs =
    //   LIVE_GROUP_PARAMS[this.ownLexStore.engineChainId as TLiveChainIds][
    //     this.feeId
    //   ];
    //
    // if (definedConfigs) {
    //   return definedConfigs;
    // } else {
    //   return {
    //     maxBorrowFInUnits: 0,
    //     maxLeverageInUnits: 0,
    //     maxPositionSizeInUnits: 0,
    //     minLeverageInUnits: 0,
    //   };
    // }
  }

  @computed public get effectiveCap_minLeverageInUnits(): number {
    const ownMinLeverageInUnits = scaledLeverageToUnits(this.minLeverage);
    const groupConfigs = this.groupObjectForPair;

    return Math.max(ownMinLeverageInUnits, groupConfigs.minLeverageInUnits);
  }

  @computed public get effectiveCap_maxLeverageInUnits(): number {
    const ownMaxLeverageInUnits = scaledLeverageToUnits(this.maxLeverage);
    const groupConfigs = this.groupObjectForPair;

    return Math.min(ownMaxLeverageInUnits, groupConfigs.maxLeverageInUnits);
  }

  @computed public get effectiveCap_maxBorrowFInUnits(): number {
    const ownMaxBorrowFInUnits = scaledFractionToUnits(this.maxBorrowF);
    const groupConfigs = this.groupObjectForPair;

    return Math.min(ownMaxBorrowFInUnits, groupConfigs.maxBorrowFInUnits);
  }

  @computed public get effectiveCap_maxBorrowAmountInUnits(): number {
    const virtualBalanceForUtilizationInUnits =
      this.ownLexStore.virtualBalanceForUtilizationInUnits;
    return (
      virtualBalanceForUtilizationInUnits * this.effectiveCap_maxBorrowFInUnits
    );
  }

  /**
   * The amount of borrow that can be taken from the pool for this pair, in units
   */
  @computed public get effectiveCap_availableBorrowAmountInUnits(): number {
    const maxBorrowInUnits = this.effectiveCap_maxBorrowAmountInUnits ?? 0;
    const totalBorrowsInUnits = this.ownLexStore.totalBorrowsInUnits;

    const poolBorrowCapacityInUnits = Math.max(
      maxBorrowInUnits - totalBorrowsInUnits,
      0,
    );

    return poolBorrowCapacityInUnits;
  }

  @computed public get effectiveCap_maxOpenInterestInUnits(): number {
    return unitsBnToFloat(
      this.maxOpenInterestInUnits,
      this.ownLexStore.chipAssetParams.decimals,
    );
  }

  @computed public get effectiveCap_maxSkewInUnits(): number {
    return unitsBnToFloat(
      this.maxSkew,
      this.ownLexStore.chipAssetParams.decimals,
    );
  }

  @computed public get effectiveCap_maxPositionSizeInUnits(): number {
    const ownMaxPositionSizeInUnits = unitsBnToFloat(
      this.maxPositionSize,
      this.ownLexStore.chipAssetParams.decimals,
    );
    const groupConfigs = this.groupObjectForPair;

    return Math.min(
      ownMaxPositionSizeInUnits,
      groupConfigs.maxPositionSizeInUnits,
    );
  }

  @computed public get effectiveCap_maxGainInUnits(): number {
    return unitsBnToFloat(
      this.maxGain,
      this.ownLexStore.chipAssetParams.decimals,
    );
  }

  // **** Fees ****

  @computed public get feeObjectForPair(): TFeeConfigs {
    // const definedConfigs =
    //   LIVE_FEES_PARAMS[this.ownLexStore.engineChainId as TLiveChainIds][
    //     this.feeId
    //   ];

    const feeConfig = this.ownLexStore.getFeeConfigById(this.feeId);

    if (feeConfig) {
      return {
        openFeeFInUnits: scaledFractionToUnits(feeConfig.openFeeF),
        closeFeeFInUnits: scaledFractionToUnits(feeConfig.closeFeeF),
      };
    } else {
      return {
        openFeeFInUnits: 0,
        closeFeeFInUnits: 0,
      };
    }
  }

  @computed public get openFeeFInUnits(): number {
    return this.feeObjectForPair.openFeeFInUnits;
  }

  @computed public get closeFeeFInUnits(): number {
    return this.feeObjectForPair.closeFeeFInUnits;
  }

  @computed public get liquidationFeeInUnits(): number {
    return precisionBnToFloat(this.liquidationFee);
  }

  // **** Params ****

  // **** Complex setters ****

  @action("createAndStoreNewAccountStore")
  private createAndStoreNewAccountStore(
    accountAddress: string,
  ): SingleTradePairUserStore {
    const singleTradePairUserStore = new SingleTradePairUserStore(
      this,
      accountAddress,
    );
    this.accountsMap.set(accountAddress, singleTradePairUserStore);

    return this.accountsMap.get(accountAddress)!;
  }

  // **** setters ****

  @action("setGroupId")
  private setGroupId(groupId: number) {
    this.groupId = groupId;
  }

  @action("setFeeId")
  private setFeeId(feeId: number) {
    this.feeId = feeId;
  }

  @action("setParamsFromPairConfigs")
  private setParamsFromPairConfigs(pairConfigs: TLexPairConfigsLensStruct) {
    this.minLeverage = Number(pairConfigs.minLeverage);
    this.maxLeverage = Number(pairConfigs.maxLeverage);
    this.maxBorrowF = Number(pairConfigs.maxBorrowF);
    this.maxOpenInterestInUnits = pairConfigs.maxOpenInterest;
    this.maxSkew = pairConfigs.maxSkew;
    this.maxPositionSize = pairConfigs.maxPositionSize;
    this.maxGain = pairConfigs.maxGain;
  }

  @action("setOpenInterestShort")
  private setOpenInterestShort(ois: bigint) {
    this.openInterestShort = ois;
  }

  @action("setOpenInterestLong")
  private setOpenInterestLong(oil: bigint) {
    this.openInterestLong = oil;
  }

  @action("setFundingRate")
  private setFundingRate(fundingRate: bigint) {
    this.fundingRate = fundingRate;
  }
}
