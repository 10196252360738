// export const ACTION_DELEGATION_URL = "http://35.238.171.45";
import {
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_FANTOM_OPERA,
  TEngineChainIds,
} from "./chainConstants.ts";

export const BAAS_INTENT_REQUEST_BUILD_INFO_URL =
  "https://request-getintentsrequestbuildinfo-multichain-6yd6gzuiuq-uc.a.run.app";

export const ACTION_TRADE_INTENTS_DELEGATION_URL_BY_ENGINE_CHAIN_ID: Record<
  TEngineChainIds,
  {
    tradeIntentsDelegationUrl: string;
    liquidityIntentsDelegationUrl: string;
    chipsIntentsDelegationUrl: string;
  }
> = {
  [CHAIN_ID_FANTOM_OPERA]: {
    tradeIntentsDelegationUrl:
      "https://request-delegatetradingintent-fantomopera-6yd6gzuiuq-uc.a.run.app",
    liquidityIntentsDelegationUrl:
      "https://request-delegateliquidityintent-fantomopera-6yd6gzuiuq-uc.a.run.app",
    chipsIntentsDelegationUrl:
      "https://request-delegatechipsintent-fantomopera-6yd6gzuiuq-uc.a.run.app",
  },

  [CHAIN_ID_BOBA_MAINNET]: {
    tradeIntentsDelegationUrl:
      "https://request-delegatetradingintent-boba-6yd6gzuiuq-uc.a.run.app",
    liquidityIntentsDelegationUrl:
      "https://request-delegateliquidityintent-boba-6yd6gzuiuq-uc.a.run.app",
    chipsIntentsDelegationUrl:
      "https://request-delegatechipsintent-boba-6yd6gzuiuq-uc.a.run.app",
  },
};

export type TActionInformingEndpoints = {
  marketOpenInformUrl: string;
  marketCloseInformUrl: string;
  updateSingleFieldInformUrl: string;
  updateDoubleFieldInformUrl: string;
};

export const ACTION_INFORMING_ENDPOINTS_BY_ENGINE_CHAIN_ID: Record<
  TEngineChainIds,
  TActionInformingEndpoints
> = {
  [CHAIN_ID_FANTOM_OPERA]: {
    marketOpenInformUrl:
      "https://appinteraction-informopenmarket-fantomopera-6yd6gzuiuq-uc.a.run.app",
    marketCloseInformUrl:
      "https://appinteraction-informclosemarket-fantomopera-6yd6gzuiuq-uc.a.run.app",
    updateSingleFieldInformUrl: "",
    updateDoubleFieldInformUrl: "",
  },

  [CHAIN_ID_BOBA_MAINNET]: {
    marketOpenInformUrl:
      "https://appinteraction-informopenmarket-boba-6yd6gzuiuq-uc.a.run.app",
    marketCloseInformUrl:
      "https://appinteraction-informclosemarket-boba-6yd6gzuiuq-uc.a.run.app",
    updateSingleFieldInformUrl: "",
    updateDoubleFieldInformUrl: "",
  },
};

export type TGeneralBaasInteractionsEndpoints = {
  timeoutMarketOpenOrderUrl: string;
  timeoutMarketCloseOrderUrl: string;
  pullAllAndClaimForUrl: string;
};

export const GENERAL_BAAS_INTERACTION_ENDPOINTS_BY_ENGINE_CHAIN_ID: Record<
  TEngineChainIds,
  TGeneralBaasInteractionsEndpoints
> = {
  [CHAIN_ID_FANTOM_OPERA]: {
    timeoutMarketOpenOrderUrl:
      "https://appinteraction-timeoutmarketopen-fantomopera-6yd6gzuiuq-uc.a.run.app",
    timeoutMarketCloseOrderUrl:
      "https://appinteraction-timeoutmarketclose-fantomopera-6yd6gzuiuq-uc.a.run.app",
    pullAllAndClaimForUrl:
      "https://appinteraction-pullallandclaimfor-fantomopera-6yd6gzuiuq-uc.a.run.app",
  },

  [CHAIN_ID_BOBA_MAINNET]: {
    timeoutMarketOpenOrderUrl:
      "https://appinteraction-timeoutmarketopen-boba-6yd6gzuiuq-uc.a.run.app",
    timeoutMarketCloseOrderUrl:
      "https://appinteraction-timeoutmarketclose-boba-6yd6gzuiuq-uc.a.run.app",
    pullAllAndClaimForUrl:
      "https://appinteraction-pullallandclaimfor-boba-6yd6gzuiuq-uc.a.run.app",
  },
};

export const BAAS_INCENTIVES_ENDPOINTS__FANTOM = {
  getAllLiveIncentivePlans:
    "https://incentivesserver-fantom-6yd6gzuiuq-uc.a.run.app/livePlans",
  getAllPastIncentivePlans:
    "https://incentivesserver-fantom-6yd6gzuiuq-uc.a.run.app/pastPlans",
  pastCompetitionResultsUrl:
    "https://incentivesserver-fantom-6yd6gzuiuq-uc.a.run.app/results",
  liveCompetitionResultsUrl:
    "https://incentivesserver-fantom-6yd6gzuiuq-uc.a.run.app/liveResults",
};

// TODO : Critical : Fix the 'multi chain' to actually be multi chain
export const BAAS_INCENTIVES_ENDPOINTS__BOBA = {
  getAllLiveIncentivePlans:
    "https://incentivesserver-boba-6yd6gzuiuq-uc.a.run.app/livePlans",
  getAllPastIncentivePlans:
    "https://incentivesserver-boba-6yd6gzuiuq-uc.a.run.app/pastPlans",
  pastCompetitionResultsUrl:
    "https://incentivesserver-boba-6yd6gzuiuq-uc.a.run.app/results",
  liveCompetitionResultsUrl:
    "https://incentivesserver-boba-6yd6gzuiuq-uc.a.run.app/liveResults",
};
