import { EoaActionTypes } from "../types/eoaActionTypes.ts";
import {
  CHAIN_ID_FANTOM_OPERA,
  TChainIds,
} from "../constants/chainConstants.ts";

export function useActionType(chainId: TChainIds): EoaActionTypes {
  if (chainId === CHAIN_ID_FANTOM_OPERA) {
    return EoaActionTypes.DIRECT;
  } else {
    return EoaActionTypes.SIGNED;
  }

  // return chainId === CHAIN_ID_BOBA_MAINNET
  //   ? EoaActionTypes.SIGNED
  //   : EoaActionTypes.DIRECT;
  // NOTE : All will be SIGNED for now
  // return EoaActionTypes.SIGNED;
}
