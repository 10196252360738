import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import { useLocation } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import {
  QUERY_PARAMS_KEYS,
  useUrlQueryValue_chainId,
} from "../../../navigation/navigationStateHooks.ts";
import {
  AppHeaderStructure,
  DepositButton,
  createFilteredQueryString,
} from "lynx-ui-components";
import { LynxLogo } from "../../Logos/LynxLogo.tsx";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useRequestWalletConnect } from "../../../hooks/walletConnectionHooks.ts";
import {
  useCryptoWalletIntegrationStore,
  useModalsStore,
} from "../../../store/storeHooks.ts";
import { useWhiteLabelIdentifier } from "../../../whitelabels/whiteLabelHooks.ts";
import { CHAIN_TO_SA_TO_LED_MAP } from "../../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { ILeverageDimensionParameters } from "../../../services/leverageDimensionService/ILeverageDiomensionsService.ts";
import { useAccount } from "wagmi";
import { ACTIVE_CHAIN_IDS, TActiveChainIds } from "../../../chainsConfigs.ts";
import { TWhiteLabelName } from "../../../whitelabels/whiteLabelTypes.ts";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
  logoSpan_boba: {
    height: `100%`,
    width: `100%`,
    backgroundImage: `url(/assets/exotix/logo_on_dark_powered_by.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "transparent",
  },
  logoSpan_solidLizard: {
    height: `100%`,
    width: `10rem`,
    backgroundImage: `url(/assets/wl/solidlizard_logo.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "transparent",

    [theme.breakpoints.down("md")]: {
      width: `100%`,
      backgroundSize: "contain",
    },
  },
  logoSpan_spiritSwap: {
    // NOTE : 110% is to ensure the logo will be displayed entirely as it is a bit bigger than the container
    height: `40px`,
    width: `180px`,
    backgroundImage: `url(/assets/wl/spiritswap_logo.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "transparent",

    border: "1px solid transparent",

    [theme.breakpoints.down("md")]: {
      width: `100%`,
      backgroundSize: "contain",
    },
  },
  logoSpan_ionic: {
    height: `100%`,
    width: `8rem`,
    backgroundImage: `url(/assets/wl/ionic_logo.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "transparent",

    [theme.breakpoints.down("md")]: {
      width: `100%`,
      backgroundSize: "contain",
    },
  },
  logoSpan_usdfi: {
    height: `3.238rem`,
    width: `12rem`,
    marginRight: "0.5rem",
    backgroundImage: `url(/assets/wl/usdfi_logo.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "transparent",

    [theme.breakpoints.down("md")]: {
      width: `100%`,
      backgroundSize: "contain",
    },
  },

  logoLink: {
    width: "100%",
    minWidth: "6rem",

    height: `2rem`,

    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    transition: "200ms",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

type THeaderLinkPayload = {
  text: string;
  linkTo: string;
  isActive: boolean;
  queryParamsToKeep: string[];
};

export const ConnectedHeader: React.FunctionComponent<IProps> = observer(
  (props) => {
    const { classes } = useStyles();

    const shouldDepositModal = useRef(false);

    const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();

    const location = useLocation();

    const modalStore = useModalsStore();

    const requestConnectToWallet = useRequestWalletConnect();

    const currentPathName = location.pathname;

    const isLeveragePage = currentPathName.includes("/leverage");

    const headerLinkPayloads = useMemo<THeaderLinkPayload[]>(() => {
      const payloads: THeaderLinkPayload[] = [
        {
          text: "Trade",
          linkTo: "leverage",
          isActive: isLeveragePage,
          queryParamsToKeep: [
            QUERY_PARAMS_KEYS.chainId,
            QUERY_PARAMS_KEYS.settlementAsset,
            QUERY_PARAMS_KEYS.referral,
          ],
        },
        {
          text: "Liquidity",
          linkTo: "pools",
          isActive: currentPathName.includes("/pools"),
          queryParamsToKeep: [
            QUERY_PARAMS_KEYS.chainId,
            QUERY_PARAMS_KEYS.settlementAsset,
            QUERY_PARAMS_KEYS.referral,
          ],
        },
        {
          text: "Portfolio",
          linkTo: "portfolio",
          isActive: currentPathName.includes("/portfolio"),
          queryParamsToKeep: [
            QUERY_PARAMS_KEYS.chainId,
            QUERY_PARAMS_KEYS.settlementAsset,
            QUERY_PARAMS_KEYS.referral,
          ],
        },

        {
          text: "Rewards",
          linkTo: "rewards",
          isActive: currentPathName.includes("/rewards"),
          queryParamsToKeep: [
            QUERY_PARAMS_KEYS.chainId,
            QUERY_PARAMS_KEYS.settlementAsset,
            QUERY_PARAMS_KEYS.referral,
          ],
        },
        {
          text: "Competition",
          linkTo: "leaderboard",
          isActive: currentPathName.toLowerCase().includes("/leaderboard"),
          queryParamsToKeep: [
            QUERY_PARAMS_KEYS.chainId,
            QUERY_PARAMS_KEYS.settlementAsset,
            QUERY_PARAMS_KEYS.referral,
          ],
        },

        // {
        //   text: "Competitions",
        //   linkTo: "competitions",
        //   isActive: currentPathName.startsWith("/competitions"),
        //   queryParamsToKeep: [QUERY_PARAMS_KEYS.chainId],
        // },
      ];

      return payloads;
    }, [currentPathName, isLeveragePage]);

    const {
      isOnAnyWhiteLabel,
      whiteLabelName,
      whiteLabelParams,
      whiteLabelRoutePrefix,
    } = useWhiteLabelIdentifier();

    const [searchParams, setSearchParams] = useSearchParams();

    const logoClassByWhiteLabel = useMemo(() => {
      const classedByWhiteLabel: Record<TWhiteLabelName, string> = {
        solidLizard: classes.logoSpan_solidLizard,
        spiritSwap: classes.logoSpan_spiritSwap,
        ionic: classes.logoSpan_ionic,
        usdfi: classes.logoSpan_usdfi,
      };

      return classedByWhiteLabel;
    }, [
      classes.logoSpan_ionic,
      classes.logoSpan_solidLizard,
      classes.logoSpan_spiritSwap,
      classes.logoSpan_usdfi,
    ]);

    const logoLinkComponent = useMemo(() => {
      const logoLink = {
        target: "_self",
        link: "/leverage",
      };

      const matchingSearchParamsString = createFilteredQueryString(
        searchParams,
        [
          QUERY_PARAMS_KEYS.chainId,
          QUERY_PARAMS_KEYS.settlementAsset,
          QUERY_PARAMS_KEYS.referral,
        ],
      );

      if (isOnAnyWhiteLabel) {
        const logoClass =
          logoClassByWhiteLabel[whiteLabelName as TWhiteLabelName] ?? "";

        return (
          <Link
            className={classes.logoLink}
            to={{
              pathname: `/${whiteLabelRoutePrefix}${logoLink.link}`,
              search: matchingSearchParamsString,
            }}
            target={logoLink.target}
          >
            <div className={logoClass} />
          </Link>
        );
      } else {
        return (
          <Link
            className={classes.logoLink}
            to={{ pathname: logoLink.link }}
            target={logoLink.target}
          >
            <LynxLogo />
          </Link>
        );
      }
    }, [
      classes.logoLink,
      isOnAnyWhiteLabel,
      logoClassByWhiteLabel,
      searchParams,
      whiteLabelName,
      whiteLabelRoutePrefix,
    ]);

    const { open, selectedNetworkId } = useWeb3ModalState();

    // console.log({
    //   selectedNetworkId,
    //   open,
    // });
    const { queryChainId, setQueryChainId } = useUrlQueryValue_chainId();

    const showDepositModal = useCallback(() => {
      const activeTradePair = Object.values(
        CHAIN_TO_SA_TO_LED_MAP[cryptoWalletConnectionStore.chainId],
      ) as ILeverageDimensionParameters[];
      const activeChainId = activeTradePair?.[0]?.id;
      if (!activeChainId) {
        return;
      }
      if (!cryptoWalletConnectionStore.isConnectedToWallet) {
        requestConnectToWallet();
        shouldDepositModal.current = true;
      } else {
        modalStore.showChipInModal(activeChainId);
        shouldDepositModal.current = false;
      }
    }, [
      cryptoWalletConnectionStore.chainId,
      cryptoWalletConnectionStore.isConnectedToWallet,
      modalStore,
      requestConnectToWallet,
    ]);

    // TODO : Move this to main app page ?
    useEffect(() => {
      if (!cryptoWalletConnectionStore.isConnectedToWallet) {
        if (
          selectedNetworkId &&
          queryChainId !== selectedNetworkId.toString()
        ) {
          setQueryChainId(selectedNetworkId.toString());
        }
      } else {
        if (shouldDepositModal.current) {
          showDepositModal();
        }
      }
    }, [
      cryptoWalletConnectionStore.isConnectedToWallet,
      queryChainId,
      selectedNetworkId,
      setQueryChainId,
      showDepositModal,
    ]);

    const depositButton = useMemo(
      () => <DepositButton text="Deposit" onClick={showDepositModal} />,
      [showDepositModal],
    );

    const { chainId } = useAccount();
    const isConnectedToASupportedChain = ACTIVE_CHAIN_IDS.includes(
      chainId as TActiveChainIds,
    );

    return (
      <AppHeaderStructure
        logoComponent={logoLinkComponent}
        headerLinkPayloads={headerLinkPayloads}
        walletButtonComponent={<w3m-button balance={"hide"} />}
        chainButtonComponent={
          isConnectedToASupportedChain ? <w3m-network-button /> : <></>
        }
        depositButton={depositButton}
        // @ts-ignore
        LinkComponent={Link}
        useSearchParamsFunction={useSearchParams}
      />
    );
  },
);
