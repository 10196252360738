import { makeStyles } from "tss-react/mui";
import { ModalTheme } from "../../../theme/customTheme";
import { Theme } from "@mui/material";

export const TOP_DISTANCE_REM = 0.5;
const BORDER_RADIUS_REM = 1.5;

export function useModalClassesForActiveLeD(modalTheme: ModalTheme) {
  const modifiedCommonModalClasses = useCommonModalClasses(modalTheme);
  return modifiedCommonModalClasses;
}

export const useCommonModalClasses = makeStyles<ModalTheme>()(
  (theme: Theme, prp) => ({
    modal: {
      transition: "height 2000ms ease",
    },
    dialogBasePaper: {
      backgroundColor: "transparent",

      // Should match the 'panel' radius
      borderRadius: `${BORDER_RADIUS_REM}rem`,
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px",
      },
    },
    panelHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",

      padding: `${TOP_DISTANCE_REM * 2}rem`,

      color: prp.textColor,
      backgroundColor: prp.backgroundColor,
      userSelect: "none",

      // Mobile
      [theme.breakpoints.down("sm")]: {},
    },
    panel: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "25.5rem",
      maxWidth: "100%",
      maxHeight: "100%",

      // DEV_NOTE : Prevents the appearing of a scroll bar for a moment on big screens
      overflowY: "auto",

      borderRadius: `${BORDER_RADIUS_REM}rem`,

      // Mobile
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px",
        overflowY: "auto",
        // DEV_NOTE : To match the 'full screen'
        width: "100%",
        height: "100%",
      },
    },

    headerTokenIcon: {
      marginRight: "0.75rem",
      width: "2.5rem",
      height: "2.5rem",
      position: "fixed",
      left: 0,
      transition: "200ms",
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    headerXButton: {
      color: prp.textColor,
    },

    upperRightCorner: {
      position: "absolute",
      // right: `${TOP_DISTANCE_REM / 2}rem`,
      right: `${BORDER_RADIUS_REM / 2}rem`,
      top: `${TOP_DISTANCE_REM}rem`,
      // Mobile
      [theme.breakpoints.down("sm")]: {
        // right: `${TOP_DISTANCE_REM / 8}rem`,
        right: `${BORDER_RADIUS_REM / 8}rem`,
        top: `${TOP_DISTANCE_REM / 8}rem`,
      },
    },
    upperLeftCorner: {
      position: "absolute",
      // left: `${TOP_DISTANCE_REM}rem`,
      left: `${BORDER_RADIUS_REM / 2}rem`,
      top: `${TOP_DISTANCE_REM}rem`,
      // Mobile
      [theme.breakpoints.down("sm")]: {
        // right: `${TOP_DISTANCE_REM / 8}rem`,
        left: `${BORDER_RADIUS_REM / 4}rem`,
        top: `${TOP_DISTANCE_REM}rem`,
      },
    },

    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      gap: "1rem",
      textAlign: "center",

      paddingTop: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",

      backgroundColor: prp.backgroundColor,
      color: prp.messageColor,

      // Mobile
      [theme.breakpoints.down("sm")]: {
        // DEV_NOTE : To match the 'full screen'
        // TODO : Move this somewhere else
        flex: 1,

        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
      },
    },

    modalFooter: {
      display: "flex",
      flexDirection: "column",

      gap: "1.25rem",
      color: prp.textColor,
      backgroundColor: prp.backgroundColor,
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  }),
);
