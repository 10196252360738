type TNumericalScaleUnit = "M" | "K" | "";
type TSumInScale = {
  scaleUnit: TNumericalScaleUnit;
  units: number;
  unitsForDisplay: string;
};

export function amountToSumInScale(
  amount: number,
  displayDecimals = 4,
): TSumInScale {
  let scaleUnit: TNumericalScaleUnit = "M";
  let scaleDivider = 1_000_000;

  // TODO : Play with this threshold
  if (amount < 500_000) {
    scaleUnit = "K";
    scaleDivider = 1_000;
  }

  // For low numbers
  if (amount < 1000) {
    scaleUnit = "";
    scaleDivider = 1;
  }

  const units = amount / scaleDivider;

  const sumInScale: TSumInScale = {
    scaleUnit,
    units,
    unitsForDisplay: numericalDisplay(units, displayDecimals),
  };

  return sumInScale;
}

export function numericalDisplay(value: number, decimals: number): string {
  const factor = Math.pow(10, decimals);
  const roundedValue = Math.floor(value * factor) / factor;

  return parseFloat(roundedValue.toFixed(decimals)).toLocaleString(undefined, {
    maximumFractionDigits: decimals,
  });
}

export function formatHexString(hexString: string, displayLength = 4): string {
  if (!hexString?.trim()) {
    return "";
  }
  if (!/^0x[a-fA-F0-9]+$/.test(hexString)) {
    throw new Error("Invalid hexadecimal address");
  }

  if (displayLength * 2 >= hexString.length) {
    return hexString;
  } else {
    const prefix = hexString.substring(0, displayLength);
    const suffix = hexString.substring(hexString.length - displayLength);

    return `${prefix}...${suffix}`;
  }
}
