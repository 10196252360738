export type TClosedPositionGist = {
  entityId: string;
  positionId: string;

  requestOpenTx: string;

  openTimestamp: number;
  openTx: string;
  openedAs: string;
  openPrice: bigint;

  openFeeForSystem: bigint;
  openFeeForLexPool: bigint;

  isMarketPriceRangeCancelled: boolean;
  cancellationPriceOnMarketOpen: bigint;
  isOpenCapCancelled: boolean;
  cancellationCapType: string;
  cancellationCapValue: bigint;
  isMarketTimeoutCancelled: boolean;

  openOrderCancellationSourceRole: string;
  cancellationTx: string;

  canceller: string;
  openOrderCancellationFee: bigint;

  tp: bigint;
  tpLastUpdate: number;
  sl: bigint;
  slLastUpdate: number;

  requestCloseTx: string;

  borrowInterestPaidOnClose: bigint;
  fundingFeesPaidOnClose: bigint;

  closeTimestamp: number;
  closeTx: string;
  closedAs: string;
  closePrice: bigint;
  closeFee: bigint;

  settlementAsset: string;
  traderAddress: string;
  pairId: number;
  positionIndex: number;
  sameIdCounter: number;

  long: boolean;

  leverageInUnits: number;

  initialCollateral: bigint;
  positionValueOnClose: bigint;

  percentProfit: bigint;
};

export type TEpochGraphInfo = {
  poolAddress: string;

  epochNumber: number;

  timestamp: number;
  blockNumber: number;

  exchangeRate: bigint;

  amountDeposited: bigint;
  amountRedeemed: bigint;
};

export type TChipHistoryActionGist = {
  userAddress: string;
  timestamp: number;
  txHash: string;

  inOut: "IN" | "OUT";

  amount: bigint;
  fee: bigint;
};

export type TLiquidityRequestGist = {
  poolAddress: string;
  providerAddress: string;
  type: "DEPOSIT" | "WITHDRAW";

  processEpoch: number;
  processTimestamp: number;
  processTxHash: string;

  assetAmount: bigint;

  // Only relevant to deposits
  isImmediate: boolean;
};

export type TUsageRoundGraphInfo = {
  poolAddress: string;
  roundNumber: number;

  startBlock: number;

  startTimestamp: number;
  endTimestamp: number;

  positionsOpenedCounter: number;

  totalOpenFeesPaid: bigint;
  totalCloseFeesPaid: bigint;

  totalPositivePnl: bigint;
  totalNegativePnl: bigint;
};

export type TUsageRoundTraderGraphInfo = {
  entityId: string;
  traderAddress: string;
  roundNumber: number;

  openedTradesCounter: number;

  totalOpenFeesPaid: bigint;
  totalCloseFeesPaid: bigint;

  totalPositivePnl: bigint;
  totalNegativePnl: bigint;
};

export type TUserInteractionsRecordGraphInfo = {
  poolAddress: string;
  userAddress: string;

  // ## Position Interactions ##

  latestTimestamp_PositionOpened: number;
  interactionEntityId_PositionOpened: string;

  latestTimestamp_PositionClosed: number;
  interactionEntityId_PositionClosed: string;

  latestTimestamp_MarketOpenCancelled: number;
  interactionEntityId_MarketOpenCancelled: string;

  latestTimestamp_MarketCloseCancelled: number;
  interactionEntityId_MarketCloseCancelled: string;

  // ## Pool Interactions ##

  latestTimestamp_PoolImmediateDeposit: number;
  interactionEpochNumber_PoolImmediateDeposit: number;

  latestTimestamp_PoolEpochDepositProcessed: number;
  interactionEpochNumber_PoolEpochDepositProcessed: number;

  latestTimestamp_PoolEpochRedeemProcessed: number;
  interactionEpochNumber_PoolEpochRedeemProcessed: number;

  // ## Chip Interactions ##

  latestTimestamp_ChipIn: number;
  interactionTx_ChipIn: string;

  latestTimestamp_ChipOut: number;
  interactionTx_ChipOut: string;
};

export type TPoolCompetitionHistoricReward = {
  poolAddress: string;
  rewardAddress: string;
  totalAmount: bigint;
};

export interface IGraphQlService {
  // ****** Positions ******

  // getDoneTradesForTrader(traderAddress: string): any[];
  getClosedPositionsForTrader(
    settlementAsset: string,
    traderAddress: string,
  ): Promise<TClosedPositionGist[]>;

  getCancelledPositionsForTrader(
    settlementAsset: string,
    traderAddress: string,
  ): Promise<TClosedPositionGist[]>;

  // ****** Epochs ******

  getFirstPoolEpochAfterTimestamp(
    poolAddress: string,
    timestamp: number,
  ): Promise<TEpochGraphInfo>;

  // ****** Chip Actions ******

  getChipActionHistory(
    chipAddress: string,
    userAddress: string,
  ): Promise<TChipHistoryActionGist[]>;

  // ****** Liquidity Requests ******

  getProcessedLiquidityRequests(
    poolAddress: string,
    providerAddress: string,
  ): Promise<TLiquidityRequestGist[]>;

  // ************* Interaction Records *************

  getAllInteractionRecordsForUser(
    userAddress: string,
  ): Promise<TUserInteractionsRecordGraphInfo[]>;

  // ************* Usage Rounds *************

  getLatestUsageRoundForPool(
    poolAddress: string,
  ): Promise<TUsageRoundGraphInfo>;

  getUsageRoundForPool(
    poolAddress: string,
    roundNumber: number,
  ): Promise<TUsageRoundGraphInfo>;

  getUsageRoundTrader(
    poolAddress: string,
    traderAddress: string,
    roundNumber: number,
  ): Promise<TUsageRoundTraderGraphInfo>;

  // ************* Competitions *************

  getCompetitionsHistoricDistributionValues(): Promise<
    TPoolCompetitionHistoricReward[]
  >;
}

export const EMPTY_CLOSED_POSITION_GIST: TClosedPositionGist = {
  borrowInterestPaidOnClose: 0n,
  cancellationCapType: "",
  cancellationCapValue: 0n,
  cancellationPriceOnMarketOpen: 0n,
  cancellationTx: "",
  canceller: "",
  closePrice: 0n,
  closeTimestamp: 0,
  closeTx: "",
  closedAs: "",
  closeFee: 0n,
  entityId: "",
  fundingFeesPaidOnClose: 0n,
  initialCollateral: 0n,
  isMarketPriceRangeCancelled: false,
  isOpenCapCancelled: false,
  isMarketTimeoutCancelled: false,
  leverageInUnits: 0,
  long: false,
  openOrderCancellationFee: 0n,
  openOrderCancellationSourceRole: "",
  openPrice: 0n,
  openTimestamp: 0,
  openTx: "",
  openedAs: "",
  pairId: 0,
  percentProfit: 0n,
  positionId: "",
  positionIndex: 0,
  positionValueOnClose: 0n,
  requestCloseTx: "",
  requestOpenTx: "",
  sameIdCounter: 0,
  settlementAsset: "",
  traderAddress: "",

  tp: 0n,
  tpLastUpdate: 0,
  sl: 0n,
  slLastUpdate: 0,

  openFeeForSystem: 0n,
  openFeeForLexPool: 0n,
};

export const EMPTY_GRAPH_INFO_EPOCH: TEpochGraphInfo = {
  poolAddress: "",
  epochNumber: 0,
  timestamp: 0,
  blockNumber: 0,
  exchangeRate: 0n,
  amountDeposited: 0n,
  amountRedeemed: 0n,
};

export const EMPTY_GRAPH_INFO_USAGE_ROUND: TUsageRoundGraphInfo = {
  poolAddress: "",
  roundNumber: 0,
  startBlock: 0,
  startTimestamp: 0,
  endTimestamp: 0,
  positionsOpenedCounter: 0,
  totalOpenFeesPaid: 0n,
  totalCloseFeesPaid: 0n,
  totalPositivePnl: 0n,
  totalNegativePnl: 0n,
};

export const EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER: TUsageRoundTraderGraphInfo = {
  entityId: "",
  roundNumber: 0,
  traderAddress: "",
  openedTradesCounter: 0,
  totalOpenFeesPaid: 0n,
  totalCloseFeesPaid: 0n,
  totalPositivePnl: 0n,
  totalNegativePnl: 0n,
};

export const EMPTY_GRAPH_INFO_USER_INTERACTIONS_RECORD: TUserInteractionsRecordGraphInfo =
  {
    poolAddress: "",
    userAddress: "",
    latestTimestamp_PositionOpened: 0,
    interactionEntityId_PositionOpened: "",
    latestTimestamp_PositionClosed: 0,
    interactionEntityId_PositionClosed: "",
    latestTimestamp_MarketOpenCancelled: 0,
    interactionEntityId_MarketOpenCancelled: "",
    latestTimestamp_MarketCloseCancelled: 0,
    interactionEntityId_MarketCloseCancelled: "",
    latestTimestamp_PoolImmediateDeposit: 0,
    interactionEpochNumber_PoolImmediateDeposit: 0,
    latestTimestamp_PoolEpochDepositProcessed: 0,
    interactionEpochNumber_PoolEpochDepositProcessed: 0,
    latestTimestamp_PoolEpochRedeemProcessed: 0,
    interactionEpochNumber_PoolEpochRedeemProcessed: 0,
    latestTimestamp_ChipIn: 0,
    interactionTx_ChipIn: "",
    latestTimestamp_ChipOut: 0,
    interactionTx_ChipOut: "",
  };

export const EMPTY_GRAPH_INFO_POOL_COMPETITION_HISTORIC_REWARD: TPoolCompetitionHistoricReward =
  {
    poolAddress: "",
    rewardAddress: "",
    totalAmount: 0n,
  };
