import { useLeverageDimensionsStore } from "../../../store/storeHooks.ts";
import {
  SingleLexStore,
  TPendingEpochDepositComplete,
  TPendingEpochRedeemComplete,
} from "../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import { TLiquidityRequestGist } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { TLiquidityHistoryTableRowGist } from "./tables/LiquidityActionsHistoryTable.tsx";
import { unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { TEngineChainIds } from "../../../constants/chainConstants.ts";
import { LeverageDimensionsStore } from "../../../store/singleInstanceStores/LeverageDimensionsStore.ts";

// TODO : Make theses hooks use memoization

export function useGetPendingEpochDepositsForHolderOnActiveChain(
  holderAddress: string,
): TPendingEpochDepositComplete[] {
  const leverageDimensionsStore = useLeverageDimensionsStore();

  const pendingEpochDeposits: TPendingEpochDepositComplete[] = [];

  for (const leverageDimensionStore of leverageDimensionsStore.allLexStoresInActiveChain) {
    const userStore = leverageDimensionStore.getUserStore(holderAddress);

    pendingEpochDeposits.push(...userStore.account_pendingEpochDeposits);
  }

  return pendingEpochDeposits;
}

export function useGetPendingEpochRedeemsForHolderOnActiveChain(
  holderAddress: string,
): TPendingEpochRedeemComplete[] {
  const leverageDimensionsStore = useLeverageDimensionsStore();

  const pendingEpochRedeems: TPendingEpochRedeemComplete[] = [];

  for (const leverageDimensionStore of leverageDimensionsStore.allLexStoresInActiveChain) {
    const userStore = leverageDimensionStore.getUserStore(holderAddress);

    pendingEpochRedeems.push(...userStore.account_pendingEpochRedeems);
  }

  return pendingEpochRedeems;
}

export function useGetLiquidityHistoryTableRowGistsForHolderOnActiveChain(
  holderAddress: string,
): TLiquidityHistoryTableRowGist[] {
  const leverageDimensionsStore = useLeverageDimensionsStore();
  const leverageDimensionsStores =
    leverageDimensionsStore.allLexStoresInActiveChain;

  const processedLiquidityRequests: TLiquidityHistoryTableRowGist[] =
    extractLiquidityHistoryTableRowsFromLexStores(
      holderAddress,
      leverageDimensionsStores,
    );

  return processedLiquidityRequests;
}

export function useGetLiquidityHistoryTableRowGistsForHolderOnAllChains(
  holderAddress: string,
): TLiquidityHistoryTableRowGist[] {
  const leverageDimensionsStore = useLeverageDimensionsStore();
  const leverageDimensionsStores = leverageDimensionsStore.lexStores;

  const processedLiquidityRequests: TLiquidityHistoryTableRowGist[] =
    extractLiquidityHistoryTableRowsFromLexStores(
      holderAddress,
      leverageDimensionsStores,
    );

  return processedLiquidityRequests;
}

function extractLiquidityHistoryTableRowsFromLexStores(
  holderAddress: string,
  leverageDimensionsStores: SingleLexStore[],
): TLiquidityHistoryTableRowGist[] {
  const processedLiquidityRequests: TLiquidityHistoryTableRowGist[] = [];

  for (const leverageDimensionStore of leverageDimensionsStores) {
    const userStore = leverageDimensionStore.getUserStore(holderAddress);

    processedLiquidityRequests.push(
      ...userStore.account_processedLiquidityRequests.map((liquidityAction) => {
        const liquidityHistoryRowGist: TLiquidityHistoryTableRowGist = {
          action: liquidityAction.type == "WITHDRAW" ? "Withdraw" : "Deposit",
          amountInUnits: unitsBnToFloat(
            liquidityAction.assetAmount,
            leverageDimensionStore?.chipAssetParams.decimals ?? 18,
          ),
          assetSymbol:
            leverageDimensionStore?.sourceAssetParameters.symbol ?? "",
          engineChainId: leverageDimensionStore?.engineChainId ?? 250,
          processEpoch: liquidityAction.processEpoch,
          processTxHash: liquidityAction.processTxHash,
          providerAddress: liquidityAction.providerAddress,
          timestamp: liquidityAction.processTimestamp,

          // TODO : Can add reading of also cancelled
          status: "Processed",
        };

        return liquidityHistoryRowGist;
      }),
    );
  }

  return processedLiquidityRequests;
}
