import { logPageView } from "../services/servicesIntergration/firebaseService/firebaseAnalytics.ts";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useCryptoWalletIntegrationStore } from "../store/storeHooks.ts";

export function usePageTracking() {
  const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();
  const location = useLocation();

  const connectedAddress = cryptoWalletConnectionStore.mainAddress;

  useEffect(() => {
    logPageView(
      connectedAddress,
      window.location.href,
      location.pathname + location.search,
      window.document.title,
    );
  }, [connectedAddress, location.pathname, location.search]);
}
