export const NATIVE_COIN_ASSET_ADDRESS =
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const FANTOM_ASSETS = {
  NATIVE_FTM: {
    symbol: "FTM",
    name: "FTM",
    shortName: "FTM",
    address: NATIVE_COIN_ASSET_ADDRESS,
    decimals: 18,
  },
  EQUAL: {
    symbol: "EQUAL",
    name: "EQUALIZER",
    shortName: "EQUAL",
    address: "0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6",
    decimals: 18,
  },
  BRUSH: {
    symbol: "BRUSH",
    name: "BRUSH",
    shortName: "BRUSH",
    address: "0x85dec8c4b2680793661bca91a8f129607571863d",
    decimals: 18,
  },
  fSONIC: {
    symbol: "fSONIC",
    name: "fSONIC",
    shortName: "fSONIC",
    address: "0x05e31a691405d06708a355c029599c12d5da8b28",
    decimals: 18,
  },
  POLTER: {
    symbol: "POLTER",
    name: "POLTER",
    shortName: "POLTER",
    address: "0x5c725631FD299703D0A74C23F89a55c6B9A0C52F",
    decimals: 18,
  },
  fBUX: {
    symbol: "fBUX",
    name: "fBUX",
    shortName: "fBUX",
    address: "0x1e2Ea3f3209D66647f959CF00627107e079B870d",
    decimals: 18,
  },
  FTAILS: {
    symbol: "FTAILS",
    name: "FTAILS",
    shortName: "FTAILS",
    address: "0x5cF90b977C86415a53CE3B7bE13b26f6abdDfee2",
    decimals: 18,
  },
  SPIRIT: {
    symbol: "SPIRIT",
    name: "SPIRIT",
    shortName: "SPIRIT",
    address: "0x5cc61a78f164885776aa610fb0fe1257df78e59b",
    decimals: 18,
  },

  USDC: {
    symbol: "USDC",
    name: "USD Coin",
    shortName: "USDC",
    address: "0x2F733095B80A04b38b0D10cC884524a3d09b836a",
    decimals: 6,
  },

  WFTM: {
    symbol: "WFTM",
    name: "Wrapped Fantom",
    shortName: "wFTM",
    address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    decimals: 18,
  },
  WETH: {
    symbol: "WETH",
    name: "Wrapped Ether",
    shortName: "wETH",
    address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
    decimals: 18,
  },
  DAI: {
    symbol: "DAI",
    name: "DAI",
    shortName: "DAI",
    address: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
    decimals: 18,
  },

  // PLAYGROUND
  PLAY_DAI: {
    symbol: "PDAI",
    name: "PLAY DAI",
    shortName: "PLAY DAI",
    // symbol: "TKN",
    // name: "TOKEN",
    // shortName: "TOKEN",
    address: "0xBbC8773dbeDE3895Beb8cEcE2372f197AC2b0D1E",
    decimals: 18,
  },
  PLAY_USDC: {
    symbol: "PUSDC",
    name: "PLAY USDC",
    shortName: "PUSDC",
    address: "0xABf7B2451873681DA22971420DD61DB14ebc5529",
    decimals: 6,
  },
  PLAY_COMPETITION: {
    symbol: "lxCOMP",
    name: "PLAY COMPETITION",
    shortName: "PCOMPETITION",
    address: "0xE3647566c104E3e7Bce2586FE10AF9b0573CCc0D",
    decimals: 18,
  },
};

export const FANTOM_OFT_CHIPS = {
  OFT_FUSE_PLAY_DAI: {
    symbol: "PDAI",
    name: "PLAY DAI",
    shortName: "PLAY DAI",
    address: "0xC78638a74B19903010223A3A434011e84622eB6A",
    decimals: 18,
  },
  OFT_FUSE_PLAY_WFUSE: {
    symbol: "WFUSE",
    name: "WFUSE",
    shortName: "WFUSE",
    address: "0x2F3699CBcE17c0220c137281EB1dFaC0bDb882A2",
    decimals: 18,
  },
  OFT_FUSE_PLAY_MST: {
    symbol: "MST",
    name: "MST",
    shortName: "MST",
    address: "0x47DE83e8ba647aDE23398DFAf162adAaAB4Ed09c",
    decimals: 18,
  },
  OFT_FUSE_SFUSE: {
    symbol: "sFUSE",
    name: "sFUSE",
    shortName: "sFUSE",
    address: "0x50799F9c292E800780D339d17E1f664Eb04fF126",
    decimals: 18,
  },
  OFT_FUSE_VOLT: {
    symbol: "VOLT",
    name: "VOLT",
    shortName: "VOLT",
    address: "0xE8CeA5f5098b237BC8291DCA2694dB2f503f08da",
    decimals: 18,
  },

  OFT_LINEA_LVC: {
    symbol: "LVC",
    name: "LVC",
    shortName: "LVC",
    address: "0xAe6127B6C886E1848C12D141Bb567E835015EE12",
    decimals: 18,
  },

  OFT_ARBITRUM_ARB: {
    symbol: "ARB",
    name: "ARB",
    shortName: "ARB",
    address: "0x97EF6C643551355cF8cB7bFc0d3E1E0E1EE6898e",
    decimals: 18,
  },
  OFT_ARBITRUM_STEUR: {
    symbol: "stEUR",
    name: "stEUR",
    shortName: "stEUR",
    address: "0x5bdF7Bf91C09Bf89D889Ee4EB1C8a0D833443beE",
    decimals: 18,
  },
  OFT_ARBITRUM_TST: {
    symbol: "TST",
    name: "TST",
    shortName: "TST",
    address: "0x28BC21Fd33848b69a06e1B4244D5d1e0eC1fAD39",
    decimals: 18,
  },
  OFT_ARBITRUM_EUROs: {
    symbol: "EUROs",
    name: "EUROs",
    shortName: "EUROs",
    address: "0xac1F13F909b49ac9a812C4d97E9F10e9aF2f867C",
    decimals: 18,
  },
  OFT_ARBITRUM_GRAI: {
    symbol: "GRAI",
    name: "GRAI",
    shortName: "GRAI",
    address: "0x8b6F1c276410d8bAC84d8ed587cC0F9d30F52ee2",
    decimals: 18,
  },
  OFT_ARBITRUM_SLIZ: {
    symbol: "SLIZ",
    name: "SLIZ",
    shortName: "SLIZ",
    address: "0xcE112647C087A872cb1146399eCCCF015Af489Ef",
    decimals: 18,
  },
  OFT_ARBITRUM_SCALES: {
    symbol: "SCALES",
    name: "SCALES",
    shortName: "SCALES",
    address: "0x67Db97e2f1252f4d0d882eCBfb1648940c51c2af",
    decimals: 18,
  },
  OFT_ARBITRUM_USDFI: {
    symbol: "USDFI",
    name: "USDFI",
    shortName: "USDFI",
    address: "0x9711E01A9FCa6641BD9Ac966845726fef8e8E5D0",
    decimals: 18,
  },

  OFT_OPTIMISM_SONNE: {
    symbol: "SONNE",
    name: "SONNE",
    shortName: "SONNE",
    address: "0x340aB01Cb6a417C7030224E855fc7294c0E0E78C",
    decimals: 18,
  },

  OFT_POLYGON_WMATIC: {
    symbol: "WMATIC",
    name: "WMATIC",
    shortName: "WMATIC",
    address: "0x8ad373e06a7aa4c00ea3C1E30FAbFa15C9DDC528",
    decimals: 18,
  },
  OFT_POLYGON_MIMATIC: {
    symbol: "MAI",
    name: "MAI",
    shortName: "MAI",
    address: "0xAB57CE5Df4B31396707A04772002D77bf2adb7e9",
    decimals: 18,
  },

  OFT_MANTLE_AUSD: {
    symbol: "aUSD",
    name: "aUSD",
    shortName: "aUSD",
    address: "0x94E5a42AB3579d2EEC9A0b7e9ff133633b98CC8B",
    decimals: 18,
  },

  OFT_BSC_LISUSD: {
    symbol: "lisUSD",
    name: "lisUSD",
    shortName: "lisUSD",
    address: "0x8a7c6F92Fe1140Afd6850df8d41b63954B726bF5",
    decimals: 18,
  },

  OFT_MODE_MODE: {
    symbol: "MODE",
    name: "MODE",
    shortName: "MODE",
    address: "0x665ba4A26Df2B630e14b0016C3d358AFF9Deb4d9",
    decimals: 18,
  },
  OFT_MODE_ION: {
    symbol: "ION",
    name: "ION",
    shortName: "ION",
    address: "0x75dC3CD135b18bBd3826Cb8813DA6B3b99dcae87",
    decimals: 18,
  },

  OFT_CELO_CELO: {
    symbol: "CELO",
    name: "CELO",
    shortName: "CELO",
    address: "0x1Ac73C1a8379F0e7207B3516Bec9Ce53AD7707F1",
    decimals: 18,
  },
  OFT_CELO_USDT: {
    symbol: "USDT",
    name: "USDT",
    shortName: "USDT",
    address: "0x5700daf62E4f296525B7F920a1761e6EbE1b54Fc",
    decimals: 18,
  },

  // OFT_LINEA_vcLVC: {
  //   symbol: "veLVC",
  //   name: "veLVC",
  //   shortName: "veLVC",
  //   address: "0x94e40C37daeF2df89d4c12934C7e24E2403BC50f",
  //   decimals: 18,
  // },
  // OFT_LINEA_agEUR: {
  //   symbol: "agEUR",
  //   name: "agEUR",
  //   shortName: "agEUR",
  //   address: "0x8fe77805F04fF43CA1E42E5a3201dd64Ee14a935",
  //   decimals: 18,
  // },
};

export const FANTOM_ENGINE_CHIPS = {
  ENGINE_FANTOM_FTM: {
    symbol: "FTM",
    name: "FTM",
    shortName: "FTM",
    address: "0x614aA983f54409D475aeC5D18120FECFD6320eF4",
    decimals: 18,
  },
  ENGINE_FANTOM_EQUAL: {
    symbol: "EQUAL",
    name: "EQUALIZER",
    shortName: "EQUAL",
    address: "0x59698CA79B8568F25294d6Eab6281667712079eE",
    decimals: 18,
  },
  ENGINE_FANTOM_BRUSH: {
    symbol: "BRUSH",
    name: "BRUSH",
    shortName: "BRUSH",
    address: "0xCBd1A63A84af4BAA9541331420fF98d8Fca3ba1C",
    decimals: 18,
  },
  ENGINE_FANTOM_FSONIC: {
    symbol: "fSONIC",
    name: "fSONIC",
    shortName: "fSONIC",
    address: "0xCCC0d9d276176FED7E6918dCf99F23DCAaCFcAc5",
    decimals: 18,
  },
  ENGINE_FANTOM_POLTER: {
    symbol: "POLTER",
    name: "POLTER",
    shortName: "POLTER",
    address: "0x089cD8AC58D9a1488b3cDfDfeb20963e7BB33732",
    decimals: 18,
  },
  ENGINE_FANTOM_FBUX: {
    symbol: "fBUX",
    name: "fBUX",
    shortName: "fBUX",
    address: "0x86fAcB048FEe156A16104531Bd36CDfF118d8107",
    decimals: 18,
  },
  ENGINE_FANTOM_FTAILS: {
    symbol: "FTAILS",
    name: "FTAILS",
    shortName: "FTAILS",
    address: "0x2c241eeFc4b61ed475d7f1DeD112df99E5De0E8F",
    decimals: 18,
  },
  ENGINE_FANTOM_USDC: {
    symbol: "USDC",
    name: "USDC",
    shortName: "USDC",
    address: "0x194609ea1C1D77e66eaB28C48CE266A48f3bC30a",
    decimals: 18,
  },
  ENGINE_FANTOM_SPIRIT: {
    symbol: "SPIRIT",
    name: "SPIRIT",
    shortName: "SPIRIT",
    address: "0x1401C2C092df468862e23502B88A8795e2e9aecf",
    decimals: 18,
  },
};

export const BOBA_MAINNET_ASSETS = {
  USDC: {
    symbol: "USDC",
    name: "USD Coin",
    shortName: "USDC",
    address: "0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc",
    decimals: 6,
  },
  BOBA: {
    symbol: "BOBA",
    name: "BOBA",
    shortName: "BOBA",
    address: "0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7",
    decimals: 18,
  },

  // PLAYGROUND
  // PLAY_DAI: {
  //   symbol: "PDAI",
  //   name: "PLAY DAI",
  //   shortName: "PLAY DAI",
  //   address: "0x1be6043a5CE2cbD72Cf6BA3Dc91B0621DfC144Ca",
  //   decimals: 18,
  // },
  // PLAY_COMPETITION: {
  //   symbol: "lxComp",
  //   name: "LYNX COMPETITION",
  //   shortName: "lxComp",
  //   address: "0xC21F2971d419E6c52e6d9dAd189b145c888accdd",
  //   decimals: 18,
  // },
};

export const BOBA_ENGINE_CHIPS = {
  ENGINE_BOBA_USDC: {
    symbol: "USDC",
    name: "USDC",
    shortName: "USDC",
    address: "0xcDD339d704Fb8f35A3a2f7d9B064238D33DC7550",
    decimals: 18,
  },
  ENGINE_BOBA_BOBA: {
    symbol: "BOBA",
    name: "BOBA",
    shortName: "BOBA",
    address: "0x9beABD8699E2306c5632C80E663dE9953e104C3f",
    decimals: 18,
  },
};

export const LINEA_MAINNET_ASSETS = {
  USDC: {
    symbol: "USDC",
    name: "USDC",
    shortName: "USDC",
    address: "0x176211869ca2b568f2a7d4ee941e073a821ee1ff",
    decimals: 6,
  },
  // agEUR: {
  //   symbol: "agEUR",
  //   name: "agEUR",
  //   shortName: "agEUR",
  //   address: "0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
  //   decimals: 18,
  // },
  // veLVC: {
  //   symbol: "veLVC",
  //   name: "veLVC",
  //   shortName: "veLVC",
  //   address: "0xaec06345b26451bda999d83b361beaad6ea93f87",
  //   decimals: 18,
  // },

  LVC: {
    symbol: "LVC",
    name: "LVC",
    shortName: "LVC",
    address: "0xcc22F6AA610D1b2a0e89EF228079cB3e1831b1D1",
    decimals: 18,
  },

  // PLAYGROUND
  // PLAY_DAI: {
  //   symbol: "PDAI",
  //   name: "PLAY DAI",
  //   shortName: "PLAY DAI",
  //   address: "0x0619E77fED51C8E1cfcb6954544091cbc7D024D8",
  //   decimals: 18,
  // },
};

export const FUSE_MAINNET_ASSETS = {
  WFUSE: {
    symbol: "WFUSE",
    name: "WFUSE",
    shortName: "WFUSE",
    address: "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629",
    decimals: 18,
  },
  MST: {
    symbol: "MST",
    name: "MST",
    shortName: "MST",
    address: "0x2363Df84fDb7D4ee9d4E1A15c763BB6b7177eAEe",
    decimals: 18,
  },
  SFUSE: {
    symbol: "sFUSE",
    name: "sFUSE",
    shortName: "sFUSE",
    address: "0xb1DD0B683d9A56525cC096fbF5eec6E60FE79871",
    decimals: 18,
  },
  VOLT: {
    symbol: "VOLT",
    name: "VOLT",
    shortName: "VOLT",
    address: "0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4",
    decimals: 18,
  },

  // PLAYGROUND
  PLAY_DAI: {
    symbol: "PDAI",
    name: "PLAY DAI",
    shortName: "PLAY DAI",
    address: "0xC5E782E2A4E2cFCb7eD454CF5a7b6aa2bB424B90",
    decimals: 18,
  },
};

export const ARBITRUM_MAINNET_ASSETS = {
  ARB: {
    symbol: "ARB",
    name: "ARB",
    shortName: "ARB",
    address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    decimals: 18,
  },
  stEUR: {
    symbol: "stEUR",
    name: "stEUR",
    shortName: "stEUR",
    address: "0x004626a008b1acdc4c74ab51644093b155e59a23",
    decimals: 18,
  },
  TST: {
    symbol: "TST",
    name: "TST",
    shortName: "TST",
    address: "0xf5a27e55c748bcddbfea5477cb9ae924f0f7fd2e",
    decimals: 18,
  },
  EUROs: {
    symbol: "EUROs",
    name: "EUROs",
    shortName: "EUROs",
    address: "0x643b34980e635719c15a2d4ce69571a258f940e9",
    decimals: 18,
  },
  GRAI: {
    symbol: "GRAI",
    name: "GRAI",
    shortName: "GRAI",
    address: "0x894134a25a5fac1c2c26f1d8fbf05111a3cb9487",
    decimals: 18,
  },
  SLIZ: {
    symbol: "SLIZ",
    name: "SLIZ",
    shortName: "SLIZ",
    address: "0x463913D3a3D3D291667D53B8325c598Eb88D3B0e",
    decimals: 18,
  },
  SCALES: {
    symbol: "SCALES",
    name: "SCALES",
    shortName: "SCALES",
    address: "0xe6af844d5740b6b297b6dd7fb2ce299ee9e3d16f",
    decimals: 18,
  },
  USDFI: {
    symbol: "USDFI",
    name: "USDFI",
    shortName: "USDFI",
    address: "0x249c48e22e95514ca975de31f473f30c2f3c0916",
    decimals: 18,
  },
};

export const OPTIMISM_ASSETS = {
  SONNE: {
    symbol: "SONNE",
    name: "SONNE",
    shortName: "SONNE",
    address: "0x1db2466d9f5e10d7090e7152b68d62703a2245f0",
    decimals: 18,
  },
};

export const POLYGON_ASSETS = {
  WMATIC: {
    symbol: "WMATIC",
    name: "WMATIC",
    shortName: "WMATIC",
    address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    decimals: 18,
  },
  miMATIC: {
    symbol: "MAI",
    name: "MAI",
    shortName: "MAI",
    address: "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    decimals: 18,
  },
};

export const MANTLE_ASSETS = {
  aUSD: {
    symbol: "aUSD",
    name: "aUSD",
    shortName: "aUSD",
    address: "0xD2B4C9B0d70e3Da1fBDD98f469bD02E77E12FC79",
    decimals: 18,
  },
};

export const BSC_ASSETS = {
  lisUSD: {
    symbol: "lisUSD",
    name: "lisUSD",
    shortName: "lisUSD",
    address: "0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5",
    decimals: 18,
  },
};

export const MODE_ASSETS = {
  MODE: {
    symbol: "MODE",
    name: "MODE",
    shortName: "MODE",
    address: "0xDfc7C877a950e49D2610114102175A06C2e3167a",
    decimals: 18,
  },
  ION: {
    symbol: "ION",
    name: "ION",
    shortName: "ION",
    address: "0x18470019bF0E94611f15852F7e93cf5D65BC34CA",
    decimals: 18,
  },
};

export const CELO_ASSETS = {
  CELO: {
    symbol: "CELO",
    name: "CELO",
    shortName: "CELO",
    address: "0x471EcE3750Da237f93B8E339c536989b8978a438",
    decimals: 18,
  },
  USDT: {
    symbol: "USDT",
    name: "USDT",
    shortName: "USDT",
    address: "0x48065fbBE25f71C9282ddf5e1cD6D6A887483D5e",
    decimals: 6,
  },
};
