import {
  BaseSelector,
  CommonPageHeader,
  createFilteredQueryString,
  LeaderBoardTable,
} from "lynx-ui-components";
import { observer } from "mobx-react";
import { Box, Stack, Typography } from "@mui/material";
import {
  QUERY_PARAMS_KEYS,
  useUrlQueryState_activeSettlementAsset,
  useUrlQueryValue_chainId,
} from "../../navigation/navigationStateHooks";
import { useCreateLexSelectOptions } from "../../hooks/assetHooks";
import { CHAIN_ID_NO_CHAIN, TChainIds } from "../../constants/chainConstants";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetHistoricCompetitionDescriptors,
  useSyncLeaderBoardRoundTradersInfo,
} from "./leaderBoardPageHooks";
import {
  useActiveLexStoreStore,
  useCryptoWalletIntegrationStore,
  useModalsStore,
  usePricesStore,
} from "../../store/storeHooks";
import React, { useCallback, useMemo } from "react";
import LightIcon from "../../assets/appIcons/lightingIcon.svg";
import ListIcon from "@mui/icons-material/ListAlt";
import { useStoreArray } from "../../hooks/storesNormalizationsHooks.ts";
import { convertCompetitionRoundTraderInfoToLeaderBoardTableData } from "./subComponents/tables/leaderboardTableUtils.ts";
import { useQuery } from "@tanstack/react-query";
import {
  TBaseSelectorOption,
  useCommonSelectorOptions,
} from "../../hooks/componentsHooks/selectorsHooks.ts";
import { isSameAddress } from "../../utils/addresses.ts";
import { useIncentivesService } from "../../services/servicesHooks.ts";
import {
  EMPTY_COMPETITION_PLAN_DESCRIPTION,
  TCompetitionPlanIncentiveDescription,
} from "../../services/servicesIntergration/incentivesService/IIncentivesService.ts";
import { timeUtils } from "../../utils/timeUtils.ts";
import { useGetTimeLeftForCurrentRound } from "../CompetitionsPage/competitionHooks.ts";
import { LeaderBoardModalWrapper } from "../../components/Modals/modalWrappers/LeaderBoardModalWrapper.tsx";

interface IProps {}

export const LeaderBoardPage = observer<React.FC<IProps>>((props) => {
  const navigate = useNavigate();
  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const lexStore = useActiveLexStoreStore();
  const pricesStore = usePricesStore();
  const poolAddress = lexStore.poolAddress;
  const roundNumber = lexStore.currentUsageRound.roundNumber;

  const usageRoundTradersInfo = useStoreArray(
    lexStore.currentRoundUsageTradersGraphInfo,
  );

  const { queryPairSettlementAsset, setQueryPairSettlementAsset } =
    useUrlQueryState_activeSettlementAsset();
  const { queryChainId } = useUrlQueryValue_chainId();
  const lexSelectOptions = useCreateLexSelectOptions(
    (Number(queryChainId) as TChainIds) ?? CHAIN_ID_NO_CHAIN,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const matchingSearchParamsString = createFilteredQueryString(searchParams, [
    QUERY_PARAMS_KEYS.chainId,
    QUERY_PARAMS_KEYS.settlementAsset,
  ]);

  useSyncLeaderBoardRoundTradersInfo(poolAddress, roundNumber);

  const onCurrencySelect = useCallback(
    (val: string) => {
      setQueryPairSettlementAsset(val);
    },
    [setQueryPairSettlementAsset],
  );

  const competitionDescription = lexStore.currentCompetitionDescription;
  const hasLiveCompetition = competitionDescription.roundNumber > 0;

  const incentivesService = useIncentivesService(lexStore.engineChainId);

  const pastCompetitionDescriptionsQuery = useQuery({
    queryFn: () => incentivesService.getAllPastIncentivePlans(),
    queryKey: [`pastCompetitionDescriptions_${lexStore.engineChainId}`],
  });

  // console.log("------ DATA : ");
  // console.log(pastCompetitionDescriptions.data);
  //
  // console.log("------ isPending : ");
  // console.log(pastCompetitionDescriptions.isPending);

  const actions = [
    {
      buttonText: "How it works",
      buttonStartIcon: null,
      buttonEndIcon: <img src={LightIcon} />,
      buttonClick: () => {
        window.open(
          "https://lynx-finance.gitbook.io/lynx-finance/for-users/traders/trader-incentives",
          "_blank",
          "noreferrer",
        );
      },
    },
    {
      buttonText: "Trade Rewards",
      buttonStartIcon: undefined,
      buttonEndIcon: <ListIcon />,
      buttonClick: () => {
        navigate(`/rewards?${matchingSearchParamsString}`);
      },
    },
  ];

  const settlementAssetSymbol = lexStore.sourceAssetParameters.symbol;

  const isLoading = lexStore.currentUsageRound.roundNumber === 0;

  const { historicCompetitionsGists } = useGetHistoricCompetitionDescriptors(
    lexStore.poolAddress,
  );

  const pastCompetitionsDescriptionsForCurrentLex = useMemo(() => {
    return (
      pastCompetitionDescriptionsQuery.data?.competitions.filter((comp) =>
        isSameAddress(comp.lexAddress, lexStore.poolAddress),
      ) ?? []
    );
  }, [
    lexStore.poolAddress,
    pastCompetitionDescriptionsQuery.data?.competitions,
  ]);

  const optionsForPastRoundSelector = useMemo<TBaseSelectorOption[]>(() => {
    if (pastCompetitionsDescriptionsForCurrentLex.length == 0) {
      return [
        {
          text: "None",
          value: "none",
        },
      ];
    } else {
      return pastCompetitionsDescriptionsForCurrentLex
        .sort((a, b) => b.roundNumber - a.roundNumber)
        .map((comp) => {
          return {
            text: `Round ${comp.roundNumber}`,
            value: comp.roundNumber.toString(),
          };
        });
    }
  }, [pastCompetitionsDescriptionsForCurrentLex]);

  const {
    setSelectedOptionValue: setSelectedPastRoundValue,
    selectedOptionValue: selectedPastRoundValue,
  } = useCommonSelectorOptions(optionsForPastRoundSelector[0].value);

  // TODO  Improves this while 'none' mechanism
  const selectedPastRoundValueNumeric =
    selectedPastRoundValue == "none" ? 0 : parseInt(selectedPastRoundValue);

  const selectedPastCompetitionDescription =
    useMemo<TCompetitionPlanIncentiveDescription>(() => {
      if (selectedPastRoundValueNumeric == 0) {
        return EMPTY_COMPETITION_PLAN_DESCRIPTION;
      } else {
        const matching = pastCompetitionsDescriptionsForCurrentLex.find(
          (comp) => comp.roundNumber === selectedPastRoundValueNumeric,
        );

        if (!matching) {
          console.warn(
            `No matching competition description found for round ${selectedPastRoundValueNumeric}`,
          );
        }

        return matching ?? EMPTY_COMPETITION_PLAN_DESCRIPTION;
      }
    }, [
      pastCompetitionsDescriptionsForCurrentLex,
      selectedPastRoundValueNumeric,
    ]);

  const selectedPastCompetitionResultQuery = useQuery({
    queryFn: () => {
      if (selectedPastRoundValueNumeric == 0) {
        return Promise.resolve([]);
      } else {
        return incentivesService.getPastCompetitionResults(
          lexStore.poolAddress,
          selectedPastRoundValueNumeric,
        );
      }
    },
    queryKey: [
      "selectedPastCompetitionResultQuery",
      { lex: lexStore.poolAddress, roundNumber: selectedPastRoundValueNumeric },
    ],
  });

  const liveCompetitionTradersInfoResultQuery = useQuery({
    queryFn: () => {
      if (hasLiveCompetition) {
        return incentivesService.getLiveCompetitionTradersInfo(
          lexStore.poolAddress,
          competitionDescription.roundNumber,
        );
      } else {
        return Promise.resolve([]);
      }
    },
    queryKey: [
      "liveCompetitionTradersInfoResultQuery",
      {
        lex: lexStore.poolAddress,
        roundNumber: competitionDescription.roundNumber,
      },
    ],
    enabled: hasLiveCompetition,
  });

  // console.log("------ DATA : ");
  // console.log(liveCompetitionTradersInfoResultQuery.data);
  //
  // console.log("------ isPending : ");
  // console.log(liveCompetitionTradersInfoResultQuery.isPending);

  const incentiveTokenPrice =
    pricesStore.symbolToPriceMap.get(
      competitionDescription.details.incentivesList[0].rewardSymbol,
    ) ?? 0;

  const mainCompetitionTableComponent = useMemo(() => {
    if (hasLiveCompetition) {
      return (
        <LeaderBoardTable
          rowGists={convertCompetitionRoundTraderInfoToLeaderBoardTableData(
            liveCompetitionTradersInfoResultQuery.data ?? [],
            competitionDescription,
            incentiveTokenPrice,
          )}
          settlementAssetSymbol={settlementAssetSymbol}
          connectedAddress={walletConnectionStore.mainAddress}
          isLoading={isLoading}
          emptyTableText={"No positions yet"}
        />
      );
    } else {
      // NOTE : This is in case we have no actual competition running and we just read the state from the graph
      return (
        <LeaderBoardTable
          rowGists={[]}
          settlementAssetSymbol={settlementAssetSymbol}
          connectedAddress={walletConnectionStore.mainAddress}
          isLoading={isLoading}
          emptyTableText={"No active competition"}
        />
        // <LeaderBoardTable
        //   rowGists={convertUsageRoundTradersInfoToLeaderBoardTableData(
        //     usageRoundTradersInfo,
        //     competitionDescription,
        //     incentiveTokenPrice,
        //   )}
        //   settlementAssetSymbol={settlementAssetSymbol}
        //   connectedAddress={walletConnectionStore.mainAddress}
        //   isLoading={isLoading}
        // />
      );
    }
  }, [
    hasLiveCompetition,
    liveCompetitionTradersInfoResultQuery.data,
    competitionDescription,
    incentiveTokenPrice,
    settlementAssetSymbol,
    walletConnectionStore.mainAddress,
    isLoading,
  ]);

  const durationLeftText = useGetTimeLeftForCurrentRound();

  return (
    <Box>
      <CommonPageHeader
        pageTitle="Rewards Leaderboard"
        endsLabel={
          hasLiveCompetition
            ? `Current Round ${competitionDescription.roundNumber} | Ends in ${durationLeftText}`
            : "No active competition"
        }
        currencyOptions={lexSelectOptions}
        currencySelectedValue={
          queryPairSettlementAsset ?? lexSelectOptions[0].value
        }
        onCurrencySelect={onCurrencySelect}
        actions={actions}
        // rememberSelected
      />

      <Box mt={3}>{mainCompetitionTableComponent}</Box>

      <br />
      <Stack direction={"column"} gap={2}>
        <Stack
          direction={"row"}
          alignContent={"center"}
          // justifyContent={"center"}
          gap={2}
        >
          <Typography alignContent={"center"} variant={"h5"}>
            Past Rounds
          </Typography>
          <BaseSelector
            options={optionsForPastRoundSelector}
            value={selectedPastRoundValue}
            onChange={setSelectedPastRoundValue}
            sx={{
              minWidth: "100px",
            }}
          />
        </Stack>
        {selectedPastCompetitionDescription.roundNumber > 0 ? (
          <>
            <Typography variant={"h5"}>
              {timeUtils.timestampToLocalDateTimeString(
                selectedPastCompetitionDescription.startTimestamp,
              )}
              {" - "}
              {timeUtils.timestampToLocalDateTimeString(
                selectedPastCompetitionDescription.endTimestamp,
              )}
            </Typography>
            <Box>
              <LeaderBoardTable
                key={`${settlementAssetSymbol}_${selectedPastCompetitionDescription.roundNumber}`}
                rowGists={convertCompetitionRoundTraderInfoToLeaderBoardTableData(
                  selectedPastCompetitionResultQuery.data ?? [],
                  selectedPastCompetitionDescription,
                  pricesStore.symbolToPriceMap.get(
                    selectedPastCompetitionDescription.details.incentivesList[0]
                      .rewardSymbol,
                  ) ?? 0,
                )}
                settlementAssetSymbol={settlementAssetSymbol}
                connectedAddress={walletConnectionStore.mainAddress}
                isLoading={false}
                emptyTableText={"No positions yet"}
              />
            </Box>
          </>
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
});
