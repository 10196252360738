import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
import { createTheme } from "@mui/material/styles";

export const baseTheme = responsiveFontSizes(
  createTheme({
    palette: {
      // primary: {
      // main: "#1a237e",
      // main: THEME_CONSTANTS.brandPurple,
      // },
    },
    typography: {
      // TODO : CRITICAL : Change to 'Helvetica Now'
      fontFamily: "Helvetica",
      // fontSize: 12,
    },
  }),
);
