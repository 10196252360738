import React, { useMemo } from "react";
import { Tooltip, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Variant } from "@mui/material/styles/createTypography";
import clsx from "clsx";

// TODO : Move to a proper place
const BACKGROUND_LIGHT_COLOR = "#fffefe";
const BACKGROUND_PAGE_CREAM = "#f9f4ef";
const TEXT_ON_LIGHT = "#2e2f3e";

interface IProps {
  title: string;
  text: string;
  // TODO : Remove color props
  textColor?: string;
  titleColor?: string;
  tooltipContent?: NonNullable<React.ReactNode>;
  titleVariant?: Variant;
  titleClassName?: string;
  textClassName?: string;
  containerClassName?: string;
  enterDelayMs?: number;
}

const useStyles = makeStyles()((theme) => ({
  positionDataCell: {
    // flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",

    // color: (props) => props.color,

    cursor: "default",
  },

  dataCellTitle: {
    // color: (props) => props.titleColor,
    transition: "300ms ease",

    userSelect: "none",

    // [theme.breakpoints.down("sm")]: {
    "--titleWeight": "bold",
    // },
  },

  tooltipWrapper: {
    border: `1px solid ${BACKGROUND_PAGE_CREAM}`,
    backgroundColor: BACKGROUND_PAGE_CREAM,
    color: TEXT_ON_LIGHT,
    marginBottom: 10,
  },
  tooltipArrow: {
    color: BACKGROUND_LIGHT_COLOR,
  },
}));

export const TitleAndValue = React.memo<IProps>((props) => {
  const { classes } = useStyles();

  const {
    title,
    text,
    tooltipContent,
    titleVariant,
    titleClassName,
    textClassName,
    containerClassName,
    enterDelayMs,
  } = props;

  const safeTooltipContent = useMemo(() => {
    if (tooltipContent) {
      if (typeof tooltipContent === "string") {
        return <Typography>{tooltipContent}</Typography>;
      } else {
        return tooltipContent;
      }
    } else {
      return "";
    }
  }, [tooltipContent]);

  const titleClsx = useMemo(() => {
    return clsx([classes.dataCellTitle, titleClassName]);
  }, [classes.dataCellTitle, titleClassName]);

  const textClsx = useMemo(() => {
    return clsx([textClassName]);
  }, [textClassName]);

  const containerClsx = useMemo(() => {
    return clsx([classes.positionDataCell, containerClassName]);
  }, [classes.positionDataCell, containerClassName]);

  return (
    <Tooltip
      arrow
      placement={"top"}
      title={safeTooltipContent}
      enterTouchDelay={0}
      enterDelay={enterDelayMs ?? 0}
      classes={{
        tooltip: classes.tooltipWrapper,
        arrow: classes.tooltipArrow,
      }}
    >
      <div className={containerClsx}>
        <Typography className={titleClsx} variant={titleVariant}>
          {title}
        </Typography>
        <Typography variant={"body1"} className={textClsx}>
          {text}
        </Typography>
      </div>
    </Tooltip>
  );
});
