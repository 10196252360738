import { WHITE_LABEL_CONFIGS } from "./whiteLabelConfigs";

export function useWhiteLabelIdentifier() {
  return identifyWhiteLabel();
}

export function identifyWhiteLabel() {
  const isOnAnyWhiteLabel = WHITE_LABEL_CONFIGS.isOnWhiteLabel;
  const whiteLabelParams = WHITE_LABEL_CONFIGS.whiteLabelParams;
  const whiteLabelName = WHITE_LABEL_CONFIGS.whiteLabelName;

  const whiteLabelRoutePrefix = isOnAnyWhiteLabel
    ? whiteLabelParams.routePrefix
    : "";

  const whiteLabelPageTitle = isOnAnyWhiteLabel
    ? whiteLabelParams.pageTitle
    : "";

  const whiteLabelDefaultChainId = whiteLabelParams.defaultSourceChainId;
  const defaultSettlementAssetSymbol =
    whiteLabelParams.defaultSettlementAssetSymbol;

  const whiteLabelFaviconUrl = whiteLabelParams.faviconUrl;

  return {
    isOnAnyWhiteLabel,
    whiteLabelParams,
    whiteLabelRoutePrefix,
    whiteLabelPageTitle,
    whiteLabelName,

    whiteLabelFaviconUrl,

    whiteLabelDefaultChainId,
    defaultSettlementAssetSymbol,
  };
}
