import { FANTOM_ENGINE_CHIPS, FANTOM_OFT_CHIPS } from "./chainAssets.ts";
import { isSameAddress } from "../utils/addresses.ts";

export function getRewardTokenSymbol(rewardTokenAddress: string): string {
  const allFantomChips = [
    ...Object.values(FANTOM_OFT_CHIPS),
    ...Object.values(FANTOM_ENGINE_CHIPS),
  ];
  return (
    allFantomChips.find((chip) =>
      isSameAddress(chip.address, rewardTokenAddress),
    )?.symbol ?? "UNKNOWN"
  );
}
