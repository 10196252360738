import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  MaterialUiTable,
  Sortings,
  TableActionButton,
} from "lynx-ui-components";
import { makeStyles } from "tss-react/mui";
import {
  LIVE_CHAIN_CONSTANTS,
  TChainIds,
  TChainIdsWithNone,
  TSourceChainIds,
} from "../../../constants/chainConstants";
import { commonTableCellsBuilders } from "../../../components/Tables/commonTableCellsBuilders.tsx";
import { localPriceStringer, localStringer } from "../../../ux/uxFunctions.ts";
import { Stack } from "@mui/material";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants.ts";
import { useIsMobile } from "../../../ux/uxHooks.ts";

const headerValuePays: Record<string, string> = {
  sourceChainId: "sourceChainId",
  sourceUnderlyingSymbol: "sourceUnderlyingSymbol",
  totalWeeklyCompetitionRewardsInUnits: "totalWeeklyCompetitionRewardsInUnits",
  connectedTraderEstimatedRewardsInUnits:
    "connectedTraderEstimatedRewardsInUnits",
};

const sortings = new Sortings(headerValuePays);

export type TActiveRewardsTableRowGist = {
  lexId: string;
  sourceChainId: TSourceChainIds;
  assetSymbol: string;

  rewardAssetSymbol: string;
  totalWeeklyCompetitionRewardsInUnits: number;
  rewardAssetPriceInUnits: number;

  connectedTraderEstimatedRewardsInUnits: number;
};

interface IProps {
  rowGists: TActiveRewardsTableRowGist[];

  connectedChainId: TChainIdsWithNone;

  isConnectedToWallet: boolean;
  requestConnectToWalletFunction: () => void;
  switchChainFunction: (chainId: TChainIds) => void;

  // goToLeaderboard: (chainId: TChainIds, saSymbol: string) => void;
  goToLeaderboard: (lexId: string) => void;
  goToTrade: (saSymbol: string) => void;
}

const useStyles = makeStyles()((theme) => ({}));

export const ActiveRewardsTable = React.memo<IProps>((props) => {
  const { classes } = useStyles();
  const {
    rowGists,
    connectedChainId,
    isConnectedToWallet,
    requestConnectToWalletFunction,
    switchChainFunction,
    goToLeaderboard,
    goToTrade,
  } = props;

  const isMobile = useIsMobile();

  const columns: ColumnDef<TActiveRewardsTableRowGist>[] = useMemo<
    ColumnDef<TActiveRewardsTableRowGist>[]
  >(() => {
    return [
      {
        header: "Source Chain",
        id: "sourceChainId",
        cell: (cellProps) => {
          return commonTableCellsBuilders.buildBlockchainCell(
            LIVE_CHAIN_CONSTANTS[cellProps.row.original.sourceChainId]
              ?.shortDisplayName ?? "",
          );
        },
      },
      {
        header: "Trading Asset",
        id: "sourceUnderlyingSymbol",
        enableSorting: true,
        cell: (props1) => {
          return commonTableCellsBuilders.buildAssetTableCell(
            props1.row.original.assetSymbol,
          );
        },
      },
      {
        header: "Trading Competition",
        id: "totalWeeklyCompetitionRewardsInUnits",
        enableSorting: true,
        cell: (cellProps) =>
          commonTableCellsBuilders.buildTextCell(
            `${localStringer(cellProps.row.original.totalWeeklyCompetitionRewardsInUnits)} ${cellProps.row.original.rewardAssetSymbol}`,
            `~${localPriceStringer(cellProps.row.original.totalWeeklyCompetitionRewardsInUnits * cellProps.row.original.rewardAssetPriceInUnits)}`,
          ),
      },
      // {
      //   header: "Estimated Rewards",
      //   id: "connectedTraderEstimatedRewardsInUnits",
      //   enableSorting: true,
      //   cell: (cellProps) =>
      //     commonTableCellsBuilders.buildTextCell(
      //       `${localStringer(cellProps.row.original.connectedTraderEstimatedRewardsInUnits)} ${cellProps.row.original.assetSymbol}`,
      //     ),
      // },
      {
        header: "",
        id: "actions",
        enableSorting: false,
        cell: (props1) => {
          return (
            <Stack direction={"row"} justifyContent={"end"} gap={1} pr={3}>
              {!isConnectedToWallet ? (
                <TableActionButton
                  text={"Connect Wallet"}
                  onClick={() => requestConnectToWalletFunction()}
                />
              ) : props1.row.original.sourceChainId === connectedChainId ? (
                <>
                  <TableActionButton
                    text={"Trade"}
                    style={{
                      color: THEME_CONSTANTS.brandLightPurple,
                      borderColor: THEME_CONSTANTS.brandLightPurple,
                    }}
                    onClick={() => goToTrade(props1.row.original.assetSymbol)}
                  />
                  <TableActionButton
                    text={"Leaderboard"}
                    style={{
                      color: THEME_CONSTANTS.infoTextOffWhite,
                      borderColor: THEME_CONSTANTS.infoTextOffWhite,
                    }}
                    onClick={() => goToLeaderboard(props1.row.original.lexId)}
                  />
                </>
              ) : (
                <>
                  <TableActionButton
                    text={"Change Chain"}
                    style={{
                      color: THEME_CONSTANTS.infoTextOffWhite,
                      borderColor: THEME_CONSTANTS.infoTextOffWhite,
                    }}
                    onClick={() =>
                      switchChainFunction(props1.row.original.sourceChainId)
                    }
                  />
                  <TableActionButton
                    text={"Leaderboard"}
                    style={{
                      color: THEME_CONSTANTS.infoTextOffWhite,
                      borderColor: THEME_CONSTANTS.infoTextOffWhite,
                    }}
                    onClick={() => goToLeaderboard(props1.row.original.lexId)}
                  />
                </>
              )}
            </Stack>
          );
        },
      },
    ];
  }, [
    connectedChainId,
    goToLeaderboard,
    goToTrade,
    isConnectedToWallet,
    requestConnectToWalletFunction,
    switchChainFunction,
  ]);

  const data = useMemo(() => {
    return [...rowGists];
  }, [rowGists]);

  return (
    <MaterialUiTable
      dinamicCompare={(x: TActiveRewardsTableRowGist) =>
        x.sourceChainId === connectedChainId
      }
      columns={columns}
      data={data}
      getRowId={(val: TActiveRewardsTableRowGist) =>
        `${val.sourceChainId}_${val.assetSymbol}`
      }
      sortings={sortings}
      emptyTableText={"No Rewards Available"}
      tableBorderRadius={isMobile ? 4 : 40}
      rowSpacing="25px 24px 16px 24px"
    />
  );
});
