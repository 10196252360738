import { useEffect, useState } from "react";

// TODO : CRITICAL : Manage to export this type from the ui-components-lib
export type TBaseSelectorOption = {
  value: string;
  text: string;
};

export function useCommonSelectorOptions(defaultValue: string) {
  const [selectedOptionValue, setSelectedOptionValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedOptionValue(defaultValue);
  }, [defaultValue]);

  return {
    selectedOptionValue,
    setSelectedOptionValue,
  };
}
