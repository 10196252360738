import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import { Box, Stack, Typography } from "@mui/material";
import { useStructureClasses } from "../../theme/commonClasses.ts";
import {
  TabSelectorGist,
  TabsSelectorRow,
  YourRewardsPanel,
} from "lynx-ui-components";
import { useIsMobile } from "../../ux/uxHooks.ts";
import { ConnectedActiveRewardsTable } from "./ActiveRewardsTable/ConnectedActiveRewardsTable.tsx";
import { localStringer } from "../../ux/uxFunctions.ts";
import {
  TRewardQueryParam,
  useGetPendingRewardsForUserFromGTD,
} from "./rewardsPageHooks.ts";
import {
  useCryptoWalletIntegrationStore,
  useModalsStore,
} from "../../store/storeHooks.ts";
import {
  BOBA_ENGINE_CHIPS,
  FANTOM_ENGINE_CHIPS,
  FANTOM_OFT_CHIPS,
} from "../../constants/chainAssets.ts";
import { toastInfo } from "../../ux/toasts/toasting.ts";
import { THEME_CONSTANTS } from "../../theme/ThemeConstants.ts";
import { useGetTimeLeftForCurrentRound } from "../CompetitionsPage/competitionHooks.ts";
import { ConnectedHistoricRewardsTable } from "./HistoricRewardsTable/ConnectedHistoricRewardsTable.tsx";
import {
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_FANTOM_OPERA,
  TEngineChainIds,
} from "../../constants/chainConstants.ts";
import { buildGeneralAppBaasInteractionService } from "../../services/servicesIntergration/IGeneralAppBaasInteractionService/IGeneralAppBaasInteractionService.ts";
import { handleDelegatedIntentUxFlow } from "../../ux/delegatedIntentsUx.ts";
import { ExternalLink } from "../../components/buildingParts/links/ExternalLink.tsx";
import { getChainExplorersUrlFromChainId } from "../../ux/chainsHooks.ts";
import { LeaderBoardModalWrapper } from "../../components/Modals/modalWrappers/LeaderBoardModalWrapper.tsx";

interface IProps {}

const useStyles = makeStyles()((theme) => ({}));

const HARD_CODED_REWARDS_PARAMS_FANTOM: TRewardQueryParam[] = [
  // {
  //   assetAddress: FANTOM_OFT_CHIPS.OFT_ARBITRUM_ARB.address,
  //   assetSymbol: "ARB",
  // },
  {
    assetAddress: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_USDC.address,
    assetSymbol: "USDC",
  },
  {
    assetAddress: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_SPIRIT.address,
    assetSymbol: "SPIRIT",
  },
  {
    assetAddress: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_FBUX.address,
    assetSymbol: "fBUX",
  },
  {
    assetAddress: FANTOM_OFT_CHIPS.OFT_FUSE_PLAY_WFUSE.address,
    assetSymbol: "WFUSE",
  },
];

const HARD_CODED_REWARDS_PARAMS_BOBA: TRewardQueryParam[] = [
  {
    assetAddress: BOBA_ENGINE_CHIPS.ENGINE_BOBA_BOBA.address,
    assetSymbol: "BOBA",
  },
];

export const RewardsPage = observer<React.FC<IProps>>((props) => {
  const { classes: pageClasses } = useStructureClasses();

  const walletConnectionStore = useCryptoWalletIntegrationStore();

  const isMobile = useIsMobile();

  const totalWeeklyRewardsInUsd = 0;

  const [selectedTab, setSelectedTab] = useState(0);
  const tabSelectors = useMemo<TabSelectorGist[]>(() => {
    return [
      {
        text: `Active`,
        value: 0,
      },
      {
        text: `Historic`,
        value: 1,
      },
    ];
  }, []);

  const pendingRewardsForUser = useGetPendingRewardsForUserFromGTD(
    walletConnectionStore.mainAddress,
    CHAIN_ID_FANTOM_OPERA,
    HARD_CODED_REWARDS_PARAMS_FANTOM,
  );
  const pendingRewardsForUser_boba = useGetPendingRewardsForUserFromGTD(
    walletConnectionStore.mainAddress,
    CHAIN_ID_BOBA_MAINNET,
    HARD_CODED_REWARDS_PARAMS_BOBA,
  );

  // console.log(
  //   `pendingRewardsForUser_fantom = ${JSON.stringify(pendingRewardsForUser, null, 2)}`,
  // );
  // console.log(
  //   `pendingRewardsForUser_boba = ${JSON.stringify(pendingRewardsForUser_boba, null, 2)}`,
  // );

  const [isWaitingForClaimTx, setIsWaitingForClaimTx] = useState(false);
  const requestRewardsClaiming = useCallback(
    (engineChainId: TEngineChainIds, tokenAddress: string) => {
      const chainExplorerLinks = getChainExplorersUrlFromChainId(engineChainId);

      const generalAppBaasInteractionService =
        buildGeneralAppBaasInteractionService(engineChainId);

      void handleDelegatedIntentUxFlow(
        `Rewards Claiming`,
        `token ${tokenAddress} for user ${walletConnectionStore.mainAddress}`,
        () =>
          generalAppBaasInteractionService.requestPullAllAndClaim(
            tokenAddress,
            walletConnectionStore.mainAddress,
          ),
        walletConnectionStore.mainAddress,
        false,
      )
        .then((txHash) =>
          toastInfo(
            <Typography>
              Claimed Rewards in{" "}
              <ExternalLink
                href={`${chainExplorerLinks.txUrlBase}/${txHash}`}
                text={"tx"}
              />
            </Typography>,
          ),
        )
        .finally(() => setIsWaitingForClaimTx(false));

      // setIsWaitingForClaimTx(true);
      // void lexUserStore
      //   .requestPullAndClaimRewards(tokenAddress)
      //   .then((txHash) => toastInfo(`Claimed Rewards in tx ${txHash}`))
      //   .finally(() => setIsWaitingForClaimTx(false));
    },
    [walletConnectionStore.mainAddress],
  );

  const durationLeftText = useGetTimeLeftForCurrentRound();

  const content = useMemo(() => {
    if (selectedTab == 0) {
      return <ConnectedActiveRewardsTable />;
    } else if (selectedTab == 1) {
      return <ConnectedHistoricRewardsTable />;
    } else {
      return <></>;
    }
  }, [selectedTab]);

  return (
    <div className={pageClasses.pageRoot}>
      <Box className={pageClasses.pageContent_column}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant={"h3"} fontWeight={"bold"}>
            Rewards
          </Typography>

          <Typography variant={"h4"} color={THEME_CONSTANTS.brandYellow}>
            Ends In : {durationLeftText}
          </Typography>

          {/* Total Weekly rewards */}
          {/*<Box*/}
          {/*  justifyContent={"center"}*/}
          {/*  alignItems={"center"}*/}
          {/*  display={"flex"}*/}
          {/*  padding={"11px 20px"}*/}
          {/*  bgcolor={THEME_CONSTANTS.popupBgGray}*/}
          {/*  borderRadius={"8px"}*/}
          {/*>*/}
          {/*  <Typography color={THEME_CONSTANTS.brightGreen}>*/}
          {/*    Total Weekly Rewards {localPriceStringer(totalWeeklyRewardsInUsd)}*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          maxWidth={"calc(min(100rem,90%))"}
          gap={1}
          flexWrap={"wrap"}
          alignItems={isMobile ? "stretch" : "center"}
          alignSelf={isMobile ? "center" : undefined}
          // justifyContent={"center"}
        >
          {[...pendingRewardsForUser, ...pendingRewardsForUser_boba].map(
            (pendingRewardGist) => {
              const hasRewards = pendingRewardGist.pendingAmountInUnits > 0;
              return (
                <YourRewardsPanel
                  rewards_total={localStringer(
                    pendingRewardGist.pendingAmountInUnits,
                    4,
                  )}
                  onClaimsClick={() => {
                    if (hasRewards) {
                      requestRewardsClaiming(
                        pendingRewardGist.engineChainId,
                        pendingRewardGist.assetAddress,
                      );
                    }
                  }}
                  traderRewardsGists={[]}
                  currencyFlag={pendingRewardGist.assetSymbol}
                  actionButtonText={hasRewards ? "claim" : "No Rewards"}
                  actionTypeButton={"primary"}
                  actionButtonDisabled={!hasRewards}
                  showPastRounds={false}
                  key={pendingRewardGist.assetAddress}
                />
              );
            },
          )}
        </Stack>
        <Stack direction={"column"} gap={2}>
          <TabsSelectorRow
            isLabelCentered
            tabSelectors={tabSelectors}
            value={selectedTab}
            onChange={(val) => setSelectedTab(val)}
          />
          {content}
        </Stack>
      </Box>
    </div>
  );
});
