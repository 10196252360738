import { PAIR_CONFIGS_IDS, TPairIds } from "./pairsConstants";

export const PAIR_ID_TO_NAME: Record<TPairIds, string> = {
  [PAIR_CONFIGS_IDS.BTC_USD]: "BTC/USD",
  [PAIR_CONFIGS_IDS.ETH_USD]: "ETH/USD",
  [PAIR_CONFIGS_IDS.BNB_USD]: "BNB/USD",
  [PAIR_CONFIGS_IDS.FTM_USD]: "FTM/USD",
  [PAIR_CONFIGS_IDS.SOL_USD]: "SOL/USD",

  // FOREX
  [PAIR_CONFIGS_IDS.EUR_USD]: "EUR/USD",
  [PAIR_CONFIGS_IDS.GBP_USD]: "GBP/USD",
  [PAIR_CONFIGS_IDS.AUD_USD]: "AUD/USD",
  [PAIR_CONFIGS_IDS.NZD_USD]: "NZD/USD",

  // CAT Mode
  [PAIR_CONFIGS_IDS.BTC_USD_CAT]: "BTC/USD Cat",
  [PAIR_CONFIGS_IDS.ETH_USD_CAT]: "ETH/USD Cat",
};

// NOTE : Used for display, assumes the other asset is USD
export const PAIR_ID_TO_BASE_ASSET: Record<TPairIds, string> = {
  [PAIR_CONFIGS_IDS.BTC_USD]: "BTC",
  [PAIR_CONFIGS_IDS.ETH_USD]: "ETH",
  [PAIR_CONFIGS_IDS.BNB_USD]: "BNB",
  [PAIR_CONFIGS_IDS.FTM_USD]: "FTM",
  [PAIR_CONFIGS_IDS.SOL_USD]: "SOL",

  // FOREX
  [PAIR_CONFIGS_IDS.EUR_USD]: "EUR",
  [PAIR_CONFIGS_IDS.GBP_USD]: "GBP",
  [PAIR_CONFIGS_IDS.AUD_USD]: "AUD",
  [PAIR_CONFIGS_IDS.NZD_USD]: "NZD",

  // CAT Mode
  [PAIR_CONFIGS_IDS.BTC_USD_CAT]: "BTC",
  [PAIR_CONFIGS_IDS.ETH_USD_CAT]: "ETH",
};
