import { FixedNumber } from "ethers";

export function divideOrZero(a: number, b: number) {
  if (a === 0 || b === 0) {
    return 0;
  } else {
    return a / b;
  }
}

/**
 * Divides the two numbers OR returns 0 if one of them is 0.
 */
export function divideOrZeroBn(a: bigint, b: bigint) {
  if (a == 0n || b == 0n) {
    return 0;
  } else {
    const partFxn = FixedNumber.fromValue(a).divUnsafe(
      FixedNumber.fromValue(b),
    );

    return partFxn.toUnsafeFloat();
  }
}
