import { fetchRemoteJson } from "../../web2NetworkActions.ts";

export interface IGekoTerminalPrice {
  id: string;
  type: string;
  attributes: {
    token_prices: Record<string, string>;
  };
}

async function fetchPriceFromCoinGecko(symbol: string): Promise<number> {
  const id =
    COIN_GECKO_SYBOLS_TO_KEYS[symbol as keyof typeof COIN_GECKO_SYBOLS_TO_KEYS];

  if (!id) {
    console.error(`fetchPriceFromCoinGecko :: Unsupported symbol ${symbol}`);
    return 0;
  }

  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`;

  const response = (await fetchRemoteJson(url)) as unknown;

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const priceObject = response[id];
  const priceInUsdUnits = priceObject?.usd ?? 0;

  return priceInUsdUnits;
}

async function fetchPricesFromGekoterminall(
  network: string,
  addresses: string,
): Promise<IGekoTerminalPrice> {
  const api = "https://api.geckoterminal.com/api/v2";
  const path = `/simple/networks/${network}/token_price/${addresses}`;
  const response = await fetchRemoteJson<{ data: IGekoTerminalPrice }>(
    api + path,
  );
  return response.data;
}

async function fetchPricesFromCoinGecko(
  symbols: string[],
): Promise<Record<string, number>> {
  const cgIds = symbols.map(
    (symbol) =>
      COIN_GECKO_SYBOLS_TO_KEYS[
        symbol as keyof typeof COIN_GECKO_SYBOLS_TO_KEYS
      ],
  );

  // @ts-ignore
  if (cgIds.includes(undefined)) {
    console.error(
      `fetchPriceFromCoinGecko :: Unsupported symbols ${cgIds} (${symbols})`,
    );
  }

  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${cgIds.join(",")}&vs_currencies=usd`;

  const response = (await fetchRemoteJson(url)) as unknown;

  const pricesInUnits: Record<string, number> = {};

  for (let i = 0; i < symbols.length; i++) {
    let symbol = symbols[i];
    let cgId = cgIds[i];
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const priceObject = response[cgId];
    const priceInUsdUnits = (priceObject?.usd as number) ?? 0;

    pricesInUnits[symbol] = priceInUsdUnits;
  }

  return pricesInUnits;
}

// https://api.coingecko.com/api/v3/simple/price?ids=&vs_currencies=usd
// https://api.coingecko.com/api/v3/coins/list
const COIN_GECKO_SYBOLS_TO_KEYS = {
  USDC: "usd-coin",
  USDT: "tether",

  FTM: "fantom",

  FUSE: "fuse",
  VOLT: "fusefi",

  DAI: "dai",

  BNB: "binancecoin",
  ARB: "arbitrum",
  BOBA: "boba-network",

  BTC: "bitcoin",
  ETH: "ethereum",
  wETH: "ethereum",
  WETH: "ethereum",
  WFUSE: "wrapped-fuse",
  WFTM: "fantom",
  WMATIC: "wmatic",

  PDAI: "dai",

  LVC: "linea-velocore",
  veLVC: "linea-velocore",

  stEUR: "staked-ageur",
  TST: "standard-token",
  EUROs: "the-standard-euro",
  GRAI: "grai",
  SLIZ: "solidlizard",
  USDFI: "usdfi",

  SONNE: "sonne-finance",

  miMATIC: "mimatic",
  MAI: "mimatic",

  MST: "meridian-mst",

  lisUSD: "lista-usd",

  EQUAL: "equalizer-dex",
  BRUSH: "paint-swap",
  fSONIC: "fantomsonicinu",
  POLTER: "polter-finance",
  fBUX: "fantom-money-market",
  fTAILS: "ftails",
  SPIRIT: "spiritswap",

  MODE: "mode",
  ION: "ionic-protocol",

  CELO: "celo",
};

export const priceFetching = {
  fetchPriceFromCoinGecko,
  fetchPricesFromCoinGecko,
  fetchPricesFromGekoterminall,
};
