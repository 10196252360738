import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { AppWrapper } from "./AppWrapper.tsx";
import * as Sentry from "@sentry/react";
import { IS_ON_PLAYGROUND } from "./configs.ts";
import { initializeSentry } from "./services/servicesIntergration/sentryService/sentryServiceInitialization.ts";

initializeSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
);
