export function extractWeb3ErrorMessage(e: unknown): string {
  return (
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    (e?.info?.error?.data?.message as string) ??
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    (e?.info?.error?.message as string) ??
    // @ts-ignore
    e.toString()
  );
}

export function extractReasonFromErrorText(text: string): string {
  const match = text.match(/reason="([^"]*)"/);
  return match ? match[1] : "";
}

export function extractMsgFromSolidityError(solidityError: string): string {
  const words = solidityError.split("_");
  // const firstWord = words.shift()
  const lowerCaseWords = words.map((word) => word.toLowerCase());
  const restOfPhrase = lowerCaseWords.reduce((acc, word) => {
    return acc + " " + word;
  }, "");

  // return firstWord + " " + restOfPhrase;
  return restOfPhrase;
}
