export async function fetchLocalJson<T = any>(localPath: string): Promise<T> {
  const pathForLocalResource = `${process.env.PUBLIC_URL}/${localPath}`;
  return fetchRemoteJson(pathForLocalResource);
}

export async function fetchRemoteJson<T = any>(remotePath: string): Promise<T> {
  const fetchRes = await fetch(remotePath);
  const json = (await fetchRes.json()) as T;

  return json;
}

export async function fetchRemoteText(remotePath: string): Promise<string> {
  const fetchRes = await fetch(remotePath);
  const text = await fetchRes.text();

  return text;
}

// export async function fetchRemoteText<T = any>(remotePath: string): Promise<T> {
//   const fetchRes = await fetch(remotePath);
//   // @ts-ignore
//   const text = (await fetchRes.text()) as T;
//
//   console.log(`!!! ${text}`);
//
//   return text;
// }
