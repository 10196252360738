/* eslint-disable mobx/missing-observer */
import React, { createContext, useContext, useState } from "react";

type LoadingBridgeTxContextType = {
  loadingBridgeTxGists: Record<string, boolean>;
  setLoadingBridgeTxGists: (key: string, value: boolean) => void;
};

const LoadingBridgeTxContext = createContext<
  LoadingBridgeTxContextType | undefined
>(undefined);

export const LoadingBridgeTxProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [loadingBridgeTxGists, setLoadingBridgeTxGistsState] = useState<
    Record<string, boolean>
  >({});

  const setLoadingBridgeTxGists = (key: string, value: boolean) => {
    setLoadingBridgeTxGistsState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <LoadingBridgeTxContext.Provider
      value={{ loadingBridgeTxGists, setLoadingBridgeTxGists }}
    >
      {children}
    </LoadingBridgeTxContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useLoadingBridgeTx = () => {
  const context = useContext(LoadingBridgeTxContext);
  if (!context) {
    throw new Error(
      "useLoadingBridgeTx must be used within a LoadingBridgeTxProvider",
    );
  }
  return context;
};