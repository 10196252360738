import { BytesLike, Signer, Provider } from "ethers";
import {
  EMPTY_COMPLETE_POSITION_DATA_FROM_LENS,
  EMPTY_TRADING_FLOOR_TRADE_PARAMS,
  ITradingFloorLensService,
  TCompletePositionDataFromLens,
  TTradingFloorLensTradeParamsStruct,
} from "./ITradingFloorLensService";
import {
  ethersStructResponseToArray,
  ethersStructResponseToObject,
} from "../../../utils/ethersTypes";
import { TradingFloorLens } from "../../../typechain/contracts/Peripheral/Lens/TradingFloorLens.ts";
import { TradingFloorLens__factory } from "../../../typechain/factories/contracts/Peripheral/Lens/TradingFloorLens__factory.ts";
import { UpdatePositionFieldOrderTypeEnums } from "../../../constants/contractEnums.ts";

export class TradingFloorLensService implements ITradingFloorLensService {
  private tradingFloorLensContract: TradingFloorLens;

  constructor(
    compoundLensAddress: string,
    signerOrProvider: Signer | Provider,
  ) {
    this.tradingFloorLensContract = TradingFloorLens__factory.connect(
      compoundLensAddress,
      signerOrProvider,
    );
    // this.tradingFloorLensContract = new Contract(
    //   compoundLensAddress,
    //   TradingFloorLensJson.abi,
    //   signerOrProvider,
    // ) as TradingFloorLens;
  }

  async getTradingFloorTradeParams(
    tradingFloorAddress: string,
  ): Promise<TTradingFloorLensTradeParamsStruct> {
    return ethersStructResponseToObject<TTradingFloorLensTradeParamsStruct>(
      await this.tradingFloorLensContract.getTradingFloorTradeParams(
        tradingFloorAddress,
      ),
      EMPTY_TRADING_FLOOR_TRADE_PARAMS,
    );
  }

  async getCompletePositionData(
    tradingFloorAddress: string,
    positionId: BytesLike,
  ): Promise<TCompletePositionDataFromLens> {
    return ethersStructResponseToObject<TCompletePositionDataFromLens>(
      await this.tradingFloorLensContract.getCompletePositionData(
        tradingFloorAddress,
        positionId,
      ),
      EMPTY_COMPLETE_POSITION_DATA_FROM_LENS,
    );
  }

  async getCompletePositionsDataInPairForTrader(
    tradingFloorAddress: string,
    settlementAsset: string,
    trader: string,
    pairId: number,
  ): Promise<TCompletePositionDataFromLens[]> {
    return ethersStructResponseToArray<TCompletePositionDataFromLens>(
      await this.tradingFloorLensContract.getPositionsDataInPairForTrader.staticCall(
        tradingFloorAddress,
        settlementAsset,
        trader,
        pairId,
      ),
      EMPTY_COMPLETE_POSITION_DATA_FROM_LENS,
    );
  }

  async getTradesInPairsForTrader(
    tradingFloorAddress: string,
    settlementAsset: string,
    trader: string,
    pairIds: number[],
  ): Promise<TCompletePositionDataFromLens[]> {
    return ethersStructResponseToArray<TCompletePositionDataFromLens>(
      await this.tradingFloorLensContract.getPositionDataInPairsForTrader.staticCall(
        tradingFloorAddress,
        settlementAsset,
        trader,
        pairIds,
      ),
      EMPTY_COMPLETE_POSITION_DATA_FROM_LENS,
    );
  }

  async getAllTradesForTraderInDimension(
    tradingFloorAddress: string,
    settlementAsset: string,
    trader: string,
  ): Promise<TCompletePositionDataFromLens[]> {
    const res = ethersStructResponseToArray<TCompletePositionDataFromLens>(
      await this.tradingFloorLensContract.getAllPositionsDataForTraderInDimension.staticCall(
        tradingFloorAddress,
        settlementAsset,
        trader,
      ),
      EMPTY_COMPLETE_POSITION_DATA_FROM_LENS,
    );

    const nonEmptyPositions = res.filter((pos) => pos.phase > 0);

    return nonEmptyPositions;
  }
}
