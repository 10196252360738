import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LiquidityDialog, InfoPanel } from "lynx-ui-components";
import {
  LIVE_CHAIN_CONSTANTS,
  TChainIds,
} from "../../../constants/chainConstants.ts";
import { TChipHolderInfoGist } from "../../../pages/PortfolioPage/subComponent/ChipsInfoTable/ChpisInfoTable.tsx";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
} from "../../../store/storeHooks.ts";
import { useSwitchChain } from "wagmi";
import { numericalDisplay } from "../../../ux/displayCalculations.ts";
import { localStringer } from "../../../ux/uxFunctions.ts";
import { StatusToastManager } from "../../../ux/stepToast/statusToastManager.tsx";
export const chipLiquidityToast = new StatusToastManager();

type TNetworkOption = {
  label: string;
  id: TChainIds;
};

interface IProps {
  open: boolean;
  closeModal: () => void;
  title: string;
  actionName: string;
  assetSymbol: string;
  nativeCoinSymbol: string;
  chainId: number;
  nativeActionFeeInUnits: number;
  assetActionFeeInUnits: number;

  availableAmountInUnits: number;
  nativeBalanceInUnits: number;

  needToApprove: boolean;

  isWaitingForTx: boolean;
  isLoadingFeesData: boolean;

  actionCallback: (amountInUnits: number) => void | Promise<void>;
  approveCallback: () => void | Promise<void>;

  isEngineChip: boolean;

  nativeCoinDisplayDecimals?: number;
  assetDisplayDecimals?: number;
  onChangeLexId: (lexId: string) => void;
  onSetAssetAmountInString: (value: string) => void;
  assetAmountInString: string;
}

export const ChipLiquidityModal = React.memo<IProps>((props) => {
  const {
    open,
    closeModal,
    title,
    actionName,
    assetSymbol,
    nativeCoinSymbol,
    chainId,
    nativeActionFeeInUnits,
    assetActionFeeInUnits,
    availableAmountInUnits,
    nativeBalanceInUnits,
    isWaitingForTx,
    isLoadingFeesData,
    actionCallback,
    isEngineChip,
    needToApprove,
    approveCallback,
    onChangeLexId,
    onSetAssetAmountInString,
    assetAmountInString,
  } = props;

  const nativeCoinDisplayDecimals = props.nativeCoinDisplayDecimals ?? 3;
  const assetDisplayDecimals = props.assetDisplayDecimals ?? 3;
  const leverageDimensionsStore = useLeverageDimensionsStore();

  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const walletConnectionStore = useCryptoWalletIntegrationStore();

  const { switchChain } = useSwitchChain();

  const getChainId = useCallback(() => {
    return Object.values(LIVE_CHAIN_CONSTANTS).find(
      (c) => c.shortDisplayName === selectedNetwork,
    )?.id;
  }, [selectedNetwork]);

  const isChainIdCurrent = useMemo(() => {
    return (
      walletConnectionStore.chainId ===
      Object.values(LIVE_CHAIN_CONSTANTS).find(
        (c) => c.shortDisplayName === selectedNetwork,
      )?.id
    );
  }, [selectedNetwork, walletConnectionStore.chainId]);

  const chipHolderInfoGists = leverageDimensionsStore.lexStores
    .map((lexStore) => {
      const chipHolderInfoGist: TChipHolderInfoGist = {
        lexId: lexStore.id,
        balanceAvailableOnEngineInUnits:
          lexStore.activeUserStore
            .accountUnderlyingBalanceInWalletOnEngineChainInUnits,
        balanceInPositionsInUnits:
          lexStore.activeUserStore
            .accountUnderlyingBalanceInOpenPositionsInUnits,
        balanceInPoolInUnits: lexStore.activeUserStore.accountSupplyInUnits,
        sourceChainId: lexStore.sourceChainId,
        sourceUnderlyingSymbol: lexStore.sourceAssetParameters.symbol,
      };

      return chipHolderInfoGist;
    })
    .sort((a, b) => {
      // Start with sort by connected chain
      if (
        b.sourceChainId == walletConnectionStore.chainId &&
        a.sourceChainId != walletConnectionStore.chainId
      ) {
        return 1;
      } else if (
        a.sourceChainId == walletConnectionStore.chainId &&
        b.sourceChainId != walletConnectionStore.chainId
      ) {
        return -1;
      } else {
        const bSum =
          b.balanceAvailableOnEngineInUnits +
          b.balanceInPositionsInUnits +
          b.balanceInPoolInUnits;
        const aSum =
          a.balanceAvailableOnEngineInUnits +
          a.balanceInPositionsInUnits +
          a.balanceInPoolInUnits;

        return bSum - aSum;
      }
    });

  const getLexId = useCallback(
    (symbol: string) => {
      return (
        chipHolderInfoGists.find(
          (c) =>
            c.sourceUnderlyingSymbol.toLowerCase() === symbol.toLowerCase(),
        )?.lexId ?? ""
      );
    },
    [chipHolderInfoGists],
  );

  const { actionButtonText, actionButtonAction, actionButtonDisabled } =
    useMemo(() => {
      let actionButtonText: string;
      let actionButtonAction: () => void | Promise<void>;
      let actionButtonDisabled = false;

      if (isLoadingFeesData) {
        actionButtonText = "Loading data...";
        actionButtonAction = () => undefined;
        // actionButtonDisabled = true;
      } else if (isWaitingForTx) {
        actionButtonText = "Waiting for TX...";
        actionButtonAction = () => undefined;
        // actionButtonDisabled = true;
      } else if (needToApprove) {
        actionButtonText = "approve";
        actionButtonAction = () => approveCallback();
      } else if (nativeBalanceInUnits < nativeActionFeeInUnits) {
        actionButtonText = "Not Enough Native";
        actionButtonAction = () => undefined;
        actionButtonDisabled = true;
      } else {
        const assetAmountInUnits = Number(assetAmountInString);

        if (assetAmountInUnits <= availableAmountInUnits) {
          actionButtonText = actionName;
          actionButtonAction = () => actionCallback(Number(assetAmountInUnits));
        } else {
          actionButtonText = "Exceeds Balance";
          actionButtonAction = () => undefined;
          actionButtonDisabled = true;
        }
      }

      if (!isChainIdCurrent) {
        actionButtonText = "Change Chain";
        actionButtonAction = () => {
          switchChain({ chainId: getChainId() as number });
        };
        actionButtonDisabled = false;
      }

      return {
        actionButtonText,
        actionButtonAction,
        actionButtonDisabled,
      };
    }, [
      actionCallback,
      actionName,
      approveCallback,
      assetAmountInString,
      availableAmountInUnits,
      getChainId,
      isChainIdCurrent,
      isLoadingFeesData,
      isWaitingForTx,
      nativeActionFeeInUnits,
      nativeBalanceInUnits,
      needToApprove,
      switchChain,
    ]);

  const networkOptions = useMemo(() => {
    const filteredData: TNetworkOption[] = Object.values(
      LIVE_CHAIN_CONSTANTS,
    ).map((el) => ({ label: el.shortDisplayName, id: el.id }));
    return [...filteredData];
  }, []);

  const tokenOptions = useCallback(
    (network: string) => {
      const filteredData: string[] = network
        ? chipHolderInfoGists
            .filter(
              (el) =>
                network ===
                LIVE_CHAIN_CONSTANTS[el.sourceChainId].shortDisplayName,
            )
            .map((el) => el.sourceUnderlyingSymbol)
        : [];
      return [...filteredData];
    },
    [chipHolderInfoGists],
  );

  const onNetworkChange = useCallback(
    (network: string) => {
      setSelectedNetwork(network);
      const options = tokenOptions(network);
      if (options) {
        onChangeLexId(getLexId(options[0]));
      }
    },
    [getLexId, onChangeLexId, tokenOptions],
  );

  useEffect(() => {
    if (chainId && networkOptions) {
      const network = networkOptions.find((n) => n.id === chainId);
      if (network) {
        setSelectedNetwork(network.label);
      } else {
        setSelectedNetwork("");
      }
    } else {
      setSelectedNetwork("");
    }
  }, [chainId, networkOptions]);

  useEffect(() => {
    const options = tokenOptions(selectedNetwork);
    if (props.assetSymbol && options) {
      const token = options.find(
        (t) => t.toLowerCase() === props.assetSymbol.toLowerCase(),
      );
      setSelectedToken(token ?? options[0]);
    }
  }, [props.assetSymbol, selectedNetwork, tokenOptions]);

  const onTokenChange = useCallback(
    (token: string) => {
      setSelectedToken(token);
      onChangeLexId(getLexId(token));
    },
    [getLexId, onChangeLexId],
  );

  const panelComponent = useMemo(() => {
    return (
      <InfoPanel
        panelStyle={{ padding: 0, backgroundColor: "transparent" }}
        headerPayload={{ panelTitle: "", panelSubTitle: "" }}
        sections={[
          {
            sectionKey: "summary",
            rows: [
              {
                title: `${actionName} Amount`,
                text: `${assetAmountInString} ${assetSymbol}`,
              },
              {
                title: nativeActionFeeInUnits > 0 ? `Gas Fee` : "",
                text: `${numericalDisplay(
                  nativeActionFeeInUnits,
                  nativeCoinDisplayDecimals,
                )} ${nativeCoinSymbol}`,
              },
              {
                title: `Bridge Fee`,
                text: `${numericalDisplay(
                  assetActionFeeInUnits,
                  assetDisplayDecimals,
                )} ${assetSymbol}`,
              },
              {
                title: `Estimated Time`,
                text: isEngineChip ? "< 1 minute" : "1-3 minutes",
              },
              {
                title: "Native Balance",
                text: localStringer(nativeBalanceInUnits),
              },
              // NOTE : Trick to make it easy to dynamically remove rows
            ].filter((r) => !!r.title),
          },
        ]}
      />
    );
  }, [
    actionName,
    assetActionFeeInUnits,
    assetAmountInString,
    assetDisplayDecimals,
    assetSymbol,
    isEngineChip,
    nativeActionFeeInUnits,
    nativeBalanceInUnits,
    nativeCoinDisplayDecimals,
    nativeCoinSymbol,
  ]);

  return (
    <LiquidityDialog
      networkTitle="Choose the source chain"
      networksOptions={networkOptions.map((el) => el.label)}
      tokenTitle="Choose the token"
      tokensOptions={tokenOptions(selectedNetwork)}
      selectedNetwork={selectedNetwork}
      onSelectNetwork={onNetworkChange}
      selectedToken={selectedToken}
      onSelectToken={onTokenChange}
      open={open}
      closeModal={closeModal}
      title={title}
      availableAmountInUnits={availableAmountInUnits}
      assetSymbol={assetSymbol}
      assetAmountInString={assetAmountInString}
      onSetAssetAmountInString={onSetAssetAmountInString}
      amountTitle="Amount to deposit"
      buttonText={actionButtonText}
      isButtonDisabled={actionButtonDisabled}
      onBottonCklick={actionButtonAction}
      amountSubTitle={"Available:"}
      panelComponent={panelComponent}
    />
  );
});

// export default ChipLiquidityModal as (props: IProps) => React.JSX.Element;
