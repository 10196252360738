// eslint-disable @typescript-eslint/no-unsafe-member-access
export function stringifyObject(o: object, spaces = 0): string {
  return JSON.stringify(
    o,
    (key, value) => replacerForBigint(key, value),
    // {
    //   // Note : Quick-n-Dirty solution to identify 'bigint'
    //   if (typeof value === "bigint") {
    //     return value.toString();
    //   } else {
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    //     return value;
    //   }
    // },
    spaces,
  );
}

export const replacerForBigint = (key: string, value: unknown) => {
  if (typeof value === "bigint") {
    return value.toString(); // Convert BigInt to string
  }
  return value;
};
