import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "tss-react/mui";
import { usePairStoreFromActiveLed } from "../../../store/multiInstanceStoreHooks";
import { ConnectedTitleAndValue } from "../../../components/Cards/ConnectedTitleAndValue";
import { InPracticeTooltipContent } from "../../../components/ToolTips/InPracticeTooltipContent";
import { Typography } from "@mui/material";
import { useIsMobile } from "../../../ux/uxHooks.ts";

interface IProps {
  pairId: number;
}

const useStyles = makeStyles()((theme) => ({
  infoItemsContainer: {
    flex: 1,
    padding: "0.5rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1rem",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },

  titleAndValueContainer: {
    flex: 1,
  },
}));

export const PairCapsContent = observer<React.FC<IProps>>((props) => {
  const { classes } = useStyles();
  const { pairId } = props;

  const pairStore = usePairStoreFromActiveLed(pairId);

  const isMobile = useIsMobile();

  const localStringer = useCallback((num: number, decimals = 3) => {
    return num.toLocaleString(undefined, {
      maximumFractionDigits: decimals,
    });
  }, []);

  // TODO : Use a 'connected' component to avoid the 'Loading...' display
  if (!pairStore) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          // flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {" "}
        <Typography>Loading ...</Typography>
      </div>
    );
  }

  const lexStore = pairStore.ownLexStore;

  const saSymbol = lexStore.sourceAssetParameters.symbol;

  const minOpenFeeInUnits = lexStore.minOpenFeeInUnits;
  const maxPositionSize = pairStore.effectiveCap_maxPositionSizeInUnits;

  const minLeverageInUnits = pairStore.effectiveCap_minLeverageInUnits;
  const maxLeverageInUnits = pairStore.effectiveCap_maxLeverageInUnits;

  const maxBorrowInUnits = pairStore.effectiveCap_maxBorrowAmountInUnits;
  const maxOpenInterestInUnits = pairStore.effectiveCap_maxOpenInterestInUnits;

  const maxSkewInUnits = pairStore.effectiveCap_maxSkewInUnits;
  const maxGainInUnits = pairStore.effectiveCap_maxGainInUnits;

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
        maxWidth: "100%",
      }}
    >
      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Min Open Fee"}
          text={`${localStringer(minOpenFeeInUnits, 3)} ${saSymbol}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={
                "The minimum amount of open fee required form a position in order to be opened"
              }
              inPractice={`The open fee of a position must be at least ${localStringer(
                minOpenFeeInUnits,
                3,
              )} ${saSymbol}`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Max Position Size"}
          text={`${localStringer(maxPositionSize, 3)} ${saSymbol}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={"The largest size of a leveraged position"}
              inPractice={`Position's collateral*leverage cannot pass ${localStringer(
                maxPositionSize,
                3,
              )} ${saSymbol}`}
            />
          }
        />
      </div>

      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Min Leverage"}
          text={`${localStringer(minLeverageInUnits, 2)}X`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={
                "The minimum leverage allowed for positions in this pair"
              }
              inPractice={`No Position can be opened for this pair with a leverage of less than ${localStringer(
                minLeverageInUnits,
                2,
              )}X`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Max Leverage"}
          text={`${localStringer(maxLeverageInUnits, 2)}X`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={
                "The maximum leverage allowed for positions in this pair"
              }
              inPractice={`No Position can be opened for this pair with a leverage of more than ${localStringer(
                maxLeverageInUnits,
                2,
              )}X`}
            />
          }
        />
      </div>

      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Max Open Interest"}
          text={`${localStringer(maxOpenInterestInUnits, 3)} ${saSymbol}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={
                "The max open interest (sum of all leveraged positions) for either side)"
              }
              inPractice={`The max open interest in either the shorts or the longs side cannot pass ${localStringer(
                maxOpenInterestInUnits,
                3,
              )} ${saSymbol}`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Max Borrow"}
          text={`${localStringer(maxBorrowInUnits, 3)} ${saSymbol}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={`The max amount of ${saSymbol} tokens that can be borrowed by both the longs and shorts`}
              inPractice={`The sum of ${saSymbol} tokens borrowed (earning potential of traders) of both longs and shorts in this pair cannot pass ${localStringer(
                maxBorrowInUnits,
                3,
              )}`}
            />
          }
        />
      </div>

      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Max Skew"}
          text={`${localStringer(maxSkewInUnits, 3)} ${saSymbol}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={
                "The max difference in open interest between the longs and the shorts"
              }
              inPractice={`The difference in the amount of open interest between the shorts and the longs side cannot pass ${localStringer(
                maxSkewInUnits,
                3,
              )} ${saSymbol}`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Max Traders Gain"}
          text={`${localStringer(maxGainInUnits, 3)} ${saSymbol}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={`The max amount of accumulated ${saSymbol} tokens that the traders can earn before trade in this pair stops`}
              inPractice={`The amount of ${saSymbol} tokens gained over time by traders cannot pass ${localStringer(
                maxGainInUnits,
                3,
              )} before it will need to be reset`}
            />
          }
        />
      </div>
    </div>
  );
});
