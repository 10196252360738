import { IEngineChipService } from "./IEngineChipService.ts";
import {
  BytesLike,
  ContractTransactionResponse,
  Provider,
  Signer,
} from "ethers";
import { EngineChip } from "../../../typechain/contracts/Lynx/Chips/EngineChip/EngineChip.ts";
import { EngineChip__factory } from "../../../typechain/factories/contracts/Lynx/Chips/EngineChip/EngineChip__factory.ts";

export class EngineChipService implements IEngineChipService {
  private engineChipAdapterContract: EngineChip;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.engineChipAdapterContract = EngineChip__factory.connect(
      address,
      signerOrProvider,
    );
  }

  public async mintChip(
    toAddress: string,
    amount: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.engineChipAdapterContract.mintChip(toAddress, amount);
  }

  public async burnChip(
    receiverAddress: string,
    amount: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.engineChipAdapterContract.burnChip(receiverAddress, amount);
  }

  public async burnChipAndCall(
    amount: bigint,
    payload: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.engineChipAdapterContract.burnChipAndCall(amount, payload);
  }
}
