import { ContractTransactionResponse, Provider, Signer } from "ethers";
import { IWrappedNativeEngineChipHelperService } from "./IWrappedNativeEngineChipHelperService.ts";
import { WrappedNativeEngineChipHelper } from "../../../typechain/contracts/Peripheral/Chips/WrappedNativeEngineChipHelper.ts";
import { WrappedNativeEngineChipHelper__factory } from "../../../typechain/factories/contracts/Peripheral/Chips/WrappedNativeEngineChipHelper__factory.ts";

export class WrappedNativeEngineChipHelperService
  implements IWrappedNativeEngineChipHelperService
{
  private wrappedNativeEngineChipHelperContract: WrappedNativeEngineChipHelper;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.wrappedNativeEngineChipHelperContract =
      WrappedNativeEngineChipHelper__factory.connect(address, signerOrProvider);
  }

  public wrapNativeAndMintEngineChip(
    nativeAmount: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.wrappedNativeEngineChipHelperContract.wrapNativeAndMintEngineChip(
      {
        value: nativeAmount,
      },
    );
  }
}
