import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  useActiveLexStoreStore,
  useContractsServicesStore,
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
} from "../store/storeHooks";
import { useUrlQueryValue_overrideAccount } from "../navigation/navigationStateHooks.ts";
import { useAccount, useWalletClient } from "wagmi";
import { WalletClient } from "viem";
import { BrowserProvider, JsonRpcSigner, Provider } from "ethers";
import { TChainIds } from "../constants/chainConstants.ts";
import { etherBnToFloat } from "../utils/bignumbers.ts";
import { useLocalStorage } from "@uidotdev/usehooks";

export function useRequestWalletConnect() {
  const { open } = useWeb3Modal();
  const requestConnectToWallet = useCallback(() => {
    open().catch((e) => {
      console.error(`Failed requestConnectToWallet ${e}`);
    });
  }, [open]);

  return requestConnectToWallet;
}

/**
 * Syncs between the account connected via wallet and the store (with potential account override)
 */
export function useSyncWalletAndStore() {
  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const activeLeverageDimensionStore = useActiveLexStoreStore();

  const { accountOverrideValue } = useUrlQueryValue_overrideAccount();

  // Handles connected address
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();

  if (accountOverrideValue) {
    console.log(`%%%%% accountOverrideValue ${accountOverrideValue}`);
  }

  // Syncing wallet address with store
  useEffect(() => {
    if (accountOverrideValue) {
      walletConnectionStore.setMainAddress(accountOverrideValue);
    } else if (!isConnected) {
      walletConnectionStore.setMainAddress("");
    } else if (isConnected) {
      if (address && walletConnectionStore.mainAddress !== address) {
        // Setting main address
        walletConnectionStore.setMainAddress(address);

        // TODO : Read all info
        // Refresh all stores
        // activeLeverageDimensionStore
        //   .refreshFromOutside()
        //   .catch((e) =>
        //     console.error(
        //       `Failed activeLeverageDimensionStore.refreshFromOutside ${e}`,
        //     ),
        //   );
      }
    }
  }, [
    accountOverrideValue,
    activeLeverageDimensionStore,
    address,
    isConnected,
    isDisconnected,
    walletConnectionStore,
  ]);

  // Syncing Signer with store
  const signer = useEthersSigner();
  useEffect(() => {
    if (signer) {
      console.log(
        // `DEBUG --- Setting signer ${signer.address} ${signer.provider._network.chainId}`,
        `DEBUG --- Setting signer ${signer.address}`,
      );
      walletConnectionStore.setChainSigner(signer);
    } else {
      console.log(`DEBUG -- No Signer`);
    }
  }, [signer, walletConnectionStore]);

  // const leverageDimensionsStore = useLeverageDimensionsStore();
  // useEffect(() => {
  //   const address = walletConnectionStore.mainAddress;
  //   const signer = walletConnectionStore.getChainSigner;
  //   console.log(
  //     `---- Effect refreshing from outside ${address} | signer ${signer}`,
  //   );
  //   leverageDimensionsStore.lexStores.forEach((store) => {
  //     store
  //       .refreshFromOutside()
  //       .catch((e) =>
  //         console.error(
  //           `Failed activeLeverageDimensionStore.refreshFromOutside ${e}`,
  //         ),
  //       );
  //   });
  // }, [
  //   activeLeverageDimensionStore,
  //   leverageDimensionsStore.lexStores,
  //   walletConnectionStore.getChainSigner,
  //   walletConnectionStore.mainAddress,
  // ]);
}

function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  };
  const provider = new BrowserProvider(transport, network);
  const signer = new JsonRpcSigner(provider, account?.address ?? "");
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });

  console.log(
    `DEBUG -- walletClient ${walletClient?.account.address ?? "No wallet"}`,
  );

  return React.useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient],
  );
}

export function useProviderForChain(chainId?: TChainIds): Provider | undefined {
  const [provider, setProvider] = useState<Provider | undefined>(undefined);

  const contractServicesStore = useContractsServicesStore();

  useEffect(() => {
    if (chainId) {
      const provider = contractServicesStore.getProviderForChainId(chainId);
      setProvider(provider);
    }
  }, [chainId, contractServicesStore]);

  return provider;
}

export function useReadNativeBalanceOnChainId(
  chainId: TChainIds,
  account: string,
) {
  // const [nativeBalance, setNativeBalance] = useState(0n);
  const [nativeBalance, setNativeBalance] = useLocalStorage(
    `native_balance_${chainId}_${account}`,
    "0",
  );

  const provider = useProviderForChain(chainId);

  useEffect(() => {
    if (provider && account) {
      provider
        .getBalance(account)
        .then((balance) => {
          setNativeBalance(balance.toString());
        })
        .catch((e) =>
          console.error(`Error reading native balance via ethers.js : ${e}`),
        );
    }
  }, [account, provider, setNativeBalance]);

  return {
    nativeBalance: BigInt(nativeBalance),
  };
}
