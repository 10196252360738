import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MaterialUiTable } from "../../../../components/Tables/MaterialUiTable";
import { Button, Typography } from "@mui/material";
import { TPendingEpochDepositComplete } from "../../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import { localStringer } from "../../../../ux/uxFunctions";
import { BaseTokenIcon } from "lynx-ui-components";
import { commonTableCellsBuilders } from "../../../../components/Tables/commonTableCellsBuilders.tsx";

interface IProps {
  pendingEpochDeposits: TPendingEpochDepositComplete[];
  epochLengthInSeconds: number;
}

export const PendingEpochDepositsTable = React.memo<IProps>((props) => {
  const { pendingEpochDeposits, epochLengthInSeconds } = props;

  const columns: ColumnDef<TPendingEpochDepositComplete>[] = useMemo<
    ColumnDef<TPendingEpochDepositComplete>[]
  >(() => {
    return [
      {
        header: "Pool",
        id: "pool",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.epochNumber)}`,
        cell: (props1) =>
          commonTableCellsBuilders.buildAssetTableCell(
            props1.cell.row.original.underlyingSymbol,
          ),
      },
      {
        header: "In Epoch",
        id: "epochNumber",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.epochNumber)}`,
      },
      {
        header: "Amount",
        id: "amountInUnits",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.amountInUnits)}`,
      },
      {
        header: "Processe State",
        id: "processedBy",
        accessorFn: (originalRow) => {
          const epochDiff =
            originalRow.epochNumber - originalRow.poolCurrentEpoch;

          if (epochDiff == 0) {
            return "Any Minute Now";
          } else if (epochDiff < 0) {
            return "Epoch Passed";
          } else {
            return epochDiff > 1
              ? `in ${epochDiff} epochs`
              : "Waiting for next epoch";
          }
        },
      },
      // {
      //   header: "Minimum LP To Receive",
      //   id: "minAmountOutInUnits",
      //   accessorFn: (originalRow) =>
      //     `${localStringer(
      //       originalRow.minAmountOutInUnits,
      //     )} ${underlyingSymbol}-LP`,
      // },

      // TODO : Add option to close pending options
      // {
      //   header: "Process",
      //   id: "process",
      //   // TODO : Only show this if the timeout buffer has passed
      //   cell: (cell) => {
      //     const canBeProcessed =
      //       currentPoolEpoch == cell.cell.row.original.epochNumber;
      //
      //     if (canBeProcessed) {
      //       return (
      //         <Button onClick={() => showProcessDeposit()} variant={"outlined"}>
      //           Finalize Deposit
      //         </Button>
      //       );
      //     } else {
      //       return <Typography>Not yet</Typography>;
      //     }
      //   },
      // },
    ];
  }, []);

  const data = useMemo(() => {
    return [...pendingEpochDeposits];
  }, [pendingEpochDeposits]);

  return (
    <MaterialUiTable
      columns={columns}
      data={data}
      getRowId={(val: TPendingEpochDepositComplete) =>
        `${val.account}_${val.epochNumber}_${val.amountInUnits}`
      }
    />
  );
});
