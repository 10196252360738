import type { TCompetitionParticipantGist } from "../../../../services/servicesIntergration/competitionGraphService/ICompetitionGraphService.ts";

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { TCompetitionConfigs } from "../../competitionsConfigs.ts";
import CompetitionParticipantsTable from "./CompetitionParticipantsTable.tsx";
import { CompetitionGraphService } from "../../../../services/servicesIntergration/competitionGraphService/CompetitionGraphService.ts";

interface IProps {
  competitionConfigs: TCompetitionConfigs;
  currentUserAddress: string;
}

export const ConnectedCompetitionParticipantsTable = observer<React.FC<IProps>>(
  (props) => {
    const { competitionConfigs, currentUserAddress } = props;

    const [competitionsParticipantsGists, setCompetitionsParticipantsGists] =
      useState<TCompetitionParticipantGist[]>([]);

    useEffect(() => {
      if (competitionConfigs.externalUrls.graphUrl) {
        readCompetitionParticipantsGists(
          competitionConfigs.externalUrls.graphUrl,
        )
          .then((gists) => {
            const orderedGists = gists.sort((a, b) => {
              if (a.volume == 0n && b.volume == 0n) {
                return 0;
              } else if (a.volume === 0n && b.volume > 0n) {
                return 1;
              } else if (a.volume > 0n && b.volume == 0n) {
                return -1;
              }
              return a.totalPnL > b.totalPnL ? -1 : 1;
            });

            setCompetitionsParticipantsGists(orderedGists);
          })
          .catch((e) =>
            console.error(`Failed readCompetitionParticipantsGists ${e}`),
          );
      }
    }, [competitionConfigs.externalUrls.graphUrl]);

    return (
      <CompetitionParticipantsTable
        currentUser={currentUserAddress}
        saSymbol={competitionConfigs.conditionsParams.collateralAssetSymbol}
        saDecimals={18}
        rewardAssetSymbol={competitionConfigs.prizeParams.rewardTokenSymbol}
        participantsGists={competitionsParticipantsGists}
      />
    );
  },
);

export default ConnectedCompetitionParticipantsTable;

async function readCompetitionParticipantsGists(
  graphUrl: string,
): Promise<TCompetitionParticipantGist[]> {
  const graphService = new CompetitionGraphService(graphUrl);
  const gists = await graphService.getCompetitionParticipantsGists();
  return gists;
}

// async function readCompetitionParticipantLivePositions(graphUrl: string) {
//   const graphService = new CompetitionGraphService(graphUrl);
//   const gists = await graphService.getCompetitionParticipantsGists();
//   return gists;
// }
