import type { IObservableArray } from "mobx/dist/internal";
import type { BytesLike, ContractTransactionResponse } from "ethers";
import type { TAssetParameters } from "../../../types/assetTypes.ts";
import type {
  ILexLensService,
  TLensCompleteLexConfigsStruct,
  TLensCompleteLexStateStruct,
  TLexFeeConfigsLensStruct,
  TLexGroupConfigsLensStruct,
  TLexPairConfigsLensStruct,
  TLexPairStateLensStruct,
  TLexPoolLensConfigsStruct,
  TLexPoolLensParamsStruct,
  TLexPoolLensStateStruct,
  TPoolAccountantConfigurationsLensStruct,
  TPoolAccountantStateLensStruct,
} from "../../../services/contractsIntegration/LexLensService/ILexLensService.ts";
import type {
  TEngineChainIds,
  TSourceChainIds,
} from "../../../constants/chainConstants.ts";
import type { IErc20Service } from "../../../services/contractsIntegration/erc20Service/IErc20Service.ts";
import type { ITradingService } from "../../../services/contractsIntegration/TradingService/ITradingService.ts";
import type { ILexPoolService } from "../../../services/contractsIntegration/LexPoolService/ILexPoolService.ts";
import type {
  ITradingFloorLensService,
  TCompletePositionDataFromLens,
} from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import type { IIntentsDelegationService } from "../../../services/servicesIntergration/intentsDelegationService/IIntentsDelegationService.ts";
import type { IGeneralAppBaasInteractionService } from "../../../services/servicesIntergration/IGeneralAppBaasInteractionService/IGeneralAppBaasInteractionService.ts";
import type { IIntentsVerifierLensService } from "../../../services/contractsIntegration/IntentsVerifierLensService/IIntentsVerifierLensService.ts";
import type { IChipsIntentsVerifierService } from "../../../services/contractsIntegration/ChipsIntentsVerifierService/IChipsIntentsVerifierService.ts";
import type { IOftChipAdapterService } from "../../../services/contractsIntegration/OftBridgeService/IOftChipAdapterService.ts";
import type { TChipModeEnum } from "../../../constants/contractEnums.ts";
import type { IEngineChipService } from "../../../services/contractsIntegration/EngineChipService/IEngineChipService.ts";
import type { IWrappedNativeEngineChipHelperService } from "../../../services/contractsIntegration/WrappedNativeEngineChipHelperService/IWrappedNativeEngineChipHelperService.ts";
import type {
  TUsageRoundGraphInfo,
  TEpochGraphInfo,
  TUsageRoundTraderGraphInfo,
} from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import type { IGeneralTokenDispenserService } from "../../../services/contractsIntegration/GeneralTokenDispenserService/IGeneralTokenDispenserService.ts";
import type { TRewardsRoundRules } from "../../../incentives/manualIncentives.ts";
import type {
  TCompetitionPlanIncentiveDescription,
  TCompetitionPlanSingleRewardDetails,
} from "../../../services/servicesIntergration/incentivesService/IIncentivesService.ts";

import { EMPTY_GRAPH_INFO_USAGE_ROUND } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import {
  EMPTY_COMPETITION_PLAN_DESCRIPTION,
  EMPTY_COMPETITION_PLAN_SINGLE_DETAILS,
} from "../../../services/servicesIntergration/incentivesService/IIncentivesService.ts";

import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import { ethers } from "ethers";
import { ILeverageDimensionParameters } from "../../../services/leverageDimensionService/ILeverageDiomensionsService.ts";
import { CryptoWalletConnectionStore } from "../../CryptoWalletConnectionStore.ts";
import { floatToUnitsBn, unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { precisionBnToFloat } from "../../../utils/lynxScalesUtils.ts";
import { SingleLexUserStore } from "./SingleLexUserStore.ts";
import { TPairIds } from "../../../constants/pairsConstants.ts";
import { SingleTradePairStore } from "../TradePairStore/SingleTradePairStore.ts";
import {
  EMPTY_GRAPH_INFO_EPOCH,
  TClosedPositionGist,
} from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { SystemStore } from "../../SystemStore.ts";
import { ContractServicesStore } from "../../ContractServicesStore.ts";
import { GraphQLService } from "../../../services/servicesIntergration/graphqlService/GraphQLService.ts";
import { toastError } from "../../../ux/toasts/toasting.ts";
import { GENERAL_BAAS_INTERACTION_ENDPOINTS_BY_ENGINE_CHAIN_ID } from "../../../constants/externalServicesConstants.ts";
import { EMPTY_ASSET_PARAMETERS } from "../../../types/assetTypes.ts";

import { GeneralAppBaasInteractionService } from "../../../services/servicesIntergration/IGeneralAppBaasInteractionService/GeneralAppBaasInteractionService.ts";
import { buildIntentsDelegationService } from "../../../ux/delegatedIntentsUx.ts";
import { PricesStore } from "../../singleInstanceStores/PricesStore.ts.ts";
import { ENGINE_CHAIN_ADDRESSES } from "../../../constants/chainSystemAddresses.ts";
import {
  FOREX_SUPPORTING_LEX_IDS,
  LED_IDS,
} from "../../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { makePersistable } from "mobx-persist-store";
import { persistenceUtils } from "../../../utils/persistenceUtils.ts";
import { entitiesSerialization } from "../../../utils/entitiesSerialization.ts";
import { timeUtils } from "../../../utils/timeUtils.ts";
import { getManualRewardsRoundRules } from "../../../incentives/manualIncentives.ts";
import { isSameAddress } from "../../../utils/addresses.ts";
import { SingleTradingFloorStore } from "../SingleTradingFloorStore/SingleTradingFloorStore.ts";
import { cloneAndOverride } from "../../../utils/cloning.ts";
import { buildGeneralAppBaasInteractionService } from "../../../services/servicesIntergration/IGeneralAppBaasInteractionService/IGeneralAppBaasInteractionService.ts";
import { FEE_CONFIGS_IDS } from "../../../constants/feesConstants.ts";

const COMPOUNDS_PER_YEAR = 365;
const SECONDS_IN_YEAR = 60 * 60 * 24 * 365;

export const APR_MEASURE_WINDOW_IN_DAYS = 7;

export type TPendingEpochDepositComplete = {
  underlyingSymbol: string;

  epochNumber: number;
  account: string;
  amount: bigint;
  minAmountOut: bigint;

  amountInUnits: number;
  minAmountOutInUnits: number;

  poolCurrentEpoch: number;
};

export type TPendingEpochRedeemComplete = {
  underlyingSymbol: string;

  epochNumber: number;
  account: string;
  amount: bigint;
  minAmountOut: bigint;
  maxAmountOut: bigint;

  lpAmountInUnits: number;
  lpAmountInUnderlyingUnitsByCurrentExchangeRate: number;
  minAmountOutInUnits: number;
  maxAmountOutInUnits: number;
};

const DATE_STALENESS = {
  currentUsageRound: 60 * 5, // 5 Minutes
};

/**
 * Store for the LexPool and PoolAccountant (+ active account info)
 */
export class SingleLexStore {
  private READING_SEMAPHORES = {
    currentUsageRound: false,
  };

  // Initialization state params
  @observable hasBeenInitialized = false;
  @observable doneLoading = false;
  @observable errorLoading = false;

  // Meta params
  @observable id: string;
  @observable urlName: string;
  @observable sourceChainId: TSourceChainIds;
  @observable engineChainId: TEngineChainIds;
  @observable chipMode: TChipModeEnum;

  @observable graphUrl: string;
  @observable minDepositAmount: bigint;

  // ***** Underlying Params *****
  // @observable public underlyingAssetAddress: string;
  // @observable public underlyingDecimals: number;
  // @observable public underlyingName: string;
  // @observable public underlyingShortName: string;
  // @observable public underlyingSymbol: string;

  @observable public chipAssetParams: TAssetParameters = observable(
    EMPTY_ASSET_PARAMETERS,
  );

  @observable public sourceAssetParameters: TAssetParameters = observable(
    EMPTY_ASSET_PARAMETERS,
  );

  @observable public oftChipAdapterOnSourceAddress = "";

  // LVToken meta
  @observable poolAddress: string;
  @observable poolTokenName: string;

  @observable poolTokenSymbol: string;
  @observable poolTokenDecimals: number;

  // Pairs Stores
  @observable tradePairsStores: IObservableArray<SingleTradePairStore> =
    observable([]);

  // Configurations
  @observable supportedPairIds: IObservableArray<TPairIds> = observable([]);

  @observable feesConfigurations: IObservableArray<TLexFeeConfigsLensStruct> =
    observable([]);

  @observable
  groupsConfigurations: IObservableArray<TLexGroupConfigsLensStruct> =
    observable([]);

  @observable public currentExchangeRate: bigint;

  /**
   * Total amount of lxTokens in circulation
   */
  @observable public totalSupply: bigint;
  @computed public get totalSupplyRaw(): bigint {
    return this.totalSupply;
  }
  @observable public totalBorrows: bigint;

  // /**
  //  * Underlying in the pool (excluding pending deposits/withdrawals and reserves
  //  */
  // @observable private totalCash: bigint;

  @observable private unrealizedFunding: bigint;
  @observable private totalPendingDeposits: bigint;
  @observable private totalPendingWithdrawals: bigint;

  @observable public borrowRatePerSecond: bigint; // asset/second of interest per borrowed unit (PRECISION)

  // ***** Accountant Configurations *****
  @observable public irmAddress: string;
  @observable public frmAddress: string;
  @observable public interestShareFactor: bigint; // (PRECISION)
  @observable public minOpenFee: bigint; // (Underlying scale)

  @observable public liquidationThresholdF: bigint; // (Fraction scale)
  @observable public liquidationFeeF: bigint; // (Fraction scale)
  @observable public lexPartF: bigint; // (Fraction scale)

  @observable public isImmediateDepositAllowed: boolean;
  @observable public currentEpochNumber: number;
  @observable public epochDuration: number;
  @observable public nextEpochStartMin: number;
  @observable public epochsDelayDeposit: number;
  @observable public epochsDelayRedeem: number;

  @observable public virtualBalanceForUtilization: bigint;
  @observable public currentVirtualUtilization: bigint;

  @observable public currentUsageRound: TUsageRoundGraphInfo;
  @observable public lastTimeRead_currentUsageRound = 0;

  // Used to calculate the APR
  @observable public aprReferenceEpoch: TEpochGraphInfo;

  // Accounts Stores
  @observable accountsMap: ObservableMap<string, SingleLexUserStore> =
    new ObservableMap<string, SingleLexUserStore>();

  // TODO : Replace this with a map ?
  @observable
  currentRoundUsageTradersGraphInfo: IObservableArray<TUsageRoundTraderGraphInfo> =
    observable([]);

  @observable
  public currentCompetitionDescription: TCompetitionPlanIncentiveDescription;

  constructor(
    public cryptoWalletConnectionStore: CryptoWalletConnectionStore,
    public systemStore: SystemStore,
    private contractServicesStore: ContractServicesStore,
    private pricesStore: PricesStore,
    public tradingFloorStore: SingleTradingFloorStore,
    leverageDimensionParameters: ILeverageDimensionParameters,
  ) {
    makeObservable(this);

    void makePersistable(this, {
      name: `SingleLexStore_${leverageDimensionParameters.id}`,
      properties: [
        // Numbers
        "lastTimeRead_currentUsageRound",
        // BigInts
        {
          key: "currentExchangeRate",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "totalSupply",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "totalBorrows",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "virtualBalanceForUtilization",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "currentVirtualUtilization",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "minOpenFee",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        // Numbers
        "currentEpochNumber",
        "epochDuration",
        "nextEpochStartMin",
        // Objects
        {
          key: "currentUsageRound",
          serialize: entitiesSerialization.usageRoundGraphInfo.serialize,
          deserialize: entitiesSerialization.usageRoundGraphInfo.deserialize,
        },
        {
          key: "aprReferenceEpoch",
          serialize: entitiesSerialization.epochGraphInfo.serialize,
          deserialize: entitiesSerialization.epochGraphInfo.deserialize,
        },
        // Arrays
        {
          key: "feesConfigurations",
          serialize: entitiesSerialization.serializeAnyObjectArray,
          // @ts-ignore
          deserialize: (arrStr: string) =>
            entitiesSerialization.deserializeAnyObjectArray(
              arrStr,
              entitiesSerialization.feesConfigurations.deserialize,
            ),
        },
        {
          key: "groupsConfigurations",
          serialize: entitiesSerialization.serializeAnyObjectArray,
          // @ts-ignore
          deserialize: (arrStr: string) =>
            entitiesSerialization.deserializeAnyObjectArray(
              arrStr,
              entitiesSerialization.groupsConfigurations.deserialize,
            ),
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 10 * 1000, // 10 hours
      removeOnExpiration: true,
    });

    this.id = leverageDimensionParameters.id;
    this.urlName = leverageDimensionParameters.urlName;
    this.sourceChainId =
      leverageDimensionParameters.lexParams.chipConfigurations.sourceChainId;
    this.engineChainId =
      leverageDimensionParameters.lexParams.chipConfigurations.enginChainId;

    this.chipMode =
      leverageDimensionParameters.lexParams.chipConfigurations.chipMode;

    this.graphUrl = leverageDimensionParameters.graphUrl;

    const { chipAssetParams, sourceAssetParams } =
      leverageDimensionParameters.lexParams.chipConfigurations;

    this.poolAddress = leverageDimensionParameters.lexParams.lexAddress;
    this.poolTokenName = "";
    this.poolTokenSymbol = "";
    this.poolTokenDecimals = 18;
    this.currentExchangeRate = 0n;

    this.totalSupply = 0n;
    this.totalBorrows = 0n;
    // this.totalCash = 0n;

    this.unrealizedFunding = 0n;
    this.totalPendingDeposits = 0n;
    this.totalPendingWithdrawals = 0n;

    this.borrowRatePerSecond = 0n;

    this.irmAddress = "";
    this.frmAddress = "";
    this.interestShareFactor = 0n;

    this.minOpenFee = 0n;
    this.liquidationThresholdF = 0n;
    this.liquidationFeeF = 0n;
    this.lexPartF = 0n;

    // These parameters are unchangeable
    this.sourceAssetParameters = sourceAssetParams;
    this.chipAssetParams = chipAssetParams;
    this.oftChipAdapterOnSourceAddress =
      leverageDimensionParameters.lexParams.chipConfigurations.oftProxyAddress;

    // this.underlyingAssetAddress = underlyingParams.address;
    // this.underlyingDecimals = underlyingParams.decimals;
    // this.underlyingName = underlyingParams.name;
    // this.underlyingShortName = underlyingParams.shortName;
    // this.underlyingSymbol = underlyingParams.symbol;

    this.isImmediateDepositAllowed = false;
    this.currentEpochNumber = 0;
    this.epochDuration = 0;
    this.minDepositAmount = 0n;
    this.nextEpochStartMin = 0;
    this.epochsDelayDeposit = 0;
    this.epochsDelayRedeem = 0;

    this.virtualBalanceForUtilization = 0n;
    this.currentVirtualUtilization = 0n;

    // BPToken underlying price
    // this.underlyingPrice = 0n;

    this.tradePairsStores.replace(
      leverageDimensionParameters.supportedPairParams.map(
        (pairParameters) =>
          new SingleTradePairStore(
            this.cryptoWalletConnectionStore,
            this,
            pairParameters,
          ),
      ),
    );

    this.supportedPairIds.replace(
      this.tradePairsStores.map((store) => store.pairId),
    );

    this.currentUsageRound = EMPTY_GRAPH_INFO_USAGE_ROUND;

    this.aprReferenceEpoch = EMPTY_GRAPH_INFO_EPOCH;

    this.currentCompetitionDescription = cloneAndOverride(
      EMPTY_COMPETITION_PLAN_DESCRIPTION,
      { lexAddress: this.poolAddress },
    );

    // reaction(
    //   () => [this.cryptoWalletConnectionStore.mainAddress],
    //   ([address]) => {
    //     this.reactToConnectedChainOrAddressChanged(address).catch((e) =>
    //       console.error(`Failed reactToConnectedChainOrAddressChanged ${e}`),
    //     );
    //   },
    //   {
    //     fireImmediately: true,
    //   },
    // );
  }

  // /**
  //  * Should be called after constructor
  //  */
  // public async initialize() {
  //   await this.refreshFromOutside();
  // }

  // **** Current address changed ****
  // private async reactToConnectedChainOrAddressChanged(currentAddress: string) {
  //   try {
  //     await this.readAllLeDData(currentAddress);
  //   } catch (e: unknown) {
  //     this.failLoadingProcess(e);
  //     console.error(
  //       "Error in reacting to address change in SingleLex Store",
  //       e,
  //     );
  //   }
  // }

  // **** General Contract interactions ****

  public async approveSettlementAssetUsageForCenter(): Promise<ContractTransactionResponse> {
    const erc20Address = this.chipAssetParams.address;
    const centerAddress = this.tradingFloorProxyAddress;

    // TODO : Add proper tx interaction.
    console.log(
      `Approving unlimited allowance for center ${centerAddress} on ${erc20Address}`,
    );
    const tx = await this.contractServicesStore
      .buildErc20Service(erc20Address, this.engineChainId)
      .approve(centerAddress, ethers.MaxUint256);

    // DEV_NOTE : Refresh data after confirmation without waiting here
    void tx.wait().then(async () => {
      // this.analyticsStore.logMarketInteraction(
      //   "pool_approval",
      //   this.cryptoWalletConnectionStore.mainAddress,
      //   spender,
      //   amount
      // );

      await this.refreshDataAfterTx();
    });

    return tx;
  }

  public async approveSettlementAssetUsageForLexPool(): Promise<ContractTransactionResponse> {
    const erc20Address = this.chipAssetParams.address;
    const centerAddress = this.tradingFloorProxyAddress;

    // TODO : Add proper tx interaction.
    console.log(
      `Approving unlimited allowance for center ${centerAddress} on ${erc20Address}`,
    );
    const tx = await this.contractServicesStore
      .buildErc20Service(erc20Address, this.engineChainId)
      .approve(this.poolAddress, ethers.MaxUint256);

    // DEV_NOTE : Refresh data after confirmation without waiting here
    void tx.wait().then(async () => {
      // this.analyticsStore.logMarketInteraction(
      //   "pool_approval",
      //   this.cryptoWalletConnectionStore.mainAddress,
      //   spender,
      //   amount
      // );

      await this.refreshDataAfterTx();
    });

    return tx;
  }

  // **** Traders Portal interactions ****

  public async direct_cancelUpdatePositionField(
    positionId: BytesLike,
  ): Promise<ContractTransactionResponse> {
    const tx =
      await this.freshTradingService.directAction_cancelPendingUpdatePositionField(
        positionId,
      );

    // DEV_NOTE : Refresh data after confirmation without waiting here
    tx.wait()
      .then(async () => {
        // this.analyticsStore.logMarketInteraction(
        //   "market_supply",
        //   this.cryptoWalletConnectionStore.mainAddress,
        //   cTokenAddress,
        //   amount
        // );

        await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      })
      .catch((e: Error) => {
        // TODO : CRITICAL : Use a unified (metamask error to message)
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const errorMessage = (e?.data?.message as string) ?? e.toString();

        toastError(errorMessage);
      });

    return tx;
  }

  public async requestCancelPendingMarketOrder(
    positionId: BytesLike,
    isOpenOrder: boolean,
  ): Promise<ContractTransactionResponse> {
    let tx: ContractTransactionResponse;

    if (isOpenOrder) {
      tx =
        await this.freshTradingService.directAction_timeout_openMarket(
          positionId,
        );
    } else {
      tx =
        await this.freshTradingService.directAction_timeout_closeMarket(
          positionId,
        );
    }

    // DEV_NOTE : Refresh data after confirmation without waiting here
    void tx.wait().then(async () => {
      await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
    });

    return tx;
  }

  public async requestCancelPendingLimitOrder(
    positionId: BytesLike,
  ): Promise<ContractTransactionResponse> {
    const tx =
      await this.freshTradingService.directAction_cancelPendingPosition_limit(
        positionId,
      );

    // DEV_NOTE : Refresh data after confirmation without waiting here
    void tx.wait().then(async () => {
      await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
    });

    return tx;
  }

  // **** Lex Pool interactions ****

  public async immediateDepositToPool(
    depositAmountInUnits: number,
  ): Promise<ContractTransactionResponse> {
    const depositAmount = floatToUnitsBn(
      depositAmountInUnits,
      this.chipAssetParams.decimals || 1,
    );

    const referralDomain = ethers.ZeroHash;
    const referralCode = ethers.ZeroHash;

    const tx = await this.freshLexPoolService.immediateDeposit(
      depositAmount,
      referralDomain,
      referralCode,
    );

    // DEV_NOTE : Refresh data after confirmation without waiting here
    tx.wait()
      .then(async () => {
        // this.analyticsStore.logMarketInteraction(
        //   "market_supply",
        //   this.cryptoWalletConnectionStore.mainAddress,
        //   cTokenAddress,
        //   amount
        // );

        await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      })
      .catch((e: Error) => {
        toastError(e.toString());
      });

    return tx;
  }

  public async processPoolDeposit(
    account: string,
  ): Promise<ContractTransactionResponse> {
    const tx = await this.freshLexPoolService.processDeposit(account);

    // DEV_NOTE : Refresh data after confirmation without waiting here
    tx.wait()
      .then(async () => {
        // this.analyticsStore.logMarketInteraction(
        //   "market_supply",
        //   this.cryptoWalletConnectionStore.mainAddress,
        //   cTokenAddress,
        //   amount
        // );

        await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      })
      .catch((e: Error) => {
        toastError(e.toString());
      });

    return tx;
  }

  public async processPoolRedeem(
    account: string,
  ): Promise<ContractTransactionResponse> {
    const tx = await this.freshLexPoolService.processDeposit(account);

    // DEV_NOTE : Refresh data after confirmation without waiting here
    tx.wait()
      .then(async () => {
        // this.analyticsStore.logMarketInteraction(
        //   "market_supply",
        //   this.cryptoWalletConnectionStore.mainAddress,
        //   cTokenAddress,
        //   amount
        // );

        await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      })
      .catch((e: Error) => {
        toastError(e.toString());
      });

    return tx;
  }

  // **** Data reading and setting ****

  /**
   * Quick-n-Dirty
   */
  public async refreshFromOutside() {
    return this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
  }

  private async readAllLeDData(accountAddress: string) {
    this.setDoneLoading(false);
    try {
      if (!this.isNoneLexStore) {
        await this.readGeneralLeXData();
        await this.readAccountSpecificLeDData(accountAddress);
      }
    } finally {
      this.setDoneLoading(true);
      this.setHasBeenInitialized(true);
    }
  }

  private async readGeneralLeXData() {
    // await this.updateWithFreshGeneralStateFromLens().catch((e: Error) =>
    //   console.error(
    //     `Failed updateWithFreshGeneralStateFromLens ${e.toString()}`,
    //   ),
    // );

    await this.updateWithFreshGeneralStateFromGraph().catch((e: Error) =>
      console.error(
        `Failed updateWithFreshGeneralStateFromGraph ${e.toString()}`,
      ),
    );

    // await this.batchReadAndUpdatePairsConfigs().catch((e) =>
    //   console.error(`Failed batchReadAndUpdatePairsConfigs ${e}`),
    // );
    //
    // await this.batchReadAndUpdatePairsState().catch((e) =>
    //   console.error(`Failed batchReadAndUpdatePairsState ${e}`),
    // );
    //
    // await this.batchReadAndUpdateFeesConfigs().catch((e) =>
    //   console.error(`Failed batchReadAndUpdateFeesConfigs ${e}`),
    // );
  }

  private async readAccountSpecificLeDData(accountAddress: string) {
    if (
      accountAddress &&
      accountAddress.toUpperCase() !== ethers.ZeroAddress.toUpperCase()
    ) {
      const userStore = this.getUserStore(accountAddress);

      await userStore
        .readAllDataForStore()
        .catch((e) =>
          console.error(
            `Failed readAllDataForStore in userStore ${accountAddress} ${e}`,
          ),
        );
    }
  }

  // **** Empty lex ****
  @computed public get isNoneLexStore(): boolean {
    return isSameAddress(this.poolAddress, ethers.ZeroAddress);
  }

  // **** Leverage Dimension related computed value ****

  /**
   * Is the underlying asset a wrapped native asset
   */
  @computed public get isWrappedNative() {
    return (
      this.id == LED_IDS.FANTOM_ENGINE_FANTOM_FTM ||
      this.id == LED_IDS.FANTOM_ENGINE_FUSE_WFUSE
    );
  }

  @computed public get getMinDepositAmount(): number {
    return unitsBnToFloat(this.minDepositAmount, this.chipAssetParams.decimals);
  }

  @computed public get tradingFloorProxyAddress(): string {
    const systemContracts = this.systemStore.systemContractsMap.get(
      this.engineChainId,
    );
    return systemContracts?.tradingFloorProxyAddress ?? ethers.ZeroAddress;
  }

  @computed public get hasGraphUrl(): boolean {
    return !!this.graphUrl;
  }

  // **** Aggregated Traders Info ****
  // TODO : Add accumulation of graph info from all traders

  // **** Services computed value ****

  @computed
  public get freshSettlementAssetErc20Service(): IErc20Service {
    return this.contractServicesStore.buildErc20Service(
      this.chipAssetParams.address,
      this.engineChainId,
    );
  }

  @computed
  public get freshSourceChainAssetErc20Service(): IErc20Service {
    return this.contractServicesStore.buildErc20Service(
      this.sourceAssetParameters.address,
      this.sourceChainId,
    );
  }

  @computed
  public get freshLexLensService(): ILexLensService {
    const chainId = this.engineChainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const leverageVaultLensAddress = systemContracts?.lexLensAddress;

    if (!leverageVaultLensAddress) {
      console.log(`No leverageVaultLens Address for ${chainId}`);
    }

    return this.contractServicesStore.buildLexLensService(
      leverageVaultLensAddress ??
        `freshLeverageVaultLensService : No LeverageVaultLens Defined For ${chainId}`,
      chainId,
      true,
    );
  }

  @computed
  public get freshTradeCenterLensService(): ITradingFloorLensService {
    const chainId = this.engineChainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const address = systemContracts?.tradingCenterLens;

    if (!address) {
      console.log(`No tradeCenterLens Address for ${chainId}`);
    }

    return this.contractServicesStore.buildTradingCenterLensService(
      address ??
        `freshTradeCenterLensService : No tradeCenterLens Defined For ${chainId}`,
      chainId,
      true,
    );
  }

  @computed
  public get freshIntentsVerifierLensService(): IIntentsVerifierLensService {
    const chainId = this.engineChainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const address = systemContracts?.intentsVerifierLens;

    if (!address) {
      console.log(`No tradeCenterLens Address for ${chainId}`);
    }

    return this.contractServicesStore.buildIntentsVerifierLensService(
      address ??
        `freshIntentsVerifierLensService : No intentsVerifierLens Defined For ${chainId}`,
      chainId,
      true,
    );
  }

  @computed
  public get freshChipsIntentsVerifierService(): IChipsIntentsVerifierService {
    const chainId = this.engineChainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const address = systemContracts?.chipIntentsVerifier;

    if (!address) {
      console.log(`No chipIntentsVerifier Address for ${chainId}`);
    }

    return this.contractServicesStore.buildChipsIntentsVerifierService(
      address ??
        `freshChipsIntentsVerifierService : No chipIntentsVerifier Defined For ${chainId}`,
      chainId,
    );
  }

  @computed
  public get freshTradingService(): ITradingService {
    const chainId = this.engineChainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const address = systemContracts?.tradersPortalAddress;

    if (!address) {
      console.log(`No Trading Address for ${chainId}`);
    }

    return this.contractServicesStore.buildTradingService(
      address ?? `freshTradingService : No Trading Defined For ${chainId}`,
      chainId,
    );
  }

  @computed
  public get freshLexPoolService(): ILexPoolService {
    const chainId = this.engineChainId;

    const address = this.poolAddress;

    if (!address) {
      console.log(`No Trading Address for ${chainId}`);
    }

    return this.contractServicesStore.buildLexPoolService(
      address || `freshLexPoolService : No Trading Defined For ${chainId}`,
      chainId,
    );
  }

  @computed
  public get freshOFTChipAdapterService(): IOftChipAdapterService {
    const chainId = this.sourceChainId;

    const address = this.oftChipAdapterOnSourceAddress;

    if (!address) {
      console.log(`No OFTChipAdapter Address for ${this.id}`);
    }

    return this.contractServicesStore.buildOftChipAdapterService(
      address,
      chainId,
    );
  }

  @computed
  public get freshEngineChipService(): IEngineChipService {
    const chainId = this.sourceChainId;

    const address = this.chipAssetParams.address;

    if (!address) {
      console.log(`No OFTChipAdapter Address for ${this.id}`);
    }

    return this.contractServicesStore.buildEngineChipAdapterService(
      address,
      chainId,
    );
  }

  @computed
  public get freshWrappedNativeEngineChipHelperService(): IWrappedNativeEngineChipHelperService {
    const chainId = this.sourceChainId;

    const address =
      ENGINE_CHAIN_ADDRESSES[this.engineChainId]
        .wrappedNativeEngineChipHelperAddress;

    if (!address) {
      console.log(`No WrappedNativeEngineChipHelper Address for ${this.id}`);
    }

    return this.contractServicesStore.buildWrappedNativeEngineChipHelperService(
      address,
      chainId,
    );
  }

  @computed
  public get freshIntentsDelegationService(): IIntentsDelegationService {
    return buildIntentsDelegationService(this.engineChainId);
  }

  @computed
  public get freshGeneralTokenDispenserService(): IGeneralTokenDispenserService {
    const chainId = this.engineChainId;

    const address =
      ENGINE_CHAIN_ADDRESSES[this.engineChainId].generalTokenDispenserAddress;

    if (!address) {
      console.log(`No WrappedNativeEngineChipHelper Address for ${this.id}`);
    }

    return this.contractServicesStore.buildGeneralTokenDispenserService(
      address,
      chainId,
    );
  }

  @computed
  public get freshGeneralBaasInteractionService(): IGeneralAppBaasInteractionService {
    return buildGeneralAppBaasInteractionService(this.engineChainId);
  }

  // **** Account Stores ****

  @computed public get activeUserStore(): SingleLexUserStore {
    const activeAccountAddress = this.cryptoWalletConnectionStore.mainAddress;
    return this.getUserStore(activeAccountAddress);
  }

  public getUserStore(accountAddress: string): SingleLexUserStore {
    const accountStore = this.accountsMap.get(accountAddress);

    if (accountStore) {
      return accountStore;
    } else {
      return this.createAndStoreNewAccountStore(accountAddress);
    }
  }

  // **** State refresh from outside ****

  public async updateWithFreshGeneralStateFromLens() {
    if (this.isNoneLexStore) {
      return;
    }

    // Lens Reading

    // if (this.hasBeenInitialized) {
    const lensInstance = this.freshLexLensService;

    await this.readAndSetLexPoolParams(lensInstance).catch((e) =>
      console.error(`Failed readAndSetLexPoolParams ${this.id} ${e}`),
    );

    await this.readAndSetLexPoolState(lensInstance).catch((e) =>
      console.error(`Failed readAndSetLexPoolState ${this.id} ${e}`),
    );

    await this.readAndSetLexPoolConfigs(lensInstance).catch((e) =>
      console.error(`Failed readAndSetLexPoolConfigs ${this.id} ${e}`),
    );

    await this.readAndSetPoolAccountantState(lensInstance).catch((e) =>
      console.error(`Failed readAndSetPoolAccountantState ${this.id} ${e}`),
    );

    await this.readAndSetPoolAccountantConfigurations(lensInstance).catch(
      (e: Error) =>
        console.error(
          `Failed readAndSetPoolAccountantConfigurations ${e.toString()} -- ${e?.stack}`,
        ),
    );

    // }
  }
  public async updateWithFreshGeneralStateFromGraph() {
    if (this.isNoneLexStore) {
      return;
    }

    // Graph Reading

    await this.readAndSetCurrentUsageRound().catch((e: Error) =>
      console.error(
        `Failed readAndSetCurrentUsageRound ${e.toString()} -- ${e?.stack}`,
      ),
    );

    await this.readAndSetReferenceEpoch().catch((e: Error) =>
      console.error(
        `Failed readAndSetReferenceEpoch ${e.toString()} -- ${e?.stack}`,
      ),
    );
  }

  // **** Params ****
  // @computed public get assetParams(): TAssetParameters {
  //   return {
  //     name: this.sourceAssetParameters.name,
  //     shortName: this.sourceAssetParameters.shortName,
  //     decimals: this.sourceAssetParameters.decimals,
  //     address: this.sourceAssetParameters.address,
  //     symbol: this.sourceAssetParameters.symbol,
  //   };
  // }

  // **** Price ****

  @computed
  public get underlyingUsdPrice(): number {
    return (
      this.pricesStore.symbolToPriceMap.get(
        this.sourceAssetParameters.symbol,
      ) ?? 0
    );
  }

  // **** General Market state - Units ****

  /***
   * Indicates how many underlying units the BPToken currently holds
   */
  @computed
  public get bpTokenCashInUnits(): number {
    return parseFloat(
      ethers.formatUnits(
        this.virtualBalanceForUtilization,
        this.chipAssetParams.decimals,
      ),
    );
  }

  // /***
  //  * Indicates how many underlying units are not used and can be given as a loan
  //  */
  // @computed
  // public get availableAmountToBorrowInUnits(): number {
  //   return parseFloat(
  //     ethers.formatUnits(
  //       this.virtualBalanceForUtilization - this.totalBorrows,
  //       this.chipAssetParams.decimals,
  //     ),
  //   );
  // }

  /**
   * Underlying units supplied to market
   */
  @computed
  public get totalSuppliedInUnits(): number {
    return unitsBnToFloat(
      this.lpTokensAmountToUnderlyingAmount(this.totalSupply),
      this.chipAssetParams.decimals,
    );
  }

  @computed
  public get totalBorrowsInUnits(): number {
    return unitsBnToFloat(this.totalBorrows, this.chipAssetParams.decimals);
  }

  @computed
  public get totalLVTokensInCirculation(): number {
    return unitsBnToFloat(this.totalSupply, this.poolTokenDecimals);
  }

  @computed
  public get unrealizedFundingInUnits(): number {
    return parseFloat(
      ethers.formatUnits(this.unrealizedFunding, this.chipAssetParams.decimals),
    );
  }

  // TODO : Verify this
  @computed public get exchangeRateInUnits(): number {
    // return etherBnToFloat(this.exchangeRateCurrent);
    return parseFloat(
      ethers.formatUnits(
        this.currentExchangeRate,
        this.chipAssetParams.decimals,
      ),
    );
  }

  @computed public get borrowRatePerSecondInUnits(): number {
    return precisionBnToFloat(this.borrowRatePerSecond);
  }

  @computed public get interestShareFactorInUnits(): number {
    return precisionBnToFloat(this.interestShareFactor);
  }

  @computed public get minOpenFeeInUnits(): number {
    return unitsBnToFloat(this.minOpenFee, this.chipAssetParams.decimals);
  }

  @computed public get virtualBalanceForUtilizationInUnits(): number {
    return unitsBnToFloat(
      this.virtualBalanceForUtilization,
      this.chipAssetParams.decimals,
    );
  }

  @computed public get currentVirtualUtilizationInUnits(): number {
    return precisionBnToFloat(this.currentVirtualUtilization);
  }

  @computed public get totalPendingDepositsInUnits(): number {
    return unitsBnToFloat(
      this.totalPendingDeposits,
      this.chipAssetParams.decimals,
    );
  }

  @computed public get totalPendingWithdrawalsInUnits(): number {
    return unitsBnToFloat(
      this.totalPendingWithdrawals,
      this.chipAssetParams.decimals,
    );
  }

  // **** Pool APRs  ****

  /***
   * Borrow APR in units (e.g : 0.57 (%57))
   */
  @computed
  public get borrowAprInUnits(): number {
    return this.borrowRatePerSecondInUnits * SECONDS_IN_YEAR;
  }

  /***
   * Supply APY in units (e.g : 0.57 (%57))
   */
  @computed
  public get borrowAprInPercentages(): number {
    return this.borrowAprInUnits * 100;
  }

  // **** Market APYs ****

  /***
   * Borrow APY in units (e.g : 0.57 (%57)) with compounding
   */
  @computed
  public get borrowApyInUnits(): number {
    const base = this.borrowAprInUnits / COMPOUNDS_PER_YEAR + 1;

    const powered = Math.pow(base, COMPOUNDS_PER_YEAR);
    const final = powered - 1;

    return final;
  }

  /***
   * Borrow APY in units (e.g : 0.57 (%57))
   */
  @computed
  public get borrowApyInPercentages(): number {
    return this.borrowApyInUnits * 100;
  }

  // **** General Pool state ****

  public lpTokensAmountToUnderlyingAmount(lpTokensAmount: bigint): bigint {
    return this.convertLpAmountToUnderlyingAmount(
      this.currentExchangeRate == 0n ? 1n : this.currentExchangeRate,
      lpTokensAmount,
    );
  }

  public underlyingAmountToLpTokensAmount(underlyingAmount: bigint): bigint {
    return this.convertUnderlyingAmountToLp(
      this.currentExchangeRate == 0n ? 1n : this.currentExchangeRate,
      underlyingAmount,
    );
  }

  @computed
  public get totalPoolUnderlyingSupplyInUnits(): number {
    return unitsBnToFloat(
      this.lpTokensAmountToUnderlyingAmount(this.totalSupply),
      this.chipAssetParams.decimals,
    );
  }

  /***
   * Indicates how many USD worth of underlying units the BPToken currently holds
   */
  @computed
  public get bpTokenCashInUSD(): number {
    return this.bpTokenCashInUnits * this.underlyingUsdPrice;
  }

  @computed
  public get totalSupplyBalanceUsd(): number {
    return this.totalPoolUnderlyingSupplyInUnits * this.underlyingUsdPrice;
  }

  // **** Pool APR ****

  @computed
  public get yearlyAprFromReferenceEpochInUnits(): number {
    const estimatedCurrentEpochStartTime =
      this.nextEpochStartMin - this.epochDuration;
    const timeDiffSinceReferenceEpoch =
      this.aprReferenceEpoch.timestamp > 0
        ? estimatedCurrentEpochStartTime - this.aprReferenceEpoch.timestamp
        : 0;

    const refEpochExchangeRateInUnits = unitsBnToFloat(
      this.aprReferenceEpoch.exchangeRate,
      this.chipAssetParams.decimals,
    );
    const growthSinceRefEpoch =
      this.exchangeRateInUnits / refEpochExchangeRateInUnits;
    const growthSinceInUnits = growthSinceRefEpoch - 1;

    const yearlyApr =
      timeDiffSinceReferenceEpoch > 0
        ? (growthSinceInUnits * SECONDS_IN_YEAR) / timeDiffSinceReferenceEpoch
        : 0;

    // console.log(
    //   `@@@@ yearlyAprFromReferenceEpochInUnits : ${this.sourceAssetParameters.symbol} : ${refEpochExchangeRateInUnits} -> ${this.exchangeRateInUnits} (growth of ${growthSinceInUnits}) in ${timeDiffSinceReferenceEpoch} seconds (epochs ${this.aprReferenceEpoch.epochNumber ?? 0} -> ${this.currentEpochNumber})`,
    // );

    return yearlyApr;
  }

  // **** OLD Incentives Mechanism ****

  // TODO : CRITICAL : Remove this after adding a proper cf info source for rewards
  @computed
  public get currentUsageRoundRewardsRules(): TRewardsRoundRules {
    return getManualRewardsRoundRules(
      this.id,
      this.currentUsageRound.roundNumber,
    );
  }

  // TODO : CRITICAL : Remove this after moving all past manual competitions to backup
  // @computed
  // public get currentUsageRoundCompetitionRewardsDescriptor(): TCompetitionRewardsDescriptor {
  //   return getManualCompetitionDescriptor(
  //     this.id,
  //     this.currentUsageRound.roundNumber,
  //   );
  // }

  // **** Incentives ****

  @computed
  public get currentCompetitionFirstDetails(): TCompetitionPlanSingleRewardDetails {
    if (this.currentCompetitionDescription.details.incentivesList.length == 0) {
      return EMPTY_COMPETITION_PLAN_SINGLE_DETAILS;
    } else {
      return this.currentCompetitionDescription.details.incentivesList[0];
    }
  }

  // TODO : Handle more than 1 descriptor
  @computed public get currentCompetitionRoundTotalRewardsInUnits(): number {
    const competitionMainDetails = this.currentCompetitionFirstDetails;

    const rewardsArray = competitionMainDetails.ranks;

    if (rewardsArray.length == 0) {
      return 0;
    } else {
      // Sum all the rewards
      return rewardsArray.reduce((sum, cur) => sum + cur, 0);
    }
  }

  public getRankForUserInCurrentRound(userAddress: string): number {
    const index = this.currentRoundUsageTradersGraphInfo.findIndex(
      (traderInfo) => isSameAddress(traderInfo.traderAddress, userAddress),
    );

    return index + 1;
  }

  public getCompetitionRewardsInCurrentRoundForRank(rank: number): number {
    const competitionMainDetails = this.currentCompetitionFirstDetails;

    const rewardsArray = competitionMainDetails.ranks;

    if (rank == 0 || rewardsArray.length == 0 || rank > rewardsArray.length) {
      return 0;
    } else {
      return rewardsArray[rank - 1];
    }
  }

  // **** Chain ****

  // **** Public utils ****

  public getTradePairStoreById(
    pairId: number,
  ): SingleTradePairStore | undefined {
    return this.tradePairsStores.find((tpStore) => tpStore.pairId === pairId);
  }

  public getLivePositionById(
    positionId: BytesLike,
  ): TCompletePositionDataFromLens | undefined {
    for (const tradePairStore of this.tradePairsStores) {
      const position = tradePairStore.activeUserStore.livePositionsInPair.find(
        (p) => p.id === positionId,
      );

      if (position) {
        return position;
      }
    }
  }

  public getClosedPositionByGraphId(
    positionId: BytesLike,
  ): TClosedPositionGist | undefined {
    for (const tradePairStore of this.tradePairsStores) {
      const position = tradePairStore.activeUserStore.closedPositionGists.find(
        (p) => p.entityId === positionId,
      );

      if (position) {
        return position;
      }
    }
  }

  public getFeeConfigById(feeId: number): TLexFeeConfigsLensStruct | undefined {
    return this.feesConfigurations.find((config) => config.feeId == feeId);
  }

  // **** LP-Underlying conversion Private Utils ****

  private convertLpAmountToUnderlyingAmount(
    exchangeRate: bigint,
    lpAmount: bigint,
  ) {
    const SELF_UNIT_SCALE = 10n ** BigInt(this.poolTokenDecimals);

    return (lpAmount * exchangeRate) / SELF_UNIT_SCALE;
  }

  private convertUnderlyingAmountToLp(
    exchangeRate: bigint,
    underlyingAmount: bigint,
  ) {
    const SELF_UNIT_SCALE = 10n ** BigInt(this.poolTokenDecimals);

    return (underlyingAmount * SELF_UNIT_SCALE) / exchangeRate;
  }

  // **** Private utils ****

  public async refreshDataAfterTx(addScheduledCalls = false) {
    await this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);

    if (addScheduledCalls) {
      setTimeout(() => {
        console.log(`First after tx refresh timeout`);
        void this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      }, 5 * 1000);

      setTimeout(() => {
        console.log(`Second after tx refresh timeout`);
        void this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      }, 10 * 1000);

      setTimeout(() => {
        console.log(`Third after tx refresh timeout`);
        void this.readAllLeDData(this.cryptoWalletConnectionStore.mainAddress);
      }, 15 * 1000);
    }
  }

  // **** Read-n-Set ****

  private async readAndSetLexPoolParams(lensInstance: ILexLensService) {
    const poolParams = await lensInstance.getLexPoolParams(this.poolAddress);
    this.setPoolParams(poolParams);
  }

  private async readAndSetLexPoolState(lensInstance: ILexLensService) {
    const poolState = await lensInstance.getLexPoolState(this.poolAddress);
    this.setPoolState(poolState);
  }

  private async readAndSetLexPoolConfigs(lensInstance: ILexLensService) {
    const poolConfigs = await lensInstance.getLexPoolConfigs(this.poolAddress);
    this.setPoolConfigs(poolConfigs);
  }

  private async readAndSetPoolAccountantState(lensInstance: ILexLensService) {
    const accountantState = await lensInstance.getPoolAccountantStateByPool(
      this.poolAddress,
    );
    this.setPoolAccountantState(accountantState);
  }

  private async readAndSetPoolAccountantConfigurations(
    lensInstance: ILexLensService,
  ) {
    const accountantConfigurations =
      await lensInstance.getPoolAccountantConfigurationsByPool(
        this.poolAddress,
      );
    this.setPoolAccountantConfigurations(accountantConfigurations);
  }

  private async readAndSetCurrentUsageRound() {
    const graphService = new GraphQLService(this.graphUrl);

    const currentUsageRoundEndTimestamp = this.currentUsageRound.endTimestamp;
    const currentTimestamp = Math.floor(Date.now() / 1000);

    const didPassEndTimestamp =
      currentTimestamp > currentUsageRoundEndTimestamp;

    if (
      // didPassEndTimestamp ||
      timeUtils.didAmountOfTimePassedSince(
        DATE_STALENESS.currentUsageRound,
        this.lastTimeRead_currentUsageRound,
      ) &&
      !this.READING_SEMAPHORES.currentUsageRound
    ) {
      this.READING_SEMAPHORES.currentUsageRound = true;
      const usageRoundGraphInfo = await graphService
        .getLatestUsageRoundForPool(this.poolAddress)
        .finally(() => (this.READING_SEMAPHORES.currentUsageRound = false));

      this.setCurrentUsageRoundInfo(usageRoundGraphInfo);
      this.setLastTimeRead_currentUsageRound(Math.floor(Date.now() / 1000));
    }
  }

  private async readAndSetReferenceEpoch() {
    const graphService = new GraphQLService(this.graphUrl);

    const aprWindowDuration = 60 * 60 * 24 * APR_MEASURE_WINDOW_IN_DAYS;
    const currentTime = Math.floor(Date.now() / 1000);
    const referenceTimestamp = currentTime - aprWindowDuration;

    // Only re-read if the one we have in memory is no longer in range (unlikely)
    if (this.aprReferenceEpoch.timestamp < referenceTimestamp) {
      const referenceEpoch = await graphService.getFirstPoolEpochAfterTimestamp(
        this.poolAddress,
        referenceTimestamp,
      );

      this.setAprReferenceEpoch(referenceEpoch);
    }
  }

  // TODO : Consider only reading once
  private async batchReadAndUpdatePairsConfigs() {
    const allPairIds = this.supportedPairIds;

    if (!allPairIds.length) {
      return console.log(`No supported pair ids for dimension ${this.id}`);
    }

    const allPairConfigs =
      await this.freshLexLensService.getAllPairsConfigsInLex(this.poolAddress);

    this.updatePairsConfigurations(allPairConfigs);
  }

  private updatePairsConfigurations(
    allPairConfigs: TLexPairConfigsLensStruct[],
  ) {
    // TODO : CRITICAL : Sanitize the pair configs (so proper values will be number, not bigint) and make sure all logic is adjusted for it
    for (const pairConfig of allPairConfigs) {
      const matchingTradePairStore = this.tradePairsStores.find(
        // TODO : Remove this 'Number' conversion after fixing type definition
        (ps) => ps.pairId == Number(pairConfig.pairId),
      );

      if (!matchingTradePairStore) {
        console.log(
          `batchReadAndUpdatePairsConfigs :: No PairStore found for pairId ${pairConfig.pairId}`,
        );
      } else {
        matchingTradePairStore.updateWithPairConfigs(pairConfig);
      }
    }
  }

  private async batchReadAndUpdatePairsState() {
    const allPairIds = this.supportedPairIds;

    if (!allPairIds.length) {
      return console.log(`No supported pair ids for dimension ${this.id}`);
    }

    const allPairsStates = await this.freshLexLensService.getAllPairsStateInLex(
      this.poolAddress,
    );

    this.updatePairsStates(allPairsStates);
  }

  private updatePairsStates(allPairsStates: TLexPairStateLensStruct[]) {
    for (const pairState of allPairsStates) {
      const matchingTradePairStore = this.tradePairsStores.find(
        // TODO : Remove this 'Number' conversion after fixing type definition
        (ps) => ps.pairId === Number(pairState.pairId),
      );

      if (!matchingTradePairStore) {
        console.log(
          `batchReadAndUpdatePairsState :: No PairStore found for pairId ${pairState.pairId}`,
        );
      } else {
        matchingTradePairStore.updateWithPairState(pairState);
      }
    }
  }

  private async batchReadAndUpdateFeesConfigs() {
    // const allPairIds = this.supportedPairIds;
    //
    // if (!allPairIds.length) {
    //   return console.log(`No supported pair ids for dimension ${this.id}`);
    // }

    const allFeeConfigs = await this.freshLexLensService.getAllFeesConfigsInLex(
      this.poolAddress,
    );

    this.setFeesConfigurations(allFeeConfigs);
  }

  // **** Complex setters ****

  @action("createAndStoreNewAccountStore")
  private createAndStoreNewAccountStore(
    accountAddress: string,
  ): SingleLexUserStore {
    const singleLexUserStore = new SingleLexUserStore(this, accountAddress);
    this.accountsMap.set(accountAddress, singleLexUserStore);

    return this.accountsMap.get(accountAddress)!;
  }

  @action("setConfigsFromCompleteLensData")
  public setConfigsFromCompleteLensData(
    completeLexConfigs: TLensCompleteLexConfigsStruct,
  ) {
    this.setPoolParams(completeLexConfigs.lexPoolParams);

    this.setPoolConfigs(completeLexConfigs.lexPoolConfigurations);
    this.setPoolAccountantConfigurations(
      completeLexConfigs.poolAccountantConfigurations,
    );

    this.setGroupConfigurations(completeLexConfigs.groupsConfigs);
    this.updatePairsConfigurations(completeLexConfigs.pairsConfigs);
    this.setFeesConfigurations(completeLexConfigs.feesConfigs);
  }

  public setStatesFromCompleteLensData(
    completeLexState: TLensCompleteLexStateStruct,
  ) {
    this.setPoolState(completeLexState.lexPoolState);
    this.setPoolAccountantState(completeLexState.poolAccountantState);
    this.updatePairsStates(completeLexState.pairsStates);
  }

  @action("setPoolParams")
  private setPoolParams(poolParamsFromLens: TLexPoolLensParamsStruct) {
    this.poolTokenName = poolParamsFromLens.name;
    this.poolTokenSymbol = poolParamsFromLens.symbol;
    // TODO : Is this needed ? We get this from the configurations
    // this.underlyingAssetAddress = poolParamsFromLens.underlying;
  }

  @action("setPoolState")
  private setPoolState(poolStateFromLens: TLexPoolLensStateStruct) {
    this.totalSupply = poolStateFromLens.totalSupply;
    // this.totalCash = poolStateFromLens.cash;

    this.currentEpochNumber = Number(poolStateFromLens.currentEpochNumber);

    this.currentExchangeRate = poolStateFromLens.currentExchangeRate;

    this.currentEpochNumber = Number(poolStateFromLens.currentEpochNumber);
    this.epochDuration = 0;
    this.nextEpochStartMin = Number(poolStateFromLens.nextEpochStartMin);

    this.virtualBalanceForUtilization =
      poolStateFromLens.virtualBalanceForUtilization;
    this.currentVirtualUtilization =
      poolStateFromLens.currentVirtualUtilization;

    this.totalPendingDeposits = poolStateFromLens.totalPendingDeposits;
    this.totalPendingWithdrawals = poolStateFromLens.totalPendingWithdrawals;
  }

  @action("setPoolConfigs")
  private setPoolConfigs(poolConfigsFromLens: TLexPoolLensConfigsStruct) {
    this.isImmediateDepositAllowed =
      poolConfigsFromLens.immediateDepositAllowed;
    this.epochDuration = Number(poolConfigsFromLens.epochLength);
    this.minDepositAmount = BigInt(poolConfigsFromLens.minDepositAmount);
    this.epochsDelayDeposit = Number(poolConfigsFromLens.epochsDelayDeposit);
    this.epochsDelayRedeem = Number(poolConfigsFromLens.epochsDelayRedeem);
  }

  @action("setPoolAccountantState")
  private setPoolAccountantState(
    accountantStateFromLens: TPoolAccountantStateLensStruct,
  ) {
    this.borrowRatePerSecond = accountantStateFromLens.borrowRatePerSecond;
    this.totalBorrows = accountantStateFromLens.totalBorrows;

    this.unrealizedFunding = accountantStateFromLens.unrealizedFunding;

    // console.log(
    //   `DEBUG :: borrowRatePerSecond ${this.borrowRatePerSecond.toString()} | scaled ${precisionBnToFloat(
    //     this.borrowRatePerSecond
    //   )}`
    // );
  }

  @action("setPoolAccountantConfigurations")
  private setPoolAccountantConfigurations(
    accountantConfigurationsFromLens: TPoolAccountantConfigurationsLensStruct,
  ) {
    this.irmAddress = accountantConfigurationsFromLens.interestRateModel;
    this.frmAddress = accountantConfigurationsFromLens.fundingRateModel;
    this.interestShareFactor =
      accountantConfigurationsFromLens.interestShareFactor;

    this.minOpenFee = accountantConfigurationsFromLens.minOpenFee;

    this.liquidationThresholdF =
      accountantConfigurationsFromLens.liquidationThresholdF;
    this.liquidationFeeF = accountantConfigurationsFromLens.liquidationFeeF;
    this.lexPartF = accountantConfigurationsFromLens.lexPartF;
  }

  private failLoadingProcess(error: unknown) {
    this.setErrorLoading(true);
    this.setDoneLoading(true);
  }

  // **** Loading State Setters ****

  @action("setHasBeenInitialized")
  private setHasBeenInitialized(hasBeenInitialized: boolean) {
    this.hasBeenInitialized = hasBeenInitialized;
  }

  @action("setDoneLoading")
  private setDoneLoading(doneLoading: boolean) {
    this.doneLoading = doneLoading;
  }

  @action("setErrorLoading")
  private setErrorLoading(errorLoading: boolean) {
    this.errorLoading = errorLoading;
  }

  // ****  Trading Floor Params setters ****

  @action("setGroupConfigurations")
  private setGroupConfigurations(
    groupConfigurations: TLexGroupConfigsLensStruct[],
  ): void {
    this.groupsConfigurations.replace(groupConfigurations);
  }

  @action("setFeesConfigurations")
  private setFeesConfigurations(
    feesConfigurations: TLexFeeConfigsLensStruct[],
  ): void {
    // TODO : CRITICAL : This is a manual fix for now.
    if (FOREX_SUPPORTING_LEX_IDS.includes(this.id)) {
      feesConfigurations.push({
        feeId: FEE_CONFIGS_IDS.BASIC_FOREX_PAIR,
        closeFeeF: 90,
        openFeeF: 90,
      });
    }

    this.feesConfigurations.replace(feesConfigurations);
  }

  // ****  Usage Rounds Setters ****

  @action("setCurrentUsageRoundInfo")
  private setCurrentUsageRoundInfo(usageRoundInfo: TUsageRoundGraphInfo): void {
    this.currentUsageRound = usageRoundInfo;
  }

  @action("setCurrentUsageRoundInfo")
  private setLastTimeRead_currentUsageRound(lastTimeRead: number): void {
    this.lastTimeRead_currentUsageRound = lastTimeRead;
  }

  // ****  Epoch Setters ****

  @action("setAprReferenceEpoch")
  private setAprReferenceEpoch(epochGraphInfo: TEpochGraphInfo): void {
    this.aprReferenceEpoch = epochGraphInfo;
  }

  @action("replaceCurrentRoundUsageTradersInfos")
  public replaceCurrentRoundUsageTradersInfos(
    usageRoundTradersGraphInfo: TUsageRoundTraderGraphInfo[],
  ): void {
    this.currentRoundUsageTradersGraphInfo.replace(usageRoundTradersGraphInfo);
  }

  @action("setCurrentCompetitionDescription")
  public setCurrentCompetitionDescription(
    competitionDescription: TCompetitionPlanIncentiveDescription,
  ) {
    this.currentCompetitionDescription = competitionDescription;
  }
}
