import { useMemo } from "react";
import { OpenOrderTypeEnums, TOpenOrderType } from "../constants/contractEnums";

const usePositionPanel = ({
  pairAssetPrice,
  openOrderType,
  slippagePInUnits,
  isLong,
  slMultiplierBaseInput,
  slMultiplier,
  openPrice,
  leverage,
  tpMultiplierBaseInput,
  tpMultiplier,
  wantedMinOpenPrice,
  wantedMaxOpenPrice,
}: {
  pairAssetPrice: number;
  openOrderType: TOpenOrderType;
  slippagePInUnits: string;
  isLong: boolean;
  slMultiplierBaseInput: string;
  slMultiplier: number;
  openPrice: number;
  leverage: number;
  tpMultiplierBaseInput: string;
  tpMultiplier: number;
  wantedMinOpenPrice: number;
  wantedMaxOpenPrice: number;
}) => {
  const { minPrice, maxPrice, slPrice, tpPrice } = useMemo(() => {
    let minPrice: number;
    let maxPrice: number;

    let slPrice: number;
    let tpPrice: number;

    const safePriceDiffForLimits = pairAssetPrice > 1 ? 0.001 : 0.0001;

    // TODO : Use a proper relevant price
    const veryHighMaxPrice = 100_000;

    // TODO : Make better ux with the 'AUTO' (0 multiplier) for TP and SL
    if (openOrderType === OpenOrderTypeEnums.MARKET) {
      const minPriceBySlippage =
        pairAssetPrice - (pairAssetPrice * Number(slippagePInUnits)) / 100;
      const maxPriceBySlippage =
        pairAssetPrice + (pairAssetPrice * Number(slippagePInUnits)) / 100;

      minPrice = minPriceBySlippage;
      maxPrice = maxPriceBySlippage;

      if (isLong) {
        let slPriceForLong = 0;
        let tpPriceForLong = 0;
        if (slMultiplierBaseInput) {
          slPriceForLong = Number(slMultiplierBaseInput);
        } else {
          slPriceForLong =
            slMultiplier > 0
              ? openPrice * (1 - slMultiplier / leverage / 100)
              : 0;
        }
        if (tpMultiplierBaseInput) {
          tpPriceForLong = Number(tpMultiplierBaseInput);
        } else {
          tpPriceForLong =
            tpMultiplier > 0
              ? openPrice * (1 + tpMultiplier / leverage / 100)
              : 0;
        }

        tpPrice = tpPriceForLong;
        // Quick-n-Dirty fix for <1 leverage
        slPrice = slPriceForLong > 0 ? slPriceForLong : 0.01;

        // TODO : Use reasonable number
        maxPrice =
          tpPrice > 0
            ? Math.min(maxPriceBySlippage, tpPrice - safePriceDiffForLimits)
            : veryHighMaxPrice;
        minPrice =
          slPrice > 0
            ? Math.max(minPriceBySlippage, slPrice + safePriceDiffForLimits)
            : 0;
      } else {
        let tpPriceForShort = 0;
        let slPriceForShort = 0;
        if (slMultiplierBaseInput) {
          slPriceForShort = Number(slMultiplierBaseInput);
        } else {
          slPriceForShort =
            slMultiplier > 0
              ? openPrice * (1 + slMultiplier / leverage / 100)
              : 0;
        }
        if (tpMultiplierBaseInput) {
          tpPriceForShort = Number(tpMultiplierBaseInput);
        } else {
          tpPriceForShort =
            tpMultiplier > 0
              ? openPrice * (1 - tpMultiplier / leverage / 100)
              : 0;
        }

        // Quick-n-Dirty fix for <1 leverage
        tpPrice = tpPriceForShort > 0 ? tpPriceForShort : 0.01;
        slPrice = slPriceForShort;

        maxPrice =
          slPrice > 0
            ? Math.min(maxPriceBySlippage, slPrice - safePriceDiffForLimits)
            : veryHighMaxPrice;
        minPrice =
          tpPrice > 0
            ? Math.max(minPriceBySlippage, tpPrice + safePriceDiffForLimits)
            : 0;
      }
    } else if (openOrderType === OpenOrderTypeEnums.LIMIT) {
      minPrice = wantedMinOpenPrice;
      maxPrice = wantedMaxOpenPrice;

      // TODO : CRITICAL : Fix the TP and SL for limit orders after fixing the basic mechanism
      slPrice = 0;
      tpPrice = 0;
    } else {
      minPrice = 0;
      maxPrice = 0;

      slPrice = 0;
      tpPrice = 0;
    }

    return {
      minPrice,
      maxPrice,
      slPrice,
      tpPrice,
    };
  }, [
    pairAssetPrice,
    openOrderType,
    slippagePInUnits,
    isLong,
    slMultiplierBaseInput,
    tpMultiplierBaseInput,
    slMultiplier,
    openPrice,
    leverage,
    tpMultiplier,
    wantedMinOpenPrice,
    wantedMaxOpenPrice,
  ]);

  return { minPrice, maxPrice, slPrice, tpPrice };
};

export { usePositionPanel };
