import { toastError, toastInfo } from "./toasting";

export function toastTxError(e: unknown) {
  // @ts-ignore
  if (e?.reason) {
    // @ts-ignore
    if (e.reason === "user rejected transaction") {
      toastInfo("Canceled");
    } else {
      // @ts-ignore
      toastError(e.reason as string);
    }
  } else {
    // @ts-ignore
    toastError(e?.toString());
  }
}
