import {
  IIntentsDelegationService,
  TDelegatedActionResponse,
  THolderRequestPayload_ChipOutLZStruct,
  TLiquidityProviderRequestPayload_EpochDepositStruct,
  TLiquidityProviderRequestPayload_EpochRedeemStruct,
  TUserDirectPayload_CancelPendingLimitPositionStruct,
  TUserDirectPayload_UpdatePendingLimitPositionStruct,
  TUserRequestPayload_CloseMarketStruct,
  TUserRequestPayload_OpenPositionStruct,
  TUserRequestPayload_UpdatePositionDoubleFieldStruct,
  TUserRequestPayload_UpdatePositionSingleFieldStruct,
} from "./IIntentsDelegationService.ts";
import { stringifyObject } from "../../../utils/strings.ts";
import {
  ChipsIntentsVerifierActionsEnums,
  LiquidityIntentsVerifierActionsEnums,
} from "../../../constants/contractEnums.ts";
import { BytesLike } from "ethers";
import { THashBasedIntentsVerifierRequestBuildingInfoStruct } from "../../contractsIntegration/IntentsVerifierLensService/IIntentsVerifierLensService.ts";
import { TEngineChainIds } from "../../../constants/chainConstants.ts";

export enum ACTION_DELEGATION_TYPES {
  NONE,
  OPEN_POSITION,
  CLOSE_MARKET,
  UPDATE_SINGLE_FIELD,
  UPDATE_DOUBLE_FIELD,
  UPDATE_PENDING_LIMIT_POSITION,
  CANCEL_PENDING_LIMIT_POSITION,
}

const LIQUIDITY_ACTION_DELEGATION_ENUMS = LiquidityIntentsVerifierActionsEnums;
const CHIPS_ACTION_DELEGATION_ENUMS = ChipsIntentsVerifierActionsEnums;

type TIntentType = "trade" | "liquidity" | "chips";

export class IntentsDelegationService implements IIntentsDelegationService {
  constructor(
    private intentRequestBuildInfoUrl: string,
    private tradeIntentsDelegationEndpointUrl: string,
    private liquidityIntentsDelegationEndpointUrl: string,
    private chipsIntentsDelegationEndpointUrl: string,
  ) {}

  // ****** Trade Intents Delegations ******

  async requestTradeIntentRequestBuildingInfoFromServer(
    engineChainId: TEngineChainIds,
    positionId: BytesLike,
    actionType: number,
  ): Promise<THashBasedIntentsVerifierRequestBuildingInfoStruct> {
    const buildingInto: THashBasedIntentsVerifierRequestBuildingInfoStruct =
      await this.readIntentBuildingInfoFromServer(
        engineChainId,
        "trade",
        actionType,
        {
          positionId,
        },
      );

    return buildingInto;
  }

  async delegateRequestPositionOpen(
    payload: TUserRequestPayload_OpenPositionStruct,
    signature: string,
    domainStr: BytesLike,
    referralStr: BytesLike,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.tradeIntentsDelegationEndpointUrl,
      ACTION_DELEGATION_TYPES.OPEN_POSITION,
      payload,
      signature,
      {
        refCode: referralStr,
        domain: domainStr,
      },
    );
  }

  async delegateRequestMarketClose(
    payload: TUserRequestPayload_CloseMarketStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.tradeIntentsDelegationEndpointUrl,
      ACTION_DELEGATION_TYPES.CLOSE_MARKET,
      payload,
      signature,
    );
  }

  delegateRequestUpdatePositionSingleField(
    payload: TUserRequestPayload_UpdatePositionSingleFieldStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.tradeIntentsDelegationEndpointUrl,
      ACTION_DELEGATION_TYPES.UPDATE_SINGLE_FIELD,
      payload,
      signature,
    );
  }

  delegateRequestUpdatePositionDoubleField(
    payload: TUserRequestPayload_UpdatePositionDoubleFieldStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.tradeIntentsDelegationEndpointUrl,
      ACTION_DELEGATION_TYPES.UPDATE_DOUBLE_FIELD,
      payload,
      signature,
    );
  }

  delegateRequestUpdatePendingLimitPosition(
    payload: TUserDirectPayload_UpdatePendingLimitPositionStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.tradeIntentsDelegationEndpointUrl,
      ACTION_DELEGATION_TYPES.UPDATE_PENDING_LIMIT_POSITION,
      payload,
      signature,
    );
  }

  delegateRequestCancelPendingLimitPosition(
    payload: TUserDirectPayload_CancelPendingLimitPositionStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.tradeIntentsDelegationEndpointUrl,
      ACTION_DELEGATION_TYPES.CANCEL_PENDING_LIMIT_POSITION,
      payload,
      signature,
    );
  }

  // ****** Liquidity Intents Delegations ******

  delegateLiquidityRequest_epochDeposit(
    payload: TLiquidityProviderRequestPayload_EpochDepositStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.liquidityIntentsDelegationEndpointUrl,
      Number(LIQUIDITY_ACTION_DELEGATION_ENUMS.REQUEST_EPOCH_DEPOSIT),
      payload,
      signature,
    );
  }

  delegateLiquidityRequest_epochRedeem(
    payload: TLiquidityProviderRequestPayload_EpochRedeemStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.liquidityIntentsDelegationEndpointUrl,
      Number(LIQUIDITY_ACTION_DELEGATION_ENUMS.REQUEST_EPOCH_REDEEM),
      payload,
      signature,
    );
  }

  // ****** Chips Intents Delegations ******

  delegateChipsRequest_chipOutLZ(
    payload: THolderRequestPayload_ChipOutLZStruct,
    signature: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendSignedActionToDelegator(
      this.chipsIntentsDelegationEndpointUrl,
      Number(CHIPS_ACTION_DELEGATION_ENUMS.CHIP_OUT_LZ),
      payload,
      signature,
    );
  }

  // ****** Internals ******

  private async sendSignedActionToDelegator(
    endpoint: string,
    actionType: number,
    payload: object,
    signature: string,
    bodyExtra = {},
  ): Promise<TDelegatedActionResponse> {
    const completeEndpoint = endpoint;

    const body = {
      payload: payload,
      signature: signature,
      actionType: actionType,
      ...bodyExtra,
    };
    const stringifiedBody = stringifyObject(body);

    const response = await fetch(completeEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: stringifiedBody,
    });

    console.log(response);

    if (!response.ok) {
      throw new Error(
        `Delegation call to ${response.url}  failed ${response.statusText}`,
      );
    } else {
      const body = (await response.json()) as {
        tx: string | null;
        error: string | null;
      };

      return {
        tx: body.tx,
        error: body.error,
      };
    }
  }

  private async readIntentBuildingInfoFromServer(
    engineChainId: TEngineChainIds,
    intentType: TIntentType,
    actionType: number,
    bodyExtra = {},
  ): Promise<THashBasedIntentsVerifierRequestBuildingInfoStruct> {
    const completeEndpoint = this.intentRequestBuildInfoUrl;

    const body = {
      engineChainId,
      intentType,
      actionType,
      ...bodyExtra,
    };
    const stringifiedBody = stringifyObject(body);

    const response = await fetch(completeEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: stringifiedBody,
    });

    console.log(response);

    if (!response.ok) {
      throw new Error(
        `Request Intent build info call to ${response.url} failed ${response.statusText}`,
      );
    } else {
      const body = (await response.json()) as {
        intentRequestBuildingInfo: THashBasedIntentsVerifierRequestBuildingInfoStruct;
        error: string;
      };

      if (body.error) {
        throw new Error(
          `Request Intent build info call to ${response.url} body-failed ${body.error}`,
        );
      }

      return body.intentRequestBuildingInfo;
    }
  }
}
