import { logWalletConnection_firebase } from "../firebaseService/firebaseAnalytics.ts";
import {
  logError_sentry,
  logWalletConnection_sentry,
  logWalletDisconnection_sentry,
} from "../sentryService/sentryService.ts";

export function logWalletConnection(accountAddress: string) {
  if (accountAddress) {
    logWalletConnection_firebase(accountAddress);
    logWalletConnection_sentry(accountAddress);
  } else {
    logWalletDisconnection_sentry(accountAddress);
  }
}

function captureError(error: Error | string) {
  logError_sentry(error);
}

export const lifecycleIntegration = {
  captureError,
};
