import { ToastBody } from "lynx-ui-components";
import { Id, toast, ToastOptions, UpdateOptions } from "react-toastify";
import { TOAST_DEFAULT_CLOSE_MS } from "../toasts/toasting";

export enum toast_step {
  received = 1,
  processing = 2,
  success = 3,
}

const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  autoClose: false,
  closeOnClick: false,
  draggable: false,
  className: "StatusStepToast",
  theme: "dark",
};

interface ToastStatus {
  toastId: Id;
  step: toast_step;
}

interface ToastParams extends ToastOptions {
  title: string;
  description: string;
}

export class StatusToastManager {
  private toastMap = new Map<string, ToastStatus>();
  private options: ToastOptions;
  private title: string | undefined;
  private description: string | undefined;

  constructor(opt?: ToastParams) {
    const { title, description, ...rest } = opt ?? {};
    this.title = title;
    this.description = description;
    this.options = { ...DEFAULT_TOAST_OPTIONS, ...rest };
  }

  private updateToastOptions(
    requestId: string,
    step: toast_step,
    errorMessage?: string,
  ): UpdateOptions {
    return {
      render: (
        <ToastBody
          step={step}
          error={errorMessage}
          title={this.title}
          description={this.description}
        />
      ),
      ...this.options,
      onClose: () => this.resetToast(requestId, step),
      autoClose: step === toast_step.success ? TOAST_DEFAULT_CLOSE_MS : false,
    };
  }

  private toastOptions(requestId: string, step: toast_step): ToastOptions {
    return {
      ...this.options,
      autoClose: step === toast_step.success ? TOAST_DEFAULT_CLOSE_MS : false,
      onClose: () => this.resetToast(requestId, step),
    };
  }

  showToast(requestId: string) {
    const toastStatus: ToastStatus = {
      toastId: toast(
        <ToastBody
          step={toast_step.received}
          title={this.title}
          description={this.description}
        />,
        this.toastOptions(requestId, toast_step.received),
      ),
      step: toast_step.received,
    };
    this.toastMap.set(requestId, toastStatus);
  }

  replaceToastId(oldId: string, newId: string) {
    const toastStatus = this.toastMap.get(oldId);
    if (toastStatus) {
      this.toastMap.delete(oldId);
      this.toastMap.set(newId, toastStatus);
    }
  }

  updateToast(requestId: string, newStep?: toast_step, errorMessage?: string) {
    const toastStatus = this.toastMap.get(requestId);
    if (toastStatus && newStep) {
      toastStatus.step = newStep;
      toast.update(
        toastStatus.toastId,
        this.updateToastOptions(requestId, +newStep, errorMessage),
      );
      if (newStep === toast_step.success) {
        this.toastMap.delete(requestId);
      } else {
        this.toastMap.set(requestId, toastStatus);
      }
    }
  }

  getStep(requestId: string) {
    return this.toastMap.get(requestId)?.step;
  }

  getToastId(requestId: string) {
    return this.toastMap.get(requestId)?.toastId;
  }

  resetToast(requestId: string, step: toast_step) {
    if (step === toast_step.success) {
      this.toastMap.delete(requestId);
    }
  }

  setDescription(description: string | undefined) {
    this.description = description;
  }

  setTitle(title: string | undefined) {
    this.title = title;
  }
}
