import { TAssetParameters } from "../types/assetTypes.ts";
import {
  CHAIN_ID_ARBITRUM_ONE,
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_BSC,
  CHAIN_ID_CELO,
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_FUSE,
  CHAIN_ID_LINEA,
  CHAIN_ID_MANTLE_MAINNET,
  CHAIN_ID_MODE,
  CHAIN_ID_OPTIMISM,
  CHAIN_ID_POLYGON,
  TEngineChainIds,
  TSourceChainIds,
} from "./chainConstants.ts";
import {
  ARBITRUM_MAINNET_ASSETS,
  BOBA_ENGINE_CHIPS,
  BOBA_MAINNET_ASSETS,
  BSC_ASSETS,
  CELO_ASSETS,
  FANTOM_ASSETS,
  FANTOM_ENGINE_CHIPS,
  FANTOM_OFT_CHIPS,
  FUSE_MAINNET_ASSETS,
  LINEA_MAINNET_ASSETS,
  MANTLE_ASSETS,
  MODE_ASSETS,
  OPTIMISM_ASSETS,
  POLYGON_ASSETS,
} from "./chainAssets.ts";
import { ChipModeEnums, TChipModeEnum } from "./contractEnums.ts";

export type TChipConfigurations = {
  enginChainId: TEngineChainIds;
  chipAssetParams: TAssetParameters;

  chipMode: TChipModeEnum;

  sourceAssetParams: TAssetParameters;
  sourceChainId: TSourceChainIds;
  oftProxyAddress: string;
};

function buildFantomOftChipConfigurations(
  sourceChainId: TSourceChainIds,
  sourceAssetParams: TAssetParameters,
  chipAssetParams: TAssetParameters,
  oftProxyAddress = "",
): TChipConfigurations {
  return {
    enginChainId: CHAIN_ID_FANTOM_OPERA,
    chipMode: ChipModeEnums.REMOTE,
    sourceChainId: sourceChainId,
    sourceAssetParams,
    chipAssetParams,
    oftProxyAddress,
  };
}

const FANTOM_ENGINE_CHIP_FUSE_PDAI: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_FUSE_PLAY_DAI,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_FUSE,
  sourceAssetParams: FUSE_MAINNET_ASSETS.PLAY_DAI,
  oftProxyAddress: "0x3b7ED1cDF0Fc64d95c0D0428b9Cc99b6A9a5CB94",
};

const FANTOM_ENGINE_CHIP_FUSE_WFUSE: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_FUSE_PLAY_WFUSE,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_FUSE,
  sourceAssetParams: FUSE_MAINNET_ASSETS.WFUSE,
  oftProxyAddress: "0x962FD1B229c8c775bC2E37A8a90dac4f3C0105B7",
};

const FANTOM_ENGINE_CHIP_FUSE_MST: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_FUSE_PLAY_MST,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_FUSE,
  sourceAssetParams: FUSE_MAINNET_ASSETS.MST,
  oftProxyAddress: "0x028815b56433a4AAe10087290d1Ed9Ef7437068F",
};
const FANTOM_ENGINE_CHIP_FUSE_SFUSE: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_FUSE_SFUSE,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_FUSE,
  sourceAssetParams: FUSE_MAINNET_ASSETS.SFUSE,
  oftProxyAddress: "0x707f3d554B47E17F1FDfb408FE091B39D51929CF",
};
const FANTOM_ENGINE_CHIP_FUSE_VOLT: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_FUSE_VOLT,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_FUSE,
  sourceAssetParams: FUSE_MAINNET_ASSETS.VOLT,
  oftProxyAddress: "0x094DE4d315198Df981D3a20ceFc3381B2182a572",
};

// const FANTOM_ENGINE_CHIP_LINEA_veLVC: TChipConfigurations = {
//   enginChainId: CHAIN_ID_FANTOM_OPERA,
//   chipAssetParams: FANTOM_OFT_CHIPS.OFT_LINEA_vcLVC,
//
//   sourceChainId: CHAIN_ID_LINEA,
//   sourceAssetParams: LINEA_MAINNET_ASSETS.veLVC,
//   oftProxyAddress: "0xC5E782E2A4E2cFCb7eD454CF5a7b6aa2bB424B90",
// };

const FANTOM_ENGINE_CHIP_LINEA_LVC: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_LINEA_LVC,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_LINEA,
  sourceAssetParams: LINEA_MAINNET_ASSETS.LVC,
  oftProxyAddress: "0x55f2f3fA843C1755e17eb5F32D29a35c99a3aF09",
};

// const FANTOM_ENGINE_CHIP_LINEA_agEUR: TChipConfigurations = {
//   enginChainId: CHAIN_ID_FANTOM_OPERA,
//   chipAssetParams: FANTOM_OFT_CHIPS.OFT_LINEA_agEUR,
//
//   sourceChainId: CHAIN_ID_LINEA,
//   sourceAssetParams: LINEA_MAINNET_ASSETS.agEUR,
//   oftProxyAddress: "0x962FD1B229c8c775bC2E37A8a90dac4f3C0105B7",
// };

const FANTOM_ENGINE_CHIP_ARBITRUM_ARB = buildFantomOftChipConfigurations(
  CHAIN_ID_ARBITRUM_ONE,
  ARBITRUM_MAINNET_ASSETS.ARB,
  FANTOM_OFT_CHIPS.OFT_ARBITRUM_ARB,
  "0x094DE4d315198Df981D3a20ceFc3381B2182a572",
);
const FANTOM_ENGINE_CHIP_ARBITRUM_stEUR: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_ARBITRUM_STEUR,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_ARBITRUM_ONE,
  sourceAssetParams: ARBITRUM_MAINNET_ASSETS.stEUR,
  oftProxyAddress: "0xC5E782E2A4E2cFCb7eD454CF5a7b6aa2bB424B90",
};
const FANTOM_ENGINE_CHIP_ARBITRUM_TST: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_ARBITRUM_TST,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_ARBITRUM_ONE,
  sourceAssetParams: ARBITRUM_MAINNET_ASSETS.TST,
  oftProxyAddress: "0xD22c72aB0f4967edB876d84773BfF0b60A92e51a",
};
const FANTOM_ENGINE_CHIP_ARBITRUM_EUROS: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_ARBITRUM_EUROs,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_ARBITRUM_ONE,
  sourceAssetParams: ARBITRUM_MAINNET_ASSETS.EUROs,
  oftProxyAddress: "0x3552fE61af3F6d3235Dd1CB75402d4281d1FbaC6",
};
const FANTOM_ENGINE_CHIP_ARBITRUM_GRAI: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_ARBITRUM_GRAI,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_ARBITRUM_ONE,
  sourceAssetParams: ARBITRUM_MAINNET_ASSETS.GRAI,
  oftProxyAddress: "0xBe1fa4177fBf43683434CecD5563DA6Ea00FD474",
};
const FANTOM_ENGINE_CHIP_ARBITRUM_SLIZ = buildFantomOftChipConfigurations(
  CHAIN_ID_ARBITRUM_ONE,
  ARBITRUM_MAINNET_ASSETS.SLIZ,
  FANTOM_OFT_CHIPS.OFT_ARBITRUM_SLIZ,
  "0x1E1F546dF45A82F2a29E709C85331E3974dC26b0",
);
const FANTOM_ENGINE_CHIP_ARBITRUM_SCALES = buildFantomOftChipConfigurations(
  CHAIN_ID_ARBITRUM_ONE,
  ARBITRUM_MAINNET_ASSETS.SCALES,
  FANTOM_OFT_CHIPS.OFT_ARBITRUM_SCALES,
  "0x1E71Fad2d453dAb287Dad8CD003CA24A9d9194EA",
);
const FANTOM_ENGINE_CHIP_ARBITRUM_USDFI = buildFantomOftChipConfigurations(
  CHAIN_ID_ARBITRUM_ONE,
  ARBITRUM_MAINNET_ASSETS.USDFI,
  FANTOM_OFT_CHIPS.OFT_ARBITRUM_USDFI,
  "0x24d6318B87ABB45B62D981693FCF25A5956F41e2",
);

const FANTOM_ENGINE_CHIP_OPTIMISM_SONNE: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_OPTIMISM_SONNE,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_OPTIMISM,
  sourceAssetParams: OPTIMISM_ASSETS.SONNE,
  oftProxyAddress: "0xC5E782E2A4E2cFCb7eD454CF5a7b6aa2bB424B90",
};

const FANTOM_ENGINE_CHIP_POLYGON_WMATIC: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_POLYGON_WMATIC,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_POLYGON,
  sourceAssetParams: POLYGON_ASSETS.WMATIC,
  oftProxyAddress: "0x028815b56433a4AAe10087290d1Ed9Ef7437068F",
};

const FANTOM_ENGINE_CHIP_POLYGON_miMATIC: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_POLYGON_MIMATIC,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_POLYGON,
  sourceAssetParams: POLYGON_ASSETS.miMATIC,
  oftProxyAddress: "0x7279d1cFf1510E503B6Be64fBBAd64088034504C",
};

const FANTOM_ENGINE_CHIP_MANTLE_aUSD: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_MANTLE_AUSD,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_MANTLE_MAINNET,
  sourceAssetParams: MANTLE_ASSETS.aUSD,
  oftProxyAddress: "0xC5E782E2A4E2cFCb7eD454CF5a7b6aa2bB424B90",
};

const FANTOM_OFT_CHIP_BSC_lisUSD: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_BSC_LISUSD,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_BSC,
  sourceAssetParams: BSC_ASSETS.lisUSD,
  oftProxyAddress: "0x3b7ED1cDF0Fc64d95c0D0428b9Cc99b6A9a5CB94",
};

const FANTOM_OFT_CHIP_MODE_MODE: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_MODE_MODE,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_MODE,
  sourceAssetParams: MODE_ASSETS.MODE,
  oftProxyAddress: "0x3b7ED1cDF0Fc64d95c0D0428b9Cc99b6A9a5CB94",
};

const FANTOM_OFT_CHIP_MODE_ION: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_MODE_ION,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_MODE,
  sourceAssetParams: MODE_ASSETS.ION,
  oftProxyAddress: "0xD22c72aB0f4967edB876d84773BfF0b60A92e51a",
};

const FANTOM_OFT_CHIP_CELO_CELO: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_CELO_CELO,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_CELO,
  sourceAssetParams: CELO_ASSETS.CELO,
  oftProxyAddress: "0x7279d1cFf1510E503B6Be64fBBAd64088034504C",
};
const FANTOM_OFT_CHIP_CELO_USDT: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_OFT_CHIPS.OFT_CELO_USDT,

  chipMode: ChipModeEnums.REMOTE,

  sourceChainId: CHAIN_ID_CELO,
  sourceAssetParams: CELO_ASSETS.USDT,
  oftProxyAddress: "0xA36cB6e644cCE5fB98bDa9aa538927B2c934D8fa",
};

function buildFantomEngineChipConfigurations(
  sourceAssetParams: TAssetParameters,
  chipAssetParams: TAssetParameters,
  oftProxyAddress = "",
): TChipConfigurations {
  return {
    sourceChainId: CHAIN_ID_FANTOM_OPERA,
    enginChainId: CHAIN_ID_FANTOM_OPERA,
    chipMode: ChipModeEnums.LOCAL,
    sourceAssetParams,
    chipAssetParams,
    oftProxyAddress,
  };
}

const FANTOM_ENGINE_CHIP_FANTOM_FTM: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_FTM,

  chipMode: ChipModeEnums.LOCAL,

  sourceChainId: CHAIN_ID_FANTOM_OPERA,
  sourceAssetParams: FANTOM_ASSETS.NATIVE_FTM,
  oftProxyAddress: "",
};

const FANTOM_ENGINE_CHIP_FANTOM_EQUAL: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_EQUAL,

  chipMode: ChipModeEnums.LOCAL,

  sourceChainId: CHAIN_ID_FANTOM_OPERA,
  sourceAssetParams: FANTOM_ASSETS.EQUAL,
  oftProxyAddress: "",
};

const FANTOM_ENGINE_CHIP_FANTOM_BRUSH: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_BRUSH,

  chipMode: ChipModeEnums.LOCAL,

  sourceChainId: CHAIN_ID_FANTOM_OPERA,
  sourceAssetParams: FANTOM_ASSETS.BRUSH,
  oftProxyAddress: "",
};

const FANTOM_ENGINE_CHIP_FANTOM_FSONIC: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_FSONIC,

  chipMode: ChipModeEnums.LOCAL,

  sourceChainId: CHAIN_ID_FANTOM_OPERA,
  sourceAssetParams: FANTOM_ASSETS.fSONIC,
  oftProxyAddress: "",
};

const FANTOM_ENGINE_CHIP_FANTOM_POLTER: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_POLTER,

  chipMode: ChipModeEnums.LOCAL,

  sourceChainId: CHAIN_ID_FANTOM_OPERA,
  sourceAssetParams: FANTOM_ASSETS.POLTER,
  oftProxyAddress: "",
};

const FANTOM_ENGINE_CHIP_FANTOM_FBUX: TChipConfigurations = {
  enginChainId: CHAIN_ID_FANTOM_OPERA,
  chipAssetParams: FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_FBUX,

  chipMode: ChipModeEnums.LOCAL,

  sourceChainId: CHAIN_ID_FANTOM_OPERA,
  sourceAssetParams: FANTOM_ASSETS.fBUX,
  oftProxyAddress: "",
};

const FANTOM_ENGINE_CHIP_FANTOM_FTAILS = buildFantomEngineChipConfigurations(
  FANTOM_ASSETS.FTAILS,
  FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_FTAILS,
);

const FANTOM_ENGINE_CHIP_FANTOM_USDC = buildFantomEngineChipConfigurations(
  FANTOM_ASSETS.USDC,
  FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_USDC,
);

const FANTOM_ENGINE_CHIP_FANTOM_SPIRIT = buildFantomEngineChipConfigurations(
  FANTOM_ASSETS.SPIRIT,
  FANTOM_ENGINE_CHIPS.ENGINE_FANTOM_SPIRIT,
);

export const FANTOM_ENGINE_CHIPS_CONFIGURATIONS = {
  FUSE_PDAI: FANTOM_ENGINE_CHIP_FUSE_PDAI,
  FUSE_WFUSE: FANTOM_ENGINE_CHIP_FUSE_WFUSE,
  FUSE_MST: FANTOM_ENGINE_CHIP_FUSE_MST,
  FUSE_SFUSE: FANTOM_ENGINE_CHIP_FUSE_SFUSE,
  FUSE_VOLT: FANTOM_ENGINE_CHIP_FUSE_VOLT,

  LINEA_LVC: FANTOM_ENGINE_CHIP_LINEA_LVC,
  // LINEA_VCLVC: FANTOM_ENGINE_CHIP_LINEA_veLVC,
  // LINEA_AGEUR: FANTOM_ENGINE_CHIP_LINEA_agEUR,

  ARBITRUM_ARB: FANTOM_ENGINE_CHIP_ARBITRUM_ARB,
  ARBITRUM_STEUR: FANTOM_ENGINE_CHIP_ARBITRUM_stEUR,
  ARBITRUM_TST: FANTOM_ENGINE_CHIP_ARBITRUM_TST,
  ARBITRUM_EUROS: FANTOM_ENGINE_CHIP_ARBITRUM_EUROS,
  ARBITRUM_GRAI: FANTOM_ENGINE_CHIP_ARBITRUM_GRAI,
  ARBITRUM_SLIZ: FANTOM_ENGINE_CHIP_ARBITRUM_SLIZ,
  ARBITRUM_SCALES: FANTOM_ENGINE_CHIP_ARBITRUM_SCALES,
  ARBITRUM_USDFI: FANTOM_ENGINE_CHIP_ARBITRUM_USDFI,

  OPTIMISM_SONNE: FANTOM_ENGINE_CHIP_OPTIMISM_SONNE,

  POLYGON_WMATIC: FANTOM_ENGINE_CHIP_POLYGON_WMATIC,
  POLYGON_MIMATIC: FANTOM_ENGINE_CHIP_POLYGON_miMATIC,

  MANTLE_AUSD: FANTOM_ENGINE_CHIP_MANTLE_aUSD,

  BSC_LISUSD: FANTOM_OFT_CHIP_BSC_lisUSD,

  MODE_MODE: FANTOM_OFT_CHIP_MODE_MODE,
  MODE_ION: FANTOM_OFT_CHIP_MODE_ION,

  CELO_CELO: FANTOM_OFT_CHIP_CELO_CELO,
  CELO_USDT: FANTOM_OFT_CHIP_CELO_USDT,

  FANTOM_FTM: FANTOM_ENGINE_CHIP_FANTOM_FTM,
  FANTOM_EQUAL: FANTOM_ENGINE_CHIP_FANTOM_EQUAL,
  FANTOM_BRUSH: FANTOM_ENGINE_CHIP_FANTOM_BRUSH,
  FANTOM_FSONIC: FANTOM_ENGINE_CHIP_FANTOM_FSONIC,
  FANTOM_POLTER: FANTOM_ENGINE_CHIP_FANTOM_POLTER,
  FANTOM_FBUX: FANTOM_ENGINE_CHIP_FANTOM_FBUX,
  FANTOM_FTAILS: FANTOM_ENGINE_CHIP_FANTOM_FTAILS,
  FANTOM_USDC: FANTOM_ENGINE_CHIP_FANTOM_USDC,
  FANTOM_SPIRIT: FANTOM_ENGINE_CHIP_FANTOM_SPIRIT,
};

function buildBobaEngineChipConfigurations(
  sourceAssetParams: TAssetParameters,
  chipAssetParams: TAssetParameters,
): TChipConfigurations {
  return {
    sourceChainId: CHAIN_ID_BOBA_MAINNET,
    enginChainId: CHAIN_ID_BOBA_MAINNET,
    chipMode: ChipModeEnums.LOCAL,
    sourceAssetParams,
    chipAssetParams,
    oftProxyAddress: "",
  };
}

export const BOBA_ENGINE_CHIPS_CONFIGURATIONS = {
  CORE_USDC: buildBobaEngineChipConfigurations(
    BOBA_MAINNET_ASSETS.USDC,
    BOBA_ENGINE_CHIPS.ENGINE_BOBA_USDC,
  ),
  CORE_BOBA: buildBobaEngineChipConfigurations(
    BOBA_MAINNET_ASSETS.BOBA,
    BOBA_ENGINE_CHIPS.ENGINE_BOBA_BOBA,
  ),
};
