import React, { useMemo } from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { unitsBnToFloat } from "../../../../utils/bignumbers";
import { Tooltip, Typography } from "@mui/material";
import { MaterialUiTable } from "../../../../components/Tables/MaterialUiTable";
import { PAIR_ID_TO_NAME } from "../../../../constants/pairsDisplayConstants";
import { TPairIds } from "../../../../constants/pairsConstants";
import {
  THEME_CONSTANTS,
  THEME_STATE_CONSTANTS,
} from "../../../../theme/ThemeConstants";
import { TClosedPositionGist } from "../../../../services/servicesIntergration/graphqlService/IGraphQLService";
import { makeStyles } from "tss-react/mui-compat";

interface IProps {
  cancelledPositionGists: TClosedPositionGist[];

  settlementAssetSymbol: string;
  settlementAssetDecimals: number;

  explorerTxBaseUrl: string;
}

const useStyles = makeStyles()((theme) => ({
  link: {
    // textDecoration: "none",
    color: THEME_CONSTANTS.brandLightPurple,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export const CancelledPositionsTable = React.memo<IProps>((props) => {
  const {
    cancelledPositionGists,
    settlementAssetSymbol,
    settlementAssetDecimals,
    explorerTxBaseUrl,
  } = props;
  const { classes } = useStyles();

  // console.log(`!!!! cancelledPositionGists ${cancelledPositionGists.length}`);

  const columns: ColumnDef<TClosedPositionGist>[] = useMemo<
    ColumnDef<TClosedPositionGist>[]
  >(() => {
    return [
      {
        header: "Type",
        id: "type",
        accessorFn: (originalRow) => (originalRow.long ? "Long" : "Short"),
        cell: (cellProps: CellContext<TClosedPositionGist, unknown>) => {
          const cellValue = cellProps.getValue() as "Long" | "Short";
          const isLong = cellValue == "Long";
          return (
            <Typography
              sx={{
                color: isLong
                  ? THEME_STATE_CONSTANTS.textColorLong
                  : THEME_STATE_CONSTANTS.textColorShort,
              }}
            >
              {cellValue}
            </Typography>
          );
        },
      },
      {
        header: "Pair",
        id: "pair_id",
        accessorFn: (originalRow) =>
          `${PAIR_ID_TO_NAME[originalRow.pairId as TPairIds]}`,
      },
      {
        header: "Leverage",
        id: "leverage",
        accessorFn: (originalRow) => `${originalRow.leverageInUnits}x`,
      },
      {
        header: "Collateral",
        id: "collateral",
        accessorKey: `initialCollateral`,
        cell: (cellProps) => {
          const closedTrade = cellProps.row.original;

          const initialCollateralInUnits = unitsBnToFloat(
            closedTrade.initialCollateral,
            settlementAssetDecimals,
          );
          return (
            <Tooltip title={``}>
              <Typography>
                {`${initialCollateralInUnits} ${settlementAssetSymbol}`}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        header: "Reason",
        id: "reason",
        cell: (cellProps) => {
          const originalRow = cellProps.row.original;
          const isCapCancelled = originalRow.isOpenCapCancelled;
          const cancellerRole = originalRow.openOrderCancellationSourceRole;

          // TODO : Improves this text, market orders can be cancelled due to price range,
          //        and users/bots can cancel after timeout
          // const text = originalRow.cancellationCapType;

          const text =
            cancellerRole == "TRIGGERS"
              ? "bot timeout"
              : cancellerRole == "TRADERS_PORTAL"
                ? "self timeout"
                : originalRow.isOpenCapCancelled
                  ? "cap"
                  : originalRow.isMarketPriceRangeCancelled
                    ? "market price"
                    : "unknown";

          return (
            //   TODO : Make dynamic
            <a
              className={classes.link}
              target={"_blank"}
              rel={"noreferrer noopener"}
              href={`${explorerTxBaseUrl}/${originalRow.cancellationTx}`}
            >
              {text}
            </a>
          );
        },
      },
      {
        header: "Fee",
        id: "fee",
        cell: (cellProps) => {
          const originalRow = cellProps.row.original;
          const feeInUnits = unitsBnToFloat(
            originalRow.openOrderCancellationFee,
            settlementAssetDecimals,
          );

          return (
            //   TODO : Make dynamic
            <Typography>{`${feeInUnits} ${settlementAssetSymbol}`}</Typography>
          );
        },
      },
    ];
  }, [
    settlementAssetDecimals,
    settlementAssetSymbol,
    classes.link,
    explorerTxBaseUrl,
  ]);

  return (
    <MaterialUiTable
      columns={columns}
      data={cancelledPositionGists}
      getRowId={(val: TClosedPositionGist) =>
        `${val.closeTx}_${val.settlementAsset}_${val.pairId}_${val.positionIndex}_${val.sameIdCounter}`
      }
    />
  );
});
