import React, { useMemo } from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { MaterialUiTable } from "../../../../components/Tables/MaterialUiTable";
import { Button, Typography } from "@mui/material";
import { BytesLike } from "ethers";
import { TCompletePositionDataFromLens } from "../../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService";
import { PositionPhaseEnumsDisplay } from "../../../../constants/contractEnumsDisplay";
import { THEME_STATE_CONSTANTS } from "../../../../theme/ThemeConstants";
import { PAIR_ID_TO_NAME } from "../../../../constants/pairsDisplayConstants";
import { TPairIds } from "../../../../constants/pairsConstants";
import { priceBnToFloat } from "../../../../utils/lynxScalesUtils.ts";

interface IProps {
  pendingLimitPositions: TCompletePositionDataFromLens[];

  showCancelPendingLimitOrder: (positionId: BytesLike) => void;
}

export const PendingLimitOrdersTable = React.memo<IProps>((props) => {
  const { pendingLimitPositions, showCancelPendingLimitOrder } = props;

  const columns: ColumnDef<TCompletePositionDataFromLens>[] = useMemo<
    ColumnDef<TCompletePositionDataFromLens>[]
  >(() => {
    return [
      {
        Header: "Status",
        id: "status",
        accessorFn: (originalRow) => {
          return PositionPhaseEnumsDisplay[Number(originalRow.phase)];
        },
      },
      {
        Header: "Type",
        id: "type",
        accessorFn: (originalRow) => (originalRow.long ? "Long" : "Short"),
        cell: (cellProps) => {
          const cellValue = cellProps.getValue() as "Long" | "Short";
          const isLong = cellValue == "Long";
          return (
            <Typography
              sx={{
                color: isLong
                  ? THEME_STATE_CONSTANTS.textColorLong
                  : THEME_STATE_CONSTANTS.textColorShort,
              }}
            >
              {cellValue}
            </Typography>
          );
        },
      },
      {
        Header: "Pair",
        id: "pair_id",
        accessorFn: (originalRow) =>
          `${PAIR_ID_TO_NAME[Number(originalRow.pairId) as TPairIds]}`,
      },
      {
        Header: "Since",
        id: "inPhaseSince",
        accessorFn: (originalRow) =>
          `${new Date(
            Number(originalRow.inPhaseSince) * 1000,
          ).toLocaleDateString()} ${new Date(
            Number(originalRow.inPhaseSince) * 1000,
          ).toLocaleTimeString()}`,
      },
      {
        Header: "Min Open Price",
        id: "minPrice",
        accessorFn: (originalRow) =>
          `${priceBnToFloat(originalRow.minPrice).toLocaleString()} $`,
      },
      {
        Header: "Max Open Price",
        id: "maxPrice",
        accessorFn: (originalRow) =>
          `${priceBnToFloat(originalRow.maxPrice).toLocaleString()} $`,
      },
      {
        Header: "SL",
        id: "sl",
        accessorFn: (originalRow) =>
          `${priceBnToFloat(originalRow.sl).toLocaleString()} $`,
      },
      {
        Header: "TP",
        id: "tp",
        accessorFn: (originalRow) =>
          `${priceBnToFloat(originalRow.tp).toLocaleString()} $`,
      },

      // TODO : Add option to close pending options
      {
        Header: "Close",
        id: "close",
        // TODO : Only show this if the timeout buffer has passed
        cell: (cellProps) => (
          <Button
            onClick={() =>
              showCancelPendingLimitOrder(cellProps.row.original.id)
            }
          >
            X
          </Button>
        ),
      },
    ];
  }, [showCancelPendingLimitOrder]);

  const data = useMemo(() => {
    return [...pendingLimitPositions];
  }, [pendingLimitPositions]);

  return (
    <MaterialUiTable
      columns={columns}
      data={data}
      getRowId={(val: TCompletePositionDataFromLens) =>
        `${val.settlementAsset}_${val.pairId}_${val.positionIndex}`
      }
    />
  );
});
