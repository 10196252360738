import { TDelegatedActionResponse } from "../intentsDelegationService/IIntentsDelegationService.ts";
import { BytesLike } from "ethers";
import { GENERAL_BAAS_INTERACTION_ENDPOINTS_BY_ENGINE_CHAIN_ID } from "../../../constants/externalServicesConstants.ts";
import { GeneralAppBaasInteractionService } from "./GeneralAppBaasInteractionService.ts";
import { TEngineChainIds } from "../../../constants/chainConstants.ts";

/**
 * Used for an assortment of app-baas cloud functions that are too few to justify a dedicated service
 */
export interface IGeneralAppBaasInteractionService {
  timeoutMarketOpenOrder(
    positionId: BytesLike,
  ): Promise<TDelegatedActionResponse>;
  timeoutMarketCloseOrder(
    positionId: BytesLike,
  ): Promise<TDelegatedActionResponse>;

  requestPullAllAndClaim(
    tokenAddress: string,
    accountAddress: string,
  ): Promise<TDelegatedActionResponse>;
}

export function buildGeneralAppBaasInteractionService(
  engineChainId: TEngineChainIds,
): IGeneralAppBaasInteractionService {
  const generalBaasInteractionEndpoints =
    GENERAL_BAAS_INTERACTION_ENDPOINTS_BY_ENGINE_CHAIN_ID[engineChainId];
  const baasGeneralInteractionService = new GeneralAppBaasInteractionService(
    generalBaasInteractionEndpoints.timeoutMarketOpenOrderUrl,
    generalBaasInteractionEndpoints.timeoutMarketCloseOrderUrl,
    generalBaasInteractionEndpoints.pullAllAndClaimForUrl,
  );

  return baasGeneralInteractionService;
}
