import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import { ethers } from "ethers";
import { TTradeActionType } from "../../../store/multiInstancesStores/TradePairStore/SingleTradePairStore.ts";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants.ts";
import { numericalDisplay } from "../../../ux/displayCalculations.ts";
import { floatToUnitsBn, unitsBnToFloat } from "../../../utils/bignumbers.ts";
import {
  useModalsStore,
  usePairAssetPrice,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import { useCalculatePositionLiquidationPrice } from "../../../hooks/tradeCalculationsHooks.ts";
import {
  OpenOrderTypeEnums,
  TOpenOrderType,
} from "../../../constants/contractEnums.ts";
import { TCallback } from "../../../types/generalTypes.ts";
import { toastInfo } from "../../../ux/toasts/toasting.ts";
import { TPairIds } from "../../../constants/pairsConstants.ts";
import { localStringer } from "../../../ux/uxFunctions.ts";
import { PRICE_UX_FOR_PAIRS } from "../../../ux/assetsUxPrecisions.ts";
import { PAIR_ID_TO_BASE_ASSET } from "../../../constants/pairsDisplayConstants.ts";
import { PositionOpenPanel } from "lynx-ui-components";
import { usePositionPanel } from "../../../hooks/positionPanelHooks.ts";
import {
  getSpreadInUnits,
  positionPanelConstants,
} from "../../../constants/positionPanelConstants.ts";
import {
  calculateStopLoss,
  calculateTakeProfit,
  getLossSubTitle,
  getProfitSubTitle,
  getProfitSubValue,
  getSLDecriptor,
  getTPDecriptor,
} from "../../../utils/positionUtilFunctions.ts";
import {
  useIsCatModePair,
  useLeverageOptionsForPair,
  useLimitOptionsForPair,
  useLimitOrderStateAndLogic,
  useConnectedCurrencySelectionComponent,
  useSummaryItemsForPositionOpeningPanel,
} from "./positionPanelHooks.tsx";
import { bnRoundingUtils } from "../../../utils/bnRoundingUtils.ts";
import { TSourceChainIds } from "../../../constants/chainConstants.ts";

interface IProps {
  traderSABalanceBn: bigint;
  approveAction: (token: string, amount: bigint) => void;
  requestOpenPosition: (
    openOrderType: TOpenOrderType,
    positionIndex: number,
    isLong: boolean,
    positionSize: bigint,
    leverage: number,
    minPrice: number,
    maxPrice: number,
    tpPrice: number,
    slPrice: number,
    tpByFraction: number,
    slByFraction: number,
  ) => Promise<void>;
  onPairSelected: (pairIdStr: string) => void;

  isConnectedToWallet: boolean;
  requestWalletConnectionFunction: () => void;

  // if the ux is waiting for the user to act, so we can prevent the user from clicking again
  isActionPending: boolean;
  pendingActionText: string;

  settlementAssetAddress: string;
  settlementAssetDecimals: number;
  settlementAssetSymbol: string;

  sourceChainId: TSourceChainIds;
  lexId: string;
  pairId: TPairIds;

  firstAvailablePositionIndexForAccount: number;

  openFeeFInUnits: number;
  closeFeeFInUnits: number;
  allowanceForCenterInUnits: number;

  // Pool State
  poolBorrowCapacityInUnits: number;

  // Limits
  minOpenFeeInUnits: number;
  maxLeveragedPositionInUnits: number;

  // Rewards Estimation
  // estimateRewardsForPosition: (positionSize: number) => number;
}

const useStyles = makeStyles()((theme) => ({
  panelDiv: {
    flex: 1,
    height: "100%",
    backgroundColor: THEME_CONSTANTS.pageBg,
    color: "#ffffff",
    padding: "16px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "0.8rem",
    [theme.breakpoints.down(1200)]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "100%",
    },
  },
}));

export const PositionOpenPanelWrappe = observer<
  React.FunctionComponent<IProps>
>((props) => {
  const { classes } = useStyles();
  const [shouldDepositModal, setShouldDepositModal] = useState(false);
  const {
    traderSABalanceBn,

    approveAction,
    requestOpenPosition,
    onPairSelected,
    settlementAssetAddress,
    settlementAssetDecimals,
    settlementAssetSymbol,

    sourceChainId,
    lexId,
    pairId,

    firstAvailablePositionIndexForAccount,
    openFeeFInUnits,
    closeFeeFInUnits,
    allowanceForCenterInUnits,

    isConnectedToWallet,
    requestWalletConnectionFunction,

    isActionPending,
    pendingActionText,

    // Pool State
    poolBorrowCapacityInUnits,

    // Limits
    minOpenFeeInUnits,
    maxLeveragedPositionInUnits,

    // Rewards
    // estimateRewardsForPosition,
  } = props;

  // ***** Safe Params *****
  // NOTE : This is done to fix "INVALID_INDEX" errors when the app sends 0 as the first available position
  const safeFirstAvailablePositionIndexForAccount =
    firstAvailablePositionIndexForAccount == 0
      ? 1
      : firstAvailablePositionIndexForAccount;

  // ***** Stores *****

  const modalsStore = useModalsStore();

  // ***** Props Derived State *****

  const traderAccountBalanceInUnits = unitsBnToFloat(
    traderSABalanceBn,
    settlementAssetDecimals,
  );
  const spreadInPercentages = getSpreadInUnits(pairId) * 100;

  // ***** Pair Configs *****

  const pairAssetName = PAIR_ID_TO_BASE_ASSET[pairId];
  const pairAssetPrice = usePairAssetPrice(pairId);
  const pairPriceDisplayDecimals = PRICE_UX_FOR_PAIRS[pairId];

  // ***** Ux Configs *****

  const { leverageMin, leverageMax, leverageOptions } =
    useLeverageOptionsForPair(pairId);

  const { slMarks, tpMarks } = useLimitOptionsForPair(pairId);

  const collateralSizeInputExtraOptions =
    positionPanelConstants.collateralSizeInputExtraOptions;

  const positionTypeSelectorOptions =
    positionPanelConstants.positionTypeSelectorOptions;

  const wantedTpMultiplierDefaultValue =
    positionPanelConstants.defaultTpMultiplier;
  const wantedSlMultiplierDefaultValue =
    positionPanelConstants.defaultSlMultiplier;

  const lossMaxLimit = 1000000;
  const profitMaxLimit = 1000000;

  // ***** Position Type State *****

  const [openOrderType, setOpenOrderType] = useState<TOpenOrderType>(
    OpenOrderTypeEnums.MARKET,
  );
  const [tradeActionType, setTradeActionType] =
    useState<TTradeActionType>("long");

  const isLong = tradeActionType == "long";
  const isLimitOrder = openOrderType === OpenOrderTypeEnums.LIMIT;

  // ***** Position Params State *****

  const [wantedLeverage, setWantedLeverage] = useState(2);
  // TODO : Use value from real input
  // TODO : Turn into a number
  const [slippagePInUnits, setSlippagePIUnits] = useState("1"); // 0.1%

  // ***** String Inputs State *****

  const [wantedPositionSizeUnitsString, setWantedPositionSizeUnitsString] =
    useState(0);

  const [wantedTpInPercentages, setWantedTpInPercentages] = useState(
    wantedTpMultiplierDefaultValue,
  );
  const [wantedSlInPercentages, setWantedSlInPercentages] = useState(
    wantedSlMultiplierDefaultValue,
  );

  const [wantedTpMultiplierBaseInput, setWantedTpMultiplierBaseInput] =
    useState("");
  const [wantedSlMultiplierBaseInput, setWantedSlMultiplierBaseInput] =
    useState("");

  const wantedTpMultiplierInUnits = wantedTpInPercentages / 100;

  // ***** Limit Order Only State & Logic *****

  const {
    wantedMinOpenPrice,
    wantedMaxOpenPrice,
    wantedEntryPriceForLimitNumeric,
    wantedEntryPriceForLimitInput,
    setWantedEntryPriceForLimitInput,
  } = useLimitOrderStateAndLogic(pairId, isLimitOrder, slippagePInUnits);

  // ***** Position & Borrow Size *****

  const positionSizeInUnits = parseFloat(
    wantedPositionSizeUnitsString.toString(),
  );
  const positionSizeBn = floatToUnitsBn(
    positionSizeInUnits,
    settlementAssetDecimals,
  );

  // Borrow needed
  const borrowSizeInUnits = positionSizeInUnits * wantedTpMultiplierInUnits;

  // ***** Open Price & Open Fee *****

  const openPrice = pairAssetPrice;
  const spreadInUnits = getSpreadInUnits(pairId);
  const spreadSize = spreadInUnits * openPrice;
  const openPriceAfterSpread = isLong
    ? openPrice + spreadSize
    : openPrice - spreadSize;

  const leveragedPositionSizeInUnits = positionSizeInUnits * wantedLeverage;

  const expectedOpenFee = leveragedPositionSizeInUnits * openFeeFInUnits;

  const effectiveOpenPrice = isLimitOrder
    ? wantedEntryPriceForLimitNumeric
    : openPriceAfterSpread;

  // NOTE : Quick-n-Dirty fix to make sure the leverage input somewhat works
  let entryPriceStr = numericalDisplay(
    effectiveOpenPrice,
    pairPriceDisplayDecimals,
  );
  entryPriceStr =
    (isLimitOrder && wantedEntryPriceForLimitInput.endsWith(".")) ||
    wantedEntryPriceForLimitInput.endsWith(".0")
      ? entryPriceStr + ".0"
      : entryPriceStr;

  // ***** Limit Calculations *****

  // Decide position parameters (different logic for market/limit)
  const { minPrice, maxPrice, slPrice, tpPrice } = usePositionPanel({
    pairAssetPrice,
    openOrderType,
    slippagePInUnits,
    isLong,
    slMultiplierBaseInput: wantedSlMultiplierBaseInput,
    slMultiplier: wantedSlInPercentages,
    openPrice,
    leverage: wantedLeverage,
    tpMultiplierBaseInput: wantedTpMultiplierBaseInput,
    tpMultiplier: wantedTpInPercentages,
    wantedMinOpenPrice,
    wantedMaxOpenPrice,
  });

  // ***** String Inputs Callbacks & Effects *****

  const safeSetWantedPositionSizeUnitsString = useCallback(
    (newVal: number) => {
      const maxNumValue = 999_999_999;
      let valToUse = newVal;
      const numValue = Number(newVal);

      if (isNaN(numValue)) {
        valToUse = 0;
      } else if (numValue > maxNumValue) {
        // valToUse = maxNumValue.toString();
        valToUse = wantedPositionSizeUnitsString;
      }

      setWantedPositionSizeUnitsString(valToUse);
    },
    [wantedPositionSizeUnitsString],
  );

  const onCollateralSizeInputOnOptionClickedHandle = useCallback(
    (value: number) => {
      const valueInUnits = value * traderAccountBalanceInUnits;
      safeSetWantedPositionSizeUnitsString(valueInUnits);
    },
    [safeSetWantedPositionSizeUnitsString, traderAccountBalanceInUnits],
  );

  const onCollateralSizeInUnitsChangeHandle = useCallback(
    (value: number) => {
      safeSetWantedPositionSizeUnitsString(value || 0);
    },
    [safeSetWantedPositionSizeUnitsString],
  );

  useEffect(() => {
    if (wantedSlMultiplierBaseInput) {
      const wantedStopLossFractionInUnits = parseFloat(
        wantedSlMultiplierBaseInput,
      );
      const stopLoss = calculateStopLoss(
        effectiveOpenPrice,
        wantedLeverage,
        wantedStopLossFractionInUnits,
        isLong,
      );
      setWantedSlInPercentages(stopLoss);
    }
  }, [wantedLeverage, wantedSlMultiplierBaseInput, isLong, effectiveOpenPrice]);

  useEffect(() => {
    if (wantedTpMultiplierBaseInput) {
      const wantedTakeProfitFractionInUnits = parseFloat(
        wantedTpMultiplierBaseInput,
      );
      const takeProfit = calculateTakeProfit(
        effectiveOpenPrice,
        wantedLeverage,
        wantedTakeProfitFractionInUnits,
        isLong,
      );
      setWantedTpInPercentages(takeProfit);
    }
  }, [wantedLeverage, wantedTpMultiplierBaseInput, isLong, effectiveOpenPrice]);

  const onLossOptionClickHandle = useCallback(
    (value: number) => {
      setWantedSlMultiplierBaseInput("");
      setWantedSlInPercentages(value || 0);
    },
    [setWantedSlMultiplierBaseInput, setWantedSlInPercentages],
  );

  const onProfitOptionClickHandle = useCallback(
    (value: number) => {
      setWantedTpInPercentages(value || 0);
      setWantedTpMultiplierBaseInput("");
    },
    [setWantedTpInPercentages, setWantedTpMultiplierBaseInput],
  );

  const collateralExtraOptions = useMemo(() => {
    if (wantedPositionSizeUnitsString) {
      return [];
    } else return collateralSizeInputExtraOptions;
  }, [collateralSizeInputExtraOptions, wantedPositionSizeUnitsString]);

  // ***** Deposit Modal Logic *****

  const handleDepositModal = useCallback(() => {
    if (!isConnectedToWallet) {
      requestWalletConnectionFunction();
      setShouldDepositModal(true);
    } else {
      modalsStore.showChipInModal(lexId);
      setShouldDepositModal(false);
    }
  }, [
    isConnectedToWallet,
    lexId,
    modalsStore,
    requestWalletConnectionFunction,
  ]);

  useEffect(() => {
    if (isConnectedToWallet && shouldDepositModal) {
      handleDepositModal();
    }
  }, [handleDepositModal, isConnectedToWallet, shouldDepositModal]);

  // ***** Action allowed mechanism flags *****

  const isCatMode = useIsCatModePair(pairId);

  const collateralLargerThanBalance =
    positionSizeInUnits > traderAccountBalanceInUnits;

  const calculatedMinimumLeveragedPosition =
    openFeeFInUnits == 0 ? 0 : minOpenFeeInUnits / openFeeFInUnits;

  const notReachingMinOpenFee =
    !isCatMode && expectedOpenFee < minOpenFeeInUnits;

  const passingMaxLeveragedPosition =
    leveragedPositionSizeInUnits > maxLeveragedPositionInUnits;

  const positionSizeZero = positionSizeInUnits == 0;

  const isWaitingToStartTrading = false;

  // A mechanism to prevent blocking the user when no info was read
  const isBorrowLimitValid =
    poolBorrowCapacityInUnits > 0 || maxLeveragedPositionInUnits > 0;

  // NOTE : If 'borrowSize' is 0. we will skip this check
  // NOTE : Using 'borrowSizeInUnits' and not 'borrowSizeAfterOpenFeeInUnits' (which will be the actual borrow amount in the tx)
  //        to take some buffer.
  const isTryingToBorrowMoreThanAvailable =
    borrowSizeInUnits > poolBorrowCapacityInUnits && isBorrowLimitValid;
  // && poolBorrowCapacityInUnits > 0;

  // console.log(` !!!! borrowSizeInUnits ${borrowSizeInUnits}`);
  // console.log(` !!!! poolBorrowCapacityInUnits ${poolBorrowCapacityInUnits}`);

  // NOTE : Removing this 'need to approve' logic for now, as chips are auto approved
  // const needsToApprove = userWantsToLeverage && !hasEnoughAllowance;
  const needsToApprove = false;

  // *****  Action button text & action *****

  const { actionButtonText, actionButtonAction, buttonStyle } = useMemo(() => {
    let actionButtonText: string;
    let actionButtonAction: TCallback;
    const buttonStyle: "primary" | "secondary" = "primary";

    if (!isConnectedToWallet) {
      actionButtonText = "Connect Wallet";
      actionButtonAction = () => requestWalletConnectionFunction();
      // buttonStyle = "secondary";
    } else if (isActionPending) {
      actionButtonText = pendingActionText;
      actionButtonAction = () => {};
    } else if (
      traderAccountBalanceInUnits == 0 ||
      traderAccountBalanceInUnits < wantedPositionSizeUnitsString
    ) {
      actionButtonText = "Deposit";

      actionButtonAction = () => {
        handleDepositModal();
      };
    } else if (needsToApprove) {
      actionButtonText = "Approve";
      actionButtonAction = () =>
        approveAction(settlementAssetAddress, ethers.MaxUint256);
    } else if (isWaitingToStartTrading) {
      actionButtonText = "Coming Soon";
      actionButtonAction = () => null;
    }
    // else if (isCompetitionFinished) {
    //   actionButtonText = "Competition Has Ended";
    //   actionButtonAction = () => null;
    // }
    else if (wantedPositionSizeUnitsString == 0) {
      actionButtonText = "Enter Amount";
      actionButtonAction = () => null;
    } else {
      actionButtonText = "Place Order";

      if (positionSizeZero) {
        actionButtonAction = () => toastInfo(`Position size 0`);
      } else {
        const tpPrice = Number(wantedTpMultiplierBaseInput) || 0;
        const slPrice = Number(wantedSlMultiplierBaseInput) || 0;
        const tpByFraction = tpPrice
          ? 0
          : wantedTpInPercentages
            ? wantedTpInPercentages / 100
            : 0;
        const slByFraction = slPrice
          ? 0
          : wantedSlInPercentages
            ? wantedSlInPercentages / 100
            : 0;

        // NOTE : Quick-n-Dirty fix to fix the 100% rounding problems
        // Ensuring that this is indeed a rounding error and not just a huge number that bypassed the
        // 'more than balance' check
        // const safeRoundingRange = traderSABalanceBn + traderSABalanceBn / 1000n;
        // const fixedPositionSizeBn =
        //   positionSizeBn > traderSABalanceBn &&
        //   positionSizeBn < safeRoundingRange
        //     ? traderSABalanceBn
        //     : positionSizeBn;

        const fixedPositionSizeBn =
          bnRoundingUtils.floorPossiblyExtraDustAmount(
            traderSABalanceBn,
            positionSizeBn,
          );

        actionButtonAction = () =>
          void requestOpenPosition(
            openOrderType,
            safeFirstAvailablePositionIndexForAccount,
            isLong,
            fixedPositionSizeBn,
            wantedLeverage,
            minPrice,
            maxPrice,
            tpPrice,
            slPrice,
            tpByFraction,
            slByFraction,
          );
      }
    }

    return {
      actionButtonText,
      actionButtonAction,
      buttonStyle,
    };
  }, [
    isConnectedToWallet,
    isActionPending,
    traderAccountBalanceInUnits,
    wantedPositionSizeUnitsString,
    needsToApprove,
    isWaitingToStartTrading,
    requestWalletConnectionFunction,
    pendingActionText,
    handleDepositModal,
    approveAction,
    settlementAssetAddress,
    positionSizeZero,
    wantedTpMultiplierBaseInput,
    wantedSlMultiplierBaseInput,
    wantedTpInPercentages,
    wantedSlInPercentages,
    traderSABalanceBn,
    positionSizeBn,
    requestOpenPosition,
    openOrderType,
    safeFirstAvailablePositionIndexForAccount,
    isLong,
    wantedLeverage,
    minPrice,
    maxPrice,
  ]);

  const { actionButtonAllowed, deactivationReason } = useMemo(() => {
    let actionButtonAllowed = true;
    let deactivationReason = "";

    if (!isConnectedToWallet) {
      actionButtonAllowed = true;
    } else if (isActionPending) {
      actionButtonAllowed = false;
    } else if (positionSizeZero) {
      actionButtonAllowed = false;
      deactivationReason = "";
    } else if (needsToApprove) {
      actionButtonAllowed = true;
      deactivationReason = "";
    } else if (collateralLargerThanBalance) {
      actionButtonAllowed = true;
      deactivationReason = `Exceeds Balance`;
    } else if (notReachingMinOpenFee) {
      actionButtonAllowed = false;
      deactivationReason = `Min Position Size ${localStringer(
        calculatedMinimumLeveragedPosition,
      )} ${settlementAssetSymbol}`;
    } else if (passingMaxLeveragedPosition) {
      actionButtonAllowed = false;
      deactivationReason = `Leveraged Position Too Big ${maxLeveragedPositionInUnits}`;
    } else if (isTryingToBorrowMoreThanAvailable) {
      actionButtonAllowed = false;
      deactivationReason = `Borrowing more than pool capacity (${localStringer(borrowSizeInUnits)} / ${localStringer(poolBorrowCapacityInUnits)} ${settlementAssetSymbol})`;
    } else if (isWaitingToStartTrading) {
      actionButtonAllowed = false;
    }
    // else if (isCompetitionFinished) {
    //   actionButtonAllowed = false;
    // }
    if (wantedLeverage < leverageMin) {
      actionButtonAllowed = false;
      deactivationReason = `Leverage Too Low (Min ${leverageMin}X)`;
    }

    const wantedInputTpMultiplier = Number(wantedTpMultiplierBaseInput);
    const wantedInputSlMultiplier = Number(wantedSlMultiplierBaseInput);
    const hasWantedTpInputValue = !!wantedTpMultiplierBaseInput;
    const hasWantedSlInputValue = !!wantedSlMultiplierBaseInput;
    const hasWantedTpSelectedValue = !!wantedTpInPercentages;
    const hasWantedSlSelectedValue = !!wantedSlInPercentages;

    if (isLong) {
      if (
        hasWantedSlInputValue &&
        wantedInputSlMultiplier > effectiveOpenPrice
      ) {
        actionButtonAllowed = false;
        deactivationReason = `Stop Loss is greater than entry price`;
      }
      if (
        hasWantedTpInputValue &&
        wantedInputTpMultiplier < effectiveOpenPrice
      ) {
        actionButtonAllowed = false;
        deactivationReason = `Take Profit is less than entry price`;
      }
    } else {
      if (
        hasWantedSlInputValue &&
        wantedInputSlMultiplier < effectiveOpenPrice
      ) {
        actionButtonAllowed = false;
        deactivationReason = `Stop Loss is less than entry price`;
      }
      if (
        hasWantedTpInputValue &&
        wantedInputTpMultiplier > effectiveOpenPrice
      ) {
        actionButtonAllowed = false;
        deactivationReason = `Take Profit is greater than entry price`;
      }
    }

    if (!hasWantedTpSelectedValue && !hasWantedTpInputValue) {
      actionButtonAllowed = false;
      deactivationReason = "Take profit required";
    }
    if (!hasWantedSlInputValue && !hasWantedSlSelectedValue) {
      actionButtonAllowed = false;
      deactivationReason = "Stop loss reuired";
    }

    const ls = wantedSlInPercentages;
    if (ls > 80 || ls < -80) {
      actionButtonAllowed = false;
      deactivationReason = `Stop loss out of range`;
    }

    const tp = wantedTpInPercentages;
    if (tp > 900 || tp < -900) {
      actionButtonAllowed = false;
      deactivationReason = `Take profit out of range`;
    }

    return {
      actionButtonAllowed,
      deactivationReason,
    };
  }, [
    isConnectedToWallet,
    isActionPending,
    positionSizeZero,
    needsToApprove,
    collateralLargerThanBalance,
    notReachingMinOpenFee,
    passingMaxLeveragedPosition,
    isTryingToBorrowMoreThanAvailable,
    isWaitingToStartTrading,
    wantedLeverage,
    leverageMin,
    wantedTpMultiplierBaseInput,
    wantedSlMultiplierBaseInput,
    wantedTpInPercentages,
    wantedSlInPercentages,
    isLong,
    calculatedMinimumLeveragedPosition,
    settlementAssetSymbol,
    maxLeveragedPositionInUnits,
    borrowSizeInUnits,
    poolBorrowCapacityInUnits,
    effectiveOpenPrice,
  ]);

  // ***** Summary Items *****

  // TODO : CRITICAL : Make sure this calculation is right
  const positionSizeAfterFeeInUnits = positionSizeInUnits - expectedOpenFee;
  const positionCollateral = positionSizeAfterFeeInUnits;
  const rolloverFee = 0;
  const fundingFee = 0;
  const liquidationPrice = useCalculatePositionLiquidationPrice(
    openPrice,
    isLong,
    positionCollateral,
    wantedLeverage,
    rolloverFee,
    fundingFee,
  );

  const summaryItems = useSummaryItemsForPositionOpeningPanel(
    sourceChainId,
    settlementAssetSymbol,
    pairAssetName,
    leveragedPositionSizeInUnits,
    expectedOpenFee,
    openFeeFInUnits,
    liquidationPrice,
  );

  // ***** Collateral Asset Component *****

  const collateralAssetComponent =
    useConnectedCurrencySelectionComponent(onPairSelected);

  // ***** Derived Texts *****

  // TODO : We can probably remove all the 'getProfitSubTitle' and 'getProfitSubValue' functions
  const profitSubTitle = useMemo(() => {
    return getProfitSubTitle(wantedTpInPercentages);
  }, [wantedTpInPercentages]);

  const lossSubTitle = useMemo(() => {
    return getLossSubTitle(wantedSlInPercentages);
  }, [wantedSlInPercentages]);

  const calcProfitSubValue = getProfitSubValue(profitSubTitle);

  const tpDescriptor = getTPDecriptor(tpPrice, pairPriceDisplayDecimals);
  const slDescriptor = getSLDecriptor(slPrice, pairPriceDisplayDecimals);

  return (
    <div className={classes.panelDiv}>
      <PositionOpenPanel
        collateralAssetComponent={collateralAssetComponent}
        collateralAssetSymbol={settlementAssetSymbol}
        collateralAssetAvailable={traderAccountBalanceInUnits}
        positionDirectionValue={tradeActionType}
        onPositionDirectionChange={setTradeActionType}
        collateralSizeInputExtraOptions={collateralExtraOptions}
        onCollateralSizeInputOnOptionClicked={
          onCollateralSizeInputOnOptionClickedHandle
        }
        positionTypeSelectorsOptions={positionTypeSelectorOptions}
        positionTypeValue={openOrderType}
        onPositionTypeChange={setOpenOrderType}
        collateralSizeInUnits={wantedPositionSizeUnitsString}
        onCollateralSizeInUnitsChange={onCollateralSizeInUnitsChangeHandle}
        minLeverage={leverageMin}
        maxLeverage={leverageMax}
        leverageOptions={leverageOptions}
        onLeverageInUnitsChange={setWantedLeverage}
        actionButtonText={actionButtonText}
        lossTitle="Stop Loss"
        lossSubTitle={wantedLeverage ? `${lossSubTitle}%` : ""}
        lossUnit={wantedLeverage ? slDescriptor : ""}
        lossOptions={slMarks}
        lossValue={wantedSlMultiplierBaseInput}
        lossValueSelected={wantedSlInPercentages}
        lossMaxLimit={lossMaxLimit}
        onLossChange={setWantedSlMultiplierBaseInput}
        onLossOptionClick={onLossOptionClickHandle}
        profitTitle="Take profit"
        profitSubTitle={wantedLeverage ? calcProfitSubValue : ""}
        profitUnit={wantedLeverage ? tpDescriptor : ""}
        profitOptions={tpMarks}
        profitValue={wantedTpMultiplierBaseInput}
        profitValueSelected={wantedTpInPercentages}
        profitMaxLimit={profitMaxLimit}
        onProfitChange={setWantedTpMultiplierBaseInput}
        onProfitOptionClick={onProfitOptionClickHandle}
        entryPriceSectionTitle={`Entry price`}
        entryPriceSectionTitleTooltip={`The estimated price at which your trade will execute, including the spread (if applicable).`}
        entryPriceSectionNote={`(Avg.Spread ${numericalDisplay(spreadInPercentages / 2, 6)}%)`}
        entryPriceSectionNoteTooltip={`You will pay a total of ${spreadInPercentages}% spread when opening the position, but no spread when closing the position`}
        entryPriceSectionSubTitle="Slippage (%)"
        entryPriceSectionPrice={`$${entryPriceStr}`}
        entryPriceSectionValue={slippagePInUnits}
        entryPriceSectionMaxSlippage={99}
        entryPriceSectionValueChange={setSlippagePIUnits}
        entryPriceSectionPriceChange={setWantedEntryPriceForLimitInput}
        summaryItems={summaryItems}
        actionTypeButton={buttonStyle}
        actionButtonOnClick={actionButtonAction}
        actionButtonDisabled={!actionButtonAllowed}
        errorMessage={deactivationReason}
        onDepositButtonClick={handleDepositModal}
      />
    </div>
  );
});
