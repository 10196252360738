import React, { useCallback, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";

import { IStores } from "./stores.ts";
import { CryptoWalletConnectionStore } from "./CryptoWalletConnectionStore";
import { PricesStore } from "./singleInstanceStores/PricesStore.ts";
import { TPairIds } from "../constants/pairsConstants";
import { ModalsStore } from "./uxStores/ModalsStore.ts";
import useInterval from "react-useinterval";
import { ContractServicesStore } from "./ContractServicesStore.ts";
import { LeverageDimensionsStore } from "./singleInstanceStores/LeverageDimensionsStore.ts";
import { SingleLexStore } from "./multiInstancesStores/LexStore/SingleLexStore.ts";
import { SystemStore } from "./SystemStore.ts";
import { isSameAddress } from "../utils/addresses.ts";
import { SingleTradingFloorStore } from "./multiInstancesStores/SingleTradingFloorStore/SingleTradingFloorStore.ts";
import { RpcsStore } from "./RpcsStore.ts";

export function useStores(): IStores {
  return React.useContext(MobXProviderContext) as IStores;
}

export function useCryptoWalletIntegrationStore(): CryptoWalletConnectionStore {
  return useStores().cryptoWalletIntegrationStore;
}

export function useRpcsStore(): RpcsStore {
  return useStores().rpcsStore;
}

export function useSystemStore(): SystemStore {
  return useStores().systemStore;
}

export function useLeverageDimensionsStore(): LeverageDimensionsStore {
  return useStores().leverageDimensionsStore;
}

export function useContractsServicesStore(): ContractServicesStore {
  return useStores().contractServicesStore;
}

export function useModalsStore(): ModalsStore {
  return useStores().modalsStore;
}

export function usePricesStore(): PricesStore {
  return useStores().pricesStore;
}

// export function useTradingFloorStore(): SingleTradingFloorStore {
//   return useStores().leverageDimensionsStore.tradingFloorStore;
// }

export function useActiveLexStoreStore(): SingleLexStore {
  return useStores().leverageDimensionsStore.activeLexStore;
}

export function useLexStoreById(id: string): SingleLexStore | undefined {
  return useStores().leverageDimensionsStore.lexStores.find(
    (store) => store.id === id,
  );
}

export function useLexStoreByAddress(poolAddress: string): SingleLexStore {
  const leverageDimensionsStore = useStores().leverageDimensionsStore;

  return leverageDimensionsStore.getLexStoreByPoolAddress(poolAddress);
}

// export async function useAutoConnectIfExists() {
//   const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();
//
//   useEffect(() => {
//     if (
//       !cryptoWalletConnectionStore.isConnectedToWallet &&
//       cryptoWalletConnectionStore.hasEthereumProvider
//     ) {
//       cryptoWalletConnectionStore.askToConnect();
//     }
//   }, [cryptoWalletConnectionStore]);
// }

// **** Prices hooks ****

export function usePairAssetPrice(pairId: TPairIds): number {
  const pricesStore = usePricesStore();

  const pairAssetPrice = pricesStore.pricesMap.get(pairId) ?? 0;

  return pairAssetPrice;
}

// export function useFreshenDimensionStorePeriodically(intervalInSeconds = 5) {
//   const tradingFloorStore = useTradingFloorStore();
//
//   const cb = useCallback(() => {
//     tradingFloorStore
//       .refreshFromOutside()
//       .catch((e) => console.error(`Failed 'lexStore.refreshFromOutside' ${e}`));
//   }, [tradingFloorStore]);
//
//   useInterval(() => {}, intervalInSeconds * 1000);
// }

export function useUpdatePricesPeriodically(intervalInSeconds = 5) {
  const pricesStore = usePricesStore();
  useInterval(() => {
    pricesStore
      .refreshPrices()
      .catch((e) => console.error(`Failed 'refreshPrices ${e}`));
  }, intervalInSeconds * 1000);
}
