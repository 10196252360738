import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import {
  PositionInfoModal,
  buildInfoRowPayload,
  formatDateFromTimestamp,
  formatTimeFromTimestamp,
  formatLengthFromSeconds,
} from "lynx-ui-components";
import type { TInfoPanelCompleteProps } from "lynx-ui-components";
import {
  useActiveLexStoreStore,
  useCryptoWalletIntegrationStore,
  useModalsStore,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import { EMPTY_COMPLETE_POSITION_DATA_FROM_LENS } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import { EMPTY_CLOSED_POSITION_GIST } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { PAIR_ID_TO_NAME } from "../../../constants/pairsDisplayConstants.ts";
import { TPairIds } from "../../../constants/pairsConstants.ts";
import { THEME_STATE_CONSTANTS } from "../../../theme/ThemeConstants.ts";
import { unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { localPriceStringer, localStringer } from "../../../ux/uxFunctions.ts";
import { TInfoPanelRowPayload } from "lynx-ui-components/dist/components/panels/InfoPanel/InfoPanelRow";
import { priceBnToFloat } from "../../../utils/lynxScalesUtils.ts";
import { scaledLeverageToUnits } from "../../../utils/leverageCalculationsUtils.ts";
import { limitsCalculations } from "../../../utils/limitsCalculations.ts";
import { useChainExplorerUrlsForChainId } from "../../../ux/chainsHooks.ts";
import { CHAIN_ID_FANTOM_OPERA } from "../../../constants/chainConstants.ts";
import {
  calculatePositionProfitFractionLikeContract,
  calculatePositionTradeValueInSAUnitsLikeContract,
} from "../../../hooks/tradeCalculations.ts";
import { themeCalculations } from "../../../ux/themeCalculations.ts";

// TODO : Remove these after fixing the TS problems with the imports
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

interface IProps {
  open: boolean;
  closeModal: () => void;
}

const implementMessage = "----IMPLEMENT----";

export const PositionInfoModalWrapper = observer<React.FC<IProps>>((props) => {
  const { open, closeModal } = props;

  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const modalsStore = useModalsStore();
  const pricesStore = usePricesStore();
  const lexStoreStore = useActiveLexStoreStore();

  // TODO : CRITICAL : Get this info
  const engineChainId = CHAIN_ID_FANTOM_OPERA;

  const explorerLinks = useChainExplorerUrlsForChainId(engineChainId);

  const poolTokenDecimals = lexStoreStore?.poolTokenDecimals ?? 18;

  const liveOrHistoricPositionId =
    modalsStore.positionInfoModal_liveOrHistoricPositionId;
  const isLivePosition = modalsStore.positionInfoModal_isLivePosition;

  const rawAmountToUnits = useCallback(
    (rawAmount: bigint) => {
      return unitsBnToFloat(rawAmount, poolTokenDecimals);
    },
    [poolTokenDecimals],
  );

  const assetSymbol = lexStoreStore.sourceAssetParameters.symbol;

  const stringifyAssetAmount = useCallback(
    (amountInUnits: number, displayDecimals = 3) => {
      return `${localStringer(amountInUnits, displayDecimals)} ${assetSymbol}`;
    },
    [assetSymbol],
  );

  const { livePosition, closedPosition } = useMemo(() => {
    const livePosition =
      lexStoreStore?.getLivePositionById(liveOrHistoricPositionId) ??
      EMPTY_COMPLETE_POSITION_DATA_FROM_LENS;

    const closedPosition =
      lexStoreStore?.getClosedPositionByGraphId(liveOrHistoricPositionId) ??
      EMPTY_CLOSED_POSITION_GIST;

    return {
      livePosition,
      closedPosition,
    };
  }, [lexStoreStore, liveOrHistoricPositionId]);

  const { positionId, pairId, isLong, leverageInUnits } = useMemo(() => {
    let positionId: string;
    let pairId: TPairIds;
    let isLong: boolean;

    let leverageInUnits: number;

    if (isLivePosition) {
      positionId = liveOrHistoricPositionId as string;

      pairId = Number(livePosition.pairId) as TPairIds;

      isLong = livePosition.long;

      leverageInUnits = scaledLeverageToUnits(Number(livePosition.leverage));
    } else {
      positionId = closedPosition.positionId;
      pairId = closedPosition.pairId as TPairIds;

      isLong = closedPosition.long;

      leverageInUnits = closedPosition.leverageInUnits;
    }

    return {
      positionId,
      pairId,
      isLong,
      leverageInUnits,
    };
  }, [
    closedPosition.leverageInUnits,
    closedPosition.long,
    closedPosition.pairId,
    closedPosition.positionId,
    isLivePosition,
    liveOrHistoricPositionId,
    livePosition.leverage,
    livePosition.long,
    livePosition.pairId,
  ]);

  // TODO : Find a better mechanism
  const pairInfo = PAIR_ID_TO_NAME[pairId] ?? "/";
  const symbolA = pairInfo.split("/")[0];
  const symbolB = pairInfo.split("/")[1];

  const pairPrice = pricesStore.getPriceForPairId(pairId);

  // const openFeeInFUnits = scaledFractionToUnits(
  //   Number(lexStoreStore?.getTradePairStoreById(pairId)?.openFee ?? 0n),
  // );
  const openFeeInFUnits =
    lexStoreStore?.getTradePairStoreById(pairId)?.feeObjectForPair
      .openFeeFInUnits ?? 0;
  const closeFeeInFUnits =
    lexStoreStore?.getTradePairStoreById(pairId)?.feeObjectForPair
      .closeFeeFInUnits ?? 0;

  // Note : Prepares common data for the info panels
  const {
    initialAmountInUnits,
    openingFeeInUnits,
    collateralInUnits,
    inPhaseSince,
    entryPrice,
    closePrice,
    tpPriceInUnits,
    slPriceInUnits,
  } = useMemo(() => {
    let initialAmountInUnits: number;
    let openingFeeInUnits: number;
    let collateralInUnits: number;

    let inPhaseSince: number;

    let entryPrice: number;
    let closePrice: number;

    let tpPriceInUnits: number;
    let slPriceInUnits: number;

    if (isLivePosition) {
      collateralInUnits = rawAmountToUnits(livePosition.collateral);

      const estimatedOriginalCollateral =
        collateralInUnits / (1 - openFeeInFUnits * leverageInUnits);

      initialAmountInUnits = estimatedOriginalCollateral;

      // TODO : Estimate this
      openingFeeInUnits = initialAmountInUnits - collateralInUnits;

      inPhaseSince = Number(livePosition.inPhaseSince);

      entryPrice = priceBnToFloat(livePosition.openPrice);
      closePrice = 0;

      tpPriceInUnits = priceBnToFloat(livePosition.tp);
      slPriceInUnits = priceBnToFloat(livePosition.sl);
    } else {
      initialAmountInUnits = rawAmountToUnits(closedPosition.initialCollateral);

      openingFeeInUnits = rawAmountToUnits(
        closedPosition.openFeeForSystem + closedPosition.openFeeForLexPool,
      );

      collateralInUnits = initialAmountInUnits - openingFeeInUnits;

      // No 'inPhase' for closed position
      inPhaseSince = 0;

      entryPrice = priceBnToFloat(closedPosition.openPrice);
      closePrice = priceBnToFloat(closedPosition.closePrice);

      tpPriceInUnits = priceBnToFloat(closedPosition.tp);
      slPriceInUnits = priceBnToFloat(closedPosition.sl);
    }

    return {
      initialAmountInUnits,
      openingFeeInUnits,
      collateralInUnits,
      inPhaseSince,
      entryPrice,
      closePrice,
      tpPriceInUnits,
      slPriceInUnits,
    };
  }, [
    closedPosition.closePrice,
    closedPosition.initialCollateral,
    closedPosition.openFeeForLexPool,
    closedPosition.openFeeForSystem,
    closedPosition.openPrice,
    closedPosition.sl,
    closedPosition.tp,
    isLivePosition,
    leverageInUnits,
    livePosition.collateral,
    livePosition.inPhaseSince,
    livePosition.openPrice,
    livePosition.sl,
    livePosition.tp,
    openFeeInFUnits,
    rawAmountToUnits,
  ]);

  // const pnlInfo = calculatePnlInfo(collateralInUnits, le);
  // TODO : Calculate liquidation price for closed position ?
  const liquidationPriceInUnits = isLivePosition
    ? priceBnToFloat(livePosition.liquidationPrice)
    : 0;

  // TODO : CRITICAL : Make this work with regard to leverage direction
  const relevantFinalPrice = isLivePosition ? pairPrice : closePrice;
  const relevantAndEntryPriceDiffInUnits = relevantFinalPrice - entryPrice;
  const relevantAndEntryPriceDiffInPercentage =
    ((relevantFinalPrice - entryPrice) / entryPrice) * 100;
  const relevantAndEntryPriceLeverageDiffInPercentage =
    relevantAndEntryPriceDiffInPercentage * leverageInUnits;
  const isPriceDiffInFavorOfTrader = isLong
    ? relevantAndEntryPriceLeverageDiffInPercentage >= 0
    : relevantAndEntryPriceLeverageDiffInPercentage <= 0;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const positionBreakDownPanelPayload = useMemo<TInfoPanelCompleteProps>(() => {
    let borrowInterestPaid = 0;
    let fundingPaid = 0;

    const positionSizeInUnits = leverageInUnits * collateralInUnits;

    let closingFee: number;

    if (isLivePosition) {
      borrowInterestPaid = rawAmountToUnits(livePosition.borrowInterest);
      fundingPaid = rawAmountToUnits(livePosition.funding);
      closingFee = positionSizeInUnits * closeFeeInFUnits;
    } else {
      borrowInterestPaid = rawAmountToUnits(
        closedPosition.borrowInterestPaidOnClose,
      );
      fundingPaid = rawAmountToUnits(closedPosition.fundingFeesPaidOnClose);
      closingFee = rawAmountToUnits(closedPosition.closeFee);
    }

    const positionTradingProfitFraction =
      calculatePositionProfitFractionLikeContract(
        entryPrice,
        relevantFinalPrice,
        isLong,
        leverageInUnits,
      );
    const onlyTradingPnl = collateralInUnits * positionTradingProfitFraction;

    // TODO : For Live position, add regard to 'live trade pnl'
    const netCollateralInUnits = isLivePosition
      ? calculatePositionTradeValueInSAUnitsLikeContract(
          positionTradingProfitFraction,
          collateralInUnits,
          borrowInterestPaid,
          fundingPaid,
          closingFee,
        )
      : // ? collateralInUnits - borrowInterestPaid - fundingPaid - closingFee
        rawAmountToUnits(closedPosition.positionValueOnClose);

    const totalPnlInUnit = netCollateralInUnits - collateralInUnits;
    const totalPnlAsProfitPercentage =
      (totalPnlInUnit / collateralInUnits) * 100;

    // NOTE : Negating the 'funding paid' to show earned funding as positive
    const fundingEarned = fundingPaid * -1;

    const positionBreakDownPanelPayload: TInfoPanelCompleteProps = {
      headerPayload: {
        panelTitle: `Position Breakdown`,
        panelSubTitle: "",
      },
      sections: [
        // Amounts
        {
          sectionKey: "amounts",
          rows: [
            {
              title: "Initial Amount",
              text: stringifyAssetAmount(initialAmountInUnits),
            },
            {
              title: "Opening Fee",
              text: stringifyAssetAmount(openingFeeInUnits),
            },
            {
              title: "Collateral",
              text: stringifyAssetAmount(collateralInUnits),
            },
            {
              title: "Leverage",
              text: `${localStringer(leverageInUnits)}X`,
            },
            {
              title: "Position Size",
              text: stringifyAssetAmount(positionSizeInUnits),
            },
          ],
        },
        // Fees
        {
          sectionKey: "fees",
          rows: [
            {
              title: "Gross P&L",
              text: stringifyAssetAmount(onlyTradingPnl),
              textColor: themeCalculations.getTextColorByValue(onlyTradingPnl),
              titleTooltip:
                "The profit or loss of your position not accounting for fees",
            },
            {
              title: "Borrow Fees",
              text: stringifyAssetAmount(borrowInterestPaid, 5),
              // textColor: themeCalculations.getTextColorByValue(
              //   borrowInterestPaid * -1,
              // ),
              subRows: [
                // {
                //   title: "Current Borrow rate 8H",
                //   text: "0.000002 USDC",
                // },
                // {
                //   title: "Average Borrow rate 8H",
                //   text: "0.000001 USDC",
                // },
              ],
            },

            {
              title: "Funding",
              text: stringifyAssetAmount(fundingEarned, 5),
              textColor: themeCalculations.getTextColorByValue(fundingEarned),
              subRows: [
                // {
                //   title: "Current Funding rate 8H",
                //   text: "+0.000001 USDC",
                // },
                // {
                //   title: "Average Funding rate 8H",
                //   text: "+0.000002 USDC",
                // },
              ],
            },
            {
              title: "Closing Fee",
              text: stringifyAssetAmount(closingFee),
            },
            {
              title: "Net P&L",
              text: stringifyAssetAmount(totalPnlInUnit),
              textColor:
                totalPnlInUnit >= 0
                  ? THEME_STATE_CONSTANTS.textColorLong
                  : THEME_STATE_CONSTANTS.negativePnL,
              comment: `${localStringer(totalPnlAsProfitPercentage)} %`,
              textFlexSize: 9,
            },
            {
              title: "Net Collateral",
              text: stringifyAssetAmount(netCollateralInUnits),
            },
          ],
        },
      ],
    };

    return positionBreakDownPanelPayload;
  }, [
    closeFeeInFUnits,
    closedPosition.borrowInterestPaidOnClose,
    closedPosition.closeFee,
    closedPosition.fundingFeesPaidOnClose,
    closedPosition.positionValueOnClose,
    collateralInUnits,
    entryPrice,
    initialAmountInUnits,
    isLivePosition,
    isLong,
    leverageInUnits,
    livePosition.borrowInterest,
    livePosition.funding,
    openingFeeInUnits,
    rawAmountToUnits,
    relevantFinalPrice,
    stringifyAssetAmount,
  ]);

  const potentialTpTradeProfitInFractionUnits =
    limitsCalculations.calculateTpInUnits(
      entryPrice,
      tpPriceInUnits,
      leverageInUnits,
    );

  const potentialSlTradeLossInFractionUnits =
    limitsCalculations.calculateTpInUnits(
      entryPrice,
      slPriceInUnits,
      leverageInUnits,
    );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const generalDetailsSectionPanelPayload =
    useMemo<TInfoPanelCompleteProps>(() => {
      const generalDetailsSectionPanelPayload: TInfoPanelCompleteProps = {
        headerPayload: {
          panelTitle: `General Details`,
          panelSubTitle: "",
        },
        sections: [
          // Entry
          {
            sectionKey: "entry",
            rows: [
              buildInfoRowPayload(
                "Entry Price",
                `${localPriceStringer(entryPrice)}`,
              ),
              buildInfoRowPayload(
                isLivePosition ? "Current Price" : "Close Price",
                `${localPriceStringer(relevantFinalPrice)}`,
              ),
              buildInfoRowPayload(
                "Difference",
                `${localPriceStringer(relevantAndEntryPriceDiffInUnits)}`,
                {
                  comment: `(${localStringer(
                    relevantAndEntryPriceDiffInPercentage,
                  )}%)`,
                },
              ),
              buildInfoRowPayload(
                "Leveraged Difference",
                `${localStringer(
                  (isPriceDiffInFavorOfTrader ? 1 : -1) *
                    Math.abs(relevantAndEntryPriceLeverageDiffInPercentage),
                )}%`,
                {
                  textColor: isPriceDiffInFavorOfTrader
                    ? THEME_STATE_CONSTANTS.positivePnL
                    : THEME_STATE_CONSTANTS.negativePnL,
                },
              ),
            ],
          },
          // TP
          {
            sectionKey: "TP",
            rows: [
              buildInfoRowPayload(
                "Take Profit Price",
                `${localPriceStringer(tpPriceInUnits)}`,
              ),
              buildInfoRowPayload(
                "Max Profit",
                `${stringifyAssetAmount(
                  potentialTpTradeProfitInFractionUnits * collateralInUnits,
                )} (${localStringer(
                  potentialTpTradeProfitInFractionUnits * 100,
                )}%)`,
                { textFlexSize: 3 },
              ),
            ],
          },
          // SL
          {
            sectionKey: "SL",
            rows: [
              buildInfoRowPayload(
                "Stop Loss Price",
                `${localPriceStringer(slPriceInUnits)}`,
              ),
              buildInfoRowPayload(
                "Max Loss",
                `${stringifyAssetAmount(
                  potentialSlTradeLossInFractionUnits * collateralInUnits,
                )} (${localStringer(
                  potentialSlTradeLossInFractionUnits * 100,
                )}%)`,
                { textFlexSize: 3 },
              ),
            ],
          },
          // Liq
          {
            sectionKey: "LIQ",
            rows: [
              buildInfoRowPayload(
                "Liquidation Price",
                `${localPriceStringer(liquidationPriceInUnits)}`,
              ),
            ],
          },
        ],
      };

      return generalDetailsSectionPanelPayload;
    }, [
      collateralInUnits,
      entryPrice,
      isLivePosition,
      isPriceDiffInFavorOfTrader,
      liquidationPriceInUnits,
      potentialSlTradeLossInFractionUnits,
      potentialTpTradeProfitInFractionUnits,
      relevantAndEntryPriceDiffInPercentage,
      relevantAndEntryPriceDiffInUnits,
      relevantAndEntryPriceLeverageDiffInPercentage,
      relevantFinalPrice,
      slPriceInUnits,
      stringifyAssetAmount,
      tpPriceInUnits,
    ]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const moreDetailsSectionPanelPayload =
    useMemo<TInfoPanelCompleteProps>(() => {
      let rows: TInfoPanelRowPayload[];

      if (isLivePosition) {
        rows = [
          buildInfoRowPayload(
            "Opened/Updated at",
            formatTimeFromTimestamp(inPhaseSince),
            {
              comment: formatDateFromTimestamp(inPhaseSince),
            },
          ),
          buildInfoRowPayload(
            `Lifetime`,
            formatLengthFromSeconds(Date.now() / 1000 - inPhaseSince),
          ),
          buildInfoRowPayload(`Status`, "Opened", {
            textColor: THEME_STATE_CONSTANTS.textColorLong,
          }),
        ] as TInfoPanelRowPayload[];
      } else {
        rows = [
          buildInfoRowPayload("Open Order Type", closedPosition.openedAs, {
            link: `${explorerLinks.txUrlBase}/${closedPosition.openTx}`,
          }),
          buildInfoRowPayload(
            "Open Time",
            formatTimeFromTimestamp(closedPosition.openTimestamp),
            {
              comment: formatDateFromTimestamp(closedPosition.openTimestamp),
            },
          ),
          buildInfoRowPayload("Close Order Type", closedPosition.closedAs, {
            link: `${explorerLinks.txUrlBase}/${closedPosition.closeTx}`,
          }),
          buildInfoRowPayload(
            "Close Time",
            formatTimeFromTimestamp(closedPosition.closeTimestamp),
            {
              comment: formatDateFromTimestamp(closedPosition.closeTimestamp),
            },
          ),
          buildInfoRowPayload(
            "Duration",
            formatLengthFromSeconds(
              closedPosition.closeTimestamp - closedPosition.openTimestamp,
            ),
          ),
          buildInfoRowPayload("Status", "Closed", {
            textColor: THEME_STATE_CONSTANTS.textColorLong,
          }),
        ] as TInfoPanelRowPayload[];
      }

      const moreDetailsSectionPanelPayload: TInfoPanelCompleteProps = {
        headerPayload: {
          panelTitle: "More Details",
          panelSubTitle: "",
        },
        sections: [
          {
            sectionKey: "info",
            rows: rows,
          },
        ],
      };

      return moreDetailsSectionPanelPayload;
    }, [
      closedPosition.closeTimestamp,
      closedPosition.closeTx,
      closedPosition.closedAs,
      closedPosition.openTimestamp,
      closedPosition.openTx,
      closedPosition.openedAs,
      explorerLinks.txUrlBase,
      inPhaseSince,
      isLivePosition,
    ]);

  return (
    <PositionInfoModal
      positionId={positionId}
      positionDirection={isLong ? "long" : "short"}
      pairSymbolA={symbolA}
      pairSymbolB={symbolB}
      infoPanelPayloads={[
        positionBreakDownPanelPayload,
        generalDetailsSectionPanelPayload,
        moreDetailsSectionPanelPayload,
      ]}
      open={open}
      closeModal={closeModal}
    />
  );
});

export default PositionInfoModalWrapper;
