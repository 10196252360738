import {
  BytesLike,
  Provider,
  Signer,
  ContractTransactionResponse,
} from "ethers";
import { ILexPoolService } from "./ILexPoolService";
import { LexPoolV1 } from "../../../typechain/contracts/Lynx/Lex/LexPool/LexPoolV1.ts";
import { LexPoolV1__factory } from "../../../typechain/factories/contracts/Lynx/Lex/LexPool/LexPoolV1__factory.ts";

export class LexPoolService implements ILexPoolService {
  private lexPoolContract: LexPoolV1;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.lexPoolContract = LexPoolV1__factory.connect(
      address,
      signerOrProvider,
    );
  }

  async immediateDeposit(
    depositAmount: bigint,
    referralDomain: BytesLike,
    referralCode: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.lexPoolContract.immediateDeposit(
      depositAmount,
      referralDomain,
      referralCode,
    );
  }

  async epochDeposit(
    depositAmount: bigint,
    minAmountOut: bigint,
    referralDomain: BytesLike,
    referralCode: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.lexPoolContract.requestDeposit(
      depositAmount,
      minAmountOut,
      referralDomain,
      referralCode,
    );
  }

  async epochRedeemByLxToken(
    redeemAmount: bigint,
    minAmountOUt: bigint,
  ): Promise<ContractTransactionResponse> {
    // TODO : 'Think about this interface '
    return this.lexPoolContract.requestRedeem(redeemAmount, minAmountOUt);
  }

  epochRedeemByUnderlying(
    underlyingAmount: bigint,
  ): Promise<ContractTransactionResponse> {
    throw new Error("Implement");
    // return this.lexPoolContract.redeemUnderlying(underlyingAmount);
  }

  async processDeposit(account: string): Promise<ContractTransactionResponse> {
    return this.lexPoolContract.processDeposit([account]);
  }

  async processRedeem(account: string): Promise<ContractTransactionResponse> {
    return this.lexPoolContract.processRedeems([account]);
  }
}
