import { TSafeBigNumberStruct } from "../../../utils/ethersTypes";

import type {
  TradingFloorLensStructs,
  TradingFloorLensTradeParamsStruct,
} from "../../../typechain/contracts/Peripheral/Lens/TradingFloorLens.ts";

import { BytesLike } from "ethers";
import {
  TUpdatePositionFieldOrderType,
  UpdatePositionFieldOrderTypeEnums,
} from "../../../constants/contractEnums.ts";
import { stringifyObject } from "../../../utils/strings.ts";

export type TCompletePositionDataFromLens = Omit<
  TSafeBigNumberStruct<TradingFloorLensStructs.CompletePositionDataFromLensStruct>,
  "pendingUpdateOrderType"
> & {
  pendingUpdateOrderType: TUpdatePositionFieldOrderType;
};

export type TTradingFloorLensTradeParamsStruct =
  TSafeBigNumberStruct<TradingFloorLensTradeParamsStruct>;

export interface ITradingFloorLensService {
  getTradingFloorTradeParams(
    tradingFloorAddress: string,
  ): Promise<TTradingFloorLensTradeParamsStruct>;

  getCompletePositionData(
    tradingFloorAddress: string,
    positionId: BytesLike,
  ): Promise<TCompletePositionDataFromLens>;

  getCompletePositionsDataInPairForTrader(
    tradingFloorAddress: string,
    settlementAsset: string,
    trader: string,
    pairId: number,
  ): Promise<TCompletePositionDataFromLens[]>;

  getTradesInPairsForTrader(
    tradingFloorAddress: string,
    settlementAsset: string,
    trader: string,
    pairIds: number[],
  ): Promise<TCompletePositionDataFromLens[]>;

  getAllTradesForTraderInDimension(
    tradingFloorAddress: string,
    settlementAsset: string,
    trader: string,
  ): Promise<TCompletePositionDataFromLens[]>;
}

export function serializeCompletePositionDataFromLens(
  val: TCompletePositionDataFromLens,
): string {
  return stringifyObject(val);
}

export function deserializeCompletePositionDataFromLens(
  valString: string,
): TCompletePositionDataFromLens {
  // TODO : Add type for serialized object
  const raw = JSON.parse(valString) as TCompletePositionDataFromLens;
  // const raw = rawVal as TCompletePositionDataFromLens;
  return {
    id: raw.id,
    settlementAsset: raw.settlementAsset,
    trader: raw.trader,
    pairId: BigInt(raw.pairId),
    positionIndex: BigInt(raw.positionIndex),
    collateral: BigInt(raw.collateral),
    phase: BigInt(raw.phase),
    inPhaseSince: BigInt(raw.inPhaseSince),
    leverage: BigInt(raw.leverage),
    long: raw.long,
    openPrice: BigInt(raw.openPrice),
    spreadReductionF: BigInt(raw.spreadReductionF),
    minPrice: BigInt(raw.minPrice),
    maxPrice: BigInt(raw.maxPrice),
    tpLastUpdated: BigInt(raw.tpLastUpdated),
    slLastUpdated: BigInt(raw.slLastUpdated),
    tp: BigInt(raw.tp),
    sl: BigInt(raw.sl),
    pendingUpdateOrderType: BigInt(
      raw.pendingUpdateOrderType as unknown as string,
    ),
    pendingUpdateOrderTimestamp: BigInt(raw.pendingUpdateOrderTimestamp),
    pendingUpdateOrderFieldAValue: BigInt(raw.pendingUpdateOrderFieldAValue),
    pendingUpdateOrderFieldBValue: BigInt(raw.pendingUpdateOrderFieldBValue),

    funding: BigInt(raw.funding),
    borrowInterest: BigInt(raw.borrowInterest),
    liquidationPrice: BigInt(raw.liquidationPrice),
  };
}

export const EMPTY_TRADING_FLOOR_TRADE_PARAMS: TTradingFloorLensTradeParamsStruct =
  {
    tradingFloor: "",
    maxSlF: 0n,
    maxTradesPerPair: 0n,
    maxSanityProfitF: 0n,
  };

export const EMPTY_COMPLETE_POSITION_DATA_FROM_LENS: TCompletePositionDataFromLens =
  {
    id: "",
    pairId: 0n,
    phase: 0n,
    positionIndex: 0n,
    trader: "",
    settlementAsset: "",
    leverage: 0n,
    long: false,
    collateral: 0n,
    maxPrice: 0n,
    minPrice: 0n,
    openPrice: 0n,
    inPhaseSince: 0n,
    tp: 0n,
    sl: 0n,
    tpLastUpdated: 0n,
    slLastUpdated: 0n,
    spreadReductionF: 0n,
    pendingUpdateOrderTimestamp: 0n,
    pendingUpdateOrderType: UpdatePositionFieldOrderTypeEnums.NONE,
    pendingUpdateOrderFieldAValue: 0n,
    pendingUpdateOrderFieldBValue: 0n,

    funding: 0n,
    borrowInterest: 0n,
    liquidationPrice: 0n,
  };
