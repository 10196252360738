import {
  PositionPhaseEnums,
  TPositionPhaseType,
  TPositionPhaseTypeNumeric,
} from "./contractEnums";

export const PositionPhaseEnumsDisplay: Record<
  TPositionPhaseTypeNumeric,
  string
> = {
  [Number(PositionPhaseEnums.NONE)]: "none",
  [Number(PositionPhaseEnums.OPEN_MARKET)]: "Market Open",
  [Number(PositionPhaseEnums.OPEN_LIMIT)]: "Limit Open",
  [Number(PositionPhaseEnums.OPENED)]: "Opened",
  [Number(PositionPhaseEnums.CLOSE_MARKET)]: "Market Close",
  [Number(PositionPhaseEnums.CLOSED)]: "Closed",
};
