import React from "react";

import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { THEME_CONSTANTS } from "../../theme/ThemeConstants";

interface IProps<T extends object> {
  columns: ColumnDef<T>[];
  data: T[];
  getRowId?: (val: T) => string;
}

function MaterialUiTableGeneric<T extends object>(props: IProps<T>) {
  const { columns, data, getRowId } = props;

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
  });

  return (
    <TableContainer
      sx={{
        flex: 1,
        height: "100%",
        borderRadius: "20px",
        backgroundColor: THEME_CONSTANTS.panelBgColor,
        paddingBottom: "1rem",
      }}
    >
      <Table>
        <TableHead>
          {
            // Loop over the header rows
            tableInstance.getHeaderGroups().map((headerGroup) => (
              // Apply the header row props
              <TableRow key={headerGroup.id}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((header) => (
                    // Apply the header cell props
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ color: THEME_CONSTANTS.titleGray }}
                      sx={{ borderBottom: "unset" }}
                      align={"center"}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableHead>
        <TableBody>
          {
            // Loop over the table rows
            tableInstance.getRowModel().rows.map((row) => {
              return (
                // Apply the row props
                <TableRow key={row.id}>
                  {
                    // Loop over the rows cells
                    row.getVisibleCells().map((cell) => {
                      // Apply the cell props
                      return (
                        <TableCell
                          key={cell.id}
                          align={"center"}
                          sx={{
                            borderBottom: "unset",
                            color: THEME_CONSTANTS.infoTextWhite,
                            padding: "0.2rem",
                            height: "2rem",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const MaterialUiTable = React.memo(MaterialUiTableGeneric) as <
  T extends object,
>(
  props: IProps<T>,
) => JSX.Element;

export default MaterialUiTable;
