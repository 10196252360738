import { IGeneralAppBaasInteractionService } from "./IGeneralAppBaasInteractionService.ts";
import { TDelegatedActionResponse } from "../intentsDelegationService/IIntentsDelegationService.ts";
import { ACTION_DELEGATION_TYPES } from "../intentsDelegationService/IntentsDelegationService.ts";
import { stringifyObject } from "../../../utils/strings.ts";
import { BytesLike } from "ethers";

export class GeneralAppBaasInteractionService
  implements IGeneralAppBaasInteractionService
{
  constructor(
    private _timeoutMarketOpenOrderEndpoint: string,
    private _timeoutMarketCloseOrderEndpoint: string,
    private _pullAllAndClaimEndpoint: string,
  ) {}

  async timeoutMarketOpenOrder(
    positionId: BytesLike,
  ): Promise<TDelegatedActionResponse> {
    return this.sendTxRequestToBaas(this._timeoutMarketOpenOrderEndpoint, {
      positionId: positionId,
    });
  }
  async timeoutMarketCloseOrder(
    positionId: BytesLike,
  ): Promise<TDelegatedActionResponse> {
    return this.sendTxRequestToBaas(this._timeoutMarketCloseOrderEndpoint, {
      positionId: positionId,
    });
  }

  requestPullAllAndClaim(
    tokenAddress: string,
    accountAddress: string,
  ): Promise<TDelegatedActionResponse> {
    return this.sendTxRequestToBaas(this._pullAllAndClaimEndpoint, {
      token: tokenAddress,
      account: accountAddress,
    });
  }

  private async sendTxRequestToBaas(
    endpintUrl: string,
    body: object,
  ): Promise<TDelegatedActionResponse> {
    const completeEndpoint = endpintUrl;

    // const body = ;
    const stringifiedBody = stringifyObject(body);

    const response = await fetch(completeEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: stringifiedBody,
    });

    console.log(response);

    if (!response.ok) {
      throw new Error(`Delegation call failed ${response.statusText}`);
    } else {
      const body = (await response.json()) as {
        tx: string | null;
        error: string | null;
      };

      return {
        tx: body.tx,
        error: body.error,
      };
    }
  }
}
