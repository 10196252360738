import { TWhiteLabelName, TWhiteLabelParams } from "./whiteLabelTypes.ts";
import {
  CHAIN_ID_ARBITRUM_ONE,
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_MODE,
} from "../constants/chainConstants.ts";

export const WHITE_LABEL_PARMAS: Record<TWhiteLabelName, TWhiteLabelParams> = {
  solidLizard: {
    hostName: "perps.solidlizard.finance",
    routePrefix: "solidlizard",
    pageTitle: "SolidLizard Perpetuals",

    // 0x2ad5505aa519f5199d43a5c10f99a1923855e587510884cefdda6d6b12ff6669
    partnerDomainName: "solidlyzard",

    faviconUrl: "",

    defaultSourceChainId: CHAIN_ID_ARBITRUM_ONE,
    defaultSettlementAssetSymbol: "scales",
  },
  spiritSwap: {
    hostName: "perps.spiritswap.finance",
    routePrefix: "spiritswap",
    pageTitle: "SpiritSwap Perpetuals",

    // 0xb159940823211553683ab398e5cc9f1d0ed9d38a5253e3ed1f8ef5a7aae35d66
    partnerDomainName: "spiritswap",

    faviconUrl: "",

    defaultSourceChainId: CHAIN_ID_FANTOM_OPERA,
    defaultSettlementAssetSymbol: "spirit",
  },
  ionic: {
    hostName: "perps.ionic.money",
    routePrefix: "uponly",
    pageTitle: "Ionic Perpetuals",

    // 0xc2be567e5d4f247cfd807b65fb03efcca821ae7f0aefece7a7252fd9759d6911
    partnerDomainName: "ionic",

    faviconUrl: "",

    defaultSourceChainId: CHAIN_ID_MODE,
    defaultSettlementAssetSymbol: "mode",
  },
  usdfi: {
    hostName: "perpetuals.usdfi.com",
    routePrefix: "usdfi",
    pageTitle: "USDFI Perpetuals",

    // 0x2a6f069718b6e60f9e7b9245760981ea0902a6322ac0e75fec0b60c1efabcdfb
    partnerDomainName: "usdfi",

    faviconUrl: "/assets/wl/usdfi_favicon.ico",

    defaultSourceChainId: CHAIN_ID_ARBITRUM_ONE,
    defaultSettlementAssetSymbol: "usdfi",
  },
};

export const EMPTY_WL_PARAMS: TWhiteLabelParams = {
  hostName: "",
  routePrefix: "",
  pageTitle: "",

  partnerDomainName: "",

  faviconUrl: "",

  defaultSourceChainId: CHAIN_ID_FANTOM_OPERA,
  defaultSettlementAssetSymbol: "",
};
