import type { TCompetitionParticipantGist } from "../../../../services/servicesIntergration/competitionGraphService/ICompetitionGraphService.ts";

import React, { useMemo } from "react";
import MaterialUiTable from "../../../../components/Tables/MaterialUiTable.tsx";
import { ColumnDef } from "@tanstack/react-table";
import { Tooltip, Typography } from "@mui/material";
import { unitsBnToFloat } from "../../../../utils/bignumbers.ts";
import { localStringer } from "../../../../ux/uxFunctions.ts";
import { summarizeAddress } from "../../../../ux/uxHooks.ts";
import { THEME_STATE_CONSTANTS } from "../../../../theme/ThemeConstants.ts";
import { isSameAddress } from "../../../../utils/addresses.ts";

interface IProps {
  saSymbol: string;
  saDecimals: number;
  rewardAssetSymbol: string;
  currentUser: string;
  participantsGists: TCompetitionParticipantGist[];
}

export const ConnectedCompetitionParticipantsTable = React.memo<IProps>(
  (props) => {
    const {
      saDecimals,
      saSymbol,
      rewardAssetSymbol,
      currentUser,
      participantsGists,
    } = props;

    // console.log(participantsGists);

    const columns: ColumnDef<TCompetitionParticipantGist>[] = useMemo<
      ColumnDef<TCompetitionParticipantGist>[]
    >(() => {
      return [
        {
          header: "Rank",
          id: "rank",
          cell: (props1) => {
            const isCurrentUser = isSameAddress(
              currentUser,
              props1.row.original.traderAddress,
            );
            return (
              <Typography>
                #{props1.row.index + 1} {isCurrentUser ? "You" : ""}
              </Typography>
            );
          },
        },
        {
          header: "Trader",
          id: "trader",
          accessorKey: "traderAddress",
          cell: (props1) => {
            const value = props1.cell.getValue() as string;
            return (
              <Tooltip title={value}>
                <Typography>{summarizeAddress(value)}</Typography>
              </Tooltip>
            );
          },
        },
        {
          header: "# Of Trades",
          id: "positionsCount",
          accessorKey: "positionsCount",
          width: "4",
        },
        {
          header: "Volume",
          id: "volume",
          accessorKey: "volume",
          cell: (props1) => {
            return (
              <Typography>{`${localStringer(
                unitsBnToFloat(props1.cell.getValue() as bigint, saDecimals),
                4,
              )} ${saSymbol}`}</Typography>
            );
          },
        },
        {
          header: "PnL",
          id: "pnl",
          accessorKey: "totalPnL",
          cell: (props1) => {
            const cellValue = props1.cell.getValue() as bigint;
            const isPositive = cellValue >= 0n;

            return (
              <Typography
                component={"p"}
                style={{
                  color: isPositive
                    ? THEME_STATE_CONSTANTS.positivePnL
                    : THEME_STATE_CONSTANTS.negativePnL,
                }}
              >{`${localStringer(
                unitsBnToFloat(cellValue, saDecimals),
                4,
              )} ${saSymbol}`}</Typography>
            );
          },
        },
        {
          header: "Win Ratio",
          id: "winRatio",
          cell: (props1) => {
            const gist = props1.row.original;

            let winRatio = 0;
            const totalClosedPositions =
              gist.profitPositionsCounter + gist.lossPositionsCounter;

            if (totalClosedPositions > 0) {
              winRatio = gist.profitPositionsCounter / totalClosedPositions;
            }

            const winRatioInPercentage = winRatio * 100;

            return <Typography>{winRatioInPercentage} %</Typography>;
          },
        },
        {
          header: "Rank Reward",
          id: "rankReward",
          cell: (props1) => {
            return <Typography>0 {rewardAssetSymbol}</Typography>;
          },
        },
      ];
    }, [currentUser, saDecimals, saSymbol, rewardAssetSymbol]);

    return (
      <MaterialUiTable
        columns={columns}
        data={participantsGists}
        getRowId={(val: TCompetitionParticipantGist) => `${val.traderAddress}`}
      />
    );
  },
);

export default ConnectedCompetitionParticipantsTable;
