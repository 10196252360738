import { ILeverageDimensionsService } from "./leverageDimensionService/ILeverageDiomensionsService";
import { LeverageDimensionsService } from "./leverageDimensionService/LeverageDimensionsService";

export interface IServices {
  // cryptoWalletIntegrationService: ICryptoWalletConnectionService;

  leverageDimensionsService: ILeverageDimensionsService;
}

// DEV_NOTE : For simplicity of early stage dev, we assume that we have ethereum provider, if not, we will not initialize the services.
export function buildServices(): IServices {
  return {
    // cryptoWalletIntegrationService: new CryptoWalletConnectionService(
    //   ethereumProvider,
    // ),
    leverageDimensionsService: new LeverageDimensionsService(),
  };
}
