import { numericalDisplay } from "../ux/displayCalculations";
import { numbersUtils } from "./numbers.ts";

export const calculateStopLoss = (
  entryPrice: number,
  wLeverage: number,
  wantedStopLossFractionInUnits: number,
  isLong?: boolean,
): number => {
  if (isLong) {
    if (wantedStopLossFractionInUnits > entryPrice) return 0;
    const calculatedMultiplier =
      ((entryPrice - wantedStopLossFractionInUnits) / entryPrice) * 100;
    return calculatedMultiplier * wLeverage;
  } else {
    if (wantedStopLossFractionInUnits < entryPrice) return 0;
    const calculatedMultiplier =
      ((wantedStopLossFractionInUnits - entryPrice) / entryPrice) * 100;
    return calculatedMultiplier * wLeverage;
  }
};

export const calculateTakeProfit = (
  entryPrice: number,
  wLeverage: number,
  wantedTakeProfitFractionInUnits: number,
  isLong?: boolean,
): number => {
  if (isLong) {
    if (wantedTakeProfitFractionInUnits < entryPrice) return 0;

    const calculatedMultiplier =
      ((wantedTakeProfitFractionInUnits - entryPrice) / entryPrice) * 100;
    return calculatedMultiplier * wLeverage;
  } else {
    if (wantedTakeProfitFractionInUnits > entryPrice) return 0;
    const calculatedMultiplier =
      ((entryPrice - wantedTakeProfitFractionInUnits) / entryPrice) * 100;
    return calculatedMultiplier * wLeverage;
  }
};

export const getLossSubTitle = (slValue: number) => {
  return slValue > 0 ? "-" + numericalDisplay(slValue, 2) : "0";
};

export const getProfitSubTitle = (tpValue: number) => {
  return numericalDisplay(tpValue, 2);
};

export const getTPDecriptor = (tpPrice: number, decimals: number) => {
  return tpPrice == 0 ? "None" : `$${numericalDisplay(tpPrice, decimals)}`;
};

export const getSLDecriptor = (slPrice: number, decimals: number) => {
  return slPrice == 0 ? "None" : `$${numericalDisplay(slPrice, decimals)}`;
};

export const getProfitSubValue = (profitSubTitle: string) => {
  const profitValue = numbersUtils.parseFloatFromLocalString(profitSubTitle);
  return `${profitValue > 0 ? "+" + profitValue.toLocaleString() : "0"}%`;
};
