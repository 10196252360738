import { formatEther, formatUnits, parseEther, parseUnits } from "ethers";

export function etherBnToFloat(etherBn: bigint): number {
  return parseFloat(formatEther(etherBn.toString()));
}

export function unitsBnToFloat(etherBn: bigint, decimals: number): number {
  return parseFloat(formatUnits(etherBn.toString(), decimals));
}

export function floatToEtherBn(units: number): bigint {
  return parseEther(units.toFixed(18));
}

export function floatToUnitsBn(units: number, decimals: number): bigint {
  return parseUnits(units.toFixed(decimals), decimals);
}
