function detectDecimalSeparator(): string {
  const number = 1.1;
  const formattedNumber = number.toLocaleString();
  return formattedNumber.charAt(1);
}

function parseFloatFromLocalString(localStringFloat: string): number {
  const decimalSeparator = detectDecimalSeparator();

  // US format (123,456.789)
  if (decimalSeparator === ".") {
    return parseFloat(localStringFloat);
  }
  // Europe format (123.456,789)
  else if (decimalSeparator === ",") {
    const localStringFloatReplaced = localStringFloat
      // Replace thousands separator with empty string
      .replace(".", "")
      // Replace decimal separator with dot
      .replace(",", ".");
    return parseFloat(localStringFloatReplaced);
  } else {
    console.error(`Unknown decimal separator: ${decimalSeparator}`);
    throw new Error(`Unknown decimal separator: ${decimalSeparator}`);
  }
}

export const numbersUtils = {
  detectDecimalSeparator,
  parseFloatFromLocalString,
};
