import React, { useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { observer } from "mobx-react";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
  useLexStoreById,
} from "../../../../store/storeHooks";
import { useWaitForTx } from "../../../../hooks/txWaitingHooks";
import { toastWarning } from "../../../../ux/toasts/toasting";
import { PoolLiquidityModal } from "./PoolLiquidityModal";
import { toastTxError } from "../../../../ux/toasts/complexToasting.ts";
import { EoaActionTypes } from "../../../../types/eoaActionTypes.ts";
import { positionToastmanager } from "../../../../ux/delegatedIntentsUx.ts";
import { toast_step } from "../../../../ux/stepToast/statusToastManager.tsx";
import { SingleLexStore } from "../../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import {
  CHAIN_ID_NO_CHAIN,
  TSourceChainIds,
} from "../../../../constants/chainConstants.ts";
import { useSwitchChain } from "wagmi";

interface IProps {
  open: boolean;
  closeModal: () => void;

  lexId: string;
  onLexIdChange: (lexId: string) => void;
  addProcessingItem: (ket: string, value: boolean) => void;
}

const useStyles = makeStyles()((theme) => ({}));

export const ConnectedPoolRemoveLiquidityModal = observer<React.FC<IProps>>(
  (props) => {
    const { classes } = useStyles();
    const { open, closeModal, lexId, onLexIdChange, addProcessingItem } = props;

    const eoaActionType = EoaActionTypes.SIGNED;

    const { switchChain } = useSwitchChain();

    const lexStore = useLexStoreById(lexId);
    const leverageDimensionsStore = useLeverageDimensionsStore();
    const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();

    const allLexStoresInRelevantChain =
      leverageDimensionsStore.getAllLexStoresByChain(
        lexStore?.sourceChainId ?? (CHAIN_ID_NO_CHAIN as TSourceChainIds),
      );
    const connectedToSourceChain =
      lexStore?.sourceChainId === cryptoWalletConnectionStore.chainId;

    const {
      setTxToWaitForAndCallbacks: setApproveTxHashAndaCallbacks,
      isLoading: isWaitingForApproveTx,
      isFetched: wasApproveTxMined,
    } = useWaitForTx(
      lexStore?.sourceChainId ?? cryptoWalletConnectionStore.chainId,
    );

    const [isLoading, setIsLoading] = useState(false);

    const userAddress = cryptoWalletConnectionStore.mainAddress;

    const removeLiquidityCallback = useCallback(
      async (amountInUnits: number, minAssetAmountOutInUnits: number) => {
        if (!connectedToSourceChain) {
          switchChain({ chainId: lexStore?.sourceChainId ?? 0 });
        }

        if (amountInUnits == 0) {
          toastWarning(`0 amount`);
          return;
        }

        if (lexStore) {
          const signerAddress =
            (await cryptoWalletConnectionStore.getChainSigner?.getAddress()) ??
            "NON";
          if (signerAddress !== userAddress) {
            throw new Error(
              `Signer address ${signerAddress} does not match user address ${userAddress}`,
            );
          }

          setIsLoading(true);
          addProcessingItem(lexId, true);
          lexStore?.activeUserStore
            ?.epochRedeemToPool(
              eoaActionType,
              amountInUnits,
              minAssetAmountOutInUnits,
            )
            .then((res) => {
              positionToastmanager.updateToast(res.txHash, toast_step.success);
              closeModal();
            })
            .catch((e) => toastTxError(e))
            .finally(() => {
              setIsLoading(false);
              addProcessingItem(lexId, false);
              void lexStore.refreshDataAfterTx();
            });
        }
      },
      [
        connectedToSourceChain,
        lexStore,
        switchChain,
        cryptoWalletConnectionStore.getChainSigner,
        userAddress,
        addProcessingItem,
        lexId,
        eoaActionType,
        closeModal,
      ],
    );

    const needToApprove =
      lexStore?.activeUserStore.account_allowanceForAdapterOnSourceChain == 0n;
    // && !(wasApproveTxSent ? wasApproveTxMined : false);

    return (
      <PoolLiquidityModal
        open={open}
        closeModal={closeModal}
        assetSymbol={lexStore?.sourceAssetParameters.symbol ?? ""}
        inSymbol={`${lexStore?.sourceAssetParameters.symbol ?? ""} LP`}
        outSymbol={lexStore?.sourceAssetParameters.symbol ?? ""}
        inToOutConversionRatioInUnits={lexStore?.exchangeRateInUnits ?? 1}
        minDepositAmount={lexStore?.getMinDepositAmount ?? 0}
        actionCallback={removeLiquidityCallback}
        isLoadingData={isLoading}
        availableAmountInUnits={
          lexStore?.activeUserStore.accountSupplyInLxTokensUnits ?? 0
        }
        title={"Remove"}
        actionName={"Remove"}
        actionEpochsDelay={lexStore?.epochsDelayRedeem ?? 0}
        lexStoreChains={allLexStoresInRelevantChain}
        onLexIdChange={onLexIdChange}
      />
    );
  },
);
