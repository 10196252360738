import { useEffect, useMemo, useState } from "react";
import {
  EMPTY_GRAPH_INFO_USAGE_ROUND,
  EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER,
  TUsageRoundGraphInfo,
  TUsageRoundTraderGraphInfo,
} from "../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { GraphQLService } from "../../services/servicesIntergration/graphqlService/GraphQLService.ts";
import { LED_IDS } from "../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { getManualRewardsRoundRules } from "../../incentives/manualIncentives.ts";
import { ENGINE_CHAIN_ADDRESSES } from "../../constants/chainSystemAddresses.ts";
import {
  CHAIN_ID_FANTOM_OPERA,
  TChainIds,
  TEngineChainIds,
} from "../../constants/chainConstants.ts";
import { useContractsServicesStore } from "../../store/storeHooks.ts";
import { IGeneralTokenDispenserService } from "../../services/contractsIntegration/GeneralTokenDispenserService/IGeneralTokenDispenserService.ts";
import { chipsBnToUnits } from "../../utils/chipsCalculationsUtils.ts";
import { stringifyObject } from "../../utils/strings.ts";

export function getCurrentRewardsRoundRulesForLex(
  lexId: string,
  graphRoundNumber: number,
) {
  return getManualRewardsRoundRules(lexId, graphRoundNumber);
}

export function useGetUsageRoundTraderInfo(
  graphUrl: string,
  poolAddress: string,
  traderAddress: string,
  roundNumber: number,
) {
  const [usageRoundTraderInfo, setUsageRoundTraderInfo] =
    useState<TUsageRoundTraderGraphInfo>(EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER);

  useEffect(() => {
    if (poolAddress && roundNumber) {
      const graphQlService = new GraphQLService(graphUrl);

      graphQlService
        .getUsageRoundTrader(poolAddress, traderAddress, roundNumber)
        .then((roundTraderInfo) => {
          setUsageRoundTraderInfo(roundTraderInfo);
        })
        .catch((e) => {
          console.error(
            `Failed getting usage round trader info for pool ${poolAddress} trader ${traderAddress} round number ${roundNumber} ${e}`,
          );
          console.error(e);
        });
    }
  }, [graphUrl, poolAddress, roundNumber, traderAddress]);

  return {
    usageRoundTraderInfo,
  };
}

export type TRewardQueryParam = {
  assetAddress: string;
  assetSymbol: string;
};

export type TPendingRewardGist = {
  engineChainId: TEngineChainIds;
  assetAddress: string;
  assetSymbol: string;
  pendingAmountInUnits: number;
};

export function useGetPendingRewardsForUserFromGTD(
  traderAddress: string,
  engineChainId: TEngineChainIds,
  rewardsQueryParams: TRewardQueryParam[],
) {
  // TODO : Make this dynamic
  const gtdAddress =
    ENGINE_CHAIN_ADDRESSES[engineChainId].generalTokenDispenserAddress;

  const contractServicesStore = useContractsServicesStore();

  const generalTokenService = useMemo(() => {
    return contractServicesStore.buildGeneralTokenDispenserService(
      gtdAddress,
      engineChainId,
    );
  }, [gtdAddress, engineChainId, contractServicesStore]);

  const [pendingRewardsForUser, setPendingRewardsForUser] = useState<
    TPendingRewardGist[]
  >(
    rewardsQueryParams.map((rewardQueryParam) => {
      return {
        engineChainId,
        assetAddress: rewardQueryParam.assetAddress,
        assetSymbol: rewardQueryParam.assetSymbol,
        pendingAmountInUnits: 0,
      };
    }),
  );

  useEffect(() => {
    if (traderAddress) {
      readPendingRewardsFromGTD(
        engineChainId,
        generalTokenService,
        traderAddress,
        rewardsQueryParams,
      )
        .then((pendingRewards) => {
          console.log(
            `Pending Rewards : ${stringifyObject(pendingRewards, 3)}`,
          );
          setPendingRewardsForUser(pendingRewards);
        })
        .catch((e) => {
          console.error(`Failed reading pending rewards for user ${e}`);
          console.error(e);
        });
    }
  }, [generalTokenService, rewardsQueryParams, traderAddress]);

  return pendingRewardsForUser;
}

async function readPendingRewardsFromGTD(
  engineChainId: TEngineChainIds,
  generalTokenService: IGeneralTokenDispenserService,
  accountAddress: string,
  rewardsQueryParams: TRewardQueryParam[],
) {
  const pendingRewards = await Promise.all(
    rewardsQueryParams.map(async (rewardQueryParam) => {
      const pendingAmountInUnits =
        await generalTokenService.getPendingAmountForAccount(
          rewardQueryParam.assetAddress,
          accountAddress,
        );

      const pendingRewardGist: TPendingRewardGist = {
        engineChainId,
        assetAddress: rewardQueryParam.assetAddress,
        assetSymbol: rewardQueryParam.assetSymbol,
        pendingAmountInUnits: chipsBnToUnits(pendingAmountInUnits),
      };

      return pendingRewardGist;
    }),
  );

  return pendingRewards;
}
