import { action, computed, makeObservable, observable } from "mobx";
import { CHAIN_ID_TO_DISPLAY_DATA } from "../constants/chainConstants";
import type { TChainIdsWithNone } from "../constants/chainConstants.ts";
import type { Signer } from "ethers";
import { ethers, JsonRpcSigner } from "ethers";
import { logWalletConnection } from "../services/servicesIntergration/lifecycleIntegration/lifecycleIntegration.ts";

type TypedDataDomain = ethers.TypedDataDomain;
type TypedDataField = ethers.TypedDataField;

export class CryptoWalletConnectionStore {
  @observable public mainAddress = "";
  @observable public chainId: TChainIdsWithNone = -1;

  @observable private chainSigner?: Signer;

  @computed public get chainCodeName(): string {
    // @ts-ignore
    if (CHAIN_ID_TO_DISPLAY_DATA[this.chainId] && this.chainId != -1) {
      return CHAIN_ID_TO_DISPLAY_DATA[this.chainId].codeName;
    } else {
      return "";
    }
  }

  @computed public get chainName(): string {
    // @ts-ignore
    if (CHAIN_ID_TO_DISPLAY_DATA[this.chainId] && this.chainId != -1) {
      return CHAIN_ID_TO_DISPLAY_DATA[this.chainId].name;
    } else {
      return "";
    }
  }

  @computed public get isConnectedToWallet(): boolean {
    return this.mainAddress !== "";
  }

  @computed public get getChainSigner(): Signer | undefined {
    return this.chainSigner;
  }

  public askToSignTypedData(
    domain: TypedDataDomain,
    types: Record<string, TypedDataField[]>,
    value: Record<string, any>,
  ) {
    const res = this.chainSigner?.signTypedData(domain, types, value);
    if (!res) {
      throw new Error(`No signature`);
    } else {
      return res;
    }
  }

  constructor() {
    makeObservable(this);
  }

  @action("setMainAddress")
  public setMainAddress(mainAddress: string) {
    logWalletConnection(mainAddress);

    this.mainAddress = mainAddress;
  }

  @action("setChainId")
  public setChainId(chainId: TChainIdsWithNone) {
    this.chainId = chainId;
  }

  @action("setChainSigner")
  public setChainSigner(chainSigner: JsonRpcSigner) {
    this.chainSigner = chainSigner;
  }
}
