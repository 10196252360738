import {
  TPositionTableRowLifecycleState,
  PositionTableRowLifecycleStateEnums,
  TPositionTableRowBaseData,
} from "lynx-ui-components";

import { TCompletePositionDataFromLens } from "../../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import { PAIR_ID_TO_NAME } from "../../../../constants/pairsDisplayConstants.ts";
import { TPairIds } from "../../../../constants/pairsConstants.ts";
import { scaledLeverageToUnits } from "../../../../utils/leverageCalculationsUtils.ts";
import { unitsBnToFloat } from "../../../../utils/bignumbers.ts";
import {
  PositionPhaseEnums,
  TPositionPhaseTypeNumeric,
} from "../../../../constants/contractEnums.ts";
import { priceBnToFloat } from "../../../../utils/lynxScalesUtils.ts";
import { TClosedPositionGist } from "../../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";

export function buildPositionTableBaseRowDataFromLivePosition(
  livePositionData: TCompletePositionDataFromLens,
  underlyingDecimals: number,
  underlyingSymbol: string,
): TPositionTableRowBaseData {
  // Lifecycle state has a bit more logic, maybe move it to a function later

  const tableRowBaseData: TPositionTableRowBaseData = {
    lifecycleState: calculateOpenPositionLifecycleState(livePositionData),
    livePositionId: livePositionData.id.toString(),
    pairName: PAIR_ID_TO_NAME[Number(livePositionData.pairId) as TPairIds],
    isLong: livePositionData.long,
    leverageInUnits: scaledLeverageToUnits(Number(livePositionData.leverage)),
    initialCollateralInUnits: unitsBnToFloat(
      livePositionData.collateral,
      underlyingDecimals,
    ),
    collateralAssetSymbol: underlyingSymbol,
    liquidationPrice: priceBnToFloat(livePositionData.liquidationPrice),
    tpPrice: priceBnToFloat(livePositionData.tp),
    slPrice: priceBnToFloat(livePositionData.sl),
  };

  return tableRowBaseData;
}

export function buildPositionTableBaseRowDataFromClosedPositionGist(
  closedPositionGist: TClosedPositionGist,
  underlyingDecimals: number,
  underlyingSymbol: string,
): TPositionTableRowBaseData {
  // Lifecycle state has a bit more logic, maybe move it to a function later

  const tableRowBaseData: TPositionTableRowBaseData = {
    lifecycleState: calculateClosedPositionLifecycleState(closedPositionGist),
    livePositionId: closedPositionGist.positionId,

    pairName: PAIR_ID_TO_NAME[closedPositionGist.pairId as TPairIds],
    isLong: closedPositionGist.long,
    leverageInUnits: closedPositionGist.leverageInUnits,
    initialCollateralInUnits: unitsBnToFloat(
      closedPositionGist.initialCollateral,
      underlyingDecimals,
    ),
    collateralAssetSymbol: underlyingSymbol,
    // TODO : Need to fill ??
    liquidationPrice: 0,
    tpPrice: priceBnToFloat(0n),
    slPrice: priceBnToFloat(0n),
  };

  return tableRowBaseData;
}

function calculateOpenPositionLifecycleState(
  livePositionData: TCompletePositionDataFromLens,
): TPositionTableRowLifecycleState {
  const isUnsuccessfulMarketOpenRequest =
    isLostOpenMarketRequest(livePositionData);
  const isUnsuccessfulMarketCloseRequest =
    isLostCloseMarketRequest(livePositionData);

  if (isUnsuccessfulMarketOpenRequest) {
    return PositionTableRowLifecycleStateEnums.MARKET_OPEN_TIMEOUT;
  } else if (isUnsuccessfulMarketCloseRequest) {
    return PositionTableRowLifecycleStateEnums.MARKET_CLOSE_TIMEOUT;
  } else {
    return PositionTableRowLifecycleStateFromPositionPhaseEnum[
      Number(livePositionData.phase)
    ];
  }
}

function calculateClosedPositionLifecycleState(
  closedPositionGist: TClosedPositionGist,
): TPositionTableRowLifecycleState {
  return PositionTableRowLifecycleStateEnums.CLOSED;
}

// TODO : Get as an argument
const marketOpenInvalidationTimeframe = 60;

export function isLostOpenMarketRequest(
  position: TCompletePositionDataFromLens,
) {
  const isMarketOpening = position.phase === PositionPhaseEnums.OPEN_MARKET;
  const timeNow = Math.floor(Date.now() / 1000);

  const isUnsuccessfulMarketOpenRequest =
    isMarketOpening &&
    Number(position.inPhaseSince) + marketOpenInvalidationTimeframe <= timeNow;

  return isUnsuccessfulMarketOpenRequest;
}

export function isLostCloseMarketRequest(
  position: TCompletePositionDataFromLens,
) {
  const isMarketOpening = position.phase === PositionPhaseEnums.CLOSE_MARKET;
  const timeNow = Math.floor(Date.now() / 1000);

  const isUnsuccessfulMarketOpenRequest =
    isMarketOpening &&
    Number(position.inPhaseSince) + marketOpenInvalidationTimeframe <= timeNow;

  return isUnsuccessfulMarketOpenRequest;
}

export const PositionTableRowLifecycleStateFromPositionPhaseEnum: Record<
  TPositionPhaseTypeNumeric,
  TPositionTableRowLifecycleState
> = {
  [Number(PositionPhaseEnums.DELEGATING)]:
    PositionTableRowLifecycleStateEnums.DELEGATING,
  [Number(PositionPhaseEnums.NONE)]: PositionTableRowLifecycleStateEnums.NONE,
  [Number(PositionPhaseEnums.OPEN_MARKET)]:
    PositionTableRowLifecycleStateEnums.OPEN_MARKET,
  [Number(PositionPhaseEnums.OPEN_LIMIT)]:
    PositionTableRowLifecycleStateEnums.OPEN_LIMIT,
  [Number(PositionPhaseEnums.OPENED)]:
    PositionTableRowLifecycleStateEnums.OPENED,
  [Number(PositionPhaseEnums.CLOSE_MARKET)]:
    PositionTableRowLifecycleStateEnums.CLOSE_MARKET,
  [Number(PositionPhaseEnums.CLOSED)]:
    PositionTableRowLifecycleStateEnums.CLOSED,
};
