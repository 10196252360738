import { ITradeIntentsVerifierService } from "./ITradeIntentsVerifierService.ts";
import {
  BigNumberish,
  BytesLike,
  Provider,
  Signer,
  ethers,
  Overrides,
} from "ethers";

import { TTradeIntentsVerifierActionsEnum } from "../../../constants/contractEnums.ts";
import { TradeIntentsVerifierV1 } from "../../../typechain/contracts/Lynx/IntentsVerifier/TradeIntentsVerifierV1.ts";
import { TradeIntentsVerifierV1__factory } from "../../../typechain/factories/contracts/Lynx/IntentsVerifier/TradeIntentsVerifierV1__factory.ts";
import {
  TUserRequestPayload_CloseMarketStruct,
  TUserRequestPayload_OpenPositionStruct,
  TUserRequestPayload_UpdatePositionDoubleFieldStruct,
  TUserRequestPayload_UpdatePositionSingleFieldStruct,
} from "../../servicesIntergration/intentsDelegationService/IIntentsDelegationService.ts";
import { floatToEtherBn } from "../../../utils/bignumbers.ts";

const SIGNER_WITH_ENGINE_NATIVE_ADDRESS =
  "0x5dbC59FD5b8cF0F092aA9de04b4F2EdBfDfCb1Aa";
const NATIVE_AMOUNT_FOR_ACTION_FEE_IN_UNITS = 5;

export class TradeIntentsVerifierService
  implements ITradeIntentsVerifierService
{
  private tradeIntentsVerifierContract: TradeIntentsVerifierV1;
  private staticCallOverrides: Overrides;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.tradeIntentsVerifierContract = TradeIntentsVerifierV1__factory.connect(
      address,
      signerOrProvider,
    );

    this.staticCallOverrides = {
      from: SIGNER_WITH_ENGINE_NATIVE_ADDRESS,
      value: floatToEtherBn(NATIVE_AMOUNT_FOR_ACTION_FEE_IN_UNITS),
    };
  }

  async readCurrentTradeIntentNonce(
    account: string,
    tradeIntentAction: TTradeIntentsVerifierActionsEnum,
  ): Promise<bigint> {
    return this.tradeIntentsVerifierContract.getNextValidNonceFor(
      account,
      tradeIntentAction,
    );
  }

  async staticCall_delegateRequestPositionOpen(
    payload: TUserRequestPayload_OpenPositionStruct,
    signature: string,
    domainCode: BytesLike,
    referralCode: BytesLike,
  ) {
    const signatureObject = ethers.Signature.from(signature);

    const v: BigNumberish = signatureObject.v;
    const r: BytesLike = signatureObject.r;
    const s: BytesLike = signatureObject.s;

    const res =
      await this.tradeIntentsVerifierContract.verifyIntent_traderRequest_openNewPosition.staticCall(
        payload,
        v,
        r,
        s,
        domainCode,
        referralCode,
        true,
        this.staticCallOverrides,
      );

    return res;
  }

  async staticCall_delegateRequestMarketClose(
    payload: TUserRequestPayload_CloseMarketStruct,
    signature: string,
  ) {
    const signatureObject = ethers.Signature.from(signature);

    const v: BigNumberish = signatureObject.v;
    const r: BytesLike = signatureObject.r;
    const s: BytesLike = signatureObject.s;

    const res =
      await this.tradeIntentsVerifierContract.verifyIntent_traderRequest_marketClosePosition.staticCall(
        payload,
        v,
        r,
        s,
        this.staticCallOverrides,
      );

    return res;
  }

  async staticCall_delegateRequestSingleFieldUpdate(
    payload: TUserRequestPayload_UpdatePositionSingleFieldStruct,
    signature: string,
  ) {
    const signatureObject = ethers.Signature.from(signature);

    const v: BigNumberish = signatureObject.v;
    const r: BytesLike = signatureObject.r;
    const s: BytesLike = signatureObject.s;

    const res =
      await this.tradeIntentsVerifierContract.verifyIntent_traderRequest_updatePositionSingleField.staticCall(
        payload,
        v,
        r,
        s,
        this.staticCallOverrides,
      );

    return res;
  }

  async staticCall_delegateRequestDoubleFieldUpdate(
    payload: TUserRequestPayload_UpdatePositionDoubleFieldStruct,
    signature: string,
  ) {
    const signatureObject = ethers.Signature.from(signature);

    const v: BigNumberish = signatureObject.v;
    const r: BytesLike = signatureObject.r;
    const s: BytesLike = signatureObject.s;

    const res =
      await this.tradeIntentsVerifierContract.verifyIntent_traderRequest_updatePositionDoubleField.staticCall(
        payload,
        v,
        r,
        s,
        this.staticCallOverrides,
      );

    return res;
  }
}
