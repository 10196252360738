import React, { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import {
  ClosedPositionsTable,
  PositionClosedAsEnums,
} from "lynx-ui-components";

import type {
  TClosedPositionsTableRowData,
  TPositionClosedAs,
  TPositionTableRowBaseData,
} from "lynx-ui-components";

import { buildPositionTableBaseRowDataFromClosedPositionGist } from "./tableWrappersUtils.ts";
import { priceBnToFloat } from "../../../../utils/lynxScalesUtils.ts";
import { BytesLike } from "ethers";
import { TClosedPositionGist } from "../../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { unitsBnToFloat } from "../../../../utils/bignumbers.ts";
import { useModalsStore } from "../../../../store/storeHooks.ts";
import { calculatePnlInfo } from "../../../../utils/pnlCalculations.ts";
import { PRICE_UX_FOR_PAIRS } from "../../../../ux/assetsUxPrecisions.ts";
import { TPairIds } from "../../../../constants/pairsConstants.ts";

interface IProps {
  isConnectedToWallet: boolean;
  requestWalletConnection: () => void;
  closedPositionGists: TClosedPositionGist[];

  // TODO : Support multiple
  underlyingDecimals: number;
  underlyingSymbol: string;

  showExpandPosition: (positionId: BytesLike) => void;
  showSharePosition: (positionId: BytesLike) => void;
}

const useStyles = makeStyles()((theme) => ({}));

function convertGistClosedAsStringToTableEnum(
  closedPositionGist: TClosedPositionGist,
): TPositionClosedAs {
  const closedAs = closedPositionGist.closedAs;
  if (closedAs == "MARKET") {
    return PositionClosedAsEnums.MARKET;
  } else if (closedAs == "TP") {
    return PositionClosedAsEnums.TP;
  } else if (closedAs == "SL") {
    return PositionClosedAsEnums.SL;
  } else if (closedAs == "LIQUIDATION") {
    return PositionClosedAsEnums.LIQ;
  } else {
    return PositionClosedAsEnums.NONE;
  }
}

export const ClosePositionsTableWrapper = React.memo<IProps>((props) => {
  const { classes } = useStyles();
  const {
    isConnectedToWallet,
    requestWalletConnection,
    closedPositionGists,
    underlyingDecimals,
    underlyingSymbol,
    showExpandPosition,
    showSharePosition,
  } = props;

  const modalsStore = useModalsStore();

  const rawAmountToUnits = useCallback(
    (rawAmount: bigint) => {
      return unitsBnToFloat(rawAmount, underlyingDecimals);
    },
    [underlyingDecimals],
  );

  const dataInRowsForm = useMemo<TClosedPositionsTableRowData[]>(() => {
    return closedPositionGists
      .sort((a, b) => b.closeTimestamp - a.closeTimestamp)
      .map((closePositionGist) => {
        const tableRowBaseData: TPositionTableRowBaseData =
          buildPositionTableBaseRowDataFromClosedPositionGist(
            closePositionGist,
            underlyingDecimals,
            underlyingSymbol,
          );

        const totalOpenFeePaid =
          closePositionGist.openFeeForSystem +
          closePositionGist.openFeeForLexPool;

        // TODO : Add this information to the graph ?
        const workingCollateral =
          closePositionGist.initialCollateral - totalOpenFeePaid;

        const pnlInfo = calculatePnlInfo(
          unitsBnToFloat(workingCollateral, underlyingDecimals),
          closePositionGist.leverageInUnits,
          priceBnToFloat(closePositionGist.openPrice),
          priceBnToFloat(closePositionGist.closePrice),
          closePositionGist.long,
          rawAmountToUnits(closePositionGist.borrowInterestPaidOnClose),
          rawAmountToUnits(closePositionGist.fundingFeesPaidOnClose),
          // rawAmountToUnits(closePositionGist.closeFee),
          // NOTE : Displaying pnl without the closing fee
          0,
        );

        const closedPositionTableRow: TClosedPositionsTableRowData = {
          ...tableRowBaseData,

          historicPositionId: closePositionGist.entityId,
          openPrice: priceBnToFloat(closePositionGist.openPrice),
          // TODO : CRITICAL : Map from gist to enum
          closedAs: convertGistClosedAsStringToTableEnum(closePositionGist),
          closeTime: closePositionGist.closeTimestamp,
          closePrice: priceBnToFloat(closePositionGist.closePrice),
          // TODO : CRITICAL : Add this to the graph and read
          pnlInUnits: pnlInfo.netPnl,
          roiInUnits: pnlInfo.roiInUnit,

          // UX
          priceDisplayDecimals:
            PRICE_UX_FOR_PAIRS[Number(closePositionGist.pairId) as TPairIds],
        };

        return closedPositionTableRow;
      });
  }, [
    closedPositionGists,
    rawAmountToUnits,
    underlyingDecimals,
    underlyingSymbol,
  ]);

  return (
    <ClosedPositionsTable
      isWalletConnected={isConnectedToWallet}
      requestWalletConnection={requestWalletConnection}
      rowsData={dataInRowsForm}
      showExpandPosition={(positionId, historicPositionId: BytesLike) =>
        modalsStore.showPositionInfoModal(historicPositionId, false)
      }
      showSharePosition={(positionId, historicPositionId: BytesLike) =>
        modalsStore.showPositionShareModal(historicPositionId, false)
      }
    />
  );
});
