import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "tss-react/mui";
import { usePairStoreFromActiveLed } from "../../../store/multiInstanceStoreHooks";
import { ConnectedTitleAndValue } from "../../../components/Cards/ConnectedTitleAndValue";
import { InPracticeTooltipContent } from "../../../components/ToolTips/InPracticeTooltipContent";
import { Typography } from "@mui/material";
import { useIsMobile } from "../../../ux/uxHooks.ts";

interface IProps {
  pairId: number;
}

const useStyles = makeStyles()((theme) => ({
  infoItemsContainer: {
    flex: 1,
    padding: "0.5rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1rem",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },

  titleAndValueContainer: {
    flex: 1,
  },
}));

export const PairOverViewComponent = observer<React.FC<IProps>>((props) => {
  const { classes } = useStyles();
  const { pairId } = props;

  const pairStore = usePairStoreFromActiveLed(pairId);

  const isMobile = useIsMobile();

  const localStringer = useCallback((num: number, decimals = 3) => {
    return num.toLocaleString(undefined, {
      maximumFractionDigits: decimals,
    });
  }, []);

  if (!pairStore) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          // flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {" "}
        <Typography>Loading ...</Typography>
      </div>
    );
  }

  const underlyingSymbol = pairStore.ownLexStore.sourceAssetParameters.symbol;

  // TODO : Take max caps into account
  const availableAmountToBorrowInUnits =
    pairStore.effectiveCap_availableBorrowAmountInUnits;
  // const availableAmountToBorrowInUnits = pairStore.ownLexStore.availableAmountToBorrowInUnits;

  const fundingRate = pairStore.fundingRateInUnitsPerSecond;
  const borrowAprInPercentages = pairStore.ownLexStore.borrowAprInPercentages;

  const openInterestShortInUnits = pairStore.openInterestShortInUnits;
  const openInterestLongInUnits = pairStore.openInterestLongInUnits;

  const openFeeInUnits = pairStore.openFeeFInUnits;
  const closeFeeInUnits = pairStore.closeFeeFInUnits;
  const openFeeInPercentage = openFeeInUnits * 100;
  const closeFeeInPercentage = closeFeeInUnits * 100;

  // TODO : This should be displayed somewhere else, not in the pair page
  const interestShareFactorInPercentages =
    pairStore.ownLexStore.interestShareFactorInUnits * 100;

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        // flexDirection: "row",
        flexWrap: "wrap",
        maxWidth: "100%",
      }}
    >
      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Available To Borrow"}
          text={`${localStringer(availableAmountToBorrowInUnits, 3)}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={
                "The amount of tokens available for borrowing in this pair"
              }
              inPractice={`There are ${localStringer(
                availableAmountToBorrowInUnits,
                3,
              )} of tokens available for borrowing`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Interest share factor"}
          text={`${interestShareFactorInPercentages}%`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={"Interest share factor"}
              inPractice={`Interest share factor`}
            />
          }
        />
      </div>

      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Borrow APR"}
          text={`${localStringer(borrowAprInPercentages, 3)} %`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={"The yearly interest paid for open interest"}
              inPractice={`All open interest pays ${localStringer(
                borrowAprInPercentages,
                3,
              )}% yearly`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Funding Rate"}
          text={`${localStringer(
            fundingRate,
          )} Tokens/Second are being paid for each skew unit`}
          containerClassName={classes.titleAndValueContainer}
          // tooltipContent={
          //   <InPracticeTooltipContent
          //     definition={"Lorem"}
          //     inPractice={`IPSUM`}
          //   />
          // }
        />
      </div>

      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Open Fee"}
          text={`${localStringer(openFeeInPercentage)} %`}
          containerClassName={classes.titleAndValueContainer}
          // tooltipContent={
          //   <InPracticeTooltipContent
          //     definition={"The yearly interest paid for open interest"}
          //     inPractice={`All open interest pays ${localStringer(
          //       borrowAprInPercentages,
          //       3,
          //     )}% yearly`}
          //   />
          // }
        />

        <ConnectedTitleAndValue
          title={"Close Fee"}
          text={`${localStringer(closeFeeInPercentage)} %`}
          containerClassName={classes.titleAndValueContainer}
          // tooltipContent={
          //   <InPracticeTooltipContent
          //     definition={"Lorem"}
          //     inPractice={`IPSUM`}
          //   />
          // }
        />
      </div>

      <div className={classes.infoItemsContainer}>
        <ConnectedTitleAndValue
          title={"Open Interest Short"}
          text={`${localStringer(openInterestShortInUnits, 3)}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={"The open interest on the Short side"}
              inPractice={`The size of all short positions in this pair is ${localStringer(
                openInterestShortInUnits,
                3,
              )} ${underlyingSymbol} tokens`}
            />
          }
        />

        <ConnectedTitleAndValue
          title={"Open Interest Long"}
          text={`${localStringer(openInterestLongInUnits, 3)}`}
          containerClassName={classes.titleAndValueContainer}
          tooltipContent={
            <InPracticeTooltipContent
              definition={"The open interest on the Long side"}
              inPractice={`The size of all long positions in this pair is ${localStringer(
                openInterestLongInUnits,
                3,
              )} ${underlyingSymbol} tokens`}
            />
          }
        />
      </div>
    </div>
  );
});
