import {
  getMessagesBySrcTxHash,
  MessageStatus as LZScannerMessageStatus,
} from "@layerzerolabs/scan-client";
import {
  dismissToast,
  toastError,
  toastInfo,
} from "../../../ux/toasts/toasting.ts";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect } from "react";
import useInterval from "react-useinterval";
import { localStringer } from "../../../ux/uxFunctions.ts";
import {
  LAYER_ZERO_ENDPOINT_IDS,
  TLiveChainIds,
} from "../../../constants/chainConstants.ts";
import { chipLiquidityToast } from "../../../components/Modals/ChipLiquidityModal/ChipLiquidityModal.tsx";
import { toast_step } from "../../../ux/stepToast/statusToastManager.tsx";

export type TPendingLZBridgeTxGist = {
  lexId: string;

  sourceChainEid: number;

  txHash: string;

  assetAmountInUnits: number;
  assetSymbol: string;

  actionName: string;
  displayMessage: string;
};

const EMPTY_PENDING_LZ_BRIDGE_TX_GIST: TPendingLZBridgeTxGist = {
  lexId: "",

  sourceChainEid: 0,

  txHash: "",

  assetAmountInUnits: 0,
  assetSymbol: "",

  actionName: "",
  displayMessage: "",
};

export function usePersistSingleLZBridgeTx(walletAddress: string) {
  const [pendingBridgeTxGist, setPendingBridgeTxGist] = useLocalStorage<
    TPendingLZBridgeTxGist[]
  >(`pendingBridgeTxHashes_${walletAddress}`, []);

  return {
    pendingBridgeTxGist,
    setPendingBridgeTxGist,
  };
}

//TODO : C.F.H : Add more info to the key and display the pending bridging in the app
export function usePersistSingleLZBridgeTxAndReactToChanges(
  walletAddress: string,
) {
  const { setPendingBridgeTxGist, pendingBridgeTxGist } =
    usePersistSingleLZBridgeTx(walletAddress);
  const currentTxGist = pendingBridgeTxGist[pendingBridgeTxGist.length - 1];

  useInterval(
    () => {
      console.log(`In Interval !`);
      void reactToLZBridgeProcess(
        currentTxGist?.sourceChainEid,
        currentTxGist?.txHash,
        // `${localStringer(pendingBridgeTxGist.assetAmountInUnits, 3)} ${
        //   pendingBridgeTxGist.assetSymbol
        // } `,
        currentTxGist?.displayMessage,
        currentTxGist.lexId,
        (txHash?: string) => {
          const getPendingBridgeTxGist = [...pendingBridgeTxGist].filter(
            (pending) => pending.txHash !== txHash,
          );
          setPendingBridgeTxGist(getPendingBridgeTxGist);
        },
      );
    },
    currentTxGist?.txHash ? 3000 : null,
    [
      currentTxGist?.sourceChainEid,
      currentTxGist?.txHash,
      setPendingBridgeTxGist,
    ],
  );

  // useEffect(() => {
  //   if (pendingBridgeTxGist.txHash) {
  //     void reactToLZBridgeProcess(
  //       pendingBridgeTxGist.sourceChainEid,
  //       pendingBridgeTxGist.txHash,
  //       () => {
  //         setPendingBridgeTxGist(EMPTY_PENDING_LZ_BRIDGE_TX_GIST);
  //       },
  //     );
  //   }
  // }, [
  //   pendingBridgeTxGist.sourceChainEid,
  //   pendingBridgeTxGist.txHash,
  //   setPendingBridgeTxGist,
  // ]);

  return {
    pendingBridgeTxGist,
    setPendingBridgeTxGist,
  };
}

// TODO : CRITICAL : Add support for states that are not in the library enum (such as 'CONFIRMING')
export async function reactToLZBridgeProcess(
  sourceChainId: number,
  pendingBridgeTxHash: string,
  bridgeMessage: string,
  lexId: string,
  onBridgeProcessFinished: (txhash?: string) => void,
) {
  const matchingLzV1Eid =
    (LAYER_ZERO_ENDPOINT_IDS[sourceChainId as TLiveChainIds] ?? 30_000) -
    30_000;

  const response = await getMessagesBySrcTxHash(
    // sourceChainId,
    matchingLzV1Eid,
    pendingBridgeTxHash,
  );

  const onGoingToastId = "lzBridgeInflight";
  const singleToastId = "lzBridgeSingle";

  const lastMessage = response.messages[0];

  if (lastMessage) {
    if (lastMessage.status === LZScannerMessageStatus.INFLIGHT) {
      chipLiquidityToast.updateToast(lexId, toast_step.processing);
    } else {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (lastMessage.status === "CONFIRMING") {
        chipLiquidityToast.updateToast(lexId, toast_step.processing);
      } else if (lastMessage.status === LZScannerMessageStatus.DELIVERED) {
        chipLiquidityToast.updateToast(lexId, toast_step.success);
        onBridgeProcessFinished(pendingBridgeTxHash);
      } else if (lastMessage.status === LZScannerMessageStatus.FAILED) {
        chipLiquidityToast.updateToast(lexId, chipLiquidityToast.getStep(lexId), `LZ Bridging for ${bridgeMessage} Failed ${lastMessage.dstTxError}`);
        onBridgeProcessFinished(pendingBridgeTxHash);
      } else {
         // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        chipLiquidityToast.updateToast(lexId, chipLiquidityToast.getStep(lexId), `Unknown LZ status of ${lastMessage.status} for bridging of ${bridgeMessage}`);
        onBridgeProcessFinished(pendingBridgeTxHash);
      }
    }
  } else {
    console.warn(
      `No Scanner response for bridge tx ${pendingBridgeTxHash} from ${sourceChainId} ("${bridgeMessage}") -- will delete it`,
    );

    // if (singeltonTimeou_isAvailable) {
    // singeltonTimeou_isAvailable = false;
    // console.warn(`Setting auto - delete timeout for pendingBridgeTx`);
    // setTimeout(
    //   () => {
    //     console.log(
    //       `^^^^ Manually removing pending bridge as no response came`,
    //     );
    //     onBridgeProcessFinished();
    //     dismissToast(onGoingToastId);
    //     singeltonTimeou_isAvailable = true;
    //   },
    //   60 * 60 * 2,
    // );
    // }
  }
}

// NOTE : Quick-n-Dirty
// let singeltonTimeou_isAvailable = true;
