import axios from "axios";
import { EmbedEntry } from "./discordTypes.ts";

export async function notifyDiscordServer(
  webhookUrl: string,
  content: string,
  embeds: EmbedEntry[],
  userName: string,
  logName?: string,
  catchErrors = false,
) {
  // Note : Quick-n-Dirty fix to limit the embeds size
  embeds = embeds.map((embed) => {
    return {
      ...embed,
      description: embed.description
        ? embed.description?.substring(0, 1900)
        : "",
    };
  });

  try {
    // Note : Quick-n-Dirty fix to limit the content size
    const discordMessageContent = {
      content: content.substring(0, 1900),
      embeds,
      username: userName,
    };
    console.info(
      `Sending to discord. url: ${webhookUrl}, data: ${JSON.stringify(
        discordMessageContent,
      )}`,
    );
    await axios.post(webhookUrl, discordMessageContent, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.error(`Could not notify discord: ${logName}`);
    console.error(`Error: ${(e as { message: string }).message}`);

    // NOTE : This was added to alert us in cases where the discord notification
    // fails due to things like max message size
    await axios.post(
      webhookUrl,
      {
        content: `Could not notify discord: ${logName}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!catchErrors) {
      throw e;
    }
  }
}
