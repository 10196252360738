import {
  ILeverageDimensionParameters,
  ILeverageDimensionsService,
} from "./ILeverageDiomensionsService";
import { LeverageDimensionsParams } from "./leveregeDimensionsParams";

export class LeverageDimensionsService implements ILeverageDimensionsService {
  fetchAllLeverageDimensionsParameters(): ILeverageDimensionParameters[] {
    const ledParams: ILeverageDimensionParameters[] = [];

    ledParams.push(...LeverageDimensionsParams);

    return ledParams;
  }
}
