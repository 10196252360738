import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import { useStructureClasses } from "../../theme/commonClasses.ts";
import { Box, Stack, Typography } from "@mui/material";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
  useModalsStore,
} from "../../store/storeHooks.ts";
import {
  TPendingLZBridgeTxGist,
  usePersistSingleLZBridgeTx,
  usePersistSingleLZBridgeTxAndReactToChanges,
} from "./pageHooks/chipsPageHooks.ts";
import {
  ChipsInfoTable,
  TChipHolderInfoGist,
} from "./subComponent/ChipsInfoTable/ChpisInfoTable.tsx";
import {
  PortfolioFilters,
  TabSelectorGist,
  TabsSelectorRow,
} from "lynx-ui-components";
import {
  ChipsHistoryTable,
  TChipHistoryTableRowGist,
} from "./subComponent/ChipsHistoryTable/ChipsHistoryTable.tsx";
import { unitsBnToFloat } from "../../utils/bignumbers.ts";
import { StringParam, useQueryParam } from "use-query-params";
import { useRequestWalletConnect } from "../../hooks/walletConnectionHooks.ts";
import { useAccount, useSwitchChain } from "wagmi";
import {
  LIVE_CHAIN_CONSTANTS,
  TChainIds,
} from "../../constants/chainConstants.ts";
import { useWhiteLabelIdentifier } from "../../whitelabels/whiteLabelHooks.ts";
import { useLoadingBridgeTx } from "../../store/loadingBridgeTxContext.tsx";

interface IProps {}

const useStyles = makeStyles()((theme) => ({}));

const networksSelectPlaceholder = "Networks";
const tokensSelectPlaceholder = "Tokens";

export const PortfolioPage: React.FC = observer(() => {
  const { classes: pageClasses } = useStructureClasses();

  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const modalsStore = useModalsStore();
  const { setLoadingBridgeTxGists } = useLoadingBridgeTx();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabSelectors = useMemo<TabSelectorGist[]>(() => {
    return [
      {
        text: `Balances`,
        value: 0,
      },
      {
        text: `History`,
        value: 1,
      },
    ];
  }, []);

  const leverageDimensionsStore = useLeverageDimensionsStore();

  const [queryShowDeposit, setQueryPairSettlementAsset] = useQueryParam(
    "showDeposit",
    StringParam,
  );
  const shouldShowChipInModalOnStart = queryShowDeposit == "true";

  useEffect(() => {
    if (
      shouldShowChipInModalOnStart &&
      leverageDimensionsStore.activeLeverageDimensionId
    ) {
      modalsStore.showChipInModal(
        leverageDimensionsStore.activeLeverageDimensionId,
      );
    }
  }, [
    leverageDimensionsStore.activeLeverageDimensionId,
    modalsStore,
    shouldShowChipInModalOnStart,
  ]);

  const { pendingBridgeTxGist } = usePersistSingleLZBridgeTx(
    walletConnectionStore.mainAddress,
  );

  // NOTE : Quick-n-Dirty to force update of info when a bridge request is set/removed
  // TODO : Change the mechanism to support multiple bridge tx and proper 'onFinish' hooks
  const previousPendingBridgeTxGist = useRef<TPendingLZBridgeTxGist[]>([]);
  const [lexIdToUpdateOnBridgTxDone, setLexIdToUpdateOnBridgTxDone] = useState<
    string[]
  >([]);

  useEffect(() => {
    // get previous and current state of pendingBridgeTxGist
    const prevTxGists = previousPendingBridgeTxGist.current;
    const currentTxGists = pendingBridgeTxGist;

    // update ref to the current state for the next render
    previousPendingBridgeTxGist.current = pendingBridgeTxGist;

    // identify removed elements by comparing previous and current arrays
    const removedTxGists = prevTxGists.filter(
      (prevTxGist) =>
        !currentTxGists.some(
          (currentTxGist) => currentTxGist.lexId === prevTxGist.lexId,
        ),
    );

    if (removedTxGists.length > 0) {
      const removedLexIds = removedTxGists.map((txGist) => txGist.lexId);
      setLexIdToUpdateOnBridgTxDone(removedLexIds);
    } else if (lexIdToUpdateOnBridgTxDone.length > 0) {
      lexIdToUpdateOnBridgTxDone.forEach((lexId) => {
        const lexStore = leverageDimensionsStore.lexStores.find(
          (lexStore) => lexStore.id === lexId,
        );
        setLoadingBridgeTxGists(lexId, false);
        if (lexStore) {
          console.log(
            `Refreshing data after bridge tx done for lex ${lexStore.id}`,
          );
          void lexStore.refreshDataAfterTx();
        }
      });
      setLexIdToUpdateOnBridgTxDone([]);
    }
  }, [
    leverageDimensionsStore.lexStores,
    lexIdToUpdateOnBridgTxDone,
    pendingBridgeTxGist,
    setLoadingBridgeTxGists,
  ]);

  const chipHolderInfoGists = leverageDimensionsStore.lexStores
    .map((lexStore) => {
      const chipHolderInfoGist: TChipHolderInfoGist = {
        lexId: lexStore.id,
        balanceAvailableOnEngineInUnits:
          lexStore.activeUserStore
            .accountUnderlyingBalanceInWalletOnEngineChainInUnits,
        balanceInPositionsInUnits:
          lexStore.activeUserStore
            .accountUnderlyingBalanceInOpenPositionsInUnits,
        balanceInPoolInUnits: lexStore.activeUserStore.accountSupplyInUnits,
        sourceChainId: lexStore.sourceChainId,
        sourceUnderlyingSymbol: lexStore.sourceAssetParameters.symbol,
      };

      return chipHolderInfoGist;
    })
    .sort((a, b) => {
      // Start with sort by connected chain
      if (
        b.sourceChainId == walletConnectionStore.chainId &&
        a.sourceChainId != walletConnectionStore.chainId
      ) {
        return 1;
      } else if (
        a.sourceChainId == walletConnectionStore.chainId &&
        b.sourceChainId != walletConnectionStore.chainId
      ) {
        return -1;
      } else {
        const bSum =
          b.balanceAvailableOnEngineInUnits +
          b.balanceInPositionsInUnits +
          b.balanceInPoolInUnits;
        const aSum =
          a.balanceAvailableOnEngineInUnits +
          a.balanceInPositionsInUnits +
          a.balanceInPoolInUnits;

        return bSum - aSum;
      }
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chipHistoricActionsGists: TChipHistoryTableRowGist[] = [];

  for (const lexStore of leverageDimensionsStore.allLexStoresInActiveChain) {
    for (const historicChipActionGist of lexStore.activeUserStore
      .account_chipHistoryActions) {
      const chipHistoricActionsGist: TChipHistoryTableRowGist = {
        engineChainId: lexStore.engineChainId,
        sourceChainId: lexStore.sourceChainId,
        sourceUnderlyingSymbol: lexStore.sourceAssetParameters.symbol,
        action: historicChipActionGist.inOut == "IN" ? "Deposit" : "Withdraw",
        amountInUnits: unitsBnToFloat(
          historicChipActionGist.amount,
          lexStore.chipAssetParams.decimals,
        ),
        timestamp: historicChipActionGist.timestamp,
        relevantTxHash: historicChipActionGist.txHash,
      };

      chipHistoricActionsGists.push(chipHistoricActionsGist);
    }
  }

  chipHistoricActionsGists.sort((a, b) => b.timestamp - a.timestamp);

  const requestConnectToWallet = useRequestWalletConnect();
  const { switchChain } = useSwitchChain();
  const { chainId: walletChainId } = useAccount();

  const connectedChainId = walletChainId;

  const [serachKeyword, setSearchKeyWord] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState(
    networksSelectPlaceholder,
  );
  const [selectedToken, setSelectedToken] = useState(tokensSelectPlaceholder);

  const onSearch = useCallback((keyword: string) => {
    setSearchKeyWord(keyword);
  }, []);

  const filteredchipHolderInfoGists = useMemo(() => {
    let filteredGists = chipHolderInfoGists;

    // Filter by network if needed
    const isFilteringByNetwork =
      !!selectedNetwork && selectedNetwork !== networksSelectPlaceholder;
    if (isFilteringByNetwork) {
      const n = Object.values(LIVE_CHAIN_CONSTANTS)
        .filter((ns) => ns.shortDisplayName === selectedNetwork)
        .map((value) => value.id);
      filteredGists = filteredGists.filter((chipdata) =>
        n.includes(chipdata.sourceChainId),
      );
    }

    // Filtered by token if needed
    const isFilteringByToken =
      !!selectedToken && selectedToken !== tokensSelectPlaceholder;
    if (isFilteringByToken) {
      filteredGists = filteredGists.filter(
        (chipdata) => chipdata.sourceUnderlyingSymbol === selectedToken,
      );
    }

    // NOTE : IF filtering by both network and token, no need for further filtering   m
    if (isFilteringByNetwork && isFilteringByToken) {
      return filteredGists;
    } else {
      const filteredData: number[] = Object.values(LIVE_CHAIN_CONSTANTS)
        .filter(
          (value) =>
            value.shortDisplayName.toLowerCase().includes(serachKeyword) ||
            serachKeyword.includes(value.shortDisplayName.toLowerCase()),
        )
        .map((value) => value.id);

      filteredGists = filteredGists.filter(
        (chipdata) =>
          filteredData.includes(chipdata.sourceChainId) ||
          chipdata.sourceUnderlyingSymbol
            .toLowerCase()
            .includes(serachKeyword) ||
          serachKeyword.includes(chipdata.sourceUnderlyingSymbol.toLowerCase()),
      );

      return filteredGists;
    }
  }, [chipHolderInfoGists, selectedNetwork, selectedToken, serachKeyword]);

  const networkOptions = useMemo(() => {
    const filteredData: string[] = Object.values(LIVE_CHAIN_CONSTANTS).map(
      (el) => el.shortDisplayName,
    );
    return [networksSelectPlaceholder, ...filteredData];
  }, []);

  const tokenOptions = useMemo(() => {
    const filteredData: string[] =
      selectedNetwork && selectedNetwork !== networksSelectPlaceholder
        ? chipHolderInfoGists
            .filter(
              (el) =>
                selectedNetwork ===
                LIVE_CHAIN_CONSTANTS[el.sourceChainId].shortDisplayName,
            )
            .map((el) => el.sourceUnderlyingSymbol)
        : chipHolderInfoGists.map((el) => el.sourceUnderlyingSymbol);
    return [tokensSelectPlaceholder, ...filteredData];
  }, [chipHolderInfoGists, selectedNetwork]);

  const content = useMemo(() => {
    if (selectedTab == 0) {
      return (
        <ChipsInfoTable
          isConnectedToWallet={walletConnectionStore.isConnectedToWallet}
          chipHolderInfoGists={filteredchipHolderInfoGists}
          showChipIn={(lexId) => modalsStore.showChipInModal(lexId)}
          showChipOut={(lexId) => modalsStore.showChipOutModal(lexId)}
          pendingBridgeTxGist={pendingBridgeTxGist}
          connectedChainId={connectedChainId as TChainIds}
          requestConnectToWalletFunction={requestConnectToWallet}
          switchChainFunction={(chainId) => switchChain({ chainId })}
        />
      );
    } else if (selectedTab == 1) {
      return (
        <ChipsHistoryTable chipActionHistoryGists={chipHistoricActionsGists} />
      );
    }
  }, [
    selectedTab,
    walletConnectionStore.isConnectedToWallet,
    filteredchipHolderInfoGists,
    pendingBridgeTxGist,
    connectedChainId,
    requestConnectToWallet,
    modalsStore,
    switchChain,
    chipHistoricActionsGists,
  ]);

  const { isOnAnyWhiteLabel } = useWhiteLabelIdentifier();

  return (
    <div className={pageClasses.pageRoot}>
      {/* Page content*/}
      <Box className={pageClasses.pageContent_column}>
        <Stack
          direction={"column"}
          style={{
            width: "100%",
            // paddingTop: "2rem",
          }}
        >
          <Typography
            variant={"h3"}
            fontWeight={"bold"}
            style={{ alignSelf: "start" }}
          >
            Your {isOnAnyWhiteLabel ? "" : "Lynx"} Account
          </Typography>
          <Typography variant={"h6"}>
            You must deposit funds into your {isOnAnyWhiteLabel ? "" : "Lynx"}{" "}
            account before you can start using the app{" "}
            <a
              href="https://lynx-finance.gitbook.io/lynx-finance/for-users/funding-your-account"
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              (Learn more)
            </a>
          </Typography>
        </Stack>
        <TabsSelectorRow
          isLabelCentered
          tabSelectors={tabSelectors}
          value={selectedTab}
          onChange={(val) => setSelectedTab(val)}
        />
        <Box mt={3}>
          <PortfolioFilters
            networksOptions={networkOptions}
            onNetworksFilter={setSelectedNetwork}
            networksFilter={selectedNetwork}
            tokensOptions={tokenOptions}
            onTokensFilter={setSelectedToken}
            tokensFilter={selectedToken}
            onSearchValue={onSearch}
            searchValue={serachKeyword}
          />
        </Box>
        <Box mt={1}>{content}</Box>
      </Box>
    </div>
  );
});

export default PortfolioPage as (props: IProps) => React.JSX.Element;
