import { Signer, Provider } from "ethers";
import {
  ILexLensService,
  TLensCompleteLexConfigsStruct,
  TLensCompleteLexStateStruct,
  TLexFeeConfigsLensStruct,
  TLexGroupConfigsLensStruct,
  TLexPairConfigsLensStruct,
  TLexPairStateLensStruct,
  TLexPoolLensConfigsStruct,
  TLexPoolLensParamsStruct,
  TLexPoolLensStateStruct,
  TLexPoolSupplierLensStateStruct,
  TPendingEpochDepositLensStruct,
  TPendingEpochRedeemLensStruct,
  TPoolAccountantConfigurationsLensStruct,
  TPoolAccountantStateLensStruct,
} from "./ILexLensService";
import {
  ethersStructResponseToArray,
  ethersStructResponseToObject,
} from "../../../utils/ethersTypes";
import { LexLens } from "../../../typechain/contracts/Peripheral/Lens/LexLens.ts";
import { LexLens__factory } from "../../../typechain/factories/contracts/Peripheral/Lens/LexLens__factory.ts";
import { FEE_CONFIGS_IDS } from "../../../constants/feesConstants.ts";
import { LexPoolV1__factory } from "../../../typechain/factories/contracts/Lynx/Lex/LexPool/LexPoolV1__factory.ts";
import { PoolAccountantV1__factory } from "../../../typechain/factories/contracts/Lynx/Lex/PoolAccountant/PoolAccountantV1__factory.ts";
import { TGroupConfigs } from "../../../constants/liveConstants.ts";

export class LexLensService implements ILexLensService {
  private leverageVaultLensContract: LexLens;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.leverageVaultLensContract = LexLens__factory.connect(
      address,
      signerOrProvider,
    );
  }

  async getCompleteStateForLexes(
    poolsAddresses: string[],
  ): Promise<TLensCompleteLexStateStruct[]> {
    const rawRes =
      await this.leverageVaultLensContract.getCompleteStateForLexes(
        poolsAddresses,
      );

    const final = rawRes.map((raw) =>
      ethersStructResponseToObject<TLensCompleteLexStateStruct>(
        raw,
        EMPTY_COMPLETE_LEX_STATE,
        {
          pairsStates: EMPTY_LEX_PAIR_STATE_LENS,
        },
        {
          lexPoolState: EMPTY_LEX_POOL_LENS_STATE,
          poolAccountantState: EMPTY_POOL_ACCOUNTANT_STATE_LENS,
        },
      ),
    );

    return final;
  }

  async getCompleteStateForLex(
    poolsAddress: string,
  ): Promise<TLensCompleteLexStateStruct> {
    return ethersStructResponseToObject<TLensCompleteLexStateStruct>(
      await this.leverageVaultLensContract.getCompleteStateForLex(poolsAddress),
      EMPTY_COMPLETE_LEX_STATE,
    );
  }

  async getCompleteConfigsForLexes(
    poolsAddresses: string[],
  ): Promise<TLensCompleteLexConfigsStruct[]> {
    const rawRes =
      await this.leverageVaultLensContract.getCompleteConfigsForLexes(
        poolsAddresses,
      );

    console.log(
      `rawRes[0].lexPoolParams.lexToken : ${rawRes[0].lexPoolParams.lexToken}`,
    );

    const final = rawRes.map((raw) =>
      ethersStructResponseToObject<TLensCompleteLexConfigsStruct>(
        raw,
        EMPTY_COMPLETE_LEX_CONFIGS,
        {
          groupsConfigs: EMPTY_GROUP_CONFIGS_LENS,
          pairsConfigs: EMPTY_PAIR_CONFIGS_LENS,
          feesConfigs: EMPTY_FEE_CONFIGS_LENS,
        },
        {
          lexPoolParams: EMPTY_LEX_POOL_LENS_PARAMS,
          lexPoolConfigurations: EMPTY_LEX_POOL_LENS_CONFIGS,
          poolAccountantConfigurations:
            EMPTY_POOL_ACCOUNTANT_CONFIGURATIONS_LENS,
        },
      ),
    );

    // Sanitizing Groups Configs
    final.forEach(
      (lexConfigs) =>
        (lexConfigs.groupsConfigs = lexConfigs.groupsConfigs.map(
          (rawConfig) => {
            const groupConfig: TLexGroupConfigsLensStruct = {
              groupId: Number(rawConfig.groupId),
              maxBorrowF: Number(rawConfig.maxBorrowF),
              maxLeverage: rawConfig.maxLeverage,
              maxPositionSize: rawConfig.maxPositionSize,
              minLeverage: rawConfig.minLeverage,
            };

            return groupConfig;
          },
        )),
    );

    final.forEach(
      (lexConfigs) =>
        (lexConfigs.pairsConfigs = lexConfigs.pairsConfigs.map((rawConfig) => {
          const pairConfig: TLexPairConfigsLensStruct = {
            pairId: Number(rawConfig.pairId),
            feeId: Number(rawConfig.feeId),
            groupId: Number(rawConfig.groupId),
            maxBorrowF: rawConfig.maxBorrowF,
            maxGain: rawConfig.maxGain,
            maxLeverage: rawConfig.maxLeverage,
            maxOpenInterest: rawConfig.maxOpenInterest,
            maxPositionSize: rawConfig.maxPositionSize,
            maxSkew: rawConfig.maxSkew,
            minLeverage: rawConfig.minLeverage,
          };

          return pairConfig;
        })),
    );

    // Sanitizing Fees Configs
    final.forEach(
      (lexConfigs) =>
        (lexConfigs.feesConfigs = lexConfigs.feesConfigs.map((rawConfig) => {
          const feeConfig: TLexFeeConfigsLensStruct = {
            feeId: Number(rawConfig.feeId),
            openFeeF: Number(rawConfig.openFeeF),
            closeFeeF: Number(rawConfig.closeFeeF),
          };

          return feeConfig;
        })),
    );

    return final;
  }

  async getCompleteConfigsForLex(
    poolsAddress: string,
  ): Promise<TLensCompleteLexConfigsStruct> {
    return ethersStructResponseToObject<TLensCompleteLexConfigsStruct>(
      await this.leverageVaultLensContract.getCompleteConfigsForLex(
        poolsAddress,
      ),
      EMPTY_COMPLETE_LEX_CONFIGS,
    );
  }

  async getLexPoolParams(lvAddress: string): Promise<TLexPoolLensParamsStruct> {
    return ethersStructResponseToObject(
      await this.leverageVaultLensContract.getLexPoolParams(lvAddress),
      EMPTY_LEX_POOL_LENS_PARAMS,
    );
  }

  async getLexPoolState(lvAddress: string): Promise<TLexPoolLensStateStruct> {
    return ethersStructResponseToObject(
      await this.leverageVaultLensContract.getLexPoolState(lvAddress),
      EMPTY_LEX_POOL_LENS_STATE,
    );
  }

  async getLexPoolStateAll(
    lexPoolAddresses: string[],
  ): Promise<TLexPoolLensStateStruct[]> {
    return ethersStructResponseToArray<TLexPoolLensStateStruct>(
      await this.leverageVaultLensContract.getLexPoolsStateAll.staticCall(
        lexPoolAddresses,
      ),
      EMPTY_LEX_POOL_LENS_STATE,
    );
  }

  async getLexPoolConfigs(
    lvAddress: string,
  ): Promise<TLexPoolLensConfigsStruct> {
    return ethersStructResponseToObject(
      await this.leverageVaultLensContract.getLexPoolConfigs(lvAddress),
      EMPTY_LEX_POOL_LENS_CONFIGS,
    );
  }

  async getAllPairsConfigsInLex(
    lx: string,
  ): Promise<TLexPairConfigsLensStruct[]> {
    return ethersStructResponseToArray<TLexPairConfigsLensStruct>(
      await this.leverageVaultLensContract.getAllPairConfigurationsInLex.staticCall(
        lx,
      ),
      EMPTY_PAIR_CONFIGS_LENS,
    );
  }

  // TODO : CRITICAL : After redeploying, use the proper lens function
  async getAllFeesConfigsInLex(
    lx: string,
  ): Promise<TLexFeeConfigsLensStruct[]> {
    const knownFeeIds = [FEE_CONFIGS_IDS.BASIC_CRYPTO_PAIR];

    const feesConfigurations: TLexFeeConfigsLensStruct[] = [];

    const poolAccountantAddress = await LexPoolV1__factory.connect(
      lx,
      this.leverageVaultLensContract.runner,
    ).poolAccountant();

    const poolAccountantInstance = PoolAccountantV1__factory.connect(
      poolAccountantAddress,
      this.leverageVaultLensContract.runner,
    );

    for (const feeId of knownFeeIds) {
      const rawFeeStruct = await poolAccountantInstance.fees(feeId);

      const feeConfig: TLexFeeConfigsLensStruct = {
        feeId,
        openFeeF: Number(rawFeeStruct.openFeeF),
        closeFeeF: Number(rawFeeStruct.closeFeeF),
      };

      feesConfigurations.push(feeConfig);
    }

    return feesConfigurations;
  }

  async getAllPairsStateInLex(lx: string): Promise<TLexPairStateLensStruct[]> {
    return ethersStructResponseToArray<TLexPairStateLensStruct>(
      await this.leverageVaultLensContract.getAllPairStateInLex(lx),
      EMPTY_LEX_PAIR_STATE_LENS,
    );
  }

  async getLexPoolSupplierState(
    poolAddress: string,
    supplier: string,
  ): Promise<TLexPoolSupplierLensStateStruct> {
    const rawRes = await this.leverageVaultLensContract.getLexPoolSupplierState(
      poolAddress,
      supplier,
    );
    return ethersStructResponseToObject(
      rawRes,
      EMPTY_LEX_POOL_SUPPLIER_LENS_STATE,
      {
        pendingEpochDeposits: EMPTY_PENDING_EPOCH_DEPOSIT,
        pendingEpochRedeems: EMPTY_PENDING_EPOCH_REDEEM,
      },
    );
  }

  async getPoolAccountantStateByPool(
    poolAddress: string,
  ): Promise<TPoolAccountantStateLensStruct> {
    return ethersStructResponseToObject<TPoolAccountantStateLensStruct>(
      await this.leverageVaultLensContract.getPoolAccountantStateByPool(
        poolAddress,
      ),
      EMPTY_POOL_ACCOUNTANT_STATE_LENS,
    );
  }

  async getPoolAccountantConfigurationsByPool(
    poolAddress: string,
  ): Promise<TPoolAccountantConfigurationsLensStruct> {
    const res =
      await this.leverageVaultLensContract.getPoolAccountantConfigurationsByPool(
        poolAddress,
      );
    return ethersStructResponseToObject<TPoolAccountantConfigurationsLensStruct>(
      res,
      EMPTY_POOL_ACCOUNTANT_CONFIGURATIONS_LENS,
    );
  }
}

const EMPTY_LEX_POOL_LENS_PARAMS: TLexPoolLensParamsStruct = {
  lexToken: "",
  name: "",
  symbol: "",
  underlying: "",
};

const EMPTY_LEX_POOL_LENS_STATE: TLexPoolLensStateStruct = {
  currentVirtualUtilization: 0n,
  nextEpochStartMin: 0n,
  poolBalance: 0n,
  totalPendingDeposits: 0n,
  totalPendingWithdrawals: 0n,
  totalSupply: 0n,
  virtualBalanceForUtilization: 0n,
  lexPool: "",
  cash: 0n,
  connectedToCenter: false,
  currentEpochNumber: 0n,
  currentExchangeRate: 0n,
};

const EMPTY_LEX_POOL_LENS_CONFIGS: TLexPoolLensConfigsStruct = {
  lexPool: "",
  epochLength: 0n,
  minDepositAmount: 0n,
  epochsDelayDeposit: 0n,
  epochsDelayRedeem: 0n,
  immediateDepositAllowed: false,
};

const EMPTY_PAIR_CONFIGS_LENS: TLexPairConfigsLensStruct = {
  feeId: 0,
  groupId: 0,
  maxBorrowF: 0n,
  maxGain: 0n,
  maxLeverage: 0n,
  maxOpenInterest: 0n,
  maxPositionSize: 0n,
  maxSkew: 0n,
  minLeverage: 0n,
  pairId: 0,
};

const EMPTY_FEE_CONFIGS_LENS: TLexFeeConfigsLensStruct = {
  feeId: 0,
  openFeeF: 0,
  closeFeeF: 0,
};

const EMPTY_GROUP_CONFIGS_LENS: TLexGroupConfigsLensStruct = {
  groupId: 0,
  maxBorrowF: 0,
  maxLeverage: 0n,
  maxPositionSize: 0n,
  minLeverage: 0n,
};

const EMPTY_LEX_PAIR_STATE_LENS: TLexPairStateLensStruct = {
  fundingRate: 0n,
  lexPool: "",
  openInterestLong: 0n,
  openInterestShort: 0n,
  pairId: 0,
};

const EMPTY_LEX_POOL_SUPPLIER_LENS_STATE: TLexPoolSupplierLensStateStruct = {
  allowanceForLexPool: 0n,
  lexPool: "",
  lxpBalance: 0n,
  lxpBalanceInUnderlying: 0n,
  pendingEpochDeposits: [],
  pendingEpochRedeems: [],
  supplier: "",
  underlyingBalance: 0n,
};

const EMPTY_POOL_ACCOUNTANT_STATE_LENS: TPoolAccountantStateLensStruct = {
  accountant: "",
  borrowRatePerSecond: 0n,
  interestShare: 0n,
  matchingPool: "",
  totalBorrows: 0n,
  unrealizedFunding: 0n,
};

const EMPTY_POOL_ACCOUNTANT_CONFIGURATIONS_LENS: TPoolAccountantConfigurationsLensStruct =
  {
    accountant: "",
    fundingRateModel: "",
    interestRateModel: "",
    interestShareFactor: 0n,
    matchingPool: "",
    minOpenFee: 0n,
    lexPartF: 0n,
    liquidationThresholdF: 0n,
    liquidationFeeF: 0n,
    supportedPairIds: [],
  };

const EMPTY_COMPLETE_LEX_STATE: TLensCompleteLexStateStruct = {
  lexPoolState: EMPTY_LEX_POOL_LENS_STATE,
  poolAccountantState: EMPTY_POOL_ACCOUNTANT_STATE_LENS,
  pairsStates: [],
};

const EMPTY_COMPLETE_LEX_CONFIGS: TLensCompleteLexConfigsStruct = {
  lexPoolParams: EMPTY_LEX_POOL_LENS_PARAMS,
  lexPoolConfigurations: EMPTY_LEX_POOL_LENS_CONFIGS,
  poolAccountantConfigurations: EMPTY_POOL_ACCOUNTANT_CONFIGURATIONS_LENS,
  groupsConfigs: [],
  pairsConfigs: [],
  feesConfigs: [],
};

export const EMPTY_PENDING_EPOCH_DEPOSIT: TPendingEpochDepositLensStruct = {
  account: "",
  amount: 0n,
  epochNumber: 0n,
  minAmountOut: 0n,
};

export const EMPTY_PENDING_EPOCH_REDEEM: TPendingEpochRedeemLensStruct = {
  account: "",
  amount: 0n,
  epochNumber: 0n,
  maxAmountOut: 0n,
  minAmountOut: 0n,
};
