export type TPossibleIncentivePlanDetails = TCompetitionPlanDetails;

export type TIncentivesPlanDescription<T = TPossibleIncentivePlanDetails> = {
  lexAddress: string;
  startTimestamp: number;
  endTimestamp: number;
  roundNumber: number;
  planCode: string;
  details: T;
};

export type TCompetitionPlanSingleRewardDetails = {
  ranks: number[];
  rewardSymbol: string;
};

export type TCompetitionPlanDetails = {
  incentivesList: TCompetitionPlanSingleRewardDetails[];
};

export type TCompetitionPlanIncentiveDescription =
  TIncentivesPlanDescription<TCompetitionPlanDetails>;

export const INCENTIVE_PLAN_TYPE_KEYS = {
  topComp: "top_comp",
};

export type TCompetitionTraderDistributionInfo = {
  rewardSymbol: string;
  amount: number;
};

export type TCompetitionRoundTraderInfo = {
  traderAddress: string;
  positionsCount: number;
  rank: number;
  totalCloseFeesPaid: bigint;
  totalNegativePnl: bigint;
  totalOpenFeesPaid: bigint;
  totalPositivePnl: bigint;
  totalPnl: bigint;
  distributed: TCompetitionTraderDistributionInfo[];
};

export interface IIncentivesService {
  getAllLiveIncentivePlans(): Promise<{
    competitions: TCompetitionPlanIncentiveDescription[];
  }>;
  getAllPastIncentivePlans(): Promise<{
    competitions: TCompetitionPlanIncentiveDescription[];
  }>;

  getPastCompetitionResults(
    lexAddress: string,
    roundNumber: number,
  ): Promise<TCompetitionRoundTraderInfo[]>;

  getLiveCompetitionTradersInfo(
    lexAddress: string,
    roundNumber: number,
  ): Promise<TCompetitionRoundTraderInfo[]>;
}

export const EMPTY_COMPETITION_PLAN_SINGLE_DETAILS: TCompetitionPlanSingleRewardDetails =
  {
    ranks: [],
    rewardSymbol: "",
  };

export const EMPTY_COMPETITION_PLAN_DESCRIPTION: TCompetitionPlanIncentiveDescription =
  {
    lexAddress: "",
    startTimestamp: 0,
    endTimestamp: 0,
    roundNumber: 0,
    planCode: "",
    details: { incentivesList: [EMPTY_COMPETITION_PLAN_SINGLE_DETAILS] },
  };
