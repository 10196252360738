import { TEngineChainIds } from "./chainConstants.ts";

export type TEngineChainAddresses = {
  registryProxyAddress: string;
  tradingFloorProxyAddress: string;
  tradersPortalAddress: string;
  triggersAddress: string;

  liquidityIntentsVerifierAddress: string;
  chipsIntentsVerifierAddress: string;

  lexLensAddress: string;
  tradingFloorLensAddress: string;
  triggersAndPortalLensAddress: string;
  intentsVerifierLensAddress: string;

  wrappedNativeEngineChipHelperAddress: string;

  generalTokenDispenserAddress: string;
};

export const ENGINE_CHAIN_ADDRESSES: Record<
  TEngineChainIds,
  TEngineChainAddresses
> = {
  250: {
    registryProxyAddress: "0x3b7ED1cDF0Fc64d95c0D0428b9Cc99b6A9a5CB94",
    tradingFloorProxyAddress: "0x0420D838a2227727F2555aa54F0164A73c05C16b",
    tradersPortalAddress: "0xfa64B6644742fe0efBeA20BcD5c0b4ae59b24804",
    triggersAddress: "0x488f33Dbe8680B9133D6Ac4AD9842EF3E7f39d92",

    liquidityIntentsVerifierAddress:
      "0x8d616E8e8F7Ae6B71581dBdf8632B0709FDBD5F5",
    chipsIntentsVerifierAddress: "0xffF880898B5Ac5699A8256bF39917c7A9C54C44f",

    lexLensAddress: "0xCC470E6d2A78ef66F69e2949CE68fDB27F69F15a",
    tradingFloorLensAddress: "0x7A1a208d8758685826E8cc06016aec163BE88677",
    triggersAndPortalLensAddress: "0x28e4f20E5166ba5B47bF3e96ca51546bE0b936c2",
    intentsVerifierLensAddress: "0xD9E77b2A3E9B1a21aC256802bB86Cba3e21627E7",

    wrappedNativeEngineChipHelperAddress:
      "0x0E87EBd60b87919a1206e5C0e75f116fc6C06595",

    // generalTokenDispenserAddress: "0x799E45352091EE6ac02183ba6Ecb0a8B61738fcA",
    generalTokenDispenserAddress: "0x68dc9B635BB398f24319a05BD6E1a335b6F131D2",
  },

  288: {
    registryProxyAddress: "0xC5E782E2A4E2cFCb7eD454CF5a7b6aa2bB424B90",
    tradingFloorProxyAddress: "0x87525b5542DbF7302cd95D82388d28e44ec9289D",
    tradersPortalAddress: "0x6bFf3b0F8aD5BeAE2b840D970B27463152d520B5",
    triggersAddress: "0xDc759798902c5061D5D45a3c903E66290B9F79c5",

    liquidityIntentsVerifierAddress:
      "0x6DADf5b5AAb115Dadca6939fb20B457d68aFb4ae",
    chipsIntentsVerifierAddress: "0x5ee7C79FF04c66425DbC53585A0BA4fFcEcB69bf",

    lexLensAddress: "0xA1C6c72f2a2e31C747de8f133B30718E1A4BCeCe",
    tradingFloorLensAddress: "0x9feDA852C4ba74e4Eba90CADd0f30618E5e9Af31",
    triggersAndPortalLensAddress: "0x8d616E8e8F7Ae6B71581dBdf8632B0709FDBD5F5",
    intentsVerifierLensAddress: "0x8F33670B7a076793D178D340AD70Cc097DD03739",

    wrappedNativeEngineChipHelperAddress: "",

    // generalTokenDispenserAddress: "0x77961b3C1e2F13a0c7AD50A264351199Ce31Fb1B",
    generalTokenDispenserAddress: "0x76c66f53e3230fAa98B725CB7A77a14BE8f11f1c",
  },
};
