import React, { useEffect, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MaterialUiTable } from "../../../../components/Tables/MaterialUiTable";
import { localStringer } from "../../../../ux/uxFunctions";
import { commonTableCellsBuilders } from "../../../../components/Tables/commonTableCellsBuilders.tsx";
import { TEngineChainIds } from "../../../../constants/chainConstants.ts";
import { getChainExplorersUrlFromChainId } from "../../../../ux/chainsHooks.ts";
import { Typography } from "@mui/material";

export type TLiquidityHistoryTableRowGist = {
  providerAddress: string;
  assetSymbol: string;
  processEpoch: number;

  engineChainId: TEngineChainIds;

  action: "Deposit" | "Withdraw";
  amountInUnits: number;

  status: "Processed" | "Cancelled" | "Pending";

  timestamp: number;
  processTxHash: string;
};

interface IProps {
  liquidityHistoryRowGists: TLiquidityHistoryTableRowGist[];
}

export const LiquidityActionsHistoryTable = React.memo<IProps>((props) => {
  const { liquidityHistoryRowGists } = props;

  const columns: ColumnDef<TLiquidityHistoryTableRowGist>[] = useMemo<
    ColumnDef<TLiquidityHistoryTableRowGist>[]
  >(() => {
    return [
      {
        header: "Pool",
        id: "assetSymbol",
        cell: (cellProps) =>
          commonTableCellsBuilders.buildAssetTableCell(
            cellProps.row.original.assetSymbol,
          ),
      },
      {
        header: "Time",
        id: "time",
        cell: (cellProps) => {
          const miliTimestamp = cellProps.row.original.timestamp * 1000;
          const date = new Date(miliTimestamp);
          return (
            <Typography>
              {date.toLocaleDateString()} {date.toLocaleTimeString()}
            </Typography>
          );
        },
      },
      {
        header: "Action",
        id: "action",
        cell: (cellProps) => {
          const explorerLinks = getChainExplorersUrlFromChainId(
            cellProps.cell.row.original.engineChainId,
          );

          const txLinkUrl = `${explorerLinks.txUrlBase}/${cellProps.row.original.processTxHash}`;
          return commonTableCellsBuilders.buildDepositWithdrawActionCell(
            cellProps.row.original.action,
            txLinkUrl,
          );
        },
      },
      {
        header: "Amount",
        id: "amountInUnits",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.amountInUnits)}`,
      },
      {
        header: "Epoch",
        id: "processEpoch",
        accessorFn: (originalRow) => localStringer(originalRow.processEpoch),
      },
      {
        header: "Status",
        id: "status",
        accessorFn: (originalRow) => originalRow.status,
      },
    ];
  }, []);

  const data = useMemo(() => {
    return [
      ...liquidityHistoryRowGists.sort((a, b) => b.timestamp - a.timestamp),
    ];
  }, [liquidityHistoryRowGists]);

  // useEffect(() => {
  //   liquidityHistoryRowGists.sort((a, b) => b.timestamp - a.timestamp);
  // }, [liquidityHistoryRowGists]);

  return (
    <MaterialUiTable
      columns={columns}
      data={data}
      getRowId={(val: TLiquidityHistoryTableRowGist) =>
        `${val.providerAddress}_${val.assetSymbol}_${val.processEpoch}`
      }
    />
  );
});
