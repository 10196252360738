import React, { useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Route, Routes } from "react-router-dom";
import AppMainPage from "./AppMainPage.tsx";
import { LeveragePage } from "../pages/LeveragePage/LeveragePage.tsx";
import { LiquidityPage } from "../pages/LiquidityPage/LiquidityPage.tsx";
import { CompetitionsPage } from "../pages/CompetitionsPage/CompetitionsPage.tsx";
import { PortfolioPage } from "../pages/PortfolioPage/PortfolioPage.tsx";
import { usePageTracking } from "../navigation/navigationTrackingHooks.ts";
import { LeaderBoardPage } from "../pages/LeaderBoardPage/LeaderBoardPage.tsx";
import { useWhiteLabelIdentifier } from "../whitelabels/whiteLabelHooks.ts";
import { RewardsPage } from "../pages/RewardsPage/RewardsPage.tsx";
import { LoadingBridgeTxProvider } from "../store/loadingBridgeTxContext.tsx";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
  main: {
    flex: 1,
    display: "flex",
  },
}));

export const MainAppRouter = React.memo<IProps>((props) => {
  const { classes } = useStyles();

  usePageTracking();

  const { isOnAnyWhiteLabel, whiteLabelRoutePrefix, whiteLabelFaviconUrl } =
    useWhiteLabelIdentifier();
  const routePrefix = isOnAnyWhiteLabel ? `/${whiteLabelRoutePrefix}` : "";

  const routesCollection = useMemo(() => {
    return (
      <>
        <Route path={`${routePrefix}/leverage`} element={<LeveragePage />} />
        {/*<Route path={"/portfolio"} element={<PortfolioPage />} />*/}
        <Route path={`${routePrefix}/pools`} element={<LiquidityPage />} />
        <Route
          path={`${routePrefix}/competitions`}
          element={<CompetitionsPage />}
        />
        <Route path={`${routePrefix}/portfolio`} element={<PortfolioPage />} />
        <Route path={`${routePrefix}/rewards`} element={<RewardsPage />} />
        <Route
          path={`${routePrefix}/leaderBoard`}
          element={<LeaderBoardPage />}
        />
      </>
    );
  }, [routePrefix]);

  return (
    <main className={classes.main}>
      <LoadingBridgeTxProvider>
        <Routes>
          {isOnAnyWhiteLabel ? (
            <Route path={`/${whiteLabelRoutePrefix}`} element={<AppMainPage />}>
              {/* NOTE : This 'index' duplication is used to fix the bug where opening '/' path leads
           to wrong url params for chain and asset */}
              {/*<Route index element={<LeveragePage />} />*/}

              {routesCollection}

              {/* Quick-n-Dirty to allow custom WL sharing links to work */}
              {/*<Route path={"/solidlizard"} element={<LeveragePage />} />*/}
              {/*<Route path={"/spiritswap"} element={<LeveragePage />}/>*/}

              {/*{IS_DEV ? (*/}
              {/*    <Route path={"/rewards"} element={<RewardsPage />} />*/}
              {/*) : undefined}*/}
            </Route>
          ) : null}
          <Route path={"/"} element={<AppMainPage />}>
            {isOnAnyWhiteLabel ? null : routesCollection}
          </Route>
        </Routes>
      </LoadingBridgeTxProvider>
    </main>
  );
});

export default MainAppRouter;
