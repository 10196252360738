import { timeUtils } from "../../utils/timeUtils.ts";
import { useState } from "react";
import useInterval from "react-useinterval";

export function useGetTimeLeftForCurrentRound() {
  const [currentTimestamp, setCurrentTimestamp] = useState(() => {
    const nextMondayTimestamp = timeUtils.geTimePartsUntilNextMonday();
    return `${nextMondayTimestamp.days}D : ${nextMondayTimestamp.hours}H : ${nextMondayTimestamp.minutes}M`;
  });

  useInterval(() => {
    const nextMondayTimestamp = timeUtils.geTimePartsUntilNextMonday();
    setCurrentTimestamp(
      `${nextMondayTimestamp.days}D : ${nextMondayTimestamp.hours}H : ${nextMondayTimestamp.minutes}M`,
    );
  }, 1000);

  return currentTimestamp;
}
