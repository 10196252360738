import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MaterialUiTable } from "../../../../components/Tables/MaterialUiTable";
import { localStringer } from "../../../../ux/uxFunctions";
import {
  LIVE_CHAIN_CONSTANTS,
  TEngineChainIds,
  TSourceChainIds,
} from "../../../../constants/chainConstants.ts";
import { buildDateTimeTableCell } from "lynx-ui-components";
import { Stack, Typography } from "@mui/material";

import WithdrawIcon from "../../../../assets/appIcons/WithdrawIcon.svg?react";
import DepositIcon from "../../../../assets/appIcons/DepositIcon.svg?react";
import { getChainExplorersUrlFromChainId } from "../../../../ux/chainsHooks.ts";
import { commonTableCellsBuilders } from "../../../../components/Tables/commonTableCellsBuilders.tsx";

export type TChipHistoryTableRowGist = {
  engineChainId: TEngineChainIds;
  sourceChainId: TSourceChainIds;
  sourceUnderlyingSymbol: string;

  action: "Deposit" | "Withdraw";
  amountInUnits: number;

  timestamp: number;
  relevantTxHash: string;
};

interface IProps {
  chipActionHistoryGists: TChipHistoryTableRowGist[];
}

const ICON_TO_TEXT_GAP_REM = 3;

export const ChipsHistoryTable = React.memo<IProps>((props) => {
  const { chipActionHistoryGists } = props;

  const columns: ColumnDef<TChipHistoryTableRowGist>[] = useMemo<
    ColumnDef<TChipHistoryTableRowGist>[]
  >(() => {
    return [
      {
        header: "Time",
        id: "time",
        cell: (cellProps) => {
          return buildDateTimeTableCell(
            cellProps.cell.row.original.timestamp,
            "column",
            "",
          );
        },
      },
      {
        header: "Type",
        id: "type",
        // accessorFn: (originalRow) => originalRow.action,
        cell: (cellProps) => {
          const explorerLinks = getChainExplorersUrlFromChainId(
            cellProps.cell.row.original.engineChainId,
          );

          const txLinkUrl = `${explorerLinks.txUrlBase}/${cellProps.row.original.relevantTxHash}`;
          return commonTableCellsBuilders.buildDepositWithdrawActionCell(
            cellProps.row.original.action,
            txLinkUrl,
          );
        },
      },
      {
        header: "Chain",
        id: "sourceChainId",
        cell: (cellProps) => {
          return commonTableCellsBuilders.buildBlockchainCell(
            LIVE_CHAIN_CONSTANTS[cellProps.row.original.sourceChainId]
              ?.shortDisplayName ?? "",
          );
        },
      },
      {
        header: "Asset",
        id: "sourceUnderlyingSymbol",
        cell: (props1) => {
          return commonTableCellsBuilders.buildAssetTableCell(
            props1.row.original.sourceUnderlyingSymbol,
          );
        },
      },
      {
        header: "Amount",
        id: "amountInUnits",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.amountInUnits)}`,
      },
    ];
  }, []);

  const data = useMemo(() => {
    return [...chipActionHistoryGists];
  }, [chipActionHistoryGists]);

  return (
    <MaterialUiTable
      columns={columns}
      data={data}
      getRowId={(val: TChipHistoryTableRowGist) =>
        `${val.sourceChainId}_${val.sourceUnderlyingSymbol}_${val.action}_${val.relevantTxHash}`
      }
    />
  );
});
