import { PAIR_CONFIGS_IDS, TPairIds } from "../constants/pairsConstants.ts";

const defaultPriceDecimals = 4;
export const PRICE_UX_FOR_PAIRS: Record<TPairIds, number> = {
  [PAIR_CONFIGS_IDS.BTC_USD]: defaultPriceDecimals,
  [PAIR_CONFIGS_IDS.ETH_USD]: defaultPriceDecimals,
  [PAIR_CONFIGS_IDS.BNB_USD]: defaultPriceDecimals,
  [PAIR_CONFIGS_IDS.FTM_USD]: defaultPriceDecimals + 2,
  [PAIR_CONFIGS_IDS.SOL_USD]: defaultPriceDecimals,

  // FOREX
  [PAIR_CONFIGS_IDS.EUR_USD]: defaultPriceDecimals + 2,
  [PAIR_CONFIGS_IDS.GBP_USD]: defaultPriceDecimals + 2,
  [PAIR_CONFIGS_IDS.AUD_USD]: defaultPriceDecimals + 2,
  [PAIR_CONFIGS_IDS.NZD_USD]: defaultPriceDecimals + 2,

  // CAT Mode
  [PAIR_CONFIGS_IDS.BTC_USD_CAT]: defaultPriceDecimals,
  [PAIR_CONFIGS_IDS.ETH_USD_CAT]: defaultPriceDecimals,
};

export const AMOUNTS_UX_FOR_NATIVE_COINS = {
  ETH: 18,
  FTM: 5,
};
