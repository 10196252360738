import { ethers } from "ethers";

function generateVerificationDomain(
  chainId: string,
  tradersPortalAddress: string,
): ethers.TypedDataDomain {
  const verificationDomain: ethers.TypedDataDomain = {
    chainId: chainId,
    name: "Trade Intents Verifier",
    version: "1",
    verifyingContract: tradersPortalAddress,
  };

  return verificationDomain;
}

const TRADER_REQUEST_PAYLOAD_CLOSE_MARKET: Record<
  string,
  ethers.TypedDataField[]
> = {
  UserRequestPayload_CloseMarket: [
    {
      name: "nonce",
      type: "uint256",
    },
    {
      name: "positionId",
      type: "bytes32",
    },
    {
      name: "minPrice",
      type: "uint64",
    },
    {
      name: "maxPrice",
      type: "uint64",
    },
  ],
};

const TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_OPEN_POSITION: Record<
  string,
  ethers.TypedDataField[]
> = {
  UserRequestPayload_OpenPosition: [
    {
      name: "nonce",
      type: "uint256",
    },
    {
      name: "positionRequestIdentifiers",
      type: "PositionRequestIdentifiers",
    },
    {
      name: "positionRequestParams",
      type: "PositionRequestParams",
    },
    {
      name: "orderType",
      type: "uint8",
    },
  ],
  PositionRequestIdentifiers: [
    {
      name: "trader",
      type: "address",
    },
    {
      name: "pairId",
      type: "uint16",
    },
    {
      name: "settlementAsset",
      type: "address",
    },
    {
      name: "positionIndex",
      type: "uint32",
    },
  ],
  PositionRequestParams: [
    {
      name: "long",
      type: "bool",
    },
    {
      name: "collateral",
      type: "uint256",
    },
    {
      name: "leverage",
      type: "uint32",
    },
    {
      name: "minPrice",
      type: "uint64",
    },
    {
      name: "maxPrice",
      type: "uint64",
    },
    {
      name: "tp",
      type: "uint64",
    },
    {
      name: "sl",
      type: "uint64",
    },
    {
      name: "tpByFraction",
      type: "uint64",
    },
    {
      name: "slByFraction",
      type: "uint64",
    },
  ],
};

const TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_UPDATE_POSITION_SINGLE_FIELD: Record<
  string,
  ethers.TypedDataField[]
> = {
  UserRequestPayload_UpdatePositionSingleField: [
    { name: "nonce", type: "uint256" },
    { name: "positionId", type: "bytes32" },
    { name: "orderType", type: "uint8" },
    { name: "fieldValue", type: "uint64" },
  ],
};

const TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_UPDATE_POSITION_DOUBLE_FIELD: Record<
  string,
  ethers.TypedDataField[]
> = {
  UserRequestPayload_UpdatePositionDoubleField: [
    { name: "nonce", type: "uint256" },
    { name: "positionId", type: "bytes32" },
    { name: "orderType", type: "uint8" },
    { name: "fieldValueA", type: "uint64" },
    { name: "fieldValueB", type: "uint64" },
  ],
};

const TRADER_DIRECT_PAYLOAD_TYPE_DEFINITION_UPDATE_PENDING_LIMIT_POSITION: Record<
  string,
  ethers.TypedDataField[]
> = {
  UserDirectPayload_UpdatePendingLimitPosition: [
    { name: "nonce", type: "uint256" },
    { name: "positionId", type: "bytes32" },
    { name: "minPrice", type: "uint64" },
    { name: "maxPrice", type: "uint64" },
    { name: "tp", type: "uint64" },
    { name: "sl", type: "uint64" },
  ],
};

const TRADER_DIRECT_PAYLOAD_TYPE_DEFINITION_CANCEL_PENDING_LIMIT_POSITION: Record<
  string,
  ethers.TypedDataField[]
> = {
  UserDirectPayload_CancelPendingLimitPosition: [
    { name: "nonce", type: "uint256" },
    { name: "positionId", type: "bytes32" },
  ],
};

export const tradeIntentsVerifierPayloadTypes = {
  buildDomainSeparator: generateVerificationDomain,
  TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_OPEN_POSITION,
  TRADER_REQUEST_PAYLOAD_CLOSE_MARKET,
  TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_UPDATE_POSITION_SINGLE_FIELD,
  TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_UPDATE_POSITION_DOUBLE_FIELD,

  TRADER_DIRECT_PAYLOAD_TYPE_DEFINITION_UPDATE_PENDING_LIMIT_POSITION,
  TRADER_DIRECT_PAYLOAD_TYPE_DEFINITION_CANCEL_PENDING_LIMIT_POSITION,
};
