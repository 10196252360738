import React, { DetailedHTMLProps, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { useCommonClasses } from "../../../theme/commonClasses";

interface IProps {
  symbol: string;
  extraClasses?: string[];
  iconSizeRem?: number;
}

const PUBLIC_URL = import.meta.env.BASE_URL;

const useStyles = makeStyles<{ iconSizeRem: number }>()((theme, prp) => ({
  token: {
    // "&:hover": {
    //   animation: "$turn 3000ms linear",
    // },
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "--iconSize": `${prp.iconSizeRem}rem`,
  },

  "@keyframes turn": {
    "0%": {
      transform: "rotateY(0deg)",
    },
    // "25%": {
    //   transform: "rotateY(-180deg)",
    // },
    "50%": {
      transform: "rotateY(-360deg)",
    },
    // "75%": {
    //   transform: "rotateY(180deg)",
    // },
    "100%": {
      transform: "rotateY(0deg)",
    },
  },
  "@keyframes return": {
    "100%": {
      transform: "rotateY(0deg)",
    },
  },
}));
export const TokenIcon = React.forwardRef<
  HTMLSpanElement,
  IProps &
    DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
>((props, ref) => {
  const {
    symbol,
    extraClasses,
    iconSizeRem,
    className: outerClassName,
    style,
    ...spanProps
  } = props;
  const { classes, cx } = useStyles({ iconSizeRem: iconSizeRem ?? 2.8 });
  const { classes: commonClasses } = useCommonClasses();

  const classesForSpan = useMemo(() => {
    const safeExtraClasses = extraClasses ?? [];
    return cx([
      commonClasses.icon,
      classes.token,
      outerClassName,
      ...safeExtraClasses,
    ]);
  }, [classes.token, commonClasses.icon, cx, extraClasses, outerClassName]);

  return (
    <span
      className={classesForSpan}
      style={{
        backgroundImage: `url(/tokens/asset_${symbol}.svg)`,
        ...style,
      }}
      {...spanProps}
      ref={ref}
    >
      {symbol === "QUESTION" ? "?" : undefined}
    </span>
  );
});
