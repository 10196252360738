import { LED_IDS } from "../services/leverageDimensionService/leveregeDimensionsParams.ts";
import {
  EMPTY_COMPETITION_PLAN_DESCRIPTION,
  INCENTIVE_PLAN_TYPE_KEYS,
  TCompetitionPlanIncentiveDescription,
} from "../services/servicesIntergration/incentivesService/IIncentivesService.ts";

export type TRewardsRoundRules = {
  isActive: boolean;

  roundNumber: number;
  graphRoundNumber: number;

  feesRebateInUnits: number;

  roundRewardsForFeesInUnits: number;
  roundRewardsForPositivePnLInUnits: number;
};

export function getManualRewardsRoundRules(
  lexId: string,
  graphRoundNumber: number,
): TRewardsRoundRules {
  const inactiveRewardsRoundGist: TRewardsRoundRules = {
    isActive: false,

    roundNumber: 0,
    graphRoundNumber: 0,

    feesRebateInUnits: 0,

    roundRewardsForFeesInUnits: 0,
    roundRewardsForPositivePnLInUnits: 0,
  };

  let rewardsRoundGist: TRewardsRoundRules = inactiveRewardsRoundGist;

  if (lexId === LED_IDS.FANTOM_ENGINE_FUSE_PDAI) {
    rewardsRoundGist = {
      isActive: true,

      roundNumber: 0,
      graphRoundNumber: 0,

      feesRebateInUnits: 0.9,

      roundRewardsForFeesInUnits: 10_000,
      roundRewardsForPositivePnLInUnits: 40_000,
    };
  } else if (lexId === LED_IDS.FANTOM_ENGINE_FUSE_WFUSE) {
    if (graphRoundNumber == 10) {
      // 1714348800 -- 1714953600
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 500,
        roundRewardsForPositivePnLInUnits: 500,
      };
    } else if (graphRoundNumber == 11) {
      // 1714953600 -- 1715558400
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 200,
        roundRewardsForPositivePnLInUnits: 200,
      };
    } else if (graphRoundNumber == 12) {
      // 1715558400 - 1716163200
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 1000,
        roundRewardsForPositivePnLInUnits: 4000,
      };
    } else if (graphRoundNumber == 13) {
      // 1716163200 -- 1716768000
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 2000,
        roundRewardsForPositivePnLInUnits: 8000,
      };
    }
  } else if (lexId === LED_IDS.FANTOM_ENGINE_BSC_LISUSD) {
    // 1716768000 -- 1717372800
    if (graphRoundNumber == 4) {
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 600,
        roundRewardsForPositivePnLInUnits: 400,
      };
    } else if (graphRoundNumber == 5) {
      // 1717372800 -- 1717977600
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 600,
        roundRewardsForPositivePnLInUnits: 400,
      };
    } else if (graphRoundNumber == 6) {
      // 1717977600 -- 1718582400
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 400,
        roundRewardsForPositivePnLInUnits: 600,
      };
    } else if (graphRoundNumber == 7) {
      // 1718582400 -- 1719187200
      rewardsRoundGist = {
        isActive: true,

        roundNumber: 0,
        graphRoundNumber: 0,

        feesRebateInUnits: 0.8, // 80%

        roundRewardsForFeesInUnits: 400,
        roundRewardsForPositivePnLInUnits: 600,
      };
    }
  }

  return rewardsRoundGist;
}

// export type TCompetitionRewardsDescriptor = {
//   isActive: boolean;
//   rewardTokenSymbol: string;
//   rewardsArray: number[];
// };

// export const EMPTY_COMPETITION_REWARDS_DESCRIPTOR: TCompetitionRewardsDescriptor =
//   {
//     isActive: false,
//     rewardTokenSymbol: "",
//     rewardsArray: [],
//   };

export function getManualCompetitionDescriptor(
  lexId: string,
  roundNumber: number,
): TCompetitionPlanIncentiveDescription {
  const eurosPoolAddress = "0xf1143f1b8d2c752EB2608eA200795D73C4EF656C";

  // TODO : Add the timestamp of the manual competitions
  if (lexId == LED_IDS.FANTOM_ENGINE_ARBITRUM_EUROS) {
    if (roundNumber == 12) {
      return {
        roundNumber: 1,
        lexAddress: eurosPoolAddress,
        planCode: INCENTIVE_PLAN_TYPE_KEYS.topComp,
        startTimestamp: 1718582400,
        endTimestamp: 1719187200,

        details: {
          incentivesList: [
            {
              ranks: [540, 220, 100, 100, 100],
              rewardSymbol: "ARB",
            },
          ],
        },
      };
    } else if (roundNumber == 13) {
      return {
        roundNumber: 2,
        lexAddress: eurosPoolAddress,
        planCode: INCENTIVE_PLAN_TYPE_KEYS.topComp,
        startTimestamp: 1719187200,
        endTimestamp: 1719792000,

        details: {
          incentivesList: [
            {
              ranks: [540, 220, 100, 100, 100],
              rewardSymbol: "ARB",
            },
          ],
        },
      };
    } else if (roundNumber == 14) {
      return {
        roundNumber: 3,
        lexAddress: eurosPoolAddress,
        planCode: INCENTIVE_PLAN_TYPE_KEYS.topComp,
        startTimestamp: 1719792000,
        endTimestamp: 1720396800,

        details: {
          incentivesList: [
            {
              ranks: [540, 220, 100, 100, 100],
              rewardSymbol: "ARB",
            },
          ],
        },
      };
    }
  }

  return EMPTY_COMPETITION_PLAN_DESCRIPTION;
}
