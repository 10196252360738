import { IGeneralTokenDispenserService } from "./IGeneralTokenDispenserService";
import { Provider, Signer } from "ethers";
import { GeneralTokenDispenserV1 } from "../../../typechain/contracts/Peripheral/TokensDistribution/GeneralTokenDispenser/GeneralTokenDispenserV1.ts";
import { GeneralTokenDispenserV1__factory } from "../../../typechain/factories/contracts/Peripheral/TokensDistribution/GeneralTokenDispenser/GeneralTokenDispenserV1__factory.ts";

export class GeneralTokenDispenserService
  implements IGeneralTokenDispenserService
{
  private generalTokenDispenserContract: GeneralTokenDispenserV1;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.generalTokenDispenserContract =
      GeneralTokenDispenserV1__factory.connect(address, signerOrProvider);
  }

  async getPendingAmountForAccount(
    tokenAddress: string,
    accountAddress: string,
  ): Promise<bigint> {
    return await this.generalTokenDispenserContract.getPendingAmountForAccount.staticCall(
      tokenAddress,
      accountAddress,
    );
  }
}
