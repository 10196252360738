import { BytesLike, Provider, Signer } from "ethers";
import {
  EMPTY_ACCOUNT_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO,
  EMPTY_HASH_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO,
  IIntentsVerifierLensService,
  TAccountBasedIntentsVerifierRequestBuildingInfoStruct,
  THashBasedIntentsVerifierRequestBuildingInfoStruct,
} from "./IIntentsVerifierLensService.ts";
import { IntentsVerifierLens } from "../../../typechain/contracts/Peripheral/Lens/IntentsVerifierLens.ts";
import { IntentsVerifierLens__factory } from "../../../typechain/factories/contracts/Peripheral/Lens/IntentsVerifierLens__factory.ts";
import { ethersStructResponseToObject } from "../../../utils/ethersTypes.ts";

export class IntentsVerifierLensService implements IIntentsVerifierLensService {
  private intentsVerifierLensContract: IntentsVerifierLens;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.intentsVerifierLensContract = IntentsVerifierLens__factory.connect(
      address,
      signerOrProvider,
    );
  }

  async getRequestBuildingInfoForTradeIntentsVerifier(
    account: BytesLike,
    actionType: number,
  ): Promise<THashBasedIntentsVerifierRequestBuildingInfoStruct> {
    const raw =
      await this.intentsVerifierLensContract.getRequestBuildingInfoForTradeIntentsVerifier(
        account,
        actionType,
      );

    return ethersStructResponseToObject(
      raw,
      EMPTY_HASH_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO,
    );
  }

  async getRequestBuildingInfoForLiquidityIntentsVerifier(
    account: string,
    actionType: number,
  ): Promise<TAccountBasedIntentsVerifierRequestBuildingInfoStruct> {
    return ethersStructResponseToObject(
      await this.intentsVerifierLensContract.getRequestBuildingInfoForLiquidityIntentsVerifier(
        account,
        actionType,
      ),
      EMPTY_ACCOUNT_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO,
    );
  }

  async getRequestBuildingInfoForChipsIntentsVerifier(
    account: string,
    actionType: number,
  ): Promise<TAccountBasedIntentsVerifierRequestBuildingInfoStruct> {
    return ethersStructResponseToObject(
      await this.intentsVerifierLensContract.getRequestBuildingInfoForChipsIntentsVerifier(
        account,
        actionType,
      ),
      EMPTY_ACCOUNT_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO,
    );
  }
}
