import React from "react";
import { makeStyles } from "tss-react/mui";
import { useStructureClasses } from "../../../theme/commonClasses.ts";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants.ts";

interface IProps {
  text: React.ReactNode;
  href: string;
}

const useStyles = makeStyles()((theme) => ({
  linksParts: {
    color: THEME_CONSTANTS.brandYellow,
    "&:hover": {
      color: THEME_CONSTANTS.brandLightPurple,
    },
  },
}));

export const ExternalLink = React.memo<IProps>((props) => {
  const { classes: structureClasses, cx: structureClassesCX } =
    useStructureClasses();
  const { classes } = useStyles();

  const { text, href } = props;

  return (
    <a
      className={classes.linksParts}
      href={href}
      target={"_blank"}
      rel={"noreferrer noopener"}
    >
      {text}
    </a>
  );
});
