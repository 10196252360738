import {
  BytesLike,
  Signer,
  Provider,
  ContractTransactionResponse,
} from "ethers";

import { TradersPortalV1 } from "../../../typechain/contracts/Lynx/TradersPortal/TradersPortalV1";
import {
  ITradingService,
  TPositionRequestIdentifierStruct,
  TPositionRequestParamsStruct,
} from "./ITradingService";
import {
  TOpenOrderType,
  TPositionField,
  TUpdatePositionFieldOrderType,
  UpdatePositionFieldOrderTypeEnums,
} from "../../../constants/contractEnums";
import { TradersPortalV1__factory } from "../../../typechain/factories/contracts/Lynx/TradersPortal/TradersPortalV1__factory.ts";

export class TradingService implements ITradingService {
  private tradingContract: TradersPortalV1;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.tradingContract = TradersPortalV1__factory.connect(
      address,
      signerOrProvider,
    );
  }

  async traderRequest_openNewPosition(
    tradeRequestIdentifier: TPositionRequestIdentifierStruct,
    tradeRequestParams: TPositionRequestParamsStruct,
    orderType: TOpenOrderType,
    referrerDomain: BytesLike,
    referrerCode: BytesLike,
    runCapTests: boolean,
    nativeFee: bigint,
    staticCall = false,
  ): Promise<ContractTransactionResponse> {
    if (staticCall) {
      // @ts-ignore
      return this.tradingContract.traderRequest_openNewPosition.staticCall(
        tradeRequestIdentifier,
        tradeRequestParams,
        orderType,
        referrerDomain,
        referrerCode,
        runCapTests,
        {
          value: nativeFee,
        },
      );
    } else {
      return this.tradingContract.traderRequest_openNewPosition(
        tradeRequestIdentifier,
        tradeRequestParams,
        orderType,
        referrerDomain,
        referrerCode,
        runCapTests,
        {
          value: nativeFee,
        },
      );
    }
  }

  async traderRequest_setExistingPositionToMarketClose(
    positionId: BytesLike,
    minPrice: bigint,
    maxPrice: bigint,
    nativeFee: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.traderRequest_marketClosePosition(
      positionId,
      minPrice,
      maxPrice,
      {
        value: nativeFee,
      },
    );
  }

  async traderRequest_updatePositionSingleField(
    positionId: BytesLike,
    orderType: TUpdatePositionFieldOrderType,
    fieldValue: bigint,
    nativeFee: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.traderRequest_updatePositionSingleField(
      positionId,
      orderType,
      fieldValue,
      {
        value: nativeFee,
      },
    );
  }

  async traderRequest_updatePositionDoubleField_tp_and_sl(
    positionId: BytesLike,
    tpValue: bigint,
    slValue: bigint,
    nativeFee: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.traderRequest_updatePositionDoubleField(
      positionId,
      UpdatePositionFieldOrderTypeEnums.UPDATE_TP_AND_SL,
      tpValue,
      slValue,
      {
        value: nativeFee,
      },
    );
  }

  async directAction_timeout_openMarket(
    positionId: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.directAction_timeout_openMarket(positionId, {
      // gasLimit: 2_000_000,
    });
  }

  async directAction_timeout_closeMarket(
    positionId: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.directAction_timeout_closeMarket(positionId);
  }

  async directAction_cancelPendingPosition_limit(
    positionId: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.directAction_cancelPendingPosition_limit(
      positionId,
    );
  }

  async directAction_cancelPendingUpdatePositionField(
    positionId: BytesLike,
  ): Promise<ContractTransactionResponse> {
    return this.tradingContract.directAction_timeout_updateTradeField(
      positionId,
    );
  }
}
