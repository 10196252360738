import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { ChipLiquidityModal } from "./ChipLiquidityModal";
import {
  useContractsServicesStore,
  useCryptoWalletIntegrationStore,
  useLexStoreById,
  useSystemStore,
} from "../../../store/storeHooks.ts";
import {
  CHAIN_ID_TO_DISPLAY_DATA,
  TChainIds,
} from "../../../constants/chainConstants.ts";
import { floatToUnitsBn } from "../../../utils/bignumbers.ts";
import {
  ChipModeEnums,
  ChipsIntentsVerifierActionsEnums,
} from "../../../constants/contractEnums.ts";
import { chipsIntentsVerifierPayloadTypes } from "../../../typechain/lynxSystem/intentsVerifiers/chipsIntentsVerifier/chipsIntentsVerifierPayloadTypes.ts";
import { THolderRequestPayload_ChipOutLZStruct } from "../../../services/servicesIntergration/intentsDelegationService/IIntentsDelegationService.ts";
import {
  buildIntentsDelegationService,
  handleDelegatedIntentUxFlow,
} from "../../../ux/delegatedIntentsUx.ts";
import { observer } from "mobx-react";
import { TPendingLZBridgeTxGist } from "../../../pages/PortfolioPage/pageHooks/chipsPageHooks.ts";
import {
  toastError,
  toastInfo,
  toastWarning,
} from "../../../ux/toasts/toasting.ts";
import { numericalDisplay } from "../../../ux/displayCalculations.ts";
import { LED_IDS } from "../../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { SingleLexStore } from "../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import { toastTxError } from "../../../ux/toasts/complexToasting.ts";
import { BytesLike } from "ethers";
import { useLoadingBridgeTx } from "../../../store/loadingBridgeTxContext.tsx";
import { useAccount } from "wagmi";

interface IProps {
  open: boolean;
  closeModal: () => void;
  lexId: string;
  pendingBridgeTxGists: TPendingLZBridgeTxGist[];

  setPendingBridgeTx: (pendingBridgeTxGist: TPendingLZBridgeTxGist[]) => void;
  onChangeLexId: (lexId: string) => void;
}

const useStyles = makeStyles()((theme) => ({}));

export const ConnectedChipOutLiquidityModal = observer<React.FC<IProps>>(
  (props) => {
    const { classes } = useStyles();

    const {
      open,
      closeModal,
      lexId,
      setPendingBridgeTx,
      pendingBridgeTxGists,
      onChangeLexId,
    } = props;

    const lexStore = useLexStoreById(lexId);
    const contractServicesStore = useContractsServicesStore();
    const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();
    const systemStore = useSystemStore();
    const { setLoadingBridgeTxGists } = useLoadingBridgeTx();
    const userAddress = cryptoWalletConnectionStore.mainAddress;

    const { chainId: walletChainId } = useAccount();
    const isConnectedToProperChain = walletChainId === lexStore?.sourceChainId;

    const [isWaitingForTx, setIsWaitingForTx] = useState(false);
    const [assetAmountInString, setAssetAmountInString] = useState("");
    const isWrappedNative = lexStore?.id == LED_IDS.FANTOM_ENGINE_FANTOM_FTM;

    const isOFTChip = lexStore?.chipMode === ChipModeEnums.REMOTE;
    const isEngineChip = lexStore?.chipMode === ChipModeEnums.LOCAL;

    const oftChipWithdrawFunction = useCallback(
      async (
        lexStore: SingleLexStore,
        signerAddress: string,
        amountInUnits: number,
      ) => {
        const amountScaled = floatToUnitsBn(
          amountInUnits,
          lexStore.chipAssetParams.decimals,
        );

        const engineChainId = lexStore.engineChainId;
        const engineChainSystemContracts =
          systemStore.systemContractsMap.get(engineChainId);
        const intentsVerifierLensAddress =
          engineChainSystemContracts?.intentsVerifierLens ?? "";

        const intentsVerifierLens =
          contractServicesStore.buildIntentsVerifierLensService(
            intentsVerifierLensAddress,
            engineChainId,
          );

        const requestBuildingInfo =
          await intentsVerifierLens.getRequestBuildingInfoForChipsIntentsVerifier(
            userAddress,
            Number(ChipsIntentsVerifierActionsEnums.CHIP_OUT_LZ),
          );

        const typedDomainData =
          chipsIntentsVerifierPayloadTypes.buildDomainSeparator(
            lexStore.sourceChainId.toString(),
            requestBuildingInfo.verifier,
          );

        const types =
          chipsIntentsVerifierPayloadTypes.HOLDER_REQUEST_PAYLOAD_TYPE_DEFINITION_CHIP_OUT_LZ;

        const payload: THolderRequestPayload_ChipOutLZStruct = {
          nonce: requestBuildingInfo.currentNonce,
          holder: userAddress,
          receiver: userAddress,
          chip: lexStore.chipAssetParams.address,
          amount: amountScaled,
        };

        const signature = await cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

        const intentsDelegationService =
          buildIntentsDelegationService(engineChainId);
        setLoadingBridgeTxGists(lexStore.id, true);
        setIsWaitingForTx(true);

        await handleDelegatedIntentUxFlow(
          `ChipOut to ${lexStore.engineChainId}`,
          `${userAddress} - ${lexStore.sourceAssetParameters.symbol} - ${amountInUnits}`,
          async () => {
            const delegationRes =
              await intentsDelegationService.delegateChipsRequest_chipOutLZ(
                payload,
                signature,
              );
            setLoadingBridgeTxGists(lexStore.id, false);
            setIsWaitingForTx(false);
            closeModal();
            const currentTxGits = {
              lexId: lexStore.id,
              assetAmountInUnits: amountInUnits,
              assetSymbol: lexStore.sourceAssetParameters.symbol,
              sourceChainEid: lexStore.engineChainId,
              txHash: delegationRes.tx ?? "",
              actionName: "Withdraw",
              displayMessage: `Withdrawing ${numericalDisplay(amountInUnits, 3)} ${lexStore.sourceAssetParameters.symbol}`,
            };

            setPendingBridgeTx([...pendingBridgeTxGists, currentTxGits]);

            return delegationRes;
          },
          cryptoWalletConnectionStore.mainAddress,
        );
      },
      [
        closeModal,
        contractServicesStore,
        cryptoWalletConnectionStore,
        pendingBridgeTxGists,
        setPendingBridgeTx,
        systemStore.systemContractsMap,
        userAddress,
        setLoadingBridgeTxGists,
      ],
    );

    const engineBurnFunction = useCallback(
      async (
        lexStore: SingleLexStore,
        signerAddress: string,
        amountInUnits: number,
        isBurnAndCall: boolean,
      ) => {
        const engineChipService = lexStore.freshEngineChipService;

        const amountScaled = floatToUnitsBn(
          amountInUnits,
          lexStore.chipAssetParams.decimals,
        );

        const engineChainId = lexStore.engineChainId;
        const engineChainSystemContracts =
          systemStore.systemContractsMap.get(engineChainId);
        const intentsVerifierLensAddress =
          engineChainSystemContracts?.intentsVerifierLens ?? "";

        const intentsDelegationService =
          buildIntentsDelegationService(engineChainId);

        try {
          setIsWaitingForTx(true);

          const emptyBytes: BytesLike = "0x";

          const conTx = isBurnAndCall
            ? await engineChipService.burnChipAndCall(amountScaled, emptyBytes)
            : await engineChipService.burnChip(signerAddress, amountScaled);
          const receipt = await conTx.wait();

          void lexStore.refreshDataAfterTx();

          toastInfo(`Burn Chip Request in tx ${receipt?.hash}`);

          closeModal();
        } catch (e) {
          toastTxError(e);
          console.error(e);
        } finally {
          setIsWaitingForTx(false);
        }
      },
      [closeModal, systemStore.systemContractsMap],
    );

    // TODO : CRITICAL : Add callback logic after deploying new version
    const chipOutCallback = useCallback(
      async (amountInUnits: number) => {
        if (amountInUnits == 0) {
          toastWarning(`0 amount`);
          return;
        }

        const signerAddress =
          (await cryptoWalletConnectionStore.getChainSigner?.getAddress()) ??
          "NON";

        if (signerAddress !== userAddress) {
          throw new Error(
            `Signer address ${signerAddress} does not match user address ${userAddress}`,
          );
        }

        if (lexStore) {
          if (!isConnectedToProperChain) {
            return toastError(
              `Connected to wrong chain ! Connected to ${walletChainId} instead of ${lexStore.engineChainId}`,
            );
          } else if (isOFTChip) {
            await oftChipWithdrawFunction(
              lexStore,
              signerAddress,
              amountInUnits,
            );
          } else if (isEngineChip) {
            await engineBurnFunction(
              lexStore,
              signerAddress,
              amountInUnits,
              isWrappedNative,
            );
          } else {
            console.error(
              `No ChipIn logic defined for chip mode ${lexStore.chipMode}`,
            );
          }
        }
      },
      [
        cryptoWalletConnectionStore.getChainSigner,
        engineBurnFunction,
        isConnectedToProperChain,
        isEngineChip,
        isOFTChip,
        isWrappedNative,
        lexStore,
        oftChipWithdrawFunction,
        userAddress,
        walletChainId,
      ],
    );

    const nativeCoinSymbol = lexStore
      ? CHAIN_ID_TO_DISPLAY_DATA[lexStore.engineChainId].nativeCoinSymbol
      : "";

    const chainId = lexStore
      ? CHAIN_ID_TO_DISPLAY_DATA[lexStore.sourceChainId].id
      : "";

    // TODO : Unify this 'loading'/'error' into a proper hook/system
    const [isLoading, setIsLoading] = useState(false);

    // TODO : CRITICAL : Add reading logic after deploying new version
    const nativeActionFeeInUnits = 0;
    const nativeBalanceInUnits = 0;

    const [assetActionFeeInUnits, setAssetActionFeeInUnits] = useState(0);

    useEffect(() => {
      // NOTE : Assumes gas cost will be the same regardless of actual transfer amount
      if (lexStore) {
        const chipsIntentsVerifierService =
          lexStore.freshChipsIntentsVerifierService;

        setIsLoading(true);
        chipsIntentsVerifierService
          .readChipOutFeeInUnitsForChip(lexStore.chipAssetParams.address)
          .then((assetFeeInUnits) => {
            setAssetActionFeeInUnits(assetFeeInUnits);
          })
          .catch((e) =>
            console.error(`Error readChipOutFeeInUnitsForChip : ${e} `),
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, [contractServicesStore, lexStore]);

    return (
      <ChipLiquidityModal
        open={open}
        closeModal={closeModal}
        assetSymbol={lexStore?.sourceAssetParameters.symbol ?? ""}
        nativeCoinSymbol={nativeCoinSymbol}
        chainId={chainId as TChainIds}
        approveCallback={() => undefined}
        actionCallback={chipOutCallback}
        needToApprove={false}
        isWaitingForTx={isWaitingForTx}
        isLoadingFeesData={isLoading}
        nativeActionFeeInUnits={nativeActionFeeInUnits}
        assetActionFeeInUnits={assetActionFeeInUnits}
        availableAmountInUnits={
          lexStore?.activeUserStore
            .accountUnderlyingBalanceInWalletOnEngineChainInUnits ?? 0
        }
        nativeBalanceInUnits={nativeBalanceInUnits}
        title={"Withdraw"}
        actionName={"Withdraw"}
        isEngineChip={isEngineChip}
        onChangeLexId={onChangeLexId}
        onSetAssetAmountInString={setAssetAmountInString}
        assetAmountInString={assetAmountInString}
      />
    );
  },
);

export default ConnectedChipOutLiquidityModal as (
  props: IProps,
) => React.JSX.Element;
