import { PRECISION_SCALE, PRICES_SCALE } from "../constants/scales";
import { floatToUnitsBn, unitsBnToFloat } from "./bignumbers.ts";

export function precisionBnToFloat(precisionBn: bigint): number {
  return unitsBnToFloat(precisionBn, Number(PRECISION_SCALE));
}

export function floatToPrecisionBn(value: number): bigint {
  return floatToUnitsBn(value, Number(PRECISION_SCALE));
}

export function priceBnToFloat(pricesBn: bigint): number {
  return unitsBnToFloat(pricesBn, PRICES_SCALE);
}

export function floatToPriceBn(priceUnits: number): bigint {
  return floatToUnitsBn(priceUnits, PRICES_SCALE);
}
