import React, { useCallback, useMemo } from "react";
import {
  useActiveLexStoreStore,
  useModalsStore,
  usePricesStore,
} from "../../../store/storeHooks";
import { DEFAULT_MODAL_THEME } from "../../../theme/ThemeConstants";
import { observer } from "mobx-react";
import { PositionUpdateModal } from "./PositionUpdateModal";
import { PositionFieldEnums } from "../../../constants/contractEnums";
import { TPairIds } from "../../../constants/pairsConstants";
import { toastTxError } from "../../../ux/toasts/complexToasting";
import { useGetLivePositionFromActiveLed } from "../../../store/multiInstanceStoreHooks.ts";
import { priceBnToFloat } from "../../../utils/lynxScalesUtils.ts";
import { useActionType } from "../../../hooks/actionTypeHooks.ts";
import { useUrlQueryValue_chainId } from "../../../navigation/navigationStateHooks.ts";
import {
  CHAIN_ID_NO_CHAIN,
  TChainIds,
} from "../../../constants/chainConstants.ts";
import { useWaitForTx } from "../../../hooks/txWaitingHooks.tsx";
import { floatToUnitsBn, unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { PRICES_SCALE } from "../../../constants/scales.ts";

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export const ConnectedPositionUpdateModal = observer<
  React.FunctionComponent<IProps>
>((props) => {
  const { open, closeModal } = props;

  const modalsStore = useModalsStore();
  const pricesStore = usePricesStore();
  const activeLedStore = useActiveLexStoreStore();
  const position = useGetLivePositionFromActiveLed(
    modalsStore.positionIdToUpdate,
  );

  const { setTxHash, data } = useWaitForTx(
    activeLedStore?.cryptoWalletConnectionStore.chainId ?? CHAIN_ID_NO_CHAIN,
  );

  const currentAssetPrice = position
    ? pricesStore.getPriceForPairId(Number(position.pairId) as TPairIds)
    : 0;

  const { queryChainId } = useUrlQueryValue_chainId();

  const updateTpFunction = useCallback(
    (fieldValueInUnits: number) => {
      const eoaActionType = useActionType(Number(queryChainId) as TChainIds);
      if (position && activeLedStore) {
        activeLedStore.activeUserStore
          .requestUpdatePositionField(
            eoaActionType,
            position.id,
            PositionFieldEnums.TP,
            fieldValueInUnits,
          )
          .then((v) => {
            setTxHash(v.txHash);
            closeModal();
          })
          .catch((e) => toastTxError(e));
      }
    },
    [activeLedStore, closeModal, position, queryChainId, setTxHash],
  );

  const updateSlFunction = useCallback(
    (fieldValueInUnits: number) => {
      const eoaActionType = useActionType(Number(queryChainId) as TChainIds);
      if (position && activeLedStore) {
        activeLedStore.activeUserStore
          .requestUpdatePositionField(
            eoaActionType,
            position.id,
            PositionFieldEnums.SL,
            fieldValueInUnits,
          )
          .then((v) => {
            setTxHash(v.txHash);
            closeModal();
          })
          .catch((e) => toastTxError(e));
      }
    },
    [activeLedStore, closeModal, position, queryChainId, setTxHash],
  );

  const updateTpAndSlFunction = useCallback(
    (tpValueInUnits: number, slValueInUnits: number) => {
      const eoaActionType = useActionType(Number(queryChainId) as TChainIds);
      if (position && activeLedStore) {
        activeLedStore.activeUserStore
          .requestUpdateDoublePositionField(
            eoaActionType,
            position.id,
            tpValueInUnits,
            slValueInUnits,
          )
          .then((v) => {
            setTxHash(v.txHash);
            closeModal();
          })
          .catch((e) => toastTxError(e));
      }
    },
    [activeLedStore, closeModal, position, queryChainId, setTxHash],
  );

  const {
    updateFunction,
    currentValueInUnits,
    minValueInUnits,
    maxValueInUnits,
  } = useMemo(() => {
    const emptyFunction = (fieldValueInUnits: number) => undefined;

    let updateFunction: (fieldValueInUnits: number) => unknown = emptyFunction;
    let currentValueInUnits = 0;
    let minValueInUnits = 0;
    let maxValueInUnits = 0;

    const eoaActionType = useActionType(Number(queryChainId) as TChainIds);

    if (position && activeLedStore) {
      const anyHighPriceUntilUsingRealLimitCalculation = 2_000_000;

      const tempCompilationEnumValue = PositionFieldEnums.SL;

      // if (modalsStore.positionFieldToUpdate === PositionFieldEnums.TP) {
      if (tempCompilationEnumValue === PositionFieldEnums.TP) {
        updateFunction = (fieldValueInUnits: number) =>
          activeLedStore.activeUserStore
            .requestUpdatePositionField(
              eoaActionType,
              position.id,
              PositionFieldEnums.TP,
              fieldValueInUnits,
            )
            .then((v) => {
              setTxHash(v.txHash);
              closeModal();
            })
            .catch((e) => toastTxError(e));
        currentValueInUnits = priceBnToFloat(position.tp);

        // TODO : Use real limits for 'min/max value'
        if (position.long) {
          maxValueInUnits = anyHighPriceUntilUsingRealLimitCalculation;
          minValueInUnits = currentAssetPrice;
        } else {
          maxValueInUnits = currentAssetPrice;
          minValueInUnits = 0;
        }
        // } else if (modalsStore.positionFieldToUpdate === PositionFieldEnums.SL) {
      } else if (tempCompilationEnumValue === PositionFieldEnums.SL) {
        updateFunction = (fieldValueInUnits: number) =>
          activeLedStore.activeUserStore
            .requestUpdatePositionField(
              eoaActionType,
              position.id,
              PositionFieldEnums.SL,
              fieldValueInUnits,
            )
            .then((v) => {
              setTxHash(v.txHash);
              closeModal();
            })
            .catch((e) => toastTxError(e));

        currentValueInUnits = priceBnToFloat(position.sl);

        // TODO : Use real limits for 'min/max value'
        if (position.long) {
          maxValueInUnits = currentAssetPrice;
          minValueInUnits = 0;
        } else {
          maxValueInUnits = anyHighPriceUntilUsingRealLimitCalculation;
          minValueInUnits = currentAssetPrice;
        }
      }
    }

    return {
      updateFunction,
      currentValueInUnits,
      minValueInUnits,
      maxValueInUnits,
    };
  }, [
    queryChainId,
    position,
    activeLedStore,
    setTxHash,
    closeModal,
    currentAssetPrice,
  ]);

  return (
    <PositionUpdateModal
      closeModal={closeModal}
      open={open}
      pos={position}
      modalTheme={DEFAULT_MODAL_THEME}
      requestTpUpdate={updateTpFunction}
      requestSlUpdate={updateSlFunction}
      requestTpAndSlUpdate={updateTpAndSlFunction}
    />
  );
});
