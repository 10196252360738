import { ethers } from "ethers";

function generateVerificationDomain(
  chainId: string,
  liquidityIntentsVerifierAddress: string,
): ethers.TypedDataDomain {
  const verificationDomain: ethers.TypedDataDomain = {
    chainId: chainId,
    name: "Liquidity Intents Verifier",
    version: "1",
    verifyingContract: liquidityIntentsVerifierAddress,
  };

  return verificationDomain;
}

const LIQUIDITY_PROVIDER_REQUEST_PAYLOAD_TYPE_DEFINITION_EPOCH_DEPOSIT: Record<
  string,
  ethers.TypedDataField[]
> = {
  LiquidityProviderRequestPayload_EpochDeposit: [
    { name: "nonce", type: "uint256" },
    { name: "pool", type: "address" },
    { name: "liquidityProvider", type: "address" },
    { name: "epoch", type: "uint256" },
    { name: "amount", type: "uint256" },
    { name: "minAmountOut", type: "uint256" },
  ],
};

const LIQUIDITY_PROVIDER_REQUEST_PAYLOAD_TYPE_DEFINITION_EPOCH_REDEEM: Record<
  string,
  ethers.TypedDataField[]
> = {
  LiquidityProviderRequestPayload_EpochRedeem: [
    { name: "nonce", type: "uint256" },
    { name: "pool", type: "address" },
    { name: "liquidityProvider", type: "address" },
    { name: "epoch", type: "uint256" },
    { name: "amount", type: "uint256" },
    { name: "minAmountOut", type: "uint256" },
  ],
};

export const liquidityIntentsVerifierPayloadTypes = {
  buildDomainSeparator: generateVerificationDomain,
  LIQUIDITY_PROVIDER_REQUEST_PAYLOAD_TYPE_DEFINITION_EPOCH_DEPOSIT,
  LIQUIDITY_PROVIDER_REQUEST_PAYLOAD_TYPE_DEFINITION_EPOCH_REDEEM,
};
