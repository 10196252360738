import React, { useEffect, useMemo, useState } from "react";
import { positionPanelConstants } from "../../../constants/positionPanelConstants.ts";
import { numbersUtils } from "../../../utils/numbers.ts";
import {
  usePairAssetPrice,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import { TPairIds } from "../../../constants/pairsConstants.ts";
import {
  useUrlQueryValue_chainId,
  useUrlQueryValue_sa,
} from "../../../navigation/navigationStateHooks.ts";
import { useCreateLexSelectOptions } from "../../../hooks/assetHooks.ts";
import {
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_NO_CHAIN,
  TChainIds,
  TSourceChainIds,
} from "../../../constants/chainConstants.ts";
import { CurrencySelection } from "lynx-ui-components";
import { numericalDisplay } from "../../../ux/displayCalculations.ts";
import { TSummaryTitleAndValue } from "./sections/TradePairPanelInfoSection.tsx";
import { etherBnToFloat } from "../../../utils/bignumbers.ts";
import { NATIVE_FEE_FOR_REQUEST_OPEN } from "../../../store/SystemStore.ts";
import { localStringer } from "../../../ux/uxFunctions.ts";

export function useIsCatModePair(pairId: number) {
  const isCatMode = useMemo(() => {
    return pairId > 10_000;
  }, [pairId]);

  return isCatMode;
}

export function useLeverageOptionsForPair(pairId: number) {
  const isCatMode = useIsCatModePair(pairId);

  const { leverageMin, leverageMax, leverageOptions } = useMemo(() => {
    let leverageMin = 2;
    let leverageMax = 100;
    let leverageOptions = [2, 20, 40, 60, 80, 100];

    if (isCatMode) {
      leverageMin = 100;
      leverageMax = 500;
      leverageOptions = [100, 200, 300, 400, 500];
    }

    return {
      leverageMin,
      leverageMax,
      leverageOptions,
    };
  }, [isCatMode]);

  return { leverageMin, leverageMax, leverageOptions };
}

export function useLimitOptionsForPair(pairId: number) {
  const isCatMode = useIsCatModePair(pairId);

  const { slMarks, tpMarks } = useMemo(() => {
    let slMarks;
    let tpMarks;

    if (isCatMode) {
      slMarks = positionPanelConstants.catPairsLimits.slMarks;
      tpMarks = positionPanelConstants.catPairsLimits.tpMarks;
    } else {
      slMarks = positionPanelConstants.normalPairsLimits.slMarks;
      tpMarks = positionPanelConstants.normalPairsLimits.tpMarks;
    }

    return {
      slMarks,
      tpMarks,
    };
  }, [isCatMode]);

  return { slMarks, tpMarks };
}

export function useLimitOrderStateAndLogic(
  pairId: TPairIds,
  isLimitOrder: boolean,
  slippagePInUnits: string,
) {
  const pricesStore = usePricesStore();

  const pairAssetPrice = usePairAssetPrice(pairId);
  const trimmedPairAssetPrice = parseFloat(pairAssetPrice.toFixed(4));

  // States for Limit order
  const [wantedMinOpenPrice, setWantedMinOpenPrice] = useState(0);
  const [wantedMaxOpenPrice, setWantedMaxOpenPrice] = useState(0);

  // Note : The combination of this effect with the next two causes the min and max price to be set to the asset
  //          price once the selected pair is changed
  useEffect(() => {
    const refreshOnPairId = pairId;
    setWantedMaxOpenPrice(0);
    setWantedMinOpenPrice(0);
  }, [pairId]);

  useEffect(() => {
    if (wantedMinOpenPrice == 0 && trimmedPairAssetPrice != 0) {
      setWantedMinOpenPrice(trimmedPairAssetPrice);
    }
  }, [trimmedPairAssetPrice, wantedMinOpenPrice]);

  useEffect(() => {
    if (wantedMaxOpenPrice == 0 && trimmedPairAssetPrice != 0) {
      setWantedMaxOpenPrice(trimmedPairAssetPrice);
    }
  }, [trimmedPairAssetPrice, wantedMaxOpenPrice]);

  const [wantedEntryPriceForLimitInput, setWantedEntryPriceForLimitInput] =
    useState("");
  const wantedEntryPriceForLimitNumeric = Number(
    numbersUtils.parseFloatFromLocalString(wantedEntryPriceForLimitInput),
  );
  useEffect(() => {
    if (isLimitOrder) {
      const pairPrice = pricesStore.getPriceForPairId(pairId);
      setWantedEntryPriceForLimitInput(pairPrice.toString());
    }
  }, [isLimitOrder, pairId, pricesStore]);

  useEffect(() => {
    const slippagePInUnitsNumeric = Number(slippagePInUnits);
    const slippageSize =
      (slippagePInUnitsNumeric * wantedEntryPriceForLimitNumeric) / 100;

    if (isLimitOrder) {
      if (wantedEntryPriceForLimitNumeric <= pairAssetPrice) {
        setWantedMaxOpenPrice(wantedEntryPriceForLimitNumeric);
        setWantedMinOpenPrice(wantedEntryPriceForLimitNumeric - slippageSize);
      } else {
        setWantedMinOpenPrice(wantedEntryPriceForLimitNumeric);
        setWantedMaxOpenPrice(wantedEntryPriceForLimitNumeric + slippageSize);
      }
    }
  }, [
    isLimitOrder,
    pairAssetPrice,
    slippagePInUnits,
    wantedEntryPriceForLimitNumeric,
  ]);

  return {
    wantedMinOpenPrice,
    wantedMaxOpenPrice,
    wantedEntryPriceForLimitNumeric,
    wantedEntryPriceForLimitInput,
    setWantedEntryPriceForLimitInput,
  };
}

export function useConnectedCurrencySelectionComponent(
  onPairSelected: (pairIdStr: string) => void,
) {
  const { queryPairSettlementAsset } = useUrlQueryValue_sa();
  const { queryChainId } = useUrlQueryValue_chainId();

  const lexSelectOptions = useCreateLexSelectOptions(
    (Number(queryChainId) as TChainIds) ?? CHAIN_ID_NO_CHAIN,
  );

  const collateralAssetComponent = useMemo(() => {
    return (
      <CurrencySelection
        value={queryPairSettlementAsset ?? lexSelectOptions[0].value}
        options={lexSelectOptions}
        onChange={onPairSelected}
        // rememberSelected
      />
    );
  }, [lexSelectOptions, onPairSelected, queryPairSettlementAsset]);

  return collateralAssetComponent;
}

export function useSummaryItemsForPositionOpeningPanel(
  sourceChainId: TSourceChainIds,
  settlementAssetSymbol: string,
  pairAssetName: string,
  leveragedPositionSizeInUnits: number,
  expectedOpenFee: number,
  openFeeFInUnits: number,
  liquidationPrice: number,
) {
  const summaryItems = useMemo(() => {
    const items: TSummaryTitleAndValue[] = [
      {
        title: "Leveraged Position Size",
        value: `${numericalDisplay(
          leveragedPositionSizeInUnits,
          2,
        )} ${settlementAssetSymbol}`,
        titleTooltip: `Collateral * Leverage`,
      },
      {
        title: "Liquidation Price",
        value: `$${numericalDisplay(liquidationPrice, 2)}`,
        titleTooltip: `If ${pairAssetName} reaches this price before your Stop Loss is triggered, your position will be automatically closed `,
      },
      {
        title: "Open Fee",
        value: `${numericalDisplay(
          expectedOpenFee,
          4,
        )} ${settlementAssetSymbol} (${numericalDisplay(
          openFeeFInUnits * 100,
          3,
        )}%)`,
        variant: "caption",
        titleTooltip: `This amount will be deducted from your collateral when opening a trade`,
      },
    ];

    if (sourceChainId === CHAIN_ID_FANTOM_OPERA) {
      const feeInUnits = etherBnToFloat(NATIVE_FEE_FOR_REQUEST_OPEN);

      items.push({
        title: "Execution Fee",
        value: `${localStringer(feeInUnits)} FTM`,
        variant: "caption",
        titleTooltip: `This amount will be sent to the protocol to execute your trade`,
      });
    }

    return items;
  }, [
    expectedOpenFee,
    leveragedPositionSizeInUnits,
    liquidationPrice,
    openFeeFInUnits,
    pairAssetName,
    settlementAssetSymbol,
    sourceChainId,
  ]);

  return summaryItems;
}
