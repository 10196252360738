// import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react";

// TODO : Ensure app needed font
// Note : Think about a CDN
import "@fontsource/inter";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

// Note : For react-toastify
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MainAppRouter from "./structureAndRoutes/MainAppRouter.tsx";
import { useShouldBlockIp } from "./ux/ipBlocksHooks.ts";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui-compat";

const useStyles = makeStyles()(() => ({
  toastContainer: {
    "& .successUi, & .Toastify__toast--error, & .Toastify__toast--warning": {
      padding: "12px 20px",
      borderRadius: "12px",

      "& .Toastify__toast-body": {
        fontFamily: "Helvetica",
        fontSize: "12px",
        lineHeight: "17px",
        fontWeight: "400",
        color: "#FFFFFF",

        "> .Toastify__toast-icon": {
          display: "none",
        },
      },

      "& .Toastify__close-button": {
        position: "relative",
        top: "9px",
        right: "7px",

        "> svg": {
          color: "#9895AA",
        },
      },
    },

    "& .successUi": {
      backgroundColor: "#0D4934",
      border: "1px solid #03A66D",
      boxShadow: "0px 0px 32px 0px rgba(3, 166, 109, 0.32)",
    },

    "& .Toastify__toast--error": {
      backgroundColor: "#55272E",
      border: "1px solid #DD434C",
      boxShadow: "0px 0px 32px 0px rgba(221, 67, 76, 0.32)",
    },

    "& .Toastify__toast--warning": {
      backgroundColor: "#ff9622",
      border: "1px solid #F8FF22",
      boxShadow: "0px 0px 32px 0px #f8ff2230",
    },
  },
}));

export const App = observer(() => {
  const shouldBlockApp = useShouldBlockIp();
  const { classes } = useStyles();

  if (shouldBlockApp) {
    return (
      <Box
        sx={{
          flex: 1,
          textAlign: "center",

          height: "100%",
          backgroundColor: "black",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant={"h3"}>
          Access to LYNX is blocked from your location
        </Typography>
      </Box>
    );
  }

  return (
    <BrowserRouter basename={import.meta.env.BASE_URL ?? "/"}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <MainAppRouter />
        <ToastContainer className={classes.toastContainer} />
      </QueryParamProvider>
    </BrowserRouter>
  );
});

export default App;
