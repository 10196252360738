import { makeStyles } from "tss-react/mui";
import React, { DetailedHTMLProps } from "react";
import {
  MAX_CONTENT_CONTAINER_PADDING,
  MAX_CONTENT_WIDTH_REM,
} from "../../ux/uxConstants";
interface IProps {}

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    height: "100%",
    width: `clamp(80%, ${MAX_CONTENT_WIDTH_REM}rem, 98% )`,
    maxWidth: `${MAX_CONTENT_WIDTH_REM}rem`,

    [theme.breakpoints.down("lg")]: {
      paddingRight: `${MAX_CONTENT_CONTAINER_PADDING}rem`,
      paddingLeft: `${MAX_CONTENT_CONTAINER_PADDING}rem`,
    },
    // overflow: "auto",

    // This is about the point where the content will become more mobiley
    [theme.breakpoints.down("md")]: {
      paddingRight: `${MAX_CONTENT_CONTAINER_PADDING / 2}rem`,
      paddingLeft: `${MAX_CONTENT_CONTAINER_PADDING / 2}rem`,
    },

    // Mobile
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
    },
  },
}));

export const ContentContainer = React.memo<
  IProps &
    DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props) => {
  const { children, ...rest } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.contentContainer} {...rest}>
      {children}
    </div>
  );
});
