import React from "react";
import { makeStyles } from "tss-react/mui";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
  logoSpan: {
    height: `100%`,
    width: `100%`,
    backgroundImage: `url(/assets/lynx/logo.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "transparent",
  },
}));

export const LynxLogo = React.memo<IProps>((props) => {
  const { classes } = useStyles();

  return <div className={classes.logoSpan} />;
});

export default LynxLogo as (props: IProps) => React.JSX.Element;
