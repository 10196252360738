// eslint-disable @typescript-eslint/no-unsafe-member-access
import { gql, request } from "graphql-request";
import {
  EMPTY_GRAPH_INFO_EPOCH,
  EMPTY_GRAPH_INFO_USAGE_ROUND,
  EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER,
  IGraphQlService,
  TChipHistoryActionGist,
  TClosedPositionGist,
  TEpochGraphInfo,
  TLiquidityRequestGist,
  TPoolCompetitionHistoricReward,
  TUsageRoundGraphInfo,
  TUsageRoundTraderGraphInfo,
  TUserInteractionsRecordGraphInfo,
} from "./IGraphQLService";
import { scaledLeverageToUnits } from "../../../utils/leverageCalculationsUtils";

let queryCounter = 0;

export class GraphQLService implements IGraphQlService {
  constructor(private uri: string) {}

  trackGraphQueryUsage(functionName: string, infoString: string) {
    queryCounter = queryCounter + 1;
    console.log(
      `%%%%%%% GRAPH ${functionName} ${infoString} ||| ${queryCounter}`,
    );
  }

  // ************* Positions *************

  async getClosedPositionsForTrader(
    settlementAsset: string,
    traderAddress: string,
  ): Promise<TClosedPositionGist[]> {
    return this.getTraderPositionByPhase(
      settlementAsset,
      traderAddress,
      "CLOSED",
    );
  }

  async getCancelledPositionsForTrader(
    settlementAsset: string,
    traderAddress: string,
  ): Promise<TClosedPositionGist[]> {
    return this.getTraderPositionByPhase(
      settlementAsset,
      traderAddress,
      "CANCELLED",
    );
  }

  private async getTraderPositionByPhase(
    settlementAsset: string,
    traderAddress: string,
    phase: string,
  ): Promise<TClosedPositionGist[]> {
    this.trackGraphQueryUsage(
      "getTraderPositionByPhase",
      `${settlementAsset} -- ${traderAddress} -- ${phase}`,
    );

    // TODO : Add back
    //  canceller
    //  cancellationFee
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const query = gql`
      query getClosedPositions(
        $traderAddress: String
        $phase: String
        $settlementAsset: String
      ) {
        positions(
          where: {
            trader_: { id: $traderAddress }
            currentPhase: $phase
            pool_: { underlying_contains: $settlementAsset }
          }
        ) {
          id
          positionId

          trader {
            id
          }
          pairId
          positionIndex
          sameIdCounter

          pool {
            underlying
          }

          direction

          openTimestamp
          openTx
          openPrice
          openOrderType

          openFeeForLexPool
          openFeeForSystem

          isMarketTimeoutCancelled
          isMarketPriceRangeCancelled
          cancellationPriceOnMarketOpen
          isOpenCapCancelled
          cancellationCapType
          cancellationCapValue

          openOrderCancellationFee
          openOrderCancellationSourceRole
          cancellationTx

          tp
          tpLastUpdate
          sl
          slLastUpdate

          borrowInterestPaidOnClose
          fundingFeesPaidOnClose

          closeTimestamp
          closeTx
          closePrice
          closedAs
          closeFee

          currentPhase

          percentProfit
          leverage
          initialCollateral

          initialCollateral
          positionValueOnClose
        }
      }
    `;

    const variables = {
      traderAddress: traderAddress.toLowerCase(),
      phase: phase,
      settlementAsset: settlementAsset.toLowerCase(),
    };
    try {
      const data = await request<{ positions: object[] }>(
        this.uri,
        query,
        variables,
        {},
      );

      // console.log(`!!!! results from GraphQlQuery`);
      // console.log(JSON.stringify(data, null, 2));

      const positions: TClosedPositionGist[] = [];

      if (data.positions) {
        positions.push(
          ...data.positions.map((rawValue: any) => {
            const closedPositionGist: TClosedPositionGist = {
              entityId: rawValue.id,
              positionId: rawValue.positionId,

              requestOpenTx: rawValue.requestOpenTx ?? "",

              openTimestamp: rawValue.openTimestamp ?? 0,
              openTx: rawValue.openTx,
              openedAs: rawValue.openOrderType ?? "",
              openPrice: BigInt(rawValue.openPrice ?? 0),

              openFeeForLexPool: BigInt(rawValue.openFeeForLexPool ?? 0),
              openFeeForSystem: BigInt(rawValue.openFeeForSystem ?? 0),

              isMarketTimeoutCancelled: !!rawValue.isMarketTimeoutCancelled,

              isMarketPriceRangeCancelled:
                !!rawValue.isMarketPriceRangeCancelled,
              cancellationPriceOnMarketOpen: BigInt(
                rawValue.cancellationPriceOnMarketOpen ?? 0,
              ),
              isOpenCapCancelled: !!rawValue.isOpenCapCancelled,
              cancellationCapType: rawValue.cancellationCapType ?? "",
              cancellationCapValue: BigInt(rawValue.cancellationCapValue ?? 0),

              openOrderCancellationSourceRole:
                rawValue.openOrderCancellationSourceRole ?? "",
              cancellationTx: rawValue.cancellationTx ?? "",

              // NOTE : CURRENTLY NOT IN GRAPH
              canceller: rawValue.canceller ?? "",
              openOrderCancellationFee: BigInt(
                rawValue.openOrderCancellationFee ?? "0",
              ),

              tp: BigInt(rawValue.tp ?? 0),
              tpLastUpdate: rawValue.tpLastUpdate ?? 0,
              sl: BigInt(rawValue.sl ?? 0),
              slLastUpdate: rawValue.slLastUpdate ?? 0,

              requestCloseTx: rawValue.requestCloseTx ?? "",

              borrowInterestPaidOnClose: BigInt(
                rawValue.borrowInterestPaidOnClose ?? 0,
              ),
              fundingFeesPaidOnClose: BigInt(
                rawValue.fundingFeesPaidOnClose ?? 0,
              ),

              closeTimestamp: rawValue.closeTimestamp ?? 0,
              closeTx: rawValue.closeTx ?? "",
              closedAs: rawValue.closedAs ?? "",
              closePrice: BigInt(rawValue.closePrice ?? 0),
              closeFee: BigInt(rawValue.closeFee ?? 0),

              settlementAsset: rawValue?.pool?.underlying ?? "NO_UNDERLYING",
              traderAddress: rawValue.trader.id,
              pairId: parseInt(rawValue.pairId ?? "0"),
              positionIndex: parseInt(rawValue.positionIndex ?? "0"),
              sameIdCounter: parseInt(rawValue.sameIdCounter ?? "0"),

              long: rawValue.direction === "LONG",

              leverageInUnits: scaledLeverageToUnits(
                parseInt(rawValue.leverage),
              ),

              initialCollateral: BigInt(rawValue.initialCollateral),
              positionValueOnClose: BigInt(rawValue.positionValueOnClose ?? 0),

              // TODO : CRITICAL : Handle the problem that this value is now 'fraction'
              // percentProfit: BigInt(rawValue.percentProfit ?? 0),
              percentProfit: BigInt(0),
            };

            return closedPositionGist;
          }),
        );
      }

      return positions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  // ************* Epochs *************

  public async getFirstPoolEpochAfterTimestamp(
    poolAddress: string,
    timestamp: number,
  ): Promise<TEpochGraphInfo> {
    this.trackGraphQueryUsage(
      "getFirstPoolEpochAfterTimestamp",
      `${timestamp}`,
    );

    const query = gql`
      query getFirstPoolEpochAfterTimestamp(
        $poolAddress: String
        $timestamp: Int
      ) {
        epoches(
          where: { pool_: { id: $poolAddress }, timestamp_gte: $timestamp }
          orderBy: timestamp
          orderDirection: asc
          first: 1
        ) {
          id
          pool {
            id
          }

          epochNumber
          timestamp
          blockNumber

          exchangeRate

          amountDeposited
          amountRedeemed
        }
      }
    `;

    const variables = {
      poolAddress: poolAddress.toLowerCase(),
      timestamp,
    };

    try {
      const data = await request<{ epoches: any[] }>(
        this.uri,
        query,
        variables,
        {},
      );

      // console.log(`!!!! results from GraphQlQuery`);
      // console.log(JSON.stringify(data, null, 2));

      if (data.epoches.length > 0) {
        const raw = data.epoches[0];

        const epochGraphInfo: TEpochGraphInfo = {
          poolAddress: raw?.pool?.id ?? "NO_POOL",
          epochNumber: Number(raw?.epochNumber ?? 0),
          timestamp: Number(raw?.timestamp ?? 0),
          blockNumber: Number(raw?.blockNumber ?? 0),
          exchangeRate: BigInt(raw?.exchangeRate ?? "0"),
          amountDeposited: BigInt(raw?.exchangeRate ?? "0"),
          amountRedeemed: BigInt(raw?.exchangeRate ?? "0"),
        };

        return epochGraphInfo;
      } else {
        return EMPTY_GRAPH_INFO_EPOCH;
      }
    } catch (err) {
      console.error(err);
      return EMPTY_GRAPH_INFO_EPOCH;
    }
  }

  // ************* Chip Actions *************

  public async getChipActionHistory(
    chipAddress: string,
    userAddress: string,
  ): Promise<TChipHistoryActionGist[]> {
    this.trackGraphQueryUsage("getChipActionHistory", `${userAddress}`);

    const query = gql`
      query getChipsActions($chipAddress: String, $userAddress: String) {
        chipInOuts(
          orderDirection: desc
          orderBy: blockNumber
          where: { user: $userAddress, chip: $chipAddress }
        ) {
          amount
          blockNumber
          chip
          fee
          id
          inOut
          user
          timestamp
        }
      }
    `;

    const variables = {
      chipAddress: chipAddress.toLowerCase(),
      userAddress: userAddress.toLowerCase(),
    };
    try {
      const data = await request<{ chipInOuts: object[] }>(
        this.uri,
        query,
        variables,
        {},
      );

      // console.log(`!!!! results from GraphQlQuery`);
      // console.log(JSON.stringify(data, null, 2));

      const chipHistoricActions: TChipHistoryActionGist[] = [];

      if (data.chipInOuts) {
        chipHistoricActions.push(
          ...data.chipInOuts.map((rawValue: any) => {
            const chipHistoryActionGist: TChipHistoryActionGist = {
              userAddress: rawValue?.user ?? "",
              amount: BigInt(rawValue?.amount) ?? 0n,
              fee: BigInt(rawValue?.fee) ?? 0n,
              inOut: rawValue?.inOut as "IN" | "OUT",
              timestamp: parseInt(rawValue.timestamp ?? "0"),
              txHash: rawValue.id ?? "",
            };

            return chipHistoryActionGist;
          }),
        );
      }

      return chipHistoricActions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  // ************* Liquidity Requests *************

  public async getProcessedLiquidityRequests(
    poolAddress: string,
    providerAddress: string,
  ): Promise<TLiquidityRequestGist[]> {
    return this.getLiquidityRequestGistByState(
      poolAddress,
      providerAddress,
      "PROCESSED",
    );
  }

  public async getCancelledLiquidityRequests(
    poolAddress: string,
    providerAddress: string,
  ): Promise<TLiquidityRequestGist[]> {
    return this.getLiquidityRequestGistByState(
      poolAddress,
      providerAddress,
      "CANCELLED",
    );
  }

  private async getLiquidityRequestGistByState(
    poolAddress: string,
    providerAddress: string,
    state: string,
  ) {
    this.trackGraphQueryUsage("getLiquidityRequestGistByState", `${state}`);
    const query = gql`
      query getDepositRequests(
        $poolAddress: String
        $providerAddress: String
        $state: String
      ) {
        depositRequests(
          orderDirection: desc
          orderBy: dueEpoch__epochNumber
          where: {
            provider_: { pool: $poolAddress, address: $providerAddress }
            state: $state
          }
        ) {
          id
          provider {
            id
            pool {
              id
            }
          }

          dueEpoch {
            id
            epochNumber
          }

          state

          requestTxHash

          processTxHash
          processTimestamp

          underlyingAmount
          immediate
        }

        redeemRequests(
          orderDirection: desc
          orderBy: dueEpoch__epochNumber
          where: {
            provider_: { pool: $poolAddress, address: $providerAddress }
            state: $state
          }
        ) {
          id
          provider {
            id
            address
            pool {
              id
            }
          }

          dueEpoch {
            id
            epochNumber
          }

          state

          requestTxHash

          processTxHash
          processTimestamp

          underlyingAmountOut
        }
      }
    `;

    const variables = {
      poolAddress: poolAddress.toLowerCase(),
      providerAddress: providerAddress.toLowerCase(),
      state: state,
    };
    // console.log(stringifyObject(variables, 3));
    try {
      const data = await request<{
        depositRequests: object[];
        redeemRequests: object[];
      }>(this.uri, query, variables, {});

      // console.log(`!!!! results from GraphQlQuery getDepositRequests`);
      // console.log(JSON.stringify(data, null, 2));

      const liquidityRequestGists: TLiquidityRequestGist[] = [];

      if (data.depositRequests) {
        liquidityRequestGists.push(
          ...data.depositRequests.map((rawValue: any) => {
            const liquidityRequestGist: TLiquidityRequestGist = {
              poolAddress: rawValue?.provider?.pool?.id ?? "NO_POOL",
              providerAddress: rawValue?.provider?.address ?? "NO_PROVIDER",
              type: "DEPOSIT",
              processEpoch: parseInt(rawValue?.dueEpoch?.epochNumber ?? "0"),
              processTimestamp: parseInt(rawValue?.processTimestamp ?? "0"),
              processTxHash: rawValue.processTxHash ?? "NO_PROCESS_TX",
              assetAmount: BigInt(rawValue?.underlyingAmount ?? "0"),
              isImmediate: Boolean(rawValue?.immediate ?? "false").valueOf(),
            };

            return liquidityRequestGist;
          }),
        );
      }

      if (data.redeemRequests) {
        liquidityRequestGists.push(
          ...data.redeemRequests.map((rawValue: any) => {
            const liquidityRequestGist: TLiquidityRequestGist = {
              poolAddress: rawValue?.provider?.pool?.id ?? "NO_POOL",
              providerAddress: rawValue?.provider?.address ?? "NO_PROVIDER",
              type: "WITHDRAW",
              processEpoch: parseInt(rawValue?.dueEpoch?.epochNumber ?? "0"),
              processTimestamp: parseInt(rawValue?.processTimestamp ?? "0"),
              processTxHash: rawValue.processTxHash ?? "NO_PROCESS_TX",
              assetAmount: BigInt(rawValue?.underlyingAmountOut ?? "0"),

              isImmediate: false,
            };

            return liquidityRequestGist;
          }),
        );
      }

      // console.log(stringifyObject(liquidityRequestGists, 3));

      return liquidityRequestGists;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  // ************* Interactions Records *************

  async getAllInteractionRecordsForUser(
    userAddress: string,
  ): Promise<TUserInteractionsRecordGraphInfo[]> {
    this.trackGraphQueryUsage(
      "getAllInteractionRecordsForUser",
      `${userAddress}`,
    );

    const query = gql`
      query getInteractionRecords($userAddress: String) {
        userInteractionsRecords(where: { user: $userAddress }) {
          id
          user
          pool {
            id
            poolAddress
          }

          interactionTx_ChipIn
          interactionTx_ChipOut
          latestTimestamp_ChipIn
          latestTimestamp_ChipOut

          latestTimestamp_PositionOpened
          latestTimestamp_PositionClosed
          latestTimestamp_MarketOpenCancelled
          latestTimestamp_MarketCloseCancelled
          latestTimestamp_PoolImmediateDeposit
          latestTimestamp_PoolEpochRedeemProcessed
          latestTimestamp_PoolEpochDepositProcessed

          interactionEpochNumber_PoolImmediateDeposit
          interactionEpochNumber_PoolEpochDepositProcessed
          interactionEpochNumber_PoolEpochRedeemProcessed
        }
      }
    `;

    const variables = {
      userAddress: userAddress.toLowerCase(),
    };

    try {
      const data = await request<{
        userInteractionsRecords: object[];
      }>(this.uri, query, variables, {});

      // console.log(`!!!! results from GraphQlQuery getAllInteractionRecordsForUser`);
      // console.log(JSON.stringify(data, null, 2));

      const userInteractionsRecords: TUserInteractionsRecordGraphInfo[] = [];

      if (data.userInteractionsRecords) {
        userInteractionsRecords.push(
          ...data.userInteractionsRecords.map((rawValue: any) => {
            const interactionsRecord: TUserInteractionsRecordGraphInfo = {
              poolAddress: rawValue.pool.poolAddress,
              userAddress: rawValue.user,

              // ## Position Interactions ##

              latestTimestamp_PositionOpened:
                rawValue.latestTimestamp_PositionOpened,
              interactionEntityId_PositionOpened:
                rawValue.interactionEntityId_PositionOpened,

              latestTimestamp_PositionClosed:
                rawValue.latestTimestamp_PositionClosed,
              interactionEntityId_PositionClosed:
                rawValue.interactionEntityId_PositionClosed,

              latestTimestamp_MarketOpenCancelled:
                rawValue.latestTimestamp_MarketOpenCancelled,
              interactionEntityId_MarketOpenCancelled:
                rawValue.interactionEntityId_MarketOpenCancelled,

              latestTimestamp_MarketCloseCancelled:
                rawValue.latestTimestamp_MarketCloseCancelled,
              interactionEntityId_MarketCloseCancelled:
                rawValue.interactionEntityId_MarketCloseCancelled,

              // ## Pool Interactions ##

              latestTimestamp_PoolImmediateDeposit:
                rawValue.latestTimestamp_PoolImmediateDeposit,
              interactionEpochNumber_PoolImmediateDeposit:
                rawValue.interactionEpochNumber_PoolImmediateDeposit,

              latestTimestamp_PoolEpochDepositProcessed:
                rawValue.latestTimestamp_PoolEpochDepositProcessed,
              interactionEpochNumber_PoolEpochDepositProcessed:
                rawValue.interactionEpochNumber_PoolEpochDepositProcessed,

              latestTimestamp_PoolEpochRedeemProcessed:
                rawValue.latestTimestamp_PoolEpochRedeemProcessed,
              interactionEpochNumber_PoolEpochRedeemProcessed:
                rawValue.interactionEpochNumber_PoolEpochRedeemProcessed,

              // ## Chip Interactions ##

              latestTimestamp_ChipIn: rawValue.latestTimestamp_ChipIn,
              interactionTx_ChipIn: rawValue.interactionTx_ChipIn,

              latestTimestamp_ChipOut: rawValue.latestTimestamp_ChipOut,
              interactionTx_ChipOut: rawValue.interactionTx_ChipOut,
            };

            return interactionsRecord;
          }),
        );
      }

      // console.log(stringifyObject(userInteractionsRecords, 3));

      return userInteractionsRecords;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  // ************* Usage Rounds *************

  // TODO : See if we can unify the double query logic

  public async getLatestUsageRoundForPool(
    poolAddress: string,
  ): Promise<TUsageRoundGraphInfo> {
    this.trackGraphQueryUsage("getLatestUsageRoundForPool", `${poolAddress}`);

    const query = gql`
      query getPoolUsageRound($poolAddress: String) {
        usageRounds(
          orderBy: roundNumber
          orderDirection: desc
          where: { pool_: { id: $poolAddress } }
        ) {
          id
          pool {
            id
          }
          roundNumber

          startBlock

          startTimestamp
          endTimestamp
          positionsOpened
          totalOpenFeesPaid
          totalCloseFeesPaid
          totalPositivePnl
          totalNegativePnl
        }
      }
    `;

    const variables = {
      poolAddress: poolAddress.toLowerCase(),
    };

    try {
      const data = await request<{
        usageRounds: object[];
      }>(this.uri, query, variables, {});

      // console.log(stringifyObject(data, 3));

      const matchingUsageRoundInfo = data.usageRounds[0] as any;

      if (matchingUsageRoundInfo) {
        const usageRoundGraphInfo: TUsageRoundGraphInfo = {
          poolAddress: matchingUsageRoundInfo.pool?.id ?? "NO_POOL",
          roundNumber: matchingUsageRoundInfo.roundNumber ?? 0,
          startBlock: Number(matchingUsageRoundInfo.startBlock ?? 0),
          startTimestamp: Number(matchingUsageRoundInfo.startTimestamp ?? 0),
          endTimestamp: Number(matchingUsageRoundInfo.endTimestamp ?? 0),
          positionsOpenedCounter: Number(
            matchingUsageRoundInfo.positionsOpened ?? 0,
          ),
          totalOpenFeesPaid: BigInt(
            matchingUsageRoundInfo.totalOpenFeesPaid ?? 0,
          ),
          totalCloseFeesPaid: BigInt(
            matchingUsageRoundInfo.totalCloseFeesPaid ?? 0,
          ),
          totalPositivePnl: BigInt(
            matchingUsageRoundInfo.totalPositivePnl ?? 0,
          ),
          totalNegativePnl: BigInt(
            matchingUsageRoundInfo.totalNegativePnl ?? 0,
          ),
        };

        // console.log(stringifyObject(usageRoundGraphInfo, 3));

        return usageRoundGraphInfo;
      } else {
        return EMPTY_GRAPH_INFO_USAGE_ROUND;
      }
    } catch (err) {
      console.error(err);
      return EMPTY_GRAPH_INFO_USAGE_ROUND;
    }
  }

  public async getUsageRoundForPool(
    poolAddress: string,
    roundNumber: number,
  ): Promise<TUsageRoundGraphInfo> {
    this.trackGraphQueryUsage(
      "getUsageRoundForPool",
      `${poolAddress}-${roundNumber}`,
    );

    const query = gql`
      query getPoolUsageRound($poolAddress: String, $roundNumber: Int) {
        usageRounds(
          where: { pool_: { id: $poolAddress }, roundNumber: $roundNumber }
        ) {
          id
          pool {
            id
          }
          roundNumber

          startBlock

          startTimestamp
          endTimestamp
          positionsOpened
          totalOpenFeesPaid
          totalCloseFeesPaid
          totalPositivePnl
          totalNegativePnl
        }
      }
    `;

    const variables = {
      poolAddress: poolAddress.toLowerCase(),
      roundNumber,
    };

    try {
      const data = await request<{
        usageRounds: object[];
      }>(this.uri, query, variables, {});

      // console.log(stringifyObject(data, 3));

      const matchingUsageRoundInfo = data.usageRounds[0] as any;

      if (matchingUsageRoundInfo) {
        const usageRoundGraphInfo: TUsageRoundGraphInfo = {
          poolAddress: matchingUsageRoundInfo.pool?.id ?? "NO_POOL",
          roundNumber: matchingUsageRoundInfo.roundNumber ?? 0,
          startBlock: Number(matchingUsageRoundInfo.startBlock ?? 0),
          startTimestamp: Number(matchingUsageRoundInfo.startTimestamp ?? 0),
          endTimestamp: Number(matchingUsageRoundInfo.endTimestamp ?? 0),
          positionsOpenedCounter: Number(
            matchingUsageRoundInfo.positionsOpened ?? 0,
          ),
          totalOpenFeesPaid: BigInt(
            matchingUsageRoundInfo.totalOpenFeesPaid ?? 0,
          ),
          totalCloseFeesPaid: BigInt(
            matchingUsageRoundInfo.totalCloseFeesPaid ?? 0,
          ),
          totalPositivePnl: BigInt(
            matchingUsageRoundInfo.totalPositivePnl ?? 0,
          ),
          totalNegativePnl: BigInt(
            matchingUsageRoundInfo.totalNegativePnl ?? 0,
          ),
        };

        // console.log(stringifyObject(usageRoundGraphInfo, 3));

        return usageRoundGraphInfo;
      } else {
        return EMPTY_GRAPH_INFO_USAGE_ROUND;
      }
    } catch (err) {
      console.error(err);
      return EMPTY_GRAPH_INFO_USAGE_ROUND;
    }
  }

  public async getUsageRoundTrader(
    poolAddress: string,
    traderAddress: string,
    roundNumber: number,
  ): Promise<TUsageRoundTraderGraphInfo> {
    this.trackGraphQueryUsage(
      "getUsageRoundForPool",
      `${poolAddress}-${traderAddress}-${roundNumber}`,
    );
    const query = gql`
      query getPoolUsageRound(
        $poolAddress: String
        $tAddress: String
        $roundNumber: Int
      ) {
        usageRoundTraders(
          where: {
            poolAddress: $poolAddress
            traderAddress: $tAddress
            round_: { roundNumber: $roundNumber }
          }
        ) {
          id

          round {
            roundNumber
          }

          poolAddress
          traderAddress

          openedTradesCounter

          totalOpenFeesPaid
          totalCloseFeesPaid

          totalPositivePnl
          totalNegativePnl
        }
      }
    `;

    const variables = {
      poolAddress: poolAddress.toLowerCase(),
      tAddress: traderAddress.toLowerCase(),
      roundNumber,
    };

    // console.log(variables);

    try {
      const data = await request<{
        usageRoundTraders: object[];
      }>(this.uri, query, variables, {});

      // console.log(stringifyObject(data, 3));

      const matchingUsageRoundTraderInfo = data.usageRoundTraders[0] as any;

      if (matchingUsageRoundTraderInfo) {
        const usageRoundTraderGraphInfo: TUsageRoundTraderGraphInfo = {
          entityId: matchingUsageRoundTraderInfo.id ?? "",
          traderAddress: matchingUsageRoundTraderInfo.traderAddress ?? "",
          roundNumber: Number(
            matchingUsageRoundTraderInfo.round.roundNumber ?? 0,
          ),
          openedTradesCounter: Number(
            matchingUsageRoundTraderInfo.openedTradesCounter ?? 0,
          ),
          totalOpenFeesPaid: BigInt(
            matchingUsageRoundTraderInfo?.totalOpenFeesPaid ?? "0",
          ),
          totalCloseFeesPaid: BigInt(
            matchingUsageRoundTraderInfo?.totalCloseFeesPaid ?? "0",
          ),
          totalPositivePnl: BigInt(
            matchingUsageRoundTraderInfo?.totalPositivePnl ?? "0",
          ),
          totalNegativePnl: BigInt(
            matchingUsageRoundTraderInfo?.totalNegativePnl ?? "0",
          ),
        };

        // console.log(stringifyObject(usageRoundTraderGraphInfo, 3));

        return usageRoundTraderGraphInfo;
      } else {
        return EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER;
      }
    } catch (err) {
      console.error(err);
      return EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER;
    }
  }

  public async getLeaderBoardRoundTraders(
    poolAddress: string,
    usageRoundNumber: number,
  ): Promise<TUsageRoundTraderGraphInfo[]> {
    this.trackGraphQueryUsage(
      "getLeaderBoardRoundTrader",
      `${poolAddress}-${usageRoundNumber}`,
    );

    const query = gql`
      query getUsageRoundTraders($poolAddress: String, $roundNumber: Int) {
        usageRoundTraders(
          where: {
            poolAddress: $poolAddress
            round_: { roundNumber: $roundNumber }
          }

          orderBy: totalPositivePnl
          orderDirection: desc
        ) {
          id
          totalCloseFeesPaid
          totalNegativePnl
          totalOpenFeesPaid
          totalPositivePnl
          traderAddress
          openedTradesCounter
        }
      }
    `;

    try {
      const data = await request<{
        usageRoundTraders: TUsageRoundTraderGraphInfo[];
      }>(
        this.uri,
        query,
        {
          poolAddress: poolAddress.toLowerCase(),
          roundNumber: usageRoundNumber,
        },
        {},
      );

      const usageRoundTradersInfo: TUsageRoundTraderGraphInfo[] = [];

      if (data.usageRoundTraders) {
        usageRoundTradersInfo.push(
          ...data.usageRoundTraders.map((raw) => {
            const usageRoundTrader: TUsageRoundTraderGraphInfo = {
              entityId: (raw as any).id ?? "",
              roundNumber: raw.roundNumber,
              traderAddress: raw.traderAddress,
              openedTradesCounter: raw.openedTradesCounter,
              totalCloseFeesPaid: BigInt(raw.totalCloseFeesPaid),
              totalNegativePnl: BigInt(raw.totalNegativePnl),
              totalOpenFeesPaid: BigInt(raw.totalOpenFeesPaid),
              totalPositivePnl: BigInt(raw.totalPositivePnl),
            };

            return usageRoundTrader;
          }),
        );
      }

      return usageRoundTradersInfo;
    } catch (err) {
      console.error(err);
      return [EMPTY_GRAPH_INFO_USAGE_ROUND_TRADER];
    }
  }

  // ************* Competitions *************

  public async getCompetitionsHistoricDistributionValues(): Promise<
    TPoolCompetitionHistoricReward[]
  > {
    this.trackGraphQueryUsage("getCompetitionsHistoricDistributionValues", "");

    const query = gql`
      query getPoolCompetitionRewardedHistories {
        poolCompetitionRewardedHistories {
          id
          pool {
            id
          }
          rewardAsset
          amount
        }
      }
    `;

    try {
      const data = await request<{
        poolCompetitionRewardedHistories: any[];
      }>(this.uri, query, undefined, {});

      const poolHistoricDistributions: TPoolCompetitionHistoricReward[] = [];

      if (data.poolCompetitionRewardedHistories) {
        poolHistoricDistributions.push(
          ...data.poolCompetitionRewardedHistories.map((raw) => {
            const poolCompetitionHistoricReward: TPoolCompetitionHistoricReward =
              {
                poolAddress: raw.pool?.id ?? "",
                rewardAddress: raw.rewardAsset ?? "",
                totalAmount: raw.amount ?? 0n,
              };

            return poolCompetitionHistoricReward;
          }),
        );
      }

      return poolHistoricDistributions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}
