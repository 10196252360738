import React from "react";
import { observer } from "mobx-react";
import { TCompetitionConfigs } from "../../competitionsConfigs.ts";
import { Box } from "@mui/material";
import CompetitionInfoRow from "./CompetitionInfoRow.tsx";

interface IProps {
  competitionConfigs: TCompetitionConfigs;
  isUserEligible: boolean;
}

export const ConnectedCompetitionInfoRow = observer<React.FC<IProps>>(
  (props) => {
    const { competitionConfigs, isUserEligible } = props;

    return (
      <CompetitionInfoRow
        competitionConfigs={competitionConfigs}
        isUserEligible={isUserEligible}
      />
    );
  },
);

export default ConnectedCompetitionInfoRow;
