import React, { useMemo } from "react";
import { observer } from "mobx-react";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import {
  HistoricRewardsTable,
  THistoricRewardsTableRowGist,
} from "./HistoricRewardsTable.tsx";
import { useQuery } from "@tanstack/react-query";
import { GraphQLService } from "../../../services/servicesIntergration/graphqlService/GraphQLService.ts";
import {
  BOBA_GRAPH_URL,
  FANTOM_GRAPH_URL,
} from "../../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { getRewardTokenSymbol } from "../../../constants/rewardsConstants.ts";
import { chipsBnToUnits } from "../../../utils/chipsCalculationsUtils.ts";

interface IProps {}

export const ConnectedHistoricRewardsTable = observer<React.FC<IProps>>(
  (props) => {
    const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();

    const leverageDimensionsStore = useLeverageDimensionsStore();

    const pricesStore = usePricesStore();

    const { fantomGraphQlService } = useMemo(() => {
      const fantomGraphQlService = new GraphQLService(FANTOM_GRAPH_URL);
      const bobaGraphQlService = new GraphQLService(BOBA_GRAPH_URL);

      return {
        fantomGraphQlService,
        bobaGraphQlService,
      };
    }, []);

    const pastCompetitionsDistributionValuesQuery = useQuery({
      queryFn: () =>
        fantomGraphQlService.getCompetitionsHistoricDistributionValues(),
      queryKey: ["getCompetitionsHistoricDistributionValues"],
      staleTime: 1000 * 60 * 5,
    });

    const historicRewardsRowGists = useMemo<
      THistoricRewardsTableRowGist[]
    >(() => {
      const rowGists: THistoricRewardsTableRowGist[] = [];

      for (const pastCompetitionDistribution of pastCompetitionsDistributionValuesQuery.data ??
        []) {
        const lexStore = leverageDimensionsStore.getLexStoreByPoolAddress(
          pastCompetitionDistribution.poolAddress,
        );

        const rewardSymbol = getRewardTokenSymbol(
          pastCompetitionDistribution.rewardAddress,
        );

        const rowGist: THistoricRewardsTableRowGist = {
          sourceChainId: lexStore.sourceChainId,
          poolAssetSymbol: lexStore.sourceAssetParameters.symbol,
          rewardAssetSymbol: rewardSymbol,
          totalRewardsDistributedInUnits: chipsBnToUnits(
            pastCompetitionDistribution.totalAmount,
          ),
          rewardAssetPriceInUnits:
            pricesStore.symbolToPriceMap.get(rewardSymbol) ?? 0,
        };

        rowGists.push(rowGist);
      }

      return rowGists;
    }, [
      leverageDimensionsStore,
      pastCompetitionsDistributionValuesQuery.data,
      pricesStore.symbolToPriceMap,
    ]);

    return (
      <HistoricRewardsTable
        rowGists={historicRewardsRowGists}
        connectedChainId={cryptoWalletConnectionStore.chainId}
      />
    );
  },
);
