import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useStructureClasses } from "../theme/commonClasses.ts";
import { ContentContainer } from "../components/structure/ContentContainer.tsx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ConnectedHeader } from "../components/structure/Header/ConnectedHeader.tsx";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
  useModalsStore,
  useUpdatePricesPeriodically,
} from "../store/storeHooks.ts";
import { useAccount } from "wagmi";
import { CHAIN_ID_NO_CHAIN } from "../constants/chainConstants.ts";
import {
  QUERY_PARAMS_DEFAULTS,
  QUERY_PARAMS_KEYS,
  useUrlQueryState_activeChain,
} from "../navigation/navigationStateHooks.ts";
import { ConnectedChipInLiquidityModal } from "../components/Modals/ChipLiquidityModal/ConnectedChipInLiquidityModal.tsx";
import { ConnectedChipOutLiquidityModal } from "../components/Modals/ChipLiquidityModal/ConnectedChipOutLiquidityModal.tsx";
import { usePersistSingleLZBridgeTxAndReactToChanges } from "../pages/PortfolioPage/pageHooks/chipsPageHooks.ts";
import { AppFooter } from "../components/structure/Footer/AppFooter.tsx";
import { useSyncWalletAndStore } from "../hooks/walletConnectionHooks.ts";
import { useWhiteLabelIdentifier } from "../whitelabels/whiteLabelHooks.ts";
import {
  useSyncInteractionRecordsForUser,
  useSyncLivePlans,
  useSyncRpcsFromBaas,
} from "../hooks/appInitializationHooks/appInitializationHooks.ts";
import { LeaderBoardModalWrapper } from "../components/Modals/modalWrappers/LeaderBoardModalWrapper.tsx";

export const AppMainPage: React.FC = observer((props) => {
  const { classes: structureClasses, cx: structureClassesCX } =
    useStructureClasses();

  // Account connection
  const walletConnectionStore = useCryptoWalletIntegrationStore();

  const modalsStore = useModalsStore();

  const hideLeaderBoardModal = useCallback(() => {
    modalsStore.hideLeaderboardModal();
  }, [modalsStore]);

  const leverageDimensionsStore = useLeverageDimensionsStore();

  useSyncWalletAndStore();

  useSyncRpcsFromBaas();

  const { setQueryChainId, queryChainId } = useUrlQueryState_activeChain();

  const { chain } = useAccount();

  const walletChainId = walletConnectionStore.chainId;
  useEffect(() => {
    if (chain?.id && chain?.id != walletChainId) {
      setQueryChainId(chain.id.toString());
      // NOTE : Quick hack to force window reload on chain switch
      if (walletConnectionStore.chainId == CHAIN_ID_NO_CHAIN) {
        // walletConnectionStore.setChainId(chain.id as TChainIds);
      } else {
        // window.location.reload();
      }
    }
  }, [chain?.id, setQueryChainId, walletChainId, walletConnectionStore]);

  // Sync Interaction records for connected user
  useSyncInteractionRecordsForUser(walletConnectionStore.mainAddress);

  // NOTE : One time initialization to all lex stores
  const lexStoresLength = leverageDimensionsStore.lexStores.length;
  useEffect(() => {
    console.log(
      `^^^ Starting initial initialization || lexStoresLength ${lexStoresLength} || mainAddress ${walletConnectionStore.mainAddress}`,
    );
    const lexStoresLengthInner = lexStoresLength;

    leverageDimensionsStore.lexStores.forEach(
      (lexStore) => void lexStore.refreshFromOutside(),
    );
    console.log(`^^^ Initial initialization`);
  }, [
    leverageDimensionsStore.lexStores,
    lexStoresLength,
    walletConnectionStore.mainAddress,
  ]);

  // Interval for price updating
  // NOTE : This currently only affects the SA prices fetched from CoinGecko
  useUpdatePricesPeriodically();

  // TODO : Think about where to put this, also fix the 'only one pending tx in storage' limitation
  const { pendingBridgeTxGist, setPendingBridgeTxGist } =
    usePersistSingleLZBridgeTxAndReactToChanges(
      walletConnectionStore.mainAddress,
    );

  const navigate = useNavigate();
  const location = useLocation();

  const {
    isOnAnyWhiteLabel,
    whiteLabelRoutePrefix,
    whiteLabelPageTitle,
    whiteLabelFaviconUrl,
  } = useWhiteLabelIdentifier();
  const isOnWhiteLabelBasePath =
    isOnAnyWhiteLabel &&
    [`/${whiteLabelRoutePrefix}`, `/${whiteLabelRoutePrefix}/`].includes(
      location.pathname,
    );

  // console.log(`@!!!!!! location.pathname ${location.pathname}`);

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "" ||
      isOnWhiteLabelBasePath
    ) {
      const searchParams = new URLSearchParams(location.search);
      const cID = searchParams.get(QUERY_PARAMS_KEYS.chainId);

      let safeAddition = "";
      if (searchParams.size == 0) {
        safeAddition = "?";
      }

      safeAddition += cID
        ? ""
        : `&${QUERY_PARAMS_KEYS.chainId}=${QUERY_PARAMS_DEFAULTS.chainId}`;

      const whitelabelBasePathAddition = isOnAnyWhiteLabel
        ? isOnWhiteLabelBasePath
          ? ""
          : `${whiteLabelRoutePrefix}/`
        : "";

      document.title = whiteLabelPageTitle;

      navigate(
        `./${whitelabelBasePathAddition}leverage${location.search}${safeAddition}`,
        {
          // replace: true,
        },
      );
    }
  }, [
    isOnAnyWhiteLabel,
    isOnWhiteLabelBasePath,
    location.pathname,
    location.search,
    navigate,
    whiteLabelRoutePrefix,
  ]);

  const onChangeLexIdForIn = useCallback(
    (lexId: string) => {
      modalsStore.showChipInModal(lexId);
    },
    [modalsStore],
  );

  const onChangeLexIdForOut = useCallback(
    (lexId: string) => {
      modalsStore.showChipOutModal(lexId);
    },
    [modalsStore],
  );

  // Sync all live plans
  useSyncLivePlans();

  const updateAppFavicon = useCallback((iconUrl: string) => {
    const favicon =
      document.querySelector('link[rel="icon"]') ??
      document.createElement("link");
    // @ts-expect-error
    favicon.rel = "icon";
    // @ts-expect-error
    favicon.href = iconUrl;

    console.log(`Updating favicon to ${iconUrl}`);

    document.head.appendChild(favicon);
  }, []);

  useEffect(() => {
    if (isOnAnyWhiteLabel && whiteLabelFaviconUrl) {
      updateAppFavicon(whiteLabelFaviconUrl);
    }
  }, [isOnAnyWhiteLabel, updateAppFavicon, whiteLabelFaviconUrl]);

  return (
    <div className={structureClassesCX([structureClasses.root])}>
      <ConnectedHeader />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <AppFooter />

      {/* Global Modals */}

      <ConnectedChipInLiquidityModal
        open={modalsStore.isChipInModalActive}
        closeModal={() => modalsStore.hideChipInModal()}
        lexId={modalsStore.chipInOrOutModal_lexId}
        setPendingBridgeTx={setPendingBridgeTxGist}
        pendingBridgeTxGists={pendingBridgeTxGist}
        onChangeLexId={onChangeLexIdForIn}
      />
      <ConnectedChipOutLiquidityModal
        open={modalsStore.isChipOutModalActive}
        closeModal={() => modalsStore.hideChipOutModal()}
        lexId={modalsStore.chipInOrOutModal_lexId}
        setPendingBridgeTx={setPendingBridgeTxGist}
        pendingBridgeTxGists={pendingBridgeTxGist}
        onChangeLexId={onChangeLexIdForOut}
      />
      <LeaderBoardModalWrapper
        closeModal={hideLeaderBoardModal}
        open={modalsStore.isLeaderboardModalActive}
      />
    </div>
  );
});

export default AppMainPage;
