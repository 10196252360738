import { action, makeObservable, observable, ObservableMap } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { persistenceUtils } from "../utils/persistenceUtils.ts";
import {
  CHAIN_ID_ARBITRUM_ONE,
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_CELO,
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_FUSE,
  CHAIN_ID_LINEA,
  CHAIN_ID_MANTLE_MAINNET,
  CHAIN_ID_MODE,
  CHAIN_ID_OPTIMISM,
  CHAIN_ID_POLYGON,
  LIVE_CHAINS,
  type TChainIds,
} from "../constants/chainConstants.ts";
import {
  READ_ONLY_ARBITRUM_ONE_ENDPOINT,
  READ_ONLY_BOBA_ENDPOINT,
  READ_ONLY_BSC_ENDPOINT,
  READ_ONLY_CELO_ENDPOINT,
  READ_ONLY_FANTOM_OPERA_ENDPOINT,
  READ_ONLY_FUSE_ENDPOINT,
  READ_ONLY_LINEA_MAINNET_ENDPOINT,
  READ_ONLY_MANTLE_MAINNET_ENDPOINT,
  READ_ONLY_MODE_ENDPOINT,
  READ_ONLY_OPTIMISM_ENDPOINT,
  READ_ONLY_POLYGON_ENDPOINT,
} from "../configs.ts";

export const defaultReadOnlyRpcs: Record<Partial<TChainIds>, string> = {
  // Fantom
  [CHAIN_ID_FANTOM_OPERA]: READ_ONLY_FANTOM_OPERA_ENDPOINT,

  [CHAIN_ID_BOBA_MAINNET]: READ_ONLY_BOBA_ENDPOINT,

  // OPTIMISM
  [CHAIN_ID_OPTIMISM]: READ_ONLY_OPTIMISM_ENDPOINT,

  // Fuse
  [CHAIN_ID_FUSE]: READ_ONLY_FUSE_ENDPOINT,

  // Linea
  [CHAIN_ID_LINEA]: READ_ONLY_LINEA_MAINNET_ENDPOINT,

  // Arbitrum
  [CHAIN_ID_ARBITRUM_ONE]: READ_ONLY_ARBITRUM_ONE_ENDPOINT,

  // Celo
  [CHAIN_ID_CELO]: READ_ONLY_CELO_ENDPOINT,

  // Polygon
  [CHAIN_ID_POLYGON]: READ_ONLY_POLYGON_ENDPOINT,

  // 288: READ_ONLY_BOBA_ENDPOINT,

  // Mantle
  [CHAIN_ID_MANTLE_MAINNET]: READ_ONLY_MANTLE_MAINNET_ENDPOINT,
  // 5001: READ_ONLY_MANTLE_TESTNET_ENDPOINT,

  56: READ_ONLY_BSC_ENDPOINT,

  [CHAIN_ID_MODE]: READ_ONLY_MODE_ENDPOINT,

  1337: "http://127.0.0.1:8545/",
  31337: "http://127.0.0.1:8545/",
};

export class RpcsStore {
  @observable public activeRpcs: ObservableMap<TChainIds, string> =
    new ObservableMap<TChainIds, string>();

  constructor() {
    makeObservable(this);
    void makePersistable(this, {
      name: "RpcsStore",
      properties: [
        {
          key: "activeRpcs",
          ...persistenceUtils.buildObservableMapSerializationFunctions(),
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 5 * 1000, // 5 hours
      removeOnExpiration: true,
    }).then(() => {
      this.initializeActiveRpcs();
    });
  }

  // ****** Public Get-like functions ******
  public getRpcByChainId(chainId: TChainIds): string {
    return this.providerRpcByChainId(chainId);
  }

  // ****** Initializers ******

  private initializeActiveRpcs() {
    // Sets the default if the active one is not configured
    for (const chainId of LIVE_CHAINS) {
      if (this.activeRpcs.get(chainId) === undefined) {
        this.setActiveRpc(chainId, defaultReadOnlyRpcs[chainId]);
      }
    }
  }

  private providerRpcByChainId(chainId: TChainIds): string {
    const rpcForChain =
      this.activeRpcs.get(chainId) ?? defaultReadOnlyRpcs[chainId];

    // console.log(`----- Getting rpc for ${chainId}`);

    if (rpcForChain) {
      return rpcForChain;
    } else {
      throw new Error(`No RPC for chain id ${chainId}`);
    }
  }

  // ****** Setters ******

  @action("setActiveRpc")
  setActiveRpc(chainId: TChainIds, rpcUrl: string) {
    this.activeRpcs.set(chainId, rpcUrl);
  }
}
