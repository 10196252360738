import React from "react";
import { Box, Typography } from "@mui/material";
import { THEME_CONSTANTS } from "../../../../../theme/ThemeConstants.ts";
import { infoLineTextVariant } from "./boxInfostyling.ts";

interface IProps {
  text: string;
  timestamp: number;
}

export const BoxInfoLineDate = React.memo<IProps>((props) => {
  const { text, timestamp } = props;

  const date = new Date(timestamp * 1000);
  const timestmapTime = date.toLocaleTimeString(undefined, {
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: false,
  });
  const timestmapDate = date.toLocaleDateString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  return (
    <Box
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Box flex={1}>
        <Typography
          variant={infoLineTextVariant}
          sx={{ color: THEME_CONSTANTS.titleGray }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        flex={1}
        flexDirection={"row"}
        display={"flex"}
        gap={1}
        // justifyContent={"end"}
      >
        <Typography variant={infoLineTextVariant}>{timestmapDate}</Typography>
        <Typography
          variant={infoLineTextVariant}
          sx={{ color: THEME_CONSTANTS.titleGray }}
        >
          {timestmapTime}
        </Typography>
      </Box>
    </Box>
  );
});

export default BoxInfoLineDate;
