import { SingleTradePairStore } from "./multiInstancesStores/TradePairStore/SingleTradePairStore.ts";
import { useActiveLexStoreStore } from "./storeHooks";
import { useEffect, useState } from "react";
import { BytesLike } from "ethers";
import { TCompletePositionDataFromLens } from "../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService";

export function usePairStoreFromActiveLed(
  pairId: number,
): SingleTradePairStore | undefined {
  const activeLedStore = useActiveLexStoreStore();

  const [tradePairStore, setTradePairStore] = useState<
    SingleTradePairStore | undefined
  >(undefined);

  // Updates the active store to match the selection
  useEffect(() => {
    if (activeLedStore && pairId) {
      const matchingBrokerStore = activeLedStore.getTradePairStoreById(pairId);
      setTradePairStore(matchingBrokerStore);
    }
  }, [activeLedStore, pairId]);

  return tradePairStore;
}

export function useGetLivePositionFromActiveLed(positionId: BytesLike) {
  const activeLedStore = useActiveLexStoreStore();

  const [position, setPosition] = useState<
    TCompletePositionDataFromLens | undefined
  >(undefined);

  // Updates the active store to match the selection
  useEffect(() => {
    if (activeLedStore && positionId) {
      const matchingPosition = activeLedStore.getLivePositionById(positionId);
      setPosition(matchingPosition);
    }
  }, [activeLedStore, positionId]);

  return position;
}
