import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";

import Color from "color";
import { LiquidityDialog, InfoPanel } from "lynx-ui-components";
import {
  DEFAULT_MODAL_THEME,
  THEME_CONSTANTS,
  THEME_STATE_CONSTANTS,
} from "../../../../theme/ThemeConstants";
import { useModalClassesForActiveLeD } from "../../../../components/Modals/modalHooks/modalsThemeHooks";
import { useIsMobile } from "../../../../ux/uxHooks";
import { numericalDisplay } from "../../../../ux/displayCalculations.ts";
import { localStringer } from "../../../../ux/uxFunctions.ts";
import { Variant } from "@mui/material/styles/createTypography";
import { SingleLexStore } from "../../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";

interface IProps {
  open: boolean;
  closeModal: () => void;

  title: string;
  actionName: string;
  actionEpochsDelay: number;

  assetSymbol: string;

  inSymbol: string;
  outSymbol: string;
  inToOutConversionRatioInUnits: number;
  minDepositAmount: number;

  availableAmountInUnits: number;

  isLoadingData: boolean;

  actionCallback: (
    amountInUnits: number,
    minAmountOutInUnits: number,
  ) => void | Promise<void>;
  lexStoreChains: SingleLexStore[];
  onLexIdChange: (lexId: string) => void;
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontWeight: "bold",
  },

  disconnectLink: {
    cursor: "pointer",

    "--baseColor": THEME_CONSTANTS.brandPurple,
    "--hoverColor": Color(THEME_CONSTANTS.brandPurple).darken(0.2).hex(),
    color: `var(--baseColor)`,

    "&:hover": {
      color: `var(--hoverColor)`,
    },
  },
  errorMessage: {
    background: "#DD434C20",
    padding: "1px 12px",
    gap: "6px",
    borderRadius: "8px",
    lineHeight: 2,
    color: THEME_STATE_CONSTANTS.textColorShort,
    textAlign: "center",
    marginTop: "5px",
  },
}));

export const PoolLiquidityModal = React.memo<IProps>((props) => {
  const { classes } = useStyles();
  const { classes: modalClasses } =
    useModalClassesForActiveLeD(DEFAULT_MODAL_THEME);

  const {
    open,
    closeModal,
    title,
    actionName,
    actionEpochsDelay,
    assetSymbol,
    inSymbol,
    outSymbol,
    inToOutConversionRatioInUnits,
    minDepositAmount,
    availableAmountInUnits,
    isLoadingData,
    actionCallback,
    lexStoreChains,
    onLexIdChange,
  } = props;

  const isMobile = useIsMobile();

  const [inAmountInString, setAssetAmountInString] = useState("");

  const { actionButtonText, actionButtonAction, actionButtonDisabled } =
    useMemo(() => {
      let actionButtonText: string;
      let actionButtonAction: () => void | Promise<void>;
      let actionButtonDisabled = false;

      if (isLoadingData) {
        actionButtonText = "Loading data...";
        actionButtonAction = () => undefined;
        // actionButtonDisabled = true;
      } else {
        const assetAmountInUnits = Number(inAmountInString);
        // TODO : Critical : Maybe use a slippage here ?
        const minAmountOutInUnits = 0;

        if (assetAmountInUnits <= availableAmountInUnits) {
          actionButtonText = actionName;
          actionButtonAction = () =>
            actionCallback(Number(assetAmountInUnits), minAmountOutInUnits);
        } else {
          actionButtonText = "Exceeds Balance";
          actionButtonAction = () => undefined;
          actionButtonDisabled = true;
        }
      }

      if (
        inAmountInString &&
        Number(inAmountInString) < (minDepositAmount ?? 0)
      ) {
        actionButtonAction = () => undefined;
        actionButtonDisabled = true;
        actionButtonText = `The amount should be greater than ${minDepositAmount}`;
      }

      return {
        actionButtonText,
        actionButtonAction,
        actionButtonDisabled,
      };
    }, [
      isLoadingData,
      inAmountInString,
      minDepositAmount,
      availableAmountInUnits,
      actionName,
      actionCallback,
    ]);

  useEffect(() => {
    if (!open) {
      setAssetAmountInString("");
    }
  }, [open]);

  const chainsData = useMemo(() => {
    return lexStoreChains.map((el) => {
      return {
        assetSimbol: el.chipAssetParams.symbol,
        id: el.id,
      };
    });
  }, [lexStoreChains]);

  const onTokenChange = useCallback(
    (symbol: string) => {
      const id = chainsData.find((el) => el.assetSimbol === symbol)?.id;
      if (id) {
        onLexIdChange(id);
      }
    },
    [onLexIdChange, chainsData],
  );

  const getInAmount = isNaN(parseFloat(inAmountInString))
    ? "0"
    : inAmountInString;

  const estimatedOutAmount =
    parseFloat(getInAmount) * inToOutConversionRatioInUnits;

  const panelComponent = useMemo(() => {
    return (
      <InfoPanel
        panelStyle={{ padding: 0, backgroundColor: "transparent" }}
        headerPayload={{ panelTitle: "", panelSubTitle: "" }}
        sections={[
          {
            sectionKey: "summary",
            rows: [
              {
                title: `${actionName} Epochs Delay`,
                text: `${localStringer(actionEpochsDelay)}`,
                titleTooltip: `Requests made now will be processed after ${actionEpochsDelay} epoch${actionEpochsDelay == 1 ? "" : "s"}`,
              },
              {
                title: `${actionName}`,
                text: `${numericalDisplay(parseFloat(getInAmount), 4)} ${inSymbol}`,
                textFlexSize: 3,
              },
              {
                title: `Receive`,
                text: `~ ${numericalDisplay(estimatedOutAmount, 4)} ${outSymbol}`,
                textFlexSize: 3,
              },
              {
                title: ``,
                text: `Exchange Rate`,
                textFlexSize: 9,
                comment: `1 ${inSymbol} ~= ${numericalDisplay(inToOutConversionRatioInUnits, 4)} ${outSymbol}`,
                variant: "caption" as Variant,
              },

              // NOTE : Trick to make it easy to dynamically remove rows
            ].filter((r) => !!r.title || !!r.text || !!r.comment),
          },
        ]}
      />
    );
  }, [
    actionEpochsDelay,
    actionName,
    estimatedOutAmount,
    getInAmount,
    inSymbol,
    inToOutConversionRatioInUnits,
    outSymbol,
  ]);

  return (
    <LiquidityDialog
      tokensOptions={chainsData.map((el) => el.assetSimbol)}
      tokenTitle="Pool"
      selectedToken={assetSymbol}
      onSelectToken={onTokenChange}
      open={open}
      closeModal={closeModal}
      title={title}
      availableAmountInUnits={availableAmountInUnits}
      assetSymbol={assetSymbol}
      assetAmountInString={inAmountInString}
      onSetAssetAmountInString={setAssetAmountInString}
      buttonText={actionButtonText}
      isButtonDisabled={actionButtonDisabled}
      onBottonCklick={actionButtonAction}
      amountSubTitle="Max:"
      panelComponent={panelComponent}
    />
  );
});

// export default ChipLiquidityModal as (props: IProps) => React.JSX.Element;
