import { useMemo } from "react";
import {
  BAAS_INCENTIVES_ENDPOINTS__FANTOM,
  BAAS_INCENTIVES_ENDPOINTS__BOBA,
} from "../constants/externalServicesConstants.ts";
import { IncentivesService } from "./servicesIntergration/incentivesService/incentivesService.ts";
import { IIncentivesService } from "./servicesIntergration/incentivesService/IIncentivesService";
import { TEngineChainIds } from "../constants/chainConstants.ts";

export function useIncentivesService(
  engineChainId: TEngineChainIds,
): IIncentivesService {
  const incentivesService = useMemo(() => {
    const addresses =
      engineChainId == 250
        ? BAAS_INCENTIVES_ENDPOINTS__FANTOM
        : BAAS_INCENTIVES_ENDPOINTS__BOBA;

    const service = new IncentivesService(
      addresses.getAllLiveIncentivePlans,
      addresses.getAllPastIncentivePlans,
      addresses.pastCompetitionResultsUrl,
      addresses.liveCompetitionResultsUrl,
    );

    return service;
  }, [engineChainId]);

  return incentivesService;
}
