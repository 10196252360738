import { Hash } from "viem";
import { useCallback, useEffect, useState } from "react";
import { TChainIdsWithNone } from "../constants/chainConstants.ts";
import { getChainExplorersUrlFromChainId } from "../ux/chainsHooks.ts";
import {
  TAsyncCallback,
  TAsyncCallbackCreator,
} from "../types/generalTypes.ts";
import { useWaitForTransactionReceipt } from "wagmi";
import { positionToastmanager } from "../ux/delegatedIntentsUx.ts";
import { toast_step } from "../ux/stepToast/statusToastManager.tsx";

export function useWaitForTx(chainId: TChainIdsWithNone) {
  const explorerUrl = getChainExplorersUrlFromChainId(chainId);

  const [txHash, setTxHash] = useState("");

  const [onSuccessCallback, setOnSuccessCallback] = useState<
    TAsyncCallback | undefined
  >(undefined);

  const { data, isError, isLoading, isFetched } = useWaitForTransactionReceipt({
    hash: txHash as Hash,
    chainId: chainId,
  });

  // console.log({
  //   isError,
  //   isLoading,
  //   isFetched,
  // });

  const setTxToWaitForAndCallbacks = useCallback(
    (txHashToSet: string, successCallback?: TAsyncCallbackCreator) => {
      setTxHash(txHashToSet);
      setOnSuccessCallback(successCallback);
    },
    [],
  );

  // ***** Waiting *****
  useEffect(() => {
    if (txHash && isLoading) {
      positionToastmanager.updateToast(txHash, toast_step.processing);
    }
  }, [explorerUrl.txUrlBase, isLoading, txHash]);

  // ***** Error *****
  useEffect(() => {
    if (txHash && isError) {
      positionToastmanager.updateToast(
        txHash,
        positionToastmanager.getStep(txHash),
        `Error on tx ${txHash}`,
      );
    }
  }, [explorerUrl.txUrlBase, isError, txHash]);

  // ***** Done *****
  useEffect(() => {
    if (txHash && isFetched) {
      positionToastmanager.updateToast(txHash, toast_step.success);
      if (onSuccessCallback) {
        void onSuccessCallback().catch((e) => {
          positionToastmanager.updateToast(
            txHash,
            positionToastmanager.getStep(txHash),
            `Error`,
          );
          console.error(`OnSuccessCallback error ${e}`);
        });
      }
    }
  }, [explorerUrl.txUrlBase, isFetched, onSuccessCallback, txHash]);

  return {
    data,
    isError,
    isLoading,
    isFetched,
    txHash,
    setTxHash,
    setTxToWaitForAndCallbacks,
  };
}
