import { formatUnits } from "ethers";

export function fixedStringerForBn(
  valueBn: bigint,
  valueDecimals: number,
  stringDecimals?: number,
) {
  const stringDecimalsToUse = stringDecimals ?? valueDecimals;

  const value = parseFloat(formatUnits(valueBn, valueDecimals));

  return safeFixedStringer(value, stringDecimalsToUse);
}

export function safeFixedStringer(val: number, stringDecimals = 3) {
  const factor = Math.pow(10, stringDecimals);
  const roundedValue = Math.floor(val * factor) / factor;
  return roundedValue.toFixed(stringDecimals);
}

export function localStringer(num: number, stringDecimals = 3) {
  return num.toLocaleString(undefined, {
    maximumFractionDigits: stringDecimals,
  });
}

export function localPriceStringer(num: number, stringDecimals = 3) {
  return `$${localStringer(num, stringDecimals)}`;
}
