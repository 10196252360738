import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { THEME_CONSTANTS } from "../../../../../theme/ThemeConstants.ts";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface IProps {
  externalLink: string;
}

export const IconButtonLink = React.memo<IProps>((props) => {
  const { externalLink } = props;
  return (
    <a
      href={externalLink}
      target={"_blank"}
      rel={"noreferrer noopener"}
      style={{ verticalAlign: "center" }}
    >
      <IconButton
        sx={{
          color: THEME_CONSTANTS.brandLightPurple,
          padding: 0,
          borderColor: "transparent",
          "&:focus": { outline: "none" },
          "&:active": { color: THEME_CONSTANTS.brandPurple },
          transition: "200ms",
          ".MuiSvgIcon-root": {
            fontSize: "1rem",
          },
        }}
      >
        <OpenInNewIcon />
      </IconButton>
    </a>
  );
});

export default IconButtonLink;
