import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "./firebaseInitialization.ts";

export function logWalletConnection_firebase(accountAddress: string) {
  logFireBaseAnalyticsLogInEvent(accountAddress);
}

export function logPageView(
  accountAddress: string,
  pageLocation: string,
  pagePath: string,
  pageTitle: string,
) {
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, "page_view", {
      account: formatAddressForAnalytics(accountAddress),
      page_location: pageLocation,
      page_path: pagePath,
      page_title: pageTitle,
    });
  }
}

function logFireBaseAnalyticsLogInEvent(accountAddress: string) {
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, "login", {
      account: formatAddressForAnalytics(accountAddress),
    });
  }
}

function formatAddressForAnalytics(rawAddress: string) {
  return `_${rawAddress}`;
}
