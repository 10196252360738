import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { PositionOpenPanelWrappe } from "../../components/panel/tradePairInteractionPanels/PositionOpenPanelWrappe.tsx";
import { SingleTradePairStore } from "../../store/multiInstancesStores/TradePairStore/SingleTradePairStore.ts";
import { TOpenOrderType } from "../../constants/contractEnums.ts";
import {
  CHAIN_ID_NO_CHAIN,
  TSourceChainIds,
} from "../../constants/chainConstants.ts";
import { PAIR_CONFIGS_IDS } from "../../constants/pairsConstants.ts";
import { getCurrentRewardsRoundRulesForLex } from "../RewardsPage/rewardsPageHooks.ts";
import { useUrlQueryValue_sa } from "../../navigation/navigationStateHooks.ts";

interface IProps {
  tradePairStore?: SingleTradePairStore;
  approveAction: (token: string, amount: bigint) => void;
  requestOpenPosition: (
    openOrderType: TOpenOrderType,
    positionIndex: number,
    isLong: boolean,
    positionSize: bigint,
    leverage: number,
    minPrice: number,
    maxPrice: number,
    tpPrice: number,
    slPrice: number,
    tpByFraction: number,
    slByFraction: number,
  ) => Promise<void>;

  isConnectedToWallet: boolean;
  requestWalletConnectionFunction: () => void;
}

export const ConnectedPositionOpenPanel = observer<React.FC<IProps>>(
  (props) => {
    const {
      tradePairStore,
      approveAction,
      requestOpenPosition,
      isConnectedToWallet,
      requestWalletConnectionFunction,
    } = props;

    const { setQueryPairSettlementAsset } = useUrlQueryValue_sa();

    const [isPending, setIsPending] = React.useState(false);

    const sourceChainId = tradePairStore?.ownLexStore.sourceChainId;

    const settlementAssetAddress =
      tradePairStore?.ownLexStore.chipAssetParams.address ?? "";
    const settlementAssetDecimals =
      tradePairStore?.ownLexStore.chipAssetParams.decimals ?? 18;
    const settlementAssetSymbol =
      tradePairStore?.ownLexStore.sourceAssetParameters.symbol ??
      "Settlement Asset";

    // TODO : Decide what to do if no pair id
    const pairId = tradePairStore?.pairId ?? PAIR_CONFIGS_IDS.BTC_USD;

    const firstAvailablePositionIndexForAccount =
      tradePairStore?.activeUserStore.firstAvailablePositionIndexForAccount ??
      0;

    const openFeeFInUnits = tradePairStore?.openFeeFInUnits ?? 0;
    const closeFeeFInUnits = tradePairStore?.closeFeeFInUnits ?? 0;
    const allowanceForCenterInUnits =
      tradePairStore?.ownLexStore.activeUserStore.allowanceForCenterInUnits ??
      0;

    const minOpenFeeInUnits =
      tradePairStore?.ownLexStore.minOpenFeeInUnits ?? 0;
    const maxLeveragedPositionInUnits =
      tradePairStore?.effectiveCap_maxPositionSizeInUnits ?? 0;

    const maxBorrowInUnits =
      tradePairStore?.effectiveCap_maxBorrowAmountInUnits ?? 0;
    const totalBorrowsInUnits =
      tradePairStore?.ownLexStore?.totalBorrowsInUnits ?? 0;
    // Zero if available is negative for some bug
    const poolBorrowCapacityInUnits = Math.max(
      maxBorrowInUnits - totalBorrowsInUnits,
      0,
    );

    const onPairSelected = useCallback(
      (val: string) => {
        setQueryPairSettlementAsset(val);
      },
      [setQueryPairSettlementAsset],
    );

    const pendableRequestOpenPosition = useCallback(
      async (
        openOrderType: TOpenOrderType,
        positionIndex: number,
        isLong: boolean,
        positionSize: bigint,
        leverage: number,
        minPrice: number,
        maxPrice: number,
        tpPrice: number,
        slPrice: number,
        tpByFraction: number,
        slByFraction: number,
      ) => {
        setIsPending(true);
        try {
          await requestOpenPosition(
            openOrderType,
            positionIndex,
            isLong,
            positionSize,
            leverage,
            minPrice,
            maxPrice,
            tpPrice,
            slPrice,
            tpByFraction,
            slByFraction,
          );
          setIsPending(false);
        } catch (e) {
          setIsPending(false);
        }
      },
      [requestOpenPosition],
    );

    return (
      <PositionOpenPanelWrappe
        sourceChainId={sourceChainId ?? (CHAIN_ID_NO_CHAIN as TSourceChainIds)}
        traderSABalanceBn={
          tradePairStore?.ownLexStore?.activeUserStore
            .account_underlyingInWalletOnEngineChain ?? 0n
        }
        approveAction={approveAction}
        requestOpenPosition={pendableRequestOpenPosition}
        onPairSelected={onPairSelected}
        isConnectedToWallet={isConnectedToWallet}
        requestWalletConnectionFunction={requestWalletConnectionFunction}
        settlementAssetAddress={settlementAssetAddress}
        settlementAssetDecimals={settlementAssetDecimals}
        settlementAssetSymbol={settlementAssetSymbol}
        lexId={tradePairStore?.ownLexStore?.id ?? ""}
        pairId={pairId}
        firstAvailablePositionIndexForAccount={
          firstAvailablePositionIndexForAccount
        }
        openFeeFInUnits={openFeeFInUnits}
        closeFeeFInUnits={closeFeeFInUnits}
        allowanceForCenterInUnits={allowanceForCenterInUnits}
        poolBorrowCapacityInUnits={poolBorrowCapacityInUnits}
        minOpenFeeInUnits={minOpenFeeInUnits}
        maxLeveragedPositionInUnits={maxLeveragedPositionInUnits}
        // estimateRewardsForPosition={estimateRewardsForPosition}
        isActionPending={isPending}
        pendingActionText={"In Progress"}
      />
    );
  },
);

export default ConnectedPositionOpenPanel;
