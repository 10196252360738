import type { TChainIds } from "../constants/chainConstants";

import { CryptoWalletConnectionStore } from "./CryptoWalletConnectionStore";
import { ethers, Signer, Provider } from "ethers";

import { Erc20Service } from "../services/contractsIntegration/erc20Service/Erc20Service";
import { IErc20Service } from "../services/contractsIntegration/erc20Service/IErc20Service";
import { LexPoolService } from "../services/contractsIntegration/LexPoolService/LexPoolService";
import { ILexPoolService } from "../services/contractsIntegration/LexPoolService/ILexPoolService";
import { ITradingService } from "../services/contractsIntegration/TradingService/ITradingService";
import { TradingService } from "../services/contractsIntegration/TradingService/TradingService";
import { ILexLensService } from "../services/contractsIntegration/LexLensService/ILexLensService";
import { LexLensService } from "../services/contractsIntegration/LexLensService/LexLensService";
import { ITradingFloorLensService } from "../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService";
import { TradingFloorLensService } from "../services/contractsIntegration/TradingFloorLensService/TradingFloorLensService";
import { OftChipAdapterService } from "../services/contractsIntegration/OftBridgeService/OftChipAdapterService.ts";
import { IOftChipAdapterService } from "../services/contractsIntegration/OftBridgeService/IOftChipAdapterService.ts";
import { ITradeIntentsVerifierService } from "../services/contractsIntegration/TradeIntentsVerifierService/ITradeIntentsVerifierService.ts";
import { TradeIntentsVerifierService } from "../services/contractsIntegration/TradeIntentsVerifierService/TradeIntentsVerifierService.ts";
import { IntentsVerifierLensService } from "../services/contractsIntegration/IntentsVerifierLensService/IntentsVerifierLensService.ts";
import { IIntentsVerifierLensService } from "../services/contractsIntegration/IntentsVerifierLensService/IIntentsVerifierLensService.ts";
import { ChipsIntentsVerifierService } from "../services/contractsIntegration/ChipsIntentsVerifierService/ChipsIntentsVerifierService.ts";
import { IChipsIntentsVerifierService } from "../services/contractsIntegration/ChipsIntentsVerifierService/IChipsIntentsVerifierService.ts";
import { EngineChipService } from "../services/contractsIntegration/EngineChipService/EngineChipService.ts";
import { IEngineChipService } from "../services/contractsIntegration/EngineChipService/IEngineChipService.ts";
import { IWrappedNativeEngineChipHelperService } from "../services/contractsIntegration/WrappedNativeEngineChipHelperService/IWrappedNativeEngineChipHelperService.ts";
import { WrappedNativeEngineChipHelperService } from "../services/contractsIntegration/WrappedNativeEngineChipHelperService/WrappedNativeEngineChipHelperService.ts";
import { IGeneralTokenDispenserService } from "../services/contractsIntegration/GeneralTokenDispenserService/IGeneralTokenDispenserService.ts";
import { GeneralTokenDispenserService } from "../services/contractsIntegration/GeneralTokenDispenserService/GeneralTokenDispenserService.ts";
import { makeObservable } from "mobx";
import { RpcsStore } from "./RpcsStore.ts";

export class ContractServicesStore {
  constructor(
    private cryptoWalletConnectionStore: CryptoWalletConnectionStore,
    private rpcsStore: RpcsStore,
  ) {
    makeObservable(this);
  }

  // ****** Service Builders ******

  buildErc20Service(erc20Address: string, chainId: TChainIds): IErc20Service {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new Erc20Service(erc20Address, signerOrProvider);
  }

  buildLeverageVaultService(
    lvAddress: string,
    chainId: TChainIds,
  ): ILexPoolService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new LexPoolService(lvAddress, signerOrProvider);
  }

  buildTradingService(
    tradingAddress: string,
    chainId: TChainIds,
  ): ITradingService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new TradingService(tradingAddress, signerOrProvider);
  }

  buildTradeIntentsVerifierService(
    tradeIntentsVerifierAddress: string,
    chainId: TChainIds,
  ): ITradeIntentsVerifierService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new TradeIntentsVerifierService(
      tradeIntentsVerifierAddress,
      signerOrProvider,
    );
  }

  buildChipsIntentsVerifierService(
    chipsIntentsVerifierAddress: string,
    chainId: TChainIds,
  ): IChipsIntentsVerifierService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new ChipsIntentsVerifierService(
      chipsIntentsVerifierAddress,
      signerOrProvider,
    );
  }

  buildOftChipAdapterService(
    oftProxyAddress: string,
    chainId: TChainIds,
  ): IOftChipAdapterService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new OftChipAdapterService(oftProxyAddress, signerOrProvider);
  }

  buildEngineChipAdapterService(
    address: string,
    chainId: TChainIds,
  ): IEngineChipService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new EngineChipService(address, signerOrProvider);
  }

  buildWrappedNativeEngineChipHelperService(
    address: string,
    chainId: TChainIds,
  ): IWrappedNativeEngineChipHelperService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new WrappedNativeEngineChipHelperService(address, signerOrProvider);
  }

  buildLexPoolService(
    lexPoolAddress: string,
    chainId: TChainIds,
  ): ILexPoolService {
    const signerOrProvider =
      this.getWalletSignerIfCanOrReadOnlyProvider(chainId);

    return new LexPoolService(lexPoolAddress, signerOrProvider);
  }

  buildLexLensService(
    address: string,
    chainId: TChainIds,
    useReadOnlyRpc = false,
  ): ILexLensService {
    const signerOrProvider = this.getWalletSignerIfCanOrReadOnlyProvider(
      chainId,
      useReadOnlyRpc,
    );

    return new LexLensService(address, signerOrProvider);
  }

  buildTradingCenterLensService(
    address: string,
    chainId: TChainIds,
    useReadOnlyRpc = false,
  ): ITradingFloorLensService {
    const signerOrProvider = this.getWalletSignerIfCanOrReadOnlyProvider(
      chainId,
      useReadOnlyRpc,
    );

    return new TradingFloorLensService(address, signerOrProvider);
  }

  buildIntentsVerifierLensService(
    address: string,
    chainId: TChainIds,
    useReadOnlyRpc = false,
  ): IIntentsVerifierLensService {
    const signerOrProvider = this.getWalletSignerIfCanOrReadOnlyProvider(
      chainId,
      useReadOnlyRpc,
    );

    return new IntentsVerifierLensService(address, signerOrProvider);
  }

  buildGeneralTokenDispenserService(
    address: string,
    chainId: TChainIds,
    useReadOnlyRpc = false,
  ): IGeneralTokenDispenserService {
    const signerOrProvider = this.getWalletSignerIfCanOrReadOnlyProvider(
      chainId,
      useReadOnlyRpc,
    );

    return new GeneralTokenDispenserService(address, signerOrProvider);
  }

  // ****** Provider ******

  public getProviderForChainId(chainId: TChainIds): Provider {
    return new ethers.JsonRpcProvider(this.rpcsStore.getRpcByChainId(chainId));
  }

  // ****** Internal ******

  private getWalletSignerIfCanOrReadOnlyProvider(
    forChainId: TChainIds,
    useReadOnlyRpc = false,
  ): Signer | Provider {
    if (
      !useReadOnlyRpc &&
      this.canUseConnectedEthereumProvider(forChainId) &&
      this.cryptoWalletConnectionStore.getChainSigner
    ) {
      // console.log(`----- Getting signer for ${forChainId}`);
      return this.cryptoWalletConnectionStore.getChainSigner;
    } else {
      return new ethers.JsonRpcProvider(
        this.rpcsStore.getRpcByChainId(forChainId),
      );
    }
  }

  private canUseConnectedEthereumProvider(forChainId: TChainIds) {
    return (
      this.cryptoWalletConnectionStore.isConnectedToWallet &&
      this.cryptoWalletConnectionStore.chainId === forChainId
    );
  }
}
