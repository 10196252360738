import { BytesLike, ethers } from "ethers";
import { Options } from "@layerzerolabs/lz-v2-utilities";
import { TSendParamStruct } from "./OFTTypes.ts";
import {
  LAYER_ZERO_ENDPOINT_IDS,
  TChainIds,
  TEngineChainIds,
} from "../../../constants/chainConstants.ts";
import { layerZeroPayloadUtils } from "../../../utils/layerZeroPayloadUtils.ts";

function buildSendParamForOftAdapter(
  destChainId: TEngineChainIds,
  receiverAddress: string,
  amount: bigint,
  localUnderlyingDecimals: number,
): TSendParamStruct {
  const emptyBytes = ethers.ZeroHash;

  const amountWithoutDust =
    layerZeroPayloadUtils.removeDustForOFTChipAdapterAmount(
      amount,
      localUnderlyingDecimals,
    );

  const sendParamStruct: TSendParamStruct = {
    dstEid: BigInt(LAYER_ZERO_ENDPOINT_IDS[destChainId]),
    to: layerZeroPayloadUtils.addressToBytes32(receiverAddress),

    amountLD: amountWithoutDust,
    minAmountLD: amountWithoutDust,

    extraOptions: getExtraOptionsByDestinationChainId(destChainId),

    oftCmd: emptyBytes,
    composeMsg: emptyBytes,
  };

  return sendParamStruct;
}

function getExtraOptionsByDestinationChainId(
  destinationChainId: TChainIds,
): BytesLike {
  // TODO : Add 'by chain' logic
  // NOTE : If we define 'enforced options' we can return 'empty' options here

  const executorGas = 200_000; // Gas limit for the executor
  const executorValue = 0; // msg.value for the lzReceive() function on destination in wei
  const _options = Options.newOptions().addExecutorLzReceiveOption(
    executorGas,
    executorValue,
  );

  const extraOptionsHexString: BytesLike = _options.toHex();

  return extraOptionsHexString;
}

export const OftBridgeUtils = { buildSendParamForOftAdapter };
