// TODO : CRITICAL : Read these values (already reading, just need to connect)
// 90% liquidation threshold
const LIQ_THRESHOLD_F = 0.9;
const LIQ_POINT_MULTIPLIER = 1 - LIQ_THRESHOLD_F;
// 900% PnL (x10)
const MAX_GAIN_P = 9;

export function calculatePositionLiquidationPrice(
  openPriceInUnits: number,
  isLong: boolean,
  collateralInUnits: number,
  leverageInUnits: number,
  rolloverFeeInUnits: number,
  fundingFeeInUnits: number,
): number {
  // TODO : CRITICAL : Add logic for closing fee
  // TODO : Make sure this calculation is correct

  const liqPriceDistance =
    (openPriceInUnits * LIQ_THRESHOLD_F -
      rolloverFeeInUnits -
      fundingFeeInUnits) /
    // collateralInUnits /
    leverageInUnits;

  const liqPrice = isLong
    ? openPriceInUnits - liqPriceDistance
    : openPriceInUnits + liqPriceDistance;

  // console.log(
  //   `@@@ leverage in units ${openPriceInUnits} || leverage in units ${leverageInUnits} || liqPriceDistance ${liqPriceDistance} === > liqPrice ${liqPrice}`,
  // );

  // console.log(`@@@@@@ liqPriceDistance: ${liqPriceDistance.toLocaleString()}`);
  // console.log(`@@@@@@ liqPrice: ${liqPrice.toLocaleString()}`);

  if (liqPrice > 0) {
    return liqPrice;
  } else {
    return 0;
  }
}

export function calculatePositionTradeValueInSAUnits(
  openPriceInUnits: number,
  currentPriceInUnits: number,
  isLong: boolean,
  leverageInUnits: number,
  collateralInUnits: number,
  interestFeeInUnits: number,
  fundingFeeInUnits: number,
  closingFeeInUnits: number,
): number {
  // 0 = no profit or loss
  const profitAsFraction = calculatePositionProfitFractionLikeContract(
    openPriceInUnits,
    currentPriceInUnits,
    isLong,
    leverageInUnits,
  );

  return calculatePositionTradeValueInSAUnitsLikeContract(
    profitAsFraction,
    collateralInUnits,
    interestFeeInUnits,
    fundingFeeInUnits,
    closingFeeInUnits,
  );
}

/**
 * Returns the profit (or loss) as a fraction (1 = 100%, 0 = 0%, -0.25 = -25%)
 */
export function calculatePositionProfitFractionLikeContract(
  openPriceInUnits: number,
  currentPriceInUnits: number,
  isLong: boolean,
  leverageInUnits: number,
): number {
  const priceDiffInDirection = calculatePriceDiffInDirection(
    openPriceInUnits,
    currentPriceInUnits,
    isLong,
  );
  const profitFraction =
    (priceDiffInDirection * leverageInUnits) / openPriceInUnits;
  // console.log(`%%%%% profitFraction ${profitFraction.toLocaleString()}`);

  return profitFraction > MAX_GAIN_P ? MAX_GAIN_P : profitFraction;
}

export function calculatePriceDiffInDirection(
  openPriceInUnits: number,
  currentPriceInUnits: number,
  isLong: boolean,
) {
  const priceDiffInDirection = isLong
    ? currentPriceInUnits - openPriceInUnits
    : openPriceInUnits - currentPriceInUnits;

  return priceDiffInDirection;
}

export function calculatePositionTradeValueInSAUnitsLikeContract(
  // Position or negative
  profitAsFraction: number,
  collateralInUnits: number,
  interestFeeInUnits: number,
  fundingFeeInUnits: number,
  closingFeeInUnits: number,
): number {
  const value =
    collateralInUnits +
    collateralInUnits * profitAsFraction -
    interestFeeInUnits -
    fundingFeeInUnits;

  const liquidationValue = collateralInUnits * LIQ_POINT_MULTIPLIER;

  // console.log(`@@@@ value ${value.toLocaleString()}`);
  // console.log(`@@@@ liquidationValue ${liquidationValue.toLocaleString()}`);

  if (value <= liquidationValue) {
    return 0;
  } else {
    const valueAfterClosingFee = value - closingFeeInUnits;
    return valueAfterClosingFee > 0 ? valueAfterClosingFee : 0;
  }
}
