import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MaterialUiTable } from "../../../../components/Tables/MaterialUiTable";
import { TPendingEpochRedeemComplete } from "../../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import { localStringer } from "../../../../ux/uxFunctions";
import { commonTableCellsBuilders } from "../../../../components/Tables/commonTableCellsBuilders.tsx";

interface IProps {
  pendingEpochRedeems: TPendingEpochRedeemComplete[];

  // showProcessDeposit: () => void;
}

export const PendingEpochRedeemsTable = React.memo<IProps>((props) => {
  const { pendingEpochRedeems } = props;

  const columns: ColumnDef<TPendingEpochRedeemComplete>[] = useMemo<
    ColumnDef<TPendingEpochRedeemComplete>[]
  >(() => {
    return [
      {
        header: "Pool",
        id: "pool",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.epochNumber)}`,
        cell: (props1) =>
          commonTableCellsBuilders.buildAssetTableCell(
            props1.cell.row.original.underlyingSymbol,
          ),
      },
      {
        header: "In Epoch",
        id: "epochNumber",
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.epochNumber)}`,
      },
      {
        header: "LP Redeemed",
        id: "lpAmountInUnits",
        accessorFn: (originalRow) =>
          `${localStringer(
            originalRow.lpAmountInUnits,
          )} ${originalRow.underlyingSymbol}-LP`,
      },
      {
        header: "Current redeem value",
        id: "lpAmountInUnderlyingUnitsByCurrentExchangeRate",
        accessorFn: (originalRow) =>
          `${localStringer(
            originalRow.lpAmountInUnderlyingUnitsByCurrentExchangeRate,
          )} ${originalRow.underlyingSymbol}`,
      },
      // {
      //   header: "Min Amount Out",
      //   id: "minAmountOutInUnits",
      //   accessorFn: (originalRow) =>
      //     `${localStringer(
      //       originalRow.minAmountOutInUnits,
      //     )} ${originalRow.underlyingSymbol}`,
      // },
      {
        header: "Max Amount Out",
        id: "maxAmountOutInUnits",
        accessorFn: (originalRow) =>
          `${localStringer(
            originalRow.maxAmountOutInUnits,
          )} ${originalRow.underlyingSymbol}`,
      },

      // // TODO : Add option to close pending options
      // {
      //   header: "Process",
      //   id: "process",
      //   // TODO : Only show this if the timeout buffer has passed
      //   accessorFn: (originalRow) => {
      //     const canBeProcessed = currentPoolEpoch == originalRow.epochNumber;
      //
      //     if (canBeProcessed) {
      //       return (
      //         <Button onClick={() => showProcessDeposit()} variant={"outlined"}>
      //           Finalize Redeem
      //         </Button>
      //       );
      //     } else {
      //       return <Typography>Not yet</Typography>;
      //     }
      //   },
      // },
    ];
  }, []);

  const data = useMemo(() => {
    return [...pendingEpochRedeems];
  }, [pendingEpochRedeems]);

  return (
    <MaterialUiTable
      columns={columns}
      data={data}
      getRowId={(val: TPendingEpochRedeemComplete) =>
        `${val.account}_${val.epochNumber}_${val.lpAmountInUnits}`
      }
    />
  );
});
