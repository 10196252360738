import { TDelegatedActionResponse } from "../services/servicesIntergration/intentsDelegationService/IIntentsDelegationService.ts";
import {
  ACTION_TRADE_INTENTS_DELEGATION_URL_BY_ENGINE_CHAIN_ID,
  BAAS_INTENT_REQUEST_BUILD_INFO_URL,
} from "../constants/externalServicesConstants.ts";
import { IntentsDelegationService } from "../services/servicesIntergration/intentsDelegationService/IntentsDelegationService.ts";
import { TEngineChainIds } from "../constants/chainConstants.ts";
import {
  StatusToastManager,
  toast_step,
} from "./../ux/stepToast/statusToastManager.tsx";
import { generateUniqueId } from "./uiid.ts";
import {
  dismissToast,
  toastErrorReplace,
  toastInfo,
} from "./toasts/toasting.ts";
import {
  EPositionActionType,
  TPositionParams,
} from "../store/multiInstancesStores/LexStore/SingleLexUserStore.ts";
import { notifyDiscordServer } from "../services/servicesIntergration/discordService/discordIntegration.ts";
import { DISCORD_ENDPOINTS } from "../services/servicesIntergration/discordService/discordConstants.ts";
import {
  extractMsgFromSolidityError,
  extractReasonFromErrorText,
} from "../utils/errorMessages.ts";
import { lifecycleIntegration } from "../services/servicesIntergration/lifecycleIntegration/lifecycleIntegration.ts";
export const positionToastmanager = new StatusToastManager();
const REQUESTING_ACTION_DELEGATION_MESSAGE = "Your request was received!";

export const getToastPrefix = (
  action_type: EPositionActionType | undefined,
) => {
  switch (action_type) {
    case EPositionActionType.open:
      return "Opening";
    case EPositionActionType.close:
      return "Closing";
    case EPositionActionType.update:
      return "Updating";
    case EPositionActionType.liquiditySupply:
      return "Supply";
    case EPositionActionType.liquidityRemove:
      return "Remove";
    default:
      return "";
  }
};

export async function handleDelegatedIntentUxFlow(
  actionName: string,
  actionDescription: string,
  delegationRequestFunction: () => Promise<TDelegatedActionResponse>,
  accountAddress: string,
  isCancellRequest?: boolean,
  positionParams?: TPositionParams,
  onErrorAccoured?: () => void,
) {
  const { action_type, settlementAsset, direction, leverage, amount } =
    positionParams ?? {};

  const prefix = getToastPrefix(action_type);
  const isLiquidity =
    action_type === EPositionActionType.liquiditySupply ||
    action_type === EPositionActionType.liquidityRemove;
  const toastTitle = isLiquidity ? prefix : `${prefix} ${settlementAsset}`;
  const liquidityTitle =
    action_type === EPositionActionType.liquiditySupply
      ? `${prefix} ${amount} ${settlementAsset}`
      : `${prefix} ${settlementAsset} liquidity`;
  const toastDescription = isLiquidity
    ? liquidityTitle
    : `${direction}, ${leverage}X`;
  if (positionParams) {
    positionToastmanager.setTitle(toastTitle);
    positionToastmanager.setDescription(toastDescription);
  }
  let txHash = "";
  const temporariToasttId = isCancellRequest
    ? String(toastInfo(REQUESTING_ACTION_DELEGATION_MESSAGE, { theme: "dark" }))
    : generateUniqueId();

  try {
    if (!isCancellRequest) {
      positionToastmanager.showToast(temporariToasttId);
    }

    const res = await delegationRequestFunction();

    if (res.error) {
      // TODO : Add some discord/analytics error logs
      console.error(`Delegation request error ${res.error}`);

      // NOTE : Using '||' to force res.error in case of an empty string
      const revertReason = extractReasonFromErrorText(res.error) || res.error;
      const clearErrorMsg = extractMsgFromSolidityError(revertReason);

      if (!isCancellRequest) {
        positionToastmanager.updateToast(
          temporariToasttId,
          positionToastmanager.getStep(temporariToasttId),
          `Error : ${clearErrorMsg}`,
        );
      } else {
        toastErrorReplace(temporariToasttId, "Received error");
        onErrorAccoured?.();
      }

      lifecycleIntegration.captureError(res.error);
    } else if (res.tx) {
      if (!isCancellRequest) {
        positionToastmanager.replaceToastId(temporariToasttId, res.tx);
        positionToastmanager.updateToast(res.tx, toast_step.received);
      } else {
        dismissToast(temporariToasttId);
      }

      console.log(
        `REFRESHING AFTER SUCCESS POSITION OPEN REQUEST (DELEGATION)`,
      );

      txHash = res.tx;
    }
  } catch (e) {
    const error = e?.toString() ?? "Error Delegating";
    console.log(
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      `CAUGHT INSIDE :: handleFlowForDelegatedAction ::  ${error}`,
    );

    positionToastmanager.updateToast(
      temporariToasttId,
      positionToastmanager.getStep(temporariToasttId),
      `${error} -- Please try again`,
    );

    notifyDiscordServer(
      DISCORD_ENDPOINTS.appDelegationErrors,
      `${actionName} -- ${error}`,
      [
        {
          title: "Info",
          description: `account ${accountAddress} | SA ${positionParams?.settlementAsset} | ${(e?.toString() ?? "").substring(0, 1000)}`,
        },
        {
          title: "Action",
          description: actionName,
        },
        {
          title: "Description",
          description: actionDescription,
        },
      ],
      "DelegatedIntentUxFlow",
      "DelegatedIntentUxFlow",
      true,
    )
      .then(() => console.log(`Notified discord about error: ${error}`))
      .catch((e) =>
        console.error(`Error notifying discord about error: ${error}`),
      );
  }

  return txHash;
}

export function buildIntentsDelegationService(engineChainId: TEngineChainIds) {
  const delegationRequestsUrls =
    ACTION_TRADE_INTENTS_DELEGATION_URL_BY_ENGINE_CHAIN_ID[engineChainId];

  const intentsDelegationService = new IntentsDelegationService(
    BAAS_INTENT_REQUEST_BUILD_INFO_URL,
    delegationRequestsUrls.tradeIntentsDelegationUrl,
    delegationRequestsUrls.liquidityIntentsDelegationUrl,
    delegationRequestsUrls.chipsIntentsDelegationUrl,
  );

  return intentsDelegationService;
}
