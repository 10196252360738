import React from "react";
import { TitleAndValue } from "./TitleAndValue";
import { observer } from "mobx-react";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import { Variant } from "@mui/material/styles/createTypography";
import { THEME_CONSTANTS } from "../../theme/ThemeConstants";

interface IProps {
  title: string;
  text: string;
  tooltipContent?: NonNullable<React.ReactNode>;
  titleClassName?: string;
  textClassName?: string;
  containerClassName?: string;
  titleVariant?: Variant;

  tooltipEnterDelayMs?: number;
}

const useStyles = makeStyles<{ titleColor: string }>()((theme, prp) => ({
  title: {
    "--titleColor": prp.titleColor,
    fontWeight: "bold",
    color: "var(--titleColor)",
  },
}));

export const ConnectedTitleAndValue = observer<React.FC<IProps>>((props) => {
  const { classes } = useStyles({ titleColor: THEME_CONSTANTS.titleGray });
  const {
    title,
    text,
    tooltipContent,
    titleClassName,
    textClassName,
    containerClassName,
    titleVariant,
    tooltipEnterDelayMs,
  } = props;

  return (
    <TitleAndValue
      title={title}
      text={text}
      tooltipContent={tooltipContent}
      textClassName={clsx([
        // activeNetworkAssetCardTheme.dataTextColor,
        textClassName,
      ])}
      containerClassName={containerClassName}
      titleVariant={titleVariant ?? "h6"}
      titleClassName={clsx([classes.title, titleClassName])}
      enterDelayMs={tooltipEnterDelayMs}
    />
  );
});
