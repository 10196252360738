import { useEffect, useState } from "react";
import { IObservableArray } from "mobx/dist/internal";

/**
 * Takes an observable array and return a similar array that will react to changes in the observable array
 * NOTE : Currently only length changes will cause a rerender, so if needed, add more checks.
 * @param arr
 */
export function useStoreArray<T>(arr: IObservableArray<T>): T[] {
  const [normalArr, setNormalArr] = useState<T[]>([...arr]);

  const len = arr.length;
  useEffect(() => {
    if (len > 0) {
      setNormalArr([...arr]);
    }
  }, [arr, len]);

  return normalArr;
}
