import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { RewardsDialog } from "lynx-ui-components";
import {
  useCryptoWalletIntegrationStore,
  useLexStoreById,
  useModalsStore,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import { useQuery } from "@tanstack/react-query";
import { useIncentivesService } from "../../../services/servicesHooks.ts";
import { CHAIN_ID_FANTOM_OPERA } from "../../../constants/chainConstants.ts";
import { convertCompetitionRoundTraderInfoToLeaderBoardTableData } from "../../../pages/LeaderBoardPage/subComponents/tables/leaderboardTableUtils.ts";
import { EMPTY_COMPETITION_PLAN_DESCRIPTION } from "../../../services/servicesIntergration/incentivesService/IIncentivesService.ts";
import { useGetTimeLeftForCurrentRound } from "../../../pages/CompetitionsPage/competitionHooks.ts";

interface IProps {
  open: boolean;
  closeModal: () => void;
}

export const LeaderBoardModalWrapper = observer<React.FC<IProps>>((props) => {
  const { open, closeModal } = props;

  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const modalsStore = useModalsStore();
  const pricesStore = usePricesStore();

  const lexStore = useLexStoreById(modalsStore.leaderboardModal_lexId);

  const competitionDescription = lexStore?.currentCompetitionDescription;
  const competitionRoundNumber = competitionDescription?.roundNumber ?? 0;
  const hasLiveCompetition = competitionRoundNumber > 0;

  const rewardSymbol =
    competitionDescription?.details?.incentivesList[0]?.rewardSymbol ?? "";

  // const weeklyRewardsInUnits = 0;
  const weeklyRewardsInUnits = (
    competitionDescription?.details.incentivesList[0]?.ranks ?? []
  ).reduce((acc, rank) => {
    return acc + rank;
  }, 0);

  const poolAddress = lexStore?.poolAddress ?? "";

  const incentivesService = useIncentivesService(
    lexStore?.engineChainId ?? CHAIN_ID_FANTOM_OPERA,
  );

  const liveCompetitionTradersInfoResultQuery = useQuery({
    queryFn: () => {
      if (hasLiveCompetition) {
        return incentivesService.getLiveCompetitionTradersInfo(
          poolAddress,
          competitionRoundNumber,
        );
      } else {
        return Promise.resolve([]);
      }
    },
    queryKey: [
      "liveCompetitionTradersInfoResultQuery",
      {
        lex: poolAddress,
        roundNumber: competitionRoundNumber,
      },
    ],
    enabled: hasLiveCompetition,
  });

  const incentiveTokenPrice =
    pricesStore.symbolToPriceMap.get(rewardSymbol) ?? 0;

  const leaderboardRowsGists = useMemo(() => {
    return convertCompetitionRoundTraderInfoToLeaderBoardTableData(
      liveCompetitionTradersInfoResultQuery.data ?? [],
      competitionDescription ?? EMPTY_COMPETITION_PLAN_DESCRIPTION,
      incentiveTokenPrice,
    );
  }, [
    competitionDescription,
    incentiveTokenPrice,
    liveCompetitionTradersInfoResultQuery.data,
  ]);

  const durationLeftText = useGetTimeLeftForCurrentRound();

  return (
    <RewardsDialog
      open={open}
      closeModal={closeModal}
      title={`${lexStore?.sourceAssetParameters.symbol} Competition`}
      endsInText={durationLeftText}
      emptyTableText={"No Competition"}
      connectedAddress={walletConnectionStore.mainAddress}
      isLoading={liveCompetitionTradersInfoResultQuery.isLoading}
      rowGists={leaderboardRowsGists}
      settlementAssetSymbol={lexStore?.sourceAssetParameters?.symbol ?? ""}
      rewardAssetSymbol={rewardSymbol}
      weeklyRewardsInUnits={weeklyRewardsInUnits}
      // roundText={hasLiveCompetition
      //     ? `Epoch ${competitionRoundNumber}`
      //     : "No Competition"}
      activeRoundNumber={competitionRoundNumber}
      onClaimClick={() => {}}
      isWaitingForClaimTx={false}
      onSelectEpoch={() => {}}
      epochs={[]}
      pastRewardsValue={""}
    />
  );
});
