import { CHIP_DECIMALS } from "../constants/scales.ts";
import { floatToUnitsBn, unitsBnToFloat } from "./bignumbers.ts";

export function floatToChipsBn(value: number): bigint {
  return floatToUnitsBn(value, CHIP_DECIMALS);
}

export function chipsBnToUnits(chipAmount: bigint): number {
  return unitsBnToFloat(chipAmount, CHIP_DECIMALS);
}
