import type { TCompetitionParticipantGist } from "./ICompetitionGraphService.ts";
import { ICompetitionGraphService } from "./ICompetitionGraphService.ts";
import { gql, request } from "graphql-request";

export class CompetitionGraphService implements ICompetitionGraphService {
  constructor(private uri: string) {}

  async getCompetitionParticipantsGists(): Promise<
    TCompetitionParticipantGist[]
  > {
    const query = gql`
      query getCompetitionParticipants {
        traderCompetitions(orderDirection: desc, orderBy: volume) {
          totalPnL
          profitPositionsCounter
          positionsCount
          lossPositionsCounter
          trader {
            id
          }
          volume
        }
      }
    `;

    const variables = {};

    try {
      const competitionParticipantsGists: TCompetitionParticipantGist[] = [];

      const data = await request<{
        traderCompetitions: {
          trader: {
            id: string;
          };
          totalPnL: string;
          volume: string;
          positionsCount: string;
          profitPositionsCounter: string;
          lossPositionsCounter: string;
        }[];
      }>(this.uri, query, variables, {});

      competitionParticipantsGists.push(
        ...data.traderCompetitions.map((entry) => {
          return {
            traderAddress: entry.trader.id,
            totalPnL: BigInt(entry.totalPnL),
            volume: BigInt(entry.volume),
            positionsCount: Number(entry.positionsCount),
            profitPositionsCounter: Number(entry.profitPositionsCounter),
            lossPositionsCounter: Number(entry.lossPositionsCounter),
          };
        }),
      );

      return competitionParticipantsGists;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  // async getCompetitionParticipantsLivePositionsInfo(): Promise<unknown[]> {
  //   const query = gql`
  //     query getCompetitionParticipantsLiveInfo {
  //       competitionPositionTraderIndexes(
  //         where: { position_: { currentPhase: OPENED } }
  //       ) {
  //         position {
  //           closePrice
  //           leverage
  //           direction
  //           currentCollateral
  //           openPrice
  //           pairId
  //           positionId
  //           tp
  //         }
  //         traderCompetition {
  //           trader {
  //             id
  //           }
  //           totalPnL
  //         }
  //       }
  //     }
  //   `;
  //
  //   const variables = {};
  //
  //   try {
  //     const competitionParticipantsGists: TCompetitionParticipantGist[] = [];
  //
  //     const data = await request<{
  //       traderCompetitions: {
  //         trader: {
  //           id: string;
  //         };
  //         totalPnL: string;
  //         volume: string;
  //         positionsCount: string;
  //         profitPositionsCounter: string;
  //         lossPositionsCounter: string;
  //       }[];
  //     }>(this.uri, query, variables, {});
  //
  //     competitionParticipantsGists.push(
  //       ...data.traderCompetitions.map((entry) => {
  //         return {
  //           traderAddress: entry.trader.id,
  //           totalPnL: BigInt(entry.totalPnL),
  //           volume: BigInt(entry.volume),
  //           positionsCount: Number(entry.positionsCount),
  //           profitPositionsCounter: Number(entry.profitPositionsCounter),
  //           lossPositionsCounter: Number(entry.lossPositionsCounter),
  //         };
  //       }),
  //     );
  //
  //     return competitionParticipantsGists;
  //   } catch (e) {
  //     console.error(e);
  //     return [];
  //   }
  // }
}
