import { Provider, Signer } from "ethers";

import { IChipsIntentsVerifierService } from "./IChipsIntentsVerifierService.ts";
import { ChipsIntentsVerifierV1 } from "../../../typechain/contracts/Lynx/IntentsVerifier/ChipsIntentsVerifierV1.ts";
import { ChipsIntentsVerifierV1__factory } from "../../../typechain/factories/contracts/Lynx/IntentsVerifier/ChipsIntentsVerifierV1__factory.ts";
import { unitsBnToFloat } from "../../../utils/bignumbers.ts";

export class ChipsIntentsVerifierService
  implements IChipsIntentsVerifierService
{
  private chipsIntentsVerifierContract: ChipsIntentsVerifierV1;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.chipsIntentsVerifierContract = ChipsIntentsVerifierV1__factory.connect(
      address,
      signerOrProvider,
    );
  }

  async readChipOutFeeInUnitsForChip(chipAddress: string): Promise<number> {
    const rawFee =
      await this.chipsIntentsVerifierContract.chipOutFeeForChip(chipAddress);

    // Chips have 18 decimals
    return unitsBnToFloat(rawFee, 18);
  }
}
