import type { IStores } from "./stores.ts";

import { configure } from "mobx";
import { ILeverageDimensionsService } from "../services/leverageDimensionService/ILeverageDiomensionsService.ts";
import { CryptoWalletConnectionStore } from "./CryptoWalletConnectionStore.ts";
import { ModalsStore } from "./uxStores/ModalsStore.ts";
import { PricesStore } from "./singleInstanceStores/PricesStore.ts.ts";
import { SystemStore, TSystemContracts } from "./SystemStore.ts";
import { ContractServicesStore } from "./ContractServicesStore.ts";
import {
  ENGINE_CHAINS_IDS,
  LIVE_CHAIN_CONSTANTS,
  LIVE_CHAINS,
  TChainIds,
  TEngineChainIds,
  TLiveChainIds,
} from "../constants/chainConstants.ts";
import { LeverageDimensionsStore } from "./singleInstanceStores/LeverageDimensionsStore.ts";
import { ENGINE_CHAIN_ADDRESSES } from "../constants/chainSystemAddresses.ts";
import { RpcsStore } from "./RpcsStore.ts";

/**
 * Builds and initializes all the stores
 */
export function getStores(
  leverageDimensionsService: ILeverageDimensionsService,
): IStores {
  // Create stores instances + Hydrate the stores
  const cryptoWalletIntegrationStore = new CryptoWalletConnectionStore();
  const rpcsStore = new RpcsStore();
  const contractServicesStore = new ContractServicesStore(
    cryptoWalletIntegrationStore,
    rpcsStore,
  );

  const systemContractsMap = fetchSystemContractsMap();
  const systemStore = new SystemStore(
    cryptoWalletIntegrationStore,
    systemContractsMap,
  );

  const modalsStore = new ModalsStore();

  const pricesStore = new PricesStore();

  const stores: IStores = {
    cryptoWalletIntegrationStore,
    rpcsStore,
    systemStore: systemStore,
    contractServicesStore: contractServicesStore,
    leverageDimensionsStore: new LeverageDimensionsStore(
      cryptoWalletIntegrationStore,
      systemStore,
      contractServicesStore,
      pricesStore,
      leverageDimensionsService,
    ),
    modalsStore,
    pricesStore,
  };

  return stores;
}

/**
 * Quick-n-Dirty to get the system contracts.
 * TODO : Add Ministry and Registry
 */
function fetchSystemContractsMap(): Map<TChainIds, TSystemContracts> {
  const chainIds = ENGINE_CHAINS_IDS;

  const systemContractsMap = new Map<TChainIds, TSystemContracts>();

  for (const chainId of chainIds) {
    systemContractsMap.set(chainId, {
      tradingFloorProxyAddress:
        ENGINE_CHAIN_ADDRESSES[chainId].tradingFloorProxyAddress,
      tradersPortalAddress:
        ENGINE_CHAIN_ADDRESSES[chainId].tradersPortalAddress,
      triggersAddress: ENGINE_CHAIN_ADDRESSES[chainId].triggersAddress,

      chipIntentsVerifier:
        ENGINE_CHAIN_ADDRESSES[chainId].chipsIntentsVerifierAddress,

      lexLensAddress: ENGINE_CHAIN_ADDRESSES[chainId].lexLensAddress,
      tradingCenterLens:
        ENGINE_CHAIN_ADDRESSES[chainId].tradingFloorLensAddress,
      intentsVerifierLens:
        ENGINE_CHAIN_ADDRESSES[chainId].intentsVerifierLensAddress,
    });
  }

  return systemContractsMap;
}

/**
 * Configures the mobx library. Should get called at App's initialization.
 */
export function configureMobx() {
  configure({
    // TODO: CRITICAL : Uncomment this
    // enforceActions: "always",
    // computedRequiresReaction: true,
    // observableRequiresReaction: true,
    // reactionRequiresObservable: true,
    disableErrorBoundaries: true,
  });
}
