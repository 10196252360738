import { makeStyles } from "tss-react/mui";
import { TEXT_ON_DARK } from "./GeneralThemeConstants.ts";
import { KEYFRAMES } from "./keyframes.ts";
import { THEME_CONSTANTS } from "./ThemeConstants.ts";

export const useCommonClasses = makeStyles()((theme) => ({
  mobileHide: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    },
  },
  label: {
    // fontFamily: 'Haas Grot Text R',
    fontStyle: "medium",
    fontWeight: 600,
    fontSize: "12px",
    color: "#AAB8C1",
  },

  textRight: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
  },
  icon: {
    "--iconSize": "2.8rem",
    flexShrink: 0,
    display: "inline-block",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    borderRadius: "50%",
    width: "var(--iconSize)",
    height: "var(--iconSize)",
    minWidth: "var(--iconSize)",
    minHeight: "var(--iconSize)",
  },
  textPositive: {
    color: "#4caf50",
  },
  textNegative: {
    color: "#f44336",
  },
  textNeutral: {
    color: "inherit",
  },
}));

export const PANELS_GAP_REM = 0.4;

export const useStructureClasses = makeStyles()((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    minHeight: "100vh",
  },

  pageRoot: {
    width: "100%",
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    gap: `${PANELS_GAP_REM}rem`,

    transition: "500ms ease",
  },
  pageContent: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0.25) as string,
    gap: `${PANELS_GAP_REM}rem`,

    animation: `${KEYFRAMES.appear} 0.8s ease`,

    // flexWrap: "wrap",
    // overflow: "auto",

    // TODO : Move this to components ?
    color: TEXT_ON_DARK,

    transition: "500ms ease",

    // Mobile
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },

  pageContent_column: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    padding: theme.spacing(0.25) as string,
    gap: `${PANELS_GAP_REM}rem`,

    animation: `${KEYFRAMES.appear} 0.8s ease`,

    // TODO : Move this to components ?
    color: TEXT_ON_DARK,

    // transition: "500ms ease",

    // Mobile
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column-reverse",
    // },
  },
  linksParts: {
    color: THEME_CONSTANTS.brandYellow,
  },
}));
