import { useCallback, useEffect } from "react";
import { GraphQLService } from "../../services/servicesIntergration/graphqlService/GraphQLService.ts";
import { IS_DEV, IS_PROD } from "../../configs.ts";
import { isSameAddress } from "../../utils/addresses.ts";
import { stringifyObject } from "../../utils/strings.ts";
import {
  BOBA_ENGINE_POOL_ADDRESSES,
  BOBA_GRAPH_URL,
  FANTOM_ENGINE_POOL_ADDRESSES,
  FANTOM_GRAPH_URL,
} from "../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { useLeverageDimensionsStore } from "../../store/storeHooks.ts";
import { useIncentivesService } from "../../services/servicesHooks.ts";
import {
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_FANTOM_OPERA,
  TChainIds,
} from "../../constants/chainConstants.ts";
import { PartialRecord } from "../../utils/types.ts";
import {
  INCENTIVE_PLAN_TYPE_KEYS,
  TCompetitionPlanIncentiveDescription,
} from "../../services/servicesIntergration/incentivesService/IIncentivesService.ts";
import { timeUtils } from "../../utils/timeUtils.ts";
import { boba } from "viem/chains";
import { plPL } from "@mui/material/locale";

const lineaVeVlcPoolAddress = "0x46cff1b57e92a7dfd032e2c344cbb987ee1a8876";
const lineaLvcPoolAddress = "0x1136d541113E9C643059B8c6850Cc35B50D4a5f0";
const fusePDAIPoolAddress = FANTOM_ENGINE_POOL_ADDRESSES.fuse.PDAI;

export function useSyncInteractionRecordsForUser(userAddress: string) {
  const leverageDimensionsStore = useLeverageDimensionsStore();

  const readUserInteractionsRecords = useCallback(
    async (graphUrl: string, userAddress: string) => {
      console.log(`^^^ Reading User Interactions Records ${userAddress}`);

      const graphQlService = new GraphQLService(graphUrl);

      const records =
        await graphQlService.getAllInteractionRecordsForUser(userAddress);

      console.log(
        `^^^ Found ${records.length} records for user ${userAddress} out of ${leverageDimensionsStore.lexStores.length} lexes`,
      );

      const lexPoolAddressesToHide = [
        lineaVeVlcPoolAddress.toLowerCase(),
        lineaLvcPoolAddress.toLowerCase(),
      ];
      if (!IS_DEV) {
        lexPoolAddressesToHide.push(fusePDAIPoolAddress.toLowerCase());
      }
      const filteredRecords = records.filter(
        (record) =>
          !lexPoolAddressesToHide.includes(record.poolAddress.toLowerCase()),
      );

      for (const record of filteredRecords) {
        const matchingLexStore = leverageDimensionsStore.lexStores.find(
          (lexStore) => isSameAddress(record.poolAddress, lexStore.poolAddress),
        );

        if (!matchingLexStore) {
          console.warn(
            `!!! Did not find a matching lex store (${record.poolAddress}) for interactions record ${stringifyObject(record)}`,
          );
          continue;
        }

        // console.log(`Found lex store ${record.poolAddress}`);
        matchingLexStore
          .getUserStore(userAddress)
          .setInteractionsRecord(record);
      }
    },
    [leverageDimensionsStore.lexStores],
  );

  useEffect(() => {
    if (userAddress) {
      readUserInteractionsRecords(FANTOM_GRAPH_URL, userAddress).catch((e) =>
        console.error(`Error in readUserInteractionsRecords for fantom ${e}`),
      );

      readUserInteractionsRecords(BOBA_GRAPH_URL, userAddress).catch((e) =>
        console.error(`Error in readUserInteractionsRecords for boba ${e}`),
      );
    }
  }, [readUserInteractionsRecords, userAddress]);
}

export function useSyncLivePlans() {
  const incentivesServiceFantom = useIncentivesService(CHAIN_ID_FANTOM_OPERA);
  const incentivesServiceBoba = useIncentivesService(CHAIN_ID_BOBA_MAINNET);
  const leverageDimensionsStore = useLeverageDimensionsStore();

  const readAllLivePlans = useCallback(async () => {
    const lexPoolAddressesToHide: string[] = [];

    if (IS_PROD) {
      lexPoolAddressesToHide.push(fusePDAIPoolAddress);
    }

    const plansFantom =
      await incentivesServiceFantom.getAllLiveIncentivePlans();
    const plansBoba = await incentivesServiceBoba.getAllLiveIncentivePlans();
    const plans = {
      competitions: [...plansFantom.competitions, ...plansBoba.competitions],
    };

    const competitionPlans = plans.competitions.filter(
      (plan) => plan.lexAddress,
    );

    // TODO : CRITICAL : Remove this manual competition info and move it to the baas
    // const manualPlan_boba_round_1: TCompetitionPlanIncentiveDescription = {
    //   lexAddress: BOBA_ENGINE_POOL_ADDRESSES.boba.BOBA,
    //   startTimestamp: 1725840000,
    //   endTimestamp: 1726444800,
    //   roundNumber: 1,
    //   planCode: INCENTIVE_PLAN_TYPE_KEYS.topComp,
    //   details: {
    //     incentivesList: [
    //       {
    //         rewardSymbol: "BOBA",
    //         ranks: [
    //           1000, 775, 650, 525, 425, 350, 300, 250, 200, 150, 125, 100, 75,
    //           50, 25,
    //         ],
    //       },
    //     ],
    //   },
    // };

    // const currentTimestamp = timeUtils.currentTimeInSeconds();
    //
    // if (
    //   currentTimestamp > manualPlan_boba_round_1.startTimestamp &&
    //   currentTimestamp < manualPlan_boba_round_1.endTimestamp
    // ) {
    //   competitionPlans.push(manualPlan_boba_round_1);
    // }

    for (const plan of competitionPlans) {
      const matchingLexStore = leverageDimensionsStore.lexStores.find(
        (lexStore) => isSameAddress(plan.lexAddress, lexStore.poolAddress),
      );

      if (!matchingLexStore) {
        console.warn(
          `!!! Did not find a matching lex store (${plan.lexAddress}) for incentives plan ${stringifyObject(plan)}`,
        );
        continue;
      }

      matchingLexStore.setCurrentCompetitionDescription(plan);
    }
  }, [
    incentivesServiceFantom,
    incentivesServiceBoba,
    leverageDimensionsStore.lexStores,
  ]);

  useEffect(() => {
    readAllLivePlans().catch((e) =>
      console.error(`Error in incentivesService.getAllLiveIncentivePlans ${e}`),
    );
  }, [readAllLivePlans]);
}

export function useSyncRpcsFromBaas() {
  // TODO : Add here baas reading when needed
  const readRpcsFromBaas = useCallback(async () => {
    const rpcsForChains: { chainId: TChainIds; rpcAddress: string }[] = [];

    return Promise.resolve(rpcsForChains);
  }, []);

  useEffect(() => {
    readRpcsFromBaas()
      .then((rpcsForChains) => {
        // TODO : Add logic here
      })
      .catch((e) => console.error(`Error in readRpcsFromBaas ${e}`));
  }, [readRpcsFromBaas]);
}
