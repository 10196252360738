import { TKeysOfConst } from "../utils/enumTypes";

export const PositionPhaseEnums = {
  DELEGATING: -1n,
  NONE: 0n,
  OPEN_MARKET: 1n,
  OPEN_LIMIT: 2n,
  OPENED: 3n,
  CLOSE_MARKET: 4n,
  CLOSED: 5n,
};
export type TPositionPhaseType = (typeof PositionPhaseEnums)[TKeysOfConst<
  typeof PositionPhaseEnums
>];

// NOTE : Quick-n-Dirty to get the enum as numbers
// TODO : Create a TS converter
const PositionPhaseEnumsNumeric = {
  NONE: 0,
  OPEN_MARKET: 1,
  OPEN_LIMIT: 2,
  OPENED: 3,
  CLOSE_MARKET: 4,
  CLOSED: 5,
};
export type TPositionPhaseTypeNumeric =
  (typeof PositionPhaseEnumsNumeric)[TKeysOfConst<
    typeof PositionPhaseEnumsNumeric
  >];

export const OpenOrderTypeEnums = {
  NONE: 0,
  MARKET: 1,
  LIMIT: 2,
};
export type TOpenOrderType = (typeof OpenOrderTypeEnums)[TKeysOfConst<
  typeof OpenOrderTypeEnums
>];

export const CloseOrderTypeEnums = {
  NONE: 0,
  MARKET: 1,
};
export type TCloseOrderType = (typeof CloseOrderTypeEnums)[TKeysOfConst<
  typeof CloseOrderTypeEnums
>];

export const LimitTriggerEnums = {
  NONE: 0n,
  TP: 1n,
  SL: 2n,
  LIQ: 3n,
};
export type TLimitTriggerTypesEnums = (typeof LimitTriggerEnums)[TKeysOfConst<
  typeof LimitTriggerEnums
>];

export const FeeTypeEnums = {
  OPEN_FEE: 0,
  OPEN_WITH_REF_FEE: 1,
  CLOSE_FEE: 2,
  TRIGGER_FEE: 3,
};

export type TFeeType = (typeof FeeTypeEnums)[TKeysOfConst<typeof FeeTypeEnums>];

export const PositionFieldEnums = {
  NONE: 0n,
  TP: 1n,
  SL: 2n,
};

export type TPositionField = (typeof PositionFieldEnums)[TKeysOfConst<
  typeof PositionFieldEnums
>];

export const AdminNumericParam = {
  NONE: 0,
  MAX_TRADES_PER_PAIR: 1,
  MAX_SL_P: 2,
};

export type TAdminNumericParamType = (typeof AdminNumericParam)[TKeysOfConst<
  typeof AdminNumericParam
>];

// ****** Lex ******

export const PoolAccountantAddressesEnums = {
  none: 0,
  irm: 1,
  frm: 2,
  rainMaker: 3,
};
export type TPoolAccountantAddresses =
  (typeof PoolAccountantAddressesEnums)[TKeysOfConst<
    typeof PoolAccountantAddressesEnums
  >];

export const PoolAccountantNumberEnums = {
  none: 1,
  minOpenFee: 1,
  lexPartF: 2,
  maxGainP: 3,
  liquidationThresholdF: 4,
  liquidationFeeF: 5,
  fundingRateMax: 6,
  reservesFactor: 7,
  borrowRateMax: 8,
  maxTotalBorrows: 9,
  maxVirtualUtilization: 10,
};
export type TPoolAccountantNumbers =
  (typeof PoolAccountantNumberEnums)[TKeysOfConst<
    typeof PoolAccountantNumberEnums
  >];

export const LexPoolAddressesEnums = {
  none: 0,
  poolAccountant: 1,
  pnlRole: 2,
};
export type TLexPoolAccountantAddress =
  (typeof LexPoolAddressesEnums)[TKeysOfConst<typeof LexPoolAddressesEnums>];

export const LexPoolNumberEnums = {
  none: 0,
  maxExtraWithdrawalAmountP: 1,
  epochsDelayDeposit: 2,
  epochsDelayRedeem: 3,
  epochDuration: 4,
  minDepositAmount: 5,
};
export type TLexPoolAccountantNumbers =
  (typeof LexPoolNumberEnums)[TKeysOfConst<typeof LexPoolNumberEnums>];

// ****** Order Book ******

export const UpdatePositionFieldOrderTypeEnums = {
  NONE: 0n,
  UPDATE_TP: 1n,
  UPDATE_SL: 2n,
  UPDATE_TP_AND_SL: 3n,
};
export type TUpdatePositionFieldOrderType =
  (typeof UpdatePositionFieldOrderTypeEnums)[TKeysOfConst<
    typeof UpdatePositionFieldOrderTypeEnums
  >];

// ****** Base Chip ******
export const ChipModeEnums = {
  NONE: 0n,
  LOCAL: 1n,
  REMOTE: 2n,
  HYBRID: 3n,
};

export type TChipModeEnum = (typeof ChipModeEnums)[TKeysOfConst<
  typeof ChipModeEnums
>];

export const ChipSpenderRolesEnums = {
  TradingFloor: "TradingFloor",
  LexPool: "LexPool",
  ChipsIntentsVerifier: "ChipsIntentsVerifier",
};

export type TChipSpenderRoles = (typeof ChipSpenderRolesEnums)[TKeysOfConst<
  typeof ChipSpenderRolesEnums
>];

// ****** Trade Intents Verifier ******

export const TradeIntentsVerifierActionsEnums = {
  NONE: 0n,
  REQUEST_POSITION_OPEN: 1n,
  REQUEST_POSITION_MARKET_CLOSE: 2n,
  REQUEST_POSITION_SINGLE_FIELD_UPDATE: 3n,
  REQUEST_POSITION_DOUBLE_FIELD_UPDATE: 4n,
  DIRECT_UPDATE_PENDING_LIMIT_POSITION: 5n,
  DIRECT_CANCEL_PENDING_LIMIT_POSITION: 6n,
};

export type TTradeIntentsVerifierActionsEnum =
  (typeof TradeIntentsVerifierActionsEnums)[TKeysOfConst<
    typeof TradeIntentsVerifierActionsEnums
  >];

// ****** Liquidity Intents Verifier ******

export const LiquidityIntentsVerifierActionsEnums = {
  NONE: 0n,
  REQUEST_EPOCH_DEPOSIT: 1n,
  REQUEST_EPOCH_REDEEM: 2n,
};

export type TLiquidityIntentsVerifierActionsEnum =
  (typeof LiquidityIntentsVerifierActionsEnums)[TKeysOfConst<
    typeof LiquidityIntentsVerifierActionsEnums
  >];

// ****** Chips Intents Verifier ******

export const ChipsIntentsVerifierActionsEnums = {
  NONE: 0n,
  CHIP_OUT_LZ: 1n,
};

export type TChipsIntentsVerifierActionsEnum =
  (typeof ChipsIntentsVerifierActionsEnums)[TKeysOfConst<
    typeof ChipsIntentsVerifierActionsEnums
  >];
