// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDg-l3JdeTIsQAQIbZFc6fgDO6tNfl55LA",
  authDomain: "lynx-protocol-app.firebaseapp.com",
  projectId: "lynx-protocol-app",
  storageBucket: "lynx-protocol-app.appspot.com",
  messagingSenderId: "782868730248",
  appId: "1:782868730248:web:dd48a56b519045763d2a3d",
  measurementId: "G-LRTCYGX19E",
};

export let firebaseAnalytics: Analytics;

// Initialize Firebase
export function initializeFirebase() {
  const app = initializeApp(firebaseConfig);
  firebaseAnalytics = getAnalytics(app);
}
