import { action, computed, makeObservable, observable } from "mobx";
import type { IObservableArray } from "mobx";
import { makePersistable } from "mobx-persist-store";

import type { TCompletePositionDataFromLens } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";

import { SingleTradePairStore } from "./SingleTradePairStore.ts";
import {
  deserializeCompletePositionDataFromLens,
  serializeCompletePositionDataFromLens,
} from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import { TClosedPositionGist } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { entitiesSerialization } from "../../../utils/entitiesSerialization.ts";

export class SingleTradePairUserStore {
  @observable public accountAddress: string;

  // Trader State
  @observable
  pendingLivePositionsInPair: IObservableArray<TCompletePositionDataFromLens> =
    observable([]);

  @observable
  livePositionsInPair: IObservableArray<TCompletePositionDataFromLens> =
    observable([]);

  @observable closedPositionGists: IObservableArray<TClosedPositionGist> =
    observable([]);

  @observable cancelledPositionGists: IObservableArray<TClosedPositionGist> =
    observable([]);

  constructor(
    public pairStore: SingleTradePairStore,
    _accountAddress: string,
  ) {
    makeObservable(this);

    void makePersistable(this, {
      name: `SingleTradePairUserStore_${pairStore.ownLexStore.id}_${pairStore.pairId}_${_accountAddress}`,
      properties: [
        // {
        //   key: "livePositionsInPair",
        //   serialize: (arr) => {
        //     return JSON.stringify(
        //       arr.map((v) => serializeCompletePositionDataFromLens(v)),
        //     );
        //   },
        //   // @ts-ignore
        //   deserialize: (arrStr: string) => {
        //     const arr = JSON.parse(arrStr) as string[];
        //     return arr.map((v) => deserializeCompletePositionDataFromLens(v));
        //   },
        // },
        //
        //
        {
          key: "closedPositionGists",
          serialize: (arr) => {
            return JSON.stringify(
              arr.map((v) =>
                entitiesSerialization.closedPositionGistFromGraph.serialize(v),
              ),
            );
          },
          // @ts-ignore
          deserialize: (arrStr: string) => {
            const arr = JSON.parse(arrStr) as string[];
            return arr.map((v) =>
              entitiesSerialization.closedPositionGistFromGraph.deserialize(v),
            );
          },
        },
        {
          key: "cancelledPositionGists",
          serialize: (arr) => {
            return JSON.stringify(
              arr.map((v) =>
                entitiesSerialization.closedPositionGistFromGraph.serialize(v),
              ),
            );
          },
          // @ts-ignore
          deserialize: (arrStr: string) => {
            const arr = JSON.parse(arrStr) as string[];
            return arr.map((v) =>
              entitiesSerialization.closedPositionGistFromGraph.deserialize(v),
            );
          },
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 24 * 2 * 1000, // 2 days
      removeOnExpiration: true,
    });

    this.accountAddress = _accountAddress;
  }

  // **** Account Position state  ****

  @computed
  public get firstAvailablePositionIndexForAccount(): number {
    const maxPositionsPerPair =
      this.pairStore.ownLexStore.tradingFloorStore.maxTradesPerPair;

    for (
      let possibleIndex = 1;
      possibleIndex <= maxPositionsPerPair;
      possibleIndex++
    ) {
      const positionWithId = this.livePositionsInPair.find(
        (p) => Number(p.positionIndex) == possibleIndex,
      );

      if (!positionWithId) {
        return possibleIndex;
      }
    }

    // TODO : Think about return value and limit the creation of new positions in the ui
    return 0;
  }

  // **** Outer Updating ****

  public updateWithFreshTraderSpecificState(
    openTrades: TCompletePositionDataFromLens[],
    closedPositions: TClosedPositionGist[],
    cancelledPositions: TClosedPositionGist[],
  ) {
    this.batchUpdateFromTraderState(
      openTrades,
      closedPositions,
      cancelledPositions,
    );
  }

  // **** Complex Setters ****

  @action("batchUpdateFromTraderState")
  private batchUpdateFromTraderState(
    livePositions: TCompletePositionDataFromLens[],
    closedPositions: TClosedPositionGist[],
    cancelledPositions: TClosedPositionGist[],
  ) {
    this.replaceLivePositionsInPair(livePositions);
    this.addNewEntriesToClosedPositionGists(closedPositions);
    this.addNewEntriesToCancelledPositionGists(cancelledPositions);
  }

  // **** Setters ****

  @action("addLivePositionsInPair")
  public addLivePositionsInPair(trade: TCompletePositionDataFromLens) {
    this.pendingLivePositionsInPair.push(trade);
    this.livePositionsInPair.push(trade);
  }

  private applyPendingPositionInPair(
    trades: TCompletePositionDataFromLens[],
  ): TCompletePositionDataFromLens[] {
    const successPositionIndices = new Set(
      trades.map((lp) => lp.positionIndex),
    );
    this.pendingLivePositionsInPair.replace(
      this.pendingLivePositionsInPair.filter(
        (p) => !successPositionIndices.has(p.positionIndex),
      ),
    );
    return trades.concat(this.pendingLivePositionsInPair);
  }

  @action("replaceLivePositionsInPair")
  private replaceLivePositionsInPair(trades: TCompletePositionDataFromLens[]) {
    this.livePositionsInPair.replace(this.applyPendingPositionInPair(trades));
  }

  @action("replaceClosedPositionGists")
  private replaceClosedPositionGists(closedPositions: TClosedPositionGist[]) {
    // console.log(`!!! closedPositions : ${closedPositions.length}`);
    this.closedPositionGists.replace(closedPositions);
  }

  @action("addNewEntriesToClosedPositionGists")
  private addNewEntriesToClosedPositionGists(
    closedPositions: TClosedPositionGist[],
  ) {
    const newEntriesOnly = closedPositions.filter((closedPosition) => {
      const isNewElement =
        this.closedPositionGists.findIndex((element) =>
          isSameClosedPositionGist(closedPosition, element),
        ) == -1;

      return isNewElement;
    });

    // console.log(`!!! closedPositions : ${closedPositions.length}`);
    this.closedPositionGists.replace([
      ...this.closedPositionGists,
      ...newEntriesOnly,
    ]);

    // console.log(
    //   `this.closedPositionGists ${this.pairStore.ownLexStore.sourceAssetParameters.symbol} ${this.pairStore.pairName} ${this.accountAddress} : ${this.closedPositionGists.length}`,
    // );
  }

  @action("replaceCancelledPositionGists")
  private replaceCancelledPositionGists(
    cancelledPositions: TClosedPositionGist[],
  ) {
    this.cancelledPositionGists.replace(cancelledPositions);
  }

  @action("addNewEntriesToCancelledPositionGists")
  private addNewEntriesToCancelledPositionGists(
    cancelledPositions: TClosedPositionGist[],
  ) {
    const newEntriesOnly = cancelledPositions.filter((cancelledPosition) => {
      const isNewElement =
        this.cancelledPositionGists.findIndex((element) =>
          isSameClosedPositionGist(cancelledPosition, element),
        ) == -1;

      return isNewElement;
    });
    this.cancelledPositionGists.replace([
      ...this.cancelledPositionGists,
      ...newEntriesOnly,
    ]);

    // console.log(
    //   `this.cancelledPositionGists ${this.pairStore.ownLexStore.sourceAssetParameters.symbol} ${this.pairStore.pairName} ${this.accountAddress} : ${this.cancelledPositionGists.length}`,
    // );
  }
}

function isSameClosedPositionGist(
  a: TClosedPositionGist,
  b: TClosedPositionGist,
): boolean {
  return a.entityId === b.entityId && a.sameIdCounter == b.sameIdCounter;
}
