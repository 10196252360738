import { Box, createTheme, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useInterval from "react-useinterval";

import {
  RewardsDataType,
  RightSidePanel,
  ToggleTabBar,
  TradePairSelection,
  type TTradePairSelectionOptionGist,
} from "lynx-ui-components";

import {
  useCryptoWalletIntegrationStore,
  useModalsStore,
  useActiveLexStoreStore,
  usePricesStore,
  useLeverageDimensionsStore,
} from "../../store/storeHooks";
import { PositionsAndOrdersDisplayPanel } from "./subComponents/PositionsAndOrdersDisplayPanel";
import { PairDisplayHeader } from "./subComponents/PairDisplayHeader";
import { PAIR_CONFIGS_IDS } from "../../constants/pairsConstants";
import { toastTxError } from "../../ux/toasts/complexToasting";
import { LeverageOverviewDisplayPanel } from "./subComponents/LeverageOverviewDisplayPanel";
import { TOpenOrderType } from "../../constants/contractEnums";

import { SingleTradePairStore } from "../../store/multiInstancesStores/TradePairStore/SingleTradePairStore.ts";
import { BytesLike, ethers } from "ethers";
import { ConnectedPositionUpdateModal } from "../../components/Modals/PositionUpdateModal/ConnectedPositionUpdateModal.tsx";
import {
  useUrlQueryState_activeChain,
  useUrlQueryState_activePairId,
  useUrlQueryState_activeSettlementAsset,
  useUrlQueryValue_referralCode,
} from "../../navigation/navigationStateHooks.ts";
import {
  PANELS_GAP_REM,
  useStructureClasses,
} from "../../theme/commonClasses.ts";
import ConnectedPositionOpenPanel from "./ConnectedPositionOpenPanel.tsx";
import { useRequestWalletConnect } from "../../hooks/walletConnectionHooks.ts";
import { useActionType } from "../../hooks/actionTypeHooks.ts";
import {
  CHAIN_ID_FANTOM_OPERA,
  LIVE_CHAIN_CONSTANTS,
  TChainIds,
} from "../../constants/chainConstants.ts";
import { useWaitForTx } from "../../hooks/txWaitingHooks.tsx";
import { calculatePositionHashId } from "../../utils/positionIdcalculation.ts";
import { TAsyncCallbackCreator } from "../../types/generalTypes.ts";
import { PositionInformingService } from "../../services/servicesIntergration/positionInforming/PositionInforminService.ts";
import { ACTION_INFORMING_ENDPOINTS_BY_ENGINE_CHAIN_ID } from "../../constants/externalServicesConstants.ts";
import PositionShareModalWrapper from "../../components/Modals/modalWrappers/PositionShareModalWrapper.tsx";
import PositionInfoModalWrapper from "../../components/Modals/modalWrappers/PositionInfoModalWrapper.tsx";
import { useGetDomainString } from "../../navigation/domainHooks.ts";
import { localPriceStringer, localStringer } from "../../ux/uxFunctions.ts";
import {
  FOREX_SUPPORTING_LEX_IDS,
  LED_IDS,
} from "../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { LeaderBoardModalWrapper } from "../../components/Modals/modalWrappers/LeaderBoardModalWrapper.tsx";
import { getGasPrice } from "viem/actions";
import { useAccount, useGasPrice } from "wagmi";
import { unitsBnToFloat } from "../../utils/bignumbers.ts";
import { GWEI_DECIMALS } from "../../constants/scales.ts";
import { toastError, toastInfo } from "../../ux/toasts/toasting.ts";
import { THEME_CONSTANTS } from "../../theme/ThemeConstants.ts";

export const LeveragePage: React.FC = observer(() => {
  const { classes: pageClasses } = useStructureClasses();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1100,
        lg: 1440,
        xl: 1536,
      },
    },
  });
  const { queryChainId } = useUrlQueryState_activeChain();

  // Leverage page should always have the SA param
  useUrlQueryState_activeSettlementAsset();

  const ledStore = useActiveLexStoreStore();

  const walletConnectionStore = useCryptoWalletIntegrationStore();
  const pricesStore = usePricesStore();
  const modalsStore = useModalsStore();

  const { setTxHash, setTxToWaitForAndCallbacks, data } = useWaitForTx(
    ledStore.engineChainId,
  );

  const isConnectedToWallet = walletConnectionStore.isConnectedToWallet;

  const baseTrick = pricesStore.lastPythFeedUpdateTimestamp;

  const pairSelectOptions: TTradePairSelectionOptionGist[] = useMemo(() => {
    // NOTE : Quick-n-Dirty trick to force options update on price change
    const none = baseTrick;

    const optionsToUse = [];

    if (ledStore.tradePairsStores.length) {
      let pairStoresToUse = [...(ledStore.tradePairsStores ?? [])];

      const shouldShowCatMode = [LED_IDS.FANTOM_ENGINE_FUSE_PDAI].includes(
        ledStore.id,
      );
      const shouldShowForex = FOREX_SUPPORTING_LEX_IDS.includes(ledStore.id);

      if (!shouldShowCatMode) {
        pairStoresToUse = pairStoresToUse.filter(
          (pairStore) => !pairStore.isCatMode,
        );
      }

      if (!shouldShowForex) {
        pairStoresToUse = pairStoresToUse.filter(
          (pairStore) => !pairStore.isForex,
        );
      }

      optionsToUse.push(
        ...pairStoresToUse.map((pairStore) => ({
          // value: `${tpStore.assetAId}-${tpStore.assetBId}`,
          value: pairStore.pairId.toString(),
          // TODO : Add a function to get the display decimals for pair
          name: `${pairStore.assetAId}-${pairStore.assetBId}${pairStore.isCatMode ? " (CAT)" : ""}`,
          symbolA: pairStore.assetAId,
          symbolB: pairStore.assetBId,
          priceUsd: pricesStore.getPriceForPairId(pairStore.pairId),
        })),
      );
    } else {
      // Note : Values that will be shown when there is no wallet coonected for example
      const defaultGists = [
        {
          pairId: PAIR_CONFIGS_IDS.BTC_USD,
          assetAId: "BTC",
          assetBId: "USD",
        },
        {
          pairId: PAIR_CONFIGS_IDS.ETH_USD,
          assetAId: "ETH",
          assetBId: "USD",
        },
        {
          pairId: PAIR_CONFIGS_IDS.BNB_USD,
          assetAId: "BNB",
          assetBId: "USD",
        },
      ];

      optionsToUse.push(
        ...defaultGists.map((gist) => ({
          // value: `${tpStore.assetAId}-${tpStore.assetBId}`,
          value: gist.pairId.toString(),
          // TODO : Add a function to get the display decimals for pair
          name: `${gist.assetAId}-${gist.assetBId}`,
          symbolA: gist.assetAId,
          symbolB: gist.assetBId,
          priceUsd: pricesStore.getPriceForPairId(gist.pairId),
        })),
      );
    }

    return optionsToUse;
  }, [baseTrick, ledStore.id, ledStore.tradePairsStores, pricesStore]);

  // *** active broker identity ***

  const [activePairIdValue, setActivePairIdValue] = useState("");

  const [activeTradePairStore, setActiveTradePairStore] = useState<
    SingleTradePairStore | undefined
  >(undefined);

  // *** active broker interaction ***
  const { queryPairId, setQueryPairId } = useUrlQueryState_activePairId();

  const optionsLength = pairSelectOptions.length;

  // Auto select the first broker as soon as the list is ready
  useEffect(() => {
    if (optionsLength && activePairIdValue === "") {
      if (queryPairId) {
        setActivePairIdValue(queryPairId);
      } else {
        setActivePairIdValue(pairSelectOptions[0].value);
      }
    }
  }, [queryPairId, activePairIdValue, pairSelectOptions, optionsLength]);

  // Updates the active store to match the selection
  useEffect(() => {
    if (ledStore && activePairIdValue) {
      const parsedPairId = parseInt(activePairIdValue);

      const matchingTradePairStore =
        ledStore.getTradePairStoreById(parsedPairId);
      setActiveTradePairStore(matchingTradePairStore);
    }
  }, [activePairIdValue, ledStore]);

  const eoaActionType = useActionType(Number(queryChainId) as TChainIds);

  const requestConnectToWallet = useRequestWalletConnect();
  const leverageDimensionsStore = useLeverageDimensionsStore();
  const { referralCodeValue } = useUrlQueryValue_referralCode();

  const domainStr = useGetDomainString();
  const referrerStr = referralCodeValue ?? "";

  const lexStoresWithLiveCompetitionPlan =
    leverageDimensionsStore.lexStores.filter(
      (lexStore) => lexStore.currentCompetitionDescription.roundNumber > 0,
    );

  const { rowGists, totalWeeklyCompetitionRewardsInUnitsValue } = useMemo<{
    totalWeeklyCompetitionRewardsInUnitsValue: number;
    rowGists: RewardsDataType[];
  }>(() => {
    const rowGists: RewardsDataType[] = [];
    let totalWeeklyCompetitionRewardsInUnitsValue = 0;
    for (const lexStoreWithCompetition of lexStoresWithLiveCompetitionPlan) {
      if (
        lexStoreWithCompetition.currentCompetitionDescription.roundNumber > 0
      ) {
        const competitionPlan =
          lexStoreWithCompetition.currentCompetitionDescription;

        for (const incentiveDetail of competitionPlan.details.incentivesList) {
          const rewardSymbol = incentiveDetail.rewardSymbol;
          const totalWeeklyCompetitionRewardsInUnits =
            incentiveDetail.ranks.reduce((acc, rank) => {
              return acc + rank;
            }, 0);

          const rewardAssetPriceInUnits =
            pricesStore.symbolToPriceMap.get(rewardSymbol) ?? 0;
          const rowGist: RewardsDataType = {
            lexId: lexStoreWithCompetition.id,
            status:
              lexStoreWithCompetition.id === ledStore.id ? "active" : undefined,
            chainName:
              LIVE_CHAIN_CONSTANTS[lexStoreWithCompetition.sourceChainId]
                ?.shortDisplayName,
            chainId: lexStoreWithCompetition.sourceChainId,
            assetSymbol: lexStoreWithCompetition.sourceAssetParameters.symbol,
            rewardAssetSymbol: incentiveDetail.rewardSymbol,
            // incentiveDetail.rewardSymbol ==
            // lexStore.sourceAssetParameters.symbol
            //   ? ""
            //   : incentiveDetail.rewardSymbol,
            totalWeeklyCompetitionRewardsInUnits: `${localStringer(Math.floor(totalWeeklyCompetitionRewardsInUnits))}`,
            totalRewardsUsdValueInUnits: Math.floor(
              totalWeeklyCompetitionRewardsInUnits * rewardAssetPriceInUnits,
            ),
          };
          totalWeeklyCompetitionRewardsInUnitsValue +=
            totalWeeklyCompetitionRewardsInUnits * rewardAssetPriceInUnits;
          rowGists.push(rowGist);
        }
      }
    }

    const sortedRowGists = rowGists.sort((a, b) => {
      // Start by sorting by identity match with active lex
      const isAEqual = ledStore.id === a.lexId;
      const isBEqual = ledStore.id === b.lexId;

      // If `a` matches and `b` doesn't, `a` should come first
      if (isAEqual && !isBEqual) return -1;
      // If `b` matches and `a` doesn't, `b` should come first
      if (!isAEqual && isBEqual) return 1;

      // Then put all active chain first
      const isAInActiveChain = a.chainId === ledStore.sourceChainId;
      const isBInActiveChain = a.chainId === ledStore.sourceChainId;
      if (isAInActiveChain && !isBInActiveChain) return -1;
      if (!isAInActiveChain && isBInActiveChain) return 1;
      return 0;

      // Then, sort by usd value
      // return b.totalRewardsUsdValueInUnits - a.totalRewardsUsdValueInUnits;
    });

    return {
      rowGists: sortedRowGists,
      totalWeeklyCompetitionRewardsInUnitsValue,
    };
  }, [
    ledStore.id,
    ledStore.sourceChainId,
    lexStoresWithLiveCompetitionPlan,
    pricesStore.symbolToPriceMap,
  ]);

  const isFantomEngine = ledStore.engineChainId === CHAIN_ID_FANTOM_OPERA;

  const gasPriceOnEngine = useGasPrice({
    chainId: ledStore.engineChainId,
    query: {
      // gcTime: 0,
      refetchInterval: 1000 * 5,
      enabled: true,
    },
  });

  const gasPriceOnEnginBn = gasPriceOnEngine.data;
  const gasPriceOnEnginInUnits = unitsBnToFloat(
    gasPriceOnEngine.data ?? 0n,
    GWEI_DECIMALS,
  );
  const isGasTooHigh = isFantomEngine && gasPriceOnEnginInUnits > 200;

  const showOpenPosition = useCallback(
    async (
      openOrderType: TOpenOrderType,
      positionIndex: number,
      isLong: boolean,
      collateralAmount: bigint,
      leverage: number,
      minPrice: number,
      maxPrice: number,
      tpPrice: number,
      slPrice: number,
      tpByFraction: number,
      slByFraction: number,
    ) => {
      if (ledStore && activeTradePairStore) {
        const userTxResponse =
          await ledStore.activeUserStore.requestPositionOpen(
            eoaActionType,
            openOrderType,
            activeTradePairStore.pairId,
            positionIndex,
            isLong,
            collateralAmount,
            leverage,
            minPrice,
            maxPrice,
            tpPrice,
            slPrice,
            tpByFraction,
            slByFraction,
            domainStr,
            referrerStr,
          );

        const positionInformingService = new PositionInformingService(
          ACTION_INFORMING_ENDPOINTS_BY_ENGINE_CHAIN_ID[ledStore.engineChainId],
        );
        const cb: TAsyncCallbackCreator = () => {
          return async () => {
            const expectedPositionHash = calculatePositionHashId(
              ledStore.chipAssetParams.address,
              ledStore.activeUserStore.accountAddress,
              activeTradePairStore.pairId,
              positionIndex,
            );

            await positionInformingService.informAboutMarketOpenRequest(
              expectedPositionHash as string,
            );
          };
        };

        setTxToWaitForAndCallbacks(userTxResponse.txHash, cb);
      }
    },
    [
      activeTradePairStore,
      domainStr,
      eoaActionType,
      ledStore,
      referrerStr,
      setTxToWaitForAndCallbacks,
    ],
  );

  const showCancelUpdatePositionOrder = useCallback(
    (positionId: BytesLike) => {
      ledStore
        ?.direct_cancelUpdatePositionField(positionId)
        .catch((e: Error) => {
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          toastTxError(e);
        });
    },
    [ledStore],
  );

  const showCloseMarket = useCallback(
    (
      positionId: BytesLike,
      resetLoadingState: (positionId: BytesLike) => void,
    ) => {
      // setPairInteractionType("closeMarket");
      ledStore.activeUserStore
        .requestMarketTradeClose(eoaActionType, positionId)
        .then((v) => {
          const positionInformingService = new PositionInformingService(
            ACTION_INFORMING_ENDPOINTS_BY_ENGINE_CHAIN_ID[
              ledStore.engineChainId
            ],
          );
          const cb: TAsyncCallbackCreator = () => {
            return async () => {
              resetLoadingState(positionId);
              await positionInformingService.informAboutMarketCloseRequest(
                positionId as string,
              );
            };
          };

          setTxToWaitForAndCallbacks(v.txHash, cb);
        })
        .catch((e: Error) => {
          resetLoadingState(positionId);
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          toastTxError(e);
        });
    },
    [
      eoaActionType,
      ledStore.activeUserStore,
      ledStore.engineChainId,
      setTxToWaitForAndCallbacks,
    ],
  );

  const showCancelMarketOpen = useCallback(
    (
      positionId: BytesLike,
      resetLoadingState: (positionId: BytesLike) => void,
    ) => {
      ledStore.activeUserStore
        .requestMarketOrderTimeout(positionId, true, () =>
          resetLoadingState(positionId),
        )
        .catch((e: Error) => {
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          // TODO : CRITICAL : Use a unified (metamask error to message)
          const errorMessage =
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (e?.info?.error?.data?.message as string) ?? e.toString();
          resetLoadingState(positionId);
          toastTxError(errorMessage);
        });

      // setPairInteractionType("closeMarket");
      // ledStore
      //   ?.requestCancelPendingMarketOrder(positionId, true)
      //   .catch((e: Error) => {
      //     console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
      //     // TODO : CRITICAL : Use a unified (metamask error to message)
      //     const errorMessage =
      //       // @ts-ignore
      //       (e?.info?.error?.data?.message as string) ?? e.toString();
      //
      //     toastTxError(errorMessage);
      //   });
    },
    [ledStore],
  );

  const showCancelMarketClose = useCallback(
    (
      positionId: BytesLike,
      resetLoadingState: (positionId: BytesLike) => void,
    ) => {
      ledStore.activeUserStore
        .requestMarketOrderTimeout(positionId, false, () =>
          resetLoadingState(positionId),
        )
        .catch((e: Error) => {
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          // TODO : CRITICAL : Use a unified (metamask error to message)
          const errorMessage =
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (e?.info?.error?.data?.message as string) ?? e.toString();
          resetLoadingState(positionId);
          toastTxError(errorMessage);
        });

      // setPairInteractionType("closeMarket");
      // ledStore
      //   ?.requestCancelPendingMarketOrder(positionId, false)
      //   .catch((e: Error) => {
      //     console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
      //     toastTxError(e);
      //   });
    },
    [ledStore],
  );

  const showCancelLimitOpen = useCallback(
    (
      positionId: BytesLike,
      resetLoadingState: (positionId: BytesLike) => void,
    ) => {
      ledStore.activeUserStore
        .requestLimitOrderCancel(eoaActionType, positionId)
        .catch((e: Error) => {
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          // TODO : CRITICAL : Use a unified (metamask error to message)
          const errorMessage =
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (e?.info?.error?.data?.message as string) ?? e.toString();
          resetLoadingState(positionId);
          toastTxError(errorMessage);
        });

      // setPairInteractionType("closeMarket");
      // ledStore
      //   ?.requestCancelPendingMarketOrder(positionId, false)
      //   .catch((e: Error) => {
      //     console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
      //     toastTxError(e);
      //   });
    },
    [eoaActionType, ledStore.activeUserStore],
  );

  const showCancelPendingMarketOrder = useCallback(
    (positionId: BytesLike, isOpenMarketOrder: boolean) => {
      // setPairInteractionType("closeMarket");
      ledStore
        ?.requestCancelPendingMarketOrder(positionId, isOpenMarketOrder)
        .catch((e: Error) => {
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          toastTxError(e);
        });
    },
    [ledStore],
  );

  const showCancelPendingLimitOrder = useCallback(
    (positionId: BytesLike) => {
      // setPairInteractionType("closeMarket");
      ledStore.requestCancelPendingLimitOrder(positionId).catch((e: Error) => {
        console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
        toastTxError(e);
      });
    },
    [ledStore],
  );

  const showUpdateLimits = useCallback(
    (positionId: BytesLike) => {
      modalsStore.showPositionUpdateModal(positionId);
    },
    [modalsStore],
  );

  const showSharePosition = useCallback(
    (positionId: BytesLike) => {
      modalsStore.showPositionShareModal(positionId, true);
    },
    [modalsStore],
  );

  const showExpandPosition = useCallback(
    (positionId: BytesLike) => {
      modalsStore.showPositionInfoModal(positionId, true);
    },
    [modalsStore],
  );

  const borrowRatePerSecondInUnits =
    activeTradePairStore?.ownLexStore.borrowRatePerSecondInUnits ?? 0;

  const openInterestShortInUnits =
    activeTradePairStore?.openInterestShortInUnits ?? 0;
  const openInterestLongInUnits =
    activeTradePairStore?.openInterestLongInUnits ?? 0;

  const shortFundingAprPerSecondInPercentage =
    activeTradePairStore?.fundingAprForShortPerSecondInPercentage ?? 0;
  const longFundingAprPerSecondInPercentage =
    activeTradePairStore?.fundingAprForLongPerSecondInPercentage ?? 0;

  const approveAction = useCallback(
    (token: string, amount: bigint) => {
      if (ledStore && activeTradePairStore) {
        ledStore.approveSettlementAssetUsageForCenter().catch((e: Error) => {
          console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
          toastTxError(e);
        });
      }
    },
    [activeTradePairStore, ledStore],
  );

  const { chainId: walletChainId } = useAccount();
  const isConnectedToProperChain = walletChainId === ledStore?.sourceChainId;

  const requestOpenPosition = useCallback(
    async (
      openOrderType: TOpenOrderType,
      positionIndex: number,
      isLong: boolean,
      collateralAmount: bigint,
      leverage: number,
      minPrice: number,
      maxPrice: number,
      tpPrice: number,
      slPrice: number,
      tpByFraction: number,
      slByFraction: number,
    ) => {
      if (!isConnectedToProperChain) {
        return toastError(
          `Connected to wrong chain ! Connected to ${walletChainId} instead of ${ledStore?.sourceChainId}`,
        );
      }

      await showOpenPosition(
        openOrderType,
        positionIndex,
        isLong,
        collateralAmount,
        leverage,
        minPrice,
        maxPrice,
        tpPrice,
        slPrice,
        tpByFraction,
        slByFraction,
      ).catch((e: Error) => {
        console.log(`CAUGHT ON PAGE ${e.toString()} ${e.stack}`);
        toastTxError(e);
      });
    },
    [
      isConnectedToProperChain,
      ledStore?.sourceChainId,
      showOpenPosition,
      walletChainId,
    ],
  );

  const openPositionPanelComponent = useMemo(() => {
    return (
      <>
        <ConnectedPositionOpenPanel
          isConnectedToWallet={isConnectedToWallet}
          requestWalletConnectionFunction={requestConnectToWallet}
          tradePairStore={activeTradePairStore}
          approveAction={approveAction}
          requestOpenPosition={requestOpenPosition}
        />
        {/*{isGasTooHigh ? (*/}
        {/*  <Stack*/}
        {/*    style={{*/}
        {/*      // backgroundColor: "darkOrange",*/}
        {/*      backgroundColor: THEME_CONSTANTS.panelBgColor,*/}
        {/*      border: "3px solid darkOrange",*/}
        {/*      borderRadius: 40,*/}
        {/*    }}*/}
        {/*    direction={"column"}*/}
        {/*    justifyContent={"center"}*/}
        {/*    alignItems={"center"}*/}
        {/*    flexWrap={"wrap"}*/}
        {/*    textAlign={"center"}*/}
        {/*    padding={3}*/}
        {/*  >*/}
        {/*    <Typography color={"warning"}>Please Notice :</Typography>*/}
        {/*    <Typography color={"warning"}>*/}
        {/*      Current Spike in gas price (*/}
        {/*      {localStringer(gasPriceOnEnginInUnits)}) may prevent opening new*/}
        {/*      positions*/}
        {/*    </Typography>*/}
        {/*  </Stack>*/}
        {/*) : undefined}*/}
      </>
    );
  }, [
    activeTradePairStore,
    approveAction,
    isConnectedToWallet,
    requestConnectToWallet,
    requestOpenPosition,
  ]);

  const leverageElement = useCallback(
    (additionalId?: string) => (
      <LeverageOverviewDisplayPanel
        pairId={activeTradePairStore?.pairId ?? PAIR_CONFIGS_IDS.BTC_USD}
        sufix={additionalId}
      />
    ),
    [activeTradePairStore?.pairId],
  );

  const onTradePairChange = useCallback(
    (val: string) => {
      setQueryPairId(val);
      setActivePairIdValue(val);
    },
    [setQueryPairId],
  );

  const tabs = useMemo(() => ["Chart", "Info"], []);
  const [index, setIndex] = useState(0);

  const refreshLexCb = useCallback(() => {
    console.log(`Refreshing active lex`);
    void ledStore.refreshFromOutside();
  }, [ledStore]);

  useInterval(refreshLexCb, 1000 * 5);

  const memorizedRewardsComponent = useMemo(
    () => (
      <RightSidePanel
        data={rowGists}
        rewardTitle="Total Weekly Rewards"
        totalReward={`${localPriceStringer(Math.floor(totalWeeklyCompetitionRewardsInUnitsValue))}`}
        openDetail={(details) => {
          modalsStore.showLeaderboardModal(details.lexId);
        }}
      />
    ),
    [modalsStore, rowGists, totalWeeklyCompetitionRewardsInUnitsValue],
  );

  const {
    hidePositionUpdateModal,
    hidePositionShareModal,
    hidePositionInfoModal,
    hideLeaderBoardModal,
  } = useMemo(() => {
    return {
      hidePositionUpdateModal: () => modalsStore.hidePositionUpdateModal(),
      hidePositionShareModal: () => modalsStore.hidePositionShareModal(),
      hidePositionInfoModal: () => modalsStore.hidePositionInfoModal(),
      hideLeaderBoardModal: () => modalsStore.hideLeaderboardModal(),
    };
  }, [modalsStore]);

  return (
    <div className={pageClasses.pageRoot}>
      {/* Page content*/}
      <Box
        className={pageClasses.pageContent}
        sx={{
          flexDirection: {
            sm: "row",
          },
          [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse",
          },
        }}
      >
        <Box
          pt={4}
          sx={{
            height: "100%",
            padding: "16px 0px",
            minWidth: "235px",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          {memorizedRewardsComponent}
        </Box>
        {/*  Graph and positions table */}
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: `${PANELS_GAP_REM}rem`,
            overflow: "hidden",
            maxWidth: "100%",
            // TODO : CRITICAL : Fix the need for this patch
            alignSelf: "start",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <Box
            pt={4}
            sx={{
              height: "100%",
              paddingTop: "1rem",
              paddingLeft: 0,
              paddingRight: 0,
              [theme.breakpoints.up("md")]: {
                display: "none",
              },
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            {memorizedRewardsComponent}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: `${PANELS_GAP_REM}rem`,
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            <PairDisplayHeader
              pairSelectOptions={pairSelectOptions}
              pairSelectedValue={activePairIdValue}
              onPairSelect={onTradePairChange}
              openInterestShortInUnits={openInterestShortInUnits}
              openInterestLongInUnits={openInterestLongInUnits}
              borrowRatePerSecondInUnits={borrowRatePerSecondInUnits}
              shortFundingAprPerSecondInPercentage={
                shortFundingAprPerSecondInPercentage
              }
              longFundingAprPerSecondInPercentage={
                longFundingAprPerSecondInPercentage
              }
              hideOnMobile
            />
            {leverageElement("second")}
          </Box>

          <PositionsAndOrdersDisplayPanel
            settlementAssetDecimals={
              activeTradePairStore?.ownLexStore.chipAssetParams.decimals ?? 0
            }
            settlementAssetSymbol={
              activeTradePairStore?.ownLexStore.sourceAssetParameters.symbol ??
              ""
            }
            relevantChainId={
              activeTradePairStore?.ownLexStore.engineChainId ?? -1
            }
            tradePairStore={activeTradePairStore}
            showCloseTrade={showCloseMarket}
            showCancelMarketOpen={showCancelMarketOpen}
            showCancelMarketClose={showCancelMarketClose}
            showCancelLimitOpen={showCancelLimitOpen}
            showCancelUpdatePositionOrder={showCancelUpdatePositionOrder}
            showCancelPendingMarketOrder={showCancelPendingMarketOrder}
            showCancelPendingLimitOrder={showCancelPendingLimitOrder}
            showUpdateLimits={showUpdateLimits}
            showSharePosition={showSharePosition}
            showExpandPosition={showExpandPosition}
          />
        </Box>

        {/* Interaction Panel */}
        <Box
          sx={{
            //flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // Note : fixes a problem where the panel container was not expanding to the whole width on smaller devices
            width: {
              xs: "100%",
            },
            [theme.breakpoints.up("sm")]: {
              width: "100%",
              display: "block",
            },
            [theme.breakpoints.up("md")]: {
              width: "340px",
            },
          }}
          alignSelf={"start"}
        >
          {openPositionPanelComponent}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${PANELS_GAP_REM}rem`,
            width: "100%",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          }}
        >
          <ToggleTabBar
            tabBarOptions={tabs}
            currentIndex={index}
            onChange={setIndex}
          >
            {leverageElement()}
            <PairDisplayHeader
              pairSelectOptions={pairSelectOptions}
              pairSelectedValue={activePairIdValue}
              onPairSelect={onTradePairChange}
              openInterestShortInUnits={openInterestShortInUnits}
              openInterestLongInUnits={openInterestLongInUnits}
              borrowRatePerSecondInUnits={borrowRatePerSecondInUnits}
              shortFundingAprPerSecondInPercentage={
                shortFundingAprPerSecondInPercentage
              }
              longFundingAprPerSecondInPercentage={
                longFundingAprPerSecondInPercentage
              }
              hideOnMobile
            />
          </ToggleTabBar>
        </Box>
        <Box
          sx={{
            display: "none",
            [theme.breakpoints.down("md")]: {
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              marginTop: "20px",
              marginBottom: "15px",
            },
          }}
        >
          {memorizedRewardsComponent}
          <TradePairSelection
            value={activePairIdValue}
            options={pairSelectOptions}
            rememberSelected
            onChange={onTradePairChange}
          />
        </Box>
      </Box>

      {/* MODALS */}
      <ConnectedPositionUpdateModal
        closeModal={hidePositionUpdateModal}
        open={modalsStore.isPositionUpdateModalActive}
      />
      <PositionShareModalWrapper
        closeModal={hidePositionShareModal}
        open={modalsStore.isPositionShareModalActive}
      />
      <PositionInfoModalWrapper
        closeModal={hidePositionInfoModal}
        open={modalsStore.isPositionInfoModalActive}
      />
    </div>
  );
});
