import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { THEME_CONSTANTS } from "../../../../../theme/ThemeConstants.ts";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButtonLink from "./IconButtonLink.tsx";
import { TokenIcon } from "../../../../../components/buildingParts/icons/TokenIcon.tsx";
import { infoLineTextVariant } from "./boxInfostyling.ts";

interface IProps {
  title: string;
  text: string;
  externalLink?: string;

  tokenSymbolBeforeText?: string;
  colorOverrideForText?: string;
}

export const BoxInfoLineText = React.memo<IProps>((props) => {
  const {
    title,
    text,
    externalLink,
    tokenSymbolBeforeText,
    colorOverrideForText,
  } = props;

  return (
    <Box
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Box flex={1}>
        <Typography
          variant={infoLineTextVariant}
          sx={{ color: THEME_CONSTANTS.titleGray }}
        >
          {title}
        </Typography>
      </Box>
      <Box flex={1} flexDirection={"row"} display={"flex"} gap={1}>
        {tokenSymbolBeforeText && (
          <TokenIcon symbol={tokenSymbolBeforeText} iconSizeRem={1.3} />
        )}
        <Typography
          variant={infoLineTextVariant}
          style={{
            color: colorOverrideForText ?? "inherit",
          }}
        >
          {text}
        </Typography>
        {externalLink && <IconButtonLink externalLink={externalLink} />}
      </Box>
    </Box>
  );
});

export default BoxInfoLineText;
