import { THEME_STATE_CONSTANTS } from "../theme/ThemeConstants.ts";

function getTextColorByValue(value: number): string {
  if (value === 0) {
    return "inherit";
  } else {
    return value > 0
      ? THEME_STATE_CONSTANTS.positivePnL
      : THEME_STATE_CONSTANTS.negativePnL;
  }
}

export const themeCalculations = {
  getTextColorByValue,
};
