import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TCompletePositionDataFromLens } from "../../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import {
  OpenPositionLimitUpdateStateEnums,
  OpenPositionsTable,
  PositionTableRowLifecycleStateEnums,
} from "lynx-ui-components";

import type {
  TOpenPositionLimitUpdateState,
  TOpenPositionsTableRowGist,
  TPositionTableRowBaseData,
} from "lynx-ui-components";

import { buildPositionTableBaseRowDataFromLivePosition } from "./tableWrappersUtils.ts";
import { priceBnToFloat } from "../../../../utils/lynxScalesUtils.ts";
import { BytesLike } from "ethers";
import { TPairIds } from "../../../../constants/pairsConstants.ts";
import { unitsBnToFloat } from "../../../../utils/bignumbers.ts";
import { scaledLeverageToUnits } from "../../../../utils/leverageCalculationsUtils.ts";
import { useModalsStore } from "../../../../store/storeHooks.ts";
import { calculatePnlInfo } from "../../../../utils/pnlCalculations.ts";
import { PRICE_UX_FOR_PAIRS } from "../../../../ux/assetsUxPrecisions.ts";
import {
  PositionPhaseEnums,
  UpdatePositionFieldOrderTypeEnums,
} from "../../../../constants/contractEnums.ts";

interface IProps {
  isConnectedToWallet: boolean;
  requestWalletConnection: () => void;
  liveOpenedPositions: TCompletePositionDataFromLens[];
  // TODO : Support multiple
  underlyingDecimals: number;
  underlyingSymbol: string;

  getPriceInUnitsForPair: (pairId: TPairIds) => number;
  getCloseFeeFInUnitsForPair: (pairId: TPairIds) => number;

  showCloseTrade: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showCancelMarketOpen: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showCancelMarketClose: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showCancelLimitOpen: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showUpdateLimits: (positionId: BytesLike) => void;

  showExpandPosition: (positionId: BytesLike) => void;
  showSharePosition: (positionId: BytesLike) => void;
}

export const OpenPositionsTableWrapper = React.memo<IProps>((props) => {
  const {
    isConnectedToWallet,
    requestWalletConnection,
    liveOpenedPositions,
    underlyingDecimals,
    underlyingSymbol,
    showCloseTrade,
    showCancelMarketOpen,
    showCancelMarketClose,
    showCancelLimitOpen,
    showUpdateLimits,
    showExpandPosition,
    showSharePosition,
    getPriceInUnitsForPair,
    getCloseFeeFInUnitsForPair,
  } = props;

  const modalsStore = useModalsStore();
  const [unsuccessRequests, setUnsuccessRequest] = useState<
    Record<string, number>
  >({});
  const [inProgressStates, setInProgressStates] = useState<
    Record<string, boolean>
  >({});

  const rawAmountToUnits = useCallback(
    (rawAmount: bigint) => {
      return unitsBnToFloat(rawAmount, underlyingDecimals);
    },
    [underlyingDecimals],
  );

  const dataInRowsForm = useMemo<TOpenPositionsTableRowGist[]>(() => {
    return liveOpenedPositions
      .sort((a, b) => Number(b.inPhaseSince - a.inPhaseSince))
      .map((livePosition) => {
        const tableRowBaseData: TPositionTableRowBaseData =
          buildPositionTableBaseRowDataFromLivePosition(
            livePosition,
            underlyingDecimals,
            underlyingSymbol,
          );

        const pairId = Number(livePosition.pairId) as TPairIds;

        const closeFeeFInUnits = getCloseFeeFInUnitsForPair(pairId);
        const leverageInUnits = scaledLeverageToUnits(
          Number(livePosition.leverage),
        );

        const collateralInUnits = rawAmountToUnits(livePosition.collateral);
        const leveragedPositionSize = collateralInUnits * leverageInUnits;
        const expectedClosingFeeInUnits =
          leveragedPositionSize * closeFeeFInUnits;

        const pairPriceInUnits = getPriceInUnitsForPair(pairId);
        const pnlInfo = calculatePnlInfo(
          collateralInUnits,
          leverageInUnits,
          priceBnToFloat(livePosition.openPrice),
          pairPriceInUnits,
          livePosition.long,
          rawAmountToUnits(livePosition.borrowInterest),
          rawAmountToUnits(livePosition.funding),
          // NOTE : Displaying pnl without the closing fee
          0,
        );

        // Note : this is a patch for an issue where the app price can be 0 (due to connectivity issues)
        //        and this prevents the display of 100% lost (Will just show 0)
        const pnlInUnits = pairPriceInUnits > 0 ? pnlInfo.netPnl : 0;
        const roiInUnits = pairPriceInUnits > 0 ? pnlInfo.roiInUnit : 0;

        let limitUpdatingState: TOpenPositionLimitUpdateState =
          OpenPositionLimitUpdateStateEnums.NONE;
        let tpLimitUpdatePriceInUnits = 0;
        let slLimitUpdatePriceInUnits = 0;

        if (
          livePosition.pendingUpdateOrderType ===
          UpdatePositionFieldOrderTypeEnums.UPDATE_TP
        ) {
          limitUpdatingState = OpenPositionLimitUpdateStateEnums.UPDATING_TP;
          tpLimitUpdatePriceInUnits = priceBnToFloat(
            livePosition.pendingUpdateOrderFieldAValue,
          );
        } else if (
          livePosition.pendingUpdateOrderType ===
          UpdatePositionFieldOrderTypeEnums.UPDATE_SL
        ) {
          limitUpdatingState = OpenPositionLimitUpdateStateEnums.UPDATING_SL;
          slLimitUpdatePriceInUnits = priceBnToFloat(
            livePosition.pendingUpdateOrderFieldAValue,
          );
        } else if (
          livePosition.pendingUpdateOrderType ===
          UpdatePositionFieldOrderTypeEnums.UPDATE_TP_AND_SL
        ) {
          limitUpdatingState =
            OpenPositionLimitUpdateStateEnums.UPDATING_TP_AND_SL;
          tpLimitUpdatePriceInUnits = priceBnToFloat(
            livePosition.pendingUpdateOrderFieldAValue,
          );
          slLimitUpdatePriceInUnits = priceBnToFloat(
            livePosition.pendingUpdateOrderFieldBValue,
          );
        }

        // NOTE : For limit orders, the min and max should be equal
        const minEntryPrice = priceBnToFloat(livePosition.minPrice);
        const maxEntryPrice = priceBnToFloat(livePosition.maxPrice);
        const entryPriceForLimitOrder =
          pairPriceInUnits > maxEntryPrice ? maxEntryPrice : minEntryPrice;

        const openPositionTableRow: TOpenPositionsTableRowGist = {
          ...tableRowBaseData,

          openPrice:
            livePosition.phase == PositionPhaseEnums.OPEN_LIMIT
              ? entryPriceForLimitOrder
              : priceBnToFloat(livePosition.openPrice),
          pnlInUnits: pnlInUnits,
          roiInUnits: roiInUnits,

          limitUpdatingState: limitUpdatingState,
          tpLimitUpdatePrice: tpLimitUpdatePriceInUnits,
          slLimitUpdatePrice: slLimitUpdatePriceInUnits,

          // UX
          priceDisplayDecimals:
            PRICE_UX_FOR_PAIRS[Number(livePosition.pairId) as TPairIds],
        };

        return openPositionTableRow;
      });
  }, [
    getCloseFeeFInUnitsForPair,
    getPriceInUnitsForPair,
    liveOpenedPositions,
    rawAmountToUnits,
    underlyingDecimals,
    underlyingSymbol,
  ]);

  const emptyFunc = () => undefined;

  const onSetInProgressValues = useCallback((key: string, value: boolean) => {
    setInProgressStates((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  // useEffect(() => {
  //   const newUnsuccessRequests = dataInRowsForm
  //     .filter(
  //       (item) =>
  //         item.lifecycleState ===
  //           PositionTableRowLifecycleStateEnums.MARKET_OPEN_TIMEOUT ||
  //         item.lifecycleState ===
  //           PositionTableRowLifecycleStateEnums.MARKET_CLOSE_TIMEOUT,
  //     )
  //     .reduce(
  //       (acc, el) => {
  //         acc[el.livePositionId] = el.lifecycleState;
  //         return acc;
  //       },
  //       {} as Record<string, number>,
  //     );
  //
  //   setUnsuccessRequest((prevRequests) => ({
  //     ...prevRequests,
  //     ...newUnsuccessRequests,
  //   }));
  // }, [dataInRowsForm]);
  //
  // useEffect(() => {
  //   if (unsuccessRequests) {
  //     const unsuccessRequestsData = Object.entries(unsuccessRequests);
  //     unsuccessRequestsData.forEach(([key, value]) => {
  //       if (inProgressStates[key] === undefined) {
  //         if (
  //           value === PositionTableRowLifecycleStateEnums.MARKET_OPEN_TIMEOUT
  //         ) {
  //           showCancelMarketOpen(key, (posid) => {
  //             setInProgressStates((prevState) => ({
  //               ...prevState,
  //               [posid as string]: false,
  //             }));
  //           });
  //         } else if (
  //           value === PositionTableRowLifecycleStateEnums.MARKET_CLOSE_TIMEOUT
  //         ) {
  //           showCancelMarketClose(key, (posid) => {
  //             setInProgressStates((prevState) => ({
  //               ...prevState,
  //               [posid as string]: false,
  //             }));
  //           });
  //         }
  //         setInProgressStates((prevState) => ({ ...prevState, [key]: true }));
  //       }
  //     });
  //   }
  // }, [
  //   inProgressStates,
  //   showCancelMarketClose,
  //   showCancelMarketOpen,
  //   unsuccessRequests,
  // ]);

  return (
    <OpenPositionsTable
      isWalletConnected={isConnectedToWallet}
      requestWalletConnection={requestWalletConnection}
      rowsData={dataInRowsForm}
      inProgressStates={inProgressStates}
      onSetInProgressStates={onSetInProgressValues}
      showCloseTrade={showCloseTrade}
      showCancelMarketOpen={showCancelMarketOpen}
      showCancelMarketClose={showCancelMarketClose}
      showCancelUpdatePositionOrder={emptyFunc}
      showUpdateLimits={showUpdateLimits}
      showExpandPosition={showExpandPosition}
      showSharePosition={showSharePosition}
      showCancelLimitOpen={showCancelLimitOpen}
    />
  );
});
