import {
  IIncentivesService,
  INCENTIVE_PLAN_TYPE_KEYS,
  TCompetitionPlanIncentiveDescription,
  TCompetitionRoundTraderInfo,
  TIncentivesPlanDescription,
} from "./IIncentivesService.ts";
import { fetchRemoteJson } from "../../web2NetworkActions.ts";

export class IncentivesService implements IIncentivesService {
  constructor(
    private _liveIncentivePlansUrl: string,
    private _pastIncentivePlansUrl: string,
    private _pastCompetitionResultsUrl: string,
    private _liveCompetitionResultsUrl: string,
  ) {}

  async getAllLiveIncentivePlans(): Promise<{
    competitions: TCompetitionPlanIncentiveDescription[];
  }> {
    const allPlans = await fetchRemoteJson<TIncentivesPlanDescription[]>(
      this._liveIncentivePlansUrl,
    );

    const competitionPlans = allPlans.filter(
      (plan) => plan.planCode === INCENTIVE_PLAN_TYPE_KEYS.topComp,
    ) as TCompetitionPlanIncentiveDescription[];

    return {
      competitions: competitionPlans,
    };
  }

  async getAllPastIncentivePlans(): Promise<{
    competitions: TCompetitionPlanIncentiveDescription[];
  }> {
    const allPlans = await fetchRemoteJson<TIncentivesPlanDescription[]>(
      this._pastIncentivePlansUrl,
    );

    const competitionPlans = allPlans.filter(
      (plan) => plan.planCode === INCENTIVE_PLAN_TYPE_KEYS.topComp,
    ) as TCompetitionPlanIncentiveDescription[];

    return {
      competitions: competitionPlans,
    };
  }

  async getPastCompetitionResults(
    lexAddress: string,
    roundNumber: number,
  ): Promise<TCompetitionRoundTraderInfo[]> {
    const roundTraderInfos = await fetchRemoteJson<
      TCompetitionRoundTraderInfo[]
    >(`${this._pastCompetitionResultsUrl}/${lexAddress}/${roundNumber}`);

    return roundTraderInfos.map((raw) =>
      this.convertRawTraderInfoToTraderInfo(raw),
    );
  }

  async getLiveCompetitionTradersInfo(
    lexAddress: string,
    roundNumber: number,
  ): Promise<TCompetitionRoundTraderInfo[]> {
    const roundTraderInfos = await fetchRemoteJson<
      TCompetitionRoundTraderInfo[]
    >(`${this._liveCompetitionResultsUrl}/${lexAddress}/${roundNumber}`);

    return roundTraderInfos.map((raw) =>
      this.convertRawTraderInfoToTraderInfo(raw),
    );
  }

  private convertRawTraderInfoToTraderInfo(
    raw: TCompetitionRoundTraderInfo,
  ): TCompetitionRoundTraderInfo {
    const properTraderInfo: TCompetitionRoundTraderInfo = {
      traderAddress: raw.traderAddress,
      positionsCount: raw.positionsCount ? Number(raw.positionsCount) : 0,
      rank: Number(raw.rank),
      totalCloseFeesPaid: BigInt(raw.totalCloseFeesPaid),
      totalNegativePnl: BigInt(raw.totalNegativePnl),
      totalOpenFeesPaid: BigInt(raw.totalOpenFeesPaid),
      totalPositivePnl: BigInt(raw.totalPositivePnl),
      totalPnl: BigInt(raw.totalPnl),
      distributed: raw.distributed ?? [],
    };

    return properTraderInfo;
  }
}
