import React from "react";
import { makeStyles } from "tss-react/mui";
import { TEXT_ON_DARK } from "../../../theme/GeneralThemeConstants";
import { KEYFRAMES } from "../../../theme/keyframes";
import { Typography } from "@mui/material";
import {
  THEME_CONSTANTS,
  THEME_GRADIENTS,
} from "../../../theme/ThemeConstants";

interface IProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles()((theme) => ({
  btn: {
    color: TEXT_ON_DARK,
    width: "100%",

    cursor: "pointer",
    userSelect: "none",

    transition: " all 0.4s ease",
    animation: `${KEYFRAMES.appear} 0.8s ease`,

    height: "3rem",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnActive: {
    "&:hover": {
      backgroundColor: THEME_CONSTANTS.activeGrayBg,
      boxShadow: `0 0 4px 3px #8247E5`,
    },

    border: "1px solid",
    borderImageSlice: 1,
    borderImageSource: `linear-gradient(to bottom, ${THEME_GRADIENTS.main.start}, ${THEME_GRADIENTS.main.middle}, ${THEME_GRADIENTS.main.end})`,
  },

  btnDisabled: {
    // "&:hover": {
    //   backgroundColor: THEME_CONSTANTS.activeGrayBg,
    //   boxShadow: `0 0 4px 3px #8247E5`,
    // },

    backgroundColor: THEME_CONSTANTS.activeGrayBg,
    color: THEME_CONSTANTS.titleGray,
  },
}));

export const PanelActionButton = React.memo<
  IProps & React.HTMLAttributes<HTMLDivElement>
>((props) => {
  const { text, disabled, onClick, className: outerClassName, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx([
        classes.btn,
        disabled ? classes.btnDisabled : classes.btnActive,
        outerClassName,
      ])}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <Typography fontWeight={"bold"}>{text}</Typography>
    </div>
  );
});
