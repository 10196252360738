import { EMPTY_WL_PARAMS, WHITE_LABEL_PARMAS } from "./whiteLabelConstants.ts";
import { TWhiteLabelParams } from "./whiteLabelTypes.ts";

const wlRes = detectWhiteLabel(window.location.hostname);
const IS_ON_WHITE_LABEL = wlRes.isOnWhiteLabel;
const WHITE_LABEL_PARAMS = wlRes.wlParams;
const WHITE_LABEL_NAME = wlRes.wlName;

export const WHITE_LABEL_CONFIGS = {
  isOnWhiteLabel: IS_ON_WHITE_LABEL,
  whiteLabelParams: WHITE_LABEL_PARAMS,
  whiteLabelName: WHITE_LABEL_NAME,
};

function detectWhiteLabel(hostname: string): {
  isOnWhiteLabel: boolean;
  wlParams: TWhiteLabelParams;
  wlName: string;
} {
  let isOnWhiteLabel = false;
  let params: TWhiteLabelParams = EMPTY_WL_PARAMS;
  let whiteLabelName = "";

  const entries = Object.entries(WHITE_LABEL_PARMAS);
  for (const [wlName, wlParams] of entries) {
    if (hostname === wlParams.hostName) {
      whiteLabelName = wlName;
      isOnWhiteLabel = true;
      params = wlParams;
      break;
    }
  }

  return { isOnWhiteLabel, wlParams: params, wlName: whiteLabelName };
}
