import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { THEME_CONSTANTS } from "../../../../theme/ThemeConstants.ts";

interface IProps {
  children: ReactNode;
}

export const CompetitionInfoBox = React.memo<IProps>((props) => {
  const { children } = props;
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      gap={0.5}
      sx={{
        backgroundColor: THEME_CONSTANTS.panelBgColor,
        borderRadius: "40px",
        padding: "1rem",
        minHeight: "10rem",
      }}
    >
      {children}
    </Box>
  );
});

export default CompetitionInfoBox;
