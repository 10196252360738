import { THEME_CONSTANTS } from "../theme/ThemeConstants.ts";

export type TAssetUxColorsInfo = {
  mainColor: string;
  secondaryColor: string;
  activeShadowColor: string;
};

// asset symbol => uxColorInfo
export const UX_COLORS_FOR_SA: Record<string, TAssetUxColorsInfo> = {
  PDAI: {
    mainColor: "#fcac34",
    secondaryColor: "#fcd49c",
    activeShadowColor: "#fceccc",
  },
  USDC: {
    mainColor: "#2874cc",
    secondaryColor: "#ccdcf4",
    activeShadowColor: "#94bce4",
  },
  USDT: {
    mainColor: "#7cc4b4",
    secondaryColor: "#d4ece4",
    activeShadowColor: "#54ac94",
  },

  WFUSE: {
    mainColor: "#84bc8c",
    secondaryColor: "#14442c",
    activeShadowColor: "#b4fcbc",
  },
  MST: {
    mainColor: "#133561",
    secondaryColor: "#839dca",
    activeShadowColor: "#aec3ea",
  },
  sFUSE: {
    mainColor: "#73e204",
    secondaryColor: "#040404",
    activeShadowColor: "#316204",
  },
  VOLT: {
    mainColor: "#041c24",
    secondaryColor: "#5cba06",
    activeShadowColor: "#44900c",
  },

  LVC: {
    mainColor: "#60d2f1",
    secondaryColor: "#3c3c3c",
    activeShadowColor: "#60d2f1",
  },
  veLVC: {
    mainColor: "#64dcfc",
    secondaryColor: "#3c646c",
    activeShadowColor: "#5cd4f4",
  },

  stEUR: {
    mainColor: "#87acfb",
    secondaryColor: "#e8e3e5",
    activeShadowColor: "#c3d4ee",
  },
  TST: {
    mainColor: "#2c2c2d",
    secondaryColor: "#5c5b5c",
    activeShadowColor: "#a6a5a6",
  },
  EUROs: {
    mainColor: "#98a4cc",
    secondaryColor: "#35499a",
    activeShadowColor: "#f5cf24",
  },
  GRAI: {
    mainColor: "#f1d8fc",
    secondaryColor: "#a404fa",
    activeShadowColor: "#c768fc",
  },
  SLIZ: {
    mainColor: "#e3e7a1",
    secondaryColor: "#3a6064",
    activeShadowColor: "#e3e7a1",
  },
  SCALES: {
    mainColor: "#ae4929",
    secondaryColor: "#dda951",
    activeShadowColor: "#5fcad1",
  },
  USDFI: {
    mainColor: "#40f029",
    secondaryColor: "#040404",
    activeShadowColor: "#4c7504",
  },

  SONNE: {
    mainColor: "#fc6b4c",
    secondaryColor: "#fcb4a5",
    activeShadowColor: "#fc9a89",
  },

  WMATIC: {
    mainColor: "#2d7cf4",
    secondaryColor: "#fff",
    activeShadowColor: "#2494fb",
  },
  miMATIC: {
    mainColor: "#dc3434",
    secondaryColor: "#de949c",
    activeShadowColor: "#d77f82",
  },
  MAI: {
    mainColor: "#dc3434",
    secondaryColor: "#de949c",
    activeShadowColor: "#d77f82",
  },

  aUSD: {
    mainColor: "#0f8b43",
    secondaryColor: "#1c1f1a",
    activeShadowColor: "#1daa67",
  },

  lisUSD: {
    mainColor: "#34349b",
    secondaryColor: "#8684c2",
    activeShadowColor: "#34349b",
  },

  MODE: {
    mainColor: "#dcfc04",
    secondaryColor: "#040404",
    activeShadowColor: "#e4fc04",
  },
  ION: {
    mainColor: "#1c8444",
    secondaryColor: "#040404",
    activeShadowColor: "#3bfb8b",
  },

  CELO: {
    mainColor: "#fcfc54",
    secondaryColor: "#040404",
    activeShadowColor: "#e0ff2c",
  },

  FTM: {
    mainColor: "#14b4ec",
    secondaryColor: "#c4ecfc",
    activeShadowColor: "#8cdcf4",
  },
  EQUAL: {
    mainColor: "#04172e",
    secondaryColor: "#046bcb",
    activeShadowColor: "#16c0e0",
  },
  BRUSH: {
    mainColor: "#00beff",
    secondaryColor: "#20324C",
    activeShadowColor: "#abb7e2",
  },
  fSONIC: {
    mainColor: "#19649d",
    secondaryColor: "#84cbf5",
    activeShadowColor: "#c8ccb5",
  },
  POLTER: {
    mainColor: "#040404",
    secondaryColor: "#c4c5c5",
    activeShadowColor: "#5d5e5e",
  },
  fBUX: {
    mainColor: "#141414",
    secondaryColor: "#49a978",
    activeShadowColor: "#d0efe2",
  },
  FTAILS: {
    mainColor: "#231712",
    secondaryColor: "#e6970e",
    activeShadowColor: "#f8d56f",
  },
  SPIRIT: {
    mainColor: "#31c69f",
    secondaryColor: "#304951",
    activeShadowColor: "#58e4bc",
  },

  BOBA: {
    mainColor: "#ccef4c",
    secondaryColor: "#040404",
    activeShadowColor: "#22d6cf",
  },
};

const defaultUxColors: TAssetUxColorsInfo = {
  mainColor: THEME_CONSTANTS.brandPurple,
  secondaryColor: THEME_CONSTANTS.infoTextWhite,
  activeShadowColor: THEME_CONSTANTS.brandLightPurple,
};

export function getUxColorsForAsset(assetSymbol: string): TAssetUxColorsInfo {
  const uxColors = UX_COLORS_FOR_SA[assetSymbol];

  if (uxColors) {
    return uxColors;
  } else {
    return defaultUxColors;
  }
}
