import type { TPairIds } from "../../constants/pairsConstants";

import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import { PAIR_CONFIGS_IDS } from "../../constants/pairsConstants";
import {
  IGekoTerminalPrice,
  priceFetching,
} from "../../services/servicesIntergration/priceFetching/priceFetching.ts";
import { IS_ON_PLAYGROUND } from "../../configs.ts";
import { makePersistable } from "mobx-persist-store";
import { PriceServiceConnection } from "@pythnetwork/price-service-client";
import { priceBnToFloat } from "../../utils/lynxScalesUtils.ts";
import {
  FANTOM_ASSETS,
  FUSE_MAINNET_ASSETS,
  MANTLE_ASSETS,
} from "../../constants/chainAssets.ts";
import { timeUtils } from "../../utils/timeUtils.ts";
import { PAIR_ID_TO_NAME } from "../../constants/pairsDisplayConstants.ts";
import { persistenceUtils } from "../../utils/persistenceUtils.ts";
import { unitsBnToFloat } from "../../utils/bignumbers.ts";

// const PYTHNET_CLUSTER_NAME: PythCluster = "pythnet";
// const connection = new SolanaConnection(
//   getPythClusterApiUrl(PYTHNET_CLUSTER_NAME),
// );
// const pythPublicKey = getPythProgramKeyForCluster(PYTHNET_CLUSTER_NAME);

export class PricesStore {
  @observable isLoading = false;

  @observable pricesMap: ObservableMap<TPairIds, number> = new ObservableMap<
    TPairIds,
    number
  >();

  @observable symbolToPriceMap: ObservableMap<string, number> =
    new ObservableMap<string, number>();

  @observable symbolToPriceMapLastUpdateTimestamp = 0;

  @observable lastPythFeedUpdateTimestamp = 0;

  getPriceForPairId(pairId: TPairIds): number {
    return this.pricesMap.get(pairId) ?? 0;
  }

  @computed
  public get isThereAnySymbolPriceZero(): boolean {
    return [...this.symbolToPriceMap.values()].some((price) => price === 0);
  }

  constructor() {
    makeObservable(this);

    void makePersistable(this, {
      name: `PricesStore`,
      properties: [
        {
          key: "symbolToPriceMap",
          ...persistenceUtils.buildObservableMapSerializationFunctions(),
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 5 * 1000, // 5 hours
      removeOnExpiration: true,
    });

    this.initializeStore().catch((e) =>
      console.error(`Failed initializing PricesStore ${e}`),
    );
  }

  async initializeStore() {
    this.setLoadingState(true);

    // Get params for all registered LeDs
    // await this.readAndUpdateAllPairPrices();

    // Get params for all registered assets by symbol
    await this.readAndUpdateAllPricesBySymbol();

    await this.attachToPriceUpdatesForPairs();

    this.setLoadingState(false);
  }

  async refreshPrices() {
    // this.readAndUpdateAllPairPrices().catch((e) =>
    //   console.error(`Failed reading prices from pythnet ${e}`),
    // );

    const currentTimestamp = Math.floor(Date.now() / 1000);

    const pricesBySymbolRefreshRateInSeconds = 60;

    if (
      currentTimestamp >
        this.symbolToPriceMapLastUpdateTimestamp +
          pricesBySymbolRefreshRateInSeconds ||
      this.isThereAnySymbolPriceZero
    ) {
      await this.readAndUpdateAllPricesBySymbol();
    }
  }

  private async attachToPriceUpdatesForPairs() {
    const connection = new PriceServiceConnection(
      "https://hermes.pyth.network",
      {
        priceFeedRequestConfig: {
          // Provide this option to retrieve signed price updates for on-chain contracts.
          // Ignore this option for off-chain use.
          binary: false,
        },
      },
    ); // See Hermes endpoints section below for other endpoints

    // You can find the ids of prices at https://pyth.network/developers/price-feed-ids
    const pythPairsInfo = [
      {
        pairId: PAIR_CONFIGS_IDS.BTC_USD,
        priceId:
          "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      },
      {
        pairId: PAIR_CONFIGS_IDS.ETH_USD,
        priceId:
          "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      },
      {
        pairId: PAIR_CONFIGS_IDS.BNB_USD,
        priceId:
          "2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      },
      {
        pairId: PAIR_CONFIGS_IDS.FTM_USD,
        priceId:
          "5c6c0d2386e3352356c3ab84434fafb5ea067ac2678a38a338c4a69ddc4bdb0c",
      },
      {
        pairId: PAIR_CONFIGS_IDS.SOL_USD,
        priceId:
          "ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
      },

      // FOREX
      {
        pairId: PAIR_CONFIGS_IDS.EUR_USD,
        priceId:
          "a995d00bb36a63cef7fd2c287dc105fc8f3d93779f062f09551b0af3e81ec30b",
      },
      {
        pairId: PAIR_CONFIGS_IDS.GBP_USD,
        priceId:
          "84c2dde9633d93d1bcad84e7dc41c9d56578b7ec52fabedc1f335d673df0a7c1",
      },
      {
        pairId: PAIR_CONFIGS_IDS.AUD_USD,
        priceId:
          "67a6f93030420c1c9e3fe37c1ab6b77966af82f995944a9fefce357a22854a80",
      },
      {
        pairId: PAIR_CONFIGS_IDS.NZD_USD,
        priceId:
          "92eea8ba1b00078cdc2ef6f64f091f262e8c7d0576ee4677572f314ebfafa4c7",
      },

      // CAT Mode
      {
        pairId: PAIR_CONFIGS_IDS.BTC_USD_CAT,
        priceId:
          "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      },
      {
        pairId: PAIR_CONFIGS_IDS.ETH_USD_CAT,
        priceId:
          "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      },
    ];

    const priceIds = pythPairsInfo.map(
      (pythPairInfo) => `0x${pythPairInfo.priceId}`,
    );
    // [
    // "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43", // BTC/USD price id
    // "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace", // ETH/USD price id
    // ];

    try {
      await connection.subscribePriceFeedUpdates(priceIds, (priceFeed) => {
        // priceFeed here is the same as returned by getLatestPriceFeeds above.
        // It will include signed price updates if the binary option was provided to the connection constructor.
        // console.log(
        //   `%%%%%%%%% Received update for ${priceFeed.id}: ${stringifyObject(priceFeed.getPriceNoOlderThan(60), 3)}`,
        // );
        //
        // console.log(
        //   `%%%%%%%%% getPriceUnchecked :: ${priceFeed.getPriceUnchecked().getPriceAsNumberUnchecked()}`,
        // );
        // console.log(
        //   `%%%%%%%%% getEmaPriceUnchecked :: ${priceFeed.getEmaPriceUnchecked().getPriceAsNumberUnchecked()}`,
        // );

        const matchingPairPriceInfos = pythPairsInfo.filter(
          (pairPriceInfo) => pairPriceInfo.priceId === priceFeed.id,
        );

        for (const matchingPairPriceInfo of matchingPairPriceInfos) {
          if (matchingPairPriceInfo) {
            const pairName = PAIR_ID_TO_NAME[matchingPairPriceInfo.pairId];

            const acceptableTimeframesForPrices = [
              2, 5, 10,
              // 30, 60, 120, 240
            ];

            const expo = Math.abs(priceFeed.getPriceUnchecked().expo);

            let priceInUnits = 0;
            for (const timeframe of acceptableTimeframesForPrices) {
              priceInUnits = unitsBnToFloat(
                BigInt(priceFeed.getPriceNoOlderThan(timeframe)?.price ?? "0"),
                expo,
              );

              if (priceInUnits === 0) {
                console.warn(
                  `Received price update for ${priceFeed.id} (${pairName}) with price 0 in timeframe ${timeframe}`,
                );
              } else {
                break;
              }
            }

            if (priceInUnits > 0) {
              this.setPriceForPair(matchingPairPriceInfo.pairId, priceInUnits);
            } else {
              console.warn(`No price found for ${priceFeed.id} (${pairName})`);
            }
          } else {
            console.warn(
              `Received price update for unknown price id ${priceFeed.id}`,
            );
          }
        }
      });
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Failed subscribing to price updates ${e}`);
    }
  }

  // NOTE : Currently replaced by `attachToPriceUpdatesForPairs`
  // private async readAndUpdateAllPairPrices() {
  //   // const pythClient: PythHttpClient = new PythHttpClient(
  //   //   connection,
  //   //   pythPublicKey,
  //   // ) as PythHttpClient;
  //   // const data = await pythClient.getData();
  //   //
  //   // for (const priceConfigGist of PYTH_PRICE_ENTRIES) {
  //   //   const [pairId, pythKey] = priceConfigGist;
  //   //
  //   //   // await waitMs(1 * 1000);
  //   //
  //   //   const matchingPricedata = data.productPrice.get(pythKey);
  //   //
  //   //   // TODO : Check for confidence as well ?
  //   //   if (matchingPricedata && matchingPricedata.price) {
  //   //     const price = matchingPricedata.price;
  //   //     this.setPriceForPair(pairId, price);
  //   //   }
  //   // }
  // }

  private async readAndUpdateAllPricesBySymbol() {
    const symbolsForGekoTerminal = [
      "MANTLE",
      "FTM",
      // "FUSE",
      "FUSE",
    ];

    const tokenAddressesForNetworks = [
      MANTLE_ASSETS.aUSD,
      FANTOM_ASSETS.FTAILS,
      // FUSE_MAINNET_ASSETS.VOLT,
      FUSE_MAINNET_ASSETS.SFUSE,
    ];

    try {
      const fetchPromises = tokenAddressesForNetworks.map((token, index) => {
        return priceFetching.fetchPricesFromGekoterminall(
          symbolsForGekoTerminal[index].toLowerCase(),
          token.address,
        );
      });

      const pricesArray: (IGekoTerminalPrice | undefined)[] =
        await Promise.all(fetchPromises);

      pricesArray.forEach((priceData, index) => {
        const symbol = tokenAddressesForNetworks[index].symbol;
        const tokenAddress =
          tokenAddressesForNetworks[index].address.toLowerCase();
        const tokenPrice =
          priceData?.attributes?.token_prices[tokenAddress] ?? "0";
        this.setPriceForSymbol(symbol, Number(tokenPrice));
      });
    } catch (error) {
      console.error("Error fetching prices:", error);
    }

    const productionPriceSymbols = [
      "USDC",
      "USDT",
      "WFUSE",
      "FTM",
      "LVC",
      "stEUR",
      "TST",
      "EUROs",
      "ARB",
      "SONNE",
      "MAI",
      "MST",
      "BRUSH",
      "WMATIC",
      "fSONIC",
      "POLTER",
      "lisUSD",
      "fBUX",
      "VOLT",
      "SLIZ",
      "SPIRIT",
      "MODE",
      "ION",
      "CELO",
      "USDFI",
      "EQUAL",

      "BOBA",
      // "SCALES"
    ];

    const devPriceSymbols = [
      ...productionPriceSymbols,
      // "DAI",
      "PDAI",
      // "EQUAL",
      "GRAI",
    ];

    const symbolsToWatch = IS_ON_PLAYGROUND
      ? devPriceSymbols
      : productionPriceSymbols;

    try {
      const prices =
        await priceFetching.fetchPricesFromCoinGecko(symbolsToWatch);

      // console.table(prices);

      for (const symbolToWatch of symbolsToWatch) {
        const price = prices[symbolToWatch] ?? 0;
        this.setPriceForSymbol(symbolToWatch, price);
      }
    } catch (e) {
      console.warn(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `readAndUpdateAllPricesBySymbol :: ${symbolsToWatch.toString()} : ${e}`,
      );
    }

    // for (const symbolToWatch of symbolsToWatch) {
    //   try {
    //     const price =
    //       await priceFetching.fetchPriceFromCoinGecko(symbolToWatch);
    //     this.setPriceForSymbol(symbolToWatch, price);
    //   } catch (e) {
    //     console.error(
    //       `readAndUpdateAllPricesBySymbol :: ${symbolToWatch} : ${e}`,
    //     );
    //   }
    // }

    const currentTimestamp = Math.floor(Date.now() / 1000);
    this.setSymbolToPriceMapLastUpdateTimestamp(currentTimestamp);
  }

  // ****  Observables setter actions ****

  @action("setPriceForPair")
  private setPriceForPair(pairId: TPairIds, priceInUnits: number) {
    // console.log(`%% setting price for ${pairId} : ${priceInUnits}`);
    this.pricesMap.set(pairId, priceInUnits);
    this.lastPythFeedUpdateTimestamp = timeUtils.currentTimeInSeconds();
  }

  @action("setPriceForSymbol")
  private setPriceForSymbol(symbol: string, priceInUnits: number) {
    // console.log(`%%% setting price for ${symbol} : ${priceInUnits}`);
    this.symbolToPriceMap.set(symbol, priceInUnits);
  }

  @action("setSymbolToPriceMapLastUpdateTimestamp")
  private setSymbolToPriceMapLastUpdateTimestamp(updateTiemstamp: number) {
    this.symbolToPriceMapLastUpdateTimestamp = updateTiemstamp;
  }

  @action("setLoadingState")
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
