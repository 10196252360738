import React, { useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useCryptoWalletIntegrationStore } from "../../store/storeHooks";
import { PendingEpochDepositsTable } from "./subComponents/tables/PendingEpochDepositsTable";
import { PendingEpochRedeemsTable } from "./subComponents/tables/PendingEpochRedeemsTable";
import { TabsSelectorRow, TabSelectorGist } from "lynx-ui-components";
import { LiquidityActionsHistoryTable } from "./subComponents/tables/LiquidityActionsHistoryTable.tsx";
import {
  useGetLiquidityHistoryTableRowGistsForHolderOnActiveChain,
  useGetLiquidityHistoryTableRowGistsForHolderOnAllChains,
  useGetPendingEpochDepositsForHolderOnActiveChain,
  useGetPendingEpochRedeemsForHolderOnActiveChain,
} from "./subComponents/liquidityPageHooks.ts";

interface IProps {
  showFromAllChains: boolean;
}

const useStyles = makeStyles()((theme) => ({
  // TODO : CRITICAL : Use a proper 'page class' from the common styles
  root: {
    flex: 1,
    // minHeight: "20rem",
    display: "flex",
    flexDirection: "column",
  },
}));

export const PendingDepositsAndRedeemDisplayPanel: React.FC<IProps> = observer(
  (props) => {
    const { classes, cx } = useStyles();
    const { showFromAllChains } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const walletConnectionStore = useCryptoWalletIntegrationStore();

    const holderAddress = walletConnectionStore.mainAddress;

    const pendingEpochDeposits =
      useGetPendingEpochDepositsForHolderOnActiveChain(holderAddress);
    const pendingEpochRedeems =
      useGetPendingEpochRedeemsForHolderOnActiveChain(holderAddress);

    const liquidityHistoryActionGistsOnActiveChain =
      useGetLiquidityHistoryTableRowGistsForHolderOnActiveChain(holderAddress);
    const liquidityHistoryActionGistsOnAllChains =
      useGetLiquidityHistoryTableRowGistsForHolderOnAllChains(holderAddress);

    const liquidityHistoryActionGistsToUse = useMemo(() => {
      if (showFromAllChains) {
        return liquidityHistoryActionGistsOnAllChains;
      } else {
        return liquidityHistoryActionGistsOnActiveChain;
      }
    }, [
      liquidityHistoryActionGistsOnActiveChain,
      liquidityHistoryActionGistsOnAllChains,
      showFromAllChains,
    ]);

    const content = useMemo(() => {
      if (selectedTab === 0) {
        if (pendingEpochDeposits.length === 0) {
          return undefined;
        } else {
          return (
            <PendingEpochDepositsTable
              pendingEpochDeposits={pendingEpochDeposits}
              epochLengthInSeconds={60 * 60 * 1}
            />
          );
        }
      } else if (selectedTab === 1) {
        if (pendingEpochRedeems.length == 0) {
          return undefined;
        } else {
          return (
            <PendingEpochRedeemsTable
              pendingEpochRedeems={pendingEpochRedeems}
            />
          );
        }
      } else if (selectedTab === 2) {
        return (
          <LiquidityActionsHistoryTable
            liquidityHistoryRowGists={liquidityHistoryActionGistsToUse}
          />
        );
      } else {
        return <Typography>None</Typography>;
      }
    }, [
      selectedTab,
      pendingEpochDeposits,
      pendingEpochRedeems,
      liquidityHistoryActionGistsToUse,
    ]);

    const pendingDepositsLength = pendingEpochDeposits.length;
    const pendingRedeemsLength = pendingEpochRedeems.length;

    const tabSelectors = useMemo<TabSelectorGist[]>(() => {
      return [
        {
          text: `Pending Deposits`,
          value: 0,
          note: pendingDepositsLength.toString(),
        },
        {
          text: `Pending Redeems`,
          value: 1,
          note: pendingRedeemsLength.toString(),
        },
        {
          text: `History`,
          value: 2,
        },
      ];
    }, [pendingDepositsLength, pendingRedeemsLength]);

    return (
      <div className={classes.root}>
        <TabsSelectorRow
          isLabelCentered
          tabSelectors={tabSelectors}
          value={selectedTab}
          onChange={(val) => setSelectedTab(val)}
        />

        <Box
          sx={{
            flex: {
              xs: "1",
              md: 1,
            },
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          {content}
        </Box>
      </div>
    );
  },
);
