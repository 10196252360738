import { Contract, ContractTransactionResponse } from "ethers";
import { Signer, Provider } from "ethers";
import ERC20Json from "../../../typechain/abis/ERC20.json";
import { IErc20Service } from "./IErc20Service";
import { ERC20 } from "../../../typechain/@openzeppelin/contracts/token/ERC20/ERC20";

export class Erc20Service implements IErc20Service {
  private erc20Contract: ERC20;

  constructor(erc20Address: string, signerOrProvider: Signer | Provider) {
    this.erc20Contract = this.erc20Contract = new Contract(
      erc20Address,
      ERC20Json.abi,
      signerOrProvider,
    ) as unknown as ERC20;
  }

  public async readBalance(address: string): Promise<bigint> {
    return this.erc20Contract.balanceOf(address);
  }

  public async readAllowance(
    ownerAddress: string,
    spenderAddress: string,
  ): Promise<bigint> {
    return this.erc20Contract.allowance(ownerAddress, spenderAddress);
  }

  public async approve(
    spenderAddress: string,
    amount: bigint,
  ): Promise<ContractTransactionResponse> {
    return this.erc20Contract.approve(spenderAddress, amount);
  }
}
