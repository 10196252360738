import React, { useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Stack, Typography } from "@mui/material";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { observer } from "mobx-react";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants";
import { PAIR_CONFIGS_IDS, TPairIds } from "../../../constants/pairsConstants";
import { PairOverViewComponent } from "./PairOverviewContent";
import { useIsMobile } from "../../../ux/uxHooks.ts";
import { TabsSelectorRow, TabSelectorGist } from "lynx-ui-components";
import { PairCapsContent } from "./PairCapsContent.tsx";

interface IProps {
  pairId?: TPairIds;
  sufix?: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    // flex: 1,
    minHeight: "30rem",
    display: "flex",
    flexDirection: "column",
  },
  headerOption: {
    height: "2rem",
    minWidth: "8rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: "0.2rem",

    // marginRight: "0.2rem",
    // marginLeft: "0.2rem",
    transition: "0.5s ease",
    borderBottom: "3px solid transparent",
    cursor: "pointer",
  },
  selectedOption: {
    // borderBottom: "3px solid #8247E5",
    borderColor: THEME_CONSTANTS.brandPurple,
    // backgroundColor: OLA_X_THEME_CONSTANTS.activeGrayBg,
  },
}));

const BTC_PAIRS = {
  USDC: "BTCUSD",
};

const ETH_PAIRS = {
  USDC: "ETHUSD",
};

const FTM_PAIRS = {
  USDC: "FTMUSD",
};

const MATIC_PAIRS = {
  USDC: "MATICUSD",
};

const TRADE_VIEW_PAIR_IDS = {
  BTC: BTC_PAIRS,

  ETH: ETH_PAIRS,
  WETH: ETH_PAIRS,

  FTM: FTM_PAIRS,
  WFTM: FTM_PAIRS,

  MATIC: MATIC_PAIRS,
  WMATIC: MATIC_PAIRS,
};

const TRADE_VIEW_PAIR_KEYS: Record<TPairIds, string> = {
  [PAIR_CONFIGS_IDS.BTC_USD]: "PYTH:BTCUSD",
  [PAIR_CONFIGS_IDS.ETH_USD]: "PYTH:ETHUSD",
  [PAIR_CONFIGS_IDS.BNB_USD]: "PYTH:BNBUSD",
  [PAIR_CONFIGS_IDS.FTM_USD]: "PYTH:FTMUSD",
  [PAIR_CONFIGS_IDS.SOL_USD]: "PYTH:SOLUSD",

  // FOREX
  [PAIR_CONFIGS_IDS.EUR_USD]: "PYTH:EURUSD",
  [PAIR_CONFIGS_IDS.GBP_USD]: "PYTH:GBPUSD",
  [PAIR_CONFIGS_IDS.AUD_USD]: "PYTH:AUDUSD",
  [PAIR_CONFIGS_IDS.NZD_USD]: "PYTH:NZDUSD",

  // CAT Mode
  [PAIR_CONFIGS_IDS.BTC_USD_CAT]: "PYTH:BTCUSD",
  [PAIR_CONFIGS_IDS.ETH_USD_CAT]: "PYTH:ETHUSD",
};

function safeGetTradeViewPair(pairId: TPairIds | 0) {
  const key = TRADE_VIEW_PAIR_KEYS[pairId as TPairIds];

  return key ?? "";
}

export const LeverageOverviewDisplayPanel = observer<React.FC<IProps>>(
  (props) => {
    const { pairId, sufix } = props;
    const { classes, cx } = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);

    const pairTradeViewIdentifier = safeGetTradeViewPair(pairId ?? 0);

    const isMobile = useIsMobile();

    const graphComponent = useMemo(() => {
      // Note : Using fixed height to prevent "structure jumps" when changing settlement assets
      const componentHeightRem = 33;
      return (
        <div
          style={{
            // flex: 1,
            height: `${componentHeightRem}rem`,
            width: "100%",
            // border: "1px solid red",
            // display: "block",
          }}
          id={"chartContainer"}
        >
          {/* TODO : Understand why the chart does not grow to 100% height in mobile */}
          {/* NOTE : Currently it seems to work with the Quick-n-Dirty fix */}
          {/* NOTE : Adding 'pairId' to key to force re-render */}
          <AdvancedRealTimeChart
            theme="dark"
            autosize={isMobile ? false : false}
            width={isMobile ? "100%" : "100%"}
            height={`${componentHeightRem}rem`}
            symbol={pairTradeViewIdentifier}
            style={"1"}
            hide_side_toolbar={isMobile}
            // key={`price_graph_${pairId}`}
            container_id={`container_price_graph_${pairId}${sufix}`}
          />
        </div>
      );
    }, [sufix, isMobile, pairId, pairTradeViewIdentifier]);

    const pairInfoComponent = useMemo(() => {
      return <PairOverViewComponent pairId={pairId ?? 0} />;
    }, [pairId]);

    const pairCapsComponent = useMemo(() => {
      return <PairCapsContent pairId={pairId ?? 0} />;
    }, [pairId]);

    const content = useMemo(() => {
      if (selectedTab === 0) {
        return graphComponent;
      } else if (selectedTab === 1) {
        return pairInfoComponent;
      } else if (selectedTab === 2) {
        return pairCapsComponent;
      } else {
        return <Typography>None</Typography>;
      }
    }, [graphComponent, pairInfoComponent, pairCapsComponent, selectedTab]);

    const tabsSelectors = useMemo<TabSelectorGist[]>(() => {
      return [
        {
          text: "Graph",
          value: 0,
        },
        {
          text: "Pair",
          value: 1,
        },
        {
          text: "Caps",
          value: 2,
        },
      ];
    }, []);

    return (
      <div className={classes.root}>
        {/* Tab Header */}
        <TabsSelectorRow
          isLabelCentered
          tabSelectors={tabsSelectors}
          onChange={(val) => setSelectedTab(val)}
          value={selectedTab}
        />
        {/* Tab Content */}
        <Box
          sx={{
            flex: {
              xs: "1",
              md: 1,
            },
            backgroundColor: THEME_CONSTANTS.panelBgColor,
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            marginTop: 1,
            justifyContent: "center",
          }}
        >
          {content}
        </Box>
      </div>
    );
  },
);
