export const CHAIN_ID_LOCALHOST = 1337;
export const CHAIN_ID_HARDHAT = 31337;
export const CHAIN_ID_ETHEREUM_ROPSTEN = 3;

export const CHAIN_ID_OPTIMISM = 10;

export const CHAIN_ID_FUSE = 122;
export const CHAIN_ID_BSC = 56;
export const CHAIN_ID_POLYGON = 137;
export const CHAIN_ID_BSC_TESTNET = 97;
export const CHAIN_ID_FANTOM_OPERA = 250;
export const CHAIN_ID_MOONBEAM = 1284;
export const CHAIN_ID_FANTOM_TESTNET = 4002;
export const CHAIN_ID_MATIC_MAINNET = 137;

export const CHAIN_ID_MANTLE_MAINNET = 5000;
export const CHAIN_ID_MANTLE_TESTNET = 5001;

export const CHAIN_ID_MODE = 34443;

export const CHAIN_ID_CELO = 42220;
export const CHAIN_ID_BOBA_MAINNET = 288;
// export const CHAIN_ID_BOBA_GOERLI = 2888;
export const CHAIN_ID_KCC = 321;
export const CHAIN_ID_AVALANCHE_C_CHAIN = 43114;

export const CHAIN_ID_ARBITRUM_ONE = 42161;

export const CHAIN_ID_LINEA = 59144;

export const CHAIN_ID_ETHERUM_MAINNET = 1;
export const CHAIN_ID_NO_CHAIN = -1;

export type TLocalChainIds =
  | typeof CHAIN_ID_LOCALHOST
  | typeof CHAIN_ID_HARDHAT;
// export type TLiveChainIds =
//   | typeof CHAIN_ID_ETHEREUM_ROPSTEN
//   | typeof CHAIN_ID_FUSE
//   | typeof CHAIN_ID_BSC
//   | typeof CHAIN_ID_BSC_TESTNET
//   | typeof CHAIN_ID_FANTOM_OPERA
//   | typeof CHAIN_ID_FANTOM_TESTNET
//   | typeof CHAIN_ID_MATIC_MAINNET
//   | typeof CHAIN_ID_CELO
//   | typeof CHAIN_ID_AVALANCHE_C_CHAIN
//   | typeof CHAIN_ID_MOONBEAM
//   | typeof CHAIN_ID_BOBA
//   | typeof CHAIN_ID_KCC
//   | typeof CHAIN_ID_MANTLE_MAINNET
//   | typeof CHAIN_ID_MANTLE_TESTNET;

export type TLiveChainIds =
  | typeof CHAIN_ID_FANTOM_OPERA
  | typeof CHAIN_ID_BOBA_MAINNET
  | typeof CHAIN_ID_LINEA
  | typeof CHAIN_ID_FUSE
  | typeof CHAIN_ID_ARBITRUM_ONE
  | typeof CHAIN_ID_OPTIMISM
  | typeof CHAIN_ID_POLYGON
  | typeof CHAIN_ID_MANTLE_MAINNET
  | typeof CHAIN_ID_BSC
  | typeof CHAIN_ID_MODE
  | typeof CHAIN_ID_CELO;

export type TEngineChainIds =
  | typeof CHAIN_ID_FANTOM_OPERA
  | typeof CHAIN_ID_BOBA_MAINNET;
export type TSourceChainIds =
  | typeof CHAIN_ID_FUSE
  | typeof CHAIN_ID_FANTOM_OPERA
  | typeof CHAIN_ID_LINEA
  | typeof CHAIN_ID_ARBITRUM_ONE
  | typeof CHAIN_ID_OPTIMISM
  | typeof CHAIN_ID_POLYGON
  | typeof CHAIN_ID_MANTLE_MAINNET
  | typeof CHAIN_ID_BSC
  | typeof CHAIN_ID_MODE
  | typeof CHAIN_ID_CELO
  | typeof CHAIN_ID_BOBA_MAINNET;

// export type TFutureChainIds = typeof CHAIN_ID_ETHERUM_MAINNET;
export type TChainIds = TLiveChainIds | TLocalChainIds;
export type TChainIdsWithNone = TChainIds | typeof CHAIN_ID_NO_CHAIN;
export type TChainIdsWithFuture = TLiveChainIds | TLocalChainIds;

export const LOCAL_CHAINS: TChainIds[] = [1337, 31337];
// TODO : CRITICAL : Remove this and use 'Active Chain Ids'
export const LIVE_CHAINS: TChainIds[] = [
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_BOBA_MAINNET,

  CHAIN_ID_OPTIMISM,
  CHAIN_ID_FUSE,
  CHAIN_ID_LINEA,
  CHAIN_ID_ARBITRUM_ONE,
  CHAIN_ID_POLYGON,
  CHAIN_ID_MANTLE_MAINNET,
  CHAIN_ID_BSC,
  CHAIN_ID_MODE,
  CHAIN_ID_CELO,
];

export const ENGINE_CHAINS_IDS: TEngineChainIds[] = [
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_BOBA_MAINNET,
];

// DEV_NOTE : Hard-coded quick-n-dirty solution for explorer urls
export type TChainExplorerBaseUrls = {
  explorerName: string;
  baseUrl: string;
  txUrlBase: string;
  blockUrlBase: string;
  addressUrlBase: string;
};

export type TChainConstants = {
  id: TChainIds;
  name: string;
  shortDisplayName: string;
  codeName: string;

  explorerUrls: TChainExplorerBaseUrls;
};

export const LIVE_CHAIN_CONSTANTS: Record<TLiveChainIds, TChainConstants> = {
  // Fantom Opera
  [CHAIN_ID_FANTOM_OPERA]: {
    id: CHAIN_ID_FANTOM_OPERA,
    name: "Fantom Opera",
    shortDisplayName: "Fantom",
    codeName: "fantom_opera",
    explorerUrls: {
      explorerName: "FTM explorer",
      baseUrl: "https://ftmscan.com/",
      blockUrlBase: "https://ftmscan.com/block",
      addressUrlBase: "https://ftmscan.com/address",
      txUrlBase: "https://ftmscan.com/tx",
    },
  },

  // BSC
  [CHAIN_ID_BSC]: {
    id: CHAIN_ID_BSC,
    name: "BSC",
    shortDisplayName: "BSC",
    codeName: "bsc",
    explorerUrls: {
      explorerName: "BscScan",
      baseUrl: "https://bscscan.com/",
      blockUrlBase: "https://bscscan.com/block",
      addressUrlBase: "https://bscscan.com/address",
      txUrlBase: "https://bscscan.com/tx/",
    },
  },

  // OPTIMISM
  [CHAIN_ID_OPTIMISM]: {
    id: CHAIN_ID_OPTIMISM,
    name: "Optimism",
    shortDisplayName: "Optimism",
    codeName: "optimism",
    explorerUrls: {
      explorerName: "OP explorer",
      baseUrl: "https://optimistic.etherscan.io/",
      blockUrlBase: "https://optimistic.etherscan.io/block",
      addressUrlBase: "https://optimistic.etherscan.io/address",
      txUrlBase: "https://optimistic.etherscan.io/tx",
    },
  },

  // POLYGON
  [CHAIN_ID_POLYGON]: {
    id: CHAIN_ID_POLYGON,
    name: "Polygon",
    shortDisplayName: "Polygon",
    codeName: "polygon",
    explorerUrls: {
      explorerName: "Polygon explorer",
      baseUrl: "https://polygonscan.com/",
      blockUrlBase: "https://polygonscan.com/block",
      addressUrlBase: "https://polygonscan.com/address",
      txUrlBase: "https://polygonscan.com/tx",
    },
  },

  // Fuse
  [CHAIN_ID_FUSE]: {
    id: CHAIN_ID_FUSE,
    name: "Fuse",
    shortDisplayName: "Fuse",
    codeName: "fuse",
    explorerUrls: {
      explorerName: "Fuse Explorer",
      baseUrl: "https://explorer.fuse.io/",
      blockUrlBase: "https://explorer.fuse.io/block/",
      addressUrlBase: "https://explorer.fuse.io/address/",
      txUrlBase: "https://explorer.fuse.io/tx/",
    },
  },

  // BOBA Mainnet
  [CHAIN_ID_BOBA_MAINNET]: {
    id: CHAIN_ID_BOBA_MAINNET,
    name: "Boba Mainnet",
    shortDisplayName: "Boba",
    codeName: "boba_mainnet",
    explorerUrls: {
      explorerName: "Boba Mainnet explorer",
      baseUrl: "https://bobascan.com/",
      blockUrlBase: "https://bobascan.com/block",
      addressUrlBase: "https://bobascan.com/address",
      txUrlBase: "https://bobascan.com/tx",
    },
  },

  // Linea
  [CHAIN_ID_LINEA]: {
    id: CHAIN_ID_LINEA,
    name: "Linea",
    shortDisplayName: "Linea",
    codeName: "linea",
    explorerUrls: {
      explorerName: "Linea Explorer",
      baseUrl: "https://lineascan.build/",
      blockUrlBase: "https://lineascan.build/block/",
      addressUrlBase: "https://lineascan.build/address/",
      txUrlBase: "https://lineascan.build/tx/",
    },
  },

  // Arbitrum
  [CHAIN_ID_ARBITRUM_ONE]: {
    id: CHAIN_ID_ARBITRUM_ONE,
    name: "Arbitrum",
    shortDisplayName: "Arbitrum",
    codeName: "arbitrum",
    explorerUrls: {
      explorerName: "Arbiscan",
      baseUrl: "https://arbiscan.io/",
      blockUrlBase: "https://arbiscan.io/block/",
      addressUrlBase: "https://arbiscan.io/address",
      txUrlBase: "https://arbiscan.io/tx/",
    },
  },

  // Celo
  [CHAIN_ID_CELO]: {
    id: CHAIN_ID_CELO,
    name: "Celo",
    shortDisplayName: "Celo",
    codeName: "celo",
    explorerUrls: {
      explorerName: "CeloScan",
      baseUrl: "https://celoscan.io/",
      blockUrlBase: "https://celoscan.io/block/",
      addressUrlBase: "https://celoscan.io/address",
      txUrlBase: "https://celoscan.io/tx/",
    },
  },

  // Mantle
  [CHAIN_ID_MANTLE_MAINNET]: {
    id: CHAIN_ID_MANTLE_MAINNET,
    name: "Mantle",
    shortDisplayName: "Mantle",
    codeName: "mantle",
    explorerUrls: {
      explorerName: "Mantle Explorer",
      baseUrl: "https://explorer.mantle.xyz/",
      blockUrlBase: "https://explorer.mantle.xyz/block/",
      addressUrlBase: "https://explorer.mantle.xyz/address",
      txUrlBase: "https://explorer.mantle.xyz/tx/",
    },
  },

  // MODE
  [CHAIN_ID_MODE]: {
    id: CHAIN_ID_MODE,
    name: "Mode",
    shortDisplayName: "Mode",
    codeName: "mode",
    explorerUrls: {
      explorerName: "Mode Scan",
      baseUrl: "https://modescan.io/",
      blockUrlBase: "https://modescan.io/block/",
      addressUrlBase: "https://modescan.io/address/",
      txUrlBase: "https://modescan.io/tx/",
    },
  },
};

// TODO : Merge those two constants together
export type TChainDisplayGist = {
  name: string;
  shortDisplayName: string;
  codeName: string;
  id: number;
  nativeCoinSymbol: string;
};

export const DISPLAY_GIST_EMPTY: TChainDisplayGist = {
  name: "",
  shortDisplayName: "",
  codeName: "",
  id: -1,
  nativeCoinSymbol: "",
};

// Quick-n-Dirty solution to get the display params for all chains
export const CHAIN_ID_TO_DISPLAY_DATA: Record<
  TChainIdsWithFuture,
  TChainDisplayGist
> = {
  // Fantom Opera
  [CHAIN_ID_FANTOM_OPERA]: {
    name: LIVE_CHAIN_CONSTANTS["250"].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS["250"].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS["250"].codeName,
    id: 250,
    nativeCoinSymbol: "FTM",
  },

  // BSC
  "56": {
    name: "BSC",
    shortDisplayName: LIVE_CHAIN_CONSTANTS["56"].shortDisplayName,
    codeName: "bsc",
    id: 56,
    nativeCoinSymbol: "BNB",
  },

  // OPTIMISM
  [CHAIN_ID_OPTIMISM]: {
    name: LIVE_CHAIN_CONSTANTS[CHAIN_ID_OPTIMISM].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_OPTIMISM].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_OPTIMISM].codeName,
    id: CHAIN_ID_OPTIMISM,
    nativeCoinSymbol: "ETH",
  },

  // Fuse
  [CHAIN_ID_FUSE]: {
    name: LIVE_CHAIN_CONSTANTS[CHAIN_ID_FUSE].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_FUSE].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_FUSE].codeName,
    id: CHAIN_ID_FUSE,
    nativeCoinSymbol: "FUSE",
  },

  // POLYGON
  [CHAIN_ID_POLYGON]: {
    name: LIVE_CHAIN_CONSTANTS[CHAIN_ID_POLYGON].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_POLYGON].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_POLYGON].codeName,
    id: CHAIN_ID_POLYGON,
    nativeCoinSymbol: "MATIC",
  },

  // Boba Mainnet
  "288": {
    name: LIVE_CHAIN_CONSTANTS[CHAIN_ID_BOBA_MAINNET].name,
    shortDisplayName:
      LIVE_CHAIN_CONSTANTS[CHAIN_ID_BOBA_MAINNET].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_BOBA_MAINNET].codeName,
    id: CHAIN_ID_BOBA_MAINNET,
    nativeCoinSymbol: "ETH",
  },

  // Linea
  "59144": {
    name: LIVE_CHAIN_CONSTANTS["59144"].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS["59144"].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS["59144"].codeName,
    id: 59144,
    nativeCoinSymbol: "ETH",
  },

  // MODE
  [CHAIN_ID_MODE]: {
    name: LIVE_CHAIN_CONSTANTS[CHAIN_ID_MODE].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_MODE].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_MODE].codeName,
    id: CHAIN_ID_MODE,
    nativeCoinSymbol: "ETH",
  },

  // Arbitrum
  "42161": {
    name: LIVE_CHAIN_CONSTANTS["42161"].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS["42161"].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS["42161"].codeName,
    id: 42161,
    nativeCoinSymbol: "ETH",
  },

  // Arbitrum
  [CHAIN_ID_CELO]: {
    name: LIVE_CHAIN_CONSTANTS[CHAIN_ID_CELO].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_CELO].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS[CHAIN_ID_CELO].codeName,
    id: CHAIN_ID_CELO,
    nativeCoinSymbol: "CELO",
  },

  // Mantle Mainnet
  "5000": {
    name: LIVE_CHAIN_CONSTANTS["5000"].name,
    shortDisplayName: LIVE_CHAIN_CONSTANTS["5000"].shortDisplayName,
    codeName: LIVE_CHAIN_CONSTANTS["5000"].codeName,
    id: 5000,
    nativeCoinSymbol: "MNT",
  },

  // Localhost
  "1337": {
    name: "Localhost",
    shortDisplayName: "Localhost",
    codeName: "localhost",
    id: 1337,
    nativeCoinSymbol: "ETH",
  },
  // Hardhat
  "31337": {
    name: "Hardhat",
    shortDisplayName: "Hardhat",
    codeName: "hardhat",
    id: 31337,
    nativeCoinSymbol: "ETH",
  },

  // // Mainnet
  // "1": {
  //   name: "Ethereum",
  //   shortDisplayName: "Ethereum",
  //   codeName: "ethereum",
  //   id: 1,
  // },
  // // Ropsten
  // "3": {
  //   name: LIVE_CHAIN_CONSTANTS["3"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["3"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["3"].codeName,
  //   id: 3,
  // },
  // // BSC Testnet
  // "97": {
  //   name: "BSC Testnet",
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["97"].shortDisplayName,
  //   codeName: "bscTestnet",
  //   id: 97,
  // },
  // // Fuse
  // "122": {
  //   name: LIVE_CHAIN_CONSTANTS["122"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["122"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["122"].codeName,
  //   id: 122,
  // },
  //
  // // Matic Mainnet
  // "137": {
  //   name: LIVE_CHAIN_CONSTANTS["137"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["137"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["137"].codeName,
  //   id: 137,
  // },
  //
  //
  // // BOBA
  // "288": {
  //   name: LIVE_CHAIN_CONSTANTS["288"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["288"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["288"].codeName,
  //   id: 288,
  // },
  //
  // // KCC
  // "321": {
  //   name: LIVE_CHAIN_CONSTANTS["321"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["321"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["321"].codeName,
  //   id: 321,
  // },
  //
  // // Moonbeam
  // "1284": {
  //   name: LIVE_CHAIN_CONSTANTS["1284"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["1284"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["1284"].codeName,
  //   id: 1284,
  // },
  //
  // // Fantom Testnet
  // "4002": {
  //   name: LIVE_CHAIN_CONSTANTS["4002"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["4002"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["4002"].codeName,
  //   id: 4002,
  // },
  //
  //
  //
  // // Celo
  // "42220": {
  //   name: LIVE_CHAIN_CONSTANTS["42220"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["42220"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["42220"].codeName,
  //   id: 42220,
  // },
  //
  // // Avalanche C Chain
  // "43114": {
  //   name: LIVE_CHAIN_CONSTANTS["43114"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["43114"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["43114"].codeName,
  //   id: 43114,
  // },

  // Mantle Testnet
  // "5001": {
  //   name: LIVE_CHAIN_CONSTANTS["5001"].name,
  //   shortDisplayName: LIVE_CHAIN_CONSTANTS["5001"].shortDisplayName,
  //   codeName: LIVE_CHAIN_CONSTANTS["5001"].codeName,
  //   id: 5001,
  //   nativeCoinSymbol: "MNT",
  // },
};

export const LAYER_ZERO_ENDPOINT_IDS: Record<TLiveChainIds, number> = {
  // fantom
  [CHAIN_ID_BSC]: 30102,
  [CHAIN_ID_POLYGON]: 30109,
  [CHAIN_ID_ARBITRUM_ONE]: 30110,
  [CHAIN_ID_OPTIMISM]: 30111,
  [CHAIN_ID_FANTOM_OPERA]: 30112,
  [CHAIN_ID_CELO]: 30125,
  [CHAIN_ID_FUSE]: 30138,
  [CHAIN_ID_LINEA]: 30183,
  [CHAIN_ID_MANTLE_MAINNET]: 30181,
  [CHAIN_ID_MODE]: 30260,

  // [CHAIN_ID_AVALANCHE_C_CHAIN]: 30106,

  // NOTE : No LZ on Boba
  [CHAIN_ID_BOBA_MAINNET]: 0,
};
