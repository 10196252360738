import React from "react";
import { Box, Typography } from "@mui/material";
import CompetitionInfoBox from "./CompetitionInfoBox.tsx";
import { useIsMobile } from "../../../../ux/uxHooks.ts";
import BoxInfoLineDate from "./BoxInfoLines/BoxInfoLineDate.tsx";
import { TCompetitionConfigs } from "../../competitionsConfigs.ts";
import BoxInfoLineText from "./BoxInfoLines/BoxInfoLineText.tsx";
import { localStringer } from "../../../../ux/uxFunctions.ts";
import {
  CHAIN_ID_TO_DISPLAY_DATA,
  LIVE_CHAIN_CONSTANTS,
  TLiveChainIds,
} from "../../../../constants/chainConstants.ts";
import { THEME_STATE_CONSTANTS } from "../../../../theme/ThemeConstants.ts";

interface IProps {
  competitionConfigs: TCompetitionConfigs;
  isUserEligible: boolean;
}

export const CompetitionInfoRow = React.memo<IProps>((props) => {
  const { competitionConfigs, isUserEligible } = props;

  const isMobile = useIsMobile();

  const urlBases =
    LIVE_CHAIN_CONSTANTS[competitionConfigs.chainId as TLiveChainIds]
      .explorerUrls;

  const collateralAssetExplorerLink = `${urlBases.addressUrlBase}/${competitionConfigs.conditionsParams.collateralAssetAddress}`;

  return (
    <Box
      flexDirection={isMobile ? "column" : "row"}
      flex={1}
      gap={1}
      display={"flex"}
    >
      {/* General Info */}
      <CompetitionInfoBox>
        <BoxInfoLineText
          title={"Collateral Asset"}
          text={competitionConfigs.conditionsParams.collateralAssetSymbol}
          externalLink={collateralAssetExplorerLink}
        />
        <BoxInfoLineText
          title={"Network"}
          text={CHAIN_ID_TO_DISPLAY_DATA[competitionConfigs.chainId].name}
          tokenSymbolBeforeText={
            CHAIN_ID_TO_DISPLAY_DATA[competitionConfigs.chainId]
              .nativeCoinSymbol
          }
        />
        <BoxInfoLineText
          title={"Prize"}
          text={`${localStringer(
            competitionConfigs.prizeParams.rewardAmountInUnits,
          )} ${competitionConfigs.prizeParams.rewardTokenSymbol}`}
          tokenSymbolBeforeText={
            competitionConfigs.prizeParams.rewardTokenSymbol
          }
        />
      </CompetitionInfoBox>

      {/* Conditions */}
      <CompetitionInfoBox>
        <BoxInfoLineText
          title={"Ranked By"}
          text={competitionConfigs.conditionsParams.rankedBy}
        />

        {/*TODO : Calculate 'minPositionSize'*/}
        <BoxInfoLineText
          title={"Min Position Size"}
          text={`${localStringer(
            competitionConfigs.conditionsParams.minPositionSizeInUnits,
          )} ${competitionConfigs.conditionsParams.collateralAssetSymbol}`}
        />
        <BoxInfoLineText
          title={"Max Position Size"}
          text={`${localStringer(
            competitionConfigs.conditionsParams.maxPositionSizeInUnits,
          )} ${competitionConfigs.conditionsParams.collateralAssetSymbol}`}
        />
        <BoxInfoLineText
          title={"Requirements"}
          text={competitionConfigs.conditionsParams.requirementsText}
          externalLink={competitionConfigs.conditionsParams.requirementsLink}
        />
        <BoxInfoLineText
          title={"Is Eligible"}
          text={isUserEligible ? "Eligible" : "Not Eligible"}
          colorOverrideForText={
            isUserEligible
              ? THEME_STATE_CONSTANTS.positivePnL
              : THEME_STATE_CONSTANTS.negativePnL
          }
        />
      </CompetitionInfoBox>

      {/* Dates */}
      <CompetitionInfoBox>
        <BoxInfoLineDate
          text={"Start Date"}
          timestamp={competitionConfigs.timeParams.startTimestamp}
        />
        <BoxInfoLineDate
          text={"End Date"}
          timestamp={competitionConfigs.timeParams.endTimestamp}
        />
        <BoxInfoLineDate
          text={"Registration Open Until"}
          timestamp={competitionConfigs.timeParams.registrationUntilTimestamp}
        />
      </CompetitionInfoBox>
    </Box>
  );
});

export default CompetitionInfoRow;
