import {
  calculatePositionTradeValueInSAUnits,
  calculatePriceDiffInDirection,
} from "../hooks/tradeCalculations.ts";

export function calculatePnlInfo(
  collateralInUnits: number,
  leverageInUnits: number,
  openPriceInUnits: number,
  currentPriceInUnits: number,
  isLong: boolean,
  interestFeeInUnits: number,
  fundingFeeInUnits: number,
  closingFeeInUnits: number,
) {
  const positionCurrentValueInSAUnits = calculatePositionTradeValueInSAUnits(
    openPriceInUnits,
    currentPriceInUnits,
    isLong,
    leverageInUnits,
    collateralInUnits,
    interestFeeInUnits,
    fundingFeeInUnits,
    closingFeeInUnits,
  );

  const priceDiffInDirectionPercentage =
    (calculatePriceDiffInDirection(
      openPriceInUnits,
      currentPriceInUnits,
      isLong,
    ) /
      openPriceInUnits) *
    100;

  const isPositive = positionCurrentValueInSAUnits > collateralInUnits;

  const netPnl = positionCurrentValueInSAUnits - collateralInUnits;
  const roiInUnit = netPnl / collateralInUnits;

  return {
    netPnl,
    roiInUnit,
  };
}
