import { useMemo } from "react";
import {
  CHAIN_ID_ARBITRUM_ONE,
  CHAIN_ID_BSC,
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_FUSE,
  CHAIN_ID_LINEA,
  CHAIN_ID_MANTLE_MAINNET,
  CHAIN_ID_OPTIMISM,
  CHAIN_ID_POLYGON,
  TChainIds,
  TChainIdsWithNone,
} from "../constants/chainConstants.ts";
import { IS_ON_PLAYGROUND } from "../configs.ts";
import { TSettlementAssetSelectionOptionGist } from "lynx-ui-components";
import { LeverageDimensionsParams } from "../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { convertSymbolToNavigationKey } from "../navigation/navigationStateFunctions.ts";

// TODO : Improves this and make it read from the Lexs that were defined (instead of manualy adding)
export function useCreateLexSelectOptions(chainId: TChainIdsWithNone) {
  const lexSelectOptions: TSettlementAssetSelectionOptionGist[] =
    useMemo(() => {
      const list = buildSettlementAssetSelectionGistListForChainId(
        chainId as TChainIds,
      );

      if (list.length) {
        return list;
      } else {
        return [
          {
            symbol: "No Chips Available",
            value: "QUESTION",
            walletBalance: 0,
          },
        ];
      }
    }, [chainId]);

  return lexSelectOptions;
}

export function buildSettlementAssetSelectionGistListForChainId(
  chainId: TChainIds,
): TSettlementAssetSelectionOptionGist[] {
  const ledParamsWithChainId = LeverageDimensionsParams.filter(
    (ledParams) =>
      ledParams.lexParams.chipConfigurations.sourceChainId == chainId,
  );

  return ledParamsWithChainId.map((ledParams) => {
    const sourceSymbol =
      ledParams.lexParams.chipConfigurations.sourceAssetParams.symbol;

    const assetSelectionOptionGist: TSettlementAssetSelectionOptionGist = {
      symbol: sourceSymbol,
      value: convertSymbolToNavigationKey(sourceSymbol),
      walletBalance: 0,
    };

    return assetSelectionOptionGist;
  });
}
