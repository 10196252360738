import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import {
  useActiveLexStoreStore,
  useCryptoWalletIntegrationStore,
} from "../../store/storeHooks";
import { useStructureClasses } from "../../theme/commonClasses.ts";
import { gql } from "graphql-request";
import { PanelActionButton } from "../../components/buildingParts/buttons/PanelActionButton.tsx";
import { toastError, toastInfo } from "../../ux/toasts/toasting.ts";
import { useCompetitionConfigByChain } from "./competitionsConfigs.ts";
import { THEME_CONSTANTS } from "../../theme/ThemeConstants.ts";
import ConnectedCompetitionInfoRow from "./subComponents/CompetitionInfoRow/ConnectedCompetitonInfoRow.tsx";
import ConnectedCompetitionParticipantsTable from "./subComponents/CompetitionParticipantsTable/ConnectedCompetitionParticipantsTable.tsx";
import { Provider, Signer } from "ethers";
import { TestCompetitionManager__factory } from "../../typechain/factories/contracts/Peripheral/TestCompetition/TestCompetitionManager__factory.ts";

interface IProps {}

const useStyles = makeStyles()((theme) => ({}));

export const CompetitionsPage = observer<React.FC<IProps>>((props) => {
  const { classes: pageClasses } = useStructureClasses();
  const { classes } = useStyles();

  const ledStore = useActiveLexStoreStore();

  const walletConnectionStore = useCryptoWalletIntegrationStore();

  const competitionConfigs = useCompetitionConfigByChain(
    walletConnectionStore.chainId,
  );

  // TODO : Move to separate hook
  const [eligibleForCompetition, setEligibleForCompetition] = useState(true);
  const [alreadyClaimedCompetitionTokens, setAlreadyClaimedCompetitionTokens] =
    useState(false);

  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  const queryHasAlreadyClaimedTokens = useCallback(
    async (
      runner: Signer | Provider,
      competitionManagerAddress: string,
      accountToCheck: string,
    ) => {
      if (competitionManagerAddress && accountToCheck) {
        const testCompetitionManagerInstance =
          TestCompetitionManager__factory.connect(
            competitionManagerAddress,
            runner,
          );

        const didAlreadyMintForAccount =
          await testCompetitionManagerInstance.accountsMintedFor(
            accountToCheck,
          );

        // console.log(`Query response is ${didAlreadyMintForAccount}`);

        return didAlreadyMintForAccount;
      } else {
        return false;
      }
    },
    [],
  );

  const queryHasAlreadyClaimedTokensAndUpdateState = useCallback(
    (
      signerOrProvider: Signer | Provider,
      competitionManagerAddress: string,
      accountToCheck: string,
    ) => {
      queryHasAlreadyClaimedTokens(
        signerOrProvider,
        competitionManagerAddress,
        accountToCheck,
      )
        .then((didAlreadyMintForAccount) => {
          console.log(
            `@@@@ DBUG response for account ${accountToCheck} is ${didAlreadyMintForAccount}`,
          );
          setAlreadyClaimedCompetitionTokens(didAlreadyMintForAccount);
        })
        .catch((e) =>
          console.error(`Failed queryHasAlreadyClaimedTokens ${e}`),
        );
    },
    [queryHasAlreadyClaimedTokens],
  );

  // Query is elegible for competition
  useEffect(() => {
    const galaxeGraphUrl = "https://graphigo.prd.galaxy.eco/query";
    const userParticipationQuery = `
    query QueryUserParticipation($campaignId: ID!, $userAddress: String!) {
        campaign(id: $campaignId) {
            participationStatus(address: $userAddress)
        }
    }`;

    const gqlQuery = gql`
      query QueryUserParticipation($campaignId: ID!, $userAddress: String!) {
        campaign(id: $campaignId) {
          participationStatus(address: $userAddress)
        }
      }
    `;

    if (walletConnectionStore.mainAddress) {
      const campaignId = "GCvhAUi9TK";

      // request<{ positions: object[] }>(
      //   galaxeGraphUrl,
      //   gqlQuery,
      //   {
      //     campaignId: campaignId,
      //     userAddress: walletConnectionStore.mainAddress,
      //   },
      //   {},
      // )
      //   .then((v) => {
      //     console.log(`DEBUG RED ${v}`);
      //   })
      //   .catch((e) => console.error(`Failed querying competition graph ${e}`));

      // const requestOptions = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     operationName: "QueryUserParticipation",
      //     query: userParticipationQuery,
      //     variables: {
      //       campaignId: campaignId,
      //       userAddress: walletConnectionStore.mainAddress,
      //     },
      //   }),
      // };
      // fetch(galaxeGraphUrl, requestOptions)
      //   .then((v) => {
      //     console.log(`DEBUG RED ${v}`);
      //   })
      //   .catch((e) => console.error(`Failed queryign competition graph ${e}`));

      const queryParams = new URLSearchParams({
        account: walletConnectionStore.mainAddress,
        campaign: campaignId,
      });
      // const firebaseParticipationQuery = `https://us-central1-lynx-baas.cloudfunctions.net/request_checkIfEligibleForCompetition?${queryParams}`;
      const firebaseParticipationQuery = `${
        competitionConfigs?.externalUrls.checkEligibilityUrl
      }?${queryParams.toString()}`;
      const firebaseRequestOptions = {
        method: "GET",
      };
      fetch(firebaseParticipationQuery, firebaseRequestOptions)
        .then((v) => {
          console.log(`DEBUG RED ${v}`);
          if (v.status == 500) {
            v.text().then((t) => toastError(t));
          } else {
            v.json()
              .then((js) => {
                const didParticipate = js.isEligible as boolean;
                console.log(`DEBUG JSON ${JSON.stringify(js)}`);
                setEligibleForCompetition(didParticipate);
              })
              .catch((e) => console.error(`Failed v.json ${e}`));
          }
        })
        .catch((e) =>
          console.error(`Failed firebase query competition graph ${e}`),
        );
    }
  }, [
    competitionConfigs?.externalUrls.checkEligibilityUrl,
    walletConnectionStore.mainAddress,
  ]);

  const signer = walletConnectionStore.getChainSigner;
  const accountAddress = walletConnectionStore.mainAddress;
  const competitionManagerAddress =
    competitionConfigs?.competitionManagerAddress;

  // Query has already minted competition tokens
  useEffect(() => {
    if (signer && accountAddress && competitionManagerAddress) {
      console.log(
        `DEBUG : Will query and update for account ${accountAddress}`,
      );

      queryHasAlreadyClaimedTokensAndUpdateState(
        signer,
        competitionManagerAddress,
        accountAddress,
      );
    }
  }, [
    accountAddress,
    competitionManagerAddress,
    queryHasAlreadyClaimedTokensAndUpdateState,
    signer,
  ]);

  const claimCompetitionTokensViaCloudFunction = useCallback(
    (accountAddress: string) => {
      if (!accountAddress) {
        toastError(`No Account address`);
      } else {
        const firebaseParticipationQuery =
          competitionConfigs?.externalUrls.claimCompetitionTokensUrl ?? "";

        const firebaseRequestOptions = {
          method: "POST",
          body: JSON.stringify({
            account: accountAddress,
            // cheatPlease: true,
          }),
          headers: { "Content-Type": "application/json" },
        };

        setIsWaitingForResponse(true);

        fetch(firebaseParticipationQuery, firebaseRequestOptions)
          .then((res) => {
            if (res.status == 500) {
              res.text().then((t) => toastError(t));
              setIsWaitingForResponse(false);
            } else {
              res
                .json()
                .then((resJson) => {
                  if (resJson.error) {
                    toastError(resJson.error);
                  }

                  if (resJson.tx) {
                    toastInfo(`Got tokens on tx ${resJson.tx}`);
                    console.log(`Done on tx ${resJson.tx}`);
                  }

                  setIsWaitingForResponse(false);
                })
                .catch((e) => {
                  toastError(`Failed parsing response json ${e} ${e.reason}`);
                  console.error(`Failed parsing response json ${e}`);
                  setIsWaitingForResponse(false);
                });
            }
          })
          .catch((e) => {
            toastError(`Failed requesting competition tokens ${e} ${e.reason}`);
            console.error(`Failed requesting competition tokens ${e}`);
            setIsWaitingForResponse(false);
          });
      }
    },
    [competitionConfigs?.externalUrls.claimCompetitionTokensUrl],
  );

  // TODO : Make a better ux for no-competition
  // TODO : CRITICAL : Read competition id from query so it will be shown also when no wallet is connected

  if (!competitionConfigs) {
    return (
      <div className={pageClasses.pageRoot} style={{ minHeight: "100%" }}>
        <Box className={pageClasses.pageContent_column}>
          <Typography variant={"h3"} fontWeight={"bold"}>
            No competition on chain {walletConnectionStore.chainName}
          </Typography>
        </Box>
      </div>
    );
  }

  return (
    <div className={pageClasses.pageRoot} style={{ minHeight: "100%" }}>
      <Box className={pageClasses.pageContent_column}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h3"} fontWeight={"bold"}>
            {competitionConfigs.displayName}
          </Typography>
          <PanelActionButton
            text={
              alreadyClaimedCompetitionTokens
                ? "Already Got Tokens"
                : isWaitingForResponse
                  ? "Waiting For Mint"
                  : !eligibleForCompetition
                    ? "Not Eligible Yet"
                    : "Get Tokens"
            }
            disabled={
              // !eligibleForCompetition ||
              alreadyClaimedCompetitionTokens || isWaitingForResponse
            }
            style={{ maxWidth: "15rem" }}
            onClick={() => {
              if (!eligibleForCompetition) {
                window.open(
                  competitionConfigs?.conditionsParams.requirementsLink,
                  "_blank",
                  "noreferrer",
                );
              } else {
                claimCompetitionTokensViaCloudFunction(
                  walletConnectionStore.mainAddress,
                );
              }
            }}
          />
        </Stack>
        <Divider
          flexItem
          // variant={"middle"}
          sx={{ height: "0.1rem", backgroundColor: THEME_CONSTANTS.borderGray }}
        />
        <Box flexDirection={"row"}>
          <ConnectedCompetitionInfoRow
            competitionConfigs={competitionConfigs}
            isUserEligible={eligibleForCompetition}
          />
        </Box>
        <Box>
          <ConnectedCompetitionParticipantsTable
            currentUserAddress={walletConnectionStore.mainAddress}
            competitionConfigs={competitionConfigs}
          />
        </Box>
      </Box>
    </div>
  );
});
