import { TAssetParameters } from "../../types/assetTypes";
import { TPairIds } from "../../constants/pairsConstants";
import { TChipConfigurations } from "../../constants/systemChips.ts";
import { ethers } from "ethers";
import { ChipModeEnums } from "../../constants/contractEnums.ts";
import { FANTOM_GRAPH_URL } from "./leveregeDimensionsParams.ts";

export interface ILeverageDimensionLexParameters {
  lexAddress: string;
  chipConfigurations: TChipConfigurations;
}

export interface ILeverageDimensionTradePairParameters {
  pairId: TPairIds;

  pairAssetAName: string;
  pairAssetBName: string;
}

// "Parameters" refers to the unChangeable elements (name, id, tokens, etc..)
// TODO : Think of a better name
export interface ILeverageDimensionParameters {
  id: string;

  // originChainId: number;
  // engineChainId: number;

  hashName: string;
  urlName: string;

  lexParams: ILeverageDimensionLexParameters;
  supportedPairParams: ILeverageDimensionTradePairParameters[];

  graphUrl: string;
}

export interface ILeverageDimensionsService {
  fetchAllLeverageDimensionsParameters(): ILeverageDimensionParameters[];
}

export const EMPTY_LEVERAGE_DIMENSION_PARAMETERS: ILeverageDimensionParameters =
  {
    id: "EMPTY_LEX",
    hashName: "",
    urlName: "",
    lexParams: {
      lexAddress: ethers.ZeroAddress,
      chipConfigurations: {
        enginChainId: 250,
        chipAssetParams: {
          name: "NO_LEX",
          shortName: "NO_LEX",
          symbol: "NO_LEX",
          address: ethers.ZeroAddress,
          decimals: 18,
        },
        chipMode: ChipModeEnums.NONE,
        sourceAssetParams: {
          name: "NO_LEX",
          shortName: "NO_LEX",
          symbol: "NO_LEX",
          address: ethers.ZeroAddress,
          decimals: 18,
        },
        sourceChainId: 250,
        oftProxyAddress: "",
      },
    },
    supportedPairParams: [],
    graphUrl: FANTOM_GRAPH_URL,
  };
