export const CHIP_DECIMALS = 18;

export const PRECISION_SCALE = 18n;
export const PRECISION_UNIT = 10n ** PRECISION_SCALE;

export const PRICES_SCALE = 8;

export const LEVERAGED_MAX_DECIMALS = 2;
// NOTE : multiplier, not power
export const LEVERAGE_MULTIPLIER_SCALE = Math.pow(10, LEVERAGED_MAX_DECIMALS);
export const LEVERAGE_UNIT = BigInt(LEVERAGE_MULTIPLIER_SCALE);

// NOTE : multiplier, not power
export const FRACTION_MULTIPLIER_SCALE = 100_000n; // allows 5 decimal points
export const FRACTION_UNIT = FRACTION_MULTIPLIER_SCALE;

export const ACCURACY_IMPROVEMENT_SCALE = 9n;
export const ACCURACY_IMPROVEMENT_UNIT = 10n ** ACCURACY_IMPROVEMENT_SCALE;

export const LEX_TOKEN_SCALE = 18n;
export const LEX_TOKEN_UNIT = 10n ** LEX_TOKEN_SCALE;

export const LEX_TOKEN_INITIAL_EXCHANGE_RATE_UNITS = 1;

// export const LEX_TOKEN_INITIAL_EXCHANGE_RATE = floatToUnitsBn(
//   0.02,
//   LEX_TOKEN_SCALE
// );

export const GWEI_DECIMALS = 9;
