import React, { useEffect, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import {
  useLexStoreById,
  usePricesStore,
} from "../../../../store/storeHooks.ts";
import { LiquidityPoolPanel, TInfoPanelRowPayload } from "lynx-ui-components";
import {
  CHAIN_ID_TO_DISPLAY_DATA,
  TChainIds,
} from "../../../../constants/chainConstants.ts";
import {
  getUxColorsForAsset,
  UX_COLORS_FOR_SA,
} from "../../../../ux/assetsUxColors.ts";
import { numericalDisplay } from "../../../../ux/displayCalculations.ts";
import { PRICE_UX_FOR_PAIRS } from "../../../../ux/assetsUxPrecisions.ts";
import { localStringer } from "../../../../ux/uxFunctions.ts";
import {
  THEME_CONSTANTS,
  THEME_STATE_CONSTANTS,
} from "../../../../theme/ThemeConstants.ts";
import { APR_MEASURE_WINDOW_IN_DAYS } from "../../../../store/multiInstancesStores/LexStore/SingleLexStore.ts";

interface IProps {
  ledId: string;
  isCardActive?: boolean;
  isProcessing?: boolean;
  showSupplyLiquidityModal: () => void;
  showRemoveLiquidityModal: () => void;
}

export const ConnectedLiquidityPoolPanel = observer<
  React.FunctionComponent<IProps>
>((props) => {
  const {
    ledId,
    isProcessing,
    showSupplyLiquidityModal,
    showRemoveLiquidityModal,
    isCardActive,
  } = props;
  const lexStore = useLexStoreById(ledId);
  const pricesStore = usePricesStore();

  // NOTE : Ensures initialization
  // useEffect(() => {
  //   setTimeout(() => {
  //     void lexStore?.refreshFromOutside();
  //   }, 2 * 1000);
  // }, [lexStore]);

  const chainName =
    CHAIN_ID_TO_DISPLAY_DATA[lexStore?.sourceChainId ?? (0 as TChainIds)]
      ?.shortDisplayName ?? "";

  const balanceForUtilization =
    lexStore?.virtualBalanceForUtilizationInUnits ?? 0;
  const totalBorrowsInUnits = lexStore?.totalBorrowsInUnits ?? 0;

  const assetSymbol = lexStore?.sourceAssetParameters.symbol ?? "";

  const assetColorsInfo = getUxColorsForAsset(assetSymbol);

  const saPriceInUnits = pricesStore.symbolToPriceMap.get(assetSymbol) ?? 0;
  const totalTVL = balanceForUtilization * saPriceInUnits;

  const nextEpochAt = lexStore?.nextEpochStartMin ?? 0;

  const yearlyAprInUnits = lexStore?.yearlyAprFromReferenceEpochInUnits ?? 0;
  const aprTextColor =
    yearlyAprInUnits >= 0
      ? THEME_STATE_CONSTANTS.positivePnL
      : THEME_STATE_CONSTANTS.negativePnL;
  const yearlyAprInPercentages = yearlyAprInUnits * 100;

  const poolInfoRowPayloads = useMemo<TInfoPanelRowPayload[]>(() => {
    return [
      {
        title: "Total TVL",
        text: `$${numericalDisplay(totalTVL, 2)}`,
        // variant: "caption",
      },
      {
        title: "Fees & Rewards APR",
        titleTooltip: `Based on last ${APR_MEASURE_WINDOW_IN_DAYS} days of trade`,
        text: `${localStringer(yearlyAprInPercentages ?? 0)}%`,
        textColor: aprTextColor,
      },
      {
        title: "Current Epoch",
        text: `${localStringer(lexStore?.currentEpochNumber ?? 0)}`,
        titleTooltip: `Next Epoch at ${
          new Date(nextEpochAt * 1000).toLocaleDateString() +
          " " +
          new Date(nextEpochAt * 1000).toLocaleTimeString()
        }`,
        variant: "caption",
      },
      {
        title: "Exchange Rate",
        text: `${localStringer(lexStore?.exchangeRateInUnits ?? 0, 6)}`,
        variant: "caption",
      },
      // {
      //   title: "7 Days Trading Volume",
      //   text: "$150,000",
      // },
    ];
  }, [
    aprTextColor,
    lexStore?.currentEpochNumber,
    lexStore?.exchangeRateInUnits,
    lexStore?.yearlyAprFromReferenceEpochInUnits,
    nextEpochAt,
    totalTVL,
  ]);

  const providerInfoRowPayloads = useMemo<TInfoPanelRowPayload[]>(() => {
    return [
      {
        title: "Your Balance",
        text: `${numericalDisplay(lexStore?.activeUserStore.accountSupplyInUnits ?? 0, 3)} ${assetSymbol}`,
        textColor: THEME_CONSTANTS.brandLightPurple,
      },
      {
        title: "",
        text: `$${numericalDisplay((lexStore?.activeUserStore.accountSupplyInUnits ?? 0) * saPriceInUnits, 3)}`,
        variant: "caption",
        textColor: THEME_CONSTANTS.brandPurple,
      },
    ];
  }, [
    assetSymbol,
    lexStore?.activeUserStore.accountSupplyInUnits,
    saPriceInUnits,
  ]);

  return (
    <LiquidityPoolPanel
      key={ledId}
      assetSymbol={assetSymbol}
      chainName={chainName}
      poolBalanceInUnits={balanceForUtilization}
      utilizationInUnits={totalBorrowsInUnits}
      poolInfoRowPayloads={poolInfoRowPayloads}
      providerInfoRowPayloads={providerInfoRowPayloads}
      onRemoveButtonClicked={showRemoveLiquidityModal}
      onSupplyButtonClicked={showSupplyLiquidityModal}
      mainColor={assetColorsInfo.mainColor}
      secondaryColor={assetColorsInfo.secondaryColor}
      activeShadowColor={assetColorsInfo.activeShadowColor}
      isCardActive={isCardActive}
      isProcessing={isProcessing}
    />
  );
});

export default ConnectedLiquidityPoolPanel as (
  props: IProps,
) => React.JSX.Element;
