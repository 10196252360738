import {
  LEVERAGE_MULTIPLIER_SCALE,
  LEVERAGE_UNIT,
  LEVERAGED_MAX_DECIMALS,
} from "../constants/scales";

// TODO : Update these converters when changing leverage precision

export function scaledLeverageToUnits(scaledLeverage: number): number {
  return scaledLeverage / LEVERAGE_MULTIPLIER_SCALE;
}

export function floatUnitsToScaledLeverage(leverageInUnits: number): number {
  // NOTE : Using floor because js float parsing caused some values to become xx.000000000y
  return Math.floor(
    parseFloat(leverageInUnits.toFixed(LEVERAGED_MAX_DECIMALS)) *
      LEVERAGE_MULTIPLIER_SCALE,
  );
}

export function calculateLeveragedPosition(
  collateral: bigint,
  leverage: number,
): bigint {
  return (collateral * BigInt(leverage)) / LEVERAGE_UNIT;
}

export function calculateCollateralFromLeveragedPosition(
  leveragedPosition: bigint,
  leverage: bigint,
): bigint {
  return (leveragedPosition * LEVERAGE_UNIT) / leverage;
}

export function calculateLeveragedPosition_leverageUnits(
  collateral: bigint,
  leverageInUnits: number,
): bigint {
  // TODO : Change after changing scale
  const leverageBn = floatUnitsToScaledLeverage(leverageInUnits);
  return calculateLeveragedPosition(collateral, leverageBn);
}
