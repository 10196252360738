import { action, computed, makeObservable, observable } from "mobx";

import type { IObservableArray } from "mobx/dist/internal";
import type { BytesLike, TypedDataDomain } from "ethers";
import type { TUserInteractionsRecordGraphInfo } from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { ContractTransactionResponse, ethers } from "ethers";

import {
  SingleLexStore,
  TPendingEpochDepositComplete,
  TPendingEpochRedeemComplete,
} from "./SingleLexStore.ts";
import { divideOrZeroBn } from "../../../utils/calculations.ts";
import type {
  ILexLensService,
  TLexPoolSupplierLensStateStruct,
  TPendingEpochDepositLensStruct,
  TPendingEpochRedeemLensStruct,
} from "../../../services/contractsIntegration/LexLensService/ILexLensService.ts";
import { ethersStructResponseToArray } from "../../../utils/ethersTypes.ts";
import {
  EMPTY_PENDING_EPOCH_DEPOSIT,
  EMPTY_PENDING_EPOCH_REDEEM,
} from "../../../services/contractsIntegration/LexLensService/LexLensService.ts";
import { floatToUnitsBn, unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { TCompletePositionDataFromLens } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import {
  EMPTY_GRAPH_INFO_USER_INTERACTIONS_RECORD,
  TChipHistoryActionGist,
  TClosedPositionGist,
  TLiquidityRequestGist,
} from "../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import {
  ChipModeEnums,
  LiquidityIntentsVerifierActionsEnums,
  TOpenOrderType,
  TPositionField,
  TradeIntentsVerifierActionsEnums,
  UpdatePositionFieldOrderTypeEnums,
} from "../../../constants/contractEnums.ts";
import {
  TPositionRequestIdentifierStruct,
  TPositionRequestParamsStruct,
} from "../../../services/contractsIntegration/TradingService/ITradingService.ts";
import { floatToPriceBn } from "../../../utils/lynxScalesUtils.ts";
import {
  floatUnitsToScaledLeverage,
  scaledLeverageToUnits,
} from "../../../utils/leverageCalculationsUtils.ts";
import { toastError } from "../../../ux/toasts/toasting.ts";
import { EoaActionTypes } from "../../../types/eoaActionTypes.ts";
import {
  TDelegatedActionResponse,
  TLiquidityProviderRequestPayload_EpochDepositStruct,
  TLiquidityProviderRequestPayload_EpochRedeemStruct,
  TUserDirectPayload_CancelPendingLimitPositionStruct,
  TUserRequestPayload_CloseMarketStruct,
  TUserRequestPayload_OpenPositionStruct,
  TUserRequestPayload_UpdatePositionDoubleFieldStruct,
  TUserRequestPayload_UpdatePositionSingleFieldStruct,
} from "../../../services/servicesIntergration/intentsDelegationService/IIntentsDelegationService.ts";
import { tradeIntentsVerifierPayloadTypes } from "../../../typechain/lynxSystem/intentsVerifiers/tradeIntentsVerifier/tradeIntentsVerifierPayloadTypes.ts";
import { TUserTxResponse } from "../../../types/userStoreTypes.ts";
import { toastTxError } from "../../../ux/toasts/complexToasting.ts";
import { extractWeb3ErrorMessage } from "../../../utils/errorMessages.ts";
import { TSourceChainIds } from "../../../constants/chainConstants.ts";
import { liquidityIntentsVerifierPayloadTypes } from "../../../typechain/lynxSystem/intentsVerifiers/liquidityIntentsVerifier/liquidityIntentsVerifierPayloadTypes.ts";
import {
  handleDelegatedIntentUxFlow,
  positionToastmanager,
} from "../../../ux/delegatedIntentsUx.ts";
import { floatUnitsToScaledFraction } from "../../../utils/reductionCalculationUtils.ts";
import { calculatePositionHashId } from "../../../utils/positionIdcalculation.ts";
import { getRefHashFromCode } from "../../../utils/referrals.ts";
import { makePersistable } from "mobx-persist-store";
import { persistenceUtils } from "../../../utils/persistenceUtils.ts";
import { entitiesSerialization } from "../../../utils/entitiesSerialization.ts";
import { isSameAddress, isSameTxHash } from "../../../utils/addresses.ts";
import { GraphQLService } from "../../../services/servicesIntergration/graphqlService/GraphQLService.ts";
import { groupBy } from "../../../utils/dashUtils.ts";
import { flare } from "viem/chains";
import { toast_step } from "../../../ux/stepToast/statusToastManager.tsx";
import { THashBasedIntentsVerifierRequestBuildingInfoStruct } from "../../../services/contractsIntegration/IntentsVerifierLensService/IIntentsVerifierLensService.ts";
import { notifyDiscordServer } from "../../../services/servicesIntergration/discordService/discordIntegration.ts";
import { DISCORD_ENDPOINTS } from "../../../services/servicesIntergration/discordService/discordConstants.ts";
import { stringifyObject } from "../../../utils/strings.ts";
import { chipsBnToUnits } from "../../../utils/chipsCalculationsUtils.ts";
import { CHIP_DECIMALS } from "../../../constants/scales.ts";
import { bnRoundingUtils } from "../../../utils/bnRoundingUtils.ts";
import { lifecycleIntegration } from "../../../services/servicesIntergration/lifecycleIntegration/lifecycleIntegration.ts";

// TODO : CRITICAL : Add back all the preflight checks (they fail now when wallet is connected to engine chain)
export enum EPositionActionType {
  open = "open",
  close = "close",
  update = "update",
  liquiditySupply = "liquiditySupply",
  liquidityRemove = "liquidityRemove",
}
export type TPositionParams = {
  direction?: string;
  leverage?: number;
  settlementAsset?: string;
  action_type?: EPositionActionType;
  amount?: string;
};

type TGroupedTraderPositionsInPair = {
  pairId: number;
  livePositions: TCompletePositionDataFromLens[];
  closedPositions: TClosedPositionGist[];
  cancelledPositions: TClosedPositionGist[];
};

// TODO : CRITICAL : Go over the 'shouldReadFromGraph' and fix it, it seems that we are reading way too much
//                   it always return true.

export class SingleLexUserStore {
  // TODO : CRITICAL : With the 'UserInteractionRecord' we can ger rid of the semaphores
  private READING_SEMAPHORES = {
    batchReadAndUpdateTradePairStoresWithAccountTrades: false,
    batchReadAndUpdateWithFreshSupplierSpecificState: false,
    updateWithChipsActionsHistory: false,
  };

  @observable public accountAddress: string;

  @observable allowanceForCenter: bigint;

  // LVToken balances
  @observable public accountLxTokenBalance: bigint; // LVToken balance for the user

  @observable private account_allowanceForPool: bigint; // Allowance in underlying with lvToken as spender
  @observable public account_lxTokenBalanceWorthOfUnderlying: bigint; // Balance of underlying for the user in the lvToken ( owed to the user, derived from the user's wallet lvToken balance ).
  @observable public account_underlyingInWalletOnEngineChain: bigint; // Underlying balance for the user in their wallet on the engine chain

  @observable public account_sourceChainInfoLoaded: boolean; // indicated at least on read of source chain info was made
  @observable public account_underlyingInWalletOnSourceChain: bigint; // Underlying balance for the user in their wallet on the source chain

  // Note : Used for Remote OFT Chips
  @observable public account_allowanceForAdapterOnSourceChain: bigint; // Underlying allowance for the OftAdapter on the source chain
  // Note : Used for Local Engine Chips
  @observable public account_allowanceForChipOnEngineChain: bigint; // Underlying allowance for the chip contract on the engine chain

  @observable
  public account_pendingEpochDeposits: IObservableArray<TPendingEpochDepositComplete> =
    observable([]);
  @observable
  public account_pendingEpochRedeems: IObservableArray<TPendingEpochRedeemComplete> =
    observable([]);

  @observable
  public account_processedLiquidityRequests: IObservableArray<TLiquidityRequestGist> =
    observable([]);

  @observable
  public account_chipHistoryActions: IObservableArray<TChipHistoryActionGist> =
    observable([]);

  @observable
  public account_pendingDispenserRewards: bigint;

  @observable public interactionsRecord: TUserInteractionsRecordGraphInfo =
    observable(EMPTY_GRAPH_INFO_USER_INTERACTIONS_RECORD);

  constructor(
    public lexStore: SingleLexStore,
    _accountAddress: string,
  ) {
    makeObservable(this);

    void makePersistable(this, {
      name: `SingleLexUserStore_${lexStore.id}_${_accountAddress}`,
      properties: [
        {
          key: "accountLxTokenBalance",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "account_lxTokenBalanceWorthOfUnderlying",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "account_underlyingInWalletOnEngineChain",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "account_underlyingInWalletOnSourceChain",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "account_allowanceForAdapterOnSourceChain",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "account_allowanceForChipOnEngineChain",
          ...persistenceUtils.buildBigIntSerializationFunctions(),
        },
        {
          key: "account_processedLiquidityRequests",
          serialize: (arr) => {
            return JSON.stringify(
              arr.map((v) =>
                entitiesSerialization.liquidityRequestGist.serialize(v),
              ),
            );
          },
          // @ts-ignore
          deserialize: (arrStr: string) => {
            const arr = JSON.parse(arrStr) as string[];

            const objArr = arr.map((objStr) =>
              entitiesSerialization.liquidityRequestGist.deserialize(objStr),
            );

            // console.log(
            //   `Desrialization for liquidityRequestGist : ${lexStore.id} - ${_accountAddress} : ${stringifyObject(objArr)}`,
            // );

            return objArr;
          },
        },
        {
          key: "account_chipHistoryActions",
          serialize: (arr) => {
            return JSON.stringify(
              arr.map((v) =>
                entitiesSerialization.chipHistoryActionGist.serialize(v),
              ),
            );
          },
          // @ts-ignore
          deserialize: (arrStr: string) => {
            const arr = JSON.parse(arrStr) as string[];

            const objArr = arr.map((objStr) =>
              entitiesSerialization.chipHistoryActionGist.deserialize(objStr),
            );

            // console.log(
            //     `Desrialization for chipHistoryActionGist : ${lexStore.id} - ${_accountAddress} : ${stringifyObject(objArr)}`,
            // );

            return objArr;
          },
        },
        {
          key: "interactionsRecord",
          serialize: entitiesSerialization.interactionsRecord.serialize,
          deserialize: entitiesSerialization.interactionsRecord.deserialize,
        },
      ],
      storage: window.localStorage,
      expireIn: 60 * 60 * 48 * 1000, // 48 hours
      removeOnExpiration: true,
    });

    this.accountAddress = _accountAddress;

    this.allowanceForCenter = 0n;

    this.accountLxTokenBalance = 0n;

    this.account_allowanceForPool = 0n;
    this.account_lxTokenBalanceWorthOfUnderlying = 0n;
    this.account_underlyingInWalletOnEngineChain = 0n;

    this.account_sourceChainInfoLoaded = false;
    this.account_underlyingInWalletOnSourceChain = 0n;
    this.account_allowanceForAdapterOnSourceChain = 0n;
    this.account_allowanceForChipOnEngineChain = 0n;

    this.account_pendingDispenserRewards = 0n;
  }

  // **** Lex Utils ****

  // private get generateDomainData(): TypedDataDomain {
  //   const domainBuildingData = this.lexStore.verificationDomainBuildData;
  //   const domainData = tradeIntentsVerifierPayloadTypes.buildDomainSeparator(
  //     domainBuildingData.sourceChainId.toString(),
  //     domainBuildingData.tradeIntentsVerifierAddress,
  //   );
  //
  //   return domainData;
  // }

  private generateTradeIntentTypedDomainData(
    tradeIntentsVerifierAddress: string,
    sourceChainId: TSourceChainIds,
  ): TypedDataDomain {
    const domainData = tradeIntentsVerifierPayloadTypes.buildDomainSeparator(
      sourceChainId.toString(),
      tradeIntentsVerifierAddress,
    );

    return domainData;
  }

  private generateLiquidityIntentTypedDomainData(
    liquidityIntentsVerifierAddress: string,
    sourceChainId: TSourceChainIds,
  ): TypedDataDomain {
    const domainData =
      liquidityIntentsVerifierPayloadTypes.buildDomainSeparator(
        sourceChainId.toString(),
        liquidityIntentsVerifierAddress,
      );

    return domainData;
  }

  // **** Trading Contract Interactions ****

  public async requestPositionOpen(
    actionType: EoaActionTypes,
    openOrderType: TOpenOrderType,
    pairId: number,
    positionIndex: number,
    isLong: boolean,
    collateralAmount: bigint,
    leverage: number,
    minPrice: number,
    maxPrice: number,
    tpPrice: number,
    slPrice: number,
    tpByFraction: number,
    slByFraction: number,
    domainStr: string,
    referralStr: string,
  ): Promise<TUserTxResponse> {
    let txHash = "";

    // Some sanity to ensure that the user does not set both TP and SL by fraction
    // better to throw an error here than to have a failed tx
    if (tpPrice != 0 && tpByFraction != 0) {
      throw new Error("Both TP Price and TP By Fraction cannot be set");
    }
    if (slPrice != 0 && slByFraction != 0) {
      throw new Error("Both SL Price and SL By Fraction cannot be set");
    }

    const tradePairIdentifier: TPositionRequestIdentifierStruct = {
      pairId: BigInt(pairId),
      settlementAsset: this.lexStore.chipAssetParams.address,
      trader: this.accountAddress,
      positionIndex: BigInt(positionIndex),
    };
    const tradeRequestParams = {
      collateral: collateralAmount,
      leverage: BigInt(floatUnitsToScaledLeverage(leverage)),
      long: isLong,

      minPrice: floatToPriceBn(minPrice),
      maxPrice: floatToPriceBn(maxPrice),

      tp: floatToPriceBn(tpPrice),
      sl: floatToPriceBn(slPrice),

      tpByFraction: floatUnitsToScaledFraction(tpByFraction),
      slByFraction: floatUnitsToScaledFraction(slByFraction),
    };

    // Sanity
    if (tradeRequestParams.collateral === 0n) {
      toastError("Collateral cannot be 0");
      lifecycleIntegration.captureError("Collateral cannot be 0");
      return {
        txHash: "",
        type: actionType,
      };
    }

    const expectedPositionId = calculatePositionHashId(
      tradePairIdentifier.settlementAsset,
      tradePairIdentifier.trader,
      pairId,
      positionIndex,
    );

    // TODO : ADJUST : Add this mechanism
    // const referralDomain = ethers.ZeroHash;
    // const referrerCode = ethers.ZeroHash;

    // TODO : ADJUST : Add logic for this
    const runCapTest = true;

    if (actionType == EoaActionTypes.DIRECT) {
      let staticCallSuccess = false;

      try {
        // TODO : Fix mechanism to simulate in the chip paradigm (set 'from' parameter)
        // Before anything, simulate the tx to check for errors
        // await this.lexStore.freshTradingService.traderRequest_openNewPosition(
        //   tradePairIdentifier,
        //   tradeRequestParams,
        //   openOrderType,
        //   referralDomain,
        //   referrerAddress,
        //   runCapTest,
        //   this.lexStore.systemStore.nativeFeeForRequestOpen,
        //   true,
        // );

        staticCallSuccess = true;
      } catch (e) {
        const txErrorMessage = extractWeb3ErrorMessage(e);

        // NOTE : Quick-n-Dirty fix for an account without any native
        if (txErrorMessage.includes("insufficient balance for transfer")) {
          staticCallSuccess = true;
        } else {
          toastTxError(`Preflight error : ${txErrorMessage}`);
        }
      }

      if (staticCallSuccess) {
        const domainHash = getRefHashFromCode(domainStr);
        const referrerHash = getRefHashFromCode(referralStr);

        const tx: ContractTransactionResponse =
          await this.lexStore.freshTradingService.traderRequest_openNewPosition(
            tradePairIdentifier,
            tradeRequestParams as unknown as TPositionRequestParamsStruct,
            openOrderType,
            domainHash,
            referrerHash,
            runCapTest,
            this.lexStore.systemStore.nativeFeeForRequestOpen,
          );

        // DEV_NOTE : Refresh data after confirmation without waiting here
        tx.wait()
          .then(async () => {
            // this.analyticsStore.logMarketInteraction(
            //   "market_supply",
            //   this.cryptoWalletConnectionStore.mainAddress,
            //   cTokenAddress,
            //   amount
            // );

            console.log(`REFRESHING AFTER SUCCESS MARKET OPEN REQUEST`);
            await this.lexStore.refreshDataAfterTx(true);
          })
          .catch((e: Error) => {
            console.log("CAUGHT INSIDE");
            toastError(e.toString());
          });

        txHash = tx.hash;
      }
    } else if (actionType == EoaActionTypes.SIGNED) {
      const requestBuildingInfo = await this.getTradeIntentRequestBuildingInfo(
        expectedPositionId,
        Number(TradeIntentsVerifierActionsEnums.REQUEST_POSITION_OPEN),
      );

      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;
      const typedDomainData = this.generateTradeIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );
      const types =
        tradeIntentsVerifierPayloadTypes.TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_OPEN_POSITION;

      const payload: TUserRequestPayload_OpenPositionStruct = {
        nonce: requestBuildingInfo.currentNonce,
        positionRequestIdentifiers: tradePairIdentifier,
        positionRequestParams: tradeRequestParams,
        orderType: openOrderType,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      this.lexStore
        .getTradePairStoreById(pairId)
        ?.activeUserStore.addLivePositionsInPair({
          ...tradeRequestParams,
          trader: this.accountAddress,
          pairId: BigInt(pairId),
          settlementAsset: this.lexStore.chipAssetParams.address,
          positionIndex: BigInt(positionIndex),
          id: calculatePositionHashId(
            this.lexStore.chipAssetParams.address,
            this.accountAddress,
            pairId,
            positionIndex,
          ),
          phase: -1n,
          inPhaseSince: 0n,
          openPrice: 0n,
          spreadReductionF: 0n,
          tpLastUpdated: 0n,
          slLastUpdated: 0n,
          pendingUpdateOrderTimestamp: 0n,
          pendingUpdateOrderFieldAValue: 0n,
          pendingUpdateOrderFieldBValue: 0n,
          funding: 0n,
          borrowInterest: 0n,
          liquidationPrice: 0n,
          pendingUpdateOrderType: 0n,
        });

      // console.log(payload);
      // console.log(stringifyObject(payload));
      // console.log(signature);

      // Preflight static call to ensure tx passes
      // await this.lexStore.freshTradeIntentsVerifierService.staticCall_delegateRequestPositionOpen(
      //   payload,
      //   signature,
      //   domainHash,
      //   referrerHash,
      // );
      const returnParams: TPositionParams = {
        direction: isLong ? "long" : "short",
        leverage,
        settlementAsset: this.lexStore.chipAssetParams.symbol,
        action_type: EPositionActionType.open,
      };

      txHash = await this.handleFlowForDelegatedAction(
        `Request Position Open`,
        `${leverage}X ${isLong ? "long" : "short"} ${chipsBnToUnits(collateralAmount)} ${this.lexStore.sourceAssetParameters.symbol} for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateRequestPositionOpen(
            payload,
            signature,
            domainStr,
            referralStr,
          ),
        false,
        returnParams,
      );
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  public async requestMarketTradeClose(
    actionType: EoaActionTypes,
    positionId: BytesLike,
  ): Promise<TUserTxResponse> {
    let txHash = "";

    // Sanity
    if (positionId === ethers.ZeroHash) {
      toastError("Position Id cannot be 0");
      lifecycleIntegration.captureError(
        "requestMarketTradeClose -- Position Id cannot be 0",
      );
      return {
        txHash: "",
        type: actionType,
      };
    }

    // TODO : CRITICAL : use real values if not limited
    const minPrice = floatToPriceBn(0);
    const maxPrice = floatToPriceBn(100_000);

    if (actionType == EoaActionTypes.DIRECT) {
      const tx =
        await this.lexStore.freshTradingService.traderRequest_setExistingPositionToMarketClose(
          positionId,
          minPrice,
          maxPrice,
          this.lexStore.systemStore.nativeFeeForRequestMarketClose,
        );

      // DEV_NOTE : Refresh data after confirmation without waiting here
      tx.wait()
        .then(async () => {
          // this.analyticsStore.logMarketInteraction(
          //   "market_supply",
          //   this.cryptoWalletConnectionStore.mainAddress,
          //   cTokenAddress,
          //   amount
          // );

          await this.lexStore.refreshDataAfterTx();
        })
        .catch((e: Error) => {
          toastError(e.toString());
        });

      txHash = tx.hash;
    } else if (actionType == EoaActionTypes.SIGNED) {
      const requestBuildingInfo = await this.getTradeIntentRequestBuildingInfo(
        positionId,
        Number(TradeIntentsVerifierActionsEnums.REQUEST_POSITION_MARKET_CLOSE),
      );

      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;
      const typedDomainData = this.generateTradeIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );

      const types =
        tradeIntentsVerifierPayloadTypes.TRADER_REQUEST_PAYLOAD_CLOSE_MARKET;

      const payload: TUserRequestPayload_CloseMarketStruct = {
        nonce: requestBuildingInfo.currentNonce,
        positionId: positionId,
        minPrice: minPrice,
        maxPrice: maxPrice,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      // Preflight static call to ensure tx passes
      // await this.lexStore.freshTradeIntentsVerifierService.staticCall_delegateRequestMarketClose(
      //   payload,
      //   signature,
      // );
      const returnParams: TPositionParams = {
        direction: this.lexStore.getLivePositionById(positionId)?.long
          ? "long"
          : "short",
        leverage: scaledLeverageToUnits(
          Number(this.lexStore.getLivePositionById(positionId)?.leverage),
        ),
        settlementAsset: this.lexStore.chipAssetParams.symbol,
        action_type: EPositionActionType.close,
      };

      txHash = await this.handleFlowForDelegatedAction(
        `Requesting market close`,
        `${this.lexStore.sourceAssetParameters.symbol} position (${positionId.toString()}) for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateRequestMarketClose(
            payload,
            signature,
          ),
        false,
        returnParams,
      );
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  public async requestUpdatePositionField(
    actionType: EoaActionTypes,
    positionId: BytesLike,
    fieldToUpdate: TPositionField,
    fieldValueInUnits: number,
  ): Promise<TUserTxResponse> {
    let txHash = "";

    const fieldValueBn = floatToPriceBn(fieldValueInUnits);

    if (actionType == EoaActionTypes.DIRECT) {
      const tx =
        await this.lexStore.freshTradingService.traderRequest_updatePositionSingleField(
          positionId,
          fieldToUpdate,
          fieldValueBn,
          this.lexStore.systemStore.nativeFeeForRequestUpdateSingleField,
        );

      // DEV_NOTE : Refresh data after confirmation without waiting here
      tx.wait()
        .then(async () => {
          // this.analyticsStore.logMarketInteraction(
          //   "market_supply",
          //   this.cryptoWalletConnectionStore.mainAddress,
          //   cTokenAddress,
          //   amount
          // );

          await this.lexStore.refreshDataAfterTx();
        })
        .catch((e: Error) => {
          toastError(e.toString());
        });

      txHash = tx.hash;
    } else if (actionType === EoaActionTypes.SIGNED) {
      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;

      const requestBuildingInfo = await this.getTradeIntentRequestBuildingInfo(
        positionId,
        Number(
          TradeIntentsVerifierActionsEnums.REQUEST_POSITION_SINGLE_FIELD_UPDATE,
        ),
      );
      const typedDomainData = this.generateTradeIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );

      const types =
        tradeIntentsVerifierPayloadTypes.TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_UPDATE_POSITION_SINGLE_FIELD;

      const payload: TUserRequestPayload_UpdatePositionSingleFieldStruct = {
        nonce: requestBuildingInfo.currentNonce,
        positionId: positionId,
        fieldValue: fieldValueBn,
        orderType: fieldToUpdate,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      // Preflight static call to ensure tx passes
      // await this.lexStore.freshTradeIntentsVerifierService.staticCall_delegateRequestSingleFieldUpdate(
      //   payload,
      //   signature,
      // );
      const returnParams: TPositionParams = {
        direction: this.lexStore.getLivePositionById(positionId)?.long
          ? "long"
          : "short",
        leverage: scaledLeverageToUnits(
          Number(this.lexStore.getLivePositionById(positionId)?.leverage),
        ),
        settlementAsset: this.lexStore.chipAssetParams.symbol,
        action_type: EPositionActionType.update,
      };

      txHash = await this.handleFlowForDelegatedAction(
        `Requesting Single Field Update`,
        `${fieldToUpdate} for ${this.lexStore.sourceAssetParameters.symbol} position (${positionId.toString()}) for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateRequestUpdatePositionSingleField(
            payload,
            signature,
          ),
        false,
        returnParams,
      );
      positionToastmanager.updateToast(txHash, toast_step.success);
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  public async requestUpdateDoublePositionField(
    actionType: EoaActionTypes,
    positionId: BytesLike,
    tpValueInUnits: number,
    slValueInUnits: number,
  ): Promise<TUserTxResponse> {
    let txHash = "";

    const tpValueBn = floatToPriceBn(tpValueInUnits);
    const slValueBn = floatToPriceBn(slValueInUnits);

    if (actionType == EoaActionTypes.DIRECT) {
      const tx =
        await this.lexStore.freshTradingService.traderRequest_updatePositionDoubleField_tp_and_sl(
          positionId,
          tpValueBn,
          slValueBn,
          this.lexStore.systemStore.nativeFeeForRequestUpdateDoubleField,
        );

      // DEV_NOTE : Refresh data after confirmation without waiting here
      tx.wait()
        .then(async () => {
          // this.analyticsStore.logMarketInteraction(
          //   "market_supply",
          //   this.cryptoWalletConnectionStore.mainAddress,
          //   cTokenAddress,
          //   amount
          // );

          await this.lexStore.refreshDataAfterTx();
        })
        .catch((e: Error) => {
          toastError(e.toString());
        });

      txHash = tx.hash;
    } else if (actionType === EoaActionTypes.SIGNED) {
      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;

      const requestBuildingInfo = await this.getTradeIntentRequestBuildingInfo(
        positionId,
        Number(
          TradeIntentsVerifierActionsEnums.REQUEST_POSITION_DOUBLE_FIELD_UPDATE,
        ),
      );

      const typedDomainData = this.generateTradeIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );

      const types =
        tradeIntentsVerifierPayloadTypes.TRADER_REQUEST_PAYLOAD_TYPE_DEFINITION_UPDATE_POSITION_DOUBLE_FIELD;

      const payload: TUserRequestPayload_UpdatePositionDoubleFieldStruct = {
        nonce: requestBuildingInfo.currentNonce,
        positionId: positionId,
        orderType: UpdatePositionFieldOrderTypeEnums.UPDATE_TP_AND_SL,
        fieldValueA: tpValueBn,
        fieldValueB: slValueBn,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      // Preflight static call to ensure tx passes
      // await this.lexStore.freshTradeIntentsVerifierService.staticCall_delegateRequestDoubleFieldUpdate(
      //   payload,
      //   signature,
      // );
      const returnParams: TPositionParams = {
        direction: this.lexStore.getLivePositionById(positionId)?.long
          ? "long"
          : "short",
        leverage: scaledLeverageToUnits(
          Number(this.lexStore.getLivePositionById(positionId)?.leverage),
        ),
        settlementAsset: this.lexStore.chipAssetParams.symbol,
        action_type: EPositionActionType.update,
      };

      txHash = await this.handleFlowForDelegatedAction(
        `Requesting update for TP and SL`,
        `${this.lexStore.sourceAssetParameters.symbol} position (${positionId.toString()}) for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateRequestUpdatePositionDoubleField(
            payload,
            signature,
          ),
        false,
        returnParams,
      );
      positionToastmanager.updateToast(txHash, toast_step.success);
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  public async requestMarketOrderTimeout(
    positionId: BytesLike,
    isMarketOpen: boolean,
    onErrorAccoured?: () => void,
  ) {
    let txHash = "";

    if (isMarketOpen) {
      txHash = await this.handleFlowForDelegatedAction(
        `Requesting market open order timeout`,
        `${this.lexStore.sourceAssetParameters.symbol} position (${positionId.toString()}) for ${this.accountAddress}`,
        () =>
          this.lexStore.freshGeneralBaasInteractionService.timeoutMarketOpenOrder(
            positionId,
          ),
        true,
        {},
        onErrorAccoured,
      );
    } else {
      txHash = await this.handleFlowForDelegatedAction(
        `Requesting market close order timeout`,
        `${this.lexStore.sourceAssetParameters.symbol} position (${positionId.toString()}) for ${this.accountAddress}`,
        () =>
          this.lexStore.freshGeneralBaasInteractionService.timeoutMarketCloseOrder(
            positionId,
          ),
        true,
        {},
        onErrorAccoured,
      );
    }

    return txHash;
  }

  public async requestLimitOrderCancel(
    actionType: EoaActionTypes,
    positionId: BytesLike,
  ): Promise<TUserTxResponse> {
    const returnParams: TPositionParams = {
      direction: this.lexStore.getLivePositionById(positionId)?.long
        ? "long"
        : "short",
      leverage: scaledLeverageToUnits(
        Number(this.lexStore.getLivePositionById(positionId)?.leverage),
      ),
      settlementAsset: this.lexStore.chipAssetParams.symbol,
      action_type: EPositionActionType.update,
    };

    let txHash = "";

    if (actionType === EoaActionTypes.DIRECT) {
      throw new Error(`Direct cancellation not yet supporter`);
    } else {
      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;

      const requestBuildingInfo = await this.getTradeIntentRequestBuildingInfo(
        positionId,
        Number(
          TradeIntentsVerifierActionsEnums.DIRECT_CANCEL_PENDING_LIMIT_POSITION,
        ),
      );

      const typedDomainData = this.generateTradeIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );

      const types =
        tradeIntentsVerifierPayloadTypes.TRADER_DIRECT_PAYLOAD_TYPE_DEFINITION_CANCEL_PENDING_LIMIT_POSITION;

      const payload: TUserDirectPayload_CancelPendingLimitPositionStruct = {
        nonce: requestBuildingInfo.currentNonce,
        positionId,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      txHash = await this.handleFlowForDelegatedAction(
        `Requesting limit order cancellation`,
        `${this.lexStore.sourceAssetParameters.symbol} position (${positionId.toString()}) for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateRequestCancelPendingLimitPosition(
            payload,
            signature,
          ),
        false,
        returnParams,
      );
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  // **** Delegation Utils ****

  /**
   * Gets the request building info for a given position and action type
   * @param positionId
   * @param actionType
   * @private
   */
  private async getTradeIntentRequestBuildingInfo(
    positionId: BytesLike,
    actionType: number,
  ): Promise<THashBasedIntentsVerifierRequestBuildingInfoStruct> {
    let requestBuildingInfo;

    try {
      // Try reading from the lens
      requestBuildingInfo =
        await this.readTradeIntentRequestBuildingInfoFromLens(
          positionId,
          actionType,
        );
    } catch (e) {
      const error = `Failed reading intent building info : ${extractWeb3ErrorMessage(e)}`;

      let baasReadingResult = "";

      try {
        // Try to fetch the info from the baas
        requestBuildingInfo =
          await this.lexStore.freshIntentsDelegationService.requestTradeIntentRequestBuildingInfoFromServer(
            this.lexStore.engineChainId,
            positionId,
            actionType,
          );
        baasReadingResult = `Fetched intent building info from baas ${stringifyObject(requestBuildingInfo, 3)}`;
      } catch (baasReadingError) {
        baasReadingResult = `Failed reading intent building info from baas : ${extractWeb3ErrorMessage(baasReadingError)}`;
      }

      notifyDiscordServer(
        DISCORD_ENDPOINTS.appDelegationErrors,
        error,
        [
          {
            title: "Description",
            description: `account ${this.accountAddress} | actionType ${actionType} | SA ${this.lexStore.sourceAssetParameters.symbol}`,
          },
          {
            title: "Error",
            description: `${extractWeb3ErrorMessage(e).substring(0, 1000)}`,
          },
          {
            title: "Baas backup result",
            description: baasReadingResult,
          },
        ],
        "SingleLexStore",
        "intentRequestBuildingInfoRead",
        true,
      )
        .then(() => console.log(`Notified discord about error: ${error}`))
        .catch((e) =>
          console.error(`Error notifying discord about error: ${error}`),
        );

      if (!requestBuildingInfo) {
        throw new Error(error);
      }
    }

    return requestBuildingInfo;
  }

  private async readTradeIntentRequestBuildingInfoFromLens(
    positionId: BytesLike,
    actionType: number,
  ): Promise<THashBasedIntentsVerifierRequestBuildingInfoStruct> {
    const intentsVerifierLens = this.lexStore.freshIntentsVerifierLensService;

    const requestBuildingInfo =
      await intentsVerifierLens.getRequestBuildingInfoForTradeIntentsVerifier(
        positionId,
        actionType,
      );

    return requestBuildingInfo;
  }

  // **** Liquidity Contract Interactions ****

  public async epochDepositToPool(
    actionType: EoaActionTypes,
    depositAmountInUnits: number,
    minAmountOutInUnits: number,
  ): Promise<TUserTxResponse> {
    const depositAmount = floatToUnitsBn(
      depositAmountInUnits,
      this.lexStore.chipAssetParams.decimals,
    );

    const safeDepositAmount = bnRoundingUtils.floorPossiblyExtraDustAmount(
      this.account_underlyingInWalletOnEngineChain,
      depositAmount,
    );

    const referralDomain = ethers.ZeroHash;
    const referralCode = ethers.ZeroHash;

    let txHash: string;

    if (actionType == EoaActionTypes.DIRECT) {
      // TODO : Think about this variable (in the delegation we use 0)
      const minAmountOutBn = floatToUnitsBn(
        minAmountOutInUnits,
        this.lexStore.poolTokenDecimals,
      );

      const tx = await this.lexStore.freshLexPoolService.epochDeposit(
        safeDepositAmount,
        minAmountOutBn,
        referralDomain,
        referralCode,
      );

      // DEV_NOTE : Refresh data after confirmation without waiting here
      tx.wait()
        .then(async () => {
          // this.analyticsStore.logMarketInteraction(
          //   "market_supply",
          //   this.cryptoWalletConnectionStore.mainAddress,
          //   cTokenAddress,
          //   amount
          // );

          await this.lexStore.refreshDataAfterTx();
        })
        .catch((e: Error) => {
          toastError(e.toString());
        });

      txHash = tx.hash;
    } else {
      const userAddress = this.lexStore.cryptoWalletConnectionStore.mainAddress;
      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;
      const intentsVerifierLens = this.lexStore.freshIntentsVerifierLensService;

      const requestBuildingInfo =
        await intentsVerifierLens.getRequestBuildingInfoForLiquidityIntentsVerifier(
          userAddress,
          Number(LiquidityIntentsVerifierActionsEnums.REQUEST_EPOCH_DEPOSIT),
        );

      const typedDomainData = this.generateLiquidityIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );
      const types =
        liquidityIntentsVerifierPayloadTypes.LIQUIDITY_PROVIDER_REQUEST_PAYLOAD_TYPE_DEFINITION_EPOCH_DEPOSIT;

      const payload: TLiquidityProviderRequestPayload_EpochDepositStruct = {
        nonce: requestBuildingInfo.currentNonce,
        pool: this.lexStore.poolAddress,
        liquidityProvider: userAddress,
        epoch: this.lexStore.currentEpochNumber,
        amount: safeDepositAmount,
        minAmountOut: 0n,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      // console.log(`chain id ${chainId}`);
      // console.log(payload);
      // console.log(stringifyObject(payload));
      // console.log(signature);

      const returnParams: TPositionParams = {
        amount: String(depositAmountInUnits),
        settlementAsset: this.lexStore.chipAssetParams.symbol,
        action_type: EPositionActionType.liquiditySupply,
      };

      txHash = await this.handleFlowForDelegatedAction(
        `Requesting deposit to pool`,
        `${this.lexStore.sourceAssetParameters.symbol} for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateLiquidityRequest_epochDeposit(
            payload,
            signature,
          ),
        false,
        returnParams,
      );
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  public async epochRedeemToPool(
    actionType: EoaActionTypes,
    lpRedeemAmountInUnits: number,
    minAssetAmountOutInUnits: number,
  ): Promise<TUserTxResponse> {
    const redeemAmount = floatToUnitsBn(
      lpRedeemAmountInUnits,
      this.lexStore.poolTokenDecimals,
    );
    const minAssetAmountOutBn = floatToUnitsBn(
      minAssetAmountOutInUnits,
      this.lexStore.chipAssetParams.decimals,
    );

    let txHash: string;

    if (actionType === EoaActionTypes.DIRECT) {
      const tx = await this.lexStore.freshLexPoolService.epochRedeemByLxToken(
        redeemAmount,
        minAssetAmountOutBn,
      );

      // DEV_NOTE : Refresh data after confirmation without waiting here
      tx.wait()
        .then(async () => {
          // this.analyticsStore.logMarketInteraction(
          //   "market_supply",
          //   this.cryptoWalletConnectionStore.mainAddress,
          //   cTokenAddress,
          //   amount
          // );

          await this.lexStore.refreshDataAfterTx();
        })
        .catch((e: Error) => {
          toastError(e.toString());
        });

      txHash = tx.hash;
    } else {
      const userAddress = this.lexStore.cryptoWalletConnectionStore.mainAddress;
      const chainId = this.lexStore.cryptoWalletConnectionStore.chainId;
      const intentsVerifierLens = this.lexStore.freshIntentsVerifierLensService;

      const requestBuildingInfo =
        await intentsVerifierLens.getRequestBuildingInfoForLiquidityIntentsVerifier(
          userAddress,
          Number(LiquidityIntentsVerifierActionsEnums.REQUEST_EPOCH_REDEEM),
        );

      const typedDomainData = this.generateLiquidityIntentTypedDomainData(
        requestBuildingInfo.verifier,
        chainId as TSourceChainIds,
      );
      const types =
        liquidityIntentsVerifierPayloadTypes.LIQUIDITY_PROVIDER_REQUEST_PAYLOAD_TYPE_DEFINITION_EPOCH_REDEEM;

      const payload: TLiquidityProviderRequestPayload_EpochRedeemStruct = {
        nonce: requestBuildingInfo.currentNonce,
        pool: this.lexStore.poolAddress,
        liquidityProvider: userAddress,
        epoch: this.lexStore.currentEpochNumber,
        amount: redeemAmount,
        minAmountOut: 0n,
      };

      const signature =
        await this.lexStore.cryptoWalletConnectionStore.askToSignTypedData(
          typedDomainData,
          types,
          payload,
        );

      // console.log(`chain id ${chainId}`);
      // console.log(payload);
      // console.log(stringifyObject(payload));
      // console.log(signature);
      const returnParams: TPositionParams = {
        amount: String(lpRedeemAmountInUnits),
        settlementAsset: this.lexStore.chipAssetParams.symbol,
        action_type: EPositionActionType.liquidityRemove,
      };

      txHash = await this.handleFlowForDelegatedAction(
        `Requesting redeem from pool`,
        `${this.lexStore.sourceAssetParameters.symbol} for ${this.accountAddress}`,
        () =>
          this.lexStore.freshIntentsDelegationService.delegateLiquidityRequest_epochRedeem(
            payload,
            signature,
          ),
        false,
        returnParams,
      );
    }

    return {
      txHash: txHash,
      type: actionType,
    };
  }

  // **** Delegation Action  ****

  private async handleFlowForDelegatedAction(
    actionName: string,
    actionDescription: string,
    delegationRequestFunction: () => Promise<TDelegatedActionResponse>,
    isCancellRequest?: boolean,
    positionParams?: TPositionParams,
    onErrorAccoured?: () => void,
  ): Promise<string> {
    const txHash = await handleDelegatedIntentUxFlow(
      actionName,
      actionDescription,
      delegationRequestFunction,
      this.accountAddress,
      isCancellRequest,
      positionParams,
      onErrorAccoured,
    );
    await this.lexStore.refreshDataAfterTx(true);

    return txHash;
  }

  // **** Outer Updating ****
  public async readAllDataForStore() {
    if (
      this.accountAddress &&
      !isSameAddress(this.accountAddress, ethers.ZeroAddress)
    ) {
      await this.batchReadAndUpdateTradePairStoresWithAccountTrades().catch(
        (e: Error) =>
          console.error(
            `Failed readNSet batch Traders trades state in pairs state ${e.toString()}`,
          ),
      );

      await this.batchReadAndUpdateWithFreshSupplierSpecificState().catch(
        (e: Error) =>
          console.error(
            `Failed lexStore::updateWithFreshSupplierSpecificState ${e.toString()}`,
          ),
      );

      await this.updateWithChipsActionsHistory().catch((e: Error) =>
        console.error(
          `Failed lexStore::updateWithChipsActionsHistory ${e.toString()}`,
        ),
      );

      await this.readAndSetPendingRewards().catch((e) =>
        console.error(`Failed readAndSetPendingRewards ${e}`),
      );
    }
  }

  public updatePoolSupplierState(
    poolSupplierStateFromLens: TLexPoolSupplierLensStateStruct,
  ) {
    this.setPoolSupplierState(poolSupplierStateFromLens);
  }

  // **** Account Trading Computed State  ****

  @computed public get hasAnyAllowanceForCenter(): boolean {
    return this.allowanceForCenter > 0n;
  }

  @computed public get allowanceForCenterInUnits(): number {
    return unitsBnToFloat(
      this.allowanceForCenter,
      this.lexStore.chipAssetParams.decimals,
    );
  }

  @computed public get allLivePositions(): TCompletePositionDataFromLens[] {
    const trades: TCompletePositionDataFromLens[] = [];
    for (const tradePairStore of this.lexStore.tradePairsStores) {
      trades.push(
        ...tradePairStore.getUserStore(this.accountAddress).livePositionsInPair,
      );
    }

    return trades;
  }

  @computed
  public get activeAccount_allClosedPositions(): TClosedPositionGist[] {
    const closedPositionGists: TClosedPositionGist[] = [];
    for (const tradePairStore of this.lexStore.tradePairsStores) {
      closedPositionGists.push(
        ...tradePairStore.getUserStore(this.accountAddress).closedPositionGists,
      );
    }

    return closedPositionGists;
  }

  @computed
  public get activeAccount_allCancelledPositions(): TClosedPositionGist[] {
    const cancelledPositionGists: TClosedPositionGist[] = [];
    for (const tradePairStore of this.lexStore.tradePairsStores) {
      cancelledPositionGists.push(
        ...tradePairStore.getUserStore(this.accountAddress)
          .cancelledPositionGists,
      );
    }

    return cancelledPositionGists;
  }

  // **** Account Supply Computed State  ****

  @computed
  public get accountUnderlyingBalanceInWalletOnEngineChainInUnits(): number {
    return parseFloat(
      ethers.formatUnits(
        this.account_underlyingInWalletOnEngineChain,
        this.lexStore.poolTokenDecimals,
      ),
    );
  }

  @computed
  public get accountUnderlyingBalanceInWalletOnSourceChainInUnits(): number {
    return parseFloat(
      ethers.formatUnits(
        this.account_underlyingInWalletOnSourceChain,
        this.lexStore.sourceAssetParameters.decimals,
      ),
    );
  }

  @computed
  public get accountUnderlyingBalanceInOpenPositionsInUnits(): number {
    return this.allLivePositions.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        unitsBnToFloat(currentValue.collateral, this.lexStore.poolTokenDecimals)
      );
    }, 0);
  }

  @computed
  public get accountSupplyInUnits(): number {
    return parseFloat(
      ethers.formatUnits(
        this.account_lxTokenBalanceWorthOfUnderlying,
        this.lexStore.chipAssetParams.decimals,
      ),
    );
  }

  // @computed
  // public get accountSupplyInBn(): bigint {
  //   return this.balanceOfUnderlying;
  // }

  @computed
  public get accountSupplyInLxTokensRaw(): bigint {
    return this.accountLxTokenBalance;
  }

  @computed
  public get accountSupplyInLxTokensUnits(): number {
    return parseFloat(
      ethers.formatUnits(
        this.accountSupplyInLxTokensRaw,
        this.lexStore.poolTokenDecimals,
      ),
    );
  }

  @computed
  public get accountSupplyBalanceUsd(): number {
    return this.accountSupplyInUnits * this.lexStore.underlyingUsdPrice;
  }

  @computed public get hasAnyAllowance(): boolean {
    return this.account_allowanceForPool > 0n;
  }

  @computed public get accountAllowanceForPool(): bigint {
    return this.account_allowanceForPool;
  }

  @computed public get accountAllowanceInUnits(): number {
    return parseFloat(
      ethers.formatUnits(
        this.account_allowanceForPool,
        this.lexStore.chipAssetParams.decimals,
      ),
    );
  }

  @computed public get accountPartInSupplySide(): number {
    return divideOrZeroBn(
      this.accountLxTokenBalance,
      this.lexStore.totalSupplyRaw,
    );
  }

  // **** Graph Reading ****

  @computed public get shouldReadClosedPositionsFromGraph(): boolean {
    const latestTimestamp =
      this.interactionsRecord.latestTimestamp_PositionClosed;
    const positionEntityId =
      this.interactionsRecord.interactionEntityId_PositionClosed;

    if (latestTimestamp == 0 && !positionEntityId) {
      return false;
    }

    const latestKnownClosedTimestamp = Math.max(
      ...this.activeAccount_allClosedPositions.map((p) => p.closeTimestamp),
    );

    // Is latest closed position already known to us ?
    const existingEntry = this.activeAccount_allClosedPositions.find(
      (e) => e.entityId == positionEntityId,
    );

    const shouldRead =
      existingEntry == undefined ||
      latestKnownClosedTimestamp < latestTimestamp;

    // If the latest entity does not exist in memory, we should read
    return shouldRead;
  }

  @computed public get shouldReadCancelledPositionsFromGraph(): boolean {
    const latestTimestamp =
      this.interactionsRecord.latestTimestamp_MarketOpenCancelled;
    const positionEntityId =
      this.interactionsRecord.interactionEntityId_MarketOpenCancelled;

    if (latestTimestamp == 0 && !positionEntityId) {
      return false;
    }

    const latestKnownCancelledTimestamp = Math.max(
      ...this.activeAccount_allCancelledPositions.map((p) => p.closeTimestamp),
    );

    // Is latest closed position already known to us ?
    const existingEntry = this.activeAccount_allCancelledPositions.find(
      (e) => e.entityId == positionEntityId,
    );

    // If the latest entity does not exist in memory, we should read
    return (
      existingEntry == undefined ||
      latestKnownCancelledTimestamp < latestTimestamp
    );
  }

  @computed public get shouldReadLiquidityProviderHistoryFromGraph(): boolean {
    const highestProcessEpoch = Math.max(
      this.interactionsRecord.interactionEpochNumber_PoolImmediateDeposit,
      this.interactionsRecord.interactionEpochNumber_PoolEpochDepositProcessed,
      this.interactionsRecord.interactionEpochNumber_PoolEpochRedeemProcessed,
    );

    if (highestProcessEpoch == 0) {
      return false;
    }

    // Is action from the highest process epoch known to us ?
    const existingEntry = this.account_processedLiquidityRequests.find(
      (plr) => plr.processEpoch == highestProcessEpoch,
    );

    // If the latest entity does not exist in memory, we should read
    return existingEntry == undefined;
  }

  @computed public get shouldReadChipActionHistoryFromGraph(): boolean {
    const txHashOfLatestChipAction =
      this.interactionsRecord.latestTimestamp_ChipIn >=
      this.interactionsRecord.latestTimestamp_ChipOut
        ? this.interactionsRecord.interactionTx_ChipIn
        : this.interactionsRecord.interactionTx_ChipOut;

    if (txHashOfLatestChipAction == "") {
      return false;
    }

    // Is action from the highest process epoch known to us ?
    const existingEntry = this.account_chipHistoryActions.find((cha) =>
      isSameTxHash(cha.txHash, txHashOfLatestChipAction),
    );

    // If the latest entity does not exist in memory, we should read
    return existingEntry == undefined;
  }

  // **** Batch Setters ****

  private async batchReadAndUpdateTradePairStoresWithAccountTrades() {
    const allIds = this.lexStore.supportedPairIds;

    if (!allIds.length) {
      return console.log(`No supported pair ids for Lex ${this.lexStore.id}`);
    }

    const allLivePositionsFromAllPairs =
      await this.lexStore.freshTradeCenterLensService.getAllTradesForTraderInDimension(
        this.lexStore.tradingFloorProxyAddress,
        this.lexStore.chipAssetParams.address,
        this.accountAddress,
      );

    // Note : Only reads closed positions if there are new entries
    const closedPositions: TClosedPositionGist[] = this
      .shouldReadClosedPositionsFromGraph
      ? await this.safeReadClosedPositions()
      : [];

    // Note : Only reads cancelled positions if there are new entries
    const cancelledPositions: TClosedPositionGist[] = this
      .shouldReadCancelledPositionsFromGraph
      ? await this.safeReadCancelledPositions()
      : [];

    const tradesAndOrdersForPairs = this.divideTraderPositionsById(
      allLivePositionsFromAllPairs,
      closedPositions,
      cancelledPositions,
    );

    for (const tradesAndOrdersForPair of tradesAndOrdersForPairs) {
      const pairId = tradesAndOrdersForPair.pairId;

      // Note : Skip empty trades structs
      if (pairId === 0) {
        continue;
      }

      const matchingPairTradeStore =
        this.lexStore.getTradePairStoreById(pairId);

      if (matchingPairTradeStore) {
        matchingPairTradeStore.updateWithFreshTraderSpecificState(
          this.accountAddress,
          tradesAndOrdersForPair.livePositions,
          tradesAndOrdersForPair.closedPositions,
          tradesAndOrdersForPair.cancelledPositions,
        );
      } else {
        console.warn(
          `No matchingPairTradeStore found with pair id of ${pairId}`,
        );
      }
    }
  }

  private async readAndSetPendingRewards() {
    try {
      const pendingRewards =
        await this.lexStore.freshGeneralTokenDispenserService.getPendingAmountForAccount(
          this.lexStore.chipAssetParams.address,
          this.accountAddress,
        );

      this.setPendingDispenserRewards(pendingRewards);
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Failed readAndSetPendingRewards ${e}`);
    }
  }

  public async batchReadAndUpdateWithFreshSupplierSpecificState() {
    const lensInstance = this.lexStore.freshLexLensService;

    await this.readAndSetPoolSupplierState(lensInstance).catch((e) =>
      console.error(`Failed readAndSetPoolSupplierState ${e}`),
    );

    await this.readAndSetSourceChainUserState().catch((e) =>
      console.error(`Failed readAndSetSourceChainUserState ${e}`),
    );

    await this.readAndSetLiquidityProviderHistory().catch((e) =>
      console.error(`Failed readAndSetLiquidityProviderHistory ${e}`),
    );
  }

  public async updateWithChipsActionsHistory() {
    try {
      const chipsActionsHistoryGists = this.shouldReadChipActionHistoryFromGraph
        ? await this.safeReadChipsActionsHistory()
        : this.account_chipHistoryActions;

      this.setChipHistoricActions(chipsActionsHistoryGists);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Failed readAndSetChipHistoryGists ${e}`);
    }
  }

  // **** Read-n-Sets ****

  private async readAndSetPoolSupplierState(lensInstance: ILexLensService) {
    const accountAddress = this.accountAddress;

    const poolSupplierState = await lensInstance.getLexPoolSupplierState(
      this.lexStore.poolAddress,
      accountAddress,
    );

    this.updatePoolSupplierState(poolSupplierState);

    // TODO : Make more organised
    try {
      const allowance =
        await this.lexStore.freshSettlementAssetErc20Service.readAllowance(
          accountAddress,
          this.lexStore.tradingFloorProxyAddress,
        );
      this.setAllowanceForCenter(allowance);
    } catch (e: unknown) {
      console.error(
        `Failed readNSet trader allowance for center ${e?.toString()}`,
      );
    }
  }

  private async readAndSetSourceChainUserState() {
    const accountAddress = this.accountAddress;
    try {
      let balanceOnSource = 0n;

      if (
        this.lexStore.chipMode == ChipModeEnums.LOCAL &&
        this.lexStore.isWrappedNative
      ) {
        // TODO : CRITICAL : Add logic to read native balance
      } else {
        balanceOnSource =
          await this.lexStore.freshSourceChainAssetErc20Service.readBalance(
            accountAddress,
          );
      }
      this.setBalanceOnSourceChain(balanceOnSource);
    } catch (e: unknown) {
      console.error(
        `Failed readNSet trader balance on source chain for lex ${
          this.lexStore.id
        } ${e?.toString()}`,
      );
    }

    try {
      if (this.lexStore.chipMode == ChipModeEnums.REMOTE) {
        const allowanceForChipAdapter =
          await this.lexStore.freshSourceChainAssetErc20Service.readAllowance(
            accountAddress,
            this.lexStore.oftChipAdapterOnSourceAddress,
          );
        this.setAllowanceForAdapterOnSourceChain(allowanceForChipAdapter);
      } else if (
        this.lexStore.chipMode == ChipModeEnums.LOCAL &&
        !this.lexStore.isWrappedNative
      ) {
        const allowanceForChipOnEngineChain =
          await this.lexStore.freshSourceChainAssetErc20Service.readAllowance(
            accountAddress,
            this.lexStore.chipAssetParams.address,
          );
        this.setAllowanceForChipOnEngineChain(allowanceForChipOnEngineChain);
      }
    } catch (e: unknown) {
      console.error(
        `Failed readNSet trader allowance for adapter on source chain for lex ${
          this.lexStore.id
        } ${e?.toString()}`,
      );
    }

    this.setSourceChainInfoRead();
  }

  private async readAndSetLiquidityProviderHistory() {
    const accountAddress = this.accountAddress;
    try {
      // Note : Only reads liquidity history if there are new entries
      const liquidityRequestsGists = this
        .shouldReadLiquidityProviderHistoryFromGraph
        ? await this.safeReadLiquidityRequestsHistory(accountAddress)
        : this.account_processedLiquidityRequests;

      this.setProcessedLiquidityRequests(liquidityRequestsGists);
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Failed readAndSetLiquidityProviderHistory ${e}`);
    }
  }

  // **** Graph interactions ****
  private async safeReadClosedPositions(): Promise<TClosedPositionGist[]> {
    const closedPositions: TClosedPositionGist[] = [];

    if (this.lexStore.hasGraphUrl) {
      const graphService = new GraphQLService(this.lexStore.graphUrl);
      const closedTradesFromGraph =
        await graphService.getClosedPositionsForTrader(
          this.lexStore.chipAssetParams.address,
          this.accountAddress,
        );
      closedPositions.push(...closedTradesFromGraph);
    }

    return closedPositions;
  }

  private async safeReadCancelledPositions(): Promise<TClosedPositionGist[]> {
    const cancelledPositions: TClosedPositionGist[] = [];

    if (this.lexStore.hasGraphUrl) {
      const graphService = new GraphQLService(this.lexStore.graphUrl);
      const closedTradesFromGraph =
        await graphService.getCancelledPositionsForTrader(
          this.lexStore.chipAssetParams.address,
          this.accountAddress,
        );
      cancelledPositions.push(...closedTradesFromGraph);
    }

    return cancelledPositions;
  }

  private async safeReadLiquidityRequestsHistory(
    providerAddress: string,
  ): Promise<TLiquidityRequestGist[]> {
    const liquidityRequestsGists: TLiquidityRequestGist[] = [];

    if (this.lexStore.hasGraphUrl) {
      const graphService = new GraphQLService(this.lexStore.graphUrl);
      const processedLiquidityRequestsFromGraph =
        await graphService.getProcessedLiquidityRequests(
          this.lexStore.poolAddress,
          providerAddress,
        );
      const cancelledLiquidityRequestsFromGraph =
        await graphService.getCancelledLiquidityRequests(
          this.lexStore.poolAddress,
          providerAddress,
        );
      const liquidityRequestsFromGraph = [
        ...processedLiquidityRequestsFromGraph,
        ...cancelledLiquidityRequestsFromGraph,
      ];
      liquidityRequestsGists.push(...liquidityRequestsFromGraph);
    }

    return liquidityRequestsGists;
  }

  private async safeReadChipsActionsHistory(): Promise<
    TChipHistoryActionGist[]
  > {
    const chipHistoryActions: TChipHistoryActionGist[] = [];

    if (this.lexStore.hasGraphUrl) {
      const graphService = new GraphQLService(this.lexStore.graphUrl);
      const chipActionsHistoryFromGraph =
        await graphService.getChipActionHistory(
          this.lexStore.chipAssetParams.address,
          this.accountAddress,
        );
      chipHistoryActions.push(...chipActionsHistoryFromGraph);
    }

    return chipHistoryActions;
  }

  // **** Complex Setters ****

  @action("setPoolSupplierState")
  private setPoolSupplierState(
    poolSupplierStateFromLens: TLexPoolSupplierLensStateStruct,
  ) {
    this.accountLxTokenBalance = poolSupplierStateFromLens.lxpBalance;
    this.account_lxTokenBalanceWorthOfUnderlying =
      poolSupplierStateFromLens.lxpBalanceInUnderlying;
    this.account_underlyingInWalletOnEngineChain =
      poolSupplierStateFromLens.underlyingBalance;
    this.account_allowanceForPool =
      poolSupplierStateFromLens.allowanceForLexPool;

    // Epoch Mints&Redeems
    const validPendingEpochDeposits =
      ethersStructResponseToArray<TPendingEpochDepositLensStruct>(
        poolSupplierStateFromLens.pendingEpochDeposits.filter(
          (ped) => ped.amount > 0n,
        ),
        EMPTY_PENDING_EPOCH_DEPOSIT,
      );
    const validPendingEpochRedeems =
      ethersStructResponseToArray<TPendingEpochRedeemLensStruct>(
        poolSupplierStateFromLens.pendingEpochRedeems.filter(
          (per) => per.amount > 0n,
        ),
        EMPTY_PENDING_EPOCH_REDEEM,
      );

    this.account_pendingEpochDeposits.replace(
      validPendingEpochDeposits.map((ped) => {
        const pendingEpochDeposit: TPendingEpochDepositComplete = {
          underlyingSymbol: this.lexStore.sourceAssetParameters.symbol,

          amount: ped.amount,
          account: ped.account,
          epochNumber: Number(ped.epochNumber),
          minAmountOut: ped.minAmountOut,

          amountInUnits: unitsBnToFloat(
            ped.amount,
            this.lexStore.chipAssetParams.decimals,
          ),
          minAmountOutInUnits: unitsBnToFloat(
            ped.minAmountOut,
            this.lexStore.poolTokenDecimals,
          ),

          poolCurrentEpoch: this.lexStore.currentEpochNumber,
        };

        return pendingEpochDeposit;
      }),
    );
    this.account_pendingEpochRedeems.replace(
      validPendingEpochRedeems.map((per) => {
        const pendingEpochRedeem: TPendingEpochRedeemComplete = {
          underlyingSymbol: this.lexStore.sourceAssetParameters.symbol,

          amount: per.amount,
          account: per.account,
          epochNumber: Number(per.epochNumber),
          minAmountOut: per.minAmountOut,
          maxAmountOut: per.maxAmountOut,

          lpAmountInUnits: unitsBnToFloat(
            per.amount,
            this.lexStore.chipAssetParams.decimals,
          ),
          lpAmountInUnderlyingUnitsByCurrentExchangeRate: unitsBnToFloat(
            this.lexStore.lpTokensAmountToUnderlyingAmount(per.amount),
            this.lexStore.chipAssetParams.decimals,
          ),
          minAmountOutInUnits: unitsBnToFloat(
            per.minAmountOut,
            this.lexStore.poolTokenDecimals,
          ),
          maxAmountOutInUnits: unitsBnToFloat(
            per.maxAmountOut,
            this.lexStore.poolTokenDecimals,
          ),
        };

        return pendingEpochRedeem;
      }),
    );
  }

  @action("setChipHistoricActions")
  public setChipHistoricActions(historicActions: TChipHistoryActionGist[]) {
    this.account_chipHistoryActions.replace(historicActions);
  }

  @action("setProcessedLiquidityRequests")
  public setProcessedLiquidityRequests(
    processedLiquidityRequests: TLiquidityRequestGist[],
  ) {
    this.account_processedLiquidityRequests.replace(processedLiquidityRequests);
  }

  // **** Setters ****

  @action("setAllowanceForCenter")
  public setAllowanceForCenter(allowance: bigint): void {
    this.allowanceForCenter = allowance;
  }

  @action("setBalanceOnSourceChain")
  public setBalanceOnSourceChain(balance: bigint): void {
    this.account_underlyingInWalletOnSourceChain = balance;
  }

  @action("setAllowanceForAdapterOnSourceChain")
  public setAllowanceForAdapterOnSourceChain(allowance: bigint): void {
    this.account_allowanceForAdapterOnSourceChain = allowance;
  }

  @action("setAllowanceForChipOnEngineChain")
  public setAllowanceForChipOnEngineChain(allowance: bigint): void {
    this.account_allowanceForChipOnEngineChain = allowance;
  }

  @action("setSourceChainInfoRead")
  public setSourceChainInfoRead(): void {
    this.account_sourceChainInfoLoaded = true;
  }

  @action("setPendingDispenserRewards")
  public setPendingDispenserRewards(pendingDispensation: bigint): void {
    this.account_pendingDispenserRewards = pendingDispensation;
  }

  @action("setInteractionsRecord")
  public setInteractionsRecord(
    interactionsRecord: TUserInteractionsRecordGraphInfo,
  ) {
    this.interactionsRecord = interactionsRecord;
  }

  // **** Private utils ****

  private divideTraderPositionsById(
    livePositions: TCompletePositionDataFromLens[],
    closedPositions: TClosedPositionGist[],
    cancelledPositions: TClosedPositionGist[],
  ): TGroupedTraderPositionsInPair[] {
    // First, get all pair ids involved
    const rawPairsArray = [
      ...livePositions.map((t) => Number(t.pairId)),
      ...closedPositions.map((cp) => cp.pairId),
      ...cancelledPositions.map((cp) => cp.pairId),
    ];
    const allPairIdsSet = new Set(rawPairsArray);
    const allPairIds = Array.from(allPairIdsSet);

    const tradesAndOrders: TGroupedTraderPositionsInPair[] = [];

    // Group By pair ids
    const livePositionsGrouped = groupBy(livePositions, (item) =>
      item.pairId.toString(),
    );

    const finishedPositionsGrouped = groupBy(closedPositions, (item) =>
      item.pairId.toString(),
    );

    const cancelledPositionsGrouped = groupBy(cancelledPositions, (item) =>
      item.pairId.toString(),
    );

    for (const tradePairId of allPairIds) {
      const matchingLivePositions = livePositionsGrouped[tradePairId] || [];
      // const matchingClosedTrades = closedTradesGrouped[tradePairId] || [];
      const matchingClosedTrades = finishedPositionsGrouped[tradePairId] || [];

      const matchingCancelledPositions =
        cancelledPositionsGrouped[tradePairId] || [];

      tradesAndOrders.push({
        pairId: tradePairId,
        livePositions: matchingLivePositions,
        closedPositions: matchingClosedTrades,
        cancelledPositions: matchingCancelledPositions,
      });
    }

    return tradesAndOrders;
  }
}
