function calculateSlInUnits(
  openPriceInUnits: number,
  slPriceInUnits: number,
  leverageInUnits: number,
) {
  return calculateLimitInUnits(
    openPriceInUnits,
    slPriceInUnits,
    leverageInUnits,
  );
}

function calculateTpInUnits(
  openPriceInUnits: number,
  tpPriceInUnits: number,
  leverageInUnits: number,
) {
  return calculateLimitInUnits(
    openPriceInUnits,
    tpPriceInUnits,
    leverageInUnits,
  );
}

function calculateLimitInUnits(
  openPriceInUnits: number,
  limitPriceInUnits: number,
  leverageInUnits: number,
) {
  const priceDiff = Math.abs(openPriceInUnits - limitPriceInUnits);

  const diffFraction = priceDiff / openPriceInUnits;

  const leveragedDiffFraction = diffFraction * leverageInUnits;

  return leveragedDiffFraction;
}

export const limitsCalculations = {
  calculateSlInUnits,
  calculateTpInUnits,
};
