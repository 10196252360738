import { action, computed, makeObservable, observable } from "mobx";
import type { BytesLike } from "ethers";
import { PositionFieldEnums } from "../../constants/contractEnums";
import type { TPositionField } from "../../constants/contractEnums";

type TModalIds =
  | "REQUEST_MARKET_OPEN_MODAL"
  | "REQUEST_MARKET_CLOSE_MODAL"
  | "TUTORIAL_MODAL"
  | "CHAIN_SELECTION"
  | "ACCOUNT_SELECTION"
  | "POSITION_UPDATE"
  | "POSITION_SHARE"
  | "POSITION_INFO"
  | "CHIP_IN"
  | "CHIP_OUT"
  | "LEADER_BOARD";
type TCurrentModalIds = TModalIds | "NONE";

export class ModalsStore {
  @observable currentModalId: TCurrentModalIds = "NONE";
  @observable initialTutorialStep = 0;

  // **** Update Position Modal fields ****
  @observable positionIdToUpdate: BytesLike = "";

  // **** Position Share Modal fields ****
  @observable positionShareModal_liveOrHistoricPositionId: BytesLike = "";
  @observable positionShareModal_isLivePosition = false;

  // **** Position Info Modal fields ****
  @observable positionInfoModal_liveOrHistoricPositionId: BytesLike = "";
  @observable positionInfoModal_isLivePosition = false;

  // **** Chip In/Out Modal fields ****
  @observable chipInOrOutModal_lexId = "";

  // **** Leader Board Modal fields ****
  @observable leaderboardModal_lexId = "";

  @computed get isRequestMarketOpenModalActive(): boolean {
    return this.currentModalId === "REQUEST_MARKET_OPEN_MODAL";
  }

  @computed get isRequestMarketCloseModalActive(): boolean {
    return this.currentModalId === "REQUEST_MARKET_CLOSE_MODAL";
  }

  @computed get isTutorialModalActive(): boolean {
    return this.currentModalId === "TUTORIAL_MODAL";
  }

  @computed get isChainSelectionModalActive(): boolean {
    return this.currentModalId === "CHAIN_SELECTION";
  }

  @computed get isAccountSelectionModalActive(): boolean {
    return this.currentModalId === "ACCOUNT_SELECTION";
  }

  @computed get isPositionUpdateModalActive(): boolean {
    return this.currentModalId === "POSITION_UPDATE";
  }

  @computed get isPositionShareModalActive(): boolean {
    return this.currentModalId === "POSITION_SHARE";
  }

  @computed get isPositionInfoModalActive(): boolean {
    return this.currentModalId === "POSITION_INFO";
  }

  @computed get isChipInModalActive(): boolean {
    return this.currentModalId === "CHIP_IN";
  }

  @computed get isChipOutModalActive(): boolean {
    return this.currentModalId === "CHIP_OUT";
  }

  @computed get isLeaderboardModalActive(): boolean {
    return this.currentModalId === "LEADER_BOARD";
  }

  constructor() {
    makeObservable(this);
  }

  // **** Modal management ****

  public showTutorialModal(initialIndex = 1) {
    this.setInitialTutorialStep(Math.max(initialIndex - 1, 0));
    this.setCurrentModalId("TUTORIAL_MODAL");
  }

  public showChainSelectionModal() {
    this.setCurrentModalId("CHAIN_SELECTION");
  }

  public showAccountSelectionModal() {
    this.setCurrentModalId("ACCOUNT_SELECTION");
  }

  public showPositionUpdateModal(positionId: BytesLike) {
    this.setCurrentModalId("POSITION_UPDATE");
    this.setUpdatePositionModalValues(positionId);
  }

  public showPositionShareModal(
    positionId: BytesLike,
    isLivePosition: boolean,
  ) {
    this.setCurrentModalId("POSITION_SHARE");
    this.setPositionShareModalValues(positionId, isLivePosition);
  }

  public showPositionInfoModal(positionId: BytesLike, isLivePosition: boolean) {
    this.setCurrentModalId("POSITION_INFO");
    this.setPositionInfoModalValues(positionId, isLivePosition);
  }

  public showChipInModal(lexId: string) {
    this.setCurrentModalId("CHIP_IN");
    this.setChipInOrOutModalValues(lexId);
  }

  public showChipOutModal(lexId: string) {
    this.setCurrentModalId("CHIP_OUT");
    this.setChipInOrOutModalValues(lexId);
  }

  public showLeaderboardModal(lexId: string) {
    this.setCurrentModalId("LEADER_BOARD");
    this.setLeaderboardModalValues(lexId);
  }

  // **** Complex setters ****

  /**
   * Will only have any effect if the current modal is 'request_market_open'
   */
  public hideRequestMarketOpenModalModal() {
    if (this.isRequestMarketOpenModalActive) {
      this.setCurrentModalId("NONE");
    }
  }

  /**
   * Will only have any effect if the current modal is 'request_market_close'
   */
  public hideRequestMarketCloseModalModal() {
    if (this.isRequestMarketCloseModalActive) {
      this.setCurrentModalId("NONE");
    }
  }

  /**
   * Will only have any effect if the current modal is 'tutorial'
   */
  public hideTutorialModal() {
    if (this.isTutorialModalActive) {
      this.setInitialTutorialStep(0);
      this.setCurrentModalId("NONE");
    }
  }

  /**
   * Will only have any effect if the current modal is 'chainSelection'
   */
  public hideChainSelectionModal() {
    if (this.isChainSelectionModalActive) {
      this.setCurrentModalId("NONE");
    }
  }

  /**
   * Will only have any effect if the current modal is 'accountSelection'
   */
  public hideAccountSelectionModal() {
    if (this.isAccountSelectionModalActive) {
      this.setCurrentModalId("NONE");
    }
  }

  /**
   * Will only have any effect if the current modal is 'updatePosition'
   */
  public hidePositionUpdateModal() {
    if (this.isPositionUpdateModalActive) {
      this.setCurrentModalId("NONE");

      this.setUpdatePositionModalValues("");
    }
  }

  /**
   * Will only have any effect if the current modal is 'PositionShare'
   */
  public hidePositionShareModal() {
    if (this.isPositionShareModalActive) {
      this.setCurrentModalId("NONE");

      // Note : Prevent modal info flickering
      setTimeout(() => this.setPositionShareModalValues("", false), 150);
    }
  }

  /**
   * Will only have any effect if the current modal is 'PositionInfo'
   */
  public hidePositionInfoModal() {
    if (this.isPositionInfoModalActive) {
      this.setCurrentModalId("NONE");

      // Note : Prevent modal info flickering
      setTimeout(() => this.setPositionInfoModalValues("", false), 150);
    }
  }

  /**
   * Will only have any effect if the current modal is 'ChipIn'
   */
  public hideChipInModal() {
    if (this.isChipInModalActive) {
      this.setCurrentModalId("NONE");

      // Note : Prevent modal info flickering
      setTimeout(() => this.setChipInOrOutModalValues(""), 150);
    }
  }

  /**
   * Will only have any effect if the current modal is 'LeaderBoard'
   */
  public hideLeaderboardModal() {
    if (this.isLeaderboardModalActive) {
      this.setCurrentModalId("NONE");

      // Note : Prevent modal info flickering
      setTimeout(() => this.setLeaderboardModalValues(""), 150);
    }
  }

  /**
   * Will only have any effect if the current modal is 'ChipOut'
   */
  public hideChipOutModal() {
    if (this.isChipOutModalActive) {
      this.setCurrentModalId("NONE");

      // Note : Prevent modal info flickering
      setTimeout(() => this.setChipInOrOutModalValues(""), 150);
    }
  }

  // ****  Observables setter actions ****
  @action("setCurrentModalId")
  private setCurrentModalId(currentModalId: TCurrentModalIds) {
    this.currentModalId = currentModalId;
  }

  // ****  Specific Modal values ****

  @action("setAssetBaseAddress")
  private setInitialTutorialStep(initialTutorialStep: number) {
    this.initialTutorialStep = initialTutorialStep;
  }

  @action("setUpdatePositionModalValues")
  private setUpdatePositionModalValues(positionId: BytesLike) {
    this.positionIdToUpdate = positionId;
  }

  @action("setPositionShareModalValues")
  private setPositionShareModalValues(
    positionId: BytesLike,
    isLivePosition: boolean,
  ) {
    this.positionShareModal_liveOrHistoricPositionId = positionId;
    this.positionShareModal_isLivePosition = isLivePosition;
  }

  @action("setPositionInfoModalValues")
  private setPositionInfoModalValues(
    positionId: BytesLike,
    isLivePosition: boolean,
  ) {
    this.positionInfoModal_liveOrHistoricPositionId = positionId;
    this.positionInfoModal_isLivePosition = isLivePosition;
  }

  @action("setChipInOrOutModalValues")
  private setChipInOrOutModalValues(lexId: string) {
    this.chipInOrOutModal_lexId = lexId;
  }

  @action("setLeaderboardModalValues")
  private setLeaderboardModalValues(lexId: string) {
    this.leaderboardModal_lexId = lexId;
  }
}
