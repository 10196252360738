import {
  AccountBasedIntentsVerifierRequestBuildingInfoStruct,
  HashBasedIntentsVerifierRequestBuildingInfoStruct,
} from "../../../typechain/contracts/Peripheral/Lens/IntentsVerifierLens.ts";
import { TSafeBigNumberStruct } from "../../../utils/ethersTypes.ts";
import { BytesLike } from "ethers";

export type TAccountBasedIntentsVerifierRequestBuildingInfoStruct =
  TSafeBigNumberStruct<AccountBasedIntentsVerifierRequestBuildingInfoStruct>;
export type THashBasedIntentsVerifierRequestBuildingInfoStruct =
  TSafeBigNumberStruct<HashBasedIntentsVerifierRequestBuildingInfoStruct>;

export interface IIntentsVerifierLensService {
  getRequestBuildingInfoForTradeIntentsVerifier(
    positionId: BytesLike,
    actionType: number,
  ): Promise<THashBasedIntentsVerifierRequestBuildingInfoStruct>;

  getRequestBuildingInfoForLiquidityIntentsVerifier(
    account: string,
    actionType: number,
  ): Promise<TAccountBasedIntentsVerifierRequestBuildingInfoStruct>;

  getRequestBuildingInfoForChipsIntentsVerifier(
    account: string,
    actionType: number,
  ): Promise<TAccountBasedIntentsVerifierRequestBuildingInfoStruct>;
}

export const EMPTY_ACCOUNT_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO: TAccountBasedIntentsVerifierRequestBuildingInfoStruct =
  {
    verifier: "",
    account: "",
    actionType: 0n,
    currentNonce: 0n,
  };

export const EMPTY_HASH_BASED_INTENTS_VERIFIER_REQUEST_BUILDING_INFO: THashBasedIntentsVerifierRequestBuildingInfoStruct =
  {
    verifier: "",
    hash: "",
    actionType: 0n,
    currentNonce: 0n,
  };
