import { useCallback, useEffect, useState } from "react";
import { fetchRemoteJson } from "../services/web2NetworkActions.ts";

// export function useShouldBlockUser(): boolean {
//   const [shouldBlock, setShouldBlock] = useState(false);
//
//   const reactToGeoIp = useCallback(async () => {
//     const shouldBlockByIp = await readGeoLocationFromIp();
//     setShouldBlock(shouldBlockByIp);
//   }, []);
//
//   useEffect(() => {
//     reactToGeoIp().catch((e) => console.error(`Failed GeoIp reading : ${e}`));
//   }, [reactToGeoIp]);
//
//   return shouldBlock;
// }

// Note : There are more fields, we only need country code for now
type TIPAPIGeoLocationResponse = {
  location: {
    country: string;
    region: string;
    timezone: string;
  };
};

export async function readGeoLocationFromIp(): Promise<
  TIPAPIGeoLocationResponse | undefined
> {
  // IP-API
  const ipReadingUrl = `https://geo.ipify.org/api/v2/country?apiKey=at_HQcG8Hy1hke3eOW2jcmQJcBlBJrc5`;

  const res = await fetchRemoteJson<TIPAPIGeoLocationResponse>(ipReadingUrl);

  // console.log(`****** ip-api response : `);
  // console.log(res);

  if (res && res.location) {
    return res;
  } else {
    console.error(
      `IP-API did not return a valid response : ${JSON.stringify(
        res,
        null,
        2,
      )}`,
    );
    return undefined;
  }
}
