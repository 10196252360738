import { IOftChipAdapterService } from "./IOftChipAdapterService.ts";
import { Provider, Signer } from "ethers";
import { ContractTransactionResponse } from "ethers";
import {
  MessagingFeeStruct,
  OFTChipAdapter,
} from "../../../typechain/contracts/Lynx/Chips/OFTChip/OFTChipAdapter.ts";
import { OFTChipAdapter__factory } from "../../../typechain/factories/contracts/Lynx/Chips/OFTChip/OFTChipAdapter__factory.ts";
import { TSendParamStruct } from "./OFTTypes.ts";

export class OftChipAdapterService implements IOftChipAdapterService {
  private oftChipAdapterContract: OFTChipAdapter;

  constructor(
    address: string,
    private signerOrProvider: Signer | Provider,
  ) {
    this.oftChipAdapterContract = OFTChipAdapter__factory.connect(
      address,
      signerOrProvider,
    );
  }

  async chipIn(
    sendParam: TSendParamStruct,
    nativeFeeToPay: bigint,
    refundAddress: string,
  ): Promise<ContractTransactionResponse> {
    const messagingFeeStruct: MessagingFeeStruct = {
      nativeFee: nativeFeeToPay,
      lzTokenFee: 0n,
    };

    return this.oftChipAdapterContract.send(
      sendParam,
      messagingFeeStruct,
      refundAddress,
      {
        value: messagingFeeStruct.nativeFee,
      },
    );
  }

  async readNativeSendFee(sendParam: TSendParamStruct): Promise<bigint> {
    const payInZeroToken = false;
    const quoteRes = await this.oftChipAdapterContract.quoteSend(
      sendParam,
      payInZeroToken,
    );

    return quoteRes.nativeFee;
  }
}
