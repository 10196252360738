import { IPositionInformingService } from "./IPositionInformingService.ts";
import { TActionInformingEndpoints } from "../../../constants/externalServicesConstants.ts";

export class PositionInformingService implements IPositionInformingService {
  constructor(private actionInformingEndpoints: TActionInformingEndpoints) {}

  async informAboutMarketOpenRequest(positionId: string): Promise<void> {
    return await this.baseInform(
      this.actionInformingEndpoints.marketOpenInformUrl,
      positionId,
      "market_open",
    );
  }

  async informAboutMarketCloseRequest(positionId: string): Promise<void> {
    return await this.baseInform(
      this.actionInformingEndpoints.marketCloseInformUrl,
      positionId,
      "market_close",
    );
  }

  async informAboutUpdateSingleFieldRequest(positionId: string): Promise<void> {
    return await this.baseInform(
      this.actionInformingEndpoints.updateSingleFieldInformUrl,
      positionId,
      "update_single_field",
    );
  }

  async informAboutUpdateDoubleFieldRequest(positionId: string): Promise<void> {
    return await this.baseInform(
      this.actionInformingEndpoints.updateDoubleFieldInformUrl,
      positionId,
      "update_double_field",
    );
  }

  private async baseInform(
    functionUrl: string,
    positionId: string,
    actionName: string,
  ) {
    const urlWithQueryParams = `${functionUrl}?positionId=${positionId}`;

    const response = await fetch(urlWithQueryParams, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: "",
    });

    // Logging
    if (response.ok) {
      console.log(`inform about ${actionName} successful`);
    } else {
      console.error(`inform about ${actionName} failed`);
    }
  }
}
