import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { localStringer } from "../../../../ux/uxFunctions";
import {
  LIVE_CHAIN_CONSTANTS,
  TChainIds,
  TChainIdsWithNone,
  TSourceChainIds,
} from "../../../../constants/chainConstants.ts";
import {
  MaterialUiTable,
  Sortings,
  TableActionButton,
} from "lynx-ui-components";
import { Stack, Tooltip, Typography } from "@mui/material";
import { TokenIcon } from "../../../../components/buildingParts/icons/TokenIcon.tsx";
import { BlockChainIcon } from "../../../../components/buildingParts/icons/BlockChainIcon.tsx";
import { TPendingLZBridgeTxGist } from "../../pageHooks/chipsPageHooks.ts";
import { stringifyObject } from "../../../../utils/strings.ts";
import { makeStyles } from "tss-react/mui";
import { KEYFRAMES } from "../../../../theme/keyframes.ts";
import { THEME_CONSTANTS } from "../../../../theme/ThemeConstants.ts";
import { useLoadingBridgeTx } from "../../../../store/loadingBridgeTxContext.tsx";

const headerValuePays: Record<string, string> = {
  sourceChainId: "sourceChainId",
  sourceUnderlyingSymbol: "sourceUnderlyingSymbol",
  balanceAvailableOnEngineInUnits: "balanceAvailableOnEngineInUnits",
  balanceInPositionsInUnits: "balanceInPositionsInUnits",
  balanceInPoolInUnits: "balanceInPoolInUnits",
  totalBalance: "balanceAvailableOnEngineInUnits",
};

const sortings = new Sortings(headerValuePays);

export type TChipHolderInfoGist = {
  lexId: string;
  sourceChainId: TSourceChainIds;
  sourceUnderlyingSymbol: string;

  balanceAvailableOnEngineInUnits: number;
  balanceInPositionsInUnits: number;
  balanceInPoolInUnits: number;
};

interface IProps {
  chipHolderInfoGists: TChipHolderInfoGist[];
  connectedChainId: TChainIdsWithNone;

  showChipIn: (lexId: string) => void;
  showChipOut: (lexId: string) => void;

  isConnectedToWallet: boolean;
  requestConnectToWalletFunction: () => void;
  switchChainFunction: (chainId: TChainIds) => void;

  pendingBridgeTxGist?: TPendingLZBridgeTxGist[];
}

const ICON_TO_TEXT_GAP_REM = 2;

const useStyles = makeStyles()((theme) => ({
  inFlightBridgeTxContainer: {
    animation: `${KEYFRAMES.pulsingText} 3s infinite ease`,
  },
}));

export const ChipsInfoTable = React.memo<IProps>((props) => {
  const { classes } = useStyles();
  const {
    connectedChainId,
    chipHolderInfoGists,
    showChipIn,
    showChipOut,
    pendingBridgeTxGist,
    isConnectedToWallet,
    requestConnectToWalletFunction,
    switchChainFunction,
  } = props;
  const { loadingBridgeTxGists } = useLoadingBridgeTx();
  // console.log(stringifyObject(pendingBridgeTxGist ?? {}, 3));

  const columns: ColumnDef<TChipHolderInfoGist>[] = useMemo<
    ColumnDef<TChipHolderInfoGist>[]
  >(() => {
    return [
      {
        header: "Origin Chain",
        id: "sourceChainId",
        cell: (cellProps) => {
          return (
            <Stack
              direction={"row"}
              // gap={ICON_TO_TEXT_GAP_REM}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                flex={2}
                justifyContent={"center"}
                direction={"row"}
                // border={"1px solid red"}
              >
                <BlockChainIcon
                  symbol={LIVE_CHAIN_CONSTANTS[
                    cellProps.row.original.sourceChainId
                  ].shortDisplayName?.toUpperCase()}
                  iconSizeRem={2}
                />
              </Stack>
              <Stack
                flex={2}
                justifyContent={"start"}
                direction={"row"}
                // border={"1px solid blue"}
              >
                <Typography>
                  {
                    LIVE_CHAIN_CONSTANTS[cellProps.row.original.sourceChainId]
                      .shortDisplayName
                  }
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        header: "Asset",
        id: "sourceUnderlyingSymbol",
        enableSorting: true,
        cell: (props1) => {
          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              // gap={ICON_TO_TEXT_GAP_REM}
              justifyContent={"center"}
              // border={"1px solid yellow"}
              flex={1}
            >
              <Stack
                flex={2}
                justifyContent={"center"}
                direction={"row"}
                // border={"1px solid red"}
              >
                <TokenIcon
                  symbol={props1.cell.row.original.sourceUnderlyingSymbol}
                  iconSizeRem={2}
                />
              </Stack>
              <Stack
                flex={2}
                justifyContent={"start"}
                direction={"row"}
                // border={"1px solid blue"}
              >
                <Typography>
                  {props1.cell.row.original.sourceUnderlyingSymbol}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        header: "Available Balance",
        id: "balanceAvailableOnEngineInUnits",
        enableSorting: true,
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.balanceAvailableOnEngineInUnits)}`,
      },
      {
        header: "Utilized in Trades",
        id: "balanceInPositionsInUnits",
        enableSorting: true,
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.balanceInPositionsInUnits)}`,
      },
      {
        header: "Utilized in Liquidity Pool",
        id: "balanceInPoolInUnits",
        enableSorting: true,
        accessorFn: (originalRow) =>
          `${localStringer(originalRow.balanceInPoolInUnits)}`,
      },
      {
        header: "Total Account Balance",
        id: "totalBalance",
        enableSorting: true,
        accessorFn: (originalRow) =>
          `${localStringer(
            originalRow.balanceAvailableOnEngineInUnits +
              originalRow.balanceInPositionsInUnits +
              originalRow.balanceInPoolInUnits,
          )}`,
      },
      {
        header: "",
        id: "actions",
        enableSorting: true,
        cell: (props1) => {
          const activePendingBridgeTxGist = pendingBridgeTxGist?.find(
            (pnd) => pnd.txHash && pnd.lexId === props1.row.original.lexId,
          );
          const isTxpemding =
            loadingBridgeTxGists[props1.row.original.lexId] === true;

          if (activePendingBridgeTxGist) {
            return (
              <Stack
                direction={"row"}
                justifyContent={"center"}
                gap={1}
                className={classes.inFlightBridgeTxContainer}
              >
                <Tooltip title={"This process might take a few minutes"}>
                  <a
                    href={`https://layerzeroscan.com/tx/${activePendingBridgeTxGist.txHash}`}
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                  >
                    <Typography className={classes.inFlightBridgeTxContainer}>
                      {activePendingBridgeTxGist.displayMessage}
                    </Typography>
                  </a>
                </Tooltip>
              </Stack>
            );
          } else {
            return (
              <Stack direction={"row"} justifyContent={"end"} gap={1} pr={3}>
                {!isConnectedToWallet ? (
                  <TableActionButton
                    text={"Connect Wallet"}
                    onClick={() => requestConnectToWalletFunction()}
                  />
                ) : props1.row.original.sourceChainId === connectedChainId ? (
                  <>
                    <TableActionButton
                      text={"deposit"}
                      style={{
                        color: THEME_CONSTANTS.brandLightPurple,
                        borderColor: THEME_CONSTANTS.brandLightPurple,
                        opacity:
                          (activePendingBridgeTxGist ?? isTxpemding) ? 0.5 : 1,
                      }}
                      onClick={() => showChipIn(props1.row.original.lexId)}
                      disabled={activePendingBridgeTxGist ?? isTxpemding}
                    />
                    <TableActionButton
                      text={"withdraw"}
                      style={{
                        color: THEME_CONSTANTS.infoTextOffWhite,
                        borderColor: THEME_CONSTANTS.infoTextOffWhite,
                        opacity:
                          (activePendingBridgeTxGist ?? isTxpemding) ? 0.5 : 1,
                      }}
                      onClick={() => showChipOut(props1.row.original.lexId)}
                      disabled={activePendingBridgeTxGist ?? isTxpemding}
                    />
                  </>
                ) : (
                  <TableActionButton
                    text={"Change Chain"}
                    style={{
                      color: THEME_CONSTANTS.infoTextOffWhite,
                      borderColor: THEME_CONSTANTS.infoTextOffWhite,
                    }}
                    onClick={() =>
                      switchChainFunction(props1.row.original.sourceChainId)
                    }
                  />
                )}
              </Stack>
            );
          }
        },
      },
    ];
  }, [
    classes.inFlightBridgeTxContainer,
    connectedChainId,
    isConnectedToWallet,
    pendingBridgeTxGist,
    requestConnectToWalletFunction,
    showChipIn,
    showChipOut,
    switchChainFunction,
  ]);

  const data = useMemo(() => {
    return [...chipHolderInfoGists];
  }, [chipHolderInfoGists]);

  return (
    <MaterialUiTable
      dinamicCompare={(x: TChipHolderInfoGist) =>
        x.sourceChainId === connectedChainId
      }
      columns={columns}
      data={data}
      getRowId={(val: TChipHolderInfoGist) =>
        `${val.sourceChainId}_${val.sourceUnderlyingSymbol}`
      }
      sortings={sortings}
      emptyTableText={""}
      tableBorderRadius={40}
      rowSpacing="25px 24px 16px 24px"
    />
  );
});
