import {
  ILeverageDimensionParameters,
  ILeverageDimensionTradePairParameters,
} from "./ILeverageDiomensionsService";
import { TChainIdsWithNone } from "../../constants/chainConstants";
import { PAIR_CONFIGS_IDS } from "../../constants/pairsConstants";
import {
  BOBA_ENGINE_CHIPS_CONFIGURATIONS,
  FANTOM_ENGINE_CHIPS_CONFIGURATIONS,
  TChipConfigurations,
} from "../../constants/systemChips.ts";
import { IS_DEV } from "../../configs.ts";
import { convertSymbolToNavigationKey } from "../../navigation/navigationStateFunctions.ts";

export const LED_IDS = {
  FANTOM_ENGINE_FUSE_PDAI: "FANTOM_ENGINE_FUSE_PDAI",
  FANTOM_ENGINE_FUSE_WFUSE: "FANTOM_ENGINE_FUSE_WFUSE",
  FANTOM_ENGINE_FUSE_MST: "FANTOM_ENGINE_FUSE_MST",
  FANTOM_ENGINE_FUSE_SFUSE: "FANTOM_ENGINE_FUSE_SFUSE",
  FANTOM_ENGINE_FUSE_VOLT: "FANTOM_ENGINE_FUSE_VOLT",
  FANTOM_ENGINE_LINEA_LVC: "FANTOM_ENGINE_LINEA_LVC",
  FANTOM_ENGINE_ARBITRUM_ARB: "FANTOM_ENGINE_ARBITRUM_ARB",
  FANTOM_ENGINE_ARBITRUM_STEUR: "FANTOM_ENGINE_ARBITRUM_STEUR",
  FANTOM_ENGINE_ARBITRUM_TST: "FANTOM_ENGINE_ARBITRUM_TST",
  FANTOM_ENGINE_ARBITRUM_EUROS: "FANTOM_ENGINE_ARBITRUM_EUROS",
  FANTOM_ENGINE_ARBITRUM_SLIZ: "FANTOM_ENGINE_ARBITRUM_SLIZ",
  FANTOM_ENGINE_ARBITRUM_SCALES: "FANTOM_ENGINE_ARBITRUM_SCALES",
  FANTOM_ENGINE_ARBITRUM_GRAI: "FANTOM_ENGINE_ARBITRUM_GRAI",
  FANTOM_ENGINE_ARBITRUM_USDFI: "FANTOM_ENGINE_ARBITRUM_USDFI",
  FANTOM_ENGINE_OPTIMISM_SONNE: "FANTOM_ENGINE_OPTIMISM_SONNE",
  FANTOM_ENGINE_POLYGON_WMATIC: "FANTOM_ENGINE_POLYGON_WMATIC",
  FANTOM_ENGINE_POLYGON_MIMATIC: "FANTOM_ENGINE_POLYGON_MIMATIC",
  FANTOM_ENGINE_MANTLE_AUSD: "FANTOM_ENGINE_MANTLE_AUSD",
  FANTOM_ENGINE_BSC_LISUSD: "FANTOM_ENGINE_BSC_LISUSD",
  FANTOM_ENGINE_MODE_MODE: "FANTOM_ENGINE_MODE_MODE",
  FANTOM_ENGINE_MODE_ION: "FANTOM_ENGINE_MODE_ION",
  FANTOM_ENGINE_CELO_CELO: "FANTOM_ENGINE_CELO_CELO",
  FANTOM_ENGINE_CELO_USDT: "FANTOM_ENGINE_CELO_USDT",
  FANTOM_ENGINE_FANTOM_FTM: "FANTOM_ENGINE_FANTOM_FTM",
  FANTOM_ENGINE_FANTOM_EQUAL: "FANTOM_ENGINE_FANTOM_EQUAL",
  FANTOM_ENGINE_FANTOM_BRUSH: "FANTOM_ENGINE_FANTOM_BRUSH",
  FANTOM_ENGINE_FANTOM_FSONIC: "FANTOM_ENGINE_FANTOM_FSONIC",
  FANTOM_ENGINE_FANTOM_POLTER: "FANTOM_ENGINE_FANTOM_POLTER",
  FANTOM_ENGINE_FANTOM_FBUX: "FANTOM_ENGINE_FANTOM_FBUX",
  FANTOM_ENGINE_FANTOM_FTAILS: "FANTOM_ENGINE_FANTOM_FTAILS",
  FANTOM_ENGINE_FANTOM_SPIRIT: "FANTOM_ENGINE_FANTOM_SPIRIT",
  FANTOM_ENGINE_FANTOM_USDC: "FANTOM_ENGINE_FANTOM_USDC",

  BOBA_ENGINE_BOBA_USDC: "BOBA_ENGINE_BOBA_USDC",
  BOBA_ENGINE_BOBA_BOBA: "BOBA_ENGINE_BOBA_BOBA",

  // FANTOM_ENGINE_LINEA_VELVC: "FANTOM_ENGINE_LINEA_VELVC",
  // FANTOM_ENGINE_LINEA_AGEUR: "FANTOM_ENGINE_LINEA_AGEUR",
};

// export const FANTOM_GRAPH_URL =
//   "https://api.thegraph.com/subgraphs/name/lynx-protocol/lynx-fantom";
// export const FANTOM_GRAPH_URL = "";

// export const FANTOM_GRAPH_URL =
//     "https://gateway-arbitrum.network.thegraph.com/api/87e8aced524dfac060c549e79c91a6aa/subgraphs/id/HZFQD9tk8Z7hiViHdcLeqh4UYi3m59QLLcFL9Qk2mUE5";
export const FANTOM_GRAPH_URL =
  "https://api.studio.thegraph.com/query/78448/lynx-fantom/version/latest";
export const BOBA_GRAPH_URL =
  "https://api.studio.thegraph.com/query/78448/lynx-boba/version/latest";

const DEFAULT_SUPPORTED_PAIRS: ILeverageDimensionTradePairParameters[] = [
  {
    pairId: PAIR_CONFIGS_IDS.BTC_USD,
    pairAssetAName: "BTC",
    pairAssetBName: "USD",
  },
  {
    pairId: PAIR_CONFIGS_IDS.ETH_USD,
    pairAssetAName: "ETH",
    pairAssetBName: "USD",
  },
  {
    pairId: PAIR_CONFIGS_IDS.BNB_USD,
    pairAssetAName: "BNB",
    pairAssetBName: "USD",
  },
  {
    pairId: PAIR_CONFIGS_IDS.FTM_USD,
    pairAssetAName: "FTM",
    pairAssetBName: "USD",
  },
  {
    pairId: PAIR_CONFIGS_IDS.SOL_USD,
    pairAssetAName: "SOL",
    pairAssetBName: "USD",
  },
];

const BOBA_LED_IDS = [
  LED_IDS.BOBA_ENGINE_BOBA_USDC,
  LED_IDS.BOBA_ENGINE_BOBA_BOBA,
];

export const FANTOM_ENGINE_POOL_ADDRESSES = {
  fuse: {
    PDAI: "0xdB25e146f3e9F7a9A5aA96BC8e79f87b21b25d89",
  },
  fantom: {
    WFTM: "0x88756Ea4d2362Be50283AA686E8B090cb7FD4F96",
    SPIRIT: "0xc1760Ae5A05C59D4cd18F1A7C829307A017D704C",
    USDC: "0xd4342Ae88ADFeF5f4D6996d4126a92e125E1D2cA",
    FBUX: "0x93E8A9cCdfc2cF58B12Da0B6fD7FecD120C9d01b",
    EQUAL: "0x3f51ffdd16BcaB1EFde03963F16e2112aC933E4F",
  },
  mode: {
    ION: "0x6c0e6297A7716936D4061D2F0A24B5307e41cAF3",
  },
  celo: {
    CELO: "0x678c262ECC2C6F39A1e8b49635FBFAf295929A82",
    USDT: "0xB35057fA7BA5B70b32Ee847f7cEAb762bec20d4C",
  },
  arbitrum: {
    USDFI: "0x037Ca81187B48255072aad705E23cc6859a8dCfd",
  },
};

export const BOBA_ENGINE_POOL_ADDRESSES = {
  boba: {
    USDC: "0x27b66f41E8ba74fF804C8b4D983b137D78D559a4",
    BOBA: "0xDa9de011c65376CA2D7bd902Ce05a48b85D93175",
  },
};

export const FOREX_SUPPORTING_LEX_IDS = [
  LED_IDS.FANTOM_ENGINE_FUSE_PDAI,
  LED_IDS.FANTOM_ENGINE_CELO_CELO,
  LED_IDS.FANTOM_ENGINE_CELO_USDT,
];

function buildStandardLeverageDimensionParameters(
  id: string,
  lexAddress: string,
  chipConfigurations: TChipConfigurations,
): ILeverageDimensionParameters {
  // TODO : CRITICAL : Find better solution
  const graphUrl = BOBA_LED_IDS.includes(id)
    ? BOBA_GRAPH_URL
    : FANTOM_GRAPH_URL;

  const supportsCat = id === LED_IDS.FANTOM_ENGINE_FUSE_PDAI;
  const supportsForex = FOREX_SUPPORTING_LEX_IDS.includes(id);

  const supportedPairs = [...DEFAULT_SUPPORTED_PAIRS];

  if (supportsForex) {
    supportedPairs.push({
      pairId: PAIR_CONFIGS_IDS.EUR_USD,
      pairAssetAName: "EUR",
      pairAssetBName: "USD",
    });
    supportedPairs.push({
      pairId: PAIR_CONFIGS_IDS.GBP_USD,
      pairAssetAName: "GBP",
      pairAssetBName: "USD",
    });
    supportedPairs.push({
      pairId: PAIR_CONFIGS_IDS.AUD_USD,
      pairAssetAName: "AUD",
      pairAssetBName: "USD",
    });
    supportedPairs.push({
      pairId: PAIR_CONFIGS_IDS.NZD_USD,
      pairAssetAName: "NZD",
      pairAssetBName: "USD",
    });
  }

  if (supportsCat) {
    supportedPairs.push({
      pairId: PAIR_CONFIGS_IDS.BTC_USD_CAT,
      pairAssetAName: "BTC",
      pairAssetBName: "USD",
    });
    supportedPairs.push({
      pairId: PAIR_CONFIGS_IDS.ETH_USD_CAT,
      pairAssetAName: "ETH",
      pairAssetBName: "USD",
    });
  }

  return {
    id,
    hashName: id,
    urlName: "",
    graphUrl: graphUrl,
    lexParams: {
      lexAddress,
      chipConfigurations,
    },
    supportedPairParams: supportedPairs,
  };
}

type TLeDConfigCreationPayload = {
  id: string;
  lexAddress: string;
  chipConfigs: TChipConfigurations;
};

// ****** Fantom Engine Chain ******

const FUSE_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // WFUSE
  {
    id: LED_IDS.FANTOM_ENGINE_FUSE_WFUSE,
    lexAddress: "0x4D609d72c1437057d2D562B6D83E97D1b8065723",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FUSE_WFUSE,
  },
  // mST
  {
    id: LED_IDS.FANTOM_ENGINE_FUSE_MST,
    lexAddress: "0xA03312361D07dfAe0C517a441C69BFA9E84e38bf",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FUSE_MST,
  },
  // SFUSE
  {
    id: LED_IDS.FANTOM_ENGINE_FUSE_SFUSE,
    lexAddress: "0xaa94596A227f2E7C3836DeA07bB927Ad0E51dB06",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FUSE_SFUSE,
  },
  // VOLT
  {
    id: LED_IDS.FANTOM_ENGINE_FUSE_VOLT,
    lexAddress: "0x870DF8cE4ee542844e59CE278600E0651Ffc15cc",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FUSE_VOLT,
  },
];

const ARBITRUM_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // ARB
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_ARB,
    lexAddress: "0x6756B1a05E26E31067A8D9f8662ba459E2873170",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_ARB,
  },
  // EUROS
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_EUROS,
    lexAddress: "0xf1143f1b8d2c752EB2608eA200795D73C4EF656C",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_EUROS,
  },
  // USDFI
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_USDFI,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.arbitrum.USDFI,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_USDFI,
  },
  // STEUR
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_STEUR,
    lexAddress: "0x1E9a0Dbde7a56d7966469d4fec8716E3250F1F05",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_STEUR,
  },
  // TST
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_TST,
    lexAddress: "0x8C1b85dA845bAA71813172fd80D2F466057363B0",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_TST,
  },
  // SLIZ
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_SLIZ,
    lexAddress: "0x5FA540f2637Dd18A28a9014bde207d39CF276158",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_SLIZ,
  },
  // SCALES
  {
    id: LED_IDS.FANTOM_ENGINE_ARBITRUM_SCALES,
    lexAddress: "0x6E3402983AD93f620599Be5526D4777CF62aAd90",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_SCALES,
  },
];

const OPTIMISM_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // SONNE
  {
    id: LED_IDS.FANTOM_ENGINE_OPTIMISM_SONNE,
    lexAddress: "0xCd7316cfe50200Dd0C85CD62A5f557407a49FCe4",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.OPTIMISM_SONNE,
  },
];

const POLYGON_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // MIMATIC
  {
    id: LED_IDS.FANTOM_ENGINE_POLYGON_MIMATIC,
    lexAddress: "0xD847E0cfeC48d4229484557CA84a7C081F4516B2",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.POLYGON_MIMATIC,
  },
  // WMATIC
  {
    id: LED_IDS.FANTOM_ENGINE_POLYGON_WMATIC,
    lexAddress: "0x198BEb8a4Aaf135796555A149eef234146cB27ee",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.POLYGON_WMATIC,
  },
];

const MANTLE_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // AUSD
  {
    id: LED_IDS.FANTOM_ENGINE_MANTLE_AUSD,
    lexAddress: "0xd514EA2CF5150b475Ed6f175413da07766567663",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.MANTLE_AUSD,
  },
];

const BSC_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // LISUSD
  {
    id: LED_IDS.FANTOM_ENGINE_BSC_LISUSD,
    lexAddress: "0x5F98ae329AA2177f006EBFB2726F1AA15011cd09",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.BSC_LISUSD,
  },
];

const MODE_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // MODE
  {
    id: LED_IDS.FANTOM_ENGINE_MODE_MODE,
    lexAddress: "0xb06115AD7A8eB52989a463DEBf9a94C5392ca404",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.MODE_MODE,
  },
  // ION
  {
    id: LED_IDS.FANTOM_ENGINE_MODE_ION,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.mode.ION,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.MODE_ION,
  },
];

const CELO_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // CELO
  {
    id: LED_IDS.FANTOM_ENGINE_CELO_CELO,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.celo.CELO,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.CELO_CELO,
  },
  // USDT
  {
    id: LED_IDS.FANTOM_ENGINE_CELO_USDT,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.celo.USDT,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.CELO_USDT,
  },
];

const FANTOM_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // WFTM
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_FTM,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.fantom.WFTM,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_FTM,
  },
  // USDC
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_USDC,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.fantom.USDC,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_USDC,
  },
  // SPIRIT
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_SPIRIT,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.fantom.SPIRIT,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_SPIRIT,
  },
  // BRUSH
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_BRUSH,
    lexAddress: "0xd1efD23eFe4308893624b61DE42D23e4854f9862",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_BRUSH,
  },
  // FBUX
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_FBUX,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.fantom.FBUX,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_FBUX,
  },
  // EQUAL
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_EQUAL,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.fantom.EQUAL,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_EQUAL,
  },
  // POLTER
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_POLTER,
    lexAddress: "0x7b8cd9871f94ECb7176F0Cca25173E33b84b2b6D",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_POLTER,
  },
  // FTAILS
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_FTAILS,
    lexAddress: "0x2F85E5B256260527A7E88D44258B974e123b8F69",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_FTAILS,
  },
  // fSONIC
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_FSONIC,
    lexAddress: "0x9466CE2219De97D4f14f131c0b2901c9Bc01988E",
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_FSONIC,
  },
];

const BOBA_SOURCE_PROD_PAYLOADS: TLeDConfigCreationPayload[] = [
  // USDC
  {
    id: LED_IDS.BOBA_ENGINE_BOBA_USDC,
    lexAddress: BOBA_ENGINE_POOL_ADDRESSES.boba.USDC,
    chipConfigs: BOBA_ENGINE_CHIPS_CONFIGURATIONS.CORE_USDC,
  },
  // BOBA
  {
    id: LED_IDS.BOBA_ENGINE_BOBA_BOBA,
    lexAddress: BOBA_ENGINE_POOL_ADDRESSES.boba.BOBA,
    chipConfigs: BOBA_ENGINE_CHIPS_CONFIGURATIONS.CORE_BOBA,
  },
];

const ALL_PROD_PAYLOADS = [
  ...FUSE_SOURCE_PROD_PAYLOADS,
  ...ARBITRUM_SOURCE_PROD_PAYLOADS,
  ...OPTIMISM_SOURCE_PROD_PAYLOADS,
  ...POLYGON_SOURCE_PROD_PAYLOADS,
  ...MANTLE_SOURCE_PROD_PAYLOADS,
  ...BSC_SOURCE_PROD_PAYLOADS,
  ...FANTOM_SOURCE_PROD_PAYLOADS,
  ...MODE_SOURCE_PROD_PAYLOADS,

  ...BOBA_SOURCE_PROD_PAYLOADS,
];

const ALL_DEV_PAYLOADS = [
  // PDAI
  {
    id: LED_IDS.FANTOM_ENGINE_FUSE_PDAI,
    lexAddress: FANTOM_ENGINE_POOL_ADDRESSES.fuse.PDAI,
    chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FUSE_PDAI,
  },

  ...CELO_SOURCE_PROD_PAYLOADS,

  // GRAI
  // {
  //   id: LED_IDS.FANTOM_ENGINE_ARBITRUM_GRAI,
  //   lexAddress: "0xF5dF9708b08809632B9cb55234BCed0764e28d2a",
  //   chipConfigs: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.ARBITRUM_GRAI,
  // },
];

// export const FANTOM_ENGINE__LINEA_LVC_DOMAIN: ILeverageDimensionParameters = {
//   id: LED_IDS.FANTOM_ENGINE_LINEA_LVC,
//   hashName: "FANTOM_ENGINE_LINEA_LVC",
//   urlName: "",
//   graphUrl: FANTOM_GRAPH_URL,
//   lexParams: {
//     lexAddress: "0x1136d541113E9C643059B8c6850Cc35B50D4a5f0",
//     chipConfigurations: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.LINEA_LVC,
//   },
//   supportedPairParams: DEFAULT_SUPPORTED_PAIRS,
// };
//
export const FANTOM_ENGINE__FANTOM_EQUAL_DOMAIN: ILeverageDimensionParameters =
  {
    id: LED_IDS.FANTOM_ENGINE_FANTOM_EQUAL,
    hashName: "FANTOM_ENGINE_FANTOM_EQUAL",
    urlName: "",
    graphUrl: FANTOM_GRAPH_URL,
    lexParams: {
      lexAddress: "0x3f51ffdd16BcaB1EFde03963F16e2112aC933E4F",
      chipConfigurations: FANTOM_ENGINE_CHIPS_CONFIGURATIONS.FANTOM_EQUAL,
    },
    supportedPairParams: DEFAULT_SUPPORTED_PAIRS,
  };

function buildLedParamsFromPayload(
  ledPayload: TLeDConfigCreationPayload,
): ILeverageDimensionParameters {
  return buildStandardLeverageDimensionParameters(
    ledPayload.id,
    ledPayload.lexAddress,
    ledPayload.chipConfigs,
  );
}

const PROD_DOMAINS: ILeverageDimensionParameters[] = [
  ...ALL_PROD_PAYLOADS.map((payload) => buildLedParamsFromPayload(payload)),
].filter((p) => p);

const TEST_DOMAINS: ILeverageDimensionParameters[] = [
  ...PROD_DOMAINS,
  ...ALL_DEV_PAYLOADS.map((payload) => buildLedParamsFromPayload(payload)),
];

export const LeverageDimensionsParams: ILeverageDimensionParameters[] = IS_DEV
  ? TEST_DOMAINS
  : PROD_DOMAINS;

function buildChainToSaToLedMap(
  ledsParams: ILeverageDimensionParameters[],
): Record<TChainIdsWithNone, object> {
  const mapRecord: Record<TChainIdsWithNone, object> = {
    250: {},
    59144: {},
    122: {},
    42161: {},
    10: {},
    137: {},
    5000: {},
    56: {},
    34443: {},
    42220: {},

    288: {},

    1337: {},
    31337: {},
    "-1": {},
  };

  for (const ledParams of ledsParams) {
    const sourceChainId = ledParams.lexParams.chipConfigurations.sourceChainId;
    const sourceAssetSymbol =
      ledParams.lexParams.chipConfigurations.sourceAssetParams.symbol;
    const assetKey = convertSymbolToNavigationKey(sourceAssetSymbol);

    const chainMap = mapRecord[sourceChainId];
    // if (!!chainMap) {
    //   throw new Error(`No chainMap for chain ${sourceChainId}`);
    // }

    // @ts-ignore
    chainMap[assetKey] = ledParams;
  }

  return mapRecord;
}

export const CHAIN_TO_SA_TO_LED_MAP = buildChainToSaToLedMap(
  LeverageDimensionsParams,
);
