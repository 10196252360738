import * as Sentry from "@sentry/browser";

export function logWalletConnection_sentry(accountAddress: string) {
  Sentry.setUser({ id: accountAddress });
}

export function logWalletDisconnection_sentry(accountAddress: string) {
  Sentry.setUser(null);
}

export function logError_sentry(error: Error | string) {
  if (typeof error === "string") {
    Sentry.captureMessage(error, "error");
  } else {
    Sentry.captureException(error, {});
  }
}
