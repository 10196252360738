import { THEME_SINGLE_IMPORT } from "./themeSingleImport";
import { THEME_CONSTANTS } from "./ThemeConstants";

const appear = THEME_SINGLE_IMPORT.keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }`;

const textChange = THEME_SINGLE_IMPORT.keyframes`
    50% {
      color: ${THEME_CONSTANTS.brandPurple};
    }`;

const pulsingText = THEME_SINGLE_IMPORT.keyframes`
    50% {
      color: ${THEME_CONSTANTS.brandYellow};
    }
    `;

export const KEYFRAMES = {
  appear,
  textChange,
  pulsingText,
};
