import React from "react";
import { Typography } from "@mui/material";

interface IProps {
  definition: string;
  inPractice: string;
}

export const InPracticeTooltipContent = React.memo<IProps>((props) => {
  const { definition, inPractice } = props;

  return (
    <Typography style={{ textAlign: "center" }}>
      <Typography style={{ fontWeight: "bold" }}>Explanation: </Typography>
      {definition}
      <br />
      <br />
      <Typography style={{ fontWeight: "bold" }}>In practice: </Typography>
      {inPractice}
    </Typography>
  );
});
