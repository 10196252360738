import { OpenOrderTypeEnums } from "./contractEnums";
import { PAIR_CONFIGS_IDS, TPairIds } from "./pairsConstants.ts";

const tpDefaultValue = 100;
const slDefaultValue = 75;
const lossMaxLimit = 1000000;
const profitMaxLimit = 1000000;
const precisionDecimalsToWorkWith = 5;

const defaultSpreadInUnits = 0.0001; // 0.01%;
const slightlyElevatedSpreadInUnits = 0.0002; // 0.02%;

function getSpreadInUnits(pairId: TPairIds) {
  switch (pairId) {
    case PAIR_CONFIGS_IDS.FTM_USD:
      return slightlyElevatedSpreadInUnits;
    default:
      return defaultSpreadInUnits;
  }
}

const slMarksNormal = [
  {
    text: "-10%",
    value: 10,
  },
  // {
  //   text: "-25%",
  //   value: 25,
  // },
  {
    text: "-50%",
    value: 50,
  },
  {
    text: "-75%",
    value: 75,
  },
];
const tpMarksNormal = [
  // {
  //   text: "+25%",
  //   value: 25,
  // },
  {
    text: "+50%",
    value: 50,
  },
  {
    text: "+100%",
    value: 100,
  },
  // {
  //   text: "+300%",
  //   value: 300,
  // },
];

const positionTypeSelectorOptions = [
  { text: "MARKET", value: OpenOrderTypeEnums.MARKET },
  // { text: "LIMIT", value: OpenOrderTypeEnums.LIMIT, disabled: true },
];

const collateralSizeInputExtraOptions = [
  // {
  //   value: 0.25,
  //   text: "25%",
  // },
  {
    value: 0.5,
    text: "50%",
  },
  {
    value: 1,
    text: "100%",
  },
];

export const positionPanelConstants = {
  normalPairsLimits: {
    slMarks: slMarksNormal,
    tpMarks: tpMarksNormal,
  },
  catPairsLimits: {
    slMarks: slMarksNormal,
    tpMarks: tpMarksNormal,
  },
  collateralSizeInputExtraOptions,
  positionTypeSelectorOptions,
  defaultTpMultiplier: 100,
  defaultSlMultiplier: 75,
};

export {
  tpDefaultValue,
  slDefaultValue,
  lossMaxLimit,
  profitMaxLimit,
  positionTypeSelectorOptions,
  collateralSizeInputExtraOptions,
  precisionDecimalsToWorkWith,
  getSpreadInUnits,
};
