import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  MaterialUiTable,
  Sortings,
  TableActionButton,
} from "lynx-ui-components";
import { makeStyles } from "tss-react/mui";
import {
  LIVE_CHAIN_CONSTANTS,
  TChainIds,
  TChainIdsWithNone,
  TSourceChainIds,
} from "../../../constants/chainConstants";
import { commonTableCellsBuilders } from "../../../components/Tables/commonTableCellsBuilders.tsx";
import { localPriceStringer, localStringer } from "../../../ux/uxFunctions.ts";
import { Stack } from "@mui/material";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants.ts";
import { useIsMobile } from "../../../ux/uxHooks.ts";

const headerValuePays: Record<string, string> = {
  sourceChainId: "sourceChainId",
  poolAssetSymbol: "poolAssetSymbol",
  rewardAssetSymbol: "rewardAssetSymbol",
  totalRewardsDistributedInUnits: "totalRewardsDistributedInUnits",
};

const sortings = new Sortings(headerValuePays);

export type THistoricRewardsTableRowGist = {
  sourceChainId: TSourceChainIds;
  poolAssetSymbol: string;

  rewardAssetSymbol: string;
  totalRewardsDistributedInUnits: number;
  rewardAssetPriceInUnits: number;
};

interface IProps {
  rowGists: THistoricRewardsTableRowGist[];
  connectedChainId: TChainIdsWithNone;
}

const useStyles = makeStyles()((theme) => ({}));

export const HistoricRewardsTable = React.memo<IProps>((props) => {
  const { classes } = useStyles();
  const { rowGists, connectedChainId } = props;

  const isMobile = useIsMobile();

  const columns: ColumnDef<THistoricRewardsTableRowGist>[] = useMemo<
    ColumnDef<THistoricRewardsTableRowGist>[]
  >(() => {
    return [
      {
        header: "Source Chain",
        id: "sourceChainId",
        cell: (cellProps) => {
          return commonTableCellsBuilders.buildBlockchainCell(
            LIVE_CHAIN_CONSTANTS[cellProps.row.original.sourceChainId]
              ?.shortDisplayName ?? "",
          );
        },
      },
      {
        header: "Trading Asset",
        id: "sourceUnderlyingSymbol",
        enableSorting: true,
        cell: (props1) => {
          return commonTableCellsBuilders.buildAssetTableCell(
            props1.row.original.poolAssetSymbol,
          );
        },
      },
      {
        header: "Reward Asset",
        id: "rewardAssetSymbol",
        enableSorting: true,
        cell: (props1) => {
          return commonTableCellsBuilders.buildAssetTableCell(
            props1.row.original.rewardAssetSymbol,
          );
        },
      },
      {
        header: "Total Rewards Distributed",
        id: "totalRewardsDistributedInUnits",
        enableSorting: true,
        cell: (cellProps) =>
          commonTableCellsBuilders.buildTextCell(
            `${localStringer(cellProps.row.original.totalRewardsDistributedInUnits)} ${cellProps.row.original.rewardAssetSymbol}`,
            `~${localPriceStringer(cellProps.row.original.totalRewardsDistributedInUnits * cellProps.row.original.rewardAssetPriceInUnits)}`,
          ),
      },
    ];
  }, []);

  const data = useMemo(() => {
    return [...rowGists];
  }, [rowGists]);

  return (
    <MaterialUiTable
      dinamicCompare={(x: THistoricRewardsTableRowGist) =>
        x.sourceChainId === connectedChainId
      }
      columns={columns}
      data={data}
      getRowId={(val: THistoricRewardsTableRowGist) =>
        `${val.sourceChainId}_${val.poolAssetSymbol}_${val.rewardAssetSymbol}`
      }
      sortings={sortings}
      emptyTableText={"No Rewards Available"}
      tableBorderRadius={isMobile ? 4 : 40}
      rowSpacing="25px 24px 16px 24px"
    />
  );
});
