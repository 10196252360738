import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { THEME_CONSTANTS } from "../../../../theme/ThemeConstants.ts";

interface IProps {
  title: string;
  secondTitle?: string;
  secondTitleLink?: string;

  mainText: string;
}

const useStyles = makeStyles()((theme) => ({
  blobDiv: {
    backgroundColor: THEME_CONSTANTS.panelBgDarkColor,
    width: "325px",
    height: "192px",
    borderRadius: "40px",
    padding: "1.5rem 1.5rem 2rem 1.5rem",
  },
}));

export const InfoBlob = React.memo<IProps>((props) => {
  const { classes } = useStyles();
  const { title, mainText, secondTitle, secondTitleLink } = props;

  return (
    <Stack className={classes.blobDiv} justifyContent={"space-between"}>
      <Stack direction={"row"}>
        <Typography variant={"h6"} color={THEME_CONSTANTS.titleGray}>
          {title}
        </Typography>
        <Typography>{secondTitle}</Typography>
      </Stack>
      <Typography variant={"h4"}>{mainText}</Typography>
    </Stack>
  );
});

// export default InfoBlob as (props: IProps) => React.JSX.Element;
