import { TUsageRoundTraderGraphInfo } from "../../../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { chipsBnToUnits } from "../../../../utils/chipsCalculationsUtils.ts";
import {
  TCompetitionPlanIncentiveDescription,
  TCompetitionRoundTraderInfo,
} from "../../../../services/servicesIntergration/incentivesService/IIncentivesService.ts";
import { TLeaderBoardTableRowGist } from "lynx-ui-components";

export function convertUsageRoundTradersInfoToLeaderBoardTableData(
  usageRoundTradersInfo: TUsageRoundTraderGraphInfo[],
  competitionDescriptor: TCompetitionPlanIncentiveDescription,
  rewardTokenPrice: number,
): TLeaderBoardTableRowGist[] {
  // Start by sorting the traders by their total PnL
  // sortUsageRoundTradersInfo(usageRoundTradersInfo);

  const rewardsArray = competitionDescriptor.details.incentivesList[0].ranks;
  const rewardTokenSymbol =
    competitionDescriptor.details.incentivesList[0].rewardSymbol;

  function getRewardsByRank(rank: number): number {
    if (rewardsArray.length == 0 || rank > rewardsArray.length) {
      return 0;
    } else {
      return rewardsArray[rank - 1];
    }
  }

  const leaderBoardTableData: TLeaderBoardTableRowGist[] =
    usageRoundTradersInfo.map((traderInfo, index) => {
      const totalPnl =
        traderInfo.totalPositivePnl -
        traderInfo.totalNegativePnl +
        traderInfo.totalOpenFeesPaid +
        traderInfo.totalCloseFeesPaid;

      const rank = index + 1;
      const rewardAmountInUnits = getRewardsByRank(rank);
      const rewardValueInUnits = rewardAmountInUnits * rewardTokenPrice;

      const leaderBoardTableRowGist: TLeaderBoardTableRowGist = {
        rowId: `${traderInfo.traderAddress}`,
        rank,
        traderAddress: traderInfo.traderAddress,
        openedTradesCounter: traderInfo.openedTradesCounter,
        pnlInUnits: chipsBnToUnits(totalPnl),
        rewardSymbol: rewardTokenSymbol,
        rewardAmountInUnits,
        rewardValueInUnits,
      };

      return leaderBoardTableRowGist;
    });

  const completeRows = addLeaderboardTablePlaceHolderRowsIfNeeded(
    leaderBoardTableData,
    rewardsArray,
    rewardTokenSymbol,
    rewardTokenPrice,
    getRewardsByRank,
  );

  return completeRows;
}

export function convertCompetitionRoundTraderInfoToLeaderBoardTableData(
  roundTradersInfo: TCompetitionRoundTraderInfo[],
  competitionDescriptor: TCompetitionPlanIncentiveDescription,
  rewardTokenPrice: number,
): TLeaderBoardTableRowGist[] {
  const rewardsArray = competitionDescriptor.details.incentivesList[0].ranks;
  const rewardTokenSymbol =
    competitionDescriptor.details.incentivesList[0].rewardSymbol;

  function getRewardsByRank(rank: number): number {
    if (rewardsArray.length == 0 || rank > rewardsArray.length) {
      return 0;
    } else {
      return rewardsArray[rank - 1];
    }
  }

  const leaderBoardTableData: TLeaderBoardTableRowGist[] = roundTradersInfo.map(
    (traderInfo, index) => {
      const rank = traderInfo.rank;
      const rewardAmountInUnits = getRewardsByRank(rank);
      const rewardValueInUnits = rewardAmountInUnits * rewardTokenPrice;

      const leaderboardRowGist: TLeaderBoardTableRowGist = {
        rowId: traderInfo.traderAddress,
        rank: traderInfo.rank,
        traderAddress: traderInfo.traderAddress,
        openedTradesCounter: traderInfo.positionsCount,
        pnlInUnits: chipsBnToUnits(traderInfo.totalPnl),
        rewardSymbol: rewardTokenSymbol,
        rewardAmountInUnits: rewardAmountInUnits,
        rewardValueInUnits: rewardValueInUnits,
      };

      return leaderboardRowGist;
    },
  );

  const completeRows = addLeaderboardTablePlaceHolderRowsIfNeeded(
    leaderBoardTableData,
    rewardsArray,
    rewardTokenSymbol,
    rewardTokenPrice,
    getRewardsByRank,
  );

  return completeRows;
}

function addLeaderboardTablePlaceHolderRowsIfNeeded(
  leaderBoardTableData: TLeaderBoardTableRowGist[],
  rewardsArray: number[],
  rewardTokenSymbol: string,
  rewardTokenPrice: number,
  getRewardsByRank: (rank: number) => number,
) {
  const actualTradersLength = leaderBoardTableData.length;
  const rewardsLength = rewardsArray.length;

  if (actualTradersLength >= rewardsLength) {
    return leaderBoardTableData;
  } else {
    const missingTradersCount = rewardsLength - actualTradersLength;

    const missingUsageRoundTradersInfo: TLeaderBoardTableRowGist[] = Array(
      missingTradersCount,
    )
      .fill(0)
      .map((_, index) => {
        const rank = actualTradersLength + index + 1;
        const rewardAmountInUnits = getRewardsByRank(rank);
        const rewardValueInUnits = rewardAmountInUnits * rewardTokenPrice;

        const rewardPlaceHolderRowGist: TLeaderBoardTableRowGist = {
          rowId: `RewardPlaceHolder_${rank}`,
          rank,
          traderAddress: "",
          openedTradesCounter: 0,
          pnlInUnits: 0,
          rewardSymbol: rewardTokenSymbol,
          rewardAmountInUnits,
          rewardValueInUnits,
        };
        return rewardPlaceHolderRowGist;
      });

    return [...leaderBoardTableData, ...missingUsageRoundTradersInfo];
  }
}
