import { IS_ON_MAIN_LYNX_DOMAIN, IS_ON_PLAYGROUND } from "../configs.ts";
import { WHITE_LABEL_CONFIGS } from "../whitelabels/whiteLabelConfigs.ts";

export function useGetDomainString() {
  return getDomainString();
}

function getDomainString() {
  if (IS_ON_MAIN_LYNX_DOMAIN) {
    return "lynx";
  } else if (IS_ON_PLAYGROUND) {
    return "lynx-playground";
  }

  if (WHITE_LABEL_CONFIGS.isOnWhiteLabel) {
    const partnerDomainName =
      WHITE_LABEL_CONFIGS.whiteLabelParams.partnerDomainName;
    return partnerDomainName;
  }

  return "unkown";
}
