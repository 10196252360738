import { StringParam, useQueryParam } from "use-query-params";
import { useEffect } from "react";
import { PAIR_CONFIGS_IDS } from "../constants/pairsConstants.ts";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
} from "../store/storeHooks.ts";
import { CHAIN_TO_SA_TO_LED_MAP } from "../services/leverageDimensionService/leveregeDimensionsParams.ts";
import {
  CHAIN_ID_ARBITRUM_ONE,
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_BSC,
  CHAIN_ID_CELO,
  CHAIN_ID_FANTOM_OPERA,
  CHAIN_ID_FUSE,
  CHAIN_ID_LINEA,
  CHAIN_ID_MANTLE_MAINNET,
  CHAIN_ID_MODE,
  CHAIN_ID_NO_CHAIN,
  CHAIN_ID_OPTIMISM,
  CHAIN_ID_POLYGON,
  LIVE_CHAINS,
  TChainIds,
} from "../constants/chainConstants.ts";
import { IS_ON_PLAYGROUND } from "../configs.ts";
import { buildSettlementAssetSelectionGistListForChainId } from "../hooks/assetHooks.ts";
import { identifyWhiteLabel } from "../whitelabels/whiteLabelHooks.ts";

export const QUERY_PARAMS_KEYS = {
  settlementAsset: "sa",
  chainId: "chainId",
  pairId: "pairId",
  overrideAccount: "acc",

  referral: "ref",
};

function getDefaultNavigationValuesByHost(): {
  chainId: TChainIds;
  settlementAsset: string;
} {
  const {
    isOnAnyWhiteLabel,
    defaultSettlementAssetSymbol,
    whiteLabelDefaultChainId,
  } = identifyWhiteLabel();

  let chainId: TChainIds = CHAIN_ID_NO_CHAIN as TChainIds;
  let settlementAsset = "noAsset";

  if (isOnAnyWhiteLabel) {
    chainId = whiteLabelDefaultChainId;
    settlementAsset = defaultSettlementAssetSymbol;
  } else {
    if (IS_ON_PLAYGROUND) {
      chainId = CHAIN_ID_FUSE;
      settlementAsset = "padi";
    } else {
      chainId = CHAIN_ID_FANTOM_OPERA;
      settlementAsset = "ftm";
    }
  }

  return {
    chainId,
    settlementAsset,
  };
}

export const QUERY_PARAMS_DEFAULTS = getDefaultNavigationValuesByHost();

export const DEFAULT_SA_PER_CHAIN: Record<TChainIds, string> = {
  // fantom
  "250": "ftm",
  // Boba
  "288": "usdc",

  // optimism
  "10": "sonne",
  // BSC
  "56": "lisusd",
  // polygon
  "137": "mai",
  // fuse
  "122": "wfuse",
  // linea
  "59144": "lvc",
  // Mode
  [CHAIN_ID_MODE]: "mode",
  // Arbitrum
  42161: "euros",

  [CHAIN_ID_CELO]: "celo",

  // Mantle
  "5000": "ausd",

  // local
  "1337": "pdai",
  "31337": "pdai",
};

function buildValueListFromChainId(chainId: TChainIds): string[] {
  return buildSettlementAssetSelectionGistListForChainId(chainId).map(
    (v) => v.value,
  );
}

export const SUPPORTED_SETTLEMENT_ASSETS_PER_CHAIN: Record<
  TChainIds,
  string[]
> = {
  // optimism
  "10": buildValueListFromChainId(CHAIN_ID_OPTIMISM),
  // BSC
  "56": buildValueListFromChainId(CHAIN_ID_BSC),
  // polygon
  "137": buildValueListFromChainId(CHAIN_ID_POLYGON),
  // fuse
  "122": buildValueListFromChainId(CHAIN_ID_FUSE),
  // fantom
  "250": buildValueListFromChainId(CHAIN_ID_FANTOM_OPERA),
  // boba
  "288": buildValueListFromChainId(CHAIN_ID_BOBA_MAINNET),
  // linea
  "59144": buildValueListFromChainId(CHAIN_ID_LINEA),
  // Arbitrum
  42161: buildValueListFromChainId(CHAIN_ID_ARBITRUM_ONE),
  // Mantle
  "5000": buildValueListFromChainId(CHAIN_ID_MANTLE_MAINNET),
  // Mode
  [CHAIN_ID_MODE]: buildValueListFromChainId(CHAIN_ID_MODE),
  // Celo
  [CHAIN_ID_CELO]: buildValueListFromChainId(CHAIN_ID_CELO),

  // local
  "1337": ["pdai"],
  "31337": ["pdai"],
};

// ******* Values *******

export function useUrlQueryValue_sa() {
  const paramName = QUERY_PARAMS_KEYS.settlementAsset;
  const [queryPairSettlementAsset, setQueryPairSettlementAsset] = useQueryParam(
    paramName,
    StringParam,
  );

  return {
    paramName,
    queryPairSettlementAsset,
    setQueryPairSettlementAsset,
  };
}

export function useUrlQueryValue_chainId() {
  const paramName = QUERY_PARAMS_KEYS.chainId;
  const [queryChainId, setQueryChainId] = useQueryParam(paramName, StringParam);

  return {
    paramName,
    queryChainId,
    setQueryChainId,
  };
}

export function useUrlQueryValue_overrideAccount() {
  const paramName = QUERY_PARAMS_KEYS.overrideAccount;
  const [accountOverrideValue, setAccountOverrideValue] = useQueryParam(
    paramName,
    StringParam,
  );

  return {
    paramName,
    accountOverrideValue,
    setAccountOverrideValue,
  };
}

export function useUrlQueryValue_referralCode() {
  const paramName = QUERY_PARAMS_KEYS.referral;
  const [referralCodeValue, setAccountOverrideValue] = useQueryParam(
    paramName,
    StringParam,
  );

  return {
    paramName,
    referralCodeValue,
    setAccountOverrideValue,
  };
}

// ******* States *******

export function useUrlQueryState_activePairId() {
  const [queryPairId, setQueryPairId] = useQueryParam(
    QUERY_PARAMS_KEYS.pairId,
    StringParam,
  );

  useEffect(() => {
    console.log(`******* useEffect queryPairId ${queryPairId}`);
    if (!queryPairId) {
      setQueryPairId(PAIR_CONFIGS_IDS.BTC_USD.toString());
    }
  }, [queryPairId, setQueryPairId]);

  return {
    queryPairId,
    setQueryPairId,
  };
}

export function useUrlQueryState_activeSettlementAsset() {
  const cryptoWalletIntegrationStore = useCryptoWalletIntegrationStore();
  const leverageDimensionsStore = useLeverageDimensionsStore();

  const { queryPairSettlementAsset, setQueryPairSettlementAsset } =
    useUrlQueryValue_sa();

  const connectedChainId = cryptoWalletIntegrationStore.chainId;

  // Ensure the param is set
  // TODO : CRITICAL : Use a better default
  useEffect(() => {
    if (!queryPairSettlementAsset) {
      if (connectedChainId == CHAIN_ID_NO_CHAIN) {
        setQueryPairSettlementAsset(QUERY_PARAMS_DEFAULTS.settlementAsset);
      } else {
        if (connectedChainId == QUERY_PARAMS_DEFAULTS.chainId) {
          setQueryPairSettlementAsset(QUERY_PARAMS_DEFAULTS.settlementAsset);
        } else {
          setQueryPairSettlementAsset(DEFAULT_SA_PER_CHAIN[connectedChainId]);
        }
      }
    } else {
      // Ensure the SA is supported in the chain
      if (connectedChainId !== CHAIN_ID_NO_CHAIN) {
        const supportedAssetsPerChain =
          SUPPORTED_SETTLEMENT_ASSETS_PER_CHAIN[connectedChainId] ?? [];

        if (!supportedAssetsPerChain.includes(queryPairSettlementAsset)) {
          setQueryPairSettlementAsset(DEFAULT_SA_PER_CHAIN[connectedChainId]);
        }
      }
    }
  }, [connectedChainId, queryPairSettlementAsset, setQueryPairSettlementAsset]);

  // If sa configured for current chain, set it as active led
  useEffect(() => {
    const configsForChain =
      CHAIN_TO_SA_TO_LED_MAP[cryptoWalletIntegrationStore.chainId];

    const matchingLedParams =
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (configsForChain[queryPairSettlementAsset]?.id as string) ??
      "NO_LED_PARAMS";
    leverageDimensionsStore.setActiveLeD(matchingLedParams);
  }, [
    cryptoWalletIntegrationStore.chainId,
    leverageDimensionsStore,
    queryPairSettlementAsset,
  ]);

  return {
    queryPairSettlementAsset,
    setQueryPairSettlementAsset,
  };
}

export function useUrlQueryState_activeChain() {
  const cryptoWalletIntegrationStore = useCryptoWalletIntegrationStore();

  const { queryChainId, setQueryChainId } = useUrlQueryValue_chainId();

  // Ensure the param is set
  // TODO : Use a better default
  useEffect(() => {
    if (!queryChainId) {
      if (cryptoWalletIntegrationStore.chainId !== CHAIN_ID_NO_CHAIN) {
        setQueryChainId(cryptoWalletIntegrationStore.chainId.toString());
      } else if (!cryptoWalletIntegrationStore.isConnectedToWallet) {
        setQueryChainId(QUERY_PARAMS_DEFAULTS.chainId.toString());
      }
    }
  }, [
    cryptoWalletIntegrationStore.chainId,
    cryptoWalletIntegrationStore.isConnectedToWallet,
    queryChainId,
    setQueryChainId,
  ]);

  const numericQueryChainId = Number(queryChainId);

  useEffect(() => {
    if (
      numericQueryChainId !== cryptoWalletIntegrationStore.chainId &&
      LIVE_CHAINS.includes(numericQueryChainId as TChainIds)
    ) {
      cryptoWalletIntegrationStore.setChainId(numericQueryChainId as TChainIds);
    }
  }, [cryptoWalletIntegrationStore, numericQueryChainId]);

  return {
    queryChainId,
    setQueryChainId,
  };
}
