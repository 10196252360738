import { TKeysOfConst } from "../utils/enumTypes";

export const PAIR_CONFIGS_IDS = {
  BTC_USD: 1,
  ETH_USD: 2,
  BNB_USD: 3,
  FTM_USD: 4,
  SOL_USD: 5,
  // MATIC_USD: 4,
  // AVAX_USD: 5,

  // FOREX
  EUR_USD: 8001,
  GBP_USD: 8002,
  AUD_USD: 8003,
  NZD_USD: 8004,

  // USD_JPY: 8201,
  // USD_CHF: 8202,

  // STOCKS
  // TSLA_USD: 9001,

  // CAT MODE -- CRYPTO
  BTC_USD_CAT: 10_001,
  ETH_USD_CAT: 10_002,
} as const;

export type TPairIds = (typeof PAIR_CONFIGS_IDS)[TKeysOfConst<
  typeof PAIR_CONFIGS_IDS
>];
