import { AddressLike, BigNumberish, BytesLike } from "ethers";
import { UnPromiseOrValue } from "./types";

/**
 * Used to convert the type generated by the latest TypeChain version which uses 'BigNumberish' for clearly BigNumber values.
 */
export type TSafeBigNumberStruct<T extends object> = {
  [K in keyof T]: UnPromiseOrValue<T[K]> extends string
    ? string
    : UnPromiseOrValue<T[K]> extends BytesLike
      ? BytesLike
      : UnPromiseOrValue<T[K]> extends BigNumberish
        ? bigint
        : UnPromiseOrValue<T[K]> extends AddressLike
          ? string
          : UnPromiseOrValue<T[K]> extends object
            ? TSafeBigNumberStruct<UnPromiseOrValue<T[K]>>
            : boolean;
};

/**
 * This functions takes a tuple returned from an 'ethers' contract object and returns it in a pure object form.
 */
export function ethersStructResponseToObject<T extends object>(
  ethersStructResponse: object,
  // NOTE : This is needed due to changes in ethers 6 + typechain that returns weird proxy object for struct which
  //        makes it hard/impossible to get the proper keys of the intended type.
  // TODO : Solve it if possible ?
  emptyConcreteObject: T,
  // NOTE : This is needed because inner array's object's fields cannot be auto inferred (same as with the main object)
  // TODO : Improve type to allow only keys found in the original object
  innerArraysConcreteObjectMap: object = {},

  innerStructsConcreteObjectMap: object = {},
): T {
  const obj = {};

  // for (let key in ethersStructResponse) {
  for (const key of Object.keys(emptyConcreteObject)) {
    // DEV_NOTE : We assume that all numeric keys belongs to the array and ignore them
    if (Number.isNaN(parseInt(key))) {
      // @ts-ignore
      const value: unknown = ethersStructResponse[key];

      // @ts-ignore
      obj[key] = value;

      // const isBigNumber = value instanceof BigNumber || value["_isBigNumber"];
      const isBigNumber = false;

      // BigNumbers are taken 'as is', other objects are cleaned as well
      // This part handles structs within structs
      if (value instanceof Object && !isFunction(value)) {
        if (isBigNumber) {
          // @ts-ignore
          obj[key] = BigInt(value);
          // @ts-ignore
        } else if (innerStructsConcreteObjectMap[key] !== undefined) {
          // @ts-ignore
          obj[key] = ethersStructResponseToObject(
            value as object,
            // @ts-ignore
            innerStructsConcreteObjectMap[key] as object,
          );
        } else if (Array.isArray(value)) {
          if (value.length === 0) {
            // @ts-ignore
            obj[key] = [];
          } else {
            const emptyObjectOfArray =
              // @ts-ignore
              (innerArraysConcreteObjectMap[key] as object) ?? {};

            // console.log(
            //   // @ts-ignore
            //   `@@@@ innerArraysConcreteObjectMap[key] ${innerArraysConcreteObjectMap[key]}`,
            // );

            // @ts-ignore
            obj[key] = ethersStructResponseToArray(
              value as object[],
              emptyObjectOfArray,
              // JSON.parse(stringifyObject(value[0] as object)),
            );
          }
        } else {
          // @ts-ignore
          obj[key] = ethersStructResponseToObject(value) as unknown;
        }
        // @ts-ignore
        // console.log(`key ${key} | obj[key] : ${obj[key]}`);
      }
    }
  }

  return obj as T;
}

export function ethersStructResponseToArray<T extends object>(
  ethersStructResponseArray: object[],
  emptyConcreteObject: T,
  innerArraysConcreteObjectMap: object = {},
): T[] {
  return ethersStructResponseArray.map((struct) =>
    ethersStructResponseToObject<T>(
      struct,
      emptyConcreteObject,
      innerArraysConcreteObjectMap,
    ),
  );
}

function isFunction(functionToCheck: unknown) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}
