import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Stack, Typography } from "@mui/material";
import { ExternalLink } from "../../buildingParts/links/ExternalLink.tsx";
import LynxLogo from "../../Logos/LynxLogo.tsx";
import { PoweredByLynxLogo } from "../../Logos/PoweredByLynxLogo.tsx";
import { useIsMobile } from "../../../ux/uxHooks.ts";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants.ts";
import { useWhiteLabelIdentifier } from "../../../whitelabels/whiteLabelHooks.ts";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
  poweredByAnchor: {
    transition: "300ms ease",
    color: THEME_CONSTANTS.infoTextWhite,
    "&:hover": {
      color: THEME_CONSTANTS.brandYellow,
    },
  },
}));

export const AppFooter = React.memo<IProps>((props) => {
  const { classes } = useStyles();

  const { isOnAnyWhiteLabel } = useWhiteLabelIdentifier();
  const showPoweredBy = isOnAnyWhiteLabel;

  const isOnMobile = useIsMobile();

  return (
    <Box
      paddingBottom={2}
      paddingTop={2}
      // height={"38px"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      gap="5px"
      flexDirection={"column"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      {showPoweredBy && (
        <a href="https://lynx.finance" className={classes.poweredByAnchor}>
          <Stack
            position={isOnMobile ? "initial" : "absolute"}
            left={0}
            marginLeft={"2rem"}
            direction={"row"}
            style={{
              // width: "100%",
              minWidth: "6rem",
              width: "13rem",

              minHeight: `2rem`,
              // height: "100%",

              // backgroundRepeat: "no-repeat",
              // backgroundSize: "cover",

              // alignItems: "center",
              // justifyContent: "center",
            }}
            gap={1}
          >
            <Box>
              <Typography flex={1} variant={"h6"} fontWeight={"bold"}>
                Powered By
              </Typography>
            </Box>
            <Box flex={1} style={{ width: "6rem", height: "2rem" }}>
              <LynxLogo />
            </Box>
            {/*</Link>*/}
          </Stack>
        </a>
      )}
      <Stack direction={"row"} gap={"5px"} justifySelf={"center"}>
        <span>Have questions?</span>
        <Typography>
          <ExternalLink
            href={"https://lynx-finance.gitbook.io/lynx-finance/community/faqs"}
            text={"Q&A"}
          />{" "}
          or{" "}
          <ExternalLink
            href={
              "https://discord.com/channels/1092809273169432646/1092809274482233380"
            }
            text={"Discord"}
          />
        </Typography>
      </Stack>
    </Box>
  );
});
