import { Stack, Typography } from "@mui/material";
import { TokenIcon } from "../buildingParts/icons/TokenIcon.tsx";
import React from "react";
import { BlockChainIcon } from "../buildingParts/icons/BlockChainIcon.tsx";

import WithdrawIcon from "../../assets/appIcons/WithdrawIcon.svg?react";
import DepositIcon from "../../assets/appIcons/DepositIcon.svg?react";
import { THEME_CONSTANTS } from "../../theme/ThemeConstants.ts";

const ICON_TO_TEXT_GAP_REM = 3;

function buildBlockchainCell(chainName: string) {
  // console.log(`chainName ${chainName}`);

  return (
    <Stack
      direction={"row"}
      gap={ICON_TO_TEXT_GAP_REM}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <BlockChainIcon symbol={chainName.toUpperCase()} iconSizeRem={2} />
      <Typography>{chainName}</Typography>
    </Stack>
  );
}

function buildAssetTableCell(assetSymbol: string) {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={ICON_TO_TEXT_GAP_REM}
      // justifyContent={"center"}
      style={
        {
          // border: "1px solid red",
        }
      }
    >
      <Stack flex={1} justifyContent={"end"} direction={"row"}>
        <TokenIcon symbol={assetSymbol} iconSizeRem={2} />
      </Stack>
      <Stack flex={1} justifyContent={"start"} direction={"row"}>
        <Typography>{assetSymbol}</Typography>
      </Stack>
    </Stack>
  );
}

function buildDepositWithdrawActionCell(
  action: "Deposit" | "Withdraw",
  txLinkUrl: string,
) {
  return (
    <a href={txLinkUrl} target={"_blank"} rel={"noreferrer noopener"}>
      <Stack
        // style={{ border: "1px solid red" }}
        direction={"row"}
        gap={ICON_TO_TEXT_GAP_REM}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {action == "Withdraw" ? (
          <WithdrawIcon
            style={
              {
                // width: "2rem",
                // border: "1px solid red"
              }
            }
          />
        ) : (
          <DepositIcon
            style={
              {
                // width: "2rem",
                // border: "1px solid red"
              }
            }
          />
        )}
        <Typography
          style={{
            width: "8ch",
            // border: "1px solid blue"
          }}
        >
          {action}
        </Typography>
      </Stack>
    </a>
  );
}

function buildTextCell(text: string, subTitle?: string) {
  return (
    <Stack direction={"column"}>
      <Typography>{text}</Typography>
      {subTitle && (
        <Typography variant={"caption"} color={THEME_CONSTANTS.titleGray}>
          {subTitle}
        </Typography>
      )}
    </Stack>
  );
}

export const commonTableCellsBuilders = {
  buildBlockchainCell,
  buildAssetTableCell,
  buildDepositWithdrawActionCell,
  buildTextCell,
};
