import React from "react";
import { Provider } from "mobx-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import createCache from "@emotion/cache";

import App from "./App.tsx";
import { configureMobx, getStores } from "./store/storesInitialization.ts";
import { THEME_SINGLE_IMPORT } from "./theme/themeSingleImport.ts";
import { baseTheme } from "./theme/Theme.tsx";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiConfig } from "wagmi";

import {
  fuse,
  boba,
  fantom,
  bsc,
  linea,
  arbitrum,
  optimism,
  polygon,
  mantle,
  mode,
} from "viem/chains";
import { buildServices } from "./services/services.ts";
import { initializeFirebase } from "./services/servicesIntergration/firebaseService/firebaseInitialization.ts";
import {
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_FUSE,
  CHAIN_ID_LINEA,
  CHAIN_ID_MANTLE_MAINNET,
  CHAIN_ID_MODE,
} from "./constants/chainConstants.ts";
import { IS_DEV } from "./configs.ts";
import { ACTIVE_CHAIN_DESCRIPTORS } from "./chainsConfigs.ts";

interface IProps {}

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

configureMobx();
const IS_ON_EXOTIX = false;
// TODO : Get this back after deploying contracts on boba

const queryClient = new QueryClient();

const projectId = "a8d655e619dcce64bb38dadf0960b2c4";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = defaultWagmiConfig({
  // @ts-ignore
  chains: ACTIVE_CHAIN_DESCRIPTORS,
  projectId,
  metadata,
});
createWeb3Modal({
  wagmiConfig,
  projectId,
  // @ts-ignore
  chains: ACTIVE_CHAIN_DESCRIPTORS,
  chainImages: {
    [CHAIN_ID_FUSE]:
      "https://assets-global.website-files.com/63a6d0820bd1f472b4150067/64d34288a22895cbb07b8291_Frame%201000005149.svg",
    [CHAIN_ID_LINEA]: "/chains/chain_LINEA.svg",
    [CHAIN_ID_MANTLE_MAINNET]: "/chains/chain_MANTLE.svg",
    [CHAIN_ID_BOBA_MAINNET]: "/chains/chain_BOBA.svg",
    [CHAIN_ID_MODE]: "/chains/chain_MODE.svg",
  },
});

const services = buildServices();
const stores = getStores(services.leverageDimensionsService);

initializeFirebase();

export const AppWrapper = React.memo<IProps>((props) => {
  return (
    <Provider {...stores} {...services}>
      <StyledEngineProvider injectFirst>
        <THEME_SINGLE_IMPORT.CacheProvider value={muiCache}>
          <ThemeProvider theme={baseTheme}>
            <WagmiConfig config={wagmiConfig}>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </WagmiConfig>
          </ThemeProvider>
        </THEME_SINGLE_IMPORT.CacheProvider>
      </StyledEngineProvider>
    </Provider>
  );
});

export default AppWrapper;
