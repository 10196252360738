import { action, computed, makeObservable, observable } from "mobx";
import {
  EMPTY_LEVERAGE_DIMENSION_PARAMETERS,
  ILeverageDimensionsService,
} from "../../services/leverageDimensionService/ILeverageDiomensionsService";
import { CryptoWalletConnectionStore } from "../CryptoWalletConnectionStore";
import { ContractServicesStore } from "../ContractServicesStore";
import { SystemStore } from "../SystemStore";
import { PricesStore } from "./PricesStore.ts";
import { SingleLexStore } from "../multiInstancesStores/LexStore/SingleLexStore.ts";
import { isSameAddress } from "../../utils/addresses.ts";
import { SingleTradingFloorStore } from "../multiInstancesStores/SingleTradingFloorStore/SingleTradingFloorStore.ts";
import {
  CHAIN_ID_BOBA_MAINNET,
  CHAIN_ID_FANTOM_OPERA,
  TSourceChainIds,
} from "../../constants/chainConstants.ts";
import { ENGINE_CHAIN_ADDRESSES } from "../../constants/chainSystemAddresses.ts";

export class LeverageDimensionsStore {
  @observable isLoading = false;

  @observable tradingFloorStores: SingleTradingFloorStore[];

  @observable emptyLexStore: SingleLexStore;

  @observable
  activeLeverageDimensionId = "";

  @computed get activeLexStore(): SingleLexStore {
    const activeLnStore = this.lexStores.find(
      (ldStore) => ldStore.id === this.activeLeverageDimensionId,
    );

    return activeLnStore ?? this.emptyLexStore;
  }

  @computed get allLexStoresInActiveChain(): SingleLexStore[] {
    const activeLnStore = this.lexStores.filter(
      (ldStore) =>
        ldStore.sourceChainId === this.cryptoWalletIntegrationStore.chainId,
    );

    return activeLnStore;
  }

  public getAllLexStoresByChain(
    sourceChainId: TSourceChainIds,
  ): SingleLexStore[] {
    const activeLnStore = this.lexStores.filter(
      (ldStore) => ldStore.sourceChainId === sourceChainId,
    );

    return activeLnStore;
  }

  @computed get lexStores(): SingleLexStore[] {
    return this.tradingFloorStores.map((tfs) => tfs.lexStores).flat();
  }

  constructor(
    private cryptoWalletIntegrationStore: CryptoWalletConnectionStore,
    private systemStore: SystemStore,
    private contractServicesStore: ContractServicesStore,
    private pricesStore: PricesStore,
    private leverageDimensionsService: ILeverageDimensionsService,
  ) {
    makeObservable(this);

    const ledParameters =
      this.leverageDimensionsService.fetchAllLeverageDimensionsParameters();

    const fantomEngineLexParameters = ledParameters.filter(
      (ld) =>
        ld.lexParams.chipConfigurations.enginChainId === CHAIN_ID_FANTOM_OPERA,
    );

    const bobaEngineLexParameters = ledParameters.filter(
      (ld) =>
        ld.lexParams.chipConfigurations.enginChainId === CHAIN_ID_BOBA_MAINNET,
    );

    this.tradingFloorStores = [
      new SingleTradingFloorStore(
        this.cryptoWalletIntegrationStore,
        this.systemStore,
        this.contractServicesStore,
        this.pricesStore,
        CHAIN_ID_FANTOM_OPERA,
        ENGINE_CHAIN_ADDRESSES[CHAIN_ID_FANTOM_OPERA].tradingFloorProxyAddress,
        fantomEngineLexParameters,
      ),
      new SingleTradingFloorStore(
        this.cryptoWalletIntegrationStore,
        this.systemStore,
        this.contractServicesStore,
        this.pricesStore,
        CHAIN_ID_BOBA_MAINNET,
        ENGINE_CHAIN_ADDRESSES[CHAIN_ID_BOBA_MAINNET].tradingFloorProxyAddress,
        bobaEngineLexParameters,
      ),
    ];

    this.emptyLexStore = new SingleLexStore(
      cryptoWalletIntegrationStore,
      systemStore,
      contractServicesStore,
      pricesStore,
      // TODO : Make sure this is ok
      this.tradingFloorStores[0],
      EMPTY_LEVERAGE_DIMENSION_PARAMETERS,
    );

    this.initializeStore();
  }

  initializeStore() {
    this.setLoadingState(true);

    // TODO : Change this
    this.setActiveLeverageDimensionId(
      this.tradingFloorStores[0].lexStores[0].id,
    );

    this.setLoadingState(false);
  }

  // ****  Getters ****

  public getLexStoreByPoolAddress(poolAddress: string): SingleLexStore {
    return (
      this.lexStores.find((ld) => isSameAddress(ld.poolAddress, poolAddress)) ??
      this.emptyLexStore
    );
  }

  // public refreshLeverageDimensionsState() {
  //   this.tradingFloorStore.lexStores.forEach((ld) => {
  //     ld.refreshFromOutside().catch((e) =>
  //       console.log(`Failed 'refreshFromOutside for LeD ${ld.id}`),
  //     );
  //   });
  // }

  // **** Self Initialization ****

  // **** Active LeD ****
  public setActiveLeD(activeLeverageDimensionId: string) {
    this.setActiveLeverageDimensionId(activeLeverageDimensionId);
  }

  // **** Store State Update ****

  public readDataFroAllStores() {}

  // ****  Observables setter actions ****

  @action("setActiveLeverageDimensionId")
  private setActiveLeverageDimensionId(activeLeverageDimensionId: string) {
    this.activeLeverageDimensionId = activeLeverageDimensionId;
  }

  @action("setLoadingState")
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
