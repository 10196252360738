import { ButtonTheme, ModalTheme, SwitchNetworkTheme } from "./customTheme";
import { TEXT_ON_DARK } from "./GeneralThemeConstants";
import { Property } from "csstype";

const BRAND_YELLOW = "#F8FF22";
const PANEL_GARY_BG = "#313036";
const PANEL_DARK_GARY_BG = "#201F23";
const PAGE_BG = "#000000";

export const THEME_CONSTANTS = {
  pageBg: PAGE_BG,

  brandYellow: BRAND_YELLOW,
  // brandPurple: "#8247E5",
  brandPurple: "#957AFF",
  brandLightPurple: "#B8A9F4",

  B8A9F4panelBgColor: "#121318",
  panelBgColor: PANEL_GARY_BG,
  panelBgDarkColor: PANEL_DARK_GARY_BG,
  panelDarkBgColor: "#080808",
  activeGrayBg: "#232530",
  panelInnerPaddingRem: 1,
  borderGray: "#323338",

  // titleGray: "#6C7080",
  titleGray: "#9895AA",
  infoTextWhite: "#FFFFFF",
  infoTextOffWhite: TEXT_ON_DARK,

  // popupBgGray: "#323546",
  popupBgGray: "#403E47",

  inputGrayBg: "#232530",

  errorTextRed: "#DC2626",
  errorTextRedRGB: "220,38,38",

  brightGreen: "#42E278",
};

export const THEME_STATE_CONSTANTS = {
  // textColorLong: "#03A66D",
  // textColorShort: "#DC2626",
  textColorLong: "#03A66D",
  textColorShort: "#DD434C",

  // positivePnL: "#03A66D",
  // negativePnL: "#DC2626",
  positivePnL: "#03A66D",
  negativePnL: "#DD434C",
};

export const THEME_GRADIENTS = {
  main: {
    start: "#7B61FF",
    middle: "#8247E5",
    end: "#C275FF",
  },
};

export const LONG_BUTTON_THEME: ButtonTheme = {
  borderPxWidth: 1,

  backgroundColor: "transparent",
  // #047857
  borderColor: "rgba(4,120,87,0.6)",
  textColor: "#03A66D",

  backgroundColorHover: "transparent",
  borderColorHover: "#34D399",
  textColorHover: "#03A66D",

  selectedBackgroundColor: THEME_CONSTANTS.activeGrayBg,
  selectedBorderColor: "#34D399",
  selectedTextColor: "#03A66D",

  selectedBackgroundColorHover: THEME_CONSTANTS.activeGrayBg,
  selectedBorderColorHover: "#34D399",
  selectedTextColorHover: "#03A66D",
};

export const SHORT_BUTTON_THEME: ButtonTheme = {
  borderPxWidth: 1,

  backgroundColor: "transparent",
  // #B91C1C
  borderColor: "rgba(185,28,28,0.8)",
  textColor: "#aa2626",

  backgroundColorHover: "transparent",
  borderColorHover: "#EF4444",
  textColorHover: "#DC2626",

  selectedBackgroundColor: THEME_CONSTANTS.activeGrayBg,
  selectedBorderColor: "#EF4444",
  selectedTextColor: "#DC2626",

  selectedBackgroundColorHover: THEME_CONSTANTS.activeGrayBg,
  selectedBorderColorHover: "#EF4444",
  selectedTextColorHover: "#DC2626",
};

export const AFFIRMATIVE_GREEN_COLOR = "#31E5A6";
export const DEAFFIRMATIVE_RED_COLOR = "#E53170";

export const SWITCH_NETWORK_THEME: SwitchNetworkTheme = {
  switchColor: THEME_CONSTANTS.brandPurple,
  backgroundColor: THEME_CONSTANTS.activeGrayBg,
  textColor: THEME_CONSTANTS.infoTextWhite,
};

export const DEFAULT_MODAL_THEME: ModalTheme = {
  backgroundColor: THEME_CONSTANTS.panelBgColor,
  textColor: THEME_CONSTANTS.infoTextWhite,
  messageColor: THEME_CONSTANTS.infoTextOffWhite,
};
