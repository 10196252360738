import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Stack, Typography } from "@mui/material";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants";
import { PendingLimitOrdersTable } from "./tables/PendingLimitOrdersTable";
import { BytesLike } from "ethers";
import {
  useCryptoWalletIntegrationStore,
  usePricesStore,
} from "../../../store/storeHooks";
import { PAIR_CONFIGS_IDS, TPairIds } from "../../../constants/pairsConstants";
import { TClosedPositionGist } from "../../../services/servicesIntergration/graphqlService/IGraphQLService";
import { useChainExplorerUrlsForChainId } from "../../../ux/chainsHooks";
import { TChainIdsWithNone } from "../../../constants/chainConstants";
import { TCompletePositionDataFromLens } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService";
import { PositionPhaseEnums } from "../../../constants/contractEnums";
import { CancelledPositionsTable } from "./tables/CancelledPositionsTable";
import { SingleTradePairStore } from "../../../store/multiInstancesStores/TradePairStore/SingleTradePairStore.ts";
import {
  TabsSelectorRow,
  TabSelectorGist,
  TextToggle,
} from "lynx-ui-components";
import { observer } from "mobx-react";
import { OpenPositionsTableWrapper } from "./tablesWrappers/OpenPositionsTableWrapper.tsx";
import { ClosePositionsTableWrapper } from "./tablesWrappers/ClosedPositionsTableWrapper.tsx";
import { useIsMobile } from "../../../ux/uxHooks.ts";
import { PAIR_ID_TO_NAME } from "../../../constants/pairsDisplayConstants.ts";
import { useRequestWalletConnect } from "../../../hooks/walletConnectionHooks.ts";

interface IProps {
  tradePairStore?: SingleTradePairStore;
  showCloseTrade: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showCancelMarketOpen: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showCancelMarketClose: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showCancelLimitOpen: (
    positionId: BytesLike,
    resetLoadingState: (positionId: BytesLike) => void,
  ) => void;
  showUpdateLimits: (positionId: BytesLike) => void;

  showSharePosition: (positionId: BytesLike) => void;
  showExpandPosition: (positionId: BytesLike) => void;
  showCancelUpdatePositionOrder: (positionId: BytesLike) => void;

  showCancelPendingMarketOrder: (
    positionId: BytesLike,
    isOpenMarket: boolean,
  ) => void;
  showCancelPendingLimitOrder: (positionId: BytesLike) => void;

  relevantChainId: TChainIdsWithNone;
  settlementAssetSymbol: string;
  settlementAssetDecimals: number;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    flex: 1,
    minHeight: "20rem",
    display: "flex",
    flexDirection: "column",
  },
  headerOption: {
    height: "2rem",
    minWidth: "8rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: "0.2rem",

    // marginRight: "0.2rem",
    // marginLeft: "0.2rem",
    transition: "0.5s ease",
    borderBottom: `3px solid ${THEME_CONSTANTS.borderGray}`,
    cursor: "pointer",
  },
  selectedOption: {
    borderColor: "#8247E5",
  },
  unSelectedOption: {
    "&:hover": {
      borderColor: THEME_CONSTANTS.brandLightPurple,
    },
  },
}));

export const PositionsAndOrdersDisplayPanel: React.FC<IProps> = observer(
  (props) => {
    const {
      tradePairStore,
      showCloseTrade,
      showCancelMarketOpen,
      showCancelMarketClose,
      showCancelLimitOpen,
      showCancelUpdatePositionOrder,
      showUpdateLimits,

      showExpandPosition,
      showSharePosition,

      showCancelPendingMarketOrder,
      showCancelPendingLimitOrder,

      relevantChainId,
      settlementAssetSymbol,
      settlementAssetDecimals,
    } = props;
    const { classes, theme } = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedDisplayToggleValue, setSelectedDisplayToggleValue] =
      useState(1);

    const walletConnectionStore = useCryptoWalletIntegrationStore();
    const pricesStore = usePricesStore();

    const requestWalletConnection = useRequestWalletConnect();

    const chainExplorerUrls = useChainExplorerUrlsForChainId(relevantChainId);

    // Quick-n-Dirty : Tracking the length to make this react to changes in the list
    const tradesLength =
      tradePairStore?.activeUserStore.livePositionsInPair.length ?? 0;
    const closedPositionsLength =
      tradePairStore?.activeUserStore.closedPositionGists.length ?? 0;
    const cancelledPositionsLength =
      tradePairStore?.activeUserStore.cancelledPositionGists.length ?? 0;

    // Quick-n-Dirty : Tracking the price to force update in the table when the price changes
    const btcPrice = pricesStore.getPriceForPairId(PAIR_CONFIGS_IDS.BTC_USD);

    const showFromAllAPairs = selectedDisplayToggleValue == 1;

    const livePositions = useMemo(() => {
      const innerTradesLength = tradesLength;
      const innerBtcPrice = btcPrice;

      let safeOpenTrades: TCompletePositionDataFromLens[];

      if (showFromAllAPairs) {
        safeOpenTrades = tradePairStore?.ownLexStore.activeUserStore
          .allLivePositions
          ? [
              ...(tradePairStore?.ownLexStore.activeUserStore
                .allLivePositions ?? []),
            ]
          : [];
      } else {
        safeOpenTrades = tradePairStore?.activeUserStore.livePositionsInPair
          ? [...(tradePairStore?.activeUserStore.livePositionsInPair ?? [])]
          : [];
      }

      return safeOpenTrades;
    }, [
      btcPrice,
      showFromAllAPairs,
      tradePairStore?.activeUserStore.livePositionsInPair,
      tradePairStore?.ownLexStore.activeUserStore.allLivePositions,
      tradesLength,
    ]);

    const liveOpenedPositions = useMemo(() => {
      // const filtered = livePositions.filter(
      //   (p) => p.phase !== PositionPhaseEnums.OPEN_LIMIT,
      // );
      //
      // return filtered;

      return livePositions;
    }, [livePositions]);

    const pendingLimitPositions = useMemo(() => {
      return livePositions.filter(
        (p) => p.phase === PositionPhaseEnums.OPEN_LIMIT,
      );
    }, [livePositions]);

    const closedPositionsGists = useMemo(() => {
      const innerClosedPositionsLength = closedPositionsLength;
      const innerBtcPrice = btcPrice;

      let safeClosedPositions: TClosedPositionGist[];

      if (showFromAllAPairs) {
        safeClosedPositions = tradePairStore?.ownLexStore.activeUserStore
          .activeAccount_allClosedPositions
          ? [
              ...(tradePairStore?.ownLexStore.activeUserStore
                .activeAccount_allClosedPositions ?? []),
            ]
          : [];
      } else {
        safeClosedPositions = tradePairStore?.activeUserStore
          .closedPositionGists
          ? [...(tradePairStore?.activeUserStore.closedPositionGists ?? [])]
          : [];
      }

      return safeClosedPositions;
    }, [
      btcPrice,
      closedPositionsLength,
      showFromAllAPairs,
      tradePairStore?.activeUserStore.closedPositionGists,
      tradePairStore?.ownLexStore.activeUserStore
        .activeAccount_allClosedPositions,
    ]);

    const cancelledPositionsGists = useMemo(() => {
      const innerCancelledPositionsLength = cancelledPositionsLength;

      let safeClosedPositions: TClosedPositionGist[];

      if (showFromAllAPairs) {
        safeClosedPositions = tradePairStore?.ownLexStore.activeUserStore
          .activeAccount_allCancelledPositions
          ? [
              ...(tradePairStore?.ownLexStore.activeUserStore
                .activeAccount_allCancelledPositions ?? []),
            ]
          : [];
      } else {
        safeClosedPositions = tradePairStore?.activeUserStore
          .cancelledPositionGists
          ? [...(tradePairStore?.activeUserStore.cancelledPositionGists ?? [])]
          : [];
      }

      return safeClosedPositions;
    }, [
      cancelledPositionsLength,
      showFromAllAPairs,
      tradePairStore?.activeUserStore.cancelledPositionGists,
      tradePairStore?.ownLexStore.activeUserStore
        .activeAccount_allCancelledPositions,
    ]);

    const getPriceForPairInUnits = useCallback(
      (pairId: TPairIds) => {
        return pricesStore.getPriceForPairId(pairId);
      },
      [pricesStore],
    );

    const getCloseFeeFPairInUnits = useCallback(
      (pairId: TPairIds) => {
        return (
          tradePairStore?.ownLexStore?.getTradePairStoreById(pairId)
            ?.closeFeeFInUnits ?? 0
        );
      },
      [tradePairStore?.ownLexStore],
    );

    const content = useMemo(() => {
      if (selectedTab === 0) {
        return (
          <>
            <OpenPositionsTableWrapper
              isConnectedToWallet={walletConnectionStore.isConnectedToWallet}
              requestWalletConnection={requestWalletConnection}
              liveOpenedPositions={liveOpenedPositions}
              underlyingSymbol={settlementAssetSymbol}
              underlyingDecimals={settlementAssetDecimals}
              showCloseTrade={showCloseTrade}
              showCancelMarketOpen={showCancelMarketOpen}
              showCancelMarketClose={showCancelMarketClose}
              showCancelLimitOpen={showCancelLimitOpen}
              showUpdateLimits={showUpdateLimits}
              showExpandPosition={showExpandPosition}
              showSharePosition={showSharePosition}
              getPriceInUnitsForPair={getPriceForPairInUnits}
              getCloseFeeFInUnitsForPair={getCloseFeeFPairInUnits}
            />
          </>
        );
        // return <div>Trades</div>;
      } else if (selectedTab === 1) {
        return (
          <PendingLimitOrdersTable
            pendingLimitPositions={pendingLimitPositions}
            showCancelPendingLimitOrder={showCancelPendingLimitOrder}
          />
        );
      } else if (selectedTab === 2) {
        return (
          <>
            <ClosePositionsTableWrapper
              isConnectedToWallet={walletConnectionStore.isConnectedToWallet}
              requestWalletConnection={requestWalletConnection}
              closedPositionGists={closedPositionsGists}
              underlyingSymbol={settlementAssetSymbol}
              underlyingDecimals={settlementAssetDecimals}
              showExpandPosition={showExpandPosition}
              showSharePosition={showSharePosition}
            />
          </>
        );
      } else if (selectedTab === 3) {
        return (
          <CancelledPositionsTable
            cancelledPositionGists={cancelledPositionsGists}
            settlementAssetSymbol={settlementAssetSymbol}
            settlementAssetDecimals={settlementAssetDecimals}
            explorerTxBaseUrl={chainExplorerUrls.txUrlBase}
          />
        );
      } else {
        return <Typography>None</Typography>;
      }
    }, [
      selectedTab,
      walletConnectionStore.isConnectedToWallet,
      requestWalletConnection,
      liveOpenedPositions,
      settlementAssetSymbol,
      settlementAssetDecimals,
      showCloseTrade,
      showCancelMarketOpen,
      showCancelMarketClose,
      showCancelLimitOpen,
      showUpdateLimits,
      showExpandPosition,
      showSharePosition,
      getPriceForPairInUnits,
      getCloseFeeFPairInUnits,
      pendingLimitPositions,
      showCancelPendingLimitOrder,
      closedPositionsGists,
      cancelledPositionsGists,
      chainExplorerUrls.txUrlBase,
    ]);

    const tabsSelectors = useMemo<TabSelectorGist[]>(() => {
      return [
        {
          text: "Positions",
          value: 0,
        },
        // {
        //   text: "Orders",
        //   value: 1,
        // },
        {
          text: "History",
          value: 2,
        },
        {
          text: "Cancelled",
          value: 3,
        },
      ];
    }, []);

    const pairName =
      PAIR_ID_TO_NAME[tradePairStore?.pairId ?? (-1 as TPairIds)] ?? "";

    return (
      <div className={classes.root}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          padding={0.5}
          sx={{
            [theme.breakpoints.down("sm")]: {
              gap: 1,
              justifyContent: "flex-start",
              marginBottom: 1,
            },
          }}
        >
          {/* Tabs */}
          <TabsSelectorRow
            isLabelCentered
            tabSelectors={tabsSelectors}
            value={selectedTab}
            onChange={(value) => setSelectedTab(value)}
          />

          <TextToggle
            value={selectedDisplayToggleValue}
            onChange={setSelectedDisplayToggleValue}
            options={[
              {
                text: pairName,
                value: 0,
              },
              {
                text: "All",
                value: 1,
              },
            ]}
          />
        </Stack>

        {/* Content */}
        <Box
          sx={{
            flex: {
              xs: "1",
              md: 1,
            },
            display: "flex",
            flexDirection: {
              xs: "column",
              // md: "row",
            },
            justifyContent: "center",
          }}
        >
          {content}
        </Box>
      </div>
    );
  },
);
