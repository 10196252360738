function didAmountOfTimePassedSince(
  amountOfTimeInSeconds: number,
  timestamp: number,
) {
  const now = Math.floor(Date.now() / 1000);

  return now > timestamp + amountOfTimeInSeconds;
}

function currentTimeInSeconds() {
  return Math.floor(Date.now() / 1000);
}

function geTimePartsUntilNextMonday() {
  const now = new Date();
  const dayOfWeek = now.getUTCDay();
  const daysUntilMonday = (8 - dayOfWeek) % 7; // Days until next Monday

  // If today is Monday, add 7 days to get to the next Monday
  const nextMonday = new Date(now);
  if (daysUntilMonday === 0) {
    nextMonday.setDate(now.getDate() + 7);
  } else {
    nextMonday.setDate(now.getDate() + daysUntilMonday);
  }
  nextMonday.setHours(0, 0, 0, 0); // start time of the day

  const nextMondayTimestamp = getNextMondayTimestamp();
  const timeDifference = nextMondayTimestamp - Math.floor(now.getTime() / 1000); // get timestamp

  const millisecondsPerMinute = 60;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;

  const days = Math.floor(timeDifference / millisecondsPerDay);
  const hours = Math.floor(
    (timeDifference % millisecondsPerDay) / millisecondsPerHour,
  );
  const minutes = Math.floor(
    (timeDifference % millisecondsPerHour) / millisecondsPerMinute,
  );

  return { days, hours, minutes };
}

function getNextMondayTimestamp(): number {
  const now = new Date();
  const currentDay = now.getUTCDay();
  const daysUntilNextMonday = (8 - currentDay) % 7 || 7;

  const nextMonday = new Date(
    now.getTime() + daysUntilNextMonday * 24 * 60 * 60 * 1000,
  );
  nextMonday.setUTCHours(0, 0, 0, 0);

  return Math.floor(nextMonday.getTime() / 1000);
}

function timestampToLocalDateTimeString(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  return date.toDateString();
  // return date.toLocaleString(undefined, {
  //   year: "numeric",
  //   day: "2-digit",
  //   month: "2-digit",
  //   // hour: "2-digit",
  //   // minute: "2-digit",
  //   // second: "2-digit",
  //   hour12: false, // Use 24-hour format
  //   weekday: "short",
  // });
}

export const timeUtils = {
  didAmountOfTimePassedSince,
  currentTimeInSeconds,
  geTimePartsUntilNextMonday,
  timestampToLocalDateTimeString,
};
