export function isSameAddress(addressA: string, addressB: string) {
  return addressA.toLowerCase() === addressB.toLowerCase();
}

export function isSameTxHash(hashA: string, hashB: string) {
  return hashA.toLowerCase() === hashB.toLowerCase();
}

export function isSameStringCaseInsensitive(a: string, b: string) {
  return a.toLowerCase() === b.toLowerCase();
}
