import {
  arbitrum,
  boba,
  bsc,
  celo,
  fantom,
  fuse,
  mantle,
  mode,
  optimism,
  polygon,
} from "viem/chains";
import { IS_DEV } from "./configs.ts";

const PROD_CHAINS = [
  fantom,
  boba,
  arbitrum,
  fuse,
  bsc,
  optimism,
  polygon,
  mantle,
  mode,
];
const DEV_CHAINS = [
  ...PROD_CHAINS,
  celo,
  // linea
];

export const ACTIVE_CHAIN_DESCRIPTORS = IS_DEV ? DEV_CHAINS : PROD_CHAINS;
export const ACTIVE_CHAIN_IDS = ACTIVE_CHAIN_DESCRIPTORS.map(
  (chain) => chain.id,
);
export type TActiveChainIds = (typeof ACTIVE_CHAIN_IDS)[number];
