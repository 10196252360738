import { useEffect, useState } from "react";
import { GraphQLService } from "../../services/servicesIntergration/graphqlService/GraphQLService.ts";
import { useMap } from "@uidotdev/usehooks";
import {
  useLeverageDimensionsStore,
  useLexStoreByAddress,
} from "../../store/storeHooks.ts";
import { SingleLexStore } from "../../store/multiInstancesStores/LexStore/SingleLexStore.ts";
import { TUsageRoundTraderGraphInfo } from "../../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import { LED_IDS } from "../../services/leverageDimensionService/leveregeDimensionsParams.ts";
import { getManualCompetitionDescriptor } from "../../incentives/manualIncentives.ts";
import { TCompetitionPlanIncentiveDescription } from "../../services/servicesIntergration/incentivesService/IIncentivesService.ts";

async function syncLeaderBoardRoundTradersInfo(
  lexStore: SingleLexStore,
  usageRoundNumber: number,
) {
  const roundTradersInfo = await readUsageRoundTradersGraphInfo(
    lexStore.graphUrl,
    lexStore.poolAddress,
    usageRoundNumber,
  );

  sortUsageRoundTradersInfo(roundTradersInfo);

  lexStore.replaceCurrentRoundUsageTradersInfos(roundTradersInfo);
}

export function useSyncLeaderBoardRoundTradersInfo(
  poolAddress: string,
  usageRoundNumber: number,
) {
  const leverageDimensionsStore = useLeverageDimensionsStore();

  // Note : ref to object so no need to memoize
  const lexStore = useLexStoreByAddress(poolAddress);

  useEffect(() => {
    if (!lexStore.isNoneLexStore) {
      syncLeaderBoardRoundTradersInfo(lexStore, usageRoundNumber).catch((e) =>
        console.error(
          `Failed syncLeaderBoardRoundTradersInfo ${lexStore.id} ${usageRoundNumber}`,
        ),
      );
    }
  }, [lexStore, usageRoundNumber]);
}

export type TAdHocHistoricRoundCompetitionGist = {
  descriptor: TCompetitionPlanIncentiveDescription;
  tradersRoundInfo: TUsageRoundTraderGraphInfo[];
  getRewardsByRank: (rank: number) => number;
  roundNumber: number;
};

export function useGetHistoricCompetitionDescriptors(poolAddress: string) {
  const [historicCompetitionsGists, setHistoricCompetitionsGists] = useState<
    TAdHocHistoricRoundCompetitionGist[]
  >([]);

  // Note : ref to object so no need to memoize
  const lexStore = useLexStoreByAddress(poolAddress);

  useEffect(() => {
    if (lexStore.id === LED_IDS.FANTOM_ENGINE_ARBITRUM_EUROS) {
      readAndBuildHistoricCompetitionGist(
        lexStore.graphUrl,
        lexStore.poolAddress,
        lexStore.id,
        [14, 13, 12],
      )
        .then((historicGists) => setHistoricCompetitionsGists(historicGists))
        .catch((e) =>
          console.error(`useGetHistoricCompetitionDescriptors :: ${e}`),
        );
    } else {
      setHistoricCompetitionsGists([]);
    }
  }, [lexStore.graphUrl, lexStore.id, lexStore.poolAddress]);

  return { historicCompetitionsGists };
}

async function readAndBuildHistoricCompetitionGist(
  graphURL: string,
  poolAddress: string,
  lexId: string,
  roundNumbers: number[],
): Promise<TAdHocHistoricRoundCompetitionGist[]> {
  const historicGists: TAdHocHistoricRoundCompetitionGist[] = [];

  for (const roundNumber of roundNumbers) {
    const pastDescriptor = getManualCompetitionDescriptor(lexId, roundNumber);

    const tradersInfo = await readUsageRoundTradersGraphInfo(
      graphURL,
      poolAddress,
      roundNumber,
    );
    sortUsageRoundTradersInfo(tradersInfo);

    const rewardsArray = pastDescriptor.details.incentivesList[0].ranks;

    const historicGist: TAdHocHistoricRoundCompetitionGist = {
      tradersRoundInfo: tradersInfo,
      getRewardsByRank: function (rank: number): number {
        if (rewardsArray.length == 0 || rank > rewardsArray.length) {
          return 0;
        } else {
          return rewardsArray[rank - 1];
        }
      },
      descriptor: pastDescriptor,
      roundNumber,
    };

    historicGists.push(historicGist);
  }

  return historicGists;
}

async function readUsageRoundTradersGraphInfo(
  graphURL: string,
  poolAddress: string,
  roundNumber: number,
): Promise<TUsageRoundTraderGraphInfo[]> {
  const graphQlService = new GraphQLService(graphURL);

  const roundTradersInfo = await graphQlService.getLeaderBoardRoundTraders(
    poolAddress,
    roundNumber,
  );

  return roundTradersInfo;
}

function sortUsageRoundTradersInfo(
  usageRoundTradersInfo: TUsageRoundTraderGraphInfo[],
) {
  usageRoundTradersInfo.sort((a, b) => {
    const totalPnl_A =
      a.totalPositivePnl -
      a.totalNegativePnl +
      a.totalOpenFeesPaid +
      a.totalCloseFeesPaid;
    const totalPnl_B =
      b.totalPositivePnl -
      b.totalNegativePnl +
      b.totalOpenFeesPaid +
      b.totalCloseFeesPaid;
    return totalPnl_B > totalPnl_A ? 1 : -1;
  });
}

export function useGetLeaderBoardRoundTradersInfo(
  graphUrl: string,
  poolAddress: string,
  usageRoundNumber: number,
): {
  usageRoundTradersInfo: TUsageRoundTraderGraphInfo[];
  dataLoaded: boolean;
} {
  // const [usageRoundTradersInfo, setUsageRoundTradersInfo] = useState<
  //   TusageRoundTraders[]
  // >([]);

  const tradersInfoMap = useMap<string>();
  const idDataLoadedMap = useMap<string>();

  // const [usageRoundTradersInfo, setUsageRoundTradersInfo] = useLocalStorage<
  //   TusageRoundTraders[]
  // >(`usageRoundTradersInfo_${poolAddress}_${usageRoundNumber}`, []);

  useEffect(() => {
    const graphQlService = new GraphQLService(graphUrl);

    // setUsageRoundTradersInfo([]);

    if (poolAddress && usageRoundNumber) {
      graphQlService
        .getLeaderBoardRoundTraders(poolAddress, usageRoundNumber)
        .then((roundTradersInfo) => {
          roundTradersInfo.sort((a, b) => {
            const totalPnl_A = a.totalPositivePnl - a.totalNegativePnl;
            const totalPnl_B = b.totalPositivePnl - b.totalNegativePnl;
            return totalPnl_B > totalPnl_A ? 1 : -1;
          });
          // setUsageRoundTradersInfo(roundTradersInfo);
          tradersInfoMap.set(poolAddress, roundTradersInfo);
          idDataLoadedMap.set(poolAddress, true);
          // setUsageRoundTradersInfo(roundTradersInfo);

          // console.log(
          //   `!!!! Just set ${roundTradersInfo.length} entries for ${poolAddress}-${usageRoundNumber}`,
          // );
        })
        .catch((e) => {
          console.error(`Failed getting usage round trader info for pool ${e}`);
          console.error(e);
        });
    }
  }, [
    graphUrl,
    poolAddress,
    usageRoundNumber,
    tradersInfoMap,
    idDataLoadedMap,
    // setUsageRoundTradersInfo,
    // usageRoundTradersInfo,
  ]);

  // console.log(`!!!! usageRoundTradersInfo ${usageRoundTradersInfo.length}`);

  return {
    usageRoundTradersInfo:
      // usageRoundTradersInfo,
      (tradersInfoMap.get(poolAddress) as TUsageRoundTraderGraphInfo[]) ?? [],
    dataLoaded: (idDataLoadedMap.get(poolAddress) as boolean) ?? false,
  };
}
