import { ethers } from "ethers";

/**
 * Mimics the payload that's created by '_encodeSendPayload' in 'OFTCoreV2'
 */
function encodeSendPayload(receiverAddress: string, amount: bigint) {
  return ethers.solidityPacked(
    ["bytes32", "uint64"],
    [addressToBytes32(receiverAddress), amount],
  );
}

function addressToBytes32(address: string) {
  const addressAsBytes32 = ethers.AbiCoder.defaultAbiCoder().encode(
    ["address"],
    [address],
  );

  return addressAsBytes32;
}

function removeDustForOFTChipAmount(rawAmount: bigint): bigint {
  const oftChipDecimals = 18;
  const sharedDecimals = 6;

  const decimalConversionRate = 10n ** BigInt(oftChipDecimals - sharedDecimals);

  // NOTE : Mimics the function '_removeDust' from 'OFTCore.sol'
  // function _removeDust(uint256 _amountLD) internal view virtual returns (uint256 amountLD) {
  //         return (_amountLD / decimalConversionRate) * decimalConversionRate;
  //     }

  const noDustAmount =
    (rawAmount / decimalConversionRate) * decimalConversionRate;

  return noDustAmount;
}

function removeDustForOFTChipAdapterAmount(
  rawAmount: bigint,
  sourceUnderlyingDecimals: number,
): bigint {
  const sharedDecimals = 6;

  const decimalConversionRate =
    10n ** BigInt(sourceUnderlyingDecimals - sharedDecimals);

  // NOTE : Mimics the function '_removeDust' from 'OFTCore.sol'
  // function _removeDust(uint256 _amountLD) internal view virtual returns (uint256 amountLD) {
  //         return (_amountLD / decimalConversionRate) * decimalConversionRate;
  //     }

  const noDustAmount =
    (rawAmount / decimalConversionRate) * decimalConversionRate;

  return noDustAmount;
}

export const layerZeroPayloadUtils = {
  encodeSendPayload,
  addressToBytes32,
  removeDustForOFTChipAmount,
  removeDustForOFTChipAdapterAmount,
};
