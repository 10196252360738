import { CHAIN_ID_FANTOM_OPERA, TChainIds } from "./constants/chainConstants";
import { WHITE_LABEL_PARMAS } from "./whitelabels/whiteLabelConstants.ts";

export const READ_ONLY_OPTIMISM_ENDPOINT = "https://optimism.llamarpc.com";

export const READ_ONLY_FUSE_ENDPOINT = "https://rpc.fuse.io";
export const READ_ONLY_MATIC_MAINNET_ENDPOINT =
  "https://rpc-mainnet.maticvigil.com/";

export const READ_ONLY_FANTOM_TESTNET_ENDPOINT =
  "https://rpc.testnet.fantom.network/";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpc.ftm.tools";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpc3.fantom.network";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpc2.fantom.network";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpcapi.fantom.network";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpc.ankr.com/fantom";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpcapi.fantom.network";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpc.ankr.com/fantom";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://fantom.drpc.org";
export const READ_ONLY_FANTOM_OPERA_ENDPOINT =
  "https://fantom-rpc.publicnode.com";
// export const READ_ONLY_FANTOM_OPERA_ENDPOINT = "https://rpc.fantom.gateway.fm";

export const READ_ONLY_CELO_ENDPOINT = "https://forno.celo.org";
export const READ_ONLY_AVALANCHE_C_CHAIN_ENDPOINT =
  "https://api.avax.network/ext/bc/C/rpc";

export const READ_ONLY_MOONBEAM_ENDPOINT = "https://rpc.api.moonbeam.network";

export const READ_ONLY_BSC_ENDPOINT = "https://bsc-dataseed.binance.org/";
export const READ_ONLY_BSC_TESTNET_ENDPOINT =
  "https://data-seed-prebsc-1-s1.binance.org:8545/";

// export const READ_ONLY_BOBA_ENDPOINT = "https://mainnet.boba.network/";
export const READ_ONLY_BOBA_ENDPOINT = "https://replica.boba.network";
export const READ_ONLY_BOBA_GOERLI_ENDPOINT = "https://goerli.boba.network/";

export const READ_ONLY_KCC_ENDPOINT = "https://rpc-mainnet.kcc.network";

export const READ_ONLY_MANTLE_MAINNET_ENDPOINT = "https://rpc.mantle.xyz";
export const READ_ONLY_MANTLE_TESTNET_ENDPOINT =
  "https://rpc.testnet.mantle.xyz";

export const READ_ONLY_LINEA_MAINNET_ENDPOINT = "https://rpc.linea.build";

// export const READ_ONLY_ARBITRUM_ONE_ENDPOINT = "https://arbitrum.llamarpc.com";
export const READ_ONLY_ARBITRUM_ONE_ENDPOINT = "https://1rpc.io/arb";

export const READ_ONLY_POLYGON_ENDPOINT = "https://polygon.rpc.blxrbdn.com";

export const READ_ONLY_MODE_ENDPOINT = "https://mainnet.mode.network";

export const READ_ONLY_ETHEREUM_MAINNET_ENDPOINT = import.meta.env
  .REACT_APP_ETHEREUM_MAINNET_RPC as string;
export const READ_ONLY_ETHEREUM_ROPSTEN_ENDPOINT =
  (import.meta.env.REACT_APP_ETHEREUM_ROPSTEN_RPC as string) ?? "";

// TODO : Go over these "env reading" configs and make sure they are needed and works

export const isForcedProd = import.meta.env.REACT_APP_USE_PROD === "true";

export const IS_ON_MAIN_LYNX_DOMAIN =
  window.location.hostname === "app.lynx.finance";

export const IS_ON_PLAYGROUND =
  window.location.hostname === "lev-playground.web.app" ||
  window.location.hostname.includes("lev-playground--") ||
  window.location.hostname === "localhost";

export const IS_PROD = isForcedProd || import.meta.env.PROD;
// isForcedProd || import.meta.env.NODE_ENV === "production";

export const IS_DEV = (!IS_PROD && !isForcedProd) || IS_ON_PLAYGROUND;

// export const CONFIGS_DEFAULT_CHAIN_ID: TChainIds = parseInt(
//   (import.meta.env.REACT_APP_DEFAULT_CHAIN_ID as string) ?? "-1",
// ) as TChainIds;

// export const IS_ON_EXOTIX = window.location.hostname === "exotix.lynx.finance";
