import { ethers } from "ethers";

function generateVerificationDomain(
  chainId: string,
  chipIntentsVerifierAddress: string,
): ethers.TypedDataDomain {
  const verificationDomain: ethers.TypedDataDomain = {
    chainId: chainId,
    name: "Chips Intents Verifier",
    version: "1",
    verifyingContract: chipIntentsVerifierAddress,
  };

  return verificationDomain;
}

const HOLDER_REQUEST_PAYLOAD_TYPE_DEFINITION_CHIP_OUT_LZ: Record<
  string,
  ethers.TypedDataField[]
> = {
  HolderRequestPayload_ChipOutLZ: [
    { name: "nonce", type: "uint256" },
    { name: "chip", type: "address" },
    { name: "holder", type: "address" },
    { name: "receiver", type: "address" },
    { name: "amount", type: "uint256" },
  ],
};

export const chipsIntentsVerifierPayloadTypes = {
  buildDomainSeparator: generateVerificationDomain,
  HOLDER_REQUEST_PAYLOAD_TYPE_DEFINITION_CHIP_OUT_LZ,
};
