import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  Fade,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Color from "color";
import { makeStyles } from "tss-react/mui";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants";
import { useModalClassesForActiveLeD } from "../modalHooks/modalsThemeHooks";
import { ModalTheme } from "../../../theme/customTheme";
import { useIsMobile } from "../../../ux/uxHooks.ts";
import { toastInfo } from "../../../ux/toasts/toasting.ts";
import { PanelActionButton, PositionUpdateModalBody } from "lynx-ui-components";
import { TCompletePositionDataFromLens } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import { unitsBnToFloat } from "../../../utils/bignumbers.ts";
import { PRICES_SCALE } from "../../../constants/scales.ts";
import { TPairIds } from "../../../constants/pairsConstants.ts";
import { numericalDisplay } from "../../../ux/displayCalculations.ts";
import { PRICE_UX_FOR_PAIRS } from "../../../ux/assetsUxPrecisions.ts";
import { priceBnToFloat } from "../../../utils/lynxScalesUtils.ts";
import { usePositionPanel } from "../../../hooks/positionPanelHooks.ts";
import { OpenOrderTypeEnums } from "../../../constants/contractEnums.ts";
import {
  lossMaxLimit,
  positionPanelConstants,
  precisionDecimalsToWorkWith,
  profitMaxLimit,
  slDefaultValue,
  tpDefaultValue,
} from "../../../constants/positionPanelConstants.ts";
import {
  calculateStopLoss,
  calculateTakeProfit,
  getLossSubTitle,
  getProfitSubTitle,
  getProfitSubValue,
  getSLDecriptor,
  getTPDecriptor,
} from "../../../utils/positionUtilFunctions.ts";
import { scaledLeverageToUnits } from "../../../utils/leverageCalculationsUtils.ts";
import { numbersUtils } from "../../../utils/numbers.ts";
import { localPriceStringer } from "../../../ux/uxFunctions.ts";

interface IProps {
  open: boolean;
  closeModal: () => void;
  pos: TCompletePositionDataFromLens | undefined;

  modalTheme: ModalTheme;

  requestTpUpdate: (fieldValueInUnits: number) => void;
  requestSlUpdate: (fieldValueInUnits: number) => void;
  requestTpAndSlUpdate: (
    tpValueInUnits: number,
    slValueInUnits: number,
  ) => void;
}

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(178, 181, 190, 0.38)",
        },
      },
    },
  },
});

const useStyles = makeStyles()((theme) => ({
  logoSpan: {
    height: `28px`,
    width: `100%`,
    backgroundImage: `url(/assets/lynx/logo.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "transparent",
  },
  wrapper: {
    borderTopLeftRadius: "1.5rem",
    borderTopRightRadius: "1.5rem",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "0",
      overflow: "auto",
    },
  },
  body: {
    padding: "0 1.5rem 1.5rem 1.5rem",
    backgroundColor: THEME_CONSTANTS.pageBg,
  },
  header: {
    padding: "1.5rem",
    backgroundColor: THEME_CONSTANTS.pageBg,
  },
  panel: {
    justifyContent: "flex-end",
  },
  disconnectLink: {
    cursor: "pointer",

    "--baseColor": THEME_CONSTANTS.brandPurple,
    "--hoverColor": Color(THEME_CONSTANTS.brandPurple).darken(0.2).hex(),
    color: `var(--baseColor)`,

    "&:hover": {
      color: `var(--hoverColor)`,
    },
  },
  errorView: {
    backgroundColor: "#DD434C20",
    padding: "1px 12px",
    borderRadius: "8px",
    lineHeight: "2",
    color: "#DD434C",
    textAlign: "center",
    marginTop: "5px",
  },
}));

export const PositionUpdateModal = React.memo<IProps>((props) => {
  const {
    open,
    closeModal,
    modalTheme,
    pos,
    requestTpUpdate,
    requestSlUpdate,
    requestTpAndSlUpdate,
  } = props;
  const { classes, cx } = useStyles();
  const { classes: modalClasses } = useModalClassesForActiveLeD(modalTheme);

  const isMobile = useIsMobile();

  const [slInputValue, setSlInputValue] = useState("");
  const [tpInputValue, setTpInputValue] = useState("");
  const [slValue, setSlValue] = useState(slDefaultValue);
  const [tpValue, setTpValue] = useState(tpDefaultValue);
  const [wantedMinOpenPrice, setWantedMinOpenPrice] = useState(0);
  const [wantedMaxOpenPrice, setWantedMaxOpenPrice] = useState(0);

  // ******  Position Data ******

  const sanitizedCurrentTP = parseFloat(
    unitsBnToFloat(pos?.tp ?? 0n, PRICES_SCALE).toFixed(
      precisionDecimalsToWorkWith,
    ),
  );
  const sanitizedCurrentSL = parseFloat(
    unitsBnToFloat(pos?.sl ?? 0n, PRICES_SCALE).toFixed(
      precisionDecimalsToWorkWith,
    ),
  );
  const wantedLeverage = scaledLeverageToUnits(
    Number(unitsBnToFloat(pos?.leverage ?? 0n, 0)),
  );
  const pairId = unitsBnToFloat(pos?.pairId ?? 0n, 0) as TPairIds;
  const isLong = pos?.long ?? false;
  const entryPrice = priceBnToFloat(pos?.openPrice ?? 0n);

  const pairPriceDisplayDecimals = PRICE_UX_FOR_PAIRS[pairId];

  const entryPriceDisplayStr = numericalDisplay(
    entryPrice,
    pairPriceDisplayDecimals,
  );

  const profitSubTitle = getProfitSubTitle(tpValue);
  const lossSubTitle = getLossSubTitle(slValue);
  const [slippagePInUnits, setlippagePIUnitas] = useState("0.5"); // 0.5%
  const { slPrice, tpPrice } = usePositionPanel({
    pairAssetPrice: entryPrice,
    openOrderType: OpenOrderTypeEnums.MARKET,
    slippagePInUnits,
    isLong,
    slMultiplierBaseInput: slInputValue,
    slMultiplier: slValue,
    openPrice: entryPrice,
    leverage: wantedLeverage,
    tpMultiplierBaseInput: tpInputValue,
    tpMultiplier: tpValue,
    wantedMinOpenPrice,
    wantedMaxOpenPrice,
  });

  const tpDescriptor = getTPDecriptor(tpPrice, pairPriceDisplayDecimals);
  const slDescriptor = getSLDecriptor(slPrice, pairPriceDisplayDecimals);

  useEffect(() => {
    setTpInputValue(sanitizedCurrentTP.toFixed(2).toString());
  }, [sanitizedCurrentTP]);
  useEffect(() => {
    setSlInputValue(sanitizedCurrentSL.toFixed(2).toString());
  }, [sanitizedCurrentSL]);

  const isTryingToUpdateTP = +sanitizedCurrentTP.toFixed(2) !== Number(tpPrice);
  const isTryingToUpdateSL = +sanitizedCurrentSL.toFixed(2) !== Number(slPrice);
  const isTryingToUpdateBothTpAndSl = isTryingToUpdateTP && isTryingToUpdateSL;

  const { buttonText, actionAllowed, deactivationReason, buttonAction } =
    useMemo(() => {
      let buttonText = "Update";
      let deactivationReason = "";
      let actionAllowed = true;
      let buttonAction: () => void = () => undefined;
      if (!tpPrice && !slPrice) {
        actionAllowed = false;
      }
      if (isTryingToUpdateBothTpAndSl) {
        buttonText = "Update TP and SL";
        buttonAction = () =>
          requestTpAndSlUpdate(Number(tpPrice), Number(slPrice));
      } else if (isTryingToUpdateTP) {
        buttonText = "Update TP";
        buttonAction = () => requestTpUpdate(Number(tpPrice));
      } else if (isTryingToUpdateSL) {
        buttonText = "Update SL";
        buttonAction = () => requestSlUpdate(Number(slPrice));
      } else {
        buttonText = "Update";
        buttonAction = () => toastInfo("No Change in values");
      }

      const entryPriceValue =
        numbersUtils.parseFloatFromLocalString(entryPriceDisplayStr);
      const wantedInputTpMultiplier = Number(tpInputValue);
      const wantedInputSlMultiplier = Number(slInputValue);
      const hasWantedTpInputValue = !!tpInputValue;
      const hasWantedSlInputValue = !!slInputValue;
      const hasWantedTpSelectedValue = !!tpValue;
      const hasWantedSlSelectedValue = !!slValue;

      if (isLong) {
        if (
          isTryingToUpdateSL &&
          hasWantedSlInputValue &&
          wantedInputSlMultiplier > entryPriceValue
        ) {
          actionAllowed = false;
          deactivationReason = `Stop Loss is greater than entry price`;
        }
        if (
          isTryingToUpdateTP &&
          hasWantedTpInputValue &&
          wantedInputTpMultiplier < entryPriceValue
        ) {
          actionAllowed = false;
          deactivationReason = `Take Profit is less than entry price`;
        }
      } else {
        if (
          isTryingToUpdateSL &&
          hasWantedSlInputValue &&
          wantedInputSlMultiplier < entryPriceValue
        ) {
          actionAllowed = false;
          deactivationReason = `Stop Loss is less than entry price`;
        }
        if (
          isTryingToUpdateTP &&
          hasWantedTpInputValue &&
          wantedInputTpMultiplier > entryPriceValue
        ) {
          actionAllowed = false;
          deactivationReason = `Take Profit is greater than entry price`;
        }
      }

      if (
        isTryingToUpdateTP &&
        !hasWantedTpSelectedValue &&
        !hasWantedTpInputValue
      ) {
        actionAllowed = false;
        deactivationReason = "Take profit required";
      }
      if (
        isTryingToUpdateSL &&
        !hasWantedSlInputValue &&
        !hasWantedSlSelectedValue
      ) {
        actionAllowed = false;
        deactivationReason = "Stop loss reuired";
      }

      if (isTryingToUpdateSL && (slValue > 80 || slValue < -80)) {
        actionAllowed = false;
        deactivationReason = `Stop loss out of range`;
      }

      if (isTryingToUpdateTP && (tpValue > 900 || tpValue < -900)) {
        actionAllowed = false;
        deactivationReason = `Take profit out of range`;
      }

      return {
        buttonText,
        actionAllowed,
        deactivationReason,
        buttonAction,
      };
    }, [
      entryPriceDisplayStr,
      isLong,
      isTryingToUpdateBothTpAndSl,
      isTryingToUpdateSL,
      isTryingToUpdateTP,
      requestSlUpdate,
      requestTpAndSlUpdate,
      requestTpUpdate,
      slInputValue,
      slPrice,
      slValue,
      tpInputValue,
      tpPrice,
      tpValue,
    ]);

  const onLossOptionClickHandle = useCallback(
    (value: number) => {
      setSlInputValue("");
      setSlValue(value || 0);
    },
    [setSlInputValue, setSlValue],
  );

  const onProfitOptionClickHandle = useCallback(
    (value: number) => {
      setTpValue(value || 0);
      setTpInputValue("");
    },
    [setTpValue, setTpInputValue],
  );

  useEffect(() => {
    if (slInputValue) {
      const wantedStopLossFractionInUnits = parseFloat(slInputValue);
      const stopLoss = calculateStopLoss(
        entryPrice,
        wantedLeverage,
        wantedStopLossFractionInUnits,
        pos?.long === true,
      );
      setSlValue(stopLoss);
    }
  }, [wantedLeverage, slInputValue, pos?.long, entryPrice]);

  useEffect(() => {
    if (tpInputValue) {
      const wantedTakeProfitFractionInUnits = parseFloat(tpInputValue);
      const takeProfit = calculateTakeProfit(
        entryPrice,
        wantedLeverage,
        wantedTakeProfitFractionInUnits,
        pos?.long === true,
      );
      setTpValue(takeProfit);
    }
  }, [wantedLeverage, tpInputValue, pos?.long, entryPrice]);

  useEffect(() => {
    if (!open) {
      setSlInputValue(sanitizedCurrentSL.toFixed(2).toString());
      setTpInputValue(sanitizedCurrentTP.toFixed(2).toString());
    }
  }, [open, sanitizedCurrentSL, sanitizedCurrentTP]);

  const calcProfitSubValue = getProfitSubValue(profitSubTitle);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        className={modalClasses.modal}
        // onBackdropClick={closeModal}
        onClose={closeModal}
        fullScreen={isMobile}
        classes={{
          paper: modalClasses.dialogBasePaper,
        }}
      >
        <Fade in={open}>
          <div
            className={cx([modalClasses.panel, classes.panel])}
            // quick hack to make sure the address is visible
            style={{ minWidth: isMobile ? undefined : "max-content" }}
          >
            <div className={classes.wrapper}>
              {/* Quick-n-Dirty x button */}
              <div className={modalClasses.upperRightCorner}>
                <IconButton
                  onClick={closeModal}
                  className={modalClasses.headerXButton}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {/* Header */}
              <div className={cx([modalClasses.panelHeader, classes.header])}>
                <div className={classes.logoSpan} />
              </div>

              {/*/!* Tab Body *!/*/}
              <div className={cx([modalClasses.modalBody, classes.body])}>
                <Typography>
                  Open Price{" "}
                  {localPriceStringer(entryPrice, pairPriceDisplayDecimals)}
                </Typography>
                <PositionUpdateModalBody
                  lossTitle="Stop Loss"
                  lossSubTitle={wantedLeverage ? `${lossSubTitle}%` : ""}
                  lossUnit={slDescriptor}
                  lossOptions={positionPanelConstants.normalPairsLimits.slMarks}
                  lossValue={slInputValue}
                  lossValueSelected={slValue}
                  lossMaxLimit={lossMaxLimit}
                  onLossChange={setSlInputValue}
                  onLossOptionClick={onLossOptionClickHandle}
                  profitTitle="Take profit"
                  profitSubTitle={wantedLeverage ? calcProfitSubValue : ""}
                  profitUnit={tpDescriptor}
                  profitOptions={
                    positionPanelConstants.normalPairsLimits.tpMarks
                  }
                  profitValue={tpInputValue}
                  profitValueSelected={tpValue}
                  profitMaxLimit={profitMaxLimit}
                  onProfitChange={setTpInputValue}
                  onProfitOptionClick={onProfitOptionClickHandle}
                />

                <Box width="100%" mt="16px">
                  <PanelActionButton
                    text={buttonText}
                    onClick={buttonAction}
                    disabled={!actionAllowed}
                    fullWidth
                  />
                  <div className={classes.errorView}>{deactivationReason}</div>
                </Box>
              </div>
            </div>
          </div>
        </Fade>
      </Dialog>
    </ThemeProvider>
  );
});
