import { useMediaQuery, useTheme } from "@mui/material";

export function summarizeAddress(address?: string, sidLength = 3): string {
  if (!address) {
    return "Refresh to connect";
  }

  const startIndex = address.startsWith("0x") ? 2 : 0;
  return `${address.substr(startIndex, sidLength)}...${address.substr(
    address.length - sidLength,
    address.length,
  )}`;
}

export function useIsMobile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return isMobile;
}
