import { TKeysOfConst } from "../utils/enumTypes.ts";

export const FEE_CONFIGS_IDS = {
  BASIC_CRYPTO_PAIR: 1,
  CAT_CRYPTO_PAIR: 2,
  BASIC_FOREX_PAIR: 3,
};

export type TFeeIds = (typeof FEE_CONFIGS_IDS)[TKeysOfConst<
  typeof FEE_CONFIGS_IDS
>];
