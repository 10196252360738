import { useCallback, useEffect, useState } from "react";
import { readGeoLocationFromIp } from "../hooks/ipBlockHooks.ts";

export function useShouldBlockIp(): boolean {
  const [shouldBlock, setShouldBlock] = useState(false);

  const reactToGeoIp = useCallback(async () => {
    const geoRes = await readGeoLocationFromIp();

    if (
      geoRes &&
      (geoRes.location.country === "US" || geoRes.location.country === "us")
    ) {
      setShouldBlock(true);
    } else {
      setShouldBlock(false);
    }
  }, []);

  useEffect(() => {
    reactToGeoIp().catch((e) => console.error(`Failed GeoIp reading : ${e}`));
  }, [reactToGeoIp]);

  return shouldBlock;
}
