import { stringifyObject } from "./strings.ts";
import { TCompletePositionDataFromLens } from "../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import {
  TChipHistoryActionGist,
  TClosedPositionGist,
  type TEpochGraphInfo,
  TLiquidityRequestGist,
  type TUsageRoundGraphInfo,
  TUserInteractionsRecordGraphInfo,
} from "../services/servicesIntergration/graphqlService/IGraphQLService.ts";
import type {
  TLexFeeConfigsLensStruct,
  TLexGroupConfigsLensStruct,
} from "../services/contractsIntegration/LexLensService/ILexLensService.ts";

function serializeAnyObject(obj: object) {
  return stringifyObject(obj);
}

function serializeAnyObjectArray(objArr: object[]) {
  return JSON.stringify(objArr.map((obj) => serializeAnyObject(obj)));
}

function deserializeAnyObjectArray<T>(
  arrStr: string,
  deserializeSingleObject: (valString: string) => T,
): T[] {
  const arr = JSON.parse(arrStr) as string[];

  const objArr = arr.map((objStr) => deserializeSingleObject(objStr));

  return objArr;
}

// ****** CompletePositionDateFromLens ******
function deserializeCompletePositionDataFromLens(
  valString: string,
): TCompletePositionDataFromLens {
  // TODO : Add type for serialized object
  const raw = JSON.parse(valString) as TCompletePositionDataFromLens;
  // const raw = rawVal as TCompletePositionDataFromLens;
  return {
    id: raw.id,
    settlementAsset: raw.settlementAsset,
    trader: raw.trader,
    pairId: BigInt(raw.pairId),
    positionIndex: BigInt(raw.positionIndex),
    collateral: BigInt(raw.collateral),
    phase: BigInt(raw.phase),
    inPhaseSince: BigInt(raw.inPhaseSince),
    leverage: BigInt(raw.leverage),
    long: raw.long,
    openPrice: BigInt(raw.openPrice),
    spreadReductionF: BigInt(raw.spreadReductionF),
    minPrice: BigInt(raw.minPrice),
    maxPrice: BigInt(raw.maxPrice),
    tpLastUpdated: BigInt(raw.tpLastUpdated),
    slLastUpdated: BigInt(raw.slLastUpdated),
    tp: BigInt(raw.tp),
    sl: BigInt(raw.sl),
    pendingUpdateOrderType: BigInt(
      raw.pendingUpdateOrderType as unknown as string,
    ),
    pendingUpdateOrderTimestamp: BigInt(raw.pendingUpdateOrderTimestamp),
    pendingUpdateOrderFieldAValue: BigInt(raw.pendingUpdateOrderFieldAValue),
    pendingUpdateOrderFieldBValue: BigInt(raw.pendingUpdateOrderFieldBValue),

    funding: BigInt(raw.funding),
    borrowInterest: BigInt(raw.borrowInterest),
    liquidationPrice: BigInt(raw.liquidationPrice),
  };
}

// ****** TClosedPositionGistFromGraph ******
function deserializeClosetPositionGistFromGraph(
  valString: string,
): TClosedPositionGist {
  // TODO : Add type for serialized object
  const raw = JSON.parse(valString) as TClosedPositionGist;
  return {
    isMarketPriceRangeCancelled: raw.isMarketPriceRangeCancelled,
    isMarketTimeoutCancelled: raw.isMarketTimeoutCancelled,
    isOpenCapCancelled: raw.isOpenCapCancelled,
    long: raw.long,
    leverageInUnits: raw.leverageInUnits,
    borrowInterestPaidOnClose: BigInt(raw.borrowInterestPaidOnClose),
    cancellationCapValue: BigInt(raw.cancellationCapValue),
    cancellationPriceOnMarketOpen: BigInt(raw.cancellationPriceOnMarketOpen),
    closeFee: BigInt(raw.closeFee),
    closePrice: BigInt(raw.closePrice),
    fundingFeesPaidOnClose: BigInt(raw.fundingFeesPaidOnClose),
    initialCollateral: BigInt(raw.initialCollateral),
    openFeeForLexPool: BigInt(raw.openFeeForLexPool),
    openFeeForSystem: BigInt(raw.openFeeForSystem),
    openOrderCancellationFee: BigInt(raw.openOrderCancellationFee),
    openPrice: BigInt(raw.openPrice),
    percentProfit: BigInt(raw.percentProfit),
    sl: BigInt(raw.sl),
    tp: BigInt(raw.tp),
    pairId: raw.pairId,
    closeTimestamp: raw.closeTimestamp,
    positionValueOnClose: raw.positionValueOnClose,
    openTimestamp: raw.openTimestamp,
    positionIndex: raw.positionIndex,
    slLastUpdate: raw.slLastUpdate,
    tpLastUpdate: raw.tpLastUpdate,
    sameIdCounter: raw.sameIdCounter,
    cancellationTx: raw.cancellationTx,
    canceller: raw.canceller,
    cancellationCapType: raw.cancellationCapType,
    closeTx: raw.closeTx,
    closedAs: raw.closedAs,
    entityId: raw.entityId,
    openOrderCancellationSourceRole: raw.openOrderCancellationSourceRole,
    openTx: raw.openTx,
    openedAs: raw.openedAs,
    positionId: raw.positionId,
    requestCloseTx: raw.requestCloseTx,
    requestOpenTx: raw.requestOpenTx,
    settlementAsset: raw.settlementAsset,
    traderAddress: raw.traderAddress,
  };
}

// ****** TLiquidityRequestGist ******
function deserializeTLiquidityRequestGistFromGraph(
  valString: string,
): TLiquidityRequestGist {
  const raw = JSON.parse(valString) as TLiquidityRequestGist;

  return {
    isImmediate: raw.isImmediate,
    assetAmount: BigInt(raw.assetAmount),
    processEpoch: raw.processEpoch,
    processTimestamp: raw.processTimestamp,
    poolAddress: raw.poolAddress,
    processTxHash: raw.processTxHash,
    providerAddress: raw.providerAddress,
    type: raw.type,
  };
}

// ****** TChipHistoryActionGist ******
function deserializeTChipHistoryActionGistFromGraph(
  valString: string,
): TChipHistoryActionGist {
  const raw = JSON.parse(valString) as TChipHistoryActionGist;

  return {
    amount: BigInt(raw.amount),
    fee: BigInt(raw.fee),
    inOut: raw.inOut,
    timestamp: raw.timestamp,
    txHash: raw.txHash,
    userAddress: raw.userAddress,
  };
}

// ****** TUsageRoundGraphInfo ******
function deserializeTUsageRoundGraphInfoFromGraph(
  valString: string,
): TUsageRoundGraphInfo {
  const raw = JSON.parse(valString) as TUsageRoundGraphInfo;

  return {
    totalCloseFeesPaid: BigInt(raw.totalCloseFeesPaid),
    totalNegativePnl: BigInt(raw.totalNegativePnl),
    totalOpenFeesPaid: BigInt(raw.totalOpenFeesPaid),
    totalPositivePnl: BigInt(raw.totalPositivePnl),
    endTimestamp: raw.endTimestamp,
    positionsOpenedCounter: raw.positionsOpenedCounter,
    roundNumber: raw.roundNumber,
    startBlock: raw.startBlock,
    startTimestamp: raw.startTimestamp,
    poolAddress: raw.poolAddress,
  };
}

// ****** TEpochGraphInfo ******
function deserializeTEpochGraphInfoFromGraph(
  valString: string,
): TEpochGraphInfo {
  const raw = JSON.parse(valString) as TEpochGraphInfo;

  return {
    amountDeposited: BigInt(raw.amountDeposited),
    amountRedeemed: BigInt(raw.amountRedeemed),
    exchangeRate: BigInt(raw.exchangeRate),
    blockNumber: raw.blockNumber,
    epochNumber: raw.epochNumber,
    timestamp: raw.timestamp,
    poolAddress: raw.poolAddress,
  };
}

// ****** TUserInteractionsRecordGraphInfo ******
function deserializeTUserInteractionsRecordGraphInfo(
  valString: string,
): TUserInteractionsRecordGraphInfo {
  const raw = JSON.parse(valString) as TUserInteractionsRecordGraphInfo;

  return {
    interactionEntityId_MarketCloseCancelled:
      raw.interactionEntityId_MarketCloseCancelled,
    interactionEntityId_MarketOpenCancelled:
      raw.interactionEntityId_MarketOpenCancelled,
    interactionEntityId_PositionClosed: raw.interactionEntityId_PositionClosed,
    interactionEntityId_PositionOpened: raw.interactionEntityId_PositionOpened,
    interactionEpochNumber_PoolEpochDepositProcessed:
      raw.interactionEpochNumber_PoolEpochDepositProcessed,
    interactionEpochNumber_PoolEpochRedeemProcessed:
      raw.interactionEpochNumber_PoolEpochRedeemProcessed,
    interactionEpochNumber_PoolImmediateDeposit:
      raw.interactionEpochNumber_PoolImmediateDeposit,
    interactionTx_ChipIn: raw.interactionTx_ChipIn,
    interactionTx_ChipOut: raw.interactionTx_ChipOut,
    latestTimestamp_ChipIn: raw.latestTimestamp_ChipIn,
    latestTimestamp_ChipOut: raw.latestTimestamp_ChipOut,
    latestTimestamp_MarketCloseCancelled:
      raw.latestTimestamp_MarketCloseCancelled,
    latestTimestamp_MarketOpenCancelled:
      raw.latestTimestamp_MarketOpenCancelled,
    latestTimestamp_PoolEpochDepositProcessed:
      raw.latestTimestamp_PoolEpochDepositProcessed,
    latestTimestamp_PoolEpochRedeemProcessed:
      raw.latestTimestamp_PoolEpochRedeemProcessed,
    latestTimestamp_PoolImmediateDeposit:
      raw.latestTimestamp_PoolImmediateDeposit,
    latestTimestamp_PositionClosed: raw.latestTimestamp_PositionClosed,
    latestTimestamp_PositionOpened: raw.latestTimestamp_PositionOpened,
    poolAddress: raw.poolAddress,
    userAddress: raw.userAddress,
  };
}

function deserializeTLexFeeConfigsLensStruct(
  valString: string,
): TLexFeeConfigsLensStruct {
  const raw = JSON.parse(valString) as TLexFeeConfigsLensStruct;

  return {
    feeId: raw.feeId,
    closeFeeF: raw.closeFeeF,
    openFeeF: raw.openFeeF,
  };
}

function deserializeTLexGroupConfigsLensStruct(
  valString: string,
): TLexGroupConfigsLensStruct {
  const raw = JSON.parse(valString) as TLexGroupConfigsLensStruct;

  return {
    groupId: raw.groupId,
    maxBorrowF: raw.maxBorrowF,
    maxLeverage: BigInt(raw.maxLeverage),
    maxPositionSize: BigInt(raw.maxPositionSize),
    minLeverage: BigInt(raw.minLeverage),
  };
}

// ****** TEpochGraphInfo ******

export const entitiesSerialization = {
  serializeAnyObjectArray,
  deserializeAnyObjectArray,

  completePositionDataFromLens: {
    serialize: serializeAnyObject,
    deserialize: deserializeCompletePositionDataFromLens,
  },
  closedPositionGistFromGraph: {
    serialize: serializeAnyObject,
    deserialize: deserializeClosetPositionGistFromGraph,
  },
  liquidityRequestGist: {
    serialize: serializeAnyObject,
    deserialize: deserializeTLiquidityRequestGistFromGraph,
  },
  chipHistoryActionGist: {
    serialize: serializeAnyObject,
    deserialize: deserializeTChipHistoryActionGistFromGraph,
  },
  usageRoundGraphInfo: {
    serialize: serializeAnyObject,
    deserialize: deserializeTUsageRoundGraphInfoFromGraph,
  },
  epochGraphInfo: {
    serialize: serializeAnyObject,
    deserialize: deserializeTEpochGraphInfoFromGraph,
  },

  interactionsRecord: {
    serialize: serializeAnyObject,
    deserialize: deserializeTUserInteractionsRecordGraphInfo,
  },

  feesConfigurations: {
    serialize: serializeAnyObject,
    deserialize: deserializeTLexFeeConfigsLensStruct,
  },
  groupsConfigurations: {
    serialize: serializeAnyObject,
    deserialize: deserializeTLexGroupConfigsLensStruct,
  },
};
