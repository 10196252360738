import type { IObservableArray } from "mobx/dist/internal";
import type { TEngineChainIds } from "../../../constants/chainConstants.ts";
import { action, computed, makeObservable, observable } from "mobx";
import { SingleLexStore } from "../LexStore/SingleLexStore.ts";
import { ILeverageDimensionParameters } from "../../../services/leverageDimensionService/ILeverageDiomensionsService.ts";
import { CryptoWalletConnectionStore } from "../../CryptoWalletConnectionStore.ts";
import { SystemStore } from "../../SystemStore.ts";
import { ContractServicesStore } from "../../ContractServicesStore.ts";
import { PricesStore } from "../../singleInstanceStores/PricesStore.ts.ts";
import { makePersistable } from "mobx-persist-store";
import type { ITradingFloorLensService } from "../../../services/contractsIntegration/TradingFloorLensService/ITradingFloorLensService.ts";
import type { ILexLensService } from "../../../services/contractsIntegration/LexLensService/ILexLensService.ts";
import { isSameAddress } from "../../../utils/addresses.ts";

export class SingleTradingFloorStore {
  @observable isLoading = false;
  @observable didReadPoolConfigs = false;

  @observable chainId: TEngineChainIds;
  @observable proxyAddress: string;

  @observable
  leverageDimensionsParameters: IObservableArray<ILeverageDimensionParameters> =
    observable([]);

  @observable
  lexStores: IObservableArray<SingleLexStore> = observable([]);

  // **** Global Trading Floor Values ****

  @observable maxTradesPerPair = 0;
  @observable maxSlF = 0;
  @observable maxSanityProfitF = 0;

  // **** Services computed value ****

  @computed
  public get freshTradeCenterLensService(): ITradingFloorLensService {
    const chainId = this.chainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const address = systemContracts?.tradingCenterLens;

    if (!address) {
      console.log(`No tradeCenterLens Address for ${chainId}`);
    }

    return this.contractServicesStore.buildTradingCenterLensService(
      address ??
        `freshTradeCenterLensService : No tradeCenterLens Defined For ${chainId}`,
      chainId,
      true,
    );
  }

  @computed
  public get freshLexLensService(): ILexLensService {
    const chainId = this.chainId;
    const systemContracts = this.systemStore.systemContractsMap.get(chainId);

    const leverageVaultLensAddress = systemContracts?.lexLensAddress;

    if (!leverageVaultLensAddress) {
      console.log(`No leverageVaultLens Address for ${chainId}`);
    }

    return this.contractServicesStore.buildLexLensService(
      leverageVaultLensAddress ??
        `freshLeverageVaultLensService : No LeverageVaultLens Defined For ${chainId}`,
      chainId,
      true,
    );
  }

  constructor(
    private cryptoWalletIntegrationStore: CryptoWalletConnectionStore,
    private systemStore: SystemStore,
    private contractServicesStore: ContractServicesStore,
    private pricesStore: PricesStore,
    chainId: TEngineChainIds,
    proxyAddress: string,
    ledParameters: ILeverageDimensionParameters[],
  ) {
    makeObservable(this);

    // void makePersistable(this, {
    //   name: `SingleTradingFloorStore_${chainId}`,
    //   properties: [],
    // });

    this.chainId = chainId;
    this.proxyAddress = proxyAddress;

    this.setLeverageDimensionsParameters(ledParameters);

    this.initializeStore();
  }

  // ****  Self Initialization ****

  initializeStore() {
    this.setLoadingState(true);

    // Create a store for each LeD
    this.buildStoresForEachLeD();

    // Initialize each store
    this.initializeLeDStores();

    this.setLoadingState(false);
  }

  private buildStoresForEachLeD() {
    const ledStores = this.leverageDimensionsParameters.map((params) => {
      return this.buildLeXStore(params);
    });

    this.setLexStoresStores(ledStores);
  }

  private buildLeXStore(
    ldParams: ILeverageDimensionParameters,
  ): SingleLexStore {
    // Build the store
    const lnStore = new SingleLexStore(
      this.cryptoWalletIntegrationStore,
      this.systemStore,
      this.contractServicesStore,
      this.pricesStore,
      this,
      ldParams,
    );

    return lnStore;
  }

  /**
   * Calls 'initialize' on each store
   */
  private async initializeLeDStores() {
    await this.readAllData();

    // TODO : CRITICAL : Remove this in favour of single call reading
    // this.lexStores.forEach((lnStore) => {
    //   lnStore
    //     .initialize()
    //     .catch((e) => console.error(`Failed lnStore initialize ${e}`));
    // });
  }

  // ****  State Syncing ****

  public async refreshFromOutside() {
    await this.readAllData();
  }

  private async readAllData() {
    // Note : Read if not yet read
    if (this.maxTradesPerPair == 0) {
      await this.batchReadAndUpdateTradeParams().catch((e: Error) =>
        console.error(`Failed batchReadAndUpdateTradeParams ${e.toString()}`),
      );
    }

    const poolAddresses = this.lexStores.map((store) => store.poolAddress);

    if (!this.didReadPoolConfigs) {
      try {
        await this.batchReadAndUpdatePoolsConfigs(poolAddresses);
      } catch (e) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        console.error(`Failed batchReadAndUpdatePoolsConfigs ${e.toString()}`);
      }
    }

    await this.batchReadAndUpdatePoolsStates(poolAddresses).catch((e: Error) =>
      console.error(`Failed batchReadAndUpdatePoolsStates ${e.toString()}`),
    );
  }

  private async batchReadAndUpdateTradeParams() {
    const tradeParams =
      await this.freshTradeCenterLensService.getTradingFloorTradeParams(
        this.proxyAddress,
      );

    this.setMaxTradesPerPair(Number(tradeParams.maxTradesPerPair));
    this.setMaxSlF(Number(tradeParams.maxSlF));
    this.setMaxSanityProfitF(Number(tradeParams.maxSanityProfitF));
  }

  private async batchReadAndUpdatePoolsConfigs(poolAddresses: string[]) {
    const completeLexesConfigs =
      await this.freshLexLensService.getCompleteConfigsForLexes(poolAddresses);

    for (const completeLexConfigs of completeLexesConfigs) {
      const poolAddressFromConfig =
        completeLexConfigs.lexPoolConfigurations.lexPool;

      const matchingLexStore = this.lexStores.find((lexStore) =>
        isSameAddress(lexStore.poolAddress, poolAddressFromConfig),
      );

      if (!matchingLexStore) {
        console.error(
          `No matching LexStore for poolConfigs ${poolAddressFromConfig}`,
        );
        continue;
      }

      matchingLexStore.setConfigsFromCompleteLensData(completeLexConfigs);
    }

    this.setDidReadPoolConfigs(true);
  }

  private async batchReadAndUpdatePoolsStates(poolAddresses: string[]) {
    const completeLexesStates =
      await this.freshLexLensService.getCompleteStateForLexes(poolAddresses);

    for (const completeLexState of completeLexesStates) {
      const poolAddressFromState = completeLexState.lexPoolState.lexPool;

      const matchingLexStore = this.lexStores.find((lexStore) =>
        isSameAddress(lexStore.poolAddress, poolAddressFromState),
      );

      if (!matchingLexStore) {
        console.error(
          `No matching LexStore for lexState ${poolAddressFromState}`,
        );
        continue;
      }

      matchingLexStore.setStatesFromCompleteLensData(completeLexState);
    }

    this.setDidReadPoolConfigs(true);
  }

  // ****  Observables setter actions ****

  @action("setLeverageDimensionsParameters")
  private setLeverageDimensionsParameters(
    leverageDimensionsParameters: ILeverageDimensionParameters[],
  ) {
    this.leverageDimensionsParameters.replace(leverageDimensionsParameters);
  }

  @action("setLexStoresStores")
  private setLexStoresStores(leverageDimensionsStores: SingleLexStore[]) {
    this.lexStores.replace(leverageDimensionsStores);
  }

  @action("setMaxTradesPerPair")
  private setMaxTradesPerPair(maxTradesPerPair: number): void {
    this.maxTradesPerPair = maxTradesPerPair;
  }

  @action("setMaxSlF")
  private setMaxSlF(maxSlF: number): void {
    this.maxSlF = maxSlF;
  }

  @action("setMaxSanityProfitF")
  private setMaxSanityProfitF(maxSanityProfitF: number): void {
    this.maxSanityProfitF = maxSanityProfitF;
  }

  @action("setLoadingState")
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action("setDidReadPoolConfigs")
  private setDidReadPoolConfigs(didRead: boolean) {
    this.didReadPoolConfigs = didRead;
  }
}
