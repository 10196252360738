export type TAssetParameters = {
  name: string;
  shortName: string;
  symbol: string;
  address: string;
  decimals: number;
};

export const EMPTY_ASSET_PARAMETERS: TAssetParameters = {
  address: "",
  symbol: "",
  name: "",
  shortName: "",
  decimals: 0,
};
