import React, { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import {
  ActiveRewardsTable,
  TActiveRewardsTableRowGist,
} from "./ActiveRewardsTable.tsx";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
  useModalsStore,
  usePricesStore,
} from "../../../store/storeHooks.ts";
import { useRequestWalletConnect } from "../../../hooks/walletConnectionHooks.ts";
import { useSwitchChain } from "wagmi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QUERY_PARAMS_KEYS } from "../../../navigation/navigationStateHooks.ts";
import { createFilteredQueryString } from "lynx-ui-components";
import { useWhiteLabelIdentifier } from "../../../whitelabels/whiteLabelHooks.ts";
import { TChainIds } from "../../../constants/chainConstants.ts";

interface IProps {}

export const ConnectedActiveRewardsTable = observer<React.FC<IProps>>(
  (props) => {
    const cryptoWalletConnectionStore = useCryptoWalletIntegrationStore();
    const requestConnectToWallet = useRequestWalletConnect();
    const { switchChain } = useSwitchChain();

    const leverageDimensionsStore = useLeverageDimensionsStore();

    const pricesStore = usePricesStore();

    // TODO : Find a way to memoize this
    // NOTE : This will break the 'memo' but also allows to react to changes in the store
    const lexStoresWithLiveCompetitionPlan =
      leverageDimensionsStore.lexStores.filter(
        (lexStore) => lexStore.currentCompetitionDescription.roundNumber > 0,
      );

    const activeRewardsRowGists = useMemo<TActiveRewardsTableRowGist[]>(() => {
      const rowGists: TActiveRewardsTableRowGist[] = [];

      for (const lexStore of lexStoresWithLiveCompetitionPlan) {
        if (lexStore.currentCompetitionDescription.roundNumber > 0) {
          const competitionPlan = lexStore.currentCompetitionDescription;

          for (const incentiveDetail of competitionPlan.details
            .incentivesList) {
            const rewardSymbol = incentiveDetail.rewardSymbol;
            const totalWeeklyCompetitionRewardsInUnits =
              incentiveDetail.ranks.reduce((acc, rank) => {
                return acc + rank;
              }, 0);

            const rowGist: TActiveRewardsTableRowGist = {
              lexId: lexStore.id,
              sourceChainId: lexStore.sourceChainId,
              assetSymbol: lexStore.sourceAssetParameters.symbol,
              rewardAssetSymbol: rewardSymbol,
              totalWeeklyCompetitionRewardsInUnits:
                totalWeeklyCompetitionRewardsInUnits,
              rewardAssetPriceInUnits:
                pricesStore.symbolToPriceMap.get(rewardSymbol) ?? 0,

              connectedTraderEstimatedRewardsInUnits: 0,
            };

            rowGists.push(rowGist);
          }
        }
      }

      return rowGists;
    }, [lexStoresWithLiveCompetitionPlan, pricesStore.symbolToPriceMap]);

    const { isOnAnyWhiteLabel, whiteLabelRoutePrefix } =
      useWhiteLabelIdentifier();
    const whitelabelRoutePrefix = isOnAnyWhiteLabel
      ? `/${whiteLabelRoutePrefix}`
      : "";

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const matchingSearchParamsString = createFilteredQueryString(searchParams, [
      QUERY_PARAMS_KEYS.chainId,
    ]);

    const goToTrade = useCallback(
      (saSymbol: string) => {
        const url = `${whitelabelRoutePrefix}/leverage?${matchingSearchParamsString}&${QUERY_PARAMS_KEYS.settlementAsset}=${saSymbol.toLowerCase()}&${QUERY_PARAMS_KEYS.pairId}=${1}`;
        console.log(`Navigating to ${url}`);
        navigate(url);
      },
      [matchingSearchParamsString, navigate, whitelabelRoutePrefix],
    );

    // const goToLeaderboard = useCallback(
    //   (chainId: TChainIds, saSymbol: string) => {
    //     const url = `${whitelabelRoutePrefix}/leaderBoard?${QUERY_PARAMS_KEYS.chainId}=${chainId}&${QUERY_PARAMS_KEYS.settlementAsset}=${saSymbol.toLowerCase()}`;
    //     console.log(`Navigating to ${url}`);
    //     navigate(url);
    //   },
    //   [navigate, whitelabelRoutePrefix],
    // );

    const modalStore = useModalsStore();
    const goToLeaderboard = useCallback(
      (lexId: string) => {
        // const url = `${whitelabelRoutePrefix}/leaderBoard?${QUERY_PARAMS_KEYS.chainId}=${chainId}&${QUERY_PARAMS_KEYS.settlementAsset}=${saSymbol.toLowerCase()}`;
        // console.log(`Navigating to ${url}`);
        // navigate(url);
        modalStore.showLeaderboardModal(lexId);
      },
      [modalStore],
    );

    return (
      <ActiveRewardsTable
        rowGists={activeRewardsRowGists}
        connectedChainId={cryptoWalletConnectionStore.chainId}
        requestConnectToWalletFunction={requestConnectToWallet}
        isConnectedToWallet={cryptoWalletConnectionStore.isConnectedToWallet}
        switchChainFunction={(chainId) => switchChain({ chainId })}
        goToLeaderboard={goToLeaderboard}
        goToTrade={goToTrade}
      />
    );
  },
);
