import { ObservableMap } from "mobx";

function buildBigIntSerializationFunctions() {
  return {
    serialize: (value: bigint) => value.toString(),
    deserialize: (strValue: string) => BigInt(strValue),
  };
}

// NOTE : Stores all map records as strings and then parses them back to their original types.
//        If using a not default key/value types, you may need to adjust the serialization/deserialization functions.
function buildObservableMapSerializationFunctions() {
  return {
    serialize: (mapObj: ObservableMap) => {
      return JSON.stringify(
        [...mapObj.entries()].map((entry) => JSON.stringify(entry)),
      );
    },
    deserialize: (arrStr: string) => {
      const entriesAsString = JSON.parse(arrStr) as string[];
      const entriesAsArrs = entriesAsString.map((stringEntry) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        JSON.parse(stringEntry),
      );
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      // console.log(`Entries As Arrs : ${entriesAsArrs}`);

      return new ObservableMap(entriesAsArrs);
    },
  };
}

export const persistenceUtils = {
  buildBigIntSerializationFunctions: buildBigIntSerializationFunctions,
  buildObservableMapSerializationFunctions:
    buildObservableMapSerializationFunctions,
};
