import React, { useCallback } from "react";
import { Box, createTheme, Stack } from "@mui/material";
import { THEME_CONSTANTS } from "../../../theme/ThemeConstants";
import { numericalDisplay } from "../../../ux/displayCalculations";

import {
  TradePairSelection,
  TTradePairSelectionOptionGist,
  PairInfoBoxes,
} from "lynx-ui-components";

interface IProps {
  pairSelectOptions: TTradePairSelectionOptionGist[];
  pairSelectedValue: string;
  onPairSelect: (val: string) => void;

  openInterestShortInUnits: number;
  openInterestLongInUnits: number;

  borrowRatePerSecondInUnits: number;
  shortFundingAprPerSecondInPercentage: number;
  longFundingAprPerSecondInPercentage: number;

  hideOnMobile?: boolean;
}

export const PairDisplayHeader = React.memo<IProps>((props) => {
  const {
    pairSelectOptions,
    onPairSelect,
    pairSelectedValue,
    openInterestLongInUnits,
    openInterestShortInUnits,
    borrowRatePerSecondInUnits,
    shortFundingAprPerSecondInPercentage,
    longFundingAprPerSecondInPercentage,
    hideOnMobile,
  } = props;
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1100,
        lg: 1440,
        xl: 1536,
      },
    },
  });

  const onTradePairChange = useCallback(
    (val: string) => {
      onPairSelect(val);
    },
    [onPairSelect],
  );

  return (
    <Stack
      direction={"row"}
      gap={1}
      sx={{
        display: {
          xs: hideOnMobile ? "none" : "flex",
          // xs: "flex",
          sm: "flex",
        },
      }}
      flexWrap={"wrap"}
      // justifyContent={"space-between"}
    >
      {/* Selectors Stack */}
      <Stack
        direction={"row"}
        gap={0.5}
        justifyContent={"center"}
        mt={2}
        flex={1}
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
          },
          [theme.breakpoints.down("md")]: {
            marginTop: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <TradePairSelection
            rememberSelected
            value={pairSelectedValue}
            options={pairSelectOptions}
            onChange={onTradePairChange}
          />
        </Box>
        <Stack flex={1} direction={"row"} minWidth={"fit-content"}>
          <PairInfoBoxes
            priceLow24={0}
            priceHigh24={0}
            borrowRatePerSecondInUnits={borrowRatePerSecondInUnits}
            fundingRatePerSecondForShortInUnits={
              shortFundingAprPerSecondInPercentage / 100
            }
            fundingRatePerSecondForLongInUnits={
              longFundingAprPerSecondInPercentage / 100
            }
            shortOIInUnits={openInterestShortInUnits}
            longOIInUnits={openInterestLongInUnits}
            rememberSelected
            // NOTE : These 2 were added because of ongoing work on the Ui components library
            // TODO : CRITICAL : Properly implement these and add relevant values
            // longSkewUnits={0}
            // shortSkewUnits={0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
});
