import { Id, toast, ToastOptions, UpdateOptions } from "react-toastify";

export const TOAST_DEFAULT_CLOSE_MS = 5000;

export function toastInfo(
  text: string | React.ReactElement,
  toastOptions?: ToastOptions,
) {
  return toast(text, {
    theme: "dark",
    closeOnClick: false,
    ...toastOptions,
  });
}

export function toastWarning(
  text: string | React.ReactElement,
  toastOptions?: ToastOptions,
) {
  return toast(text, {
    theme: "dark",
    type: "warning",
    closeOnClick: false,
    ...toastOptions,
  });
}

export function toastError(
  text: string | React.ReactElement,
  toastOptions?: ToastOptions,
) {
  toast(text, {
    type: "error",
    theme: "dark",
    closeOnClick: false,
    ...toastOptions,
  });
}

export function toastInfoReplace(
  toastId: Id,
  text: string | React.ReactElement,
  toastOptions?: UpdateOptions,
) {
  toast.update(toastId, { render: text, type: "info", ...toastOptions });
}

export function toastErrorReplace(
  toastId: Id,
  text: string,
  toastOptions?: UpdateOptions,
) {
  toast.update(toastId, { render: text, type: "error", ...toastOptions });
}

export function dismissToast(toastId: Id) {
  toast.dismiss(toastId);
}
