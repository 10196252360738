import { BytesLike, ethers } from "ethers";

export function calculatePositionHashId(
  settlementAsset: string,
  trader: string,
  pairId: number,
  positionIndex: number,
): BytesLike {
  const encoded = ethers.solidityPacked(
    ["address", "address", "uint16", "uint32"],
    [settlementAsset, trader, pairId, positionIndex],
  );
  const hash = ethers.keccak256(encoded);
  return hash;
}
