import { useEffect, useState } from "react";
import { calculatePositionLiquidationPrice } from "./tradeCalculations";

export function useCalculatePositionLiquidationPrice(
  openPriceInUnits: number,
  isLong: boolean,
  collateralInUnits: number,
  leverageInUnits: number,
  rolloverFeeInUnits: number,
  fundingFeeInUnits: number,
) {
  const [liquidationPrice, setLiquidationPrice] = useState(0);

  useEffect(() => {
    if (collateralInUnits > 0) {
      const calculatePrice = calculatePositionLiquidationPrice(
        openPriceInUnits,
        isLong,
        collateralInUnits,
        leverageInUnits,
        rolloverFeeInUnits,
        fundingFeeInUnits,
      );

      setLiquidationPrice(calculatePrice);
    } else {
      setLiquidationPrice(0);
    }
  }, [
    collateralInUnits,
    fundingFeeInUnits,
    isLong,
    leverageInUnits,
    openPriceInUnits,
    rolloverFeeInUnits,
  ]);

  return liquidationPrice;
}
