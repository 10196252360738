/**
 * Note : Enforces to fill all the fields
 */
export function cloneAndCompleteOverride<T>(source: T, overrides?: T): T {
  return cloneAndOverride(source, overrides);
}

export function cloneAndOverride<T>(source: T, overrides?: Partial<T>): T {
  const safeOverrides = overrides || {};

  return {
    ...source,
    ...safeOverrides,
  };
}
