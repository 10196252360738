import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { observer } from "mobx-react";
import { Box, Stack, Typography } from "@mui/material";
import { PendingDepositsAndRedeemDisplayPanel } from "./PendingDepositsAndRedeemDisplayPanel";
import {
  useCryptoWalletIntegrationStore,
  useLeverageDimensionsStore,
} from "../../store/storeHooks";
import {
  PANELS_GAP_REM,
  useStructureClasses,
} from "../../theme/commonClasses.ts";
import { useUrlQueryState_activeSettlementAsset } from "../../navigation/navigationStateHooks.ts";
import { ConnectedLiquidityPoolPanel } from "./subComponents/LiquidityPoolPanel/ConnectedLiquidityPoolPanel.tsx";
import { InfoBlob } from "./subComponents/InfoBlob/InfoBlob.tsx";
import { numericalDisplay } from "../../ux/displayCalculations.ts";
import { ConnectedPoolSupplyLiquidityModal } from "./subComponents/LiquidityActionModal/ConnectedPoolSupplyLiquidityModal.tsx";
import { ConnectedPoolRemoveLiquidityModal } from "./subComponents/LiquidityActionModal/ConnectedPoolRemoveLiquidityModal.tsx";
import {
  TabSelectorGist,
  TabsSelectorRow,
  TextToggle,
} from "lynx-ui-components";

interface IProps {}

const useStyles = makeStyles()((theme) => ({}));

export const LiquidityPage = observer<React.FC<IProps>>((props) => {
  const { classes: pageClasses } = useStructureClasses();
  const { classes } = useStyles();

  // Liquidity page should always have the SA param
  useUrlQueryState_activeSettlementAsset();

  const leverageDimensionsStore = useLeverageDimensionsStore();
  const walletConnectionStore = useCryptoWalletIntegrationStore();

  const [lexIdForLiquidityModal, seLexIdForLiquidityModal] = useState("");

  const [showLiquiditySupplyModal, setShowLiquiditySupplyModal] =
    useState(false);
  const [showLiquidityRemovalModal, setShowLiquidityRemovalModal] =
    useState(false);

  const [liquidityProcessingItems, setLiquidityProcessingItems] = useState<
    Record<string, boolean>
  >({});

  const showSupplyLiquidityModal = useCallback((lexId: string) => {
    seLexIdForLiquidityModal(lexId);
    setShowLiquiditySupplyModal(true);
  }, []);
  const hideSupplyLiquidityModal = useCallback(() => {
    seLexIdForLiquidityModal("");
    setShowLiquiditySupplyModal(false);
  }, []);

  const showRemoveLiquidityModal = useCallback((lexId: string) => {
    seLexIdForLiquidityModal(lexId);
    setShowLiquidityRemovalModal(true);
  }, []);
  const hideRemoveLiquidityModal = useCallback(() => {
    seLexIdForLiquidityModal("");
    setShowLiquidityRemovalModal(false);
  }, []);

  // TODO : CRITICAL : Make a replacement that will auto load all lexes in chain
  // NOTE : Quick-n-Dirty to make sure that the lex data is being read
  // useEffect(() => {
  //   void lexStore?.refreshFromOutside();
  // }, [lexStore]);

  const lexStoresLength = leverageDimensionsStore.lexStores.length;

  const timestamp = Math.floor(Date.now() / 1000);

  // const { userDepositsSumInUSD, globalDepositsSumInUSD } = useMemo(() => {
  //   const timestampInner = timestamp;
  //   let userDepositsSumInUSD = 0;
  //   let globalDepositsSumInUSD = 0;
  //
  //   for (const lexStore of leverageDimensionsStore.lexStores) {
  //     // TODO : Move this for loop into a computed function
  //     const globalBalance = lexStore.virtualBalanceForUtilizationInUnits;
  //     const userBalance = lexStore.activeUserStore.accountSupplyInUnits;
  //     const price = lexStore.underlyingUsdPrice;
  //
  //     globalDepositsSumInUSD += globalBalance * price;
  //     userDepositsSumInUSD += userBalance * price;
  //   }
  //
  //   return { userDepositsSumInUSD, globalDepositsSumInUSD };
  // }, [leverageDimensionsStore.lexStores, timestamp]);

  const [selectedDisplayToggleValue, setSelectedDisplayToggleValue] =
    useState(0);

  const showFromActiveChain = selectedDisplayToggleValue === 0;
  const showFromAllChains = selectedDisplayToggleValue === 1;

  let lexStoresToUse = showFromActiveChain
    ? leverageDimensionsStore.allLexStoresInActiveChain
    : leverageDimensionsStore.lexStores;

  if (showFromAllChains) {
    lexStoresToUse = lexStoresToUse.sort((a, b) => {
      // debugger;
      if (
        a.sourceChainId == walletConnectionStore.chainId &&
        b.sourceChainId != walletConnectionStore.chainId
      ) {
        return -1;
      } else if (
        a.sourceChainId !== walletConnectionStore.chainId &&
        b.sourceChainId !== walletConnectionStore.chainId
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const timestampInner = timestamp;
  let userDepositsSumInUSD = 0;
  let globalDepositsSumInUSD = 0;

  for (const lexStore of lexStoresToUse) {
    // TODO : Move this for loop into a computed function
    const globalBalance = lexStore.virtualBalanceForUtilizationInUnits;
    const userBalance = lexStore.activeUserStore.accountSupplyInUnits;
    const price = lexStore.underlyingUsdPrice;

    globalDepositsSumInUSD += globalBalance * price;
    userDepositsSumInUSD += userBalance * price;
  }

  return (
    <div className={pageClasses.pageRoot}>
      <Box
        className={pageClasses.pageContent_column}
        // style={{ border: "1px solid red" }}
      >
        <Stack
          direction={"row"}
          // border={"1px solid blue"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Typography
            variant={"h3"}
            fontWeight={"bold"}
            style={{ alignSelf: "start" }}
          >
            Liquidity Pools
            <Typography variant={"h6"}>
              <a
                href="https://lynx-finance.gitbook.io/lynx-finance/for-users/liquidity-providers"
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                (Learn more)
              </a>
            </Typography>
          </Typography>
          <Stack
            direction={"row"}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <InfoBlob
              title={"Your Deposits"}
              mainText={`$${numericalDisplay(userDepositsSumInUSD, 3)}`}
            />
            <InfoBlob
              title={"Total Deposits"}
              mainText={`$${numericalDisplay(globalDepositsSumInUSD, 3)}`}
            />
          </Stack>
        </Stack>
        {walletConnectionStore.isConnectedToWallet ? (
          <Box>
            <PendingDepositsAndRedeemDisplayPanel
              showFromAllChains={showFromAllChains}
            />
          </Box>
        ) : undefined}
        <Box
          sx={{
            flex: 3,
            display: "flex",
            flexDirection: "column",
            gap: `${PANELS_GAP_REM}rem`,
            // alignItems: "center",
          }}
          padding={2}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <TextToggle
              value={selectedDisplayToggleValue}
              onChange={setSelectedDisplayToggleValue}
              options={[
                {
                  text: walletConnectionStore.chainName,
                  value: 0,
                },
                {
                  text: "All Chains",
                  value: 1,
                },
              ]}
            />
          </Stack>

          <Stack
            direction={"row"}
            gap={3.5}
            justifyContent={"center"}
            flexWrap={"wrap"}
          >
            {lexStoresToUse.map((lexStore) => {
              return (
                <ConnectedLiquidityPoolPanel
                  ledId={lexStore?.id ?? ""}
                  key={lexStore.id}
                  showSupplyLiquidityModal={() =>
                    showSupplyLiquidityModal(lexStore.id)
                  }
                  showRemoveLiquidityModal={() =>
                    showRemoveLiquidityModal(lexStore.id)
                  }
                  isCardActive={lexIdForLiquidityModal == lexStore.id}
                  isProcessing={liquidityProcessingItems[lexStore.id]}
                />
              );
            })}
          </Stack>
        </Box>
      </Box>
      <ConnectedPoolSupplyLiquidityModal
        lexId={lexIdForLiquidityModal}
        open={showLiquiditySupplyModal}
        closeModal={hideSupplyLiquidityModal}
        onLexIdChange={showSupplyLiquidityModal}
        addProcessingItem={(key, value) =>
          setLiquidityProcessingItems((prev) => ({ ...prev, [key]: value }))
        }
      />
      <ConnectedPoolRemoveLiquidityModal
        lexId={lexIdForLiquidityModal}
        open={showLiquidityRemovalModal}
        closeModal={hideRemoveLiquidityModal}
        onLexIdChange={showRemoveLiquidityModal}
        addProcessingItem={(key, value) =>
          setLiquidityProcessingItems((prev) => ({ ...prev, [key]: value }))
        }
      />
    </div>
  );
});
