/**
 * Used to fix BN amount that originate in a normal number (such as the XXXInUnits) and due to JS float limitations
 * might have a small extra remainder that will elad to "ERC20:transfer amount exceeds balance".
 * @param availableAmount The max available amount
 * @param requestedAmount The amount to deposit/use
 * @param sensitivity
 */
function floorPossiblyExtraDustAmount(
  availableAmount: bigint,
  requestedAmount: bigint,
  sensitivity = 1000n,
): bigint {
  const safeRoundingRange = availableAmount + availableAmount / sensitivity;
  const fixedPositionSizeBn =
    requestedAmount > availableAmount && requestedAmount < safeRoundingRange
      ? availableAmount
      : requestedAmount;

  return fixedPositionSizeBn;
}

export const bnRoundingUtils = { floorPossiblyExtraDustAmount };
