import {
  CHAIN_ID_NO_CHAIN,
  CHAIN_ID_TO_DISPLAY_DATA,
  DISPLAY_GIST_EMPTY,
  LIVE_CHAIN_CONSTANTS,
  TChainExplorerBaseUrls,
  TChainIdsWithNone,
  TLiveChainIds,
} from "../constants/chainConstants";
import { useMemo } from "react";

export function useDisplayParamsForChainId(chainId?: TChainIdsWithNone) {
  const displayParams = useMemo(() => {
    if (
      chainId &&
      chainId !== CHAIN_ID_NO_CHAIN &&
      CHAIN_ID_TO_DISPLAY_DATA[chainId as TLiveChainIds]
    ) {
      return CHAIN_ID_TO_DISPLAY_DATA[chainId as TLiveChainIds];
    } else {
      return DISPLAY_GIST_EMPTY;
    }
  }, [chainId]);

  return displayParams;
}

export function useChainExplorerUrlsForChainId(
  chainId?: TChainIdsWithNone,
): TChainExplorerBaseUrls {
  const explorerUrls = useMemo(() => {
    return getChainExplorersUrlFromChainId(chainId ?? CHAIN_ID_NO_CHAIN);
  }, [chainId]);

  return explorerUrls;
}

export function getChainExplorersUrlFromChainId(
  chainId: TChainIdsWithNone,
): TChainExplorerBaseUrls {
  if (
    chainId &&
    chainId !== CHAIN_ID_NO_CHAIN &&
    LIVE_CHAIN_CONSTANTS[chainId as TLiveChainIds]
  ) {
    return LIVE_CHAIN_CONSTANTS[chainId as TLiveChainIds].explorerUrls;
  } else {
    return {
      explorerName: "",
      baseUrl: "",
      txUrlBase: "",
      addressUrlBase: "",
      blockUrlBase: "",
    };
  }
}
